import { createContext, ReactNode, useContext, useState } from 'react';

import { Filter, FilterContextType } from './types';

const FilterContext = createContext<FilterContextType | undefined>(undefined);

export const useFilters = (): FilterContextType => {
  const context = useContext(FilterContext);
  if (!context)
    throw new Error('useFilters must be used within a FilterProvider');

  return context;
};

export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [filter, setFilter] = useState<Filter>({});

  // dodaje/azurira properti unutar filter objekta
  const updateFilters = (key: string, value: any) => {
    setFilter((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  // uklanja odgovarajuci properti iz filter objekta
  const removeFilter = (key: string) => {
    setFilter((prev) => {
      const { [key]: _, ...rest } = prev;
      return rest;
    });
  };

  const removeFilters = (keys: Array<string>) => {
    keys.forEach((key) => removeFilter(key));
  };

  // uklanja sve propertije iz filter objekta
  const clearFilters = () => setFilter({});

  return (
    <FilterContext.Provider
      value={{
        filter,
        updateFilters,
        removeFilter,
        removeFilters,
        clearFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
