export enum CodebookAction {
  none,
  all,
  add,
  edit,
  delete,
  enable,
  disable,
  assignToParticipant,
  assignToAnotherParticipant,
  unassignFromParticipant,
  exportLog,
  removeTag,
  import,
  export,

  search,
  viewQR,
  manageDevices,
  archive,

  assignTag,
  assignAnotherTag,
  unassignTag,
  removeParticipant,
  replaceParticipant,
}

export interface TableColumn {
  id: string;
  displayName: string;
  accessor: string;
  width: number;
  sortable?: boolean;
  render?: any;
}

export interface TableResponse {
  data: any;
  _route: TableParameters;
}

export interface TableParameters {
  returned: number;
  total: number;
}
