import { PropertyPath, get, set } from 'lodash';

interface StringToDateArgs<T> {
  /**
   * Object whose values will be converted to Date
   */
  object: T;
  /**
   * Key or keys to be converted to Date
   * @example
   * const object = {
   *  key1: '2021-01-01',
   *  key2: '2021-01-02',
   *  key3: '2021-01-03',
   * };
   *
   * stringToDate({ object, key: 'key1' });
   * // { key1: Date('2021-01-01'), key2: '2021-01-02', key3: '2021-01-03' }
   *
   * stringToDate({ object, key: ['key1', 'key2'] });
   * // { key1: Date('2021-01-01'), key2: Date('2021-01-02'), key3: '2021-01-03' }
   */
  key: PropertyPath | PropertyPath[];
}

export const stringToDate = <T extends object>({
  object,
  key,
}: StringToDateArgs<T>) => {
  const objectClone = structuredClone(object);

  if (Array.isArray(key)) {
    return key.reduce((acc, curr) => {
      return stringToDate({ object: acc, key: curr });
    }, objectClone);
  }

  const value = get(objectClone, key);

  if (typeof value === 'string') {
    set(objectClone, key, new Date(value));
  }

  return objectClone;
};
