import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { TextInput, Stack, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import Select from 'components/Select/Select';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useEffect } from 'react';

import { AddControlAccess } from '../types';

import styles from './Modals.module.scss';

const AddingControlAccessModal = ({
  formState,
  setFormState,
  isFormValid,
  setIsFormValid,
}: ModalFormProps) => {
  const form = useForm<AddControlAccess>({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      accessName: '',
      status: '',
      description: '',
    },
  });
  useEffect(() => {
    if (form.isValid()) {
      setIsFormValid(true);
      const newFormState = form.values;

      if (setFormState) {
        setFormState((prevState) => ({
          ...prevState,
          ...newFormState,
        }));
      }
    } else {
      setIsFormValid(false);
    }
  }, [form.values, form.isValid()]);

  return (
    <Stack gap="1.5rem">
      <TextInput
        label="Access name"
        classNames={{ label: styles.labelStyle }}
        key={form.key('accessName')}
        {...form.getInputProps('accessName')}
      />
      <Select
        label="Status"
        classNames={{ label: styles.labelStyle }}
        data={['Active', 'Inactive']}
        rightSection={
          <ChevronDownIcon width="1.5rem" height="1.5rem" color="#0F172A" />
        }
        key={form.key('status')}
        {...form.getInputProps('status')}
      />
      <Textarea
        label="Description"
        classNames={{ label: styles.labelStyle }}
        placeholder="Short description about control access..."
        autosize
        minRows={3}
        key={form.key('description')}
        {...form.getInputProps('description')}
      />
    </Stack>
  );
};
export default AddingControlAccessModal;
