import { ErrorMessage } from '@hookform/error-message';
import { DateInput } from '@lanaco/lnc-react-ui';
import { Text } from '@mantine/core';

import { InputError } from '../InputError';

import styles from './DateInputGroup.module.scss';

interface DateInputGroupProps {
  label: string;
  name: string;
  placeholder?: string;
  register: any;
  errors: any;
  value: Date | undefined;
  withLargeErrorMessage?: boolean;
  disabled?: boolean;
  onChange?: (value: Date, element: HTMLElement) => void;
  defaultValue?: Date;
  withAsterisk?: boolean;
}

export const DateInputGroup = ({
  label,
  name,
  register,
  errors,
  withAsterisk,
  defaultValue,
  onChange,
  value,
  withLargeErrorMessage,
  ...props
}: DateInputGroupProps) => {
  return (
    <div className={styles.dateInputGroup}>
      <label htmlFor={name} className={styles.label}>
        {label}
        {withAsterisk && (
          <Text component="span" c="red">
            *
          </Text>
        )}
      </label>
      <DateInput
        {...props}
        {...register(name)}
        selected={value}
        shouldCloseOnOpen={true}
        onChange={onChange}
        className={styles.datePicker}
        defaultValue={defaultValue}
      />

      {/* <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <InputError largeMessage={withLargeErrorMessage} message={message} />
        )}
      /> */}
    </div>
  );
};
