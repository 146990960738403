import { IconCircle } from '@tabler/icons-react';
import { MarkerComponentProps } from 'react-image-marker';

//import style from '../../pages/Events/Events.module.css';

const CustomMarker = (props: MarkerComponentProps) => {
  return <IconCircle stroke={4} color="red" />;
};

export default CustomMarker;
