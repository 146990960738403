import { EventParticipant } from 'pages/Events/interfaces';
import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import { EventParticipantPayload } from 'types/event';
import {
  CREATE_EVENT_PARTICIPANT,
  UPDATE_EVENT_PARTICIPANT,
} from 'utils/apiUrl';

interface UpdateParams {
  eventId: string;
  participantId: string;
}

export class EventParticipantsService extends ResponseInterceptor {
  static async createParticipant(
    eventId: string,
    body: EventParticipantPayload,
  ): Promise<APIAxiosResponse<EventParticipant>> {
    const res = await client.post(CREATE_EVENT_PARTICIPANT(eventId), body);
    return this.handleResponse(res);
  }

  static async updateParticipant(
    { eventId, participantId }: UpdateParams,
    body: EventParticipantPayload,
  ): Promise<APIAxiosResponse<EventParticipant>> {
    const res = await client.put(
      UPDATE_EVENT_PARTICIPANT(eventId, participantId),
      body,
    );

    return this.handleResponse(res);
  }
}
