import {
  FolderPlusIcon,
  BellSlashIcon,
  LockOpenIcon,
  LockClosedIcon,
  FolderMinusIcon,
  BellIcon,
} from '@heroicons/react/24/outline';
import { Badge, Checkbox, Flex, Stack, Text } from '@mantine/core';
import { useEntitiesContext } from 'context/EntityProvider';
import { ControlAccessDetailsCardProps } from 'pages/Entities/Hub/types';
import { useState } from 'react';

const ControlAccessDetailsCard = ({
  controlAccessObject,
  numberOfElements,
}: ControlAccessDetailsCardProps) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const {
    selectedAccessControlIds,
    setSelectedAccessControlIds,
    setIndeterminateControlAccessCheckbox,
    setSelectAllAccessControl,
  } = useEntitiesContext();

  const handleCheckedCard = (id: string) => {
    setSelectedAccessControlIds((prev) => {
      const isAlreadySelected = prev.some((existingId) => existingId === id);
      const updatedIds = isAlreadySelected
        ? prev.filter((existingId) => existingId !== id)
        : [...prev, id];

      if (updatedIds.length === 0) {
        setIndeterminateControlAccessCheckbox(false);
        setSelectAllAccessControl(false);
      } else if (updatedIds.length === numberOfElements) {
        setIndeterminateControlAccessCheckbox(false);
        setSelectAllAccessControl(true);
      } else {
        setIndeterminateControlAccessCheckbox(true);
        setSelectAllAccessControl(false);
      }

      return updatedIds;
    });
  };

  return (
    <Stack
      pt="1.125rem"
      pb={showDetails ? '0.75rem' : '1.125rem'}
      px="0.75rem"
      gap="0.75rem"
      bg={`${
        selectedAccessControlIds.some(
          (existingId) => existingId === controlAccessObject.id,
        )
          ? 'var(--blue-50)'
          : showDetails
          ? 'var(--gray-50)'
          : 'var(--gray-0)'
      }`}
      bd={`${
        selectedAccessControlIds.some(
          (existingId) => existingId === controlAccessObject.id,
        )
          ? '1px solid var(--blue-500)'
          : '1px solid var(--gray-200)'
      }`}
      onClick={() => setShowDetails((prev) => !prev)}
      style={{
        borderRadius: '0.75rem',
      }}
    >
      <Flex mb={showDetails ? '0.375rem' : ''} justify="space-between">
        <Flex pl="0.5rem" align="center">
          <Checkbox
            label={controlAccessObject.name}
            c="var(--gray-900)"
            checked={selectedAccessControlIds.some(
              (existingId) => existingId === controlAccessObject.id,
            )}
            onChange={() => {
              handleCheckedCard(controlAccessObject.id);
            }}
            onClick={(e) => e.stopPropagation()}
          />
          <Text ml="0.25rem" c="var(--gray-500)" fz="0.875rem">
            (
            {controlAccessObject?.eventAccessLevel.length +
              controlAccessObject?.eventLayoutAccessLevel.length +
              controlAccessObject?.eventLayoutSectionAccessLevel.length}
            )
          </Text>
        </Flex>

        <Flex gap="1.5rem">
          {/* ovaj podatak nema u apiju */}
          {/* <Flex justify="center" align="center">
            <ViewfinderCircleIcon
              height="1.25rem"
              width="1.25rem"
              color="var(--gray-500)"
            />
            <Text ml="0.5rem" c="var(--gray-500)" fz="0.875rem">
              {controlAccessObject.numberOfTrackingObject}
            </Text>
          </Flex> */}

          <Badge
            variant="light"
            color={
              controlAccessObject.status === 'active'
                ? 'var(--green-500)'
                : 'var(--gray-500)'
            }
            tt="none"
          >
            {controlAccessObject.status === 'active' ? 'Active' : 'Inactive'}
          </Badge>
        </Flex>
      </Flex>

      {showDetails &&
        (controlAccessObject.eventAccessLevel?.length > 0 ||
          controlAccessObject.eventLayoutAccessLevel?.length > 0 ||
          controlAccessObject?.eventLayoutSectionAccessLevel?.length > 0) &&
        [
          {
            array: controlAccessObject.eventAccessLevel,
            label: 'Entity level',
          },
          {
            array: controlAccessObject.eventLayoutAccessLevel,
            label: 'Layout level',
          },
          {
            array: controlAccessObject.eventLayoutSectionAccessLevel,
            label: 'Section level',
          },
        ]
          .filter((item) => item.array && item.array.length > 0)
          .map((item, index) =>
            item.array.map((x) => (
              <Flex
                key={x._id}
                p="0.75rem"
                bg="var(--gray-0)"
                bd="1px solid var(--gray-200)"
                justify="space-between"
                wrap="nowrap"
                style={{
                  borderRadius: '6px',
                }}
              >
                <Stack gap="0.5rem">
                  <Text fz="0.875rem" c="var(--gray-900)">
                    {x.name}
                  </Text>
                  {x.startTime && (
                    <Text fz="0.875rem" c="var(--gray-500)">
                      {x.startTime}
                    </Text>
                  )}
                  <Badge
                    variant="light"
                    color={
                      x.status === 'active'
                        ? 'var(--green-500)'
                        : 'var(--gray-500)'
                    }
                    tt="none"
                  >
                    {x.status === 'active' ? 'Active' : 'Inactive'}
                  </Badge>
                </Stack>

                <Stack gap="0.5rem">
                  <Text fz="0.875rem" c="var(--gray-500)" ta="right">
                    {item.label}
                  </Text>
                  {x.endTime && (
                    <Text fz="0.875rem" c="var(--gray-500)" ta="right">
                      {x.endTime}
                    </Text>
                  )}
                  <Flex justify="flex-end" gap="0.75rem">
                    {x.permission === 'access' ? (
                      <LockOpenIcon
                        width="1.25rem"
                        height="1.25rem"
                        color="var(--green-600)"
                      />
                    ) : (
                      <LockClosedIcon
                        width="1.25rem"
                        height="1.25rem"
                        color="var(--red-600)"
                      />
                    )}
                    {x.notification === true ? (
                      <BellIcon
                        width="1.25rem"
                        height="1.25rem"
                        color="var(--green-600)"
                      />
                    ) : (
                      <BellSlashIcon
                        width="1.25rem"
                        height="1.25rem"
                        color="var(--red-600)"
                      />
                    )}
                    {x.report === true ? (
                      <FolderPlusIcon
                        width="1.25rem"
                        height="1.25rem"
                        color="var(--green-600)"
                      />
                    ) : (
                      <FolderMinusIcon
                        width="1.25rem"
                        height="1.25rem"
                        color="var(--red-600)"
                      />
                    )}
                  </Flex>
                </Stack>
              </Flex>
            )),
          )}
    </Stack>
  );
};

export default ControlAccessDetailsCard;
