import { Table as TableLNC } from '@lanaco/lnc-react-ui';
import clsx from 'clsx';
import { TLModal, TLModalProps } from 'components/Modal';
import { ReactNode, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { NestedObjectKeys } from 'types/util';

import { CodebookAction, TableResponse } from './interfaces';
import style from './Table.module.css';
import { TableFooter } from './TableFooter';
import { TableHeader } from './TableHeader';

export interface Column<T> {
  id: string;
  displayName: string;
  render: (rowData: T) => ReactNode;
  accessor?: NestedObjectKeys<T>;
  width?: number;
  sortable?: boolean;
}

type TableProps<T> = {
  data?: T[];
  columns: Column<T>[];
  loading: boolean;
  selectedData: any;
  setSelectedData?: any;
  selectedEntirePage: boolean;
  onSelectAll?: any;
  onSelectRow?: any;
  getRowStatusIndicatorColor?: (rowData: any) => string;
  enableRowStatusIndicator?: boolean;
  addFormComponent?: any;
  addFormTitle?: any;
  addButtonTitle?: any;
  actions: Array<CodebookAction>;
  rowIdentifier?: string;
  renderAddComponent?: any;
  buttonsGroup?: any;
  withoutHeader?: boolean;
  addFormSubtitle?: string;
  size?: TLModalProps['size'];
  offset: number;
  limit: number;
  setOffset: any;
  tableResponse?: TableResponse;
  enableSelection?: boolean;
  enableSelectAll?: boolean;
  hideFooter?: boolean;
  noBorder?: boolean;
};

export const Table = <T extends unknown>({
  withoutHeader,
  actions,
  addFormTitle,
  addButtonTitle,
  selectedData,
  setSelectedData,
  buttonsGroup,
  size,
  addFormSubtitle,
  renderAddComponent,
  rowIdentifier,
  selectedEntirePage,
  onSelectAll,
  onSelectRow,
  data,
  columns,
  enableRowStatusIndicator,
  getRowStatusIndicatorColor,
  loading,
  hideFooter,
  tableResponse,
  limit,
  offset,
  setOffset,
  enableSelection = true,
  enableSelectAll = true,
  noBorder,
}: TableProps<T>) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [showModal, hideModal] = useModal(
    () => (
      <TLModal
        size={size}
        title={addFormTitle}
        subtitle={addFormSubtitle}
        onClose={hideModal}
      >
        {renderAddComponent(hideModal, null, false)}
      </TLModal>
    ),
    [size, addFormTitle, addFormSubtitle],
  );

  return (
    <div className={clsx(style.tableContainer)}>
      {!withoutHeader && (
        <TableHeader
          actions={actions}
          formAddTitle={addFormTitle}
          buttonAddTitle={addButtonTitle}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          addClickHandler={showModal}
          buttonsGroup={buttonsGroup}
        ></TableHeader>
      )}

      <TableLNC
        EnableSelection={enableSelection}
        EnableSelectAll={enableSelectAll}
        RowIdentifier={rowIdentifier}
        SelectedData={selectedData}
        SelectedEntirePage={selectedEntirePage}
        onSelectAll={onSelectAll}
        onSelectRow={onSelectRow}
        className={clsx(style.table, { [style.noBorder]: noBorder })}
        Data={data}
        Columns={columns}
        EnableRowStatusIndicator={enableRowStatusIndicator}
        GetRowStatusIndicatorColor={getRowStatusIndicatorColor}
        Loading={loading}
        noBorder={noBorder}
      ></TableLNC>
      {!hideFooter && (
        <TableFooter
          totalRows={
            tableResponse?._route.total ? tableResponse?._route.total : 1
          }
          limit={limit}
          offset={offset}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setOffset={setOffset}
        />
      )}
    </div>
  );
};
