import { FlexGrid, FlexGridItem, FormField } from '@lanaco/lnc-react-ui';
import clsx from 'clsx';
import { Icon } from 'components/Icon/Icon';
import { TLModal } from 'components/Modal';
import QR from 'components/QR/QR';
import { useModal } from 'react-modal-hook';
import { formatDateTimeFull } from 'utils/dateFormatters';

import style from '../../Events.module.css';
import styles from '../EventLayout.module.scss';
import { EventLayout, EventLayoutPresentationType } from '../interfaces';
import { LayoutLocationPicker } from '../LayoutScehemForm/LayoutLocationPicker';
import { getPresentationFromEventLayout } from '../util';

type LayoutDetailsProps = {
  layout: EventLayout;
  timezone: string;
};

const LayoutDetails = ({ layout, timezone }: LayoutDetailsProps) => {
  const [showQrCodeModal, hideQrCodeModal] = useModal(
    () => (
      <TLModal
        title={layout.name}
        onClose={hideQrCodeModal}
        className={styles.qrModal}
        titleIcon="layer-group"
      >
        <QR value={layout._id} qrId={layout._id} />
      </TLModal>
    ),
    [layout],
  );

  const presentationSource = getPresentationFromEventLayout(layout, 'large');

  const onLayoutSchemeChange = (
    type: EventLayoutPresentationType,
    locationOrImage:
      | File
      | (google.maps.LatLngLiteral & { zoom: number | undefined }),
  ) => {
    // const { lat, lng, zoom } = locationOrImage as google.maps.LatLngLiteral & {
    //   zoom: number | undefined;
    // };
  };
  const isImage =
    layout.presentation?.type === EventLayoutPresentationType.Image;

  return (
    <FlexGrid>
      <FlexGridItem XS={12} L={5}>
        <FlexGrid className={styles.layout_details_label}>
          <FlexGridItem XS={12} L={12}>
            <FormField
              label="Description"
              className={styles.layout_details_label}
            >
              <p className={styles.layout_details_value}>
                {layout.description}
              </p>
            </FormField>
          </FlexGridItem>
          <FlexGridItem XS={12} L={6}>
            <FormField label="Start" className={styles.layout_details_label}>
              <p className={styles.layout_details_value}>
                {formatDateTimeFull(
                  new Date(layout.startDateTime!).toISOString(),
                  timezone!,
                )}
              </p>
            </FormField>
          </FlexGridItem>
          <FlexGridItem XS={12} L={6}>
            <FormField label="End" className={styles.layout_details_label}>
              <p className={styles.layout_details_value}>
                {formatDateTimeFull(
                  new Date(layout.endDateTime!).toISOString(),
                  timezone!,
                )}
              </p>
            </FormField>
          </FlexGridItem>

          <FlexGridItem XS={12} L={6}>
            <FormField label="Capacity" className={styles.layout_details_label}>
              <p className={styles.layout_details_value}>
                {layout.maxParticipants}
              </p>
            </FormField>
          </FlexGridItem>
          <FlexGridItem XS={12} L={6}>
            <FormField
              label="Age limit"
              className={styles.layout_details_label}
            >
              <p className={styles.layout_details_value}>
                {layout.participantMinAge &&
                  layout.participantMaxAge &&
                  `${layout.participantMinAge} - ${layout.participantMaxAge}`}
                {!layout.participantMinAge &&
                  layout.participantMaxAge &&
                  `${layout.participantMaxAge} max`}
                {layout.participantMinAge &&
                  !layout.participantMaxAge &&
                  `${layout.participantMinAge} min`}
              </p>
            </FormField>
          </FlexGridItem>

          {layout.validationQR && (
            <FlexGridItem XS={12} L={6}>
              <FormField
                label="Validation"
                className={styles.layout_details_label}
              >
                <p
                  className={clsx(styles.layout_details_label, styles.qrCode)}
                  onClick={showQrCodeModal}
                >
                  View QR
                </p>
              </FormField>
            </FlexGridItem>
          )}
          <FlexGridItem XS={12} L={6}>
            <FormField label="Status" className={styles.layout_details_label}>
              <p className={styles.layout_details_value}>{layout.status}</p>
            </FormField>
          </FlexGridItem>
        </FlexGrid>
      </FlexGridItem>
      <FlexGridItem XS={12} L={7}>
        <div className={style.layout_details_image_map}>
          {!presentationSource && (
            <>
              <Icon
                icon="camera"
                className={style.layout_details_image_map_no_image}
              />
              <p className={style.layout_details_image_map_no_image_text}>
                No image
              </p>
            </>
          )}
          {presentationSource && !isImage && (
            <LayoutLocationPicker
              eventLayout={layout}
              visible={true}
              onChange={onLayoutSchemeChange}
            />
          )}

          {presentationSource && isImage && (
            <img
              src={presentationSource}
              alt="map of layout"
              className={styles.layout_details_image}
            />
          )}
        </div>
      </FlexGridItem>
    </FlexGrid>
  );
};

export default LayoutDetails;
