import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import { UpdateClientStatusPayload } from 'types/client';
import { DELETE_CLIENT, DELETE_CLIENTS, UPDATE_CLIENT } from 'utils/apiUrl';

import { ClientResponse } from './types';

export class ClientService extends ResponseInterceptor {
  static async updateClientStatus(
    id: string,
    payload: UpdateClientStatusPayload,
  ): Promise<APIAxiosResponse<ClientResponse>> {
    const response = await client.put(UPDATE_CLIENT(id), payload);
    return this.handleResponse(response);
  }

  static async deleteClient(id: string): Promise<APIAxiosResponse<string>> {
    const response = await client.delete(DELETE_CLIENT(id));
    return this.handleResponse(response);
  }

  static async deleteClients(
    ids: string[],
  ): Promise<APIAxiosResponse<string[]>> {
    const response = await client.delete(DELETE_CLIENTS(), {
      data: { ids },
    });
    return this.handleResponse(response);
  }
}
