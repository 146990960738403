import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { EventLayout } from 'pages/Events/Layout/interfaces';
import { client } from 'services/api';
import { Response } from 'types/response';
import { GET_EVENT_LAYOUT } from 'utils/apiUrl';
import { stringToDate } from 'utils/stringToDate';

import { eventLayoutBaseKey } from './eventLayoutBaseKey';

interface EventLayoutProps {
  eventId?: string;
  offset?: number;
  rowLimit?: number;
  status?: string;
  filter?: string;
}

export const useFetchEventLayouts = ({
  eventId,
  offset,
  rowLimit: row_limit,
  status,
  filter,
}: EventLayoutProps) => {
  const queryClient = useQueryClient();
  const queryResult = useQuery<Response<EventLayout[]>, AxiosError>({
    queryKey: [eventLayoutBaseKey, eventId, offset, row_limit, status, filter],
    queryFn: async () => {
      if (!eventId) {
        return {
          data: [],
          _route: {
            limit: 0,
            offset: 0,
            returned: 0,
            total: 0,
            url: '',
          },
        } as Response<EventLayout[]>;
      }

      const retVal = await client.get<Response<EventLayout[]>>(
        GET_EVENT_LAYOUT(eventId, offset, row_limit, status, filter),
      );

      const { data: _data, ...rest } = retVal.data;

      const data = _data.map<EventLayout>((object) => {
        queryClient.setQueryData<EventLayout>(
          [eventLayoutBaseKey, object._id],
          object,
        );
        return stringToDate({ object, key: ['startDateTime', 'endDateTime'] });
      });

      return {
        ...rest,
        data,
      };
    },
  });

  return queryResult;
};
