import Layout from 'components/Layout/Layout';
import { Navigate, Outlet } from 'react-router';
import { getSession } from 'services/auth';

const PrivateRoutes = () => {
  const session = getSession();

  // useEffect(() => {
  //   let checkToken = async () => {
  //     let { accessToken, refreshToken } = getSession();
  //     if (accessToken) {
  //       const userTokenData = parseJwt(accessToken);
  //       if (userTokenData.exp < Date.now() && refreshToken) {
  //         let tokens = await getRefreshedToken(accessToken, refreshToken);
  //         console.log('tokens');
  //         console.log(tokens);
  //         setSession(tokens.accessToken, tokens.refreshToken);
  //       }
  //       dispatch(getUserData(userTokenData));
  //     }
  //   };
  //   checkToken();
  // }, [dispatch, session]);

  if (session.session) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  }
  return <Navigate to="/login" />;
};

export default PrivateRoutes;
