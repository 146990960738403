import io, { Socket } from 'socket.io-client'

import { getSession } from './auth';

interface DefaultEventsMap {
  [event: string]: (...args: any[]) => void;
}

interface EventsMap {
  [event: string]: any;
}

const SERVER_BASE =
  process.env.REACT_APP_API_LOCATION ||
  'https://timelineapidevenv.azurewebsites.net';


const socket = io(SERVER_BASE, {auth: {token: getAccessHeader()}})
socket.on('connect_error', () => {
  if (!getSession().accessToken) {
    console.info('Socket connect failed');
    return;
  }
  console.info('Socket connect failed - retrying');
  (socket as any).auth.token = getAccessHeader();
  socket.connect();
})
socket.on('connect', () => console.info('Socket connected'));
socket.on('disconnect', () => console.info('Socket disconnected'));

function getAccessHeader() {
  return `bearer ${getSession().accessToken}`
}

export function getSocket<ListenEventsMap extends EventsMap = DefaultEventsMap, EmitEventsMap extends EventsMap = ListenEventsMap>() {
  return {
    reconnect: () => {
      (socket as any).auth.token = getAccessHeader();
      socket.connect();
    },
    disconnect: () => {
      (socket as any).auth.token = undefined;
      socket.disconnect();
    },
    socket: socket as Socket<ListenEventsMap, EmitEventsMap>,
  }
}
