import { CheckBoxInput } from '@lanaco/lnc-react-ui';
import clsx from 'clsx';
import React, { useEffect } from 'react';

type IndeterminateCheckboxProps = {
  indeterminate?: boolean;
} & React.HTMLProps<HTMLInputElement>;

function IndeterminateCheckbox({
  indeterminate,
  className,
  ...rest
}: IndeterminateCheckboxProps) {
  const ref = React.useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, indeterminate]);

  return (
    <CheckBoxInput
      ref={ref}
      className={clsx('indeterminate-checkbox', className)}
      {...rest}
    />
  );
}

export default IndeterminateCheckbox;
