import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { Checkbox, Flex, Stack, TextInput } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import { useEntitiesContext } from 'context/EntityProvider';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useEffect, useState } from 'react';
import { useControlAccess } from 'services/entity/controlAccess';
import { useTrackingObjectByEvent } from 'services/entity/trackingObject';

import ControlAccessDetailsCard from './ControlAccessDetailsCard';

const ControlAccessContent = ({
  dataId = '',
  eventId = '',
  setFormState,
}: ModalFormProps & { eventId: string }) => {
  const { height } = useViewportSize();
  const { data } = useControlAccess(eventId);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { data: { eventParticipants } = {} } = useTrackingObjectByEvent(
    eventId,
    dataId,
  );

  const {
    setSelectedAccessControlIds,
    indeterminateControlAccessCheckbox,
    setSelectAllAccessControl,
    selectAllAccessControl,
    setIndeterminateControlAccessCheckbox,
    selectedAccessControlIds,
  } = useEntitiesContext();

  useEffect(() => {
    if (setFormState)
      setFormState((prev) => ({
        ...prev,
        eventAccessLevels: selectedAccessControlIds,
      }));
  }, [selectedAccessControlIds]);

  useEffect(() => {
    if (eventParticipants && eventParticipants.length > 0) {
      if (eventParticipants[0]?.eventAccess.length > 0) {
        setSelectedAccessControlIds(
          eventParticipants[0].eventAccess.map((x) => x._id),
        );
      }
      if (data?.length === eventParticipants[0]?.eventAccess.length) {
        setSelectAllAccessControl(true);
      }
    } else {
      setSelectedAccessControlIds([]);
      setSelectAllAccessControl(false);
    }
  }, [eventParticipants]);

  const clearCheckboxes = () => {
    setSelectAllAccessControl(false);
    setIndeterminateControlAccessCheckbox(false);
    setSelectedAccessControlIds([]);
  };

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (indeterminateControlAccessCheckbox) {
      clearCheckboxes();
    } else {
      if (event.currentTarget.checked) {
        if (data && data?.length > 0)
          setSelectedAccessControlIds(data?.map((x) => x._id));
      } else {
        setSelectedAccessControlIds([]);
      }
      setSelectAllAccessControl(event.currentTarget.checked);
    }
  };
  return (
    <Stack gap="1.5rem">
      <Flex align="center" justify="space-between">
        <Checkbox
          c="#0F172A"
          label="Select all"
          my="auto"
          mr="1.25rem"
          checked={selectAllAccessControl}
          indeterminate={indeterminateControlAccessCheckbox}
          onChange={handleChecked}
        />
        <TextInput
          size="sm"
          fz="1rem"
          radius="md"
          w="320px"
          placeholder="Search"
          onChange={(e) => setSearchQuery(e.target.value)}
          leftSection={
            <MagnifyingGlassIcon
              height="1.25rem"
              width="1.25rem"
              color="#94A3B8"
            />
          }
        />
      </Flex>

      <ScrollArea.Autosize mah={height - 15 * 24} mr="-1rem" variant="thumb">
        <Flex pr="0.5rem" direction="column" gap="0.75rem">
          {data &&
            data?.length > 0 &&
            data
              .filter((item) =>
                item.name.toLowerCase().includes(searchQuery.toLowerCase()),
              )
              .map((x) => (
                <ControlAccessDetailsCard
                  key={x.id}
                  controlAccessObject={x}
                  numberOfElements={data?.length}
                />
              ))}
        </Flex>
      </ScrollArea.Autosize>
    </Stack>
  );
};

export default ControlAccessContent;
