import clsx from 'clsx';
import {
  TLDangerLoadingButton,
  TLNeutralButton,
  TLNeutralLoadingButton,
  TLPrimaryLoadingButton,
} from 'components/Button';
import { Icon } from 'components/Icon/Icon';
import { TLModal, TLModalProps } from 'components/Modal';
import { ReactNode } from 'react';

import style from './PromptModal.module.scss';

export type PromptModalProps = TLModalProps & {
  children?: ReactNode;
  title?: string;
  onConfirm?: any;
  warningMessage?: ReactNode;
  iconName?: string;
  confirmButtonName?: string;
  isRemoveButton?: boolean;
  icon?: string;
  iconSize?: string;
  iconColor?: string;
  isExport?: any;
  isDeleteButton?: any;
  onCancel?: () => void;
  isLoading?: boolean;
};

const PromptModal = ({
  title,
  warningMessage,
  confirmButtonName,
  children,
  isRemoveButton,
  icon,
  isExport,
  iconColor,
  iconSize,
  isDeleteButton,
  onCancel,
  onConfirm,
  className,
  isLoading = false,
  ...rest
}: PromptModalProps) => {
  return (
    <TLModal
      hideHeader
      className={clsx(style.promptModal, className)}
      {...rest}
    >
      <div className={style.modalContent}>
        {icon && (
          <div
            className={clsx(style.iconPic, {
              [`${style.deleteBg}`]: icon === 'exclamation-triangle',
              [`${style.enableBg}`]: confirmButtonName === 'Enable',
            })}
          >
            {icon && <Icon icon={icon} color={iconColor} size={iconSize} />}
          </div>
        )}

        <div className={style.message}>
          {title && <h3>{title}</h3>}
          {warningMessage && (
            <p className={style.warningMessage}>{warningMessage}</p>
          )}
        </div>
      </div>
      {children}
      <div className={style.footer}>
        {isExport && (
          <>
            <TLNeutralLoadingButton isLoading={isLoading} onClick={onConfirm}>
              Download .json file
            </TLNeutralLoadingButton>
            <TLNeutralLoadingButton isLoading={isLoading} onClick={onConfirm}>
              Download .xlsx file
            </TLNeutralLoadingButton>
          </>
        )}
        <TLNeutralButton onClick={onCancel}>Cancel</TLNeutralButton>
        {!isExport &&
          (isRemoveButton || isDeleteButton ? (
            <TLDangerLoadingButton isLoading={isLoading} onClick={onConfirm}>
              {confirmButtonName}
            </TLDangerLoadingButton>
          ) : (
            <TLPrimaryLoadingButton isLoading={isLoading} onClick={onConfirm}>
              {confirmButtonName}
            </TLPrimaryLoadingButton>
          ))}
      </div>
    </TLModal>
  );
};

export default PromptModal;
