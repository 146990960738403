import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Modal,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useDisclosure, useElementSize } from '@mantine/hooks';
import LayoutChanger from 'components/Canvas/CanvasToolbox/LayoutChanger';
import ZoomControl from 'components/Canvas/CanvasToolbox/ZoomControl';
import {
  CanvasDeviceType,
  LayoutChangerType,
  MapControlType,
} from 'components/Canvas/types';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import { SortingParamType } from 'components/SortingDropdown/types';
import { has } from 'lodash';
import { mappingDevicesSortingParams } from 'pages/Entities/consts';
import DeleteModal from 'pages/Entities/ModalManager/DeleteModal';
import { useEffect, useState } from 'react';

import LayoutPicture from '../../../../../assets/images/LayoutPicture.jpg';
import MapPicture from '../../../../../assets/images/MapPicture.png';
import MapControl from '../../../../../components/Canvas/CanvasToolbox/MapControl';
import InteractiveCanvas from '../../../../../components/Canvas/InteractiveCanvas/InteractiveCanvas';
import SortingDropdown from '../../../../../components/SortingDropdown/SortingDropdown';
import DeviceMappingCard from '../Components/DeviceMappingCard';

const layouts: Array<LayoutChangerType> = [
  {
    id: '550e8400-e29b-41d4-a716-446655440000',
    name: 'Ground floor',
    picture: MapPicture,
    devices: [
      {
        id: 1,
        state: 'online',
        type: 'relay',
        name: 'BT-D-MISKA-1',
        date: '14 Feb 2024',
        time: '10:30 PM',
        macAddress: 'D8:3A:DD:2A:49:53',
        status: 'enabled',
        coordinates: {
          lat: 0.0359,
          lng: 0.08899,
        },
      },
      {
        id: 2,
        state: 'online',
        type: 'switch',
        name: 'D-MISKA-2',
        date: '14 Feb 2024',
        time: '10:30 PM',
        macAddress: 'D8:3A:DD:2A:49:43',
        status: 'enabled',
      },
      {
        id: 3,
        state: 'offline',
        type: 'anthena',
        name: 'BT-D-MISKA-2',
        date: '14 Feb 2024',
        time: '10:30 PM',
        macAddress: 'D8:3A:DD:2A:49:33',
        status: 'disabled',
        coordinates: {
          lat: 0.5,
          lng: 0.5,
        },
      },
      {
        id: 4,
        state: 'online',
        type: 'anthenaRelay',
        name: 'D-MISKA-1',
        date: '14 Feb 2024',
        time: '10:30 PM',
        macAddress: 'D8:3A:DD:2A:49:23',
        status: 'enabled',
      },
      {
        id: 5,
        state: 'offline',
        type: 'processor',
        name: 'BT-D-MISKA-2',
        date: '14 Feb 2024',
        time: '10:30 PM',
        macAddress: 'D8:3A:DD:2A:49:13',
        status: 'disabled',
      },
      {
        id: 6,
        state: 'idle',
        type: 'relay',
        name: 'BT-D-MISKA-3',
        date: '14 Feb 2024',
        time: '10:30 PM',
        macAddress: 'D8:3A:DD:2A:49:63',
        status: 'enabled',
        coordinates: {
          lat: 0.75,
          lng: 0.65,
        },
      },
    ],
  },
  {
    id: '3f29c5a4-8d7e-4f6a-bb0e-9a1f3c6d2b45',
    name: 'First floor',
    picture: LayoutPicture,
    devices: [
      {
        id: 7,
        state: 'online',
        type: 'relay',
        name: 'BD-T-MISKA-1',
        date: '14 Feb 2024',
        time: '10:30 PM',
        macAddress: 'D8:3A:DD:2A:19:13',
        status: 'enabled',
        coordinates: {
          lat: 0.5359,
          lng: 0.6,
        },
      },
      {
        id: 8,
        state: 'offline',
        type: 'switch',
        name: 'BDD-MISKA-1',
        date: '14 Feb 2024',
        time: '10:30 PM',
        macAddress: 'D8:3A:DD:2A:79:73',
        status: 'enabled',
      },
      {
        id: 9,
        state: 'idle',
        type: 'anthena',
        name: 'BDD-MISKA-2',
        date: '14 Feb 2024',
        time: '10:30 PM',
        macAddress: 'D8:3A:DD:2A:59:53',
        status: 'disabled',
        coordinates: {
          lat: 0.75,
          lng: 0.75,
        },
      },
    ],
  },
];

const MappingDevicesModal = () => {
  const [activeLayout, setActiveLayout] = useState<LayoutChangerType>(
    layouts[0],
  );
  const [filteredDevices, setFilteredDevices] = useState<
    Array<CanvasDeviceType>
  >([]);
  const [selectedDevice, setSelectedDevice] = useState<CanvasDeviceType | null>(
    null,
  );
  const [hoveredDevice, setHoveredDevice] = useState<CanvasDeviceType | null>(
    null,
  );
  const [deleteDevice, setDeleteDevice] = useState<CanvasDeviceType | null>(
    null,
  );
  const [selectedState, setSelectedState] = useState<
    'all' | 'online' | 'idle' | 'offline'
  >('all');
  const [sortingParam, setSortingParam] = useState<SortingParamType | null>(
    null,
  );
  const [mapControl, setMapControl] = useState<MapControlType>({
    sectionsPins: false,
    sectionsArea: false,
    devices: false,
  });
  const [scale, setScale] = useState<number>(1);

  const { ref: boxRef, height: boxHeight, width: boxWidth } = useElementSize();
  const [
    opened,
    { open: openConfirmModal, close: closeConfirmModal },
  ] = useDisclosure();

  useEffect(() => {
    if (selectedState === 'all') setFilteredDevices(activeLayout?.devices);
    else
      setFilteredDevices(
        activeLayout?.devices.filter(
          (device) => device.state === selectedState,
        ),
      );
  }, [activeLayout, selectedState]);

  useEffect(() => {
    setSelectedDevice(null);
  }, [activeLayout]);

  return (
    <>
      <Modal
        size={320}
        withCloseButton={false}
        centered
        opened={opened}
        onClose={() => {
          setDeleteDevice(null);
          closeConfirmModal();
        }}
        zIndex={300}
      >
        <DeleteModal
          variant="notification"
          title="Remove device?"
          body={
            <>
              Are you sure you want to delete device <b>{deleteDevice?.name}</b>{' '}
              from {activeLayout.name}?
            </>
          }
          actionButtonLabel="Remove"
          actionButtonFn={() => {
            // TODO: dodati delete mutaciju
            setDeleteDevice(null);
            closeConfirmModal();
          }}
          closeButtonFn={() => {
            setDeleteDevice(null);
            closeConfirmModal();
          }}
        />
      </Modal>

      <Grid>
        <Grid.Col span={5}>
          <Button.Group mb="0.75rem">
            <Button
              flex={1}
              bg={selectedState === 'all' ? '#f1f5f9' : '#ffffff'}
              c={selectedState === 'all' ? '#334155' : '#64748b'}
              bd="1px solid #e2e8f0"
              onClick={() => setSelectedState('all')}
            >
              <Text inline mr="0.375rem" c="#0f172a" fz="0.875rem">
                All
              </Text>
              <Text inline c="#94a3b8" fz="0.875rem">
                {activeLayout.devices.length}
              </Text>
            </Button>
            <Button
              flex={1}
              bg={selectedState === 'online' ? '#f1f5f9' : '#ffffff'}
              c={selectedState === 'online' ? '#334155' : '#64748b'}
              bd="1px solid #e2e8f0"
              onClick={() => setSelectedState('online')}
            >
              <Box
                h="0.5rem"
                w="0.5rem"
                mr="0.5rem"
                bg="#10b981"
                style={{ borderRadius: '50%' }}
              />
              <Text inline mr="0.375rem" c="#0f172a" fz="0.875rem">
                Online
              </Text>
              <Text inline c="#94a3b8" fz="0.875rem">
                {
                  activeLayout.devices.filter(
                    (device) => device.state === 'online',
                  ).length
                }
              </Text>
            </Button>
            <Button
              flex={1}
              bg={selectedState === 'idle' ? '#f1f5f9' : '#ffffff'}
              c={selectedState === 'idle' ? '#334155' : '#64748b'}
              bd="1px solid #e2e8f0"
              onClick={() => setSelectedState('idle')}
            >
              <Box
                h="0.5rem"
                w="0.5rem"
                mr="0.5rem"
                bg="#eab308"
                style={{ borderRadius: '50%' }}
              />
              <Text inline mr="0.375rem" c="#0f172a" fz="0.875rem">
                Idle
              </Text>
              <Text inline c="#94a3b8" fz="0.875rem">
                {
                  activeLayout.devices.filter(
                    (device) => device.state === 'idle',
                  ).length
                }
              </Text>
            </Button>
            <Button
              flex={1}
              bg={selectedState === 'offline' ? '#f1f5f9' : '#ffffff'}
              c={selectedState === 'offline' ? '#334155' : '#64748b'}
              bd="1px solid #e2e8f0"
              onClick={() => setSelectedState('offline')}
            >
              <Box
                h="0.5rem"
                w="0.5rem"
                mr="0.5rem"
                bg="#cbd5e1"
                style={{ borderRadius: '50%' }}
              />
              <Text inline mr="0.375rem" c="#0f172a" fz="0.875rem">
                Offline
              </Text>
              <Text inline c="#94a3b8" fz="0.875rem">
                {
                  activeLayout.devices.filter(
                    (device) => device.state === 'offline',
                  ).length
                }
              </Text>
            </Button>
          </Button.Group>

          <Stack
            p="1rem"
            bd="1px solid #e2e8f0"
            style={{ borderRadius: '0.75rem' }}
          >
            <Flex align="center">
              <TextInput
                size="sm"
                mr="0.75rem"
                fz="1rem"
                radius="md"
                flex={1}
                placeholder="Search"
                leftSection={
                  <MagnifyingGlassIcon
                    height="1.25rem"
                    width="1.25rem"
                    color="#94A3B8"
                  />
                }
              />

              <SortingDropdown
                sortingParams={mappingDevicesSortingParams}
                sortingParam={sortingParam}
                setSortingParam={setSortingParam}
              />
            </Flex>

            <ScrollArea h="28rem" pr="0.25rem" mr="-0.75rem">
              {filteredDevices?.map((device, index, array) => (
                <DeviceMappingCard
                  key={`device-${device.macAddress}`}
                  state={device.state}
                  type={device.type}
                  name={device.name}
                  date={device.date}
                  time={device.time}
                  macAddress={device.macAddress}
                  isMapped={device?.coordinates?.lat !== undefined}
                  status={device.status}
                  style={{
                    marginBottom: array.length - 1 !== index ? '0.5rem' : '0',
                  }}
                  isSelected={selectedDevice === device}
                  isHovered={hoveredDevice === device}
                  onClick={() =>
                    setSelectedDevice((prev) =>
                      prev === device ? null : device,
                    )
                  }
                  onMouseEnter={() => setHoveredDevice(device)}
                  onMouseLeave={() => setHoveredDevice(null)}
                  onDelete={() => {
                    setDeleteDevice(device);
                    openConfirmModal();
                  }}
                />
              ))}
            </ScrollArea>
          </Stack>
        </Grid.Col>
        <Grid.Col span={7}>
          <Stack
            h="100%"
            p="1rem"
            bd="1px solid #e2e8f0"
            gap={0}
            style={{ borderRadius: '0.75rem' }}
          >
            <Flex mb="1rem" align="center" gap="0.5rem">
              <InformationCircleIcon
                height="1.25rem"
                width="1.25rem"
                color={selectedDevice ? '#60a5fa' : '#94a3b8'}
              />

              <Text
                mr="auto"
                c={selectedDevice ? '#3b82f6' : '#64748b'}
                fz="0.875rem"
                fw={400}
              >
                {has(selectedDevice, 'coordinates')
                  ? 'Device has been mapped'
                  : selectedDevice
                  ? 'Place a device marker on a map'
                  : 'Choose a device from the list'}
              </Text>

              <MapControl
                mapControl={mapControl}
                setMapControl={setMapControl}
              />
            </Flex>

            <Divider mb="1rem" c="#e2e8f0" />

            <Box h="100%" pos="relative" ref={boxRef}>
              {boxRef.current && (
                <InteractiveCanvas
                  imageUrl={activeLayout.picture}
                  height={boxHeight}
                  width={boxWidth}
                  scale={scale}
                  showSections={mapControl.sectionsArea}
                  showPins={mapControl.sectionsPins}
                  showDevices={mapControl.devices}
                  drawMode="device"
                  devicesData={filteredDevices}
                  setDevicesData={setFilteredDevices}
                  hoveredDevice={hoveredDevice}
                  setHoveredDevice={setHoveredDevice}
                  selectedDevice={selectedDevice}
                  setSelectedDevice={setSelectedDevice}
                />
              )}

              <LayoutChanger
                layouts={layouts}
                activeLayout={activeLayout}
                setActiveLayout={setActiveLayout}
                pos="absolute"
                bottom={0}
                left={0}
              />

              <ZoomControl
                scale={scale}
                setScale={setScale}
                pos="absolute"
                right="0.675rem"
                bottom="1rem"
              />
            </Box>
          </Stack>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default MappingDevicesModal;
