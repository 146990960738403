import { ComponentProps, ComponentPropsWithoutRef, ReactNode } from 'react';

export enum ButtonIconStyle {
  REGULAR = 'regular',
  SOLID = 'solid',
}

export enum ButtonBorderRadius {
  REGULAR = 'regular',
  CURVED = 'curved',
  NONE = 'none',
}

export enum ButtonType {
  FILLED = 'filled',
  TINTED = 'tinted',
  OUTLINE = 'outline',
  BASIC = 'basic',
}

export enum ButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFORMATION = 'information',
  NEUTRAL = 'neutral',
}

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface LanacoUIButtonProps {
  children?: ReactNode;
  text?: string;
  iconStyle?: ButtonIconStyle;
  leadingIcon?: string;
  trailingIcon?: string;
  borderRadius?: ButtonBorderRadius;
  btnType?: ButtonType;
  color?: ButtonColor;
  size?: ButtonSize;
}

export interface TLButtonProps
  extends LanacoUIButtonProps,
    Omit<ComponentProps<'button'>, 'color'> {}

export interface TLLoadingButtonProps extends TLButtonProps {
  isLoading?: boolean;
}
