import { Flex, Stack, Text, Avatar, Image, Box } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { ScheduleInformationModalProps } from 'components/ScheduleCard/types';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEntityScheduled } from 'services/entity/home';
import { returnDateAndTimeFromDateTime } from 'utils/dateFormatters';

dayjs.extend(customParseFormat);

const ScheduleInformationModal = ({
  scheduleId,
  eventId,
  timeZone,
}: ScheduleInformationModalProps) => {
  const { data: entityScheduledData } = useEntityScheduled(eventId, scheduleId);
  const { height } = useViewportSize();

  return (
    <ScrollArea.Autosize
      mah={height - 8.75 * 24}
      mr="-1rem"
      variant="thumb"
      scrollbarSize={6}
      styles={{ root: { paddingRight: '0.5rem' } }}
    >
      <Stack>
        <Box pos="relative">
          <Image
            mah={200}
            src={entityScheduledData?.hostHeroImage?.largeSource}
            radius={12}
          />
          <Avatar
            src={entityScheduledData?.hostImage?.largeSource}
            alt="image"
            w="6.25rem"
            h="6.25rem"
            bd="4px solid #FFFFFF"
            pos="absolute"
            bottom={0}
            left="0.5rem"
            style={{
              borderRadius: '50%',
              transform: 'translateY(50%)',
            }}
          />
        </Box>

        <Stack gap="0.25rem" mt="2.25rem">
          <Text fw={600} fz="1.125rem" c="#0F172A" lh="1.75rem">
            {entityScheduledData?.hostName}
          </Text>
          <Text fw={500} fz="0.875rem" c="#64748B">
            {entityScheduledData?.title}
          </Text>
        </Stack>

        <Flex gap="14.375rem">
          <Stack gap="0.25rem">
            <Text fw={500} fz="0.875rem" c="#0F172A">
              Start date
            </Text>
            <Text fw={400} fz="0.875rem" c="#64748B">
              {timeZone &&
                entityScheduledData?.startDateTime &&
                dayjs(
                  returnDateAndTimeFromDateTime(
                    entityScheduledData?.startDateTime,
                    timeZone,
                  ).date,
                ).format('DD MMMM YYYY')}
            </Text>
          </Stack>
          <Stack gap="0.25rem">
            <Text fw={500} fz="0.875rem" c="#0F172A">
              Start time
            </Text>
            <Text fw={400} fz="0.875rem" c="#64748B">
              {timeZone &&
                entityScheduledData?.startDateTime &&
                dayjs(
                  returnDateAndTimeFromDateTime(
                    entityScheduledData?.startDateTime,
                    timeZone,
                  ).time,
                  'HH:mm',
                ).format('hh:mm A')}
            </Text>
          </Stack>
        </Flex>
        <Flex gap="14.375rem">
          <Stack gap="0.25rem">
            <Text fw={500} fz="0.875rem" c="#0F172A">
              End date
            </Text>
            <Text fw={400} fz="0.875rem" c="#64748B">
              {timeZone &&
                entityScheduledData?.endDateTime &&
                dayjs(
                  returnDateAndTimeFromDateTime(
                    entityScheduledData?.endDateTime,
                    timeZone,
                  ).date,
                ).format('DD MMMM YYYY')}
            </Text>
          </Stack>
          <Stack gap="0.25rem">
            <Text fw={500} fz="0.875rem" c="#0F172A">
              End time
            </Text>
            <Text fw={400} fz="0.875rem" c="#64748B">
              {timeZone &&
                entityScheduledData?.endDateTime &&
                dayjs(
                  returnDateAndTimeFromDateTime(
                    entityScheduledData?.endDateTime,
                    timeZone,
                  ).time,
                  'HH:mm',
                ).format('hh:mm A')}
            </Text>
          </Stack>
        </Flex>
        <Flex gap="14.375rem">
          <Stack gap="0.25rem">
            <Text fw={500} fz="0.875rem" c="#0F172A">
              Event layout
            </Text>
            <Text fw={400} fz="0.875rem" c="#64748B">
              {entityScheduledData?.eventLayout?.name ?? '-'}
            </Text>
          </Stack>
          <Stack gap="0.25rem">
            <Text fw={500} fz="0.875rem" c="#0F172A">
              Section
            </Text>
            <Text fw={400} fz="0.875rem" c="#64748B">
              {entityScheduledData?.eventLayoutSection?.information?.name ??
                '-'}
            </Text>
          </Stack>
        </Flex>
        <Stack gap="0.25rem">
          <Text fw={500} fz="0.875rem" c="#0F172A">
            Description
          </Text>
          <Text fw={400} fz="0.875rem" c="#64748B">
            {entityScheduledData?.description ?? '-'}
          </Text>
        </Stack>
      </Stack>
    </ScrollArea.Autosize>
  );
};
export default ScheduleInformationModal;
