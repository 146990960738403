import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DeviceService } from 'services/device';
import { UpdateDeviceStatusPayload } from 'types';

import { deviceBaseKey } from './baseKeys';

interface UpdateStatusDeviceProps {
  id: string;
  payload: UpdateDeviceStatusPayload;
}

export const useMutateStatusDevice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, payload }: UpdateStatusDeviceProps) =>
      (await DeviceService.updateDeviceStatus(id, payload)).data,
    onSuccess: () => {
      queryClient.invalidateQueries([deviceBaseKey]);
    },
  });
};
