import { Box, Flex, Text } from '@mantine/core';

type LineStepIndicatorProps = {
  activeStep: number;
  titles: string[];
};

const LineStepIndicator = ({ activeStep, titles }: LineStepIndicatorProps) => {
  return (
    <Box mt="-0.5rem">
      <Text mb="0.5rem" fz="1.25rem" fw={600} c="#0f172a" ta="left">
        {titles[activeStep - 1]}
      </Text>

      <Flex justify="space-between" gap="0.5rem">
        {titles.map((_, index) => (
          <Box
            key={index}
            h="0.375rem"
            bg={activeStep === index + 1 ? '#3b82f6' : '#f1f5f9'}
            flex={1}
            style={{
              borderRadius: '4px',
            }}
          ></Box>
        ))}
      </Flex>
    </Box>
  );
};

export default LineStepIndicator;
