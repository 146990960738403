import {
  FlexBox,
  FlexGrid,
  FlexGridItem,
  FormField,
  notification,
} from '@lanaco/lnc-react-ui';
import { useQueryClient } from '@tanstack/react-query';
import { DateInputGroup } from 'components/Input/DateInputGroup';
import { useDateField } from 'components/Input/hooks/useDateField';
import { useTimeField } from 'components/Input/hooks/useTimeField';
import { TimeInputGroup } from 'components/Input/TimeInput';
import SubmitModal from 'components/SubmitModal/SubmitModal';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { client } from 'services/api';
import { REGISTER_DEVICE } from 'utils/apiUrl';
import { createDateAndTime } from 'utils/createDateAndTime';

import { RegisterDeviceFormData } from './TimingLocations.interfaces';

type Props = {
  eventId?: string;
  layoutId: string;
  sectionId: string;
  gatingId: string;
  timezone: string;

  closeModal: () => void;
};

const RegisterDeviceForm = ({
  eventId,
  layoutId,
  sectionId,
  gatingId,
  timezone,
  closeModal,
}: Props) => {
  const [spinner, setSpinner] = useState(false);
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<RegisterDeviceFormData>();

  const onSubmit: SubmitHandler<RegisterDeviceFormData> = async (data) => {
    try {
      const dataObject = {
        dateTime: createDateAndTime(
          data?.registerDate as Date,
          data?.time as string,
          timezone,
        ),
      };

      const response = await client.post(
        REGISTER_DEVICE(eventId as string, layoutId, sectionId, gatingId),
        dataObject,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.code === 201) {
        setSpinner(false);
        closeModal();
        notification.success('Device successfully registered.');
      } else {
        setSpinner(false);
        notification.error(
          'Unable to register device. ' + response.data.message,
        );
      }
    } catch (error) {
      setSpinner(false);
      notification.error(error);
    } finally {
      queryClient.invalidateQueries(['eventTimingLocationGatings']);
    }
  };

  let [{ registerDate }, onDateChange] = useDateField({
    names: ['registerDate'],
    setValue,
    watch,
  });

  let [{ time }, onTimeChange] = useTimeField({
    names: ['time'],
    setValue,
    watch,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FlexGrid spacing={10}>
        <FlexGridItem XS={12} S={6}>
          <FormField
            text={errors.registerDate && errors.registerDate.message}
            color={errors.registerDate && 'danger'}
          >
            <DateInputGroup
              name="registerDate"
              label="Register date"
              placeholder="e.g. 01/01/2023"
              errors={errors}
              value={registerDate}
              onChange={onDateChange}
              register={register}
              withLargeErrorMessage={true}
            />
          </FormField>
        </FlexGridItem>
        <FlexGridItem XS={12} S={6}>
          <FormField
            text={errors.time && errors.time.message}
            color={errors.time && 'danger'}
          >
            <TimeInputGroup
              name="time"
              label="Register time"
              placeholder="e.g. 01/01/2023"
              errors={errors}
              value={time}
              onChange={onTimeChange}
              register={register}
            />
          </FormField>
        </FlexGridItem>

        <FlexGridItem col={12}>
          <FlexBox justifyContent={'End'}>
            <SubmitModal
              closeModal={closeModal}
              spinner={spinner}
              buttonText={'Save'}
            ></SubmitModal>
          </FlexBox>
        </FlexGridItem>
      </FlexGrid>
    </form>
  );
};

export default RegisterDeviceForm;
