import {
  TrashIcon,
  PencilSquareIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';
import { Button, Menu } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import FilterButtonGroup from 'components/FilterButtonGroup/FilterButtonGroup';
import { FilterButtonGroupType } from 'components/FilterButtonGroup/types';
import { StatProps } from 'components/Stat/types';
import Stats from 'components/Stats/Stats';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import TableFooter from 'components/TableFooter/TableFooter';
import { format } from 'date-fns';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Icons,
  useMantineReactTable,
} from 'mantine-react-table';
import { useEffect, useMemo, useRef, useState } from 'react';

import live from '../../../assets/icons/indicator-live.svg';
import offline from '../../../assets/icons/indicator-offline.svg';
import past from '../../../assets/icons/indicator-past.svg';
import { openModal } from '../ModalManager/ModalsManager';

import styles from './EntitySchedule.module.scss';
import { setScheduleModalContent } from './Modals/scheduleModalsConfig';
import { ScheduleTableType } from './types';

const heroIcons: Partial<MRT_Icons> = {
  IconArrowsSort: (props: any) => (
    <ArrowsUpDownIcon width={20} height={20} color="#64748B" />
  ),
  IconSortAscending: (props: any) => (
    <ArrowUpIcon width={20} height={20} color="#64748B" />
  ),
  IconSortDescending: (props: any) => (
    <ArrowDownIcon width={20} height={20} color="#64748B" />
  ),
};

const EntitySchedule = () => {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [rowsOnPage, setRowsOnPage] = useState<number>(5);
  const [currentRowCount, setCurrentRowCount] = useState<number>(5);
  const [limit, setLimit] = useState<number>(5);
  const [selectedFilter, setSelectedFilter] = useState<string>('All');
  const [filteredData, setFilteredData] = useState<ScheduleTableType[]>([]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const { height } = useViewportSize();
  const hasSelection = Object.keys(rowSelection).length > 0;

  const handleRowsOnPageChange = (newRows: number) => {
    setRowsOnPage(newRows);
    setLimit(newRows);
  };

  const handleFilterChange = (status: string) => {
    setSelectedFilter(status);
    const filtered = filterData(status);
    setFilteredData(filtered);
  };

  const filterData = (status: string) => {
    let filteredData: ScheduleTableType[];

    switch (status) {
      case 'Live':
        filteredData = data.filter((event) => {
          const startDate = new Date(event.start);
          const endDate = new Date(event.end);
          const now = new Date();
          return startDate <= now && endDate >= now;
        });
        break;
      case 'Upcoming':
        filteredData = data.filter(
          (event) => new Date(event.start) > new Date(),
        );
        break;
      case 'Past':
        filteredData = data.filter((event) => new Date(event.end) < new Date());
        break;
      default:
        filteredData = data;
        break;
    }

    return filteredData;
  };

  useEffect(() => {
    const filtered = filterData('All');
    setFilteredData(filtered);
  }, []);

  const stats: Array<StatProps> = [
    {
      title: 'Total live',
      amount: '95',
      total: `of 97 total`,
      color: '#F43F5E',
      percent: 12,
      active: true,
    },
    {
      title: 'Total upcoming',
      amount: '95',
      total: '',
      color: '#F43F5E',
      percent: 2.02,
      active: true,
    },
    {
      title: 'Total past',
      amount: '76',
      total: '',
      color: '#F43F5E',
      percent: 4.05,
      active: true,
    },
  ];

  const buttons: FilterButtonGroupType[] = [
    {
      text: 'All',
      active: selectedFilter === 'All',
      indicator: '',
      text1: '50',
      onClick: () => handleFilterChange('All'),
    },
    {
      text: 'Live',
      active: selectedFilter === 'Live',
      indicator: live,
      text1: '20',
      onClick: () => handleFilterChange('Live'),
    },
    {
      text: 'Upcoming',
      active: selectedFilter === 'Upcoming',
      indicator: offline,
      text1: '10',
      onClick: () => handleFilterChange('Upcoming'),
    },
    {
      text: 'Past',
      active: selectedFilter === 'Past',
      indicator: past,
      text1: '10',

      onClick: () => handleFilterChange('Past'),
    },
  ];

  const columns = useMemo<MRT_ColumnDef<ScheduleTableType>[]>(
    () => [
      {
        maxSize: 5,
        minSize: 2,
        id: 'statusIndicator',
        header: '',

        Cell: ({ row }) => {
          const { start, end } = row.original;

          return (
            <StatusIndicator
              height="2rem"
              startDate={new Date(start)}
              endDate={new Date(end)}
            />
          );
        },
      },
      {
        maxSize: 220,
        minSize: 180,
        accessorKey: 'title',
        header: 'Title',
        enableSorting: true,

        Cell: ({ cell, row }) => {
          return (
            <div
              className={`${styles.firstColumn} ${styles.cursorPointer}`}
              onClick={() => {}}
            >
              {cell.getValue() as React.ReactNode}
            </div>
          );
        },
      },
      {
        maxSize: 200,
        minSize: 180,
        accessorKey: 'host',
        header: 'Host',
        enableSorting: true,
      },
      {
        maxSize: 220,
        minSize: 180,
        accessorKey: 'timingLocation',
        header: 'Timing location',
        enableSorting: true,
      },
      {
        id: 'start',
        maxSize: 180,
        minSize: 150,
        accessorFn: ({ start }) =>
          format(new Date(start), 'dd MMM yyyy hh:mm a'),
        header: 'Start',
        enableSorting: true,
      },
      {
        id: 'end',
        maxSize: 180,
        minSize: 150,
        accessorFn: ({ end }) => format(new Date(end), 'dd MMM yyyy hh:mm a'),
        header: 'End',
        enableSorting: true,
      },

      {
        maxSize: 40,
        minSize: 30,
        id: 'actions',
        enableSorting: false,
        header: '',
        accessorKey: 'actions',

        Cell: () => (
          <Menu position="bottom-end">
            <Menu.Target>
              <button className={styles.actionMenuItem}>
                <EllipsisHorizontalIcon
                  color="#475569"
                  height={20}
                  width={20}
                />
              </button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item className={styles.menuItem} onClick={() => {}}>
                <PencilSquareIcon width={20} height={20} color="#3b82f6" />
                Edit
              </Menu.Item>
              <Menu.Item className={styles.menuItem} onClick={() => {}}>
                <TrashIcon width={20} height={20} color="#f43f5e" />
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
      },
    ],
    [],
  );

  const data: ScheduleTableType[] = [
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '21 Dec 2024 09:00 AM ',
      end: '29 Dec 2024 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '29 Dec 2024 09:00 AM ',
      end: '29 Dec 2027 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '29 Dec 2023 09:00 AM ',
      end: '10 Dec 2024 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '29 Dec 2025 09:00 AM ',
      end: '29 Dec 2026 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '21 Dec 2024 09:00 AM ',
      end: '29 Dec 2024 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '29 Dec 2024 09:00 AM ',
      end: '29 Dec 2027 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '29 Dec 2023 09:00 AM ',
      end: '10 Dec 2024 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '29 Dec 2025 09:00 AM ',
      end: '29 Dec 2026 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '21 Dec 2024 09:00 AM ',
      end: '29 Dec 2024 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '29 Dec 2024 09:00 AM ',
      end: '29 Dec 2027 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '29 Dec 2023 09:00 AM ',
      end: '10 Dec 2024 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '29 Dec 2025 09:00 AM ',
      end: '29 Dec 2026 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '21 Dec 2024 09:00 AM ',
      end: '29 Dec 2024 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '29 Dec 2024 09:00 AM ',
      end: '29 Dec 2027 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '29 Dec 2023 09:00 AM ',
      end: '10 Dec 2024 09:00 AM',
    },
    {
      title: 'Your Day at a Glance',
      host: 'Avery Sterling',
      timingLocation: 'BMW',
      start: '29 Dec 2025 09:00 AM ',
      end: '29 Dec 2026 09:00 AM',
    },
  ];

  const table = useMantineReactTable({
    columns,
    data: filteredData,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableColumnPinning: true,
    icons: heroIcons,
    enableSorting: true,
    manualPagination: true,
    enableRowSelection: true,
    enableStickyHeader: true,
    enablePagination: false,

    initialState: {
      columnPinning: {
        right: ['actions'],
        left: ['statusIndicator'],
      },
    },
    mantineSelectAllCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantineSelectCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantineTableContainerProps: {
      ref: scrollRef,
      style: {
        maxHeight: height > 750 ? height / 1.8 : height / 2.4,
      },
    },

    mantinePaperProps: {
      style: {
        '--mrt-pinned-column-background-color': '#ffffff',
        '--mrt-pinned-row-background-color': '#333',
        boxShadow: 'none',
        borderRadius: 0,
        marginTop: -2,
        height: height > 750 ? height / 1.8 : height / 2.4,
        position: 'sticky',
        zIndex: 10,
        bottom: 0,
      },
    },

    mantineTableBodyRowProps: ({ row }) => {
      return {
        className: row.getIsSelected() ? styles.selectedRow : '',
      };
    },

    state: {
      rowSelection,
    },

    onRowSelectionChange: setRowSelection,
  });

  return (
    <div className={styles.container}>
      <Stats stats={stats} />
      <div className={styles.tableContainer}>
        <FilterButtonGroup
          hasBorder={true}
          buttons={buttons}
          widthButtonGroup="100%"
          extraButton={
            hasSelection ? (
              <>
                <Button
                  bg="#ffffff"
                  bd="1px solid #e2e8f0"
                  c="#344155"
                  mr={12}
                  radius={8}
                  leftSection={<XMarkIcon height={20} width={20} />}
                  onClick={() => setRowSelection({})}
                >
                  Clear selection
                </Button>
                <Button
                  c="#f43f5e"
                  bg="#ffffff"
                  bd="1px solid #e2e8f0"
                  radius={8}
                  leftSection={
                    <TrashIcon
                      height="1.25rem"
                      width="1.25rem"
                      color="#f43f5e"
                    />
                  }
                >
                  Delete
                </Button>
              </>
            ) : (
              <Button
                bg="#3B82F6"
                radius="0.5rem"
                leftSection={<PlusIcon height="1.25rem" width="1.25rem" />}
                onClick={() =>
                  openModal({
                    title: 'Add new schedule',
                    subtitles: [
                      'Schedule information',
                      'Agenda details',
                      'Host information',
                    ],
                    isMultistep: true,
                    size: 640,
                    setModalContent: setScheduleModalContent,
                  })
                }
              >
                New schedule
              </Button>
            )
          }
        />

        <MantineReactTable table={table} />
        <TableFooter
          totalCount={50}
          currentCount={currentRowCount}
          rowsOptions={['2', '5', '7', '10']}
          rowsOnPage={rowsOnPage}
          onRowsOnPageChange={handleRowsOnPageChange}
        />
      </div>
    </div>
  );
};

export default EntitySchedule;
