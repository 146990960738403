import clsx from 'clsx';
import { Icon } from 'components/Icon/Icon';

import style from './TLModalHeader.module.scss';

export type TLModalHeaderProps = {
  /**
   * The name of the icon to display.
   * @link https://fontawesome.com/v5/search?m=free
   */
  icon?: string;
  subtitle?: string;
  title?: string;
  onClose?: () => void;
  className?: string;
  style?: React.CSSProperties;
};

export const TLModalHeader = ({
  icon,
  title,
  subtitle,
  className,
  onClose,
  ...props
}: TLModalHeaderProps) => (
  <div>
    <div className={clsx(style.header, className)} {...props}>
      <div className={style.titleContainer}>
        {icon && <Icon className={style.titleIcon} icon={icon} />}
        {title && <span className={style.title}>{title}</span>}
      </div>
      <Icon icon="times" className={style.icon} onClick={onClose} />
    </div>
    {subtitle && <span className={style.subtitle}>{subtitle}</span>}
  </div>
);
