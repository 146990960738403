import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventLayoutService } from 'services/event/layout/EventLayoutService';

import { singleEventStatisticsBaseKey } from '../statistics/baseKeys';

import { eventLayoutBaseKey } from './eventLayoutBaseKey';

interface DeleteEventLayoutProps {
  eventId: string;
  eventLayoutId: string;
  layoutIds: string[];
}

export const useDeleteEventLayout = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      eventId,
      eventLayoutId,
      layoutIds,
    }: DeleteEventLayoutProps) =>
      eventLayoutId != null
        ? await EventLayoutService.deleteEventLayout(eventId, eventLayoutId)
        : await EventLayoutService.deleteEventLayouts(eventId, layoutIds),
    onSuccess: () => {
      queryClient.invalidateQueries([eventLayoutBaseKey]);
      queryClient.invalidateQueries([singleEventStatisticsBaseKey]);
    },
  });
};
