import { notification } from '@lanaco/lnc-react-ui';
import { RowSelectionState, createColumnHelper } from '@tanstack/table-core';
import { TLNeutralButton, TLSecondaryLoadingButton } from 'components/Button';
import { InputSearch } from 'components/Input';
import { TLModalFooter } from 'components/Modal';
import { NotAvailable } from 'components/NotAvailable';
import TanStackTable from 'components/TanStackTable/TanStackTable';
import { useAddEventTimingLocationAssignableDevices } from 'hooks/event/timing-location/useAddEventTimingLocationAssignableDevices';
import { useFetchEventTimingLocationAssignableDevices } from 'hooks/event/timing-location/useFetchEventTimingLocationAssignableDevices';
import { useState } from 'react';
import { getErrorMsg } from 'utils/getErrorMsg';

import style from '../Events.module.css';

import {
  CreateEventTimingLocationsDevices,
  EventTimingLocationsDevicesTableData,
} from './TimingLocations.interfaces';

type Props = {
  eventId?: string;
  layoutId: string;
  sectionId: string;

  closeModal: () => void;
};

const columnHelper = createColumnHelper<EventTimingLocationsDevicesTableData>();
const ROW_LIMIT = 10;

const AddDevicesTimingLocationForm = ({
  eventId,
  layoutId,
  sectionId,
  closeModal,
}: Props) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});

  const { data, isFetching } = useFetchEventTimingLocationAssignableDevices({
    eventId: eventId as string,
    layoutId: layoutId,
    sectionId: sectionId,
    offset: (page - 1) * ROW_LIMIT,
    row_limit: ROW_LIMIT,
    filter: search,
  });

  const { _route, data: assignableDevices } = data || {
    _route: { returned: 0, total: 0 },
    data: [],
  };

  const selectedIds = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }

      return acc;
    },
    [],
  );

  const mutation = useAddEventTimingLocationAssignableDevices();
  const noDevicesAvailable =
    assignableDevices?.length === 0 || assignableDevices == null;

  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  const tableColumns = [
    columnHelper.accessor('macName', {
      header: 'Device',
    }),
    columnHelper.accessor('macAddress', {
      header: 'MAC address',
    }),
  ];

  const selectedDevices = `${selectedIds.length} ${
    selectedIds.length > 1 ? 'Devices' : 'Device'
  }`;

  const onAddHandler = async (e) => {
    const selectedObject = Object.entries(selectedRows).reduce<
      CreateEventTimingLocationsDevices[]
    >((acc, [key, value]) => {
      if (value) {
        const item: CreateEventTimingLocationsDevices = {
          deviceId: key,
          registerDateTime: undefined,
          unregisterDateTime: undefined,
        };

        acc.push(item);
      }
      return acc;
    }, []);

    e.preventDefault();
    setSelectedRows({});
    mutation.mutate(
      {
        eventId: eventId as string,
        layoutId: layoutId as string,
        sectionId: sectionId as string,
        filter: '',
        payload: selectedObject,
      },
      {
        onSuccess: () => {
          notification.success(`${selectedDevices} added to event`);
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
      },
    );
  };

  return (
    <div className={style.add_event_devices}>
      <InputSearch
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
      ></InputSearch>
      <form onSubmit={onAddHandler}>
        {noDevicesAvailable ? (
          <NotAvailable message="Oops, no devices available at the moment." />
        ) : (
          <TanStackTable
            getRowId={(row) => row._id}
            selectedRows={selectedRows}
            onRowSelectionChange={setSelectedRows}
            columns={tableColumns}
            pageIndex={page}
            pageSize={ROW_LIMIT}
            totalRows={_route.total}
            pageCount={Math.ceil(_route.total / ROW_LIMIT)}
            data={assignableDevices || []}
            onPageChange={handlePageChange}
            isLoading={isFetching}
            rowClassName={(row) =>
              row.status === 'disabled' ? 'text-gray-400' : ''
            }
            enableRowSelection
            showHeader
          />
        )}
        <TLModalFooter
          style={{ paddingTop: '2rem' }}
          cancelButton={
            <TLNeutralButton onClick={closeModal}>Cancel</TLNeutralButton>
          }
          actionButton={
            <TLSecondaryLoadingButton
              isLoading={false}
              disabled={selectedIds?.length === 0}
              type="submit"
            >
              Add
            </TLSecondaryLoadingButton>
          }
          moreInfo={
            selectedDevices.length > 0 ? `${selectedDevices} selected` : ''
          }
        />
      </form>
    </div>
  );
};

export default AddDevicesTimingLocationForm;
