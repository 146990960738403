import { Box, Flex, Image, rgba } from '@mantine/core';
import { LayoutLibraryCardProps } from 'pages/Entities/Hub/types';

const LayoutLibraryCard = ({
  title,
  image,
  onClick,
}: LayoutLibraryCardProps) => {
  return (
    <Box
      pos="relative"
      style={{ borderRadius: '0.5rem', overflow: 'hidden', cursor: 'pointer' }}
      onClick={onClick}
    >
      <Image src={image} h="8.5rem" w="9.5rem" />

      <Flex
        h="2.25rem"
        w="100%"
        bg={rgba('#334155', 0.7)}
        c="#ffffff"
        fz="0.75rem"
        pos="absolute"
        bottom={0}
        justify="center"
        align="center"
      >
        {title}
      </Flex>
    </Box>
  );
};

export default LayoutLibraryCard;
