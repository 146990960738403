import { notification } from '@lanaco/lnc-react-ui';
import PromptModal from 'components/PromptModal/PromptModal';
import { useMutateStatusTag } from 'hooks/tag';
import { getErrorMsg } from 'utils/getErrorMsg';

import { TagTableData } from '../interfaces';

interface ToggleEnabledTagModalProps {
  id: string;
  name?: string;
  status?: string;
  closeModal: () => void;
  onTagEdit: (device: TagTableData | null) => void;
}

export const ToggleEnabledTagModal = ({
  id,
  status,
  name,
  closeModal,
  onTagEdit,
}: ToggleEnabledTagModalProps) => {
  const { isLoading, mutate } = useMutateStatusTag();
  const hideToggleEnabledModal = () => {
    closeModal();
    onTagEdit(null);
  };

  const toggleTagEnabled = async () => {
    mutate(
      {
        id,
        payload: { status: status === 'active' ? 'disabled' : 'active' },
      },
      {
        onSuccess: (data) => {
          if (data.status !== status) {
            const msg = `Tag successfully ${
              status === 'active' ? 'disabled' : 'enabled'
            }`;
            notification.success(msg);
          } else {
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          hideToggleEnabledModal();
        },
      },
    );
  };

  return (
    <PromptModal
      title={status === 'active' ? 'Disable tag?' : 'Enable tag'}
      isLoading={isLoading}
      confirmButtonName={status === 'active' ? 'Disable' : 'Enable'}
      icon="power-off"
      warningMessage={
        <>
          Tag {name} will be {status === 'active' ? 'disabled' : 'enabled'}.
          <br />
          You can {status === 'active' ? 'enable' : 'disable'} it again at any
          time.
        </>
      }
      onConfirm={toggleTagEnabled}
      onCancel={hideToggleEnabledModal}
    />
  );
};
