import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Event } from 'pages/Events/interfaces';
import { client } from 'services/api';
import { Response } from 'types/response';
import { GET_ALL_EVENTS } from 'utils/apiUrl';
import { stringToDate } from 'utils/stringToDate';

import { eventBaseKey } from './baseKey';

interface useFetchEventProps {
  limit: number;
  offset: number;
  status?: string | null;
  filter?: string | null;
}

export const useFetchEvents = ({
  limit: row_limit,
  offset,
  status,
  filter,
}: useFetchEventProps) => {
  const queryClient = useQueryClient();
  const queryResult = useQuery<Response<Event[]>, AxiosError>({
    queryKey: [eventBaseKey, row_limit, offset, status, filter],
    queryFn: async () => {
      const retVal = await client.get<Response<Event[]>>(
        GET_ALL_EVENTS(row_limit, offset, status, filter),
      );

      const data = retVal.data.data.map((object) => {
        queryClient.setQueryData<Event>([eventBaseKey, object._id], object);
        return stringToDate({ object, key: ['startDate', 'finishDate'] });
      });

      return {
        ...retVal.data,
        data,
      };
    },
  });

  return queryResult;
};
