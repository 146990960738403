import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import { DELETE_EVENT_LAYOUT, DELETE_EVENT_LAYOUTS } from 'utils/apiUrl';

export class EventLayoutService extends ResponseInterceptor {
  static async deleteEventLayout(
    eventId: string,
    eventLayoutId: string,
  ): Promise<APIAxiosResponse<string>> {
    const response = await client.delete(
      DELETE_EVENT_LAYOUT(eventId, eventLayoutId),
    );
    return this.handleResponse(response);
  }

  static async deleteEventLayouts(
    eventId: string,
    ids: string[],
  ): Promise<APIAxiosResponse<string>> {
    const response = await client.delete(DELETE_EVENT_LAYOUTS(eventId), {
      data: { ids },
    });
    return this.handleResponse(response);
  }
}
