import { useQueryClient } from '@tanstack/react-query';
import { Event } from 'pages/Events/interfaces';

import { eventKeys } from '../../eventKeys';

export const useGetEventDetails = (eventId?: string) => {
  const queryClient = useQueryClient();

  return queryClient.getQueryData<Event>(eventKeys.event(eventId));
};
