import { Flex, Grid, Stack, Text } from '@mantine/core';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';

import styles from './ScheduleCard.module.scss';
import { ScheduleCardProps } from './types';

const ScheduleCard = ({
  startDate,
  startTime,
  text,
  name,
  endDate,
  endTime,
  level,
  type,
  formattedDate,
  onClick,
}: ScheduleCardProps) => {
  const validStartDate = startDate ? new Date(startDate) : new Date();
  const validEndDate = endDate ? new Date(endDate) : new Date();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }
  };
  return (
    <Grid
      className={styles.card}
      onClick={handleClick}
      p={8}
      justify="space-between"
    >
      <Grid.Col span={7}>
        <Flex gap="0.5rem" align="center">
          <StatusIndicator
            startDate={validStartDate}
            endDate={validEndDate}
            startTime={startTime}
            endTime={endTime}
            height="4.25rem"
          />

          <Stack gap="0.25rem">
            <Text c="#64748B" fz="0.875rem">
              {startDate && startDate !== formattedDate ? startDate : ''}{' '}
              {startTime && startDate && startDate !== formattedDate
                ? `- ${startTime}`
                : startTime}
            </Text>
            <Text c="#0F172A" fz="0.875rem">
              {text}
            </Text>
            <Text c="#0F172A" fz="0.875rem">
              {name}
            </Text>
          </Stack>
        </Flex>
      </Grid.Col>
      <Grid.Col span={5}>
        <Stack ta="right" gap="0.25rem">
          {endDate && endDate !== formattedDate ? (
            <Text c="#64748B" fz="0.875rem">
              {endTime} {endDate && `- ${endDate}`}
            </Text>
          ) : (
            endTime && (
              <Text c="#64748B" fz="0.875rem">
                {endTime}
              </Text>
            )
          )}
          {level && (
            <Text c="#0F172A" fz="0.875rem">
              {level}
            </Text>
          )}
          {type && (
            <Text c="#0F172A" fz="0.875rem">
              {type}
            </Text>
          )}
        </Stack>
      </Grid.Col>
    </Grid>
  );
};

export default ScheduleCard;
