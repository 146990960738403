import { Flex, Text } from '@mantine/core';

import { CardTitleProps } from '../../types';

const CardTitle = ({ Icon, title, code }: CardTitleProps) => {
  return (
    <Flex align="center">
      {Icon && <Icon width={20} height={20} color="var(--gray-400)" />}
      <Text c="var(--gray-900)" fz={14}>
        {title}{' '}
        {code && <span style={{ color: 'var(--gray-500)' }}>({code})</span>}
      </Text>
    </Flex>
  );
};

export default CardTitle;
