import {
  Button,
  DropdownItem,
  DropdownMenu,
  FlexGrid,
  FlexGridItem,
  Separator,
} from '@lanaco/lnc-react-ui';
import {
  BackgroundImage,
  Badge,
  Box,
  Flex,
  Group,
  Overlay,
  Paper,
  Stack,
  Text,
  alpha,
} from '@mantine/core';
import { IconCalendarClock } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { ModalSize, TLModal } from 'components/Modal';
import QR from 'components/QR/QR';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { useFetchSingleEventStatistics } from 'hooks/event/statistics/useFetchSingleEventStatistics';
import { eventKeys } from 'hooks/eventKeys';
import { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useModal } from 'react-modal-hook';
import MultiProgress from 'react-multi-progress';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatDateTimeFull } from 'utils/dateFormatters';

import { ReactComponent as Indicator } from '../../../assets/icons/legend-indicator.svg';
import arrowTopRightOnSquare from '../../../assets/icons/solid/arrow-top-right-on-square.svg';
import buildingOffice from '../../../assets/icons/solid/building-office-2.svg';
import clock from '../../../assets/icons/solid/clock.svg';
import devicesPlus from '../../../assets/icons/solid/device-plus.svg';
import devices from '../../../assets/icons/solid/device.svg';
import { ReactComponent as Icon } from '../../../assets/icons/solid/ellipsis-horizontal.svg';
import globeAmericas from '../../../assets/icons/solid/globe-americas.svg';
import timingLocation from '../../../assets/icons/solid/map-pin.svg';
import { ReactComponent as Pencil } from '../../../assets/icons/solid/pencil.svg';
import { ReactComponent as ViewQR } from '../../../assets/icons/solid/qr-code.svg';
import layout from '../../../assets/icons/solid/square-3-stack-3d.svg';
import tagsPlus from '../../../assets/icons/solid/tag-plus.svg';
import tags from '../../../assets/icons/solid/tag.svg';
import trash from '../../../assets/icons/solid/trash.svg';
import participants from '../../../assets/icons/solid/user-group.svg';
import usersPlus from '../../../assets/icons/solid/user-plus.svg';
import cover from '../../../assets/images/Cover.jpg';
import EventForm from '../EventForm';
import style from '../Events.module.css';
import { DeleteEventModal } from '../Home/Event/DeleteEventModal';
import { Event } from '../interfaces';

import EventMetadata from './EventMetadata';

ChartJS.register(ArcElement, Tooltip, Legend);

const EventAbout = () => {
  const now = new Date();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [eventToEdit, setEventToEdit] = useState<Event | null>(null);
  const event2 = queryClient.getQueryData<Event>(eventKeys.event(id));

  const event = {
    ...event2,
    startDate: new Date(event2?.startDate!),
    finishDate: new Date(event2?.finishDate!),
  };

  const [showJSONDataModal, hideJSONDataModal] = useModal(
    () => (
      <TLModal title="Metadata" onClose={hideJSONDataModal}>
        <EventMetadata data={event?.metadata}></EventMetadata>
      </TLModal>
    ),
    [],
  );

  const [showQRModal, hideQRModal] = useModal(
    () => (
      <TLModal
        size={ModalSize.XS}
        title={event.name}
        onClose={hideQRModal}
        className={style.qrModal}
      >
        <QR value={event?._id as string} qrId={event?._id as string} />
      </TLModal>
    ),
    [],
  );

  const [showDeleteEventModal, _hideDeleteEventModal] = useModal(() => {
    const hideDeleteEventModal = () => {
      _hideDeleteEventModal();
    };

    return (
      <DeleteEventModal
        id={event!._id as string}
        name={event!.name}
        closeModal={hideDeleteEventModal}
        onEventEdit={(e) => {}}
        redirectToHome={true}
      />
    );
  }, []);

  const [showEditEventModal, hideEditEventModal] = useModal(
    () => (
      <TLModal hideHeader onClose={hideEditEventModal}>
        <EventForm
          data={event as Event}
          isEdit={true}
          onClose={hideEditEventModal}
          //onEditSubmitHandler={(data) => console.log(data)}
          fromEventAbout={true}
        ></EventForm>
      </TLModal>
    ),
    [event],
  );

  const { data: singleEventStatistics } = useFetchSingleEventStatistics({
    eventId: id as string,
  });

  const ageKeys = singleEventStatistics
    ? Object.keys(singleEventStatistics.participants.age)
    : [];

  let totalParticipants = 0;
  const ageValues = singleEventStatistics
    ? Object.values(singleEventStatistics.participants.age)
    : [];
  ageValues.forEach((value) => {
    totalParticipants += value;
  });

  const colors = [
    '#RE64765',
    '#94A3B8',
    '#E2E8F0',
    '#93C5FD',
    '#3B82F6',
    '#06B6D4',
    '#0E7490',
    '#164E63',
  ];

  const onlineColor = '#10B981';
  const offlineColor = '#E2E8F0';
  const busyColor = '#EAB308';
  const idleColor = '#EAB308';

  const usualColor = '#10B981';

  const data = {
    labels: ageKeys,
    datasets: [
      {
        label: '# of Participants',
        data: ageKeys.map(
          (key) => singleEventStatistics?.participants.age[key],
        ),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
        barThickness: 20,
      },
    ],
  };

  const calculatePercentage = (maxNumber, number) => {
    if (number !== 0) {
      return Number(((number * 100) / maxNumber).toFixed(2));
    } else {
      return 0;
    }
  };

  return (
    <div className={style.event_about_wrapper}>
      <FlexGrid>
        <FlexGridItem XS={12} L={8}>
          <div className={style.content_column1}>
            <div
              className={
                event?.heroImage?.smallSource
                  ? style.cover
                  : style.cover_without_image
              }
            >
              {event?.heroImage?.smallSource && (
                <img
                  src={event?.heroImage?.smallSource}
                  draggable={false}
                  srcSet={`${event?.heroImage?.smallSource} 300w, ${event?.heroImage?.mediumSource} 768w, ${event?.heroImage?.largeSource} 1280w`}
                  sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
                  alt="Event Cover"
                  className={style.cover_image}
                ></img>
              )}
              <div className={style.cover_actions}>
                <Overlay
                  top="-10px"
                  zIndex={1}
                  bg="rgba(0,0,0,0.2)"
                  // gradient="linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.1) 100%)"
                />
                <div className={style.cover_info}>
                  <div className={style.event_duration_status}>
                    {isBefore(now, event.finishDate) &&
                      isAfter(now, event.startDate) && (
                        <Badge flex="1 0 auto" p="xs" bg="red">
                          LIVE
                        </Badge>
                      )}
                    <div
                      className={
                        event?.heroImage?.smallSource
                          ? style.event_duration
                          : style.event_duration_without_image
                      }
                    >
                      <Box display="inline-block">
                        {formatDateTimeFull(
                          event?.startDate!.toISOString(),
                          event.timezone!,
                        )}
                      </Box>
                      {' - '}
                      <Box display="inline-block">
                        {formatDateTimeFull(
                          event?.finishDate!.toISOString(),
                          event.timezone!,
                        )}
                      </Box>
                    </div>
                  </div>

                  <div
                    className={
                      event?.heroImage?.smallSource
                        ? style.event_name
                        : style.event_name_without_image
                    }
                  >
                    {event?.name}
                  </div>
                </div>
                <div className={style.edit_delete}>
                  <Button
                    className={style.actionButton}
                    btnType={'filled'}
                    iconStyle={'regular'}
                    color={'gray'}
                    onClick={showQRModal}
                  >
                    <ViewQR fill="#94A3B8" />
                    View QR
                  </Button>
                  <Button
                    className={style.actionButton}
                    btnType={'filled'}
                    iconStyle={'regular'}
                    onClick={showEditEventModal}
                    color={'gray'}
                  >
                    <Pencil fill="#94A3B8" />
                    Edit event
                  </Button>

                  <DropdownMenu
                    control={
                      <Button
                        className={style.actionButton}
                        btnType={'filled'}
                        iconStyle={'regular'}
                      >
                        <Icon fill="#0F172A" />
                      </Button>
                    }
                    closeOnItemSelect={true}
                    portalTarget={document.body}
                    className={style.dropdownMenu}
                    color={'gray'}
                  >
                    <>
                      <DropdownItem
                        key={1}
                        onClick={() => navigate(`/event/${id}/devices`)}
                        name="addDevices"
                        className={style.dropdownItem}
                        title="Add device"
                      >
                        <img
                          src={devicesPlus}
                          alt="Add device"
                          width={'17px'}
                          height={'17px'}
                        ></img>
                        <span>Add devices</span>
                      </DropdownItem>
                      <DropdownItem
                        key={2}
                        name="addTags"
                        onClick={() => navigate(`/event/${id}/tags`)}
                        className={style.dropdownItem}
                        title="Add tag"
                      >
                        <img
                          src={tagsPlus}
                          alt="Add device"
                          width={'17px'}
                          height={'17px'}
                        ></img>
                        <span>Add tags</span>
                      </DropdownItem>
                      <DropdownItem
                        key={3}
                        title="Add participants"
                        name="addParticipants"
                        onClick={() => navigate(`/event/${id}/participants`)}
                        className={style.dropdownItem}
                      >
                        <img
                          src={usersPlus}
                          alt="Add participants"
                          width={'17px'}
                          height={'17px'}
                        ></img>
                        <span>Add participants</span>
                      </DropdownItem>

                      <Separator className={style.separator} />
                      <DropdownItem
                        key={4}
                        className={
                          style.dropdownItem + ' ' + style.dropdownItemDelete
                        }
                        name="delete"
                        title="Delete"
                        renderComponent={null}
                        onClick={showDeleteEventModal}
                      >
                        <img
                          className={style.delete}
                          src={trash}
                          alt="Trash"
                          width={'17px'}
                          height={'17px'}
                        ></img>
                        <span className={style.delete}>Delete event</span>
                      </DropdownItem>
                    </>
                  </DropdownMenu>
                </div>
              </div>
            </div>
            <Stack
              className={style.column1_overview}
              p="lg"
              bg="white"
              w="100%"
            >
              <Text component="h2" fw={600}>
                Overview
              </Text>
              <Stack>
                <div className={style.column1_overview_content_description}>
                  {event?.description}
                </div>
                <div className={style.column1_overview_content_data}>
                  <div className={style.column1_overview_content_data_item}>
                    <img src={clock} alt=""></img>
                    <div>
                      {formatDateTimeFull(
                        event?.startDate!.toISOString(),
                        event.timezone!,
                      )}
                      {' - '}
                      {formatDateTimeFull(
                        event?.finishDate!.toISOString(),
                        event.timezone!,
                      )}
                    </div>
                  </div>
                  {event.timezone && (
                    <Group
                      gap="sm"
                      className={style.column1_overview_content_data_item}
                    >
                      <IconCalendarClock color="#94A3B8" />
                      <span>{event.timezone}</span>
                    </Group>
                  )}
                  <div className={style.column1_overview_content_data_item}>
                    <img src={timingLocation} alt=""></img>
                    <div>{event?.location?.description}</div>
                  </div>
                  <div className={style.column1_overview_content_data_item}>
                    <img src={buildingOffice} alt=""></img>
                    <div>Client: {event.companyId?.name}</div>
                  </div>
                  <div className={style.column1_overview_content_data_item}>
                    <img src={arrowTopRightOnSquare} alt=""></img>
                    <div>
                      <Link
                        to={{ pathname: `${'//' + event?.eventUrl}` }}
                        target={'_blank'}
                      >
                        {event?.eventUrl}
                      </Link>
                    </div>
                  </div>
                  <div className={style.column1_overview_content_data_item}>
                    <img src={globeAmericas} alt=""></img>
                    {event?.socials && (
                      <Group gap="xs">
                        {event.socials.facebook && (
                          <Link
                            to={{
                              pathname: `${'//' + event?.socials.facebook}`,
                            }}
                            target={'_blank'}
                          >
                            Facebook
                          </Link>
                        )}
                        {event.socials.instagram && (
                          <Link
                            to={{
                              pathname: `${'//' + event?.socials.instagram}`,
                            }}
                            target={'_blank'}
                          >
                            Instagram
                          </Link>
                        )}
                        {event.socials.linkedin && (
                          <Link
                            to={{
                              pathname: `${'//' + event?.socials.linkedin}`,
                            }}
                            target={'_blank'}
                          >
                            LinkedIn
                          </Link>
                        )}
                        {event.socials.tikTok && (
                          <Link
                            to={{ pathname: `${'//' + event?.socials.tikTok}` }}
                            target={'_blank'}
                          >
                            TikTok
                          </Link>
                        )}
                        {event.socials.twitter && (
                          <Link
                            to={{
                              pathname: `${'//' + event?.socials.twitter}`,
                            }}
                            target={'_blank'}
                          >
                            Twitter
                          </Link>
                        )}
                        {event.socials.youtube && (
                          <Link
                            to={{
                              pathname: `${'//' + event?.socials.youtube}`,
                            }}
                            target={'_blank'}
                          >
                            YouTube
                          </Link>
                        )}
                      </Group>
                    )}
                  </div>
                </div>
              </Stack>
            </Stack>
            <div className={style.column1_registration}>
              <div className={style.column1_overview_header}>Registration</div>
              <div className={style.text_align}>
                <div>
                  <FlexGrid>
                    <FlexGridItem XS={12} L={2}>
                      <div>Link</div>
                    </FlexGridItem>
                    <FlexGridItem XS={12} L={10}>
                      <div>
                        {' '}
                        <Link
                          to={{
                            pathname: `${'//' + event?.registration?.url}`,
                          }}
                          target={'_blank'}
                        >
                          {event?.registration?.url}
                        </Link>
                      </div>
                    </FlexGridItem>
                  </FlexGrid>

                  <FlexGrid>
                    <FlexGridItem XS={12} L={2}>
                      <div>Duration</div>
                    </FlexGridItem>
                    {event?.registration && (
                      <FlexGridItem XS={12} L={10}>
                        <div>
                          {formatDateTimeFull(
                            event?.registration?.startDate!,
                            event.timezone!,
                          )}
                          {event?.registration?.finishDate! && ' - '}
                          {formatDateTimeFull(
                            event?.registration?.finishDate!,
                            event.timezone!,
                          )}
                        </div>
                      </FlexGridItem>
                    )}
                  </FlexGrid>

                  <FlexGrid>
                    <FlexGridItem XS={12} L={2}>
                      <div>Metadata</div>
                    </FlexGridItem>
                    <FlexGridItem XS={12} L={10}>
                      <Link to="" onClick={showJSONDataModal}>
                        View JSON data
                      </Link>
                    </FlexGridItem>
                  </FlexGrid>
                </div>
              </div>
            </div>
            <div className={style.column1_contact_info}>
              <div className={style.column1_overview_header}>Contact info</div>
              <div className={style.text_align}>
                <FlexGrid>
                  <FlexGridItem XS={12} L={3}>
                    <div>Phone number</div>
                  </FlexGridItem>
                  <FlexGridItem XS={12} L={9}>
                    <div>{event?.contactPerson?.phoneNumber}</div>
                  </FlexGridItem>
                </FlexGrid>

                <FlexGrid>
                  <FlexGridItem XS={12} L={3}>
                    <div>Email</div>
                  </FlexGridItem>
                  <FlexGridItem XS={12} L={9}>
                    <div>{event?.contactPerson?.email}</div>
                  </FlexGridItem>
                </FlexGrid>

                <FlexGrid>
                  <FlexGridItem XS={12} L={3}>
                    <div>Contact person(s)</div>
                  </FlexGridItem>
                  <FlexGridItem XS={12} L={9}>
                    <div>{event?.contactPerson?.name}</div>
                  </FlexGridItem>
                </FlexGrid>
              </div>
            </div>
          </div>
        </FlexGridItem>
        <FlexGridItem XS={12} L={4}>
          <div className={style.content_column2}>
            <div className={style.column2_tag}>
              <div className={style.column2_tag_primary}>
                <div className={style.column2_tag_primary_header}>
                  <div className={style.column2_tag_primary_header_title}>
                    <img src={layout} alt=""></img> Layout
                  </div>
                  <div className={style.column2_tag_primary_header_number}>
                    {singleEventStatistics?.eventLayouts.total}
                  </div>
                </div>
              </div>
            </div>

            <div className={style.column2_tag}>
              <div className={style.column2_tag_primary}>
                <div className={style.column2_tag_primary_header}>
                  <div className={style.column2_tag_primary_header_title}>
                    <img src={timingLocation} alt=""></img> Timing locations
                  </div>
                  <div className={style.column2_tag_primary_header_number}>
                    {singleEventStatistics?.eventLayoutSectionStatuses.total}
                  </div>
                </div>
              </div>
              <div className={style.column2_tag_secondary}>
                <div className={style.column2_tag_secondary_title}>
                  <div>
                    {' '}
                    {
                      singleEventStatistics?.eventLayoutSectionStatuses.online
                    }{' '}
                    online /{' '}
                    {singleEventStatistics?.eventLayoutSectionStatuses.idle}{' '}
                    idle
                    {/* / 4 heavy */}
                  </div>
                  <div>
                    {singleEventStatistics?.eventLayoutSectionStatuses.offline}{' '}
                    offline
                  </div>
                </div>

                <MultiProgress
                  elements={[
                    {
                      value: calculatePercentage(
                        singleEventStatistics?.eventLayoutSectionStatuses.total,
                        singleEventStatistics?.eventLayoutSectionStatuses
                          .online,
                      ),
                      color: usualColor,
                    },
                    {
                      value: calculatePercentage(
                        singleEventStatistics?.eventLayoutSectionStatuses.total,
                        singleEventStatistics?.eventLayoutSectionStatuses.idle,
                      ),
                      color: busyColor,
                    },
                    // {
                    //   value: 4,
                    //   color: heavyColor,
                    // },
                    {
                      value: calculatePercentage(
                        singleEventStatistics?.eventLayoutSectionStatuses.total,
                        singleEventStatistics?.eventLayoutSectionStatuses
                          .offline,
                      ),
                      color: offlineColor,
                    },
                  ]}
                />
              </div>
            </div>
            <div className={style.column2_tag}>
              <div className={style.column2_tag_primary}>
                <div className={style.column2_tag_primary_header}>
                  <div className={style.column2_tag_primary_header_title}>
                    <img src={devices} alt=""></img> Devices
                  </div>
                  <div className={style.column2_tag_primary_header_number}>
                    {singleEventStatistics?.devices.total}
                  </div>
                </div>
              </div>
              <div className={style.column2_tag_secondary}>
                <div className={style.column2_tag_secondary_title}>
                  <div>
                    {' '}
                    {singleEventStatistics?.devices.online} online /{' '}
                    {singleEventStatistics?.devices.idle} idle
                  </div>
                  <div>{singleEventStatistics?.devices.offline} offline</div>
                </div>

                <MultiProgress
                  elements={[
                    {
                      value: calculatePercentage(
                        singleEventStatistics?.devices.total,
                        singleEventStatistics?.devices.online,
                      ),
                      color: onlineColor,
                    },
                    {
                      value: calculatePercentage(
                        singleEventStatistics?.devices.total,
                        singleEventStatistics?.devices.idle,
                      ),
                      color: idleColor,
                    },
                    {
                      value: calculatePercentage(
                        singleEventStatistics?.devices.total,
                        singleEventStatistics?.devices.offline,
                      ),
                      color: offlineColor,
                    },
                  ]}
                />
              </div>
            </div>
            <div className={style.column2_tag}>
              <div className={style.column2_tag_primary}>
                <div className={style.column2_tag_primary_header}>
                  <div className={style.column2_tag_primary_header_title}>
                    <img src={tags} alt=""></img> Tags
                  </div>
                  <div className={style.column2_tag_primary_header_number}>
                    {singleEventStatistics?.tags.total}
                  </div>
                </div>
              </div>
              <div className={style.column2_tag_secondary}>
                <div className={style.column2_tag_secondary_title}>
                  <div>{singleEventStatistics?.tags.online} online</div>
                  <div>{singleEventStatistics?.tags.offline} offline</div>
                </div>

                <MultiProgress
                  elements={[
                    {
                      value: calculatePercentage(
                        singleEventStatistics?.tags.total,
                        singleEventStatistics?.tags.online,
                      ),
                      color: onlineColor,
                    },
                    {
                      value: calculatePercentage(
                        singleEventStatistics?.tags.total,
                        singleEventStatistics?.tags.offline,
                      ),
                      color: offlineColor,
                    },
                  ]}
                />
              </div>
            </div>
            <div className={style.column2_tag}>
              <div className={style.column2_tag_primary}>
                <div className={style.column2_tag_primary_header}>
                  <div className={style.column2_tag_primary_header_title}>
                    <img src={participants} alt=""></img> Participants
                  </div>
                  <div className={style.column2_tag_primary_header_number}>
                    {totalParticipants}
                  </div>
                </div>
              </div>

              <Flex
                direction={{ base: 'column', sm: 'row' }}
                className={style.participant_legend}
                p="sm"
              >
                <div className={style.doughnut}>
                  <Doughnut
                    data={data}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                          position: 'right',
                          //rtl: true,
                          labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            padding: 20,
                            font: {
                              size: 14,
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>

                <div className={style.participant_legend}>
                  <div>
                    {ageKeys
                      .map((key, index) => (
                        <div
                          className={style.participant_legend_item}
                          key={`participant_legend_${index}`}
                        >
                          <div>
                            <Indicator fill={colors[index]} />
                          </div>
                          <div>{key}</div>
                        </div>
                      ))
                      .sort()
                      .reverse()}
                  </div>
                  <div>
                    {ageKeys
                      .map((key) => (
                        <div>
                          {calculatePercentage(
                            totalParticipants,
                            singleEventStatistics?.participants.age[key],
                          )}
                          %
                        </div>
                      ))
                      .sort()
                      .reverse()}
                  </div>
                </div>
              </Flex>
            </div>
          </div>
        </FlexGridItem>
      </FlexGrid>
    </div>
  );
};

export default EventAbout;
