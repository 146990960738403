import { FlexGrid, FlexGridItem } from '@lanaco/lnc-react-ui';
import { RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import TanStackTable from 'components/TanStackTable/TanStackTable';
import { format } from 'date-fns';
import {
  useFetchEventStatistics,
  useFetchParticipantStatistics,
} from 'hooks/event/statistics';
import {
  Client,
  useFetchClientStatistics,
} from 'hooks/event/statistics/useFetchClientsStatistics';
import { useFetchDeviceStatistics } from 'hooks/event/statistics/useFetchDeviceStatistics';
import { useFetchTagStatistics } from 'hooks/event/statistics/useFetchTagStatistics';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import MultiProgress from 'react-multi-progress';
import { Navigate } from 'react-router';
import { getSession } from 'services/auth';
import { requestForToken } from 'services/firebase';

import clients from '../../assets/icons/building.svg';
import idle from '../../assets/icons/indicator-idle.svg';
import offline from '../../assets/icons/indicator-offline.svg';
import online from '../../assets/icons/indicator-online.svg';
import participants from '../../assets/icons/participants.svg';
import { ReactComponent as Marked } from '../../assets/icons/solid/check-circle.svg';
import devices from '../../assets/icons/solid/device.svg';
import { ReactComponent as Servers } from '../../assets/icons/solid/server.svg';
import tags from '../../assets/icons/solid/tag.svg';

import style from './Dashboard.module.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);
type Props = {};

const columnHelper = createColumnHelper<Client>();

const clientTableColumn = [
  columnHelper.accessor('client', {
    header: 'Client',
  }),
  columnHelper.accessor('name', {
    header: 'Username',
  }),
  columnHelper.accessor('totalEvents', {
    header: 'Events',
  }),
  columnHelper.accessor('status', {
    header: 'Status',
  }),
];

const ROW_LIMIT = 10;

const Dashboard = (props: Props) => {
  const { session } = getSession();
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [page, setPage] = useState(1);
  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  const { data: eventStatistics } = useFetchEventStatistics();
  const { data: participantStatistics } = useFetchParticipantStatistics();
  const { data: tagStatistics } = useFetchTagStatistics();
  const { data: deviceStatistics } = useFetchDeviceStatistics();
  const {
    data: clientsStatistics,
    isLoading: isClientStatisticsLoading,
  } = useFetchClientStatistics();

  useEffect(() => {
    requestForToken();
  }, []);

  if (!session?.institutionId) {
    return <Navigate to="/entities" />;
  }
  //console.log(clientsStatistics);
  const { data: clStatistics } = clientsStatistics || {
    data: [],
  };

  let userData = getSession().session;

  const onlineDevicePercentage =
    (deviceStatistics &&
      (deviceStatistics?.onlineDevices / deviceStatistics?.totalDevices) *
        100) ||
    0;

  const onlineTagPercentage =
    (tagStatistics &&
      (tagStatistics?.onlineTags / tagStatistics?.totalTags) * 100) ||
    0;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
        gridLines: {
          lineWidth: 0,
        },
      },
    },
  };

  const optionsHorizontal = {
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },

        ticks: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        gridLines: {
          lineWidth: 0,
        },
      },
    },
  };

  // TODO: memoize somehow
  const participantStatisticsData = {
    labels:
      participantStatistics?.eventsFromLastSixMonthsToNextFiveWithParticipants.map(
        ({ month }) => format(month, 'MMM'),
      ) || [],
    datasets: [
      {
        label: '# of Votes',
        data:
          participantStatistics?.eventsFromLastSixMonthsToNextFiveWithParticipants.map(
            ({ participants }) => participants,
          ) || [],
        backgroundColor: [
          '#64748B',
          '#64748B',
          '#64748B',
          '#64748B',
          '#64748B',
          '#A7F3D0',
          '#E2E8F0',
          '#E2E8F0',
          '#E2E8F0',
          '#E2E8F0',
          '#E2E8F0',
          '#E2E8F0',
        ],
        borderColor: [
          '#64748B',
          '#64748B',
          '#64748B',
          '#64748B',
          '#64748B',
          '#A7F3D0',
          '#E2E8F0',
          '#E2E8F0',
          '#E2E8F0',
          '#E2E8F0',
          '#E2E8F0',
          '#E2E8F0',
        ],
        borderRadius: {
          topLeft: 5,
          topRight: 5,
          bottomLeft: 5,
          bottomRight: 5,
        },
        borderWidth: 1,
      },
    ],
  };

  const eventStatisticsData = {
    labels:
      eventStatistics?.eventsFromLastFiveMonthsToNextSixMonths.map(
        ({ month }) => format(month, 'MMM'),
      ) || [],
    datasets: [
      {
        label: '# of Votes',
        data:
          eventStatistics?.eventsFromLastFiveMonthsToNextSixMonths.map(
            ({ eventCount }) => eventCount,
          ) || [],
        backgroundColor: [
          '#64748B',
          '#64748B',
          '#64748B',
          '#64748B',
          '#64748B',
          '#A7F3D0',
          '#E2E8F0',
          '#E2E8F0',
          '#E2E8F0',
          '#E2E8F0',
          '#E2E8F0',
          '#E2E8F0',
        ],
      },
    ],
  };

  return (
    <FlexGrid>
      <FlexGridItem L={12}>
        <div className={style.user}>Hello, {userData?.firstName} </div>
      </FlexGridItem>
      <FlexGridItem L={12}>
        <FlexGrid>
          <FlexGridItem M={12} L={8}>
            <FlexGrid>
              <FlexGridItem L={6}>
                <div className={style.column2_tag}>
                  <div className={style.column2_tag_primary}>
                    <div className={style.column2_tag_primary_header}>
                      <div className={style.column2_tag_primary_header_title}>
                        <img src={devices} alt=""></img> Devices
                      </div>
                    </div>
                  </div>
                  <FlexGrid>
                    <FlexGridItem L={6}>
                      <div className={`${style.total} ${style.paddingLeft}`}>
                        Total
                      </div>
                      <div className={`${style.number} ${style.paddingLeft}`}>
                        {deviceStatistics?.totalDevices}
                      </div>
                    </FlexGridItem>
                    <FlexGridItem L={6}>
                      <div className={style.total}>Avg per event</div>
                      <div className={`${style.number} ${style.colorSiva}`}>
                        {deviceStatistics?.averageDevicesPerEvent}
                      </div>
                    </FlexGridItem>
                  </FlexGrid>
                  <div className={style.column2_tag_secondary}>
                    <div className={style.column2_tag_secondary_title}>
                      <div>{deviceStatistics?.onlineDevices} online</div>
                      <div>{deviceStatistics?.offlineDevices} offline</div>
                    </div>

                    <MultiProgress
                      elements={[
                        {
                          value: onlineDevicePercentage,
                          color: '#10B981',
                          className: 'test',
                        },
                        {
                          value: 100 - onlineDevicePercentage,
                          color: '#E2E8F0',
                        },
                      ]}
                    />
                  </div>
                </div>
              </FlexGridItem>
              <FlexGridItem M={12} L={6}>
                <div className={style.column2_tag}>
                  <div className={style.column2_tag_primary}>
                    <div className={style.column2_tag_primary_header}>
                      <div className={style.column2_tag_primary_header_title}>
                        <img src={tags} alt=""></img> Tags
                      </div>
                    </div>
                  </div>
                  <FlexGrid>
                    <FlexGridItem L={6}>
                      <div className={`${style.total} ${style.paddingLeft}`}>
                        Total
                      </div>
                      <div className={`${style.number} ${style.paddingLeft}`}>
                        {tagStatistics?.totalTags}
                      </div>
                    </FlexGridItem>
                    <FlexGridItem L={6}>
                      <div className={style.total}>Avg per event</div>
                      <div className={`${style.number} ${style.colorSiva}`}>
                        {tagStatistics?.averageTagsPerEvent}
                      </div>
                    </FlexGridItem>
                  </FlexGrid>
                  <div className={style.column2_tag_secondary}>
                    <div className={style.column2_tag_secondary_title}>
                      <div>{tagStatistics?.onlineTags} online</div>
                      <div>{tagStatistics?.offlineTags} offline</div>
                    </div>

                    <MultiProgress
                      elements={[
                        {
                          value: onlineTagPercentage,
                          color: '#10B981',
                          className: 'test',
                        },
                        {
                          value: 100 - onlineTagPercentage,
                          color: '#E2E8F0',
                        },
                      ]}
                    />
                  </div>
                </div>
              </FlexGridItem>
              <FlexGridItem L={12}>
                <div className={style.column2_tag}>
                  <div className={style.column2_tag_primary}>
                    <div className={style.column2_tag_primary_header}>
                      <div className={style.column2_tag_primary_header_title}>
                        <img src={participants} alt=""></img> Events
                      </div>
                    </div>
                  </div>
                  <FlexGrid>
                    <FlexGridItem M={12} L={6}>
                      <FlexGrid>
                        <FlexGridItem L={6}>
                          <div
                            className={`${style.total} ${style.paddingLeft}`}
                          >
                            Total
                          </div>
                          <div
                            className={`${style.number} ${style.paddingLeft}`}
                          >
                            {eventStatistics?.totalEvents}
                          </div>
                        </FlexGridItem>
                        <FlexGridItem L={6}>
                          <div className={style.total}>Avg per month</div>
                          <div
                            className={`${style.number} ${style.colorPlava} ${style.marginBottom}`}
                          >
                            {eventStatistics?.averageUniqueEventsPerMonth}
                          </div>
                        </FlexGridItem>
                        {/* <FlexGridItem L={6}>
                          <div
                            className={`${style.total} ${style.paddingLeft}`}
                          >
                            MTD vs Aug 2021
                          </div>
                          <div
                            className={`${style.number} ${style.paddingLeft}`}
                          >
                            <div className={`${style.eventPlus}`}>
                              <img src={arrowUp} alt=""></img>
                              24.00 %
                            </div>
                          </div>
                        </FlexGridItem>
                        <FlexGridItem L={6}>
                          <div className={style.total}>MTD vs last month</div>
                          <div
                            className={`${style.number} ${style.colorPlava}`}
                          >
                            <div className={`${style.eventMinus}`}>
                              <img src={arrowDown} alt=""></img>
                              24.00 %
                            </div>
                          </div>
                        </FlexGridItem> */}
                      </FlexGrid>
                    </FlexGridItem>
                    <FlexGridItem M={12} L={6}>
                      <div className={style.okvir}>
                        <FlexGrid>
                          <FlexGridItem L={8}>
                            <div className={`${style.textPart}`}>
                              <img src={online} alt=""></img>
                              Active now
                            </div>
                          </FlexGridItem>
                          <FlexGridItem L={4}>
                            <div className={style.numberPart}>
                              {eventStatistics?.activeEvents}
                            </div>
                          </FlexGridItem>
                        </FlexGrid>
                      </div>
                      <div className={style.okvir}>
                        <FlexGrid>
                          <FlexGridItem L={8}>
                            <div className={`${style.textPart}`}>
                              <img src={idle} alt=""></img>
                              Upcoming
                            </div>
                          </FlexGridItem>
                          <FlexGridItem L={4}>
                            <div className={style.numberPart}>
                              {eventStatistics?.upcomingEvents}
                            </div>
                          </FlexGridItem>
                        </FlexGrid>
                      </div>
                      <div className={style.okvir}>
                        <FlexGrid>
                          <FlexGridItem L={8}>
                            <div className={`${style.textPart}`}>
                              <img src={offline} alt=""></img>
                              Past
                            </div>
                          </FlexGridItem>
                          <FlexGridItem L={4}>
                            <div className={style.numberPart}>
                              {eventStatistics?.pastEvents}
                            </div>
                          </FlexGridItem>
                        </FlexGrid>
                      </div>
                      <div className={style.okvir}></div>
                    </FlexGridItem>
                  </FlexGrid>
                  <FlexGrid>
                    <FlexGridItem M={12}>
                      <div className={style.paddingAndMargin}>
                        <Bar data={eventStatisticsData} options={options} />
                      </div>
                    </FlexGridItem>
                  </FlexGrid>
                </div>
              </FlexGridItem>
              <FlexGridItem L={12}>
                <div className={style.column2_tag}>
                  <div className={style.column2_tag_primary}>
                    <div className={style.column2_tag_primary_header}>
                      <div className={style.column2_tag_primary_header_title}>
                        <img src={clients} alt=""></img> <b>Clients</b>
                      </div>
                    </div>
                    {/* <Table
                      columns={clientTableColumns}
                      actions={[]}
                      limit={clientsStatistics?.length || 0}
                      offset={0}
                      loading={isClientStatisticsLoading}
                      data={clientsStatistics}
                      selectedData={[]}
                      selectedEntirePage={false}
                      setOffset={() => {}}
                      renderAddComponent={() => null}
                      onSelectAll={false}
                      withoutHeader
                    /> */}

                    <TanStackTable
                      selectedRows={selectedRows}
                      onRowSelectionChange={setSelectedRows}
                      columns={clientTableColumn}
                      pageIndex={page}
                      pageSize={ROW_LIMIT}
                      totalRows={clStatistics.length}
                      pageCount={Math.ceil(clStatistics.length / ROW_LIMIT)}
                      data={clStatistics}
                      onPageChange={handlePageChange}
                      isLoading={isClientStatisticsLoading}
                      rowClassName={(row) =>
                        row.status === 'disabled' ? 'text-gray-400' : ''
                      }
                      showHeader
                      getRowId={(row) => row._id}
                    />
                  </div>
                </div>
              </FlexGridItem>
            </FlexGrid>
          </FlexGridItem>
          <FlexGridItem L={4}>
            <FlexGridItem L={4}>
              <div className={style.column2_tag}>
                <div className={style.column2_tag_primary}>
                  <div className={style.column2_tag_primary_header}>
                    <div className={style.column2_tag_primary_header_title}>
                      <img src={participants} alt=""></img> Tracked Subjects
                    </div>
                  </div>
                </div>
                <FlexGrid>
                  <FlexGridItem L={6}>
                    <div className={`${style.total} ${style.paddingLeft}`}>
                      Total
                    </div>
                    <div className={`${style.number} ${style.paddingLeft}`}>
                      {participantStatistics?.totalParticipants}
                    </div>
                  </FlexGridItem>
                  <FlexGridItem L={6}>
                    <div className={style.total}>Avg per event</div>
                    <div
                      className={`${style.number} ${style.colorPlava} ${style.marginBottom}`}
                    >
                      {participantStatistics?.averageByEvent}
                    </div>
                  </FlexGridItem>
                </FlexGrid>
                <div className={style.okvir}>
                  <FlexGrid>
                    <FlexGridItem L={8}>
                      <div className={`${style.textPart}`}>
                        <img src={online} alt=""></img>
                        Active now
                      </div>
                    </FlexGridItem>
                    <FlexGridItem L={4}>
                      <div className={style.numberPart}>
                        {
                          participantStatistics?.totalParticipantsFromCurrentlyActiveEvents
                        }
                      </div>
                    </FlexGridItem>
                  </FlexGrid>
                </div>
                <div className={style.okvir}>
                  <FlexGrid>
                    <FlexGridItem L={8}>
                      <div className={`${style.textPart}`}>
                        <img src={idle} alt=""></img>
                        Upcoming
                      </div>
                    </FlexGridItem>
                    <FlexGridItem L={4}>
                      <div className={style.numberPart}>
                        {
                          participantStatistics?.totalParticipantsInUpcomingEvents
                        }
                      </div>
                    </FlexGridItem>
                  </FlexGrid>
                </div>
                <div className={style.okvir}>
                  <FlexGrid>
                    <FlexGridItem L={8}>
                      <div className={`${style.textPart}`}>
                        <img src={offline} alt=""></img>
                        Past
                      </div>
                    </FlexGridItem>
                    <FlexGridItem L={4}>
                      <div className={style.numberPart}>
                        {participantStatistics?.totalParticipantsInPastEvents}
                      </div>
                    </FlexGridItem>
                  </FlexGrid>
                </div>
                <div className={style.okvir}></div>

                <FlexGrid>
                  <FlexGridItem M={12}>
                    <div className={style.paddingAndMargin}>
                      <Bar
                        data={participantStatisticsData}
                        options={optionsHorizontal}
                        className={style.minHeight}
                      />
                    </div>
                  </FlexGridItem>
                </FlexGrid>
              </div>

              <FlexGridItem L={12}>
                <div className={style.column2_tag}>
                  <div className={style.column2_tag_primary}>
                    <div className={style.column2_tag_primary_header}>
                      <div className={style.column2_tag_primary_header_title}>
                        <Servers fill="#94A3B8"></Servers> System Status
                      </div>
                    </div>
                  </div>
                  <div className={style.notification}>
                    <div className={style.notificationText}>
                      <Marked fill="#059669"></Marked> All services are
                      operating normally
                    </div>
                  </div>
                </div>
              </FlexGridItem>
            </FlexGridItem>
          </FlexGridItem>
        </FlexGrid>
      </FlexGridItem>
    </FlexGrid>
  );
};

export default Dashboard;
