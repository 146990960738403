import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ClientsTableData } from 'pages/Clients/interfaces';
import { client } from 'services/api';
import { Response } from 'types/response';
import { GET_ALL_CLIENTS } from 'utils/apiUrl';

import { clientBaseKey } from './baseKeys';

interface Props {
  row_limit: number;
  offset: number;
  status?: string;
  filter?: string;
}

export const useFetchClients = ({
  row_limit,
  offset,
  status,
  filter,
}: Props) => {
  const queryClient = useQueryClient();
  const queryResult = useQuery<Response<ClientsTableData[]>, AxiosError>({
    queryKey: [clientBaseKey, row_limit, offset, filter, status],
    queryFn: async () => {
      const retVal = await client.get<Response<ClientsTableData[]>>(
        GET_ALL_CLIENTS(row_limit, offset, status, filter),
      );

      retVal.data.data.forEach(async (client) =>
        queryClient.setQueryData<ClientsTableData>(
          [clientBaseKey, client._id],
          client,
        ),
      );

      return retVal.data;
    },
  });
  return queryResult;
};
