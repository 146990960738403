import { zodResolver } from '@hookform/resolvers/zod';
import { FieldValues, UseFormProps, useForm } from 'react-hook-form';
import { ZodType } from 'zod';

export type FormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
> = Omit<UseFormProps<TFieldValues, TContext>, 'resolver'>;

export const useZodForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>(
  formProps: FormProps<TFieldValues, TContext> = { mode: 'onTouched' },
  schema: ZodType,
) =>
  useForm<TFieldValues>({
    ...formProps,
    resolver: zodResolver(schema),
  });
