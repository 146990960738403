import { rgba } from '@mantine/core';
import { Line, Rect } from 'react-konva';

import { CanvasSectionDotProps, CanvasSectionProps } from '../types';

const CanvasSection = ({
  points,
  fill = rgba('#3b82f6', 0.3),
  stroke = '#3b82f6',
  closed = true,
}: CanvasSectionProps) => {
  return (
    <Line
      points={points}
      dash={[3]}
      fill={fill}
      stroke={stroke}
      closed={closed}
    />
  );
};

const Dot = ({ x, y }: CanvasSectionDotProps) => {
  return (
    <Rect
      x={x}
      y={y}
      height={12}
      width={12}
      fill="#dbeafe"
      stroke="#3b82f6"
      strokeWidth={1.5}
    />
  );
};

CanvasSection.Dot = Dot;

export default CanvasSection;
