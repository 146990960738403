import {
  InformationCircleIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/24/solid';
import { Box, Divider, Flex, Grid, Text, Tooltip } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import MapControl from 'components/Canvas/CanvasToolbox/MapControl';
import ZoomControl from 'components/Canvas/CanvasToolbox/ZoomControl';
import InteractiveCanvas from 'components/Canvas/InteractiveCanvas/InteractiveCanvas';
import { MapControlType } from 'components/Canvas/types';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { useTimezoneByEvent } from 'services/entity/home';
import { useTagByEvent } from 'services/entity/tag';
import { convertFirstLetterToUppercase } from 'utils/consts';
import { convertDateAndTime } from 'utils/createDateAndTime';
import { calculateTimeElapsed } from 'utils/dateFormatters';

import MapPicture from '../../../../../../assets/images/MapPicture.png';

import classNames from './TagDetailsModal.module.scss';

const TagDetailsModal = ({ dataId }) => {
  const [mapControl, setMapControl] = useState<MapControlType>({
    sectionsPins: false,
    sectionsArea: false,
    devices: false,
  });
  let eventId = window.location.href.split('/').at(-2);
  const { data: tagData } = useTagByEvent(eventId ?? '', dataId);
  const [scale, setScale] = useState<number>(1);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const { height } = useViewportSize();
  const { data: timezoneData } = useTimezoneByEvent(eventId ?? '');

  return (
    <Grid pr="1rem">
      {tagData && (
        <>
          <Grid.Col p={0} span={5}>
            <ScrollArea
              h={height - 10.75 * 24}
              p="0.5rem"
              pl="1rem"
              type="always"
              scrollbarSize={7}
              offsetScrollbars
              scrollbars="y"
              classNames={{
                scrollbar: classNames.scrollbar,
                thumb: classNames.thumb,
              }}
            >
              <Grid>
                <Grid.Col span={12}>
                  <Flex direction="column" gap="1rem">
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Name
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {tagData?.tag?.name ?? '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Number
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {tagData?.number ?? '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Tag ID
                      </Text>
                      {tagData?.uniqueId ?? '-'}
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Layout
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {tagData?.tagEventStatusEvent[0]?.layoutName ?? '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Section
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {tagData?.tagEventStatusEvent?.length === 1 ? (
                          tagData?.tagEventStatusEvent[0]?.sectionName
                        ) : tagData?.tagEventStatusEvent?.length > 1 ? (
                          <Tooltip
                            label={
                              <Flex direction="column">
                                <span>Sections</span>
                                <br />
                                {tagData?.tagEventStatusEvent?.map(
                                  (item, index) => (
                                    <span
                                      key={index}
                                      style={{ marginBottom: '4px' }}
                                    >
                                      {item.sectionName}
                                    </span>
                                  ),
                                )}
                              </Flex>
                            }
                            position="top"
                            withArrow
                          >
                            <Text fz="0.875rem" fw={400} c="#64748b">
                              (+{tagData?.tagEventStatusEvent?.length - 1})
                            </Text>
                          </Tooltip>
                        ) : (
                          '-'
                        )}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Last seen
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {tagData?.updatedAt
                          ? calculateTimeElapsed(tagData?.updatedAt)
                          : '-'}
                      </Text>
                    </Box>
                  </Flex>
                  <Text
                    mt="1rem"
                    mb="0.75rem"
                    fz="0.875rem"
                    fw={500}
                    c="#0f172a"
                  >
                    Tracking object
                  </Text>
                  {tagData?.participant ? (
                    <Flex
                      p="0.5rem"
                      bg="#f8fafc"
                      bd="1px solid #e2e8f0"
                      align="center"
                      style={{ borderRadius: '0.5rem' }}
                    >
                      <Box mr="0.5rem">
                        <ViewfinderCircleIcon
                          height="1.5rem"
                          width="1.5rem"
                          color="#64748b"
                        />
                      </Box>
                      <Grid w="100%" align="center" columns={8}>
                        <Grid.Col span={4}>
                          <Text fz="0.875rem" fw={500} c={'#64748b'}>
                            {tagData?.participant?.firstLastName ?? '-'}
                          </Text>
                          {tagData?.participant?.trackedSubjectType?.type ? (
                            <Text fz="0.875rem" fw={400} c={'#94a3b8'}>
                              {convertFirstLetterToUppercase(
                                tagData?.participant?.trackedSubjectType?.type,
                              )}
                            </Text>
                          ) : (
                            <Text>-</Text>
                          )}
                        </Grid.Col>
                        {tagData?.participant?.updatedAt && timezoneData && (
                          <Grid.Col span={2}>
                            <Text fz="0.875rem" fw={500} c="#64748b">
                              {`${dayjs(
                                convertDateAndTime(
                                  tagData?.participant?.updatedAt,
                                  timezoneData,
                                ),
                                'YYYY-MM-DD HH:mm:ss',
                              ).format('DD MMM YYYY')}`}
                            </Text>
                            <Text fz="0.875rem" fw={400} c="#94a3b8">
                              {`${dayjs(
                                convertDateAndTime(
                                  tagData?.participant?.updatedAt,
                                  timezoneData,
                                ),
                                'YYYY-MM-DD HH:mm:ss',
                              ).format('hh:mm A')}`}
                            </Text>
                          </Grid.Col>
                        )}
                      </Grid>
                    </Flex>
                  ) : (
                    '-'
                  )}
                </Grid.Col>
              </Grid>
            </ScrollArea>
          </Grid.Col>
          <Grid.Col py={0} pr={0} span={7}>
            <Box
              p="1rem"
              bd="1px solid #e2e8f0"
              className={classNames.mapControlBox}
            >
              <Flex justify="space-between" align="center">
                <Flex c="#94a3b8" gap="0.5rem">
                  <InformationCircleIcon height="1.25rem" width="1.25rem" />
                  <Text fz="0.875rem" fw={400}>
                    Use map control for more informations
                  </Text>
                </Flex>

                <MapControl
                  mapControl={mapControl}
                  setMapControl={setMapControl}
                />
              </Flex>

              <Divider my="1rem" c="#e2e8f0" />

              <Box pos="relative" ref={boxRef}>
                {boxRef.current && (
                  <InteractiveCanvas
                    imageUrl={MapPicture}
                    height={height - 17 * 24}
                    width={boxRef.current?.offsetWidth}
                    scale={scale}
                    showSections={mapControl.sectionsArea}
                    showPins={mapControl.sectionsPins}
                    showDevices={mapControl.devices}
                  />
                )}

                <ZoomControl
                  scale={scale}
                  setScale={setScale}
                  pos="absolute"
                  right="0.675rem"
                  bottom="1rem"
                />
              </Box>
            </Box>
          </Grid.Col>
        </>
      )}
    </Grid>
  );
};

export default TagDetailsModal;
