import { Grid, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useViewportSize } from '@mantine/hooks';
import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import GoogleAutocomplete from 'components/GoogleAutocomplete/GoogleAutocomplete';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import Select from 'components/Select/Select';
import dayjs from 'dayjs';
import { pickBy, identity } from 'lodash';
import {
  CreateTrackingObject,
  CreateTrackingObjectStepOne,
} from 'pages/Entities/Hub/types';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useEffect, useState } from 'react';
import {
  useAddTrackingObjectByEvent,
  useTrackingObjectByEvent,
  useUpdateTrackingObjectByEvent,
} from 'services/entity/trackingObject';
import { useEnumType } from 'services/enum/enum';
import { useOrganizations } from 'services/organization/organization';
import { genders, statuses } from 'utils/consts';

const TrackingObjectInformationContent = ({
  dataId = '',
  eventId = '',
  setFormState,
  setIsFormValid,
  setOnFormSubmit,
}: ModalFormProps & { eventId: string }) => {
  const [
    showFirstNameAndLastName,
    setShowFirstNameAndLastName,
  ] = useState<boolean>(true);
  const [classificationType, setClassificationType] = useState<string>(
    'person',
  );
  const { data: countries } = useEnumType('country');
  const { data: organizations } = useOrganizations();
  const { data: trackingObjectType } = useEnumType('trackingObjectType');
  const { data: trackingObjectTypeClassification } = useEnumType(
    'trackingObjectType',
    classificationType,
  );
  const { data: { eventParticipants } = {} } = useTrackingObjectByEvent(
    eventId,
    dataId,
  );
  const { mutate: createMutateData } = useAddTrackingObjectByEvent();
  const { mutate: updateMutateData } = useUpdateTrackingObjectByEvent();

  const { height } = useViewportSize();

  const form = useForm<CreateTrackingObjectStepOne>({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      typeId: '',
      classificationId: '',
      firstName: '',
      lastName: '',
      firstLastName: '',
      email: '',
      gender: '',
      country: '',
      organisationId: '',
      status: '',
      birthday: '',
      externalId: '',
      postcode: '',
      phoneNumber: '',
      location: null,
    },
    validate: {
      typeId: (value) => (!value ? 'Type is required' : null),
    },
  });

  form.watch('typeId', ({ value }) => {
    if (trackingObjectType?.find((x) => x.key === value)?.key === 'person') {
      setShowFirstNameAndLastName(true);
      form.setFieldValue('firstLastName', null);
      setClassificationType('person');
    } else {
      setShowFirstNameAndLastName(false);
      form.setFieldValue('firstName', null);
      form.setFieldValue('lastName', null);
      setClassificationType('asset');
    }
  });

  const handleAddressSelect = (
    lat: number,
    lng: number,
    address: string,
    metadata: any,
  ) => {
    if (address) {
      form.setValues({
        location: {
          description: address,
          longitude: lng,
          latitude: lat,
          address: address,
          metadata: metadata,
          name: address,
        },
      });
    } else {
      form.setValues({
        location: null,
      });
    }
  };

  useEffect(() => {
    if (eventParticipants && eventParticipants.length > 0) {
      form.setFieldValue(
        'typeId',
        eventParticipants[0].trackedSubjectType.type,
      );
      form.setFieldValue(
        'classificationId',
        eventParticipants[0].trackedSubjectType?.subType ?? '',
      );
      form.setFieldValue('firstName', eventParticipants[0].firstName);
      form.setFieldValue('lastName', eventParticipants[0].lastName);
      form.setFieldValue('firstLastName', eventParticipants[0].firstLastName);
      form.setFieldValue('email', eventParticipants[0].email);
      form.setFieldValue('gender', eventParticipants[0].gender);
      form.setFieldValue('country', eventParticipants[0].country);
      form.setFieldValue('organisationId', eventParticipants[0].organisationId);
      form.setFieldValue('status', eventParticipants[0].status);
      form.setFieldValue(
        'birthday',
        eventParticipants[0].birthday
          ? dayjs(eventParticipants[0].birthday).format('YYYY-MM-DD')
          : '',
      );
      form.setFieldValue('externalId', eventParticipants[0].externalId);
      form.setFieldValue('postcode', eventParticipants[0].postcode);
      form.setFieldValue('phoneNumber', eventParticipants[0].phoneNumber);
      form.setFieldValue('location', eventParticipants[0].location);
      if (eventParticipants[0].trackedSubjectType.type === 'person') {
        setShowFirstNameAndLastName(true);
        setClassificationType('person');
      } else {
        setShowFirstNameAndLastName(false);
        setClassificationType('asset');
      }
    }
  }, [eventParticipants]);

  useEffect(() => {
    if (form.isValid()) {
      setIsFormValid(true);
      const newFormState = form.values;
      const type = newFormState.typeId;
      const subType = newFormState.classificationId;
      const newBirthday = newFormState.birthday
        ? newFormState.birthday + 'T00:00:00Z'
        : '';
      const {
        classificationId,
        typeId,
        birthday,
        ...filteredFormState
      } = newFormState;
      const trackingObject: Partial<CreateTrackingObject> = {
        ...filteredFormState,
        birthday: newBirthday,
        trackedSubjectType: {
          ...pickBy(
            {
              subType: subType,
            },
            identity,
          ),
          type,
        },
      };

      if (setFormState) {
        const cleandedData = pickBy(trackingObject, identity);

        setFormState((prevState) => ({
          ...prevState,
          ...cleandedData,
        }));
      }
    } else {
      setIsFormValid(false);
    }
  }, [form.isValid(), form.values]);

  const onSubmit = (data) => {
    if (eventId) {
      if (dataId) {
        updateMutateData({
          eventId: eventId,
          trackingObjectId: dataId,
          data: data,
        });
      } else {
        createMutateData({
          eventId: eventId,
          data: data,
        });
      }
    }
  };

  useEffect(() => {
    if (setOnFormSubmit) {
      setOnFormSubmit(() => onSubmit);
    }
  }, [setOnFormSubmit]);

  return (
    <ScrollArea
      h={height - 12.75 * 24}
      mr="-1rem"
      pr="0.5rem"
      variant="thumb"
      scrollbarSize={6}
    >
      <Grid>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Select
            mb="1rem"
            label="Type"
            placeholder="Select type"
            withAsterisk
            data={trackingObjectType
              ?.filter((x) => x.key === 'asset' || x.key === 'person')
              .map((item) => ({
                value: item.key,
                label: item.name,
              }))}
            key={form?.key('typeId')}
            {...form?.getInputProps('typeId')}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <Select
            mb="1rem"
            label="Classification"
            placeholder="Select classification"
            data={trackingObjectTypeClassification?.map((item) => ({
              value: item.key,
              label: item.name,
            }))}
            key={form?.key('classificationId')}
            {...form?.getInputProps('classificationId')}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          {showFirstNameAndLastName && (
            <Grid gutter="lg">
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label="First Name"
                  placeholder="John"
                  key={form?.key('firstName')}
                  {...form?.getInputProps('firstName')}
                  mb="1rem"
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  label="Last Name"
                  placeholder="Doe"
                  key={form?.key('lastName')}
                  {...form?.getInputProps('lastName')}
                  mb="1rem"
                />
              </Grid.Col>
            </Grid>
          )}
          {!showFirstNameAndLastName && (
            <TextInput
              label="Name"
              placeholder="Vevor 96 inch"
              key={form?.key('firstLastName')}
              {...form?.getInputProps('firstLastName')}
              mb="1rem"
            />
          )}
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <Select
            mb="1rem"
            label="Gender"
            placeholder="Select gender"
            data={genders?.map((item) => ({
              value: item.value,
              label: item.label,
            }))}
            key={form?.key('gender')}
            {...form?.getInputProps('gender')}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <DatePickerInput
            label="Date of Birth"
            mb="1rem"
            value={form?.values.birthday ?? null}
            onChange={(date) => {
              if (date) form?.setFieldValue('birthday', date);
            }}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <TextInput
            label="Email"
            placeholder="e.g. john@company.com"
            mb="1rem"
            key={form?.key('email')}
            {...form?.getInputProps('email')}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <TextInput
            label="Phone Number"
            placeholder="e.g. 0491 570 006"
            mb="1rem"
            key={form?.key('phoneNumber')}
            {...form?.getInputProps('phoneNumber')}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <Select
            mb="1rem"
            label="Country"
            placeholder="Select country"
            data={countries?.map((item) => ({
              value: item.key,
              label: item.name,
            }))}
            key={form?.key('country')}
            {...form?.getInputProps('country')}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <TextInput
            label="Postal Code"
            placeholder="e.g. 8884"
            mb="1rem"
            key={form?.key('postcode')}
            {...form?.getInputProps('postcode')}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          {form?.values?.location?.address ? (
            <GoogleAutocomplete
              onAddressSelect={handleAddressSelect}
              name={form?.values?.location?.address}
            />
          ) : (
            <GoogleAutocomplete onAddressSelect={handleAddressSelect} />
          )}
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <Select
            mb="1rem"
            label="Organisation"
            placeholder="Select organisation"
            data={organizations?.map((item) => ({
              value: item._id,
              label: item.name,
            }))}
            key={form?.key('organisationId')}
            {...form?.getInputProps('organisationId')}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <Select
            mb="1rem"
            label="Status"
            placeholder="Select status"
            data={statuses?.map((item) => ({
              value: item.value,
              label: item.label,
            }))}
            key={form?.key('status')}
            {...form?.getInputProps('status')}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <TextInput
            label="External ID"
            placeholder="Optional"
            mb="1rem"
            key={form?.key('externalId')}
            {...form?.getInputProps('externalId')}
          />
        </Grid.Col>
      </Grid>
    </ScrollArea>
  );
};

export default TrackingObjectInformationContent;
