import { FormField } from '@lanaco/lnc-react-ui';
import { FlexGrid } from '@lanaco/lnc-react-ui';
import { FlexGridItem } from '@lanaco/lnc-react-ui';
import { TextInput } from '@lanaco/lnc-react-ui';
import { Dropdown } from '@lanaco/lnc-react-ui';
import { FlexBox } from '@lanaco/lnc-react-ui';
import { notification } from '@lanaco/lnc-react-ui';
import { useQueryClient } from '@tanstack/react-query';
import SubmitModal from 'components/SubmitModal/SubmitModal';
import { clientBaseKey } from 'hooks/client/baseKeys';
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { client } from 'services/api';
import { INSERT_CLIENT, UPDATE_CLIENT } from 'utils/apiUrl';
import { statusOptions } from 'utils/dropdown-options';

import { IFormInput } from './interfaces';

type Props = {
  closeModal: any;
  data: any;
  isEdit: boolean;
};

const ClientForm = (props: Props) => {
  const [spinner, setSpinner] = useState(false);
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormInput>();

  useEffect(() => {
    reset(props.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      setSpinner(true);
      if (props.isEdit) {
        //console.log(data);
        const response = await client.put(UPDATE_CLIENT(data._id), data);
        console.log(response.data.code);
        if (response.data.code === 201) {
          setSpinner(false);
          props.closeModal();
          notification.success('Client successfully updated');
        } else {
          setSpinner(false);
          notification.error(
            'Unable to update client. ' + response.data.message,
          );
        }
      } else {
        const response = await client.post(INSERT_CLIENT, data);
        if (response.data.code === 201) {
          setSpinner(false);
          props.closeModal();
          notification.success('Client successfully added.');
        } else {
          setSpinner(false);
          notification.error('Unable to add client. ' + response.data.message);
        }
      }
    } catch (error) {
      setSpinner(false);
      notification.error(error);
    } finally {
      queryClient.invalidateQueries([clientBaseKey]);
    }
  };
  return (
    <div>
      {' '}
      <span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexGrid spacing={10}>
            <input
              type="hidden"
              {...register('_id')}
              value={props.data && props.data.id}
            />
            <FlexGridItem XS={12} S={12}>
              <FormField
                label="Name"
                text={errors.name && <span>{errors.name?.message}</span>}
                color={errors.name && 'danger'}
              >
                <TextInput
                  placeholder={'Name'}
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'Field Name is required.',
                    },
                  })}
                  color={errors.name && 'danger'}
                  value={props.data && props.data.name}
                />
              </FormField>
            </FlexGridItem>

            <FlexGridItem XS={12} S={12}>
              <FormField label="Short name">
                <TextInput
                  placeholder={'Short name'}
                  {...register('nameShort')}
                  value={props.data && props.data.shortName}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6}>
              <FormField label="Status">
                <Dropdown
                  {...register('status')}
                  placeholder={'Active'}
                  options={statusOptions}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={12}>
              <FormField label="Code">
                <TextInput
                  placeholder={'Code'}
                  {...register('code')}
                  value={props.data && props.data.code}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem col={12}>
              <FlexBox justifyContent={'End'}>
                <SubmitModal
                  closeModal={props.closeModal}
                  spinner={spinner}
                  buttonText={'Save'}
                ></SubmitModal>
              </FlexBox>
            </FlexGridItem>
          </FlexGrid>
        </form>
      </span>
    </div>
  );
};

export default ClientForm;
