export const useParticipantDropdowns = (watch, setValue) => {
  const status = watch('status');
  const gender = watch('gender');

  const setValueHandler = (name: string, value: unknown) => {
    setValue(name, value, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  const onChangeDropdown = ({ value }, { name }) => {
    setValueHandler(name, value);
  };

  return {
    status,
    onChangeDropdown,
    gender,
  };
};
