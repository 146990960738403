import { useZodForm } from 'hooks/useZodForm';
import type { EventParticipantTableData } from 'pages/Events/Participants/EventParticipants.interface';
import z from 'zod';

import { PHONE_REGEX } from 'constants/regex';

const participantSchema = z.object({
  firstName: z
    .string()
    .min(1, 'First name should have at least one character')
    .max(50, 'First name can be maximum 50 character long'),
  lastName: z
    .string()
    .min(1, 'Last name should have at least one character')
    .max(50, 'Last name can be maximum 50 character long'),
  gender: z.string().min(1, 'Gender is required'),
  birthday: z.date().optional(),
  // .string()
  // .refine((val) => z.coerce.date().safeParse(val).success, {
  //   message: 'Date format should be valid (e.g. MM/DD/YYYY)',
  // })
  // .optional()
  // .or(z.literal('')),
  email: z
    .string()
    .email()
    .optional()
    .nullable()
    .or(z.literal('').nullable().optional()),
  phoneNumber: z
    .string()
    .regex(PHONE_REGEX, {
      message: 'Invalid phone number format',
    })
    .optional()
    .or(z.literal('').nullable().optional()),
  country: z.string().optional().nullable(),
  postcode: z.string().optional().nullable(),
  organisation: z.string().optional(),
  status: z.string(),
  externalId: z
    .string()
    .max(30, 'External ID can be maximum 30 character long'),
});
export type ParticipantSchema = z.infer<typeof participantSchema>;

export const useParticipantForm = (participant?: EventParticipantTableData) =>
  useZodForm<ParticipantSchema>(
    {
      defaultValues: {
        ...participant,
        gender: participant?.gender ?? '',
        organisation: participant?.organisation ?? '',
        birthday: participant?.birthday
          ? new Date(participant.birthday)
          : undefined,
      },
    },
    participantSchema,
  );
