import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deviceStatisticsBaseKey } from 'hooks/event/statistics';
import { ClientService } from 'services/client';

import { clientBaseKey } from './baseKeys';

interface DeleteClientsProps {
  ids: string[];
}

export const useDeleteClients = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ ids }: DeleteClientsProps) =>
      await ClientService.deleteClients(ids),
    onSuccess: () => {
      queryClient.invalidateQueries([clientBaseKey]);
      queryClient.invalidateQueries([deviceStatisticsBaseKey]);
    },
  });
};
