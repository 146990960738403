import { AUTH_REFRESH_TOKEN } from 'utils/apiUrl';

import { client } from './api';
import { unregisterToken } from './firebase';

export const setSession = (accessToken: string, refreshToken: string) => {
  // TODO: Drop local storage and do not use local cookies
  // cookie is sent from server and it is tied to it, no need to do our internal tracking
  // we do need to logout user using logout method to delete cookie
  localStorage.setItem('__session', accessToken);
  localStorage.setItem('__session_refresh_token', refreshToken);
};

export const getSession = () => {
  const accessToken = localStorage.getItem('__session');
  const refreshToken = localStorage.getItem('__session_refresh_token');

  let session: {
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    companyId?: string;
    companyPosition?: string;
    institutionId?: string;
    institutionPosition?: string;
    iat: number;
    exp: number;
  } | null = null;
  try {
    if (accessToken) {
      const base64Url = accessToken.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      // what is window.atob ?
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/atob
      session = JSON.parse(window.atob(base64));
    }
  } catch (error) {
    console.log('getSession error:', error);
  }

  return { session, accessToken, refreshToken };
};

export const logOut = async () => {
  await unregisterToken();
  localStorage.removeItem('__session');
  localStorage.removeItem('__session_refresh_token');
  localStorage.clear();
  window.location.href = '/';
};

export const getRefreshedToken = async (
  accessToken: string,
  refreshToken: string,
) => {
  let resp = await client.post(
    AUTH_REFRESH_TOKEN,
    {
      refreshToken: refreshToken,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
    },
  );

  if (resp && resp.status === 200) {
    return resp.data.data;
  }

  return resp;
};
