export enum DocumentStatus {
  ACTIVE = 'active',
  PASSIVE = 'passive',
  DISABLED = 'disabled',
}

export const DocumentStatusDescription: Record<DocumentStatus, string> = {
  [DocumentStatus.ACTIVE]: 'Active',
  [DocumentStatus.PASSIVE]: 'Passive',
  [DocumentStatus.DISABLED]: 'Disabled',
};
