import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventLayout } from 'pages/Events/Layout/interfaces';
import { Presentation } from 'pages/Events/Layout/LayoutForm';
import { client } from 'services/api';
import { POST_EVENT_LAYOUT, PUT_EVENT_LAYOUT } from 'utils/apiUrl';

import { singleEventStatisticsBaseKey } from '../statistics/baseKeys';

import { eventLayoutBaseKey } from './eventLayoutBaseKey';

type Payload = Omit<EventLayout, 'startDateTime' | 'endDateTime'> & {
  startDateTime?: string | Date;
  endDateTime?: string | Date;
};

export const useMutateEventLayout = (eventId: string) => {
  const queryClient = useQueryClient();

  const retVal = useMutation<
    EventLayout,
    unknown,
    Partial<Omit<EventLayout, 'presentation'>> & {
      newPresentation: Presentation | null;
    }
  >({
    mutationFn: async (eventLayout) => {
      if (!eventLayout._id) {
        const { newPresentation, ...payload } = eventLayout as Payload & {
          newPresentation: Presentation | null;
        };

        // convert dates to ISO string
        if (payload.startDateTime) {
          payload.startDateTime = (payload.startDateTime as Date).toISOString();
        }
        if (payload.endDateTime) {
          payload.endDateTime = (payload.endDateTime as Date).toISOString();
        }

        const retVal = await client.post(
          POST_EVENT_LAYOUT(eventId),
          eventLayout,
        );

        return retVal.data.data;
      }

      const retVal = await client.put(
        PUT_EVENT_LAYOUT(eventId, eventLayout._id!),
        eventLayout,
      );

      return retVal.data.data;
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries([eventLayoutBaseKey, response.eventId]);
      queryClient.invalidateQueries([singleEventStatisticsBaseKey]);
    },
  });

  return retVal;
};
