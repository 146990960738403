import { EventsTableData } from 'pages/Events/Home/interfaces';
import { EventParticipantTableData } from 'pages/Events/Participants/EventParticipants.interface';
import { EventTagTableData } from 'pages/Events/Tags/EventTag.interface';
import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import { CREATE_EVENT_ADMISSION } from 'utils/apiUrl';

interface Params {
  eventId: string;
  participantId?: string;
  participantExternalId: string;
  tagId?: string;
  tagNumber: string;
}

interface CreateEventAdmissionResponse {
  participant: EventParticipantTableData;
  eventTag: EventTagTableData;
  event: EventsTableData;
}

export class EventAdmissionService extends ResponseInterceptor {
  static async createAdmission({
    eventId,
    participantId,
    participantExternalId,
    tagId,
    tagNumber,
  }: Params): Promise<APIAxiosResponse<CreateEventAdmissionResponse>> {
    const res = await client.post(CREATE_EVENT_ADMISSION(eventId), {
      participantId,
      participantExternalId,
      tagId,
      tagNumber,
    });
    return this.handleResponse(res);
  }
}
