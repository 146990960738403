import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SortingParamType } from 'components/SortingDropdown/types';
import { Filter } from 'context/types';
import { isUndefined, omit, omitBy } from 'lodash';
import { client } from 'services/api';

import {
  EntityLayout,
  EntityLayouts,
  EntityLayoutSubmitData,
  GeoRange,
} from './types';

const getEntityLayout = async (
  eventId: string,
  layoutId: string,
): Promise<EntityLayout> => {
  const url = `event/${eventId}/layout/${layoutId}`;

  const response = await client.get(url);

  return response?.data?.data;
};

const getEntityLayouts = async (
  eventId: string,
  limit: number = 20,
  searchTerm: string = '',
  filter: Filter | null = null,
  sortingParam: SortingParamType | null = null,
): Promise<EntityLayouts> => {
  const params = new URLSearchParams({
    limit: limit.toString(),
    offset: '0',
    filter: searchTerm,
    fetchTotalCount: String(false),
    fetchDataAndFilterCount: String(true),
  });

  if (filter?.states?.length)
    filter.states.forEach((state) => params.append('states[]', state));

  if (filter?.statuses?.length)
    filter.statuses.forEach((status) => params.append('status[]', status));

  if (sortingParam) {
    params.append('sortField', sortingParam.field);
    params.append('sortOrder', sortingParam.order.toString());
  }

  const url = `event/${eventId}/layout/all?${params.toString()}`;

  const response = await client.get(url);

  return response?.data?.data;
};

const addEntityLayoutPresentation = async (
  eventId: string,
  layoutId: string,
  presentation: string | File | GeoRange,
) => {
  const formData = new FormData();
  const url = `event/${eventId}/layout/${layoutId}/presentation`;

  if (presentation instanceof File) {
    formData.append('type', 'image');
    formData.append('presentation', presentation);
  } else if (typeof presentation === 'string') {
    formData.append('type', 'template');
    formData.append('presentation', presentation);
  } else {
    formData.append('type', 'googlemap');
    formData.append('presentation', JSON.stringify(presentation));
  }

  const response = await client.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response?.data;
};

const addEntityLayout = async (
  eventId: string,
  data: EntityLayoutSubmitData,
) => {
  const url = `event/${eventId}/layout`;
  const submitData = omitBy(data, isUndefined);

  const response = await client.post(
    url,
    omit(submitData, ['id', 'presentation']),
  );

  return response?.data;
};

const updateEntityLayout = async (
  eventId: string,
  layoutId: string,
  data: EntityLayoutSubmitData,
) => {
  const url = `event/${eventId}/layout/${layoutId}`;
  const submitData = omitBy(data, isUndefined);

  const response = await client.put(url, omit(submitData, 'presentation'));

  return response?.data;
};

const deleteEntityLayout = async (
  eventId: string,
  layoutIds: Array<string>,
) => {
  const url = `event/${eventId}/layout/`;
  const data = { ids: layoutIds };

  const response = await client.delete(url, {
    data,
  });

  return response.data;
};

const getLayoutLibrary = async () => {
  const url = 'images/template';

  const response = await client.get(url);

  const data = response?.data?.data?.map((layout) => {
    return {
      id: layout?._id,
      title: layout?.originalName,
      image: layout?.mediumSource,
    };
  });

  return data;
};

export const useEntityLayout = (eventId: string, layoutId: string) =>
  useQuery<EntityLayout, Error>({
    queryKey: ['entityLayout', eventId, layoutId],
    queryFn: () => getEntityLayout(eventId, layoutId),
    enabled: eventId.length > 0 && layoutId.length > 0,
  });

export const useEntityLayouts = (
  eventId: string,
  limit?: number,
  searchTerm?: string,
  filter?: Filter | null,
  sortingParam?: SortingParamType | null,
) =>
  useQuery<EntityLayouts, Error>({
    queryKey: [
      'entityLayouts',
      eventId,
      limit,
      searchTerm,
      filter,
      sortingParam,
    ],
    queryFn: () =>
      getEntityLayouts(eventId, limit, searchTerm, filter, sortingParam),
    enabled: eventId.length > 0,
  });

export const useAddEntityLayoutPresentation = () =>
  useMutation({
    mutationFn: ({
      eventId,
      layoutId,
      presentation,
    }: {
      eventId: string;
      layoutId: string;
      presentation: string | File | GeoRange;
    }) => addEntityLayoutPresentation(eventId, layoutId, presentation),
  });

export const useAddEntityLayout = () => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateAddEntityLayoutPresentation,
  } = useAddEntityLayoutPresentation();

  return useMutation({
    mutationFn: ({
      eventId,
      data,
    }: {
      eventId: string;
      data: EntityLayoutSubmitData;
    }) => addEntityLayout(eventId, data),
    onSuccess: (response, { eventId, data }) => {
      const layoutId = response.data._id;

      mutateAddEntityLayoutPresentation({
        eventId,
        layoutId,
        presentation:
          data?.presentation?.image! || data?.presentation?.geoRange!,
      });

      queryClient.invalidateQueries({ queryKey: ['entityLayouts', eventId] });
    },
    onError: (error: Error) => console.error('Error: ', error.message),
  });
};

export const useUpdateEntityLayout = () => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateAddEntityLayoutPresentation,
  } = useAddEntityLayoutPresentation();

  return useMutation({
    mutationFn: ({
      eventId,
      layoutId,
      data,
    }: {
      eventId: string;
      layoutId: string;
      data: EntityLayoutSubmitData;
    }) => updateEntityLayout(eventId, layoutId, data),
    onSuccess: (_, { eventId, layoutId, data }) => {
      // Kada vrsim update layout-a, za sliku mi je postavljen url (string) njen
      // koji sluzi za prikaz
      // Ovaj uslov iskljucuje taj slucaj i ne vrsi se nikakav update prezentacije layout-a
      if (
        data?.presentation?.type === 'image' &&
        typeof data?.presentation?.image === 'string'
      ) {
        queryClient.invalidateQueries({ queryKey: ['entityLayouts', eventId] });
        return;
      }

      mutateAddEntityLayoutPresentation({
        eventId,
        layoutId,
        presentation:
          data?.presentation?.image! || data?.presentation?.geoRange!,
      });

      queryClient.invalidateQueries({ queryKey: ['entityLayouts', eventId] });
    },
    onError: (error: Error) => console.error('Error: ', error.message),
  });
};

export const useDeleteEntityLayout = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      eventId,
      layoutIds,
    }: {
      eventId: string;
      layoutIds: Array<string>;
    }) => deleteEntityLayout(eventId, layoutIds),
    onSuccess: () => queryClient.invalidateQueries(['entityLayouts']),
    onError: (error: Error) => console.error('Error: ', error.message),
  });
};

export const useLayoutLibrary = () =>
  useQuery({
    queryKey: ['layoutLibrary'],
    queryFn: getLayoutLibrary,
  });
