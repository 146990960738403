import { ChangeEvent, useRef } from 'react';

import styles from './InputFileButton.module.scss';

type Props = {
  onUploadImage: (e: ChangeEvent<HTMLInputElement>) => void;
  acceptImgType?: string;
  text: string;
};

export const InputFileButton = ({
  onUploadImage,
  acceptImgType = '.jpeg, .png',
  text = 'Upload image',
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <button
      type="button"
      onClick={handleButtonClick}
      className={styles.inputFileButton}
    >
      {text}
      <input
        type="file"
        ref={fileInputRef}
        accept={acceptImgType}
        onChange={onUploadImage}
      />
    </button>
  );
};
