import React from 'react';

type Props = {
  data: any;
};

const EventMetadata = (props: Props) => {
  console.log(props.data);
  return <div>{JSON.stringify(props.data)}</div>;
};

export default EventMetadata;
