import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { ClockIcon } from '@heroicons/react/24/outline';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Modal,
  Text,
  TextInput,
} from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useDisclosure, useViewportSize } from '@mantine/hooks';
import DrawControl from 'components/Canvas/CanvasToolbox/DrawControl';
import ZoomControl from 'components/Canvas/CanvasToolbox/ZoomControl';
import { MapControlType } from 'components/Canvas/types';
import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import InteractiveMap from 'components/Map/InteractiveMap';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import Select from 'components/Select/Select';
import DeleteModal from 'pages/Entities/ModalManager/DeleteModal';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useEffect, useRef, useState } from 'react';

import LayoutPicture from '../../../../../assets/images/LayoutPicture.jpg';
import MapPicture from '../../../../../assets/images/MapPicture.png';
import MapControl from '../../../../../components/Canvas/CanvasToolbox/MapControl';
import InteractiveCanvas from '../../../../../components/Canvas/InteractiveCanvas/InteractiveCanvas';
import { AddSectionModalForm, LayoutDataType } from '../../types';

const layoutData: Array<LayoutDataType> = [
  {
    id: 'l1',
    name: 'Ground floor',
    picture: MapPicture,
    sections: [
      {
        id: 1,
        name: 'nissan',
        color: '#eab308',
        // Tacke u normalizovanom obliku (visina: 503, sirina: 678)
        points: [
          [0.0159, 0.05899], // [8, 40]
          [0.10934, 0.05899], // [55, 40]
          [0.10934, 0.22123], // [55, 150]
          [0.0159, 0.22123], // [8, 150]
        ],
      },
      {
        id: 22,
        name: 'kia',
        color: '#f43f5e',
        points: [
          [0.0159, 0.22418], // [8, 152]
          [0.10934, 0.22418], // [40, 152]
          [0.10934, 0.38938], // [40, 264]
          [0.0159, 0.38938], // [8, 264]
        ],
      },
      {
        id: 333,
        name: 'infiniti',
        color: '#10b981',
        points: [
          [0.11332, 0.22418], // [57, 152]
          [0.20278, 0.22418], // [102, 152]
          [0.20278, 0.38938], // [102, 264]
          [0.11332, 0.38938], // [57, 264]
        ],
      },
    ],
  },
  { id: 'l2', name: 'First floor', picture: LayoutPicture, sections: [] },
  {
    id: 'l3',
    name: 'Location',
    coordinates: {
      lat: -33.860664,
      lng: 151.208138,
    },
    sections: [
      {
        id: 12,
        name: 'polygon section',
        color: 'red',
        points: [
          [-33.860664, 151.208138],
          [-33.860664, 151.209138],
          [-33.862664, 151.209138],
          [-33.862664, 151.208138],
        ],
      },
      {
        id: 23,
        name: 'other polygon section',
        color: 'blue',
        points: [
          [-33.859604, 151.208138],
          [-33.859604, 151.209138],
          [-33.860604, 151.209138],
          [-33.860604, 151.208138],
        ],
      },
    ],
  },
];

const organisationData = [
  { id: 'o1', name: 'Toyota Motors' },
  { id: 'o2', name: 'Subaru' },
  { id: 'o3', name: 'Jeep' },
];

const typeData = [
  { id: 't1', name: 'Option 1' },
  { id: 't2', name: 'Option 2' },
  { id: 't3', name: 'Option 3' },
];

const trackingTypeData = [
  { id: 'tt1', name: 'Entrance (In)' },
  { id: 'tt2', name: 'Transition (In/Out)' },
  { id: 'tt3', name: 'Exit (Out)' },
];

const AddSectionModal = ({
  formState,
  setFormState,
  isFormValid,
  setIsFormValid,
}: ModalFormProps) => {
  const [selectedLayout, setSelectedLayout] = useState<LayoutDataType | null>(
    layoutData[0],
  );
  const [mapControl, setMapControl] = useState<MapControlType>({
    sectionsPins: false,
    sectionsArea: false,
    devices: false,
  });
  const [scale, setScale] = useState<number>(1);
  const [drawMode, setDrawMode] = useState<'section' | 'device' | null>(null);
  const [newSectionPoints, setNewSectionPoints] = useState<number[][]>([]);
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);
  const boxRef = useRef<HTMLDivElement | null>(null);

  const [
    opened,
    { open: openConfirmModal, close: closeConfirmModal },
  ] = useDisclosure();
  const { height } = useViewportSize();

  const form = useForm<AddSectionModalForm>({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      name: '',
      layoutId: layoutData[0].id,
      organisationId: '',
      typeId: '',
      trackingType: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      capacity: '',
      points: [],
    },
    validate: {
      layoutId: (value) => (!value ? 'Layout is required' : null),
      typeId: (value) => (!value ? 'Type is required' : null),
      trackingType: (value) => (!value ? 'Tracking type is required' : null),
      points: (value) => (value.length === 0 ? 'Points are required' : null),
    },
  });

  form.watch('layoutId', ({ value }) => {
    setSelectedLayout(layoutData.find((layout) => layout.id === value) || null);

    // Resetovanje crtanja pri promjeni aktivnog sablona
    setDrawMode(null);
    setNewSectionPoints([]);
  });

  useEffect(() => {
    form.setFieldValue('points', newSectionPoints);
  }, [form, newSectionPoints]);

  useEffect(() => {
    if (form.isValid()) {
      setIsFormValid(true);
      const newFormState = form.values;

      if (setFormState) {
        setFormState((prevState) => ({
          ...prevState,
          ...newFormState,
        }));
      }
    } else {
      setIsFormValid(false);
    }
  }, [form, setFormState, setIsFormValid]);

  return (
    <>
      {/* Mantine ne podrzava otvaranje modala preko modala kroz menadzer modala (modals.open()) */}
      {/* Ovu funkcionalnost moguce postici jedino preko Modal komponente */}
      <Modal
        size={320}
        withCloseButton={false}
        centered
        opened={opened}
        onClose={closeConfirmModal}
        zIndex={300}
      >
        <DeleteModal
          variant="notification"
          title="Clear outline?"
          body={
            <>
              Are you sure you want to clear outline
              {form.getInputProps('layoutId').value ? ' from ' : null}
              {form.getInputProps('layoutId').value ? (
                <b>
                  {
                    layoutData.find(
                      (layout) =>
                        layout.id === form.getInputProps('layoutId')?.value,
                    )?.name
                  }
                </b>
              ) : null}
              ?
            </>
          }
          actionButtonLabel="Clear"
          actionButtonFn={() => {
            // TODO: dodati delete mutaciju
            setNewSectionPoints([]);
            closeConfirmModal();
          }}
          closeButtonFn={closeConfirmModal}
        />
      </Modal>

      <Grid>
        <Grid.Col span={5}>
          <ScrollArea h={height - 11.25 * 24} pr="0.5rem" mr="-0.5rem">
            <TextInput
              mb="1.5rem"
              label="Name"
              placeholder="Section name"
              key={form?.key('name')}
              {...form?.getInputProps('name')}
            />

            <Select
              mb="1.5rem"
              label="Layout"
              placeholder="Select layout"
              data={layoutData.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              withAsterisk
              key={form.key('layoutId')}
              {...form.getInputProps('layoutId')}
            />

            <Select
              mb="1.5rem"
              label="Organisation"
              placeholder="Select organisation"
              data={organisationData.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              key={form.key('organisationId')}
              {...form.getInputProps('organisationId')}
            />

            <Select
              mb="1.5rem"
              label="Type"
              placeholder="Select type"
              data={typeData.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              withAsterisk
              key={form.key('typeId')}
              {...form.getInputProps('typeId')}
            />

            <Select
              mb="1.5rem"
              label="Tracking Type"
              placeholder="Select tracking type"
              data={trackingTypeData.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              withAsterisk
              key={form.key('trackingType')}
              {...form.getInputProps('trackingType')}
            />

            <Grid mb="1.5rem">
              <Grid.Col span={6}>
                <DatePickerInput
                  label="Start date"
                  value={form.values.startDate}
                  onChange={(date) => form.setFieldValue('startDate', date)}
                />
              </Grid.Col>

              <Grid.Col span={6}>
                <TimeInput
                  label="Start time"
                  key={form.key('startTime')}
                  ref={startTimeRef}
                  rightSection={
                    <ClockIcon
                      width={20}
                      height={20}
                      color="#0F172A"
                      onClick={() => startTimeRef.current?.showPicker()}
                    />
                  }
                  {...form.getInputProps('startTime')}
                />
              </Grid.Col>
            </Grid>

            <Grid mb="1.5rem">
              <Grid.Col pt={0} span={6}>
                <DatePickerInput
                  label="End date"
                  value={form.values.endDate}
                  onChange={(date) => form.setFieldValue('endDate', date)}
                />
              </Grid.Col>

              <Grid.Col pt={0} span={6}>
                <TimeInput
                  label="End time"
                  key={form.key('endTime')}
                  ref={endTimeRef}
                  rightSection={
                    <ClockIcon
                      width={20}
                      height={20}
                      color="#0F172A"
                      onClick={() => endTimeRef.current?.showPicker()}
                    />
                  }
                  {...form.getInputProps('endTime')}
                />
              </Grid.Col>
            </Grid>

            <TextInput
              label="Capacity"
              placeholder="0"
              key={form?.key('capacity')}
              {...form?.getInputProps('capacity')}
            />
          </ScrollArea>
        </Grid.Col>

        <Grid.Col span={7}>
          <Box
            p="1rem"
            bd="1px solid #e2e8f0"
            style={{ borderRadius: '0.75rem' }}
          >
            <Flex mb="1rem" justify="space-between" align="center">
              <Flex gap="0.5rem">
                <InformationCircleIcon
                  height="1.25rem"
                  width="1.25rem"
                  color={drawMode ? '#60A5FA' : '#94A3B8'}
                />
                <Text
                  c={drawMode ? '#3b82f6' : '#64748b'}
                  fz="0.875rem"
                  fw={400}
                >
                  {drawMode
                    ? 'Connect points to create loop'
                    : newSectionPoints.length > 0
                    ? 'Timing location selection is completed'
                    : 'Select box to start drawing'}
                </Text>
              </Flex>

              <Flex gap="1rem">
                <MapControl
                  mapControl={mapControl}
                  setMapControl={setMapControl}
                />
                {newSectionPoints.length > 0 && (
                  <Button
                    bg="#ffffff"
                    c="#f43f5e"
                    bd="1px solid #e2e8f0"
                    fz="0.875rem"
                    onClick={openConfirmModal}
                  >
                    Clear outline
                  </Button>
                )}
              </Flex>
            </Flex>

            <Divider mb="1rem" c="#e2e8f0" />

            {selectedLayout && (
              <Box mt="2.75rem" pos="relative" ref={boxRef}>
                {selectedLayout?.picture && boxRef.current && (
                  <InteractiveCanvas
                    imageUrl={selectedLayout.picture}
                    height={height - 19 * 24}
                    width={boxRef.current?.offsetWidth}
                    scale={scale}
                    showSections={mapControl.sectionsArea}
                    showPins={mapControl.sectionsPins}
                    showDevices={mapControl.devices}
                    drawMode={drawMode}
                    setDrawMode={setDrawMode}
                    sectionsData={selectedLayout.sections}
                    newSectionPoints={newSectionPoints}
                    setNewSectionPoints={setNewSectionPoints}
                  />
                )}

                {selectedLayout?.coordinates && (
                  <InteractiveMap
                    height={height - 19 * 24}
                    center={selectedLayout?.coordinates}
                    showSections={mapControl.sectionsArea}
                    showPins={mapControl.sectionsPins}
                    showDevices={mapControl.devices}
                    drawMode={drawMode}
                    setDrawMode={setDrawMode}
                    sectionsData={selectedLayout?.sections}
                    newSectionPoints={newSectionPoints}
                    setNewSectionPoints={setNewSectionPoints}
                  />
                )}

                <DrawControl
                  drawMode={drawMode}
                  setDrawMode={setDrawMode}
                  drawingDisabled={newSectionPoints.length > 0}
                  pos="absolute"
                  top="-1.75rem"
                  gap="1rem"
                />

                {selectedLayout?.picture && (
                  <ZoomControl
                    scale={scale}
                    setScale={setScale}
                    pos="absolute"
                    right="0.675rem"
                    bottom="1rem"
                  />
                )}
              </Box>
            )}
          </Box>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default AddSectionModal;
