import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { GET_REGISTER_FCM_TOKEN, GET_UNREGISTER_FCM_TOKEN } from 'utils/apiUrl';

import { client } from './api';

const hasAddedServiceWorker = false;
let hasRequestedToken = false;

(function addServiceWorker() {
  if (hasAddedServiceWorker) {
    return;
  }
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(`${window.location.origin}/firebase-messaging-sw.js`)
      .then((registration) => {
        console.log(
          'Service Worker registered with scope:',
          registration.scope,
        );
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });

    navigator.serviceWorker.addEventListener('message', (event) => {
      if (!event.data.action) {
        return;
      }

      if (event.data.action === 'redirect-from-notificationclick') {
        window.location.href = event.data.url;
      }
    });
  }
})();

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = {
  apiKey: 'AIzaSyBJmd0RjanhJNM0kjyTjeegbMWFu15O8-c',
  authDomain: 'timeline-c7413.firebaseapp.com',
  projectId: 'timeline-c7413',
  storageBucket: 'timeline-c7413.appspot.com',
  messagingSenderId: '366093736004',
  appId: '1:366093736004:web:69f78e096959b7545b4411',
  measurementId: 'G-TV422MND78',
};

initializeApp(firebaseConfig);
const messaging = getMessaging();
const VAPID_KEY =
  'BBhEwoaBjvD4U3l2bp3LP4LUjViyOn6ce2e7ZmDCxOcPZhqoUGEalsPBFnAjDaDCnuy7NpPTvUlhsHLRzxjT2Kc';
export const requestForToken = async () => {
  if (!('Notification' in window)) {
    console.error('This browser does not support desktop notification');
    return;
  }

  if (hasRequestedToken) {
    return;
  }

  hasRequestedToken = true;
  getToken(messaging, { vapidKey: VAPID_KEY })
    .then(async (fcmToken) => {
      if (fcmToken) {
        await unregisterToken();
        await client
          .post(GET_REGISTER_FCM_TOKEN, { fcmToken })
          .then(() => {
            localStorage.setItem('fcm_token', fcmToken);
          })
          .catch(() => {
            /* noop */
          });
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.',
        );
      }
    })
    .catch((_) => {
      /* noop */
    });

  await requestNotificationPermission();
};

// Function to request permission for notifications
async function requestNotificationPermission(): Promise<boolean> {
  if (Notification.permission === 'granted') {
    return true;
  }

  // Request permission from the user
  const permission = await Notification.requestPermission();
  return handlePermission(permission);
}

// Function to handle the permission response
function handlePermission(permission: NotificationPermission) {
  if (permission === 'granted') {
    return true;
  }

  if (permission === 'denied') {
    return false;
  }

  return false;
}

export const unregisterToken = async () => {
  const fcmToken = localStorage.getItem('fcm_token');
  if (fcmToken) {
    await client.post(GET_UNREGISTER_FCM_TOKEN, { fcmToken }).catch(() => {
      /* noop */
    });
  }
};
