import { SetModalContentParams } from 'pages/Entities/ModalManager/types';

import ContactModal from './ContactModal';
import RegistrationModal from './RegistrationModal';

export const setAboutModalContent = ({
  type,
  dataId,
  step,
  formState,
  setFormState,
  isFormValid,
  setIsFormValid,
  setOnFormSubmit,
}: SetModalContentParams) => {
  switch (type) {
    case 'registration':
      return (
        <RegistrationModal
          formState={formState}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
          dataId={dataId}
          setOnFormSubmit={setOnFormSubmit}
        />
      );
    case 'contact':
      return (
        <ContactModal
          formState={formState}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
          dataId={dataId}
          setOnFormSubmit={setOnFormSubmit}
        />
      );
  }
};
