import { useQuery } from '@tanstack/react-query';
import { EventAccess } from 'pages/Entities/Hub/types';
import { client } from 'services/api';

const getControlAccess = async (
  eventId: string,
): Promise<EventAccess[] | []> => {
  const response = await client.get(`/event/${eventId}/access`);
  return response.data.data;
};

export const useControlAccess = (eventId: string) => {
  return useQuery<EventAccess[] | [], Error>({
    queryKey: ['controlAccess', eventId],
    queryFn: () => getControlAccess(eventId),
    enabled: !!eventId && eventId.length > 0,
  });
};
