import React, { ReactNode } from 'react';

import styles from './ResponsiveRow.module.scss';

type Props = {
  children: ReactNode;
};

export const ResponsiveRow = ({ children }: Props) => {
  return <div className={styles.responsiveRow}>{children}</div>;
};
