import { SetModalContentParams } from 'pages/Entities/ModalManager/types';

import AgendaDetailsContent from './AgendaDetailsContent';
import HostInformationContent from './HostInformationContent';
import ScheduleInformtionContent from './ScheduleInformationContent';

export const setScheduleModalContent = ({
  step,
  formState,
  setFormState,
  isFormValid,
  setIsFormValid,
}: SetModalContentParams) => {
  return (
    <>
      {step === 1 && (
        <ScheduleInformtionContent
          formState={formState}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
        />
      )}
      {step === 2 && (
        <AgendaDetailsContent
          formState={formState}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
        />
      )}
      {step === 3 && (
        <HostInformationContent
          formState={formState}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
        />
      )}
    </>
  );
};
