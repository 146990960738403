import { notification } from '@lanaco/lnc-react-ui';
import { useQueryClient } from '@tanstack/react-query';
import { RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import {
  TLDangerButton,
  TLNeutralButton,
  TLPrimaryButton,
} from 'components/Button';
import Header from 'components/Header/Header';
import { TLModal } from 'components/Modal';
import TanStackTable, {
  RowAction,
} from 'components/TanStackTable/TanStackTable';
import { useFetchClients } from 'hooks/client';
import { clientBaseKey } from 'hooks/client/baseKeys';
import { useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { client } from 'services/api';
import { Response } from 'types';
import {
  TOGGLE_CLIENT_STATUSES,
  // GET_CLIENTS_STATISTICS,
} from 'utils/apiUrl';

import ClientForm from './ClientForm';
import { DeleteClientModal } from './DeleteClientModal/DeleteClientModal';
import { DeleteClientsModal } from './DeleteClientsModal/DeleteClientsModal';
import { ClientsTableData } from './interfaces';
import { ToggleEnabledClientModal } from './ToggleEnabledClientModal/ToggleEnabledClientModal';

type Props = {};

const columnHelper = createColumnHelper<ClientsTableData>();
const ROW_LIMIT = 10;

// const deviceStatusToBadgeColorMap = {
//   offline: 'bg-gray-50 text-gray-500',
//   online: 'bg-green-50 text-green-500',
//   idle: 'bg-yellow-50 text-yellow-500',
// };

const Clients = (props: Props) => {
  const queryClient = useQueryClient();

  //const [status, setStatus] = useState('all');
  const [search, setSearch] = useState('');
  // const [
  //   clientsStatistics,
  //   setClientsStatistics,
  // ] = useState<ClientsStatistics>();

  const [page, setPage] = useState(1);
  const [clientToEdit, setClientToEdit] = useState<ClientsTableData | null>(
    null,
  );
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});

  const selectedIds = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    },
    [],
  );

  const [showCreateEditModal, _closeCreateEditModal] = useModal(() => {
    const closeModal = () => {
      setClientToEdit(null);
      _closeCreateEditModal();
    };

    return (
      <TLModal
        title={!clientToEdit ? 'Add new client' : 'Edit client'}
        onClose={_closeCreateEditModal}
      >
        <ClientForm
          closeModal={closeModal}
          data={clientToEdit}
          isEdit={!!clientToEdit}
        />
      </TLModal>
    );
  }, [clientToEdit]);

  const [showDeleteClientModal, hideDeleteClientModal] = useModal(
    () => (
      <DeleteClientModal
        id={clientToEdit?._id as string}
        name={clientToEdit?.name}
        onClientEdit={setClientToEdit}
        closeModal={hideDeleteClientModal}
      />
    ),
    [clientToEdit?.name, clientToEdit?._id],
  );

  const [showDeleteClientsModal, closeDeleteClientsModal] = useModal(
    () => (
      <DeleteClientsModal
        selectedIds={selectedIds}
        onClientEdit={setClientToEdit}
        closeModal={closeDeleteClientsModal}
        setSelectedRows={setSelectedRows}
      />
    ),
    [selectedIds],
  );

  const [
    showToggleEnabledClientModal,
    hideToggleEnabledClientModal,
  ] = useModal(
    () => (
      <ToggleEnabledClientModal
        id={clientToEdit?._id as string}
        closeModal={hideToggleEnabledClientModal}
        onClientEdit={setClientToEdit}
        name={clientToEdit?.name}
        status={clientToEdit?.status}
      />
    ),
    [clientToEdit?._id, clientToEdit?.status, clientToEdit?.name],
  );

  // const [showToggleStatusModal, _closeToggleStatusModal] = useModal(() => {
  //   // TODO should be in separate component
  //   const closeToggleStatusModal = () => {
  //     setClientToEdit(null);
  //     _closeToggleStatusModal();
  //   };

  //   const toggleClientEnabled = async () => {
  //     if (clientToEdit) {
  //       const status = clientToEdit.status === 'active' ? 'disabled' : 'active';

  //       const payload = { status };

  //       try {
  //         const response = await client.put(
  //           UPDATE_CLIENT(clientToEdit._id),
  //           payload,
  //         );

  //         if (response.data.data.status !== clientToEdit.status) {
  //           const msg = `Client successfully ${
  //             clientToEdit.status === 'active' ? 'disabled' : 'enabled'
  //           }`;
  //           notification.success(msg);
  //         } else {
  //           notification.error('Something went wrong');
  //         }
  //       } catch (error) {
  //         notification.error(error);
  //       } finally {
  //         queryClient.invalidateQueries([clientBaseKey]);
  //         queryClient.invalidateQueries([clientStatisticsBaseKey]);
  //         closeToggleStatusModal();
  //       }
  //     }
  //   };

  //   return (
  //     <PromptModal
  //       title={
  //         clientToEdit?.status === 'active'
  //           ? 'Disable client?'
  //           : 'Enable client'
  //       }
  //       confirmButtonName={
  //         clientToEdit?.status === 'active' ? 'Disable' : 'Enable'
  //       }
  //       icon="power-off"
  //       warningMessage={
  //         <>
  //           Client {clientToEdit?.name} will be{' '}
  //           {clientToEdit?.status === 'active' ? 'disabled' : 'enabled'}.
  //           <br />
  //           You can {clientToEdit?.status === 'active'
  //             ? 'enable'
  //             : 'disable'}{' '}
  //           it again at any time.
  //         </>
  //       }
  //       onConfirm={toggleClientEnabled}
  //       onCancel={closeToggleStatusModal}
  //     />
  //   );
  // }, [clientToEdit?.status, clientToEdit?.name, clientToEdit?._id]);

  // const [showDeleteModal, _closeDeleteModal] = useModal(() => {
  //   // TODO should be in separate component
  //   const closeDeleteModal = () => {
  //     setClientToEdit(null);
  //     _closeDeleteModal();
  //   };

  //   const confirmDeleteClient = async () => {
  //     if (clientToEdit) {
  //       try {
  //         const response = await client.delete(DELETE_CLIENT(clientToEdit._id));

  //         if (response.status === 200) {
  //           notification.success('Client successfully deleted.');
  //         } else {
  //           notification.error('Something went wrong');
  //         }
  //       } catch (error) {
  //         notification.error(error);
  //       } finally {
  //         queryClient.invalidateQueries([clientBaseKey]);
  //         queryClient.invalidateQueries([clientStatisticsBaseKey]);
  //         closeDeleteModal();
  //       }
  //     }
  //   };

  //   return (
  //     <PromptModal
  //       title="Delete client?"
  //       warningMessage={
  //         <>
  //           Are you sure you want to delete client {clientToEdit?.name}?
  //           <br />
  //           This action cannot be undone.
  //         </>
  //       }
  //       confirmButtonName="Delete"
  //       icon="exclamation-triangle"
  //       iconSize="24"
  //       iconColor="danger"
  //       isDeleteButton
  //       onConfirm={confirmDeleteClient}
  //       onCancel={closeDeleteModal}
  //     />
  //   );
  // }, [clientToEdit?.status, clientToEdit?.name, clientToEdit?._id]);

  // const [showDeleteManyModal, hideDeleteManyModal] = useModal(() => {
  //   // TODO should be in separate component
  //   const closeDeleteManyModal = () => {
  //     setClientToEdit(null);
  //     hideDeleteManyModal();
  //   };

  //   const confirmDeleteManyClients = async () => {
  //     try {
  //       const response = await client.delete(DELETE_CLIENTS(), {
  //         data: { ids: selectedIds },
  //       });

  //       if (response.status === 200) {
  //         notification.success('Client successfully deleted.');
  //       } else {
  //         notification.error('Something went wrong');
  //       }
  //     } catch (error) {
  //       notification.error(error);
  //     } finally {
  //       queryClient.invalidateQueries([clientBaseKey]);
  //       queryClient.invalidateQueries([clientStatisticsBaseKey]);
  //       closeDeleteManyModal();
  //     }
  //   };

  //   return (
  //     <PromptModal
  //       title="Delete clients?"
  //       warningMessage={
  //         <>
  //           Are you sure you want to delete {selectedIds.length} clients?
  //           <br />
  //           This action cannot be undone.
  //         </>
  //       }
  //       confirmButtonName="Delete"
  //       icon="exclamation-triangle"
  //       iconSize="24"
  //       iconColor="danger"
  //       isDeleteButton
  //       onConfirm={confirmDeleteManyClients}
  //       onCancel={closeDeleteManyModal}
  //     />
  //   );
  // }, [queryClient, selectedIds]);

  const { data, isFetching } = useFetchClients({
    offset: (page - 1) * ROW_LIMIT,
    row_limit: ROW_LIMIT,
    //status,
    filter: search,
  });

  const { _route, data: clients } = data || {
    _route: { returned: 0, total: 0 },
    data: [],
  };

  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  useEffect(() => {
    const fetchData = async () => {
      //const response = await client.get(GET_CLIENTS_STATISTICS());
      //setClientsStatistics(response.data.data);
    };
    try {
      fetchData();
    } catch (error) {}
  }, []);

  // const enabledDevices =
  //   deviceStatistics &&
  //   deviceStatistics?.totalDevices - deviceStatistics?.disabledDevices;

  const isMassEnabling = selectedIds.some(
    (id) =>
      queryClient.getQueryData<ClientsTableData>([clientBaseKey, id])
        ?.status === 'disabled',
  );

  // const getHandleStatusFilterChangeCallback = (status: string) => () => {
  //   setPage(1);
  //   setStatus(status);
  // };

  // const buttons: Array<ButtonGroupDataInterface> = [
  //   {
  //     text: 'All',
  //     text1: '',
  //     //indicator: '',
  //     onClick: getHandleStatusFilterChangeCallback('all'),
  //   },
  // {
  //   text: 'Online',
  //   text1: deviceStatistics?.onlineDevices?.toString(),
  //   indicator: online,
  //   onClick: getHandleStatusFilterChangeCallback('online'),
  // },
  // {
  //   text: `Idle ${deviceStatistics?.idleDevices || 0}`,
  //   indicator: idle,
  //   onClick: getHandleStatusFilterChangeCallback('idle'),
  // },
  // {
  //   text: 'Offline',
  //   text1: deviceStatistics?.offlineDevices?.toString(),
  //   indicator: offline,
  //   onClick: getHandleStatusFilterChangeCallback('offline'),
  // },
  // {
  //   text: 'Disabled',
  //   text1: deviceStatistics?.disabledDevices?.toString(),
  //   indicator: '',
  //   onClick: getHandleStatusFilterChangeCallback('disabled'),
  // },
  //];

  // const stats: Array<StatInterface> = [
  //   {
  //     title: 'Enabled',
  //     amount: '',
  //     //icon: arrowSmallUp,
  //     //badge: 'secondary',
  //   },
  // ];

  const clientsTableColumns = [
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('nameShort', {
      header: 'Short name',
    }),

    // columnHelper.accessor('status', {
    //   header: 'Status',
    //   cell: (row) => <span className="capitalize">{row.getValue()}</span>,
    // }),
    columnHelper.accessor('code', {
      header: 'Code',
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (row) => <span className="capitalize">{row.getValue()}</span>,
    }),
  ];

  const clearSelectedRows = () => setSelectedRows({});

  const handleEditClick = (row: ClientsTableData) => {
    setClientToEdit(row);
    showCreateEditModal();
  };

  const handleAddClientClick = () => {
    setClientToEdit(null);
    showCreateEditModal();
  };

  const handleToggleEnabledClick = (row: ClientsTableData) => {
    setClientToEdit(row);
    showToggleEnabledClientModal();
  };

  const handleDeleteClick = (row: ClientsTableData) => {
    setClientToEdit(row);
    showDeleteClientModal();
  };

  const toggleClientStatus = async () => {
    const status = isMassEnabling ? 'active' : 'disabled';

    try {
      const response = await client.put<
        Response<{ matchedCount: number; modifiedCount: number }>
      >(TOGGLE_CLIENT_STATUSES(), {
        ids: selectedIds,
        status,
      });

      const { matchedCount, modifiedCount } = response.data.data;

      notification.success(
        `${modifiedCount} of ${matchedCount} Clients successfully ${
          isMassEnabling ? 'enabled' : 'disabled'
        }`,
      );
    } catch (error) {
      notification.error(error);
    } finally {
      queryClient.invalidateQueries([clientBaseKey]);
      //queryClient.invalidateQueries([clentStatisticsBaseKey]);
    }
  };

  const clientsTableSelectedRowsActions = (
    <>
      <TLNeutralButton leadingIcon="times" onClick={clearSelectedRows}>
        Clear selected
      </TLNeutralButton>
      <TLNeutralButton
        leadingIcon="power-off"
        className="text-red-500"
        onClick={toggleClientStatus}
      >
        {isMassEnabling ? 'Enable' : 'Disable'}
      </TLNeutralButton>
      <TLDangerButton leadingIcon="trash" onClick={showDeleteClientsModal}>
        Delete
      </TLDangerButton>
    </>
  );
  const clientsTableActions = (
    <>
      <TLPrimaryButton leadingIcon="plus" onClick={handleAddClientClick}>
        New client
      </TLPrimaryButton>
    </>
  );

  const clientsTableRowActions = (row: ClientsTableData): RowAction[] => [
    {
      icon: 'pencil',
      key: 'edit',
      label: 'Edit',
      onClick: () => handleEditClick(row),
    },
    {
      icon: 'power-off',
      key: 'toggle-enabled',
      label: row.status === 'disabled' ? 'Enable' : 'Disable',
      onClick: () => handleToggleEnabledClick(row),
    },
    {
      icon: 'trash',
      key: 'delete',
      label: 'Delete',
      onClick: () => handleDeleteClick(row),
    },
  ];

  return (
    <div>
      <Header title="Clients" onSearch={(e) => setSearch(e)}></Header>
      {/* <Stats stats={stats}></Stats> */}

      <TanStackTable
        getRowId={(row) => row._id}
        selectedRows={selectedRows}
        onRowSelectionChange={setSelectedRows}
        columns={clientsTableColumns}
        pageIndex={page}
        pageSize={ROW_LIMIT}
        totalRows={_route.total}
        pageCount={Math.ceil(_route.total / ROW_LIMIT)}
        data={clients}
        rowActions={clientsTableRowActions}
        onPageChange={handlePageChange}
        //headerTitle={<ButtonGroupData buttons={buttons} />}
        isLoading={isFetching}
        rowClassName={(row) =>
          row.status === 'disabled' ? 'text-gray-400' : ''
        }
        tableActions={
          selectedIds.length > 0
            ? clientsTableSelectedRowsActions
            : clientsTableActions
        }
        statusIndicatorClassName={(row) => {
          if (row.status === 'online') return 'bg-green-500';
          if (row.status === 'idle') return 'bg-yellow-500';
          return 'bg-gray-300';
        }}
        enableRowSelection
        showHeader
      />
    </div>
  );
};

export default Clients;
