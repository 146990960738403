import {
  TrashIcon,
  PencilSquareIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowsUpDownIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
  PlusIcon,
} from '@heroicons/react/20/solid';
import { Menu, Button } from '@mantine/core';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';
import { FilterButtonGroupType } from 'components/FilterButtonGroup/types';
import HeaderAndFilterGroup from 'components/HeaderAndFilterGroup/HeaderAndFilterGroup';
import { StatProps } from 'components/Stat/types';
import Stats from 'components/Stats/Stats';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import TableFooter from 'components/TableFooter/TableFooter';
import { useEntitiesContext } from 'context/EntityProvider';
import { format } from 'date-fns';
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_ColumnDef,
  MRT_Icons,
} from 'mantine-react-table';
import { useMemo, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { useUpdateEffect } from 'react-use';
import {
  useEntities,
  useFilteredCounts,
  useTotalCounts,
} from 'services/entity/home';

import live from '../../../assets/icons/indicator-live.svg';
import offline from '../../../assets/icons/indicator-offline.svg';
import past from '../../../assets/icons/indicator-past.svg';
import { openModal } from '../ModalManager/ModalsManager';

import styles from './Entities.module.css';
import { setEntityModalContent } from './Modals/entityModalsConfig';
import { EntityTableType } from './types';

const heroIcons: Partial<MRT_Icons> = {
  IconArrowsSort: (props: any) => (
    <ArrowsUpDownIcon width={20} height={20} color="#64748B" />
  ),
  IconSortAscending: (props: any) => (
    <ArrowUpIcon width={20} height={20} color="#64748B" />
  ),
  IconSortDescending: (props: any) => (
    <ArrowDownIcon width={20} height={20} color="#64748B" />
  ),
};

type SortType = {
  id: string;
  desc: boolean;
};

const Entities = () => {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [rowsOnPage, setRowsOnPage] = useState<number>(25);
  const [currentRowCount, setCurrentRowCount] = useState<number>(25);
  const [limit, setLimit] = useState<number>(25);
  const [isNearBottom, setIsNearBottom] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<string>('all');
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<number>(0);
  const [sorting, setSorting] = useState<SortType[] | []>([]);
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);
  const hasSelection = Object.keys(rowSelection).length > 0;

  const { data: totalCounts = [] } = useTotalCounts();
  const { data: filteredCounts = [] } = useFilteredCounts();
  const { height } = useViewportSize();
  const { data: entities = { events: [], nextCursor: null } } = useEntities(
    limit,
    searchTerm,
    selectedFilter,
    sortField,
    sortOrder,
  );

  const handleRowsOnPageChange = (newRows: number) => {
    setRowsOnPage(newRows);
    setLimit(newRows);
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const nearBottom = scrollHeight - scrollTop <= clientHeight + 20;
      setIsNearBottom(nearBottom);
    }
  };

  const handleSortingChange = (updater) => {
    setSorting(updater);
  };

  useEffect(() => {
    if (isNearBottom) {
      setLimit((prev) => prev + rowsOnPage);
    }
  }, [isNearBottom]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (entities?.events?.length > 0) {
      setCurrentRowCount(entities.events.length);
    }
  }, [entities.events]);

  useUpdateEffect(() => {
    if (sorting?.length > 0) {
      const { id, desc } = sorting[0];

      switch (id) {
        case 'type':
          setSortField('type');
          setSortOrder(desc === false ? 1 : -1);
          break;

        case 'name':
          setSortField('name');
          setSortOrder(desc === false ? 1 : -1);
          break;

        case 'location.description':
          setSortField('location');
          setSortOrder(desc === false ? 1 : -1);
          break;

        case 'clientName':
          setSortField('clientName');
          setSortOrder(desc === false ? 1 : -1);
          break;

        default:
          setSortField('');
          setSortOrder(0);
          break;
      }
    } else {
      setSortField('');
      setSortOrder(0);
    }
  }, [sorting]);

  const buttons: FilterButtonGroupType[] = [
    {
      text: 'All',
      active: selectedFilter === 'all',
      indicator: '',
      text1: filteredCounts.totalFiltered,
      onClick: () => setSelectedFilter('all'),
    },
    {
      text: 'Live',
      active: selectedFilter === 'live',
      indicator: live,
      text1: filteredCounts.filteredLiveEvents,
      onClick: () => setSelectedFilter('live'),
    },
    {
      text: 'Upcoming',
      active: selectedFilter === 'upcoming',
      indicator: offline,
      text1: filteredCounts.filteredUpcomingEvents,
      onClick: () => setSelectedFilter('upcoming'),
    },
    {
      text: 'Past',
      active: selectedFilter === 'past',
      indicator: past,
      text1: filteredCounts.filteredPastEvents,

      onClick: () => setSelectedFilter('past'),
    },
  ];

  const stats: Array<StatProps> = [
    {
      title: 'Total live',
      amount: totalCounts.totalLiveEvents,
      total: `of ${totalCounts.totalEvents} total`,
      color: '#F43F5E',
      percent: (totalCounts.totalLiveEvents / totalCounts.totalEvents) * 100,

      active: true,
    },
    {
      title: 'Total upcoming',
      color: '#CBD5E1',
      amount: totalCounts.totalUpcomingEvents,
      total: `of ${totalCounts.totalEvents} total`,
      percent:
        (totalCounts.totalUpcomingEvents / totalCounts.totalEvents) * 100,
    },
    {
      title: 'Total past',
      color: '#64748B',
      amount: totalCounts.totalPastEvents,
      total: `of ${totalCounts.totalEvents} total`,
      percent: (totalCounts.totalPastEvents / totalCounts.totalEvents) * 100,
    },
  ];

  const columns = useMemo<MRT_ColumnDef<EntityTableType>[]>(
    () => [
      {
        maxSize: 5,
        minSize: 2,
        id: 'statusIndicator',
        header: '',

        Cell: ({ row }) => {
          const { startDate, finishDate } = row.original;

          return (
            <StatusIndicator
              height="2rem"
              startDate={new Date(startDate)}
              endDate={new Date(finishDate)}
            />
          );
        },
      },
      {
        maxSize: 300,
        minSize: 260,
        id: 'name',
        accessorKey: 'name',
        header: 'Entities',
        enableSorting: true,

        Cell: ({ cell, row }) => {
          return (
            <div
              className={`${styles.firstColumn} ${styles.cursorPointer}`}
              onClick={() => {
                navigate(`/entities/${row.original._id}/hub`);
              }}
            >
              {cell.getValue() as React.ReactNode}
            </div>
          );
        },
      },
      {
        maxSize: 200,
        minSize: 180,
        accessorKey: 'type',
        header: 'Type',
        enableSorting: true,
      },
      {
        id: 'start/end',
        maxSize: 220,
        minSize: 200,
        accessorFn: ({ startDate, finishDate }) =>
          `${format(new Date(startDate), 'dd MMM yyyy')} - ${format(
            new Date(finishDate),
            'dd MMM yyyy',
          )}`,
        header: 'Start/End',
        enableSorting: true,
      },
      {
        maxSize: 220,
        minSize: 200,
        accessorKey: 'location.address',
        header: 'Location',
        enableSorting: true,
      },
      {
        maxSize: 220,
        minSize: 180,
        accessorFn: (row) => row.companyInfo[0]?.clientName,
        header: 'Client',
        enableSorting: true,
      },
      {
        maxSize: 40,
        minSize: 30,
        id: 'actions',
        enableSorting: false,
        header: '',
        accessorKey: 'actions',

        Cell: ({ row }) => (
          <Menu position="bottom-end">
            <Menu.Target>
              <button className={styles.actionMenuItem}>
                <EllipsisHorizontalIcon
                  color="#475569"
                  height={20}
                  width={20}
                />
              </button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                className={styles.menuItem}
                onClick={() =>
                  openModal({
                    title: 'Edit entity',
                    dataId: row.original._id,
                    subtitles: [
                      'Entity informations',
                      'Entity scanning content',
                      'Entity social media',
                    ],
                    isMultistep: true,
                    size: 640,
                    setModalContent: setEntityModalContent,
                  })
                }
              >
                <PencilSquareIcon width={20} height={20} color="#3b82f6" />
                Edit
              </Menu.Item>
              <Menu.Item className={styles.menuItem} onClick={() => {}}>
                <TrashIcon width={20} height={20} color="#f43f5e" />
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
      },
    ],
    [sortField, sortOrder],
  );

  const table = useMantineReactTable({
    columns,
    data: entities.events || [],
    enableTopToolbar: false, // Isključivanje toolbar-a na vrhu
    enableColumnActions: false, // Isključivanje akcija u kolonama
    enableColumnPinning: true,
    enableSorting: true,
    onSortingChange: handleSortingChange,
    manualPagination: true,
    enableBottomToolbar: false,
    icons: heroIcons,
    enableRowSelection: true, //checkbox
    enableStickyHeader: true,

    mantineTableContainerProps: {
      ref: scrollRef,
      style: {
        maxHeight: height > 750 ? height / 1.8 : height / 2.4, // TODO: izmijeniti
      },
    },

    enablePagination: false,
    enableRowVirtualization: true,

    initialState: {
      columnPinning: {
        right: ['actions'],
        left: ['statusIndicator'],
      },
    },

    mantineSelectAllCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantineSelectCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantinePaperProps: {
      style: {
        '--mrt-pinned-column-background-color': '#ffffff',
        '--mrt-pinned-row-background-color': '#333',
        boxShadow: 'none',
        borderRadius: 0,
        marginTop: -2,
        height: height > 750 ? height / 1.8 : height / 2.4, // TODO: izmijeniti
        position: 'sticky',
        zIndex: 10,
        bottom: 0,
      },
    },

    mantineTableBodyRowProps: ({ row }) => {
      return {
        className: row.getIsSelected() ? styles.selectedRow : '',
      };
    },

    state: {
      rowSelection,
      sorting,
    },

    onRowSelectionChange: setRowSelection,
  });

  return (
    <div className={styles.container}>
      {!isMobile && <Stats stats={stats} />}
      <div className={styles.tableContainer}>
        {!isMobile && (
          <HeaderAndFilterGroup
            title="Entities"
            subtitle="Keep track of all your live, upcoming and past entities."
            buttons={buttons}
            hasBorder={false}
            width="100%"
            onSearch={setSearchTerm}
            extraButton={
              hasSelection ? (
                <>
                  <Button
                    bg="#ffffff"
                    bd="1px solid #e2e8f0"
                    c="#344155"
                    mr={12}
                    radius={8}
                    leftSection={<XMarkIcon height={20} width={20} />}
                    onClick={() => setRowSelection({})}
                  >
                    Clear selection
                  </Button>
                  <Button
                    c="#f43f5e"
                    bg="#ffffff"
                    bd="1px solid #e2e8f0"
                    radius={8}
                    leftSection={
                      <TrashIcon
                        height="1.25rem"
                        width="1.25rem"
                        color="#f43f5e"
                      />
                    }
                  >
                    Delete
                  </Button>
                </>
              ) : (
                <Button
                  bg="#3B82F6"
                  radius="0.5rem"
                  leftSection={<PlusIcon height="1.25rem" width="1.25rem" />}
                  onClick={() =>
                    openModal({
                      title: 'Adding new entity',
                      subtitles: [
                        'Entity informations',
                        'Entity scanning content',
                        'Entity social media',
                      ],
                      isMultistep: true,
                      size: 640,
                      setModalContent: setEntityModalContent,
                    })
                  }
                >
                  Create entity
                </Button>
              )
            }
          />
        )}

        <MantineReactTable table={table} />
        <TableFooter
          totalCount={totalCounts.totalEvents}
          currentCount={currentRowCount}
          rowsOptions={['25', '50', '100', '200']}
          rowsOnPage={rowsOnPage}
          onRowsOnPageChange={handleRowsOnPageChange}
        />
      </div>
    </div>
  );
};

export default Entities;
