import { useQuery, useQueryClient } from '@tanstack/react-query';
import { EventTimingLocationsDevicesTableData } from 'pages/Events/TimingLocations/TimingLocations.interfaces';
import { client } from 'services/api';
import { Response } from 'types';
import { GET_TIMING_LOCATION_ASSIGNABLE_DEVICES } from 'utils/apiUrl';

import { eventTimingLocationDeviceAssignableKey } from '../baseKey';

interface Props {
  eventId: string;
  layoutId: string;
  sectionId: string;
  offset: number;
  row_limit: number;
  filter?: string;
}

export const useFetchEventTimingLocationAssignableDevices = ({
  eventId,
  layoutId,
  sectionId,
  offset,
  row_limit,
  filter,
}: Props) => {
  const queryClient = useQueryClient();
  const queryResult = useQuery<any>({
    queryKey: [
      eventTimingLocationDeviceAssignableKey,
      eventId,
      layoutId,
      sectionId,
      row_limit,
      offset,
      filter,
    ],
    queryFn: async () => {
      // if (!eventId) return new EventDevice[];

      const retVal = await client.get<
        Response<EventTimingLocationsDevicesTableData[]>
      >(
        GET_TIMING_LOCATION_ASSIGNABLE_DEVICES(
          eventId,
          layoutId,
          sectionId,
          row_limit,
          offset,
          filter,
        ),
      );

      // retVal.data.data.forEach(async (device) =>
      //   queryClient.setQueryData<EventTimingLocationsDevicesTableData>(
      //     ['eventTimingLocationAssignableDevices', device._id],
      //     device,
      //   ),
      // );
      return retVal.data;
    },
  });

  return queryResult;
};
