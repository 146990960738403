import { ReactElement, useMemo, useState } from 'react';

export function useMultiStepForm(steps: ReactElement[]) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const next = () => {
    setCurrentStepIndex((i: any) => {
      if (i >= steps.length) return i;
      return i + 1;
    });
  };

  const back = () => {
    setCurrentStepIndex((i: any) => {
      if (i <= 0) return i;
      return i - 1;
    });
  };

  return {
    currentStepIndex,
    currentStep: useMemo(() => steps[currentStepIndex], [
      currentStepIndex,
      steps,
    ]),
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    next,
    back,
    steps,
  };
}
