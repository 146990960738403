import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TagsService } from 'services/tags';
import { UpdateTagStatusPayload } from 'types/tag';

import { tagBaseKey } from './baseKey';

interface UpdateStatusTagProps {
  id: string;
  payload: UpdateTagStatusPayload;
}

export const useMutateStatusTag = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, payload }: UpdateStatusTagProps) =>
      (await TagsService.updateTagStatus(id, payload)).data,
    onSuccess: () => {
      queryClient.invalidateQueries([tagBaseKey]);
    },
  });
};
