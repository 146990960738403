import { ErrorMessage } from '@hookform/error-message';
import { DragDropFiles } from '@lanaco/lnc-react-ui';
import { InputError } from 'components/Input';
import { ChangeEvent, ReactNode } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  FieldValues,
} from 'react-hook-form';

import styles from './DragAndDropImageInputGroup.module.scss';

export interface DragAndDropImageInputGroupProps<T extends FieldValues> {
  control?: Control<T>;
  name: FieldPath<T>;
  errors?: FieldErrors;
  dndFileText?: ReactNode;
  selectFileText?: string;
  onUploadImage: (file: File | null) => void;
}

const DragAndDropImageInputGroup = <T extends FieldValues>({
  name,
  control,
  errors,
  dndFileText = '',
  selectFileText = '',
  onUploadImage,
}: DragAndDropImageInputGroupProps<T>) => {
  const uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] ?? null;

    onUploadImage(file);
  };

  return (
    <div className={styles.dragAndDropImageInputGroup}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DragDropFiles
            {...field}
            dndFileText={dndFileText}
            selectFileText={selectFileText}
            onChange={uploadImage}
          />
        )}
      />

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <InputError message={message} />}
      />
    </div>
  );
};

export default DragAndDropImageInputGroup;
