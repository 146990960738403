import { useNavigate } from 'react-router';

export const Redirect = ({
  to,
  mode = 'react',
}: {
  to: string;
  mode: 'browser' | 'react';
}) => {
  const navigate = useNavigate();

  if (mode === 'react') {
    navigate(to);
    return null;
  }

  window.location.href = to;
  return null;
};
