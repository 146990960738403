import { Box } from '@mantine/core';
import { Map, Marker } from '@vis.gl/react-google-maps';
import { getCenterPoint } from 'components/Canvas/consts';
import polygonClipping from 'polygon-clipping';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import icon from '../../../src/assets/images/locationpin.png';

import MapPin from './MapPin';
import MapSection from './MapSection';
import { InteractiveMapProps } from './types';
import { useGeolocation } from './useGeolocation';

const InteractiveMap = ({
  height,
  width = 'auto',
  backgroundColor = '#e2e8f0',
  borderRadius,
  showSections = false,
  showPins = false,
  showDevices = false,
  autozoom = false,
  zoom,
  setZoom,
  center,
  setCenter,
  drawMode,
  setDrawMode,
  markersData = [],
  newSectionPoints,
  setNewSectionPoints,
  marker,
  setMarker,
  sectionsData,
  readLocation = false,
  gestureHandling = 'auto',
}: InteractiveMapProps) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const drawingManager = useRef<google.maps.drawing.DrawingManager | null>(
    null,
  );
  const currentPolygonRef = useRef<google.maps.Polygon | null>(null);

  useGeolocation(readLocation, setCenter);

  const mapKey = useMemo(() => `map-${center.lat}-${center.lng}`, [center]);
  const bounds = useMemo(() => {
    if (!map || !autozoom || markersData.length === 0) return null;

    const bounds = new google.maps.LatLngBounds();
    markersData.forEach((marker) => bounds.extend(marker));

    if (marker) bounds.extend(marker);

    return bounds;
  }, [map, autozoom, markersData, marker]);

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds, { top: 12, bottom: 12, left: 12, right: 12 });
    }
  }, [map, bounds]);

  useEffect(() => {
    if (!map || drawMode !== 'section') return;

    drawingManager.current = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      polygonOptions: {
        fillColor: '#3b82f6',
        fillOpacity: 0.3,
        strokeColor: '#3b82f6',
        strokeWeight: 1.5,
      },
      drawingControl: false,
    });

    drawingManager.current.setMap(map);

    const listener = google.maps.event.addListener(
      drawingManager.current,
      'polygoncomplete',
      (polygon) => {
        let intersects = false;
        currentPolygonRef.current = polygon;
        const paths = polygon
          .getPath()
          .getArray()
          .map((latLng) => [latLng.lat(), latLng.lng()]);

        if (sectionsData) {
          for (const section of sectionsData) {
            intersects =
              polygonClipping.intersection(
                [paths as [number, number][]],
                [section?.points as [number, number][]],
              ).length > 0;

            if (intersects) break;
          }
        }

        if (intersects) {
          currentPolygonRef?.current?.setMap(null);
          currentPolygonRef.current = null;
          return;
        }

        setNewSectionPoints?.(paths);
        setDrawMode?.(null);
      },
    );

    return () => {
      if (drawingManager.current) {
        google.maps.event.removeListener(listener);
        drawingManager.current.setMap(null);
      }
    };
  }, [map, sectionsData, drawMode, setDrawMode, setNewSectionPoints]);

  useEffect(() => {
    if (newSectionPoints?.length === 0 && currentPolygonRef.current) {
      currentPolygonRef.current.setMap(null);
      currentPolygonRef.current = null;
    }
  }, [newSectionPoints]);

  return (
    <Box
      h={height}
      w={width}
      bg={backgroundColor}
      style={
        borderRadius
          ? {
              borderRadius: borderRadius,
              overflow: 'hidden',
            }
          : {}
      }
    >
      <Map
        key={mapKey}
        mapId={'map-id'}
        defaultZoom={zoom || 15}
        defaultCenter={center}
        backgroundColor={backgroundColor}
        clickableIcons={false}
        disableDefaultUI
        gestureHandling={gestureHandling}
        onZoomChanged={(e) => setZoom && setZoom(e.detail.zoom)}
        onClick={(e) => {
          // Ukoliko je drawing ukljuceno onda klik sluzi za crtanje poligona
          if (e.detail.latLng && drawMode === undefined) {
            setMarker && setMarker(e.detail.latLng);
            map?.panTo(e.detail.latLng);
          }
        }}
        onTilesLoaded={(mapInstance) => setMap(mapInstance.map)}
      >
        {/* Iscrtavanje markera */}
        {markersData?.map((marker, index) => (
          <Marker
            key={`marker-${index}`}
            position={marker}
            opacity={0.7}
            icon={icon}
          />
        ))}
        {/* Marker trenutnog sablona */}
        {marker && <Marker position={marker} icon={icon} />}

        {sectionsData?.map((section) => {
          const { x: lat, y: lng } = getCenterPoint(section.points);

          return (
            <Fragment key={section.id}>
              {/* Iscrtavanje sekcija */}
              <MapSection
                key={`section-${section.name}`}
                map={map}
                points={section.points}
                show={showSections}
              />

              {/* Iscrtavanje pinova */}
              {showPins && (
                <MapPin
                  key={`pin-${lat}-${lng}`}
                  position={{ lat, lng }}
                  text={`${section.id}`}
                />
              )}
            </Fragment>
          );
        })}
      </Map>
    </Box>
  );
};

export default InteractiveMap;
