import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CreateAndUpdateEntityReq } from 'pages/Entities/Home/types';
import { client } from 'services/api';

import {
  EntityScheduled,
  Event,
  GetEntityType,
  HubSectionStatistics,
} from './types';

const getEntities = async (
  limit: number,
  searchTerm: string | '',
  filterType: string,
  sortField: string | '',
  sortOrder: number,
) => {
  let url = `/event/getAllEvents?fetchDataAndFilterCount=true&fetchTotalCount=true&limit=${limit}&searchTerm=${searchTerm}`;

  if (filterType) {
    url += `&filterType=${filterType}`;
  }
  if (sortField && sortOrder) {
    url += `&sortField=${sortField}&sortOrder=${sortOrder}`;
  }

  const response = await client.get(url);

  return {
    events: response.data.data.events,
    nextCursor: response.data._route.next,
  };
};

export const getTotalCounts = async () => {
  const response = await client.get(
    '/event/getAllEvents?fetchDataAndFilterCount=true&fetchTotalCount=true',
  );

  return response.data.data.totalCounts;
};

export const getFilteredCounts = async () => {
  const response = await client.get(
    '/event/getAllEvents?fetchDataAndFilterCount=true&fetchTotalCount=true',
  );

  return response.data.data.filteredCounts;
};

const getEntityType = async (): Promise<GetEntityType[]> => {
  const url = 'event/types/';

  const response = await client.get(url);

  return response.data.data;
};

const getTimezoneByEvent = async (eventId: string): Promise<string> => {
  const url = `event/${eventId}/timezone`;

  const response = await client.get(url);

  return response.data.data.timezone;
};

const getHubSectionStatistics = async (
  eventId: string,
  sectionName: string,
): Promise<HubSectionStatistics> => {
  const params = new URLSearchParams({
    fetchTotalCount: String(true),
    fetchDataAndFilterCount: String(false),
  });

  const url = `event/${eventId}/${sectionName}/all?${params.toString()}`;

  const response = await client.get(url);

  return response.data.data.totalCounts;
};

const addEvent = async (data: CreateAndUpdateEntityReq) => {
  const url = 'event/';

  const response = await client.post(url, data);

  return response.data;
};

const updateEvent = async (data: CreateAndUpdateEntityReq, eventId: string) => {
  const url = `event/${eventId}`;

  const response = await client.put(url, data);

  return response.data;
};

const getEntity = async (eventId: string): Promise<Event> => {
  const url = `event/${eventId}`;

  const response = await client.get(url);

  return response.data.data;
};

const getEntitySchedules = async (
  eventId: string,
): Promise<EntityScheduled[] | []> => {
  const url = `event/${eventId}/schedule`;

  const response = await client.get(url);

  return response.data.data;
};

const getEntityScheduled = async (
  eventId: string,
  scheduledId: string,
): Promise<EntityScheduled | null> => {
  const url = `event/${eventId}/schedule/${scheduledId}`;

  const response = await client.get(url);

  return response.data.data;
};

const uploadEntityImage = async (image: File, eventId: string) => {
  const formData = new FormData();
  formData.append('heroImage', image);

  const url = `event/${eventId}/hero`;

  const response = await client.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
};

export const useUploadEntityImage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ image, eventId }: { image: File; eventId: string }) => {
      return uploadEntityImage(image, eventId);
    },
    onSuccess: (data, { eventId }) => {
      queryClient.invalidateQueries({
        queryKey: ['entity', eventId],
      });
    },
    onError: (error: Error) => {
      console.error('Error:', error.message);
    },
  });
};

export const useEntity = (eventId: string) => {
  return useQuery<Event, Error>({
    queryKey: ['entity', eventId],
    queryFn: () => getEntity(eventId),
    enabled: !!eventId && eventId.length > 0,
  });
};

export const useEntitySchedules = (eventId: string) => {
  return useQuery<EntityScheduled[] | [], Error>({
    queryKey: ['entitySchedules', eventId],
    queryFn: () => getEntitySchedules(eventId),
    enabled: !!eventId && eventId.length > 0,
  });
};

export const useEntityScheduled = (eventId: string, scheduledId: string) => {
  return useQuery<EntityScheduled | null, Error>({
    queryKey: ['entityScheduled', eventId, scheduledId],
    queryFn: () => getEntityScheduled(eventId, scheduledId),
    enabled:
      !!eventId &&
      eventId.length > 0 &&
      !!scheduledId &&
      scheduledId.length > 0,
  });
};

export const useEntities = (
  limit = 5,
  filter: string | '',
  filterType: string,
  sortField: string | '',
  sortOrder: number,
) => {
  return useQuery({
    queryKey: ['entities', limit, filter, filterType, sortField, sortOrder],
    queryFn: () => getEntities(limit, filter, filterType, sortField, sortOrder),
    enabled: !!filterType && filterType.length > 0,
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      return lastPage.nextCursor || undefined;
    },
  });
};

export const useTotalCounts = () => {
  return useQuery({
    queryKey: ['totalCounts'],
    queryFn: getTotalCounts,
  });
};

export const useFilteredCounts = () => {
  return useQuery({
    queryKey: ['filteredCounts'],
    queryFn: getFilteredCounts,
  });
};

export const useEntityType = () => {
  return useQuery<GetEntityType[], Error>({
    queryKey: ['entityType'],
    queryFn: () => getEntityType(),
  });
};

export const useAddEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addEvent,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['entities'],
      });
    },
  });
};

export const useUpdateEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      data,
      eventId,
    }: {
      data: CreateAndUpdateEntityReq;
      eventId: string;
    }) => updateEvent(data, eventId),

    onSuccess: (data, { eventId }) => {
      queryClient.invalidateQueries({
        queryKey: ['entities'],
      });

      queryClient.invalidateQueries({
        queryKey: ['entity', eventId],
      });
    },
  });
};

export const deleteEvent = async (id: string) => {
  const url = `event/${id}`;
  const response = await client.delete(url);

  return response.data;
};

export const useDeleteEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ eventId }: { eventId: string }) => deleteEvent(eventId),

    onSuccess: () => {
      queryClient.invalidateQueries(['entities']);
    },
    onError: (error: Error) => {
      console.error('Error: ', error.message);
    },
  });
};

export const useHubSectionStatistics = (
  eventId: string | null,
  sectionName: string,
) => {
  return useQuery<HubSectionStatistics, Error>({
    queryKey: ['hubSectionStatistics', eventId, sectionName],
    queryFn: () => getHubSectionStatistics(eventId!, sectionName),
    enabled: !!eventId && eventId.length > 0 && sectionName?.length > 0,
  });
};

export const useTimezoneByEvent = (eventId: string) => {
  return useQuery<string>({
    queryKey: ['timezoneByEvent', eventId],
    queryFn: () => getTimezoneByEvent(eventId!),
    enabled: !!eventId && eventId.length > 0,
  });
};
