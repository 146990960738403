import { useEffect, useRef } from 'react';

import { MapSectionProps } from './types';

const MapSection = ({ map, points, show = true }: MapSectionProps) => {
  const polygonRef = useRef<google.maps.Polygon | null>(null);

  useEffect(() => {
    if (!map || !show) return;

    const paths = points.map(
      (point) => new google.maps.LatLng(point[0], point[1]),
    );

    polygonRef.current = new google.maps.Polygon({
      paths,
      fillColor: '#3b82f6',
      fillOpacity: 0.3,
      strokeColor: '#3b82f6',
      strokeWeight: 1,
      map,
    });

    return () => {
      if (polygonRef.current) {
        polygonRef.current.setMap(null);
      }
    };
  }, [map, points, show]);

  return <></>;
};

export default MapSection;
