import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventParticipantDeleteService } from 'services/event/participant/event-participant-delete';

import { eventParticipantBaseKey } from './eventParticipantBaseKey';

interface Props {
  eventId: string;
  participantId?: string;
  ids?: string[];
}

export const useMutateDeleteEventParticipant = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ eventId, participantId, ids }: Props) => {
      if (!eventId) return;
      const resp =
        participantId === undefined
          ? await EventParticipantDeleteService.deleteEventParticipants({
              eventId,
              ids,
            })
          : await EventParticipantDeleteService.deleteEventParticipant({
              eventId,
              participantId,
            });

      return resp?.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([eventParticipantBaseKey]);
    },
  });

  return mutation;
};
