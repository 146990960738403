import { notification } from '@lanaco/lnc-react-ui';
import { RowSelectionState, createColumnHelper } from '@tanstack/table-core';
import { TLNeutralButton, TLSecondaryLoadingButton } from 'components/Button';
import { InputSearch } from 'components/Input';
import { TLModalFooter } from 'components/Modal';
import { NotAvailable } from 'components/NotAvailable';
import TanStackTable from 'components/TanStackTable/TanStackTable';
import { useFetchEventParticipants } from 'hooks/event/participants';
import { useAddParticipantOnTag } from 'hooks/event/tags/useAddParticipantOnTag';
import { useState } from 'react';
import { getErrorMsg } from 'utils/getErrorMsg';

import { EventParticipantTableData } from '../Participants/EventParticipants.interface';

import style from './EventTag.module.css';

type Props = {
  eventId: string;
  oldParticipantId: string;
  tagId: string;
  tagName?: string;
  replaceParticipant: boolean;
  closeModal: () => void;
};

const columnHelper = createColumnHelper<EventParticipantTableData>();
const ROW_LIMIT = 7;

const AddRemoveParticipantTagForm = ({
  eventId,
  oldParticipantId,
  tagId,
  tagName,
  replaceParticipant,
  closeModal,
}: Props) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>(
    replaceParticipant && oldParticipantId != null
      ? { [oldParticipantId]: true }
      : {},
  );

  
  const { data, isFetching } = useFetchEventParticipants({
    eventId: eventId as string,
    limit: ROW_LIMIT,
    offset: (page - 1) * ROW_LIMIT,
    filter: search,
    tagId: tagId,
  });

  const { _route, data: eventParticipants } = data || {
    _route: { returned: 0, total: 0 },
    data: [],
  };

  console.log(eventParticipants);

  const noParticipantsAvailable = !search && eventParticipants?.length === 0;

  const selectedId = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {        
        //acc.splice(0, acc.length);
        acc.push(key);
      }
      return acc;
    },
    [],
  );

  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  const mutation = useAddParticipantOnTag();

  const onAddHandler = async (e) => {
    e.preventDefault();
    mutation.mutate(
      {
        eventId: eventId,
        tagIds: [tagId],
        participantId: selectedId[0],
        oldParticipantId: oldParticipantId,
      },
      {
        onSuccess: () => {
          closeModal();
          const participantName = eventParticipants?.filter(
            (s) => s._id === selectedId[0],
          )[0]?.firstLastName;
          notification.success(
            `Tag ${tagName} assigned to ${participantName}.`,
          );
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
      },
    );
  };

  const participantTableColumns = [
    columnHelper.accessor('firstLastName', {
      header: 'Participant',
    }),
    columnHelper.accessor('registrationId', {
      header: 'Registration ID',
    }),
    columnHelper.accessor('tagId.uniqueId', {
      header: 'Tag ID',
    }),
    columnHelper.accessor('organisation', {
      header: 'Organisation',
    }),
  ];

  return (
    <>
      <InputSearch
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <form onSubmit={onAddHandler}>
        {noParticipantsAvailable ? (
          <NotAvailable message="Oops, no participants available at the moment." />
        ) : (
          <TanStackTable
            getRowId={(row) => row._id}
            selectedRows={selectedRows}
            onRowSelectionChange={setSelectedRows}
            isLoading={isFetching}
            columns={participantTableColumns}
            pageIndex={page}
            pageSize={ROW_LIMIT}
            totalRows={_route.total}
            pageCount={Math.ceil(_route.total / ROW_LIMIT)}
            data={eventParticipants}
            onPageChange={handlePageChange}
            enableRowSelection={(row) =>
              oldParticipantId === row.id ? false : true
            }
            enableMultiRowSelection={false}
            rowClassName={(row) =>
              replaceParticipant && row._id === oldParticipantId
                ? 'text-gray-400'
                : ''
            }
          />
        )}
        <TLModalFooter
          className={style.modalFooter}
          cancelButton={
            <TLNeutralButton onClick={closeModal}>Cancel</TLNeutralButton>
          }
          actionButton={
            <TLSecondaryLoadingButton
              isLoading={isFetching}
              disabled={selectedId?.length === 0}
              type="submit"
            >
              Add
            </TLSecondaryLoadingButton>
          }
        />
      </form>
    </>
  );
};

export default AddRemoveParticipantTagForm;
