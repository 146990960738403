import { ClockIcon } from '@heroicons/react/24/outline';
import { ActionIcon, Grid, Stack, Textarea, TextInput } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useViewportSize } from '@mantine/hooks';
import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import GoogleAutocomplete from 'components/GoogleAutocomplete/GoogleAutocomplete';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import Select from 'components/Select/Select';
import dayjs from 'dayjs';
import { identity, pickBy } from 'lodash';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useEffect, useRef } from 'react';
import { useClients } from 'services/client/client';
import {
  useAddEvent,
  useEntityType,
  useEntity,
  useTimezoneByEvent,
  useUpdateEvent,
} from 'services/entity/home';
import { useEnumType } from 'services/enum/enum';
import {
  convertDateAndTime,
  convertDateAndTimeToUtc,
} from 'utils/createDateAndTime';

import {
  CreateAndUpdateEntityFirstStep,
  CreateAndUpdateEntityReq,
} from '../types';

const EntityInformationsContent = ({
  setFormState,
  formState,
  setIsFormValid,
  setOnFormSubmit,
  dataId,
}: ModalFormProps) => {
  const { height } = useViewportSize();
  const { data: clientsData } = useClients();
  const { data: entityTypeData } = useEntityType();
  const { data: timeZoneData } = useEnumType('timezone');
  const { data: eventData } = useEntity(dataId ?? '');
  const { data: timezoneData } = useTimezoneByEvent(dataId ?? '');
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);
  const form = useForm<CreateAndUpdateEntityFirstStep>({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      name: formState?.name ?? '',
      timezone: formState?.timezone ?? '',
      type: formState?.type ?? '',
      companyId: formState?.companyId ?? '',
      startDate: formState?.eventStartDate
        ? dayjs(
            convertDateAndTime(formState.eventStartDate, formState?.timezone),
          ).format('YYYY-MM-DD')
        : '',
      startTime: formState?.eventStartDate
        ? dayjs(
            convertDateAndTime(formState.eventStartDate, formState?.timezone),
          ).format('HH:mm')
        : '',
      endDate: formState?.eventFinishDate
        ? dayjs(
            convertDateAndTime(formState.eventFinishDate, formState?.timezone),
          ).format('YYYY-MM-DD')
        : '',
      endTime: formState?.eventFinishDate
        ? dayjs(
            convertDateAndTime(formState.eventFinishDate, formState?.timezone),
          ).format('HH:mm')
        : '',
      location: formState?.location?.address ?? '',
      description: formState?.description ?? '',
    },
    validate: {
      name: (value) => (!value ? 'Name is required' : null),
      companyId: (value) => (!value ? 'Client is required' : null),
      type: (value) => (!value ? 'Type is required' : null),
      timezone: (value) => (!value ? 'Timezone is required' : null),
      startDate: (value) => (!value ? 'Start date is required' : null),
      endDate: (value) => (!value ? 'End date is required' : null),
      startTime: (value) => (!value ? 'Start time is required' : null),
      endTime: (value) => (!value ? 'End time is required' : null),
    },
  });
  const { mutate: addEventData } = useAddEvent();
  const { mutate: updateEventData } = useUpdateEvent();

  useEffect(() => {
    if (eventData && timezoneData) {
      form.setFieldValue('name', eventData.name);
      form.setFieldValue('description', eventData.description);
      form.setFieldValue('timezone', eventData.timezone);
      form.setFieldValue('type', eventData.type);
      form.setFieldValue(
        'companyId',
        eventData.companyId ? eventData.companyId._id : '',
      );
      form.setFieldValue('description', eventData.description);
      form.setFieldValue(
        'startDate',
        dayjs(
          convertDateAndTime(eventData.eventStartDate, timezoneData),
        ).format('YYYY-MM-DD'),
      );
      form.setFieldValue(
        'startTime',
        dayjs(
          convertDateAndTime(eventData.eventStartDate, timezoneData),
        ).format('HH:mm'),
      );
      form.setFieldValue(
        'endDate',
        dayjs(
          convertDateAndTime(eventData.eventFinishDate, timezoneData),
        ).format('YYYY-MM-DD'),
      );
      form.setFieldValue(
        'endTime',
        dayjs(
          convertDateAndTime(eventData.eventFinishDate, timezoneData),
        ).format('HH:mm'),
      );
      form.setFieldValue('location', eventData.location);
    }
  }, [eventData, timezoneData]);

  useEffect(() => {
    if (form.isValid()) {
      setIsFormValid(true);
      const newFormState = form.values;
      if (setFormState) {
        const firstStep: Partial<CreateAndUpdateEntityReq> = {
          name: newFormState.name,
          type: newFormState.type,
          timezone: newFormState.timezone,
          companyId: newFormState.companyId,
          eventStartDate:
            newFormState.startDate &&
            newFormState.startTime &&
            newFormState.timezone
              ? convertDateAndTimeToUtc(
                  newFormState.startDate + 'T' + newFormState.startTime,
                  newFormState.timezone,
                )
              : null,
          eventFinishDate:
            newFormState.endDate &&
            newFormState.endTime &&
            newFormState.timezone
              ? convertDateAndTimeToUtc(
                  newFormState.endDate + 'T' + newFormState.endTime,
                  newFormState.timezone,
                )
              : null,
          location: newFormState.location ?? null,
          description: newFormState.description ?? null,
        };
        const cleandedData = pickBy(firstStep, identity);

        setFormState((prevState) => ({
          ...prevState,
          ...cleandedData,
        }));
      }
    } else {
      setIsFormValid(false);
    }
  }, [form.isValid(), form.values]);

  const onSubmit = (data) => {
    if (dataId) {
      updateEventData({ data: data, eventId: dataId });
    } else {
      addEventData(data);
    }
  };

  useEffect(() => {
    if (setOnFormSubmit) {
      setOnFormSubmit(() => onSubmit);
    }
  }, [setOnFormSubmit]);

  const handleAddressSelect = (
    lat: number,
    lng: number,
    address: string,
    metadata: any,
  ) => {
    if (address) {
      form.setValues({
        location: {
          description: '',
          longitude: lng,
          latitude: lat,
          address: address,
          metadata: metadata,
          name: address,
        },
      });
    } else {
      form.setValues({
        location: null,
      });
    }
  };

  const startTimePickerControl = (
    <ActionIcon
      variant="subtle"
      color="#0F172A"
      onClick={() => startTimeRef.current?.showPicker()}
    >
      <ClockIcon width={20} height={20} />
    </ActionIcon>
  );

  const endTimePickerControl = (
    <ActionIcon
      variant="subtle"
      color="#0F172A"
      onClick={() => endTimeRef.current?.showPicker()}
    >
      <ClockIcon width={20} height={20} />
    </ActionIcon>
  );

  return (
    <ScrollArea.Autosize
      mah={height - 12.75 * 24}
      mr="-1rem"
      variant="thumb"
      scrollbarSize={6}
      styles={{ root: { paddingRight: '0.5rem' } }}
    >
      <Stack>
        <TextInput
          placeholder="Name"
          label="Entity name"
          withAsterisk
          key={form.key('name')}
          {...form.getInputProps('name')}
        />

        <Select
          label="Timezone"
          placeholder="Select timezone"
          data={timeZoneData?.map((item) => ({
            value: item.key,
            label: item.name,
          }))}
          key={form.key('timezone')}
          {...form.getInputProps('timezone')}
          withAsterisk
        />

        <Grid>
          <Grid.Col span={6}>
            <Stack>
              <Select
                label="Type"
                placeholder="Select type"
                data={entityTypeData?.map((item) => ({
                  value: item.type,
                  label: item.description,
                }))}
                key={form.key('type')}
                {...form.getInputProps('type')}
                withAsterisk
              />

              <DatePickerInput
                label="Event start date"
                showAsterisk={true}
                value={form.values.startDate}
                onChange={(date) => form.setFieldValue('startDate', date)}
                // errorMessage={handleError(form.errors.startDate)}
              />

              <DatePickerInput
                label="Event end date"
                showAsterisk={true}
                value={form.values.endDate}
                onChange={(date) => form.setFieldValue('endDate', date)}
                // errorMessage={handleError(form.errors.startDate)}
              />
            </Stack>
          </Grid.Col>
          <Grid.Col span={6}>
            <Stack>
              <Select
                withAsterisk
                label="Client"
                placeholder="Select client"
                data={clientsData?.map((item) => ({
                  value: item._id,
                  label: item.name,
                }))}
                key={form.key('companyId')}
                {...form.getInputProps('companyId')}
              />

              <TimeInput
                label="Event start time"
                withAsterisk
                key={form.key('startTime')}
                ref={startTimeRef}
                rightSection={startTimePickerControl}
                {...form.getInputProps('startTime')}
              />

              <TimeInput
                label="Event end time"
                withAsterisk
                key={form.key('endTime')}
                ref={endTimeRef}
                rightSection={endTimePickerControl}
                {...form.getInputProps('endTime')}
              />
            </Stack>
          </Grid.Col>
        </Grid>

        {form?.values?.location?.address ? (
          <GoogleAutocomplete
            onAddressSelect={handleAddressSelect}
            name={form?.values?.location?.address}
          />
        ) : (
          <GoogleAutocomplete
            onAddressSelect={handleAddressSelect}
            name={formState?.location?.address}
          />
        )}

        <Textarea
          placeholder="Short description about the organisation"
          label="Description"
          key={form.key('description')}
          {...form.getInputProps('description')}
        />
      </Stack>
    </ScrollArea.Autosize>
  );
};

export default EntityInformationsContent;
