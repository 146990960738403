import { DocumentStatus } from 'types/enums';

export interface LayoutTableData {
  _id: string;
  name: string;
  startDateTime: string;
  endDateTime: string;
  maxParticipants: string;
  ageLimit: string;
  status: string;
  description: string;
}

interface ImageInfo {
  width: number;
  height: number;
  name: string;
}

export interface Image {
  originalName: string;
  extension: string;
  mimeType: string;
  largeSource: string;
  largeInfo?: ImageInfo;
  mediumSource: string;
  mediumInfo?: ImageInfo;
  smallSource: string;
  smallInfo?: ImageInfo;
  status: DocumentStatus;
  metaData?: any;
}

export enum EventLayoutPresentationType {
  GoogleMap = 'googlemap',
  Image = 'image',
}

export interface EventLayoutPresentationGoogle {
  type: EventLayoutPresentationType.GoogleMap;
  geoRange: {
    zoom: number;
    center: {
      lat: number;
      lng: number;
    };
  };
}

export interface EventLayoutPresentationImage {
  type: EventLayoutPresentationType.Image;
  image: Image;
  // By default its 1000x1000 for pins
}

export type EventLayoutPresentation =
  | EventLayoutPresentationImage
  | EventLayoutPresentationGoogle;

export type EventLayout = {
  _id: string;
  eventId: string;
  startDateTime: Date | null;
  startTime: string;
  endDateTime: Date | null;
  endTime: string;
  locked: boolean;
  maxParticipants: number;
  participantMaxAge: number;
  participantMinAge: number;
  name: string;
  validationQR: number;
  presentation?: EventLayoutPresentation;
  status: string;
  description: string;
};
