import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { TagTableData } from 'pages/Tags/interfaces';
import { client } from 'services/api';
import { Response } from 'types/response';
import { GET_ALL_TAGS } from 'utils/apiUrl';

import { tagBaseKey } from './baseKey';

interface Props {
  row_limit: number;
  offset: number;
  status?: string;
  filter?: string;
}

export const useFetchTags = ({ row_limit, offset, status, filter }: Props) => {
  const queryClient = useQueryClient();
  const queryResult = useQuery<Response<TagTableData[]>, AxiosError>({
    queryKey: [tagBaseKey, row_limit, offset, status, filter],
    queryFn: async () => {
      const retVal = await client.get<Response<TagTableData[]>>(
        GET_ALL_TAGS(row_limit, offset, status, filter),
      );

      retVal.data.data.forEach(async (tag) =>
        queryClient.setQueryData<TagTableData>([tagBaseKey, tag._id], tag),
      );

      return retVal.data;
    },
  });

  return queryResult;
};
