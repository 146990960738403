import {
  HomeIcon,
  PlusIcon,
  TagIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/solid';
import { UserIcon } from '@heroicons/react/24/solid';
import { ActionIcon, Flex, Grid, Image, Paper, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { MultipleDetectionItemProps } from '../types';

const MultipleDetectionItem = ({
  id,
  isTagItem,
  image,
  upperRowTitle,
  upperRowSubtitle,
  lowerFirstCell,
  lowerSecondCell,
  handleAddParticipant,
  handleAddTag,
}: MultipleDetectionItemProps) => {
  const Icon = lowerFirstCell === 'asset' ? WrenchScrewdriverIcon : UserIcon;

  const isMobile = useMediaQuery('(max-width: 1024px)');
  return (
    <Paper
      p="xs"
      bd="1px solid var(--gray-200)"
      bg="var(--gray-0)"
      style={{
        height: '5rem',
        display: 'flex',
        borderRadius: '0.5rem',
        cursor: 'pointer',
      }}
      onClick={() => {
        if (handleAddParticipant && isTagItem === false) {
          handleAddParticipant(id);
        } else if (handleAddTag && isTagItem === true) {
          handleAddTag(id);
        }
      }}
    >
      <Grid style={{ width: '100%' }}>
        {!isTagItem && (
          <Grid.Col span={isTagItem ? 2 : 1} mr={isMobile ? 20 : 0}>
            {image ? (
              <Image
                src={image}
                alt="img"
                style={{ height: '100%', objectFit: 'cover' }}
              />
            ) : (
              <Flex
                w="3.75rem"
                h="3.75rem"
                bg="var(--gray-200)"
                justify="center"
                align="center"
                style={{ borderRadius: '0.25rem' }}
              >
                <Icon width="2rem" height="2rem" color="var(--gray-300)" />
              </Flex>
            )}
          </Grid.Col>
        )}

        <Grid.Col span={8}>
          <Grid
            style={{ height: '100%' }}
            gutter={6}
            ml={!isTagItem ? 'lg' : ''}
          >
            <Grid.Col span={12}>
              <Text
                fw={500}
                c={'var(--gray-900)'}
                fz={14}
                style={{ display: 'inline' }}
                truncate="end"
              >
                {upperRowTitle}
              </Text>
              {upperRowSubtitle && !isMobile && (
                <Text
                  fw={500}
                  c={'var(--gray-500)'}
                  fz={14}
                  style={{ display: 'inline', marginLeft: '8px' }}
                  truncate="end"
                >
                  {`(${upperRowSubtitle})`}
                </Text>
              )}
            </Grid.Col>

            <Grid.Col span={isTagItem ? 10 : 9}>
              <Flex align="center" gap={2}>
                {isTagItem ? (
                  <>
                    <HomeIcon
                      height="1.5rem"
                      width="1.5rem"
                      color="var(--gray-400)"
                    />
                    <Text fz={14} c={'var(--gray-500)'} truncate="end">
                      {lowerFirstCell}
                    </Text>
                  </>
                ) : (
                  <>
                    <UserIcon
                      height="1.5rem"
                      width="1.5rem"
                      color="var(--gray-400)"
                    />
                    <Text fz={14} c={'var(--gray-500)'} truncate="end">
                      {lowerFirstCell}
                    </Text>
                  </>
                )}
              </Flex>
            </Grid.Col>
            <Grid.Col span={isTagItem ? 2 : 3}>
              <Flex align="center" gap={2}>
                {isTagItem ? (
                  <>
                    <UserIcon
                      height="1.5rem"
                      width="1.5rem"
                      color="var(--gray-400)"
                    />
                    <Text fz={14} c={'var(--gray-500)'}>
                      {lowerSecondCell}
                    </Text>
                  </>
                ) : (
                  <>
                    <TagIcon
                      height="1.5rem"
                      width="1.5rem"
                      color="var(--gray-400)"
                    />
                    <Text fz={14} c={'var(--gray-500)'}>
                      {lowerSecondCell}
                    </Text>
                  </>
                )}
              </Flex>
            </Grid.Col>
          </Grid>
        </Grid.Col>

        <Grid.Col
          span={isTagItem ? 3 : 2}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <ActionIcon
            style={{
              height: isMobile ? '30px' : '60px',
              width: isMobile ? '30px' : '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#ffffff',
            }}
          >
            <PlusIcon
              height={isMobile ? '30px' : '60px'}
              width={isMobile ? '30px' : '60px'}
              color="var(--blue-200)"
            />
          </ActionIcon>
        </Grid.Col>
      </Grid>
    </Paper>
  );
};

export default MultipleDetectionItem;
