import { ButtonGroup } from '@lanaco/lnc-react-ui';
import { Button } from '@lanaco/lnc-react-ui';

import styles from './ButtonGroupData.module.css';

export interface ButtonGroupDataInterface {
  indicator?: string;
  text: string;
  text1?: string;
  active?: boolean;
  onClick?: () => void;
}

type Props = {
  buttons: Button[];
  widthButtonGroup?: any;
  width?: any;
};

const ButtonGroupData = (props: Props) => (
  <div className={styles.ButtonGroupData}>
    <ButtonGroup style={{ width: props.widthButtonGroup }}>
      {props.buttons?.map((item: Button, index) => {
        return (
          <Button
            btnType={'basic'}
            iconStyle={'regular'}
            color={item.active ? 'success' : 'neutral'}
            className={styles.button}
            onClick={(e) => {
              e.preventDefault();
              item.onClick?.();
            }}
            name={index}
            key={index}
            style={{ width: props.width }}
          >
            <img src={item.indicator} alt="" />
            {item.text} {item.text1}
          </Button>
        );
      })}
    </ButtonGroup>
  </div>
);

export default ButtonGroupData;
