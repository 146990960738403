import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/16/solid';
import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Text,
  TextInput,
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { SortingParamType } from 'components/SortingDropdown/types';
import { useFilters } from 'context/FilterProvider';
import { debounce } from 'lodash';
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from 'mantine-react-table';
import { addTagsSortingParams } from 'pages/Entities/consts';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useAddTagsToEvent, useTags } from 'services/entity/tag';
import { states, statuses } from 'utils/consts';

import SortingDropdown from '../../../../../components/SortingDropdown/SortingDropdown';
import AdditionalFilters from '../../LeftHubContainer/AdditionalFilters/AdditionalFilters';
import { mapTagToAddTagTable } from '../../mapper';
import { TagTableType } from '../../types';
import styles from '../TableModals.module.css';

const AddTagsModal = () => {
  let eventId = window.location.href.split('/').at(-2);
  const [sortingParam, setSortingParam] = useState<SortingParamType | null>(
    null,
  );
  const [showAdditionalFilters, setShowAdditionalFilters] = useState<boolean>(
    false,
  );
  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>(
    {},
  );
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
  const [filteredData, setFilteredData] = useState<TagTableType[] | []>([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isAtBottom, setIsAtBottom] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(20);
  const { filter } = useFilters();
  const { height } = useViewportSize();
  const { mutate: addTagToEventMutate } = useAddTagsToEvent();
  const { data: { tags } = {}, isLoading: isLoadingTags } = useTags(
    limit,
    debouncedSearchTerm,
    filter,
    sortingParam ?? undefined,
  );

  const selectedIds = useMemo(() => {
    return Object.keys(rowSelection).map(
      (rowId) => filteredData[Number(rowId)]?._id,
    );
  }, [rowSelection, filteredData]);

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      if (value?.length >= 3) setDebouncedSearchTerm(value);
      else if (value?.length === 0) setDebouncedSearchTerm('');
    }, 500),
    [],
  );

  const handleSubmitModal = () => {
    if (eventId) addTagToEventMutate({ eventId: eventId, tagIds: selectedIds });
  };

  useEffect(() => {
    if (tags) {
      setFilteredData(mapTagToAddTagTable(tags));
    }
  }, [tags]);

  useUpdateEffect(() => {
    if (isAtBottom) {
      setLimit((prev) => prev + 20);
    }
  }, [isAtBottom]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        const threshold = 100;
        const atBottom = scrollHeight - scrollTop - clientHeight <= threshold;

        if (setIsAtBottom) {
          setIsAtBottom(atBottom);
        }
      }
    };

    const viewport = scrollRef.current;
    if (viewport) {
      viewport.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (viewport) {
        viewport.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const columns = useMemo<MRT_ColumnDef<TagTableType>[]>(
    () => [
      {
        header: 'Device',
        accessorKey: 'device',

        Cell: ({ cell }) => {
          return (
            <div className={`${styles.firstColumn}`}>
              {cell.getValue() as React.ReactNode}
            </div>
          );
        },
      },
      {
        header: 'Type',
        accessorKey: 'type',
      },
      {
        header: 'Model',
        accessorKey: 'model',
      },
      {
        header: 'Tag ID',
        accessorKey: 'tagID',
      },
      {
        header: 'Status',
        accessorKey: 'status',

        Cell: ({ row }) => {
          const status = row.original.status;
          return (
            <Badge
              variant="light"
              color={status === 'Enabled' ? '#10B981' : '#64748B'}
              radius={12}
              fz="0.75rem"
              tt="none"
            >
              {status}
            </Badge>
          );
        },
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data: filteredData,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    enableRowSelection: true,
    enablePagination: true,
    manualPagination: true,
    state: {
      isLoading: isLoadingTags,
      rowSelection,
    },

    onRowSelectionChange: (newRowSelection) => {
      setRowSelection(newRowSelection);
    },

    mantineTableContainerProps: {
      ref: scrollRef,
      style: {
        maxHeight: height > 750 ? height / 1.8 : height / 2,
      },
    },

    mantineTableBodyRowProps: ({ row }) => {
      return {
        className: row.getIsSelected() ? styles.selectedRow : '',
      };
    },

    mantineSelectAllCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantineSelectCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantinePaperProps: {
      style: {
        boxShadow: 'none',
        borderRadius: '0.5rem',
        borderColor: '#E2E8F0',
      },
    },
  });
  return (
    <div className={styles.tableContainer}>
      <Flex mr="0.5rem" mb="1.5rem" justify="center">
        <TextInput
          size="sm"
          mr="0.75rem"
          fz="1rem"
          radius="md"
          flex={1}
          placeholder="Search"
          onChange={(e) => debouncedSetSearchTerm(e.target.value)}
          leftSection={
            <MagnifyingGlassIcon
              height="1.25rem"
              width="1.25rem"
              color="#94A3B8"
            />
          }
        />
        <Flex align="center" gap="0.75rem">
          <SortingDropdown
            sortingParams={addTagsSortingParams}
            sortingParam={sortingParam}
            setSortingParam={setSortingParam}
          />
          <ActionIcon
            bg="#ffffff"
            c={showAdditionalFilters ? '#3B82F6' : '#0F172A'}
            onClick={() => setShowAdditionalFilters((prev) => !prev)}
          >
            <AdjustmentsHorizontalIcon height="1.5rem" width="1.5rem" />
          </ActionIcon>
        </Flex>
      </Flex>

      {showAdditionalFilters && (
        <AdditionalFilters
          statuses={statuses.map(({ value, label }) => ({
            id: value,
            value: label,
          }))}
          states={states.map(({ value, label }) => ({
            id: value,
            value: label,
          }))}
        />
      )}

      <MantineReactTable table={table} />

      <Flex mt="2rem" justify="space-between" align="center">
        <Text>{Object.keys(rowSelection).length} rows selected</Text>

        <Flex justify="flex-end" gap="1rem">
          <Button
            bg="#ffffff"
            bd="1px solid #e2e8f0"
            c="#344155"
            onClick={modals.closeAll}
          >
            Cancel
          </Button>
          <Button
            bg="#3b82f6"
            c="#ffffff"
            onClick={() => {
              handleSubmitModal();
              modals.closeAll();
            }}
          >
            Add
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};
export default AddTagsModal;
