import {
  CloudArrowUpIcon,
  EllipsisVerticalIcon,
  FolderArrowDownIcon,
} from '@heroicons/react/24/solid';
import { ActionIcon, Menu, Switch } from '@mantine/core';
import { useEntitiesContext } from 'context/EntityProvider';
import { useState } from 'react';

const SettingsDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>();

  const {
    showHubCardCheckbox,
    setShowHubCardCheckbox,
    setIndeterminateHubCheckbox,
    setSelectAllHubCard,
    setSelectedHubCardIds,
  } = useEntitiesContext();

  const handleBulkSelectionCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!e.currentTarget.checked)
      setShowHubCardCheckbox(e.currentTarget.checked);
    else {
      setShowHubCardCheckbox(e.currentTarget.checked);
      setIndeterminateHubCheckbox(false);
      setSelectAllHubCard(false);
      setSelectedHubCardIds([]);
    }
  };

  return (
    <Menu
      width={190}
      position="bottom-end"
      offset={0}
      onOpen={() => setIsDropdownOpen(true)}
      onClose={() => setIsDropdownOpen(false)}
    >
      <Menu.Target>
        <ActionIcon bg={isDropdownOpen ? '#e2e8f0' : '#ffffff'} c="#475569">
          <EllipsisVerticalIcon height="1.5rem" width="1.5rem" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          leftSection={
            <CloudArrowUpIcon
              height="1.25rem"
              width="1.25rem"
              color="#94A3B8"
            />
          }
        >
          Import
        </Menu.Item>
        <Menu.Divider m={0} />
        <Menu.Item
          leftSection={
            <FolderArrowDownIcon
              height="1.25rem"
              width="1.25rem"
              color="#94A3B8"
            />
          }
        >
          Export
        </Menu.Item>
        <Menu.Divider m={0} />
        <Menu.Item
          rightSection={
            <Switch
              size="md"
              checked={showHubCardCheckbox}
              onChange={handleBulkSelectionCheckbox}
              styles={{
                track: {
                  backgroundColor: showHubCardCheckbox ? '#059669' : '#e11d48',
                  cursor: 'pointer',
                },
              }}
            />
          }
          closeMenuOnClick={false}
          styles={{ item: { cursor: 'default' } }}
        >
          Bulk selection
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default SettingsDropdown;
