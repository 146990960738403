import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventTagAssignParticipantService } from 'services/event/tag/event-tag-assign-participant';

import { eventParticipantBaseKey } from '../participants/eventParticipantBaseKey';

import { eventTagBaseKey } from './baseKeys';

interface Props {
  eventId: string;
  participantId: string;
  oldParticipantId?: string;
  oldTagIds?: string[];
  tagIds: string[];
}

export const useAddParticipantOnTag = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      eventId,
      participantId,
      oldParticipantId,
      oldTagIds,
      tagIds,
    }: Props) => {
      if (!eventId) return;
      if (oldParticipantId != null) {
        const resp = await EventTagAssignParticipantService.unassignParticipantFromEventTag(
          {
            eventId,
            tagIds: tagIds,
            participantId: oldParticipantId,
          },
        );
        if (resp?.data.code === 201) {
          const response = await EventTagAssignParticipantService.assignParticipantOnEventTag(
            {
              eventId,
              tagIds: tagIds,
              participantId,
            },
          );
          console.log(response?.data);
          return response?.data;
        }
      } else if (oldTagIds != null && oldTagIds?.length > 0) {
        const resp = await EventTagAssignParticipantService.unassignParticipantFromEventTag(
          {
            eventId,
            tagIds: oldTagIds,
            participantId,
          },
        );
        if (resp?.data.code === 201) {
          const response = await EventTagAssignParticipantService.assignParticipantOnEventTag(
            {
              eventId,
              tagIds: tagIds,
              participantId,
            },
          );
          return response?.data;
        }
      } else {
        const resp = await EventTagAssignParticipantService.assignParticipantOnEventTag(
          {
            eventId,
            tagIds: tagIds,
            participantId,
          },
        );
        return resp?.data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([eventTagBaseKey]);
      queryClient.invalidateQueries([eventParticipantBaseKey]);
    },
  });

  return mutation;
};
