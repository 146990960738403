import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DeviceTableData } from 'pages/Devices/interfaces';
import { client } from 'services/api';
import { Response } from 'types/response';
import { GET_ALL_DEVICES } from 'utils/apiUrl';

import { deviceBaseKey } from './baseKeys';

interface Props {
  row_limit: number;
  offset: number;
  status?: string;
  filter?: string;
}

export const useFetchDevices = ({
  row_limit,
  offset,
  status,
  filter,
}: Props) => {
  const queryClient = useQueryClient();
  const queryResult = useQuery<Response<DeviceTableData[]>, AxiosError>({
    queryKey: [deviceBaseKey, row_limit, offset, filter, status],
    queryFn: async () => {
      const retVal = await client.get<Response<DeviceTableData[]>>(
        GET_ALL_DEVICES(row_limit, offset, status, filter),
      );

      retVal.data.data.forEach(async (device) =>
        queryClient.setQueryData<DeviceTableData>(
          [deviceBaseKey, device.id],
          device,
        ),
      );

      return retVal.data;
    },
  });
  return queryResult;
};
