import { TextInput, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { identity, pickBy } from 'lodash';
import { CreateAndUpdateEntityReq } from 'pages/Entities/Home/types';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useEffect } from 'react';
import { useEntity, useUpdateEvent } from 'services/entity/home';

import { CreateAndUpdateEntityAboutContact } from '../types';

import styles from './Modals.module.scss';

const ContactModal = ({
  setFormState,
  setIsFormValid,
  dataId,
  setOnFormSubmit,
}: ModalFormProps) => {
  const form = useForm<CreateAndUpdateEntityAboutContact>({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      contact: '',
      email: '',
      phoneNumber: '',
    },
    validate: {
      email: (value) =>
        !value || /^\S+@\S+\.\S+$/.test(value)
          ? null
          : 'Incorrect email address format',
    },
  });
  const { data: eventData } = useEntity(dataId ?? '');
  const { mutate: updateEventData } = useUpdateEvent();

  useEffect(() => {
    if (eventData) {
      form.setFieldValue('contact', eventData?.contactPerson?.name ?? '');
      form.setFieldValue('email', eventData?.contactPerson?.email ?? '');
      form.setFieldValue(
        'phoneNumber',
        eventData?.contactPerson?.phoneNumber ?? '',
      );
    }
  }, [eventData]);

  useEffect(() => {
    if (setIsFormValid) setIsFormValid(true);
  }, [setIsFormValid]);

  useEffect(() => {
    if (form.isValid()) {
      setIsFormValid(true);
      const newFormState = form.values;
      if (setFormState) {
        const contactStep: Partial<CreateAndUpdateEntityReq> = {
          contactPerson: {
            name: newFormState.contact,
            email: newFormState.email,
            phoneNumber: newFormState.phoneNumber,
          },
        };
        const cleandedData = pickBy(contactStep, identity);
        setFormState((prevState) => ({
          ...prevState,
          ...cleandedData,
        }));
      }
    } else {
      setIsFormValid(false);
    }
  }, [form.values, form.isValid()]);

  const onSubmit = (data) => {
    if (dataId) {
      updateEventData({ data: data, eventId: dataId });
    }
  };

  useEffect(() => {
    if (setOnFormSubmit) {
      setOnFormSubmit(() => onSubmit);
    }
  }, [setOnFormSubmit]);

  return (
    <Stack gap="1.5rem">
      <TextInput
        classNames={{ label: styles.labelStyle }}
        label="Contact person"
        placeholder="e.g.John Doe"
        key={form.key('contact')}
        {...form.getInputProps('contact')}
      />
      <TextInput
        classNames={{ label: styles.labelStyle }}
        label="Email"
        placeholder="e.g.johm@company.com"
        key={form.key('email')}
        {...form.getInputProps('email')}
      />
      <TextInput
        classNames={{ label: styles.labelStyle }}
        label="Phone number"
        placeholder="e.g. 049157006"
        key={form.key('phoneNumber')}
        {...form.getInputProps('phoneNumber')}
      />
    </Stack>
  );
};
export default ContactModal;
