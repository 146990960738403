import { useCallback } from 'react';

import { CreateOrUpdateParticipantForm } from '../CreateOrUpdateParticipantForm';
import { EventParticipantTableData } from '../EventParticipants.interface';

export const useRenderCreateOrUpdateParticipantForm = (eventId: string) =>
  useCallback(
    (onClose: () => void, data: EventParticipantTableData, isEdit: boolean) => (
      <CreateOrUpdateParticipantForm
        eventId={eventId}
        participant={data}
        onClose={onClose}
        isEditMode={isEdit}
      />
    ),
    [eventId],
  );
