import { DropdownItem, DropdownMenu, Separator } from '@lanaco/lnc-react-ui';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Link, useNavigate } from 'react-router-dom';
import { getSession } from 'services/auth';
import { logout } from 'store/slices/userSlice/userSlice';

import cog from '../../assets/icons/cog.svg';
import arrowPath from '../../assets/icons/outline/arrow-path.svg';
import arrowRightOnRectangle from '../../assets/icons/outline/arrow-right-on-rectangle.svg';
import eye from '../../assets/icons/outline/eye.svg';

import style from './UserSettings.module.css';
import { unregisterToken } from 'services/firebase';

type Props = {
  loggedUser: any;
  role: any;
  company?: any;
  initials: string;
};

const UserSettings = (props: Props) => {
  const { session } = getSession();
  let dispatch = useAppDispatch();
  let navigate = useNavigate();
  const onClickLogout = async () => {
    await unregisterToken();
    dispatch(logout());
    navigate('/login');
  };

  return (
    <div>
      <DropdownMenu
        zIndex={21}
        control={
          // TODO: replace anchor or have real href
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#" className={style.profile}>
            <div className={style.profilePicture}>
              <div>{props.initials}</div>
              {/* <img src={man} alt="User" /> */}
            </div>
            <div className={style.text}>
              <p>
                <span>
                  {props.loggedUser} <br />
                  <span className={style.company}>{props.company}</span>
                </span>
              </p>
            </div>
          </a>
        }
        closeOnItemSelect={true}
        portalTarget={document.body}
        placement={'right'}
        className={style.dropdownMenu}
      >
        {session?.institutionId && (
          <>
            <DropdownItem name="profile" className={style.dropdownItem}>
              <div className={style.menuItem}>
                <div className={style.monogram}>L</div>
                <span>
                  {props.company} <br />{' '}
                  <span className={style.role}>{props.role}</span>
                </span>
              </div>
            </DropdownItem>
            <Separator className={style.separator} />
            <DropdownItem
              onClick={() => {
                //location.pathname = '/settings';
              }}
              name="settings"
              className={style.dropdownItem}
            >
              <div className={style.menuItem}>
                <Link to={'/settings'}>
                  <img src={cog} alt="Cog" width={'18px'} height={'18px'}></img>
                  <span className={style.content}>Settings</span>
                </Link>
              </div>
            </DropdownItem>
            <Separator className={style.separator} />
            <DropdownItem
              // onClick={() => {
              //   if (editModalRef.current) {
              //     editModalRef.current.open();
              //   }
              // }}
              name="switch"
              className={style.dropdownItem}
            >
              <div className={style.menuItem}>
                <img
                  src={arrowPath}
                  alt="Arrow path"
                  width={'18px'}
                  height={'18px'}
                ></img>
                <span className={style.content}>Switch dashboard</span>
              </div>
            </DropdownItem>

            <Separator className={style.separator} />
          </>
        )}

        {props.role === 'System admin' && (
          <div>
            <DropdownItem
              // onClick={() => {
              //   if (editModalRef.current) {
              //     editModalRef.current.open();
              //   }
              // }}
              name="viewDataAs"
              className={style.dropdownItem}
            >
              <div className={style.menuItem}>
                <img
                  src={eye}
                  alt="View data"
                  width={'18px'}
                  height={'18px'}
                ></img>
                <span className={style.content}>View data as</span>
              </div>
            </DropdownItem>
            <Separator className={style.separator} />
          </div>
        )}
        <DropdownItem
          onClick={onClickLogout}
          name="logout"
          className={style.dropdownItem}
        >
          <div className={style.menuItem}>
            <img
              src={arrowRightOnRectangle}
              alt="Logout"
              width={'18px'}
              height={'18px'}
            ></img>
            <span className={style.content}>Logout</span>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </div>
  );
};

export default UserSettings;
