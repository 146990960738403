import { InputGroup } from 'components/Input';
import { DateInputGroup } from 'components/Input/DateInputGroup';
import { DropDownInputGroup } from 'components/Input/DropDownInputGroup';
import { useDateField } from 'components/Input/hooks/useDateField';
import { useDropdownField } from 'components/Input/hooks/useDropdownField';
import { useNumberField } from 'components/Input/hooks/useNumberField';
import { useTimeField } from 'components/Input/hooks/useTimeField';
import { NumberInputGroup } from 'components/Input/NumberInput';
import { TimeInputGroup } from 'components/Input/TimeInput';
import { ResponsiveRow } from 'components/utils/ResponsiveRow/ResponsiveRow';
import { useZodForm } from 'hooks/useZodForm';
import { useFormContext } from 'react-hook-form';

import { useMultiStep } from '../MultiStepForm/context/multi-step-context';
import { MultiStepFormContent } from '../MultiStepForm/MultiStepFormContent/MultiStepFormContent';
import { MultiStepFormContentFooter } from '../MultiStepForm/MultiStepFormContent/MultiStepFormContentFooter/MultiStepFormContentFooter';
import {
  CreateTimingLocationSchema,
  DetailsSchema,
  detailsSchema,
} from '../MultiStepForm/timingLocation-schema';

const types = [
  { value: 'transition', label: 'Transition (In/Out)' },
  {
    value: 'timingPoint',
    label: 'Timing Point',
  },
];

type DetailsFormProps = {
  defaultValues: DetailsSchema;
  step: number;
  onCancel?: () => void;
};

export const DetailsForm = ({
  defaultValues,
  step,
  onCancel,
}: DetailsFormProps) => {
  const { next } = useMultiStep();

  const {
    setValue: setContextValue,
  } = useFormContext<CreateTimingLocationSchema>();

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useZodForm<DetailsSchema>(
    {
      defaultValues,
      mode: 'onBlur',
    },
    detailsSchema,
  );

  const [{ startDateTime, endDateTime }, onDateChange] = useDateField({
    names: ['startDateTime', 'endDateTime'],
    setValue,
    watch,
  });

  const [{ capacity }, onNumberChange] = useNumberField({
    names: ['capacity'],
    setValue,
    watch,
  });

  const [{ startTime, endTime }, onTimeChange] = useTimeField({
    names: ['startTime', 'endTime'],
    setValue,
    watch,
  });

  const [{ type }, onDropdownChange] = useDropdownField({
    names: ['type'],
    setValue,
    watch,
  });

  const onSubmit = (data: DetailsSchema) => {
    reset(data);
    setContextValue('details', data);
    next();
  };

  return (
    <MultiStepFormContent step={step}>
      <InputGroup
        withAsterisk
        register={register}
        label="Name"
        placeholder="Name"
        name="name"
        errors={errors}
      />

      <DropDownInputGroup
        withAsterisk
        register={register}
        label="Type"
        placeholder="Select Type"
        name="type"
        value={type}
        errors={errors}
        options={types}
        onChange={onDropdownChange}
      />

      <ResponsiveRow>
        <DateInputGroup
          register={register}
          name="startDateTime"
          label="Start date"
          placeholder="e.g. 01/01/2023"
          errors={errors}
          value={startDateTime!}
          onChange={onDateChange}
        />

        <TimeInputGroup
          name="startTime"
          label="Start time"
          placeholder=""
          errors={errors}
          value={startTime}
          onChange={onTimeChange}
          register={register}
        />
      </ResponsiveRow>
      <ResponsiveRow>
        <DateInputGroup
          register={register}
          name="endDateTime"
          label="End date"
          placeholder="e.g. 01/01/2023"
          value={endDateTime!}
          errors={errors}
          withLargeErrorMessage={errors.endDateTime?.message !== 'Required'}
          onChange={onDateChange}
        />
        <TimeInputGroup
          name="endTime"
          label="Finish time"
          placeholder=""
          errors={errors}
          value={endTime}
          onChange={onTimeChange}
          register={register}
        />
      </ResponsiveRow>

      <NumberInputGroup
        register={register}
        label="Capacity"
        placeholder="Capacity"
        name="capacity"
        errors={errors}
        value={capacity}
        onChange={onNumberChange}
      />

      <MultiStepFormContentFooter
        isValid={isValid}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCancel}
      />
    </MultiStepFormContent>
  );
};

export default DetailsForm;
