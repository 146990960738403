import { notification } from '@lanaco/lnc-react-ui';
import { ButtonGroupDataInterface } from 'components/ButtonGroupData/ButtonGroupData.interface';
import Header from 'components/Header/Header';
import { StatInterface } from 'components/Stat/Stat.interface';
import Stats from 'components/Stats/Stats';
import {
  CodebookAction,
  Column,
  Table,
  TableOptionsButton,
  TableResponse,
} from 'components/Table';
import { useEffect, useState } from 'react';
import { client } from 'services/api';
import { DELETE_DEVICE, GET_ALL_DEVICES, GET_DEVICE } from 'utils/apiUrl';

import idle from '../../assets/icons/indicator-idle.svg';
import offline from '../../assets/icons/indicator-offline.svg';
import online from '../../assets/icons/indicator-online.svg';
import arrowSmallDown from '../../assets/icons/mini/arrow-small-down.svg';
import arrowSmallUp from '../../assets/icons/mini/arrow-small-up.svg';

import { DeviceTableData } from './interfaces';
import UserForm from './UserForm';
type Props = {};

const stats: Array<StatInterface> = [
  {
    title: 'Enabled',
    amount: '95 of 97 total',
    icon: arrowSmallUp,
    badge: 'secondary',
    value: 12,
  },
  {
    title: 'Average per event',
    amount: '98',
    icon: arrowSmallUp,
    badge: 'secondary',
    value: 2.02,
  },
  {
    title: 'Required per month',
    amount: '76',
    icon: arrowSmallDown,
    badge: 'danger',
    value: 4.05,
  },
];

const buttons: Array<ButtonGroupDataInterface> = [
  {
    text: 'All',
    text1: '97',
    indicator: '',
  },
  {
    text: 'Online',
    text1: '3',
    indicator: online,
  },
  {
    text: 'Idle',
    text1: '2',
    indicator: idle,
  },
  {
    text: 'Offline',
    text1: '90',
    indicator: offline,
  },
  {
    text: 'Disabled',
    text1: '2',
    indicator: '',
  },
];

const Settings = (props: Props) => {
  const [deviceList, setDeviceList] = useState<Array<DeviceTableData>>([]);
  const [loading, setLoading] = useState(true);
  const [selectedEntirePage, setSelectedEntirePage] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<Array<DeviceTableData>>([]);
  const [offset, setOffset] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tableResponse, setTableResponse] = useState<TableResponse>();
  const limit = 5;

  const columns: Column<any>[] = [
    {
      id: 'id',
      displayName: 'ID',
      accessor: 'id',
      width: 0,
      render: (rowData: DeviceTableData) => {
        return (
          <div style={{ width: '0', textAlign: 'left' }}>{rowData.id}</div>
        );
      },
      sortable: true,
    },
    {
      id: 'email',
      displayName: 'Email',
      accessor: 'email',
      width: 15,
      render: (rowData: DeviceTableData) => {
        return (
          <div style={{ width: '100%', textAlign: 'left' }}>
            {rowData.macAddress}
          </div>
        );
      },
      sortable: true,
    },
    {
      id: 'userName',
      displayName: 'Username',
      accessor: 'userName',
      width: 15,
      render: (rowData: DeviceTableData) => {
        return (
          <div style={{ width: '100%', textAlign: 'left' }}>
            {rowData.macName}
          </div>
        );
      },
      sortable: true,
    },
    {
      id: 'firstName',
      displayName: 'First name',
      accessor: 'firstName',
      width: 15,
      render: (rowData: DeviceTableData) => {
        return (
          <div style={{ width: '100%', textAlign: 'left' }}>
            {rowData.status}
          </div>
        );
      },
      sortable: true,
    },
    {
      id: 'lastName',
      displayName: 'Last name',
      accessor: 'lastName',
      width: 15,
      render: (rowData: DeviceTableData) => {
        return (
          <div style={{ width: '100%', textAlign: 'left' }}>
            {rowData.status}
          </div>
        );
      },
      sortable: true,
    },
    {
      id: 'gender',
      displayName: 'Gender',
      accessor: 'gender',
      width: 10,
      render: (rowData: DeviceTableData) => {
        return (
          <div style={{ width: '100%', textAlign: 'left' }}>
            {rowData.status}
          </div>
        );
      },
      sortable: true,
    },
    {
      id: 'phoneNumber',
      displayName: 'Phone number',
      accessor: 'phoneNumber',
      width: 10,
      render: (rowData: DeviceTableData) => {
        return (
          <div style={{ width: '100%', textAlign: 'left' }}>
            {rowData.status}
          </div>
        );
      },
      sortable: true,
    },
    {
      id: 'accountStatus',
      displayName: 'Account status',
      accessor: 'gender',
      width: 10,
      render: (rowData: DeviceTableData) => {
        return (
          <div style={{ width: '100%', textAlign: 'left' }}>
            {rowData.status}
          </div>
        );
      },
      sortable: true,
    },
    {
      id: 'options',
      displayName: '',
      width: 10,
      accessor: 'status',
      render: (rowData: any) => {
        return (
          <TableOptionsButton
            actions={[
              CodebookAction.edit,
              CodebookAction.disable,
              CodebookAction.delete,
            ]}
            editLabel="Edit user"
            deleteLabel="Delete user"
            disableLabel={
              rowData.status === 'active' ? 'Disable user' : 'Enable device'
            }
            deleteButtonName="Delete"
            disableButtonName={
              rowData.status === 'active' ? 'Disable' : 'Enable'
            }
            confirmButtonName="Save"
            warningMessageDelete="Are you sure you want to delete user?"
            warningMessageDisable={
              rowData.status === 'active'
                ? 'Are you sure you want to disable user?'
                : 'Are you sure you want to enable user?'
            }
            onDeleteClick={deleteClick}
            onDisableClick={disableEnableClick}
            id={rowData.id}
            status={rowData.status}
            renderEditComponent={renderSettingsFormComponent}
            data={rowData}
          ></TableOptionsButton>
        );
      },
      sortable: true,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const response = await client.get(GET_ALL_DEVICES());
      setDeviceList(response.data.data);
    };

    try {
      fetchData();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const deleteClick = async (id: string, modal: any) => {
    const response = client.get(GET_DEVICE(id));
    if (response != null) {
      const deleteResponse = await client.delete(DELETE_DEVICE(id));
      if (deleteResponse.data.code === 201) {
        modal.current.close();
        notification.success('Device successfully deleted.');
      }
    }
  };

  const disableEnableClick = (id: string, modal: any) => {
    modal.current.close();

    console.log('Disable/enable ');
    console.log(id);
  };

  const renderSettingsFormComponent = (
    onCloseListener: any,
    data: any,
    isEdit: boolean,
  ) => {
    return (
      <UserForm
        closeModal={onCloseListener}
        data={data}
        isEdit={isEdit}
      ></UserForm>
    );
  };

  return (
    <div>
      <Header title="Users"></Header>
      <Stats stats={stats}></Stats>
      <Table
        rowIdentifier="id"
        renderAddComponent={renderSettingsFormComponent}
        addFormTitle="Add new user"
        addButtonTitle="New user"
        setSelectedData={setSelectedData}
        selectedData={selectedData}
        selectedEntirePage={selectedEntirePage}
        buttonsGroup={buttons}
        data={deviceList}
        columns={columns}
        loading={loading}
        actions={[CodebookAction.all]}
        onSelectAll={(e: any, IsSelected: boolean) => {
          if (!IsSelected) {
            setSelectedEntirePage(true);
            setSelectedData(
              deviceList.map((x: DeviceTableData) => ({
                ...x,
                device: x.id,
              })),
            );
          } else {
            setSelectedEntirePage(false);
            setSelectedData([]);
          }
        }}
        onSelectRow={(
          e: any,
          RowData: DeviceTableData,
          IsSelected: boolean,
        ) => {
          let copy: any;
          if (IsSelected) {
            copy = [...selectedData];
            setSelectedData(copy.filter((x: any) => x.id !== RowData.id));
            setSelectedEntirePage(copy.length === 0);
          } else {
            copy = [
              ...selectedData,
              {
                id: RowData.id,
              },
            ];
            setSelectedData(copy);
            setSelectedEntirePage(copy.length === deviceList.length);
          }
        }}
        offset={offset}
        limit={limit}
        setOffset={setOffset}
        tableResponse={tableResponse}
      ></Table>
    </div>
  );
};

export default Settings;
