import {
  ChevronRightIcon,
  ChartBarSquareIcon,
  TvIcon,
  BellIcon,
} from '@heroicons/react/24/solid';
import {
  ActionIcon,
  Breadcrumbs,
  Button,
  Flex,
  Tabs,
  Text,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEntitiesContext } from 'context/EntityProvider';
import PageNotFound from 'pages/PageNotFound';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useEntity } from 'services/entity/home';

import { entityDetailsTabs } from '../tabsConfig';

import { EntityDetailsParams } from './types';

const EntityDetails = () => {
  const navigate = useNavigate();
  const { id, tab } = useParams<EntityDetailsParams>();
  const { data: eventData } = useEntity(id ?? '');
  const isMobile = useMediaQuery('(max-width: 768px)');

  const {
    breadcrumbs,
    removeBreadcrumbs,
    addBreadcrumb,
    initialBreadcrumbsState,
  } = useEntitiesContext();

  const activeTab = isMobile ? 'admission' : tab || 'hub';

  useEffect(() => {
    initialBreadcrumbsState();
    if (eventData?.name) {
      addBreadcrumb(eventData.name, `/entities/${id}`);
    }
  }, [eventData, id]);

  if (!Object.hasOwn(entityDetailsTabs, activeTab)) {
    return <PageNotFound />;
  }

  const ActiveTabComponent = entityDetailsTabs[activeTab].panel;

  return (
    <>
      <Flex justify="space-between" mb="1.25rem">
        {!isMobile && (
          <Breadcrumbs separator={<ChevronRightIcon />}>
            {breadcrumbs.map((item, index, array) => (
              <Text
                key={index}
                c="#64748b"
                onClick={() => {
                  if (index !== array.length - 1) {
                    removeBreadcrumbs(index);
                    navigate(item.href);
                  }
                }}
              >
                {item.title}
              </Text>
            ))}
          </Breadcrumbs>
        )}

        {!isMobile && (
          <Flex gap="0.75rem">
            <Button bg="#fff1f2" c="#f43f5e" radius="md">
              Live
            </Button>
            <Button
              bg="#ffffff"
              c="#3B82F6"
              bd="1px solid #e2e8f0"
              leftSection={
                <ChartBarSquareIcon height="1.25rem" width="1.25rem" />
              }
            >
              Timeline
            </Button>
            <Button
              bg="#ffffff"
              c="#3B82F6"
              bd="1px solid #e2e8f0"
              leftSection={<TvIcon height="1.25rem" width="1.25rem" />}
            >
              Public view
            </Button>
            <ActionIcon
              h="inherit"
              w="2.25rem"
              bg="#ffffff"
              c="#94A3B8"
              bd="1px solid #e2e8f0"
            >
              <BellIcon height="1.25rem" width="1.25rem" />
            </ActionIcon>
          </Flex>
        )}
      </Flex>

      <Tabs value={activeTab} mb="1.5rem">
        {!isMobile && (
          <Tabs.List>
            {Object.entries(entityDetailsTabs).map(([key, tabObj]) => (
              <Tabs.Tab
                key={key}
                value={key}
                leftSection={<tabObj.icon />}
                onClick={() => navigate(`/entities/${id}/${key}`)}
              >
                {tabObj.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        )}
      </Tabs>
      <ActiveTabComponent />
    </>
  );
};

export default React.memo(EntityDetails);
