import { Box } from '@mantine/core';
import { useEntitiesContext } from 'context/EntityProvider';
import { hubSections } from 'pages/Entities/consts';
import { openModal } from 'pages/Entities/ModalManager/ModalsManager';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHubSectionStatistics } from 'services/entity/home';

import { mapHubSectionStatisticsToHubSectionStatisticsType } from '../mapper';
import { setHubModalContent } from '../Modals/hubModalsConfig';
import { HubSectionDataType } from '../types';

import HubSection from './HubSection/HubSection';

const RightHubContainer = () => {
  const {
    selectedSection,
    setSelectedSection,
    handleResetState,
  } = useEntitiesContext();

  const { id } = useParams<{ id?: string }>();

  const {
    data: trackingObjectStatisticsData,
    isLoading: trackingObjectIsLoading,
  } = useHubSectionStatistics(id ?? '', 'participant');
  const {
    data: sectionStatisticsData,
    isLoading: sectionIsLoading,
  } = useHubSectionStatistics(id ?? '', 'section');
  const {
    data: layoutStatisticsData,
    isLoading: layoutIsLoading,
  } = useHubSectionStatistics(id ?? '', 'layout');
  const {
    data: tagStatisticsData,
    isLoading: tagIsLoading,
  } = useHubSectionStatistics(id ?? '', 'tag');
  const {
    data: deviceStatisticsData,
    isLoading: deviceIsLoading,
  } = useHubSectionStatistics(id ?? '', 'device');
  const [data, setData] = useState<HubSectionDataType[] | []>([]);

  useEffect(() => {
    if (trackingObjectStatisticsData) {
      setData((prevData) => [
        ...prevData,
        mapHubSectionStatisticsToHubSectionStatisticsType(
          trackingObjectStatisticsData,
          'trackingObjects',
          'totalParticipants',
          'totalOnline',
          'totalIdle',
          'totalOffline',
        ),
      ]);
    }
    if (layoutStatisticsData) {
      setData((prevData) => [
        ...prevData,
        mapHubSectionStatisticsToHubSectionStatisticsType(
          layoutStatisticsData,
          'layouts',
          'totalCount',
          'totalOnline',
          'totalCountIdle',
          'totalCountOffline',
        ),
      ]);
    }
    if (sectionStatisticsData) {
      setData((prevData) => [
        ...prevData,
        mapHubSectionStatisticsToHubSectionStatisticsType(
          sectionStatisticsData,
          'sections',
          'totalCount',
          'totalCountOnline',
          'totalCountIdle',
          'totalCountOffline',
        ),
      ]);
    }
    if (deviceStatisticsData) {
      setData((prevData) => [
        ...prevData,
        mapHubSectionStatisticsToHubSectionStatisticsType(
          deviceStatisticsData,
          'devices',
          'totalDevices',
          'totalDevicesOnline',
          'totalDevicesIdle',
          'totalDevicesOffline',
        ),
      ]);
    }
    if (tagStatisticsData) {
      setData((prevData) => [
        ...prevData,
        mapHubSectionStatisticsToHubSectionStatisticsType(
          tagStatisticsData,
          'tags',
          'totalTags',
          'totalTagsOnline',
          'totalTagsIdle',
          'totalTagsOffline',
        ),
      ]);
    }
  }, [
    trackingObjectStatisticsData,
    sectionStatisticsData,
    layoutStatisticsData,
    deviceStatisticsData,
    tagStatisticsData,
  ]);

  const loaderMap: Record<string, boolean> = {
    trackingObjects: trackingObjectIsLoading,
    layouts: layoutIsLoading,
    sections: sectionIsLoading,
    devices: deviceIsLoading,
    tags: tagIsLoading,
  };

  return (
    <Box>
      {hubSections.map((hubSection) => {
        const hubSectionData = data.find((y) => y.type === hubSection.type);

        return (
          <HubSection
            key={hubSection.type}
            active={hubSection.type === selectedSection.type}
            loader={loaderMap[hubSection.type]}
            titleIcon={hubSection.icon}
            title={hubSection.title}
            totalNumber={hubSectionData?.total}
            progressBarData={hubSectionData?.progressBarData}
            handleClickOnSection={() => {
              setSelectedSection(hubSection);
              handleResetState(hubSection.type);
            }}
            handleClickOnAddButton={() =>
              openModal({
                type: hubSection.type,
                size: hubSection.modalSize,
                title: hubSection.modalTitle,
                subtitles: hubSection.modalSubTitles,
                isMultistep: hubSection.type === 'trackingObjects',
                hasFooter: hubSection.hasFooter,
                setModalContent: setHubModalContent,
              })
            }
          />
        );
      })}
    </Box>
  );
};

export default RightHubContainer;
