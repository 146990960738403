import Stat from 'components/Stat/Stat';
import { StatProps } from 'components/Stat/types';

import styles from './Stats.module.css';

type Props = {
  stats: any;
};

const Stats = (props: Props) => {
  return (
    <div className={styles.stats}>
      {props.stats.map((item: StatProps, index) => {
        return (
          <div
            key={item.title}
            className={
              index !== props.stats.length - 1 ? styles.stat : styles.statLast
            }
          >
            <Stat
              title={item.title}
              amount={item.amount}
              color={item.color}
              total={item.total}
              icon={item.icon}
              badge={item.badge}
              value={item.value}
              percent={item.percent}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Stats;
