import { notification } from "@lanaco/lnc-react-ui";
import { ButtonColor, ButtonType } from "components/Button";
import { ButtonActionGroup } from "components/ButtonActionGroup";
import { InputGroup } from "components/Input";
import { DateInputGroup } from "components/Input/DateInputGroup";
import { DropDownInputGroup } from "components/Input/DropDownInputGroup";
import { useDateField } from "components/Input/hooks/useDateField";
import {
	type ParticipantSchema,
	useParticipantForm,
} from "hooks/event/participants";
import { useMutateEventParticipant } from "hooks/event/participants/useMutateEventParticipant";
import type { SubmitHandler } from "react-hook-form";
import type { EventParticipantPayload } from "types";
import type { DocumentStatus } from "types/enums";
import { genderOptions, statusOptions } from "utils/dropdown-options";
import { getErrorMsg } from "utils/getErrorMsg";

import { PersonSubtype, TrackedSubjectType } from "../../interfaces";
import type { EventParticipantTableData } from "../EventParticipants.interface";
import { useParticipantDropdowns } from "../hooks";

import styles from "./CreateOrUpdateParticipantForm.module.css";

interface CreateOrUpdateParticipantFormProps {
	eventId: string;
	participant?: EventParticipantTableData;
	onClose: any; // () => void;
	isEditMode: boolean;
}

export const CreateOrUpdateParticipantForm = ({
	eventId,
	isEditMode,
	participant,
	onClose,
}: CreateOrUpdateParticipantFormProps) => {
	const {
		register,
		watch,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useParticipantForm(participant);

	const {
		gender,
		onChangeDropdown,
		status: statusDropdownValue,
	} = useParticipantDropdowns(watch, setValue);

	const [{ birthday }, onDateChange] = useDateField({
		names: ["birthday"],
		setValue,
		watch,
	});

	const mutation = useMutateEventParticipant(eventId);

	const onConfirm: SubmitHandler<ParticipantSchema> = async (data) => {
		const { firstName, lastName, status, birthday } = data;

		const eventParticipant: EventParticipantPayload = {
			...data,
			eventId,
			_id: participant?._id,
			birthday: birthday ? new Date(birthday).toDateString() : undefined,
			gender: gender || undefined,
			status: status as DocumentStatus,
			firstLastName: `${firstName} ${lastName}`,
			lastFirstName: `${lastName} ${firstName}`,

			trackedSubjectType: {
				type: TrackedSubjectType.PERSON,
				personSubtype: PersonSubtype.Participant,
			},
		};

		mutation.mutate(eventParticipant, {
			onSuccess: () => {
				notification.success(
					`Participant successfully ${isEditMode ? "updated" : "created"}.`,
				);
				onClose();
			},
			onError: (error) => {
				notification.error(getErrorMsg(error));
			},
		});
	};

	return (
		<form
			className={styles.CreateOrUpdateParticipantForm}
			onSubmit={handleSubmit(onConfirm, console.log)}
		>
			<div className={styles.content}>
				<InputGroup
					register={register}
					name="firstName"
					label="First Name"
					placeholder="John"
					errors={errors}
				/>
				<InputGroup
					register={register}
					name="lastName"
					label="Last Name"
					placeholder="Doe"
					errors={errors}
				/>
				<DropDownInputGroup
					register={register}
					placeholder="Select gender"
					name="gender"
					label="Gender"
					onChange={onChangeDropdown}
					value={gender}
					options={genderOptions}
					errors={errors}
				/>
				<DateInputGroup
					register={register}
					name="birthday"
					label="Date of Birth"
					placeholder="e.g. 01/15/2000"
					errors={errors}
					onChange={onDateChange}
					value={birthday === undefined ? undefined : new Date(birthday)}
				/>
				<InputGroup
					register={register}
					name="email"
					label="Email"
					placeholder="e.g. john@company.com"
					errors={errors}
				/>
				<InputGroup
					register={register}
					name="phoneNumber"
					label="Phone"
					placeholder="e.g. +1 123 456 7890"
					errors={errors}
				/>
				<InputGroup
					register={register}
					name="country"
					label="Country"
					placeholder=""
					errors={errors}
				/>
				<InputGroup
					register={register}
					name="postcode"
					label="Postal code"
					placeholder=""
					errors={errors}
				/>
				<InputGroup
					register={register}
					name="organisation"
					label="Organisation"
					placeholder=""
					errors={errors}
				/>
				<DropDownInputGroup
					register={register}
					placeholder="Select status"
					name="status"
					label="Status"
					errors={errors}
					onChange={onChangeDropdown}
					value={statusDropdownValue}
					options={statusOptions}
				/>
				<InputGroup
					register={register}
					name="externalId"
					label="External ID"
					placeholder="Optional"
					errors={errors}
				/>
			</div>
			<ButtonActionGroup
				buttonProps={[
					{
						id: "cancel",
						text: "Cancel",
						btnType: ButtonType.OUTLINE,
						color: ButtonColor.NEUTRAL,
						onClick: onClose,
					},
					{
						id: "confirm",
						text: "Confirm",
						type: "submit",
						onClick: handleSubmit(onConfirm),
						btnType: ButtonType.FILLED,
						color: ButtonColor.PRIMARY,
					},
				]}
			/>
		</form>
	);
};
