import { useEffect } from 'react';

const DEFAULT_CENTER: google.maps.LatLngLiteral = {
  lat: -33.860664,
  lng: 151.208138,
};

export const useGeolocation = (
  readLocation: boolean,
  setCenter?: (center: google.maps.LatLngLiteral) => void,
) => {
  useEffect(() => {
    if (!readLocation || !setCenter) return;

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      () => {
        setCenter(DEFAULT_CENTER);
      },
    );
  }, [readLocation, setCenter]);
};
