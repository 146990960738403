import { ErrorMessage } from '@hookform/error-message';
import { Dropdown } from '@lanaco/lnc-react-ui';
import { Text } from '@mantine/core';
import { InputError } from 'components/Input';
import { FocusEvent, useMemo } from 'react';
import {
  FieldErrors,
  FieldPath,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';

import styles from './DropDownInputGroup.module.scss';

export interface DropdownSelectedOption {
  value: unknown;
  label: string;
}

export interface DropdownAction {
  action: string;
  name: string;
  option?: undefined;
}

export type DropdownOnChange<T extends FieldValues> = (
  data: DropdownSelectedOption,
  action: {
    action: string;
    name: FieldPath<T>;
    option?: undefined;
  },
) => void;

export interface DropDownInputGroupProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  name: FieldPath<T>;
  label: string;
  placeholder: string;
  options: { value: string; label: string }[];
  errors?: FieldErrors;
  isDisabled?: boolean;
  isLoading?: boolean;
  value?: unknown;
  withAsterisk?: boolean;
  defaultValue?: unknown;
  defaultInputValue?: string;
  onInputChange?: (value: string) => void;
  onChange?: DropdownOnChange<T>;
  onBlur?: (e?: FocusEvent<HTMLSelectElement>) => void;
}

export const DropDownInputGroup = <T extends FieldValues>({
  register,
  name,
  label,
  placeholder,
  options,
  errors,
  isDisabled = false,
  withAsterisk,
  isLoading,
  value,
  defaultValue,
  defaultInputValue,
  onInputChange,
  onChange,
  onBlur,
}: DropDownInputGroupProps<T>) => {
  const valueLocal = useMemo(() => {
    const valueFound = options.find((o) => o.value === value);
    return valueFound
      ? ({
          label: valueFound.label,
          value,
        } as DropdownSelectedOption)
      : undefined;
  }, [value, options]);

  return (
    <label className={styles.inputGroup}>
      <span className={styles.label}>
        {label}
        {withAsterisk && (
          <Text component="span" c="red">
            *
          </Text>
        )}
      </span>
      <Dropdown
        {...register(name)}
        placeholder={placeholder}
        options={options}
        className={styles.select}
        isDisabled={isDisabled}
        value={valueLocal}
        defaultValue={defaultValue}
        defaultInputValue={defaultInputValue}
        isLoading={isLoading}
        loadingMessage={() => 'Loading...'}
        onBlur={onBlur}
        onChange={onChange}
        onInputChange={onInputChange}
      />

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <InputError message={message} />}
      />
    </label>
  );
};
