import { Badge, notification } from "@lanaco/lnc-react-ui";
import { useQueryClient } from "@tanstack/react-query";
import {
	createColumnHelper,
	type RowSelectionState,
} from "@tanstack/react-table";
import idle from "assets/icons/indicator-idle.svg";
import offline from "assets/icons/indicator-offline.svg";
import online from "assets/icons/indicator-online.svg";
import arrowSmallDown from "assets/icons/mini/arrow-small-down.svg";
import arrowSmallUp from "assets/icons/mini/arrow-small-up.svg";
import clsx from "clsx";
import {
	TLDangerButton,
	TLNeutralButton,
	TLPrimaryButton,
} from "components/Button";
import ButtonGroupData from "components/ButtonGroupData/ButtonGroupData";
import type { ButtonGroupDataInterface } from "components/ButtonGroupData/ButtonGroupData.interface";
import Header from "components/Header/Header";
import { TLModal } from "components/Modal";
import type { StatInterface } from "components/Stat/Stat.interface";
import Stats from "components/Stats/Stats";
import TanStackTable, {
	type RowAction,
} from "components/TanStackTable/TanStackTable";
import { useFetchDevices } from "hooks/device";
import { deviceBaseKey } from "hooks/device/baseKeys";
import { deviceStatisticsBaseKey } from "hooks/event/statistics";
import { useFetchDeviceStatistics } from "hooks/event/statistics/useFetchDeviceStatistics";
import { useState } from "react";
import { useModal } from "react-modal-hook";
import { client } from "services/api";
import type { Response } from "types/response";
import { TOGGLE_DEVICE_STATUSES } from "utils/apiUrl";

import { DeleteDeviceModal } from "./DeleteDeviceModal/DeleteDeviceModal";
import { DeleteDevicesModal } from "./DeleteDevicesModal/DeleteDevicesModal";
import DeviceForm from "./DeviceForm";
import type { DeviceTableData } from "./interfaces";
import { ToggleEnabledDeviceModal } from "./ToggleEnabledDeviceModal/ToggleEnabledDeviceModal";

type Props = {};

const columnHelper = createColumnHelper<DeviceTableData>();
const ROW_LIMIT = 10;

const deviceStatusToBadgeColorMap = {
	offline: "bg-gray-50 text-gray-500",
	online: "bg-green-50 text-green-500",
	idle: "bg-yellow-50 text-yellow-500",
};

const Device = (props: Props) => {
	const queryClient = useQueryClient();

	const [status, setStatus] = useState("all");
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);
	const [deviceToEdit, setDeviceToEdit] = useState<DeviceTableData | null>(
		null,
	);
	const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});

	const selectedIds = Object.entries(selectedRows).reduce<string[]>(
		(acc, [key, value]) => {
			if (value) {
				acc.push(key);
			}
			return acc;
		},
		[],
	);

	const [showInsertDeviceModal, hideInsertDeviceModal] = useModal(() => {
		const closeModal = () => {
			setDeviceToEdit(null);
			hideInsertDeviceModal();
		};

		return (
			<TLModal
				title={!deviceToEdit ? "Add new device" : "Edit device"}
				onClose={closeModal}
				hideFooter
			>
				<DeviceForm
					closeModal={closeModal}
					data={deviceToEdit}
					isEdit={!!deviceToEdit}
				/>
			</TLModal>
		);
	}, [deviceToEdit]);

	const [showToggleEnabledModal, hideToggleEnabledModal] = useModal(
		() => (
			<ToggleEnabledDeviceModal
				id={deviceToEdit?.id}
				status={deviceToEdit?.status}
				macName={deviceToEdit?.macName}
				onDeviceEdit={setDeviceToEdit}
				closeModal={hideToggleEnabledModal}
			/>
		),
		[deviceToEdit?.status, deviceToEdit?.macName, deviceToEdit?.id],
	);

	const [showDeleteTagModal, hideDeleteTagModal] = useModal(
		() => (
			<DeleteDeviceModal
				id={deviceToEdit?.id}
				macName={deviceToEdit?.macName}
				onDeviceEdit={setDeviceToEdit}
				closeModal={hideDeleteTagModal}
			/>
		),
		[deviceToEdit?.macName, deviceToEdit?.id],
	);

	const clearSelectedRows = () => setSelectedRows({});

	const [showDeleteDevicesModal, closeDeleteDevicesModal] = useModal(
		() => (
			<DeleteDevicesModal
				selectedIds={selectedIds}
				onDeviceEdit={setDeviceToEdit}
				closeModal={closeDeleteDevicesModal}
				clearSelectedRows={clearSelectedRows}
			/>
		),
		[selectedIds],
	);

	const { data, isFetching } = useFetchDevices({
		offset: (page - 1) * ROW_LIMIT,
		row_limit: ROW_LIMIT,
		status,
		filter: search,
	});

	const { _route, data: devices } = data || {
		_route: { returned: 0, total: 0 },
		data: [],
	};

	devices.sort((a, b) => a.macName.localeCompare(b.macName));

	const handlePageChange = (pageNumber: number) => setPage(pageNumber);

	const { data: deviceStatistics } = useFetchDeviceStatistics();

	const enabledDevices =
		deviceStatistics &&
		deviceStatistics?.totalDevices - deviceStatistics?.disabledDevices;

	const isMassEnabling = selectedIds.some(
		(id) =>
			queryClient.getQueryData<DeviceTableData>([deviceBaseKey, id])?.status ===
			"disabled",
	);

	const getHandleStatusFilterChangeCallback = (status: string) => () => {
		setPage(1);
		setStatus(status);
	};

	const buttons: Array<ButtonGroupDataInterface> = [
		{
			text: "All",
			text1: deviceStatistics?.totalDevices?.toString(),
			indicator: "",
			onClick: getHandleStatusFilterChangeCallback("all"),
		},
		{
			text: "Online",
			text1: deviceStatistics?.onlineDevices?.toString(),
			indicator: online,
			onClick: getHandleStatusFilterChangeCallback("online"),
		},
		{
			text: `Idle ${deviceStatistics?.idleDevices || 0}`,
			indicator: idle,
			onClick: getHandleStatusFilterChangeCallback("idle"),
		},
		{
			text: "Offline",
			text1: deviceStatistics?.offlineDevices?.toString(),
			indicator: offline,
			onClick: getHandleStatusFilterChangeCallback("offline"),
		},
		{
			text: "Disabled",
			text1: deviceStatistics?.disabledDevices?.toString(),
			indicator: "",
			onClick: getHandleStatusFilterChangeCallback("disabled"),
		},
	];

	const stats: Array<StatInterface> = [
		{
			title: "Enabled",
			amount:
				`${enabledDevices} of ${deviceStatistics?.totalDevices} total`,
			icon: arrowSmallUp,
			badge: "secondary",
			value: 12,
		},
		{
			title: "Average per event",
			amount: deviceStatistics?.averageDevicesPerEvent?.toString(),
			icon: arrowSmallUp,
			badge: "secondary",
			value: 2.02,
		},
		{
			title: "Required in next 30 days",
			amount: "76",
			icon: arrowSmallDown,
			badge: "danger",
			value: 4.05,
		},
	];

	const deviceTableColumns = [
		columnHelper.accessor("macName", {
			header: "Device",
		}),
		columnHelper.accessor("macAddress", {
			header: "MAC address",
		}),
		columnHelper.accessor("manufacturer", {
			header: "Manufacturer",
		}),
		columnHelper.accessor("lastSeen", {
			header: "Last seen",
		}),
		columnHelper.accessor("lastLocation", {
			header: "Last Location",
		}),
		columnHelper.accessor("deviceStatus", {
			header: "State",
			cell: (row) => (
				<Badge
					className={clsx(
						"capitalize",
						deviceStatusToBadgeColorMap[row.getValue()],
					)}
				>
					{row.getValue()}
				</Badge>
			),
		}),
		columnHelper.accessor("status", {
			header: "Status",
			cell: (row) => <span className="capitalize">{row.getValue()}</span>,
		}),
	];

	const toggleDeviceStatus = async () => {
		const status = isMassEnabling ? "active" : "disabled";

		try {
			const response = await client.put<
				Response<{ matchedCount: number; modifiedCount: number }>
			>(TOGGLE_DEVICE_STATUSES(), {
				ids: selectedIds,
				status,
			});

			const { matchedCount, modifiedCount } = response.data.data;

			notification.success(
				`${modifiedCount} of ${matchedCount} Devices successfully ${
					isMassEnabling ? "enabled" : "disabled"
				}`,
			);
		} catch (error) {
			notification.error(error);
		} finally {
			queryClient.invalidateQueries([deviceBaseKey]);
			queryClient.invalidateQueries([deviceStatisticsBaseKey]);
		}
	};

	const handleEditClick = (row: DeviceTableData) => {
		setDeviceToEdit(row);
		showInsertDeviceModal();
	};

	const handleAddDeviceClick = () => {
		setDeviceToEdit(null);
		showInsertDeviceModal();
	};

	const handleToggleEnabledClick = (row: DeviceTableData) => {
		showToggleEnabledModal();
		setDeviceToEdit(row);
	};

	const handleDeleteClick = (row: DeviceTableData) => {
		showDeleteTagModal();
		setDeviceToEdit(row);
	};

	const deviceTableSelectedRowsActions = (
		<>
			<TLNeutralButton leadingIcon="times" onClick={clearSelectedRows}>
				Clear selected
			</TLNeutralButton>
			<TLNeutralButton
				leadingIcon="power-off"
				onClick={toggleDeviceStatus}
				className="text-red-500"
			>
				{isMassEnabling ? "Enable" : "Disable"}
			</TLNeutralButton>
			<TLDangerButton
				leadingIcon="trash"
				onClick={showDeleteDevicesModal}
				className="text-white"
			>
				Delete
			</TLDangerButton>
		</>
	);
	const deviceTableActions = (
		<>
			<TLPrimaryButton leadingIcon="plus" onClick={handleAddDeviceClick}>
				New device
			</TLPrimaryButton>
		</>
	);

	const deviceTableRowActions = (row: DeviceTableData): RowAction[] => [
		{
			icon: "pencil",
			key: "edit",
			label: "Edit",
			onClick: () => handleEditClick(row),
		},
		{
			icon: "power-off",
			key: "toggle-enabled",
			label: row.status === "disabled" ? "Enable" : "Disable",
			onClick: () => handleToggleEnabledClick(row),
		},
		{
			icon: "trash",
			key: "delete",
			label: "Delete",
			color: "danger",
			onClick: () => handleDeleteClick(row),
		},
	];

	return (
		<div>
			<Header title="Devices" onSearch={(e) => setSearch(e)} />
			<Stats stats={stats} />
			<TanStackTable
				getRowId={(row) => row.id}
				selectedRows={selectedRows}
				onRowSelectionChange={setSelectedRows}
				columns={deviceTableColumns}
				pageIndex={page}
				pageSize={ROW_LIMIT}
				totalRows={_route.total}
				pageCount={Math.ceil(_route.total / ROW_LIMIT)}
				data={devices}
				rowActions={deviceTableRowActions}
				onPageChange={handlePageChange}
				headerTitle={<ButtonGroupData buttons={buttons} />}
				isLoading={isFetching}
				rowClassName={(row) =>
					row.status === "disabled" ? "disabled-row text-gray-400" : ""
				}
				tableActions={
					selectedIds.length > 0
						? deviceTableSelectedRowsActions
						: deviceTableActions
				}
				statusIndicatorClassName={(row) => {
					if (row.deviceStatus === "online") return "bg-green-500";
					if (row.deviceStatus === "idle") return "bg-yellow-500";
					return "bg-gray-300";
				}}
				enableRowSelection
				showHeader
			/>
		</div>
	);
};

export default Device;
