import {
  FlexGrid,
  FlexGridItem,
  FormField,
  NotificationMessage,
  TextInput,
  notification,
} from '@lanaco/lnc-react-ui';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { client } from 'services/api';
import { RESEND_VERIFICATION_EMAIL, VERIFY_EMAIL } from 'utils/apiUrl';

import { RegistrationData } from './RegistrationConfirm.interfaces';
import style from './RegistrationConfirm.module.css';

type Props = {};

const RegistrationConfirm = (props: Props) => {
  // let dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationData>();
  const queryParams = new URLSearchParams(window.location.search);
  const term = { code: queryParams.get('code') };

  const [message, setMessage] = useState('');
  const [codeExpired, setCodeExpired] = useState(false);
  //client.post(VERIFY_EMAIL, term);
  const dataFetchedRef = useRef(false);
  const fetchData = async () => {
    const response = await client.post(VERIFY_EMAIL, term);
    if (response.data.code === 200) {
      setMessage(
        'Your registration has been successfully completed. To continue, you can click on Login.',
      );
    } else {
      setMessage(
        'Code expired or invalid. Please enter your email so we can send you a confirmation code.',
      );
      setCodeExpired(true);
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const onClickHandler = async () => {
    navigate('/login');
  };

  const onSubmitSendVerificationCode: SubmitHandler<RegistrationData> = async (
    data,
  ) => {
    const response = await client.post(RESEND_VERIFICATION_EMAIL, data);

    if (response.data.code === 200) {
      notification.success(
        <NotificationMessage title={'Info'}>
          Verification email successfully sent.
        </NotificationMessage>,
        {},
      );
    } else {
      notification.error(
        <NotificationMessage title={'Error'}>
          {response.data.message}
        </NotificationMessage>,
        {},
      );
    }
  };

  return (
    <FlexGrid>
      <FlexGridItem XS={12} M={12}>
        <div className={style.center}>
          <div className={style.sidebar}>
            <span className={style.naslov1}></span>
            <div className={style.naslov2}>{message}</div>

            {!codeExpired && (
              <div className={style.forgottenPass}>
                <button onClick={onClickHandler} className={style.loginButton}>
                  Login
                </button>
              </div>
            )}
            {codeExpired && (
              <div>
                {/* <button
                  onClick={onClickResendVerificationMailHandler}
                  className={style.loginButton}
                >
                  Resend verification email
                </button> */}

                <form
                  onSubmit={handleSubmit(onSubmitSendVerificationCode)}
                  className={style.registrationConfirm}
                >
                  <FormField
                    // label="Email"
                    text={errors.email && <span>{errors.email?.message}</span>}
                    color={errors.email && 'danger'}
                  >
                    <TextInput
                      placeholder={'Email'}
                      {...register('email', {
                        required: {
                          value: true,
                          message: 'Field Email is required.',
                        },
                      })}
                      color={errors.email && 'danger'}
                    />
                  </FormField>
                  <span>{errors?.email?.message}</span>
                  <button className={style.loginButton}>
                    Send confirmation code
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </FlexGridItem>
    </FlexGrid>
  );
};

export default RegistrationConfirm;
