import {
  ViewfinderCircleIcon,
  WrenchScrewdriverIcon,
  UserIcon,
  ClockIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CalendarDaysIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import { BarChart, PieChart } from '@mantine/charts';
import {
  Box,
  Divider,
  Flex,
  Grid,
  ScrollArea,
  Stack,
  Text,
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import ScheduleCard from 'components/ScheduleCard/ScheduleCard';
import { ScheduleCardProps } from 'components/ScheduleCard/types';
import Select from 'components/Select/Select';
import { addDays, format, subDays } from 'date-fns';
import React, { Fragment, useState } from 'react';

import ScheduleInformationModal from '../About/Modals/ScheduleInformationModal';
import { openModal } from '../ModalManager/ModalsManager';

import styles from '././EntityDashboard.module.scss';
import DashboardCard from './Components/DashboardCard';
import DonutChartComponent from './Components/DonutChart';
import LengthOfStayComponent from './Components/LengthOfStay';
import TemperatureHumidityDonutChart from './Components/TemperatureAndHumidityDonutChart';
import { DashboardCardType } from './types';

const selectUnits = ['Days', 'Weeks', 'Months', 'Years'];

const card1Data: DashboardCardType = {
  titleIcon: ViewfinderCircleIcon,
  title: 'Equipment',
  value1: '1,643',
  value1Color: '#3B82F6',
  icon: WrenchScrewdriverIcon,
  text: 'Total',
  value2: '2,750',
};
const card2Data: DashboardCardType = {
  titleIcon: ClockIcon,
  title: 'Equipment ALOS',
  value1: '5d 07h',
  value1Color: '#3B82F6',
  icon: WrenchScrewdriverIcon,
  text: 'Entity avg',
  value2: '1d 10h',
};

const card3Data: DashboardCardType = {
  titleIcon: ViewfinderCircleIcon,
  title: 'Visitors',
  value1: '1,032',
  value1Color: '#0891B2',
  icon: UserIcon,
  text: 'Total',
  value2: '3,612',
};
const card4Data: DashboardCardType = {
  titleIcon: ClockIcon,
  title: 'Visitors ALOS ',
  value1: '01h 10m',
  value1Color: '#0891B2',
  icon: UserIcon,
  text: 'Entity avg',
  value2: '01h 10m',
};

const dataCapacityStatus = [
  { value: 251, label: 'Occupied', name: 'Occupied', color: '#3B82F6' },
  { value: 48, label: 'Remaining', name: 'Remaining', color: '#E2E8F0' },
];
const dataLayouts = [
  { value: 2, label: 'Online', name: 'Online', color: '#10B981' },
  { value: 0, label: 'Idle', name: 'Idle', color: '#EAB308' },
  { value: 0, label: 'Offline', name: 'Offline', color: '#94A3B8' },
];
const dataSections = [
  { value: 15, label: 'Online', name: 'Online', color: '#10B981' },
  { value: 4, label: 'Idle', name: 'Idle', color: '#EAB308' },
  { value: 4, label: 'Offline', name: 'Offline', color: '#94A3B8' },
];
const dataDevices = [
  { value: 121, label: 'Online', name: 'Online', color: '#10B981' },
  { value: 25, label: 'Idle', name: 'Idle', color: '#EAB308' },
  { value: 60, label: 'Offline', name: 'Offline', color: '#94A3B8' },
];
const dataTotalTags = [
  { value: '84%', label: 'Assigned', name: 'Assigned', color: '#2563EB' },
  {
    value: '16%',
    label: 'Unassigned ',
    name: 'Unassigned',
    color: '#F43F5E',
  },
];
const dataTotalVisits = [
  { value: '76%', label: 'One-off', name: 'One-off', color: '#2563EB' },
  {
    value: '24%',
    label: 'Repeated ',
    name: 'Repeated',
    color: '#F43F5E',
  },
];
const dataGenderBreakdown = [
  { value: '65%', label: 'Male', name: 'Male', color: '#2563EB' },
  {
    value: '35%',
    label: 'Female ',
    name: 'Female',
    color: '#F43F5E',
  },
];
const dataAgeBreakdown = [
  { value: ' 65%', label: '0-20', name: '0-20', color: '#BFDBFE' },
  {
    value: '30%',
    label: '20-40 ',
    name: '20-40',
    color: '#93C5FD',
  },
  {
    value: '20%',
    label: '40-60 ',
    name: '40-60',
    color: '#60A5FA',
  },
  {
    value: '5%',
    label: '60-80 ',
    name: '60-80',
    color: '#3B82F6',
  },
];
const pieChartData = [
  { value: 40, name: 'Germany', color: '#3B82F6' },
  { value: 10, name: 'Japan', color: '#BFDBFE' },
  { value: 30, name: 'Switzarland', color: '#60A5FA' },
  { value: 10, name: 'France', color: '#DBEAFE' },
  { value: 10, name: 'Australia', color: '#93C5FD' },
  { value: 10, name: 'Other', color: '#CBD5E1' },
];
const trafficData = [
  { month: 'January', traffic: 5000 },
  { month: 'February', traffic: 6000 },
  { month: 'March', traffic: 18000 },
  { month: 'April', traffic: 16000 },
  { month: 'May', traffic: 20000 },
  { month: 'June', traffic: 25000 },
  { month: 'July', traffic: 22000 },
  { month: 'August', traffic: 21000 },
  { month: 'September', traffic: 17000 },
  { month: 'October', traffic: 19000 },
  { month: 'November', traffic: 7000 },
  { month: 'December', traffic: 23000 },
];
const Top50Data = [
  {
    name: 'Andrei Popescu',
    level: 'Ground floor',
    model: 'BMW',
    time: '43d 17h',
  },
  {
    name: 'Andrei Popescu',
    level: 'Ground floor',
    model: 'BMW',
    time: '41d 10h',
  },
  {
    name: 'Andrei Popescu',
    level: 'Ground floor',
    model: 'BMW',
    time: '38d 13h',
  },
  {
    name: 'Andrei Popescu',
    level: 'Ground floor',
    model: 'BMW',
    time: '35d 13h',
  },
  {
    name: 'Andrei Popescu',
    level: 'Ground floor',
    model: 'BMW',
    time: '27d 13h',
  },
  {
    name: 'Andrei Popescu',
    level: 'Ground floor',
    model: 'BMW',
    time: '21d 13h',
  },
  {
    name: 'Andrei Popescu',
    level: 'Ground floor',
    model: 'BMW',
    time: '5d 13h',
  },
  {
    name: 'Andrei Popescu',
    level: 'Ground floor',
    model: 'BMW',
    time: '1d 13h',
  },
];
const maxTime = Top50Data[0].time;
const schedulecards: Array<ScheduleCardProps> = [
  {
    startDate: '28 Feb 2025',
    startTime: '09:00 AM',
    text: 'The pathway to success',
    name: 'Martin Grimes',
    endTime: '02:30 PM',
    endDate: '28 Feb 2025',
    level: 'Ground level',
    type: 'Mercedes Benz',
  },
  {
    startDate: '28 Feb 2024',
    startTime: '',
    text: 'The pathway to success',
    name: 'Martin Grimes',
    endTime: '02:30 PM',
    endDate: '03 Jun 2024',
    level: 'Ground level',
    type: 'Mercedes Benz',
  },
  {
    startDate: '28 Feb 2024',
    startTime: '',
    text: 'The pathway to success',
    name: 'Martin Grimes',
    endTime: '02:30 PM',
    endDate: '28 Jun 2025',
    level: 'Ground level',
    type: 'Mercedes Benz',
  },
  {
    startDate: '28 Feb 2024',
    startTime: '',
    text: 'The pathway to success',
    name: 'Martin Grimes',
    endTime: '02:30 PM',
    endDate: '28 Jun 2025',
    level: 'Ground level',
    type: 'Mercedes Benz',
  },
  {
    startDate: '28 Feb 2024',
    startTime: '',
    text: 'The pathway to success',
    name: 'Martin Grimes',
    endTime: '02:30 PM',
    endDate: '28 Jun 2025',
    level: 'Ground level',
    type: 'Mercedes Benz',
  },
];

const EntityDashboard = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState<string | null>(
    selectUnits[0],
  );

  const { height, width } = useViewportSize();
  const responsiveWidth = width * 0.19;
  const responsiveHeight = height * 0.25;
  const responsiveSize = Math.min(responsiveWidth, responsiveHeight);

  const formattedDate = format(currentDate, 'dd MMM yyyy');
  const goToPreviousDay = () => {
    setCurrentDate((prevDate) => subDays(prevDate, 1));
  };

  const goToNextDay = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 1));
  };

  return (
    <>
      <Flex gap="1.5rem">
        <Stack gap="1.5rem" flex={1}>
          <Flex gap="1.5rem" flex={1}>
            <DashboardCard
              titleIcon={card1Data.titleIcon}
              title={card1Data.title}
              value1={card1Data.value1}
              value1Color={card1Data.value1Color}
              icon={card1Data.icon}
              text={card1Data.text}
              value2={card1Data.value2}
              flex={1}
            />

            <DashboardCard
              titleIcon={card3Data.titleIcon}
              title={card3Data.title}
              value1={card3Data.value1}
              value1Color={card3Data.value1Color}
              icon={card3Data.icon}
              text={card3Data.text}
              value2={card3Data.value2}
              flex={1}
            />
          </Flex>
          <Flex gap="1.5rem">
            <DashboardCard
              titleIcon={card2Data.titleIcon}
              title={card2Data.title}
              value1={card2Data.value1}
              value1Color={card2Data.value1Color}
              icon={card2Data.icon}
              text={card2Data.text}
              value2={card2Data.value2}
              flex={1}
            />

            <DashboardCard
              titleIcon={card4Data.titleIcon}
              title={card4Data.title}
              value1={card4Data.value1}
              value1Color={card4Data.value1Color}
              icon={card4Data.icon}
              text={card4Data.text}
              value2={card4Data.value2}
              flex={1}
            />
          </Flex>
        </Stack>
        <Flex align="stretch" flex={1}>
          <DonutChartComponent
            title="Capacity status"
            data={dataCapacityStatus}
            chartLabelText="Occupied"
            chartLabelValue="70%"
            showDivider={true}
            flex={1}
          />
        </Flex>
      </Flex>
      <Flex mt="1.5rem" gap="1.5rem">
        <DonutChartComponent
          data={dataLayouts}
          title="Layouts"
          chartLabelText="Total count"
          chartLabelValue="2"
          flex={1}
        />
        <DonutChartComponent
          data={dataSections}
          title="Sections"
          chartLabelText="Total count"
          chartLabelValue="23"
        />
        <DonutChartComponent
          data={dataDevices}
          title="Devices"
          chartLabelText="Total count"
          chartLabelValue="206"
        />
      </Flex>

      <Grid mt="1.5rem" gutter="1.5rem">
        <Grid.Col span={6}>
          <TemperatureHumidityDonutChart
            title="Temperature"
            circles={[
              { color: '#3B82F6', text: 'Cold' },
              { color: '#EAB308', text: 'Warm' },
              { color: '#F43F5E', text: 'Hot' },
            ]}
            lowest="-5°C"
            highest="42°C"
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TemperatureHumidityDonutChart
            title="Humidity"
            circles={[
              { color: '#3B82F6', text: 'Low' },
              { color: '#EAB308', text: 'Medium' },
              { color: '#F43F5E', text: 'High' },
            ]}
            lowest="5%"
            highest="42°C"
          />
        </Grid.Col>
      </Grid>

      <Grid mt="1.5rem" gutter="1.5rem">
        <Grid.Col span={6}>
          <DonutChartComponent
            title="Total tags"
            data={dataTotalTags}
            chartLabelValue="2,626"
            thickness={20}
            strokeWidth={5}
            startAngle={30}
            endAngle={390}
            paddingAngle={5}
            cornerRadius={10}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <DonutChartComponent
            title="Total visits"
            data={dataTotalVisits}
            chartLabelValue="147,637"
            thickness={20}
            strokeWidth={5}
            startAngle={40}
            endAngle={400}
            paddingAngle={5}
            cornerRadius={10}
          />
        </Grid.Col>
      </Grid>

      <Flex gap="1.5rem" mt="1.5rem">
        <DonutChartComponent
          title="Gender breakdown"
          data={dataGenderBreakdown}
          chartLabelText="Total visitors"
          chartLabelValue="2,052"
          thickness={20}
          strokeWidth={5}
          startAngle={40}
          endAngle={400}
          paddingAngle={5}
          cornerRadius={10}
          flex={1}
        />
        <Stack
          gap="2rem"
          pt="1rem"
          pr="1.5rem"
          pb="1rem"
          pl="1.5rem"
          bg="#FFFFFF"
          bd="1px solid #0D0A2C14"
          style={{ borderRadius: '1.25rem' }}
          flex={1}
        >
          <Text fw={600} fz="1.125rem" c="#0F172A">
            Country breakdown
          </Text>
          <Flex align="center" justify="center">
            {height && width && (
              <PieChart
                size={responsiveSize}
                h={responsiveSize}
                w={responsiveSize}
                data={pieChartData}
                paddingAngle={0}
                strokeWidth={0}
              />
            )}
          </Flex>
          <Stack>
            <Flex justify="space-between" wrap="wrap" gap="1rem">
              {pieChartData.slice(0, 2).map((item, index) => (
                <Flex key={`dashboard-${index}`} align="center" gap="0.5rem">
                  <Box
                    w="1rem"
                    h="1rem"
                    bg={item.color}
                    style={{ borderRadius: '50%' }}
                  />
                  <Text ta="center" fz="0.875rem" c="#475569" fw={400}>
                    {item.name}
                  </Text>
                  <Text ta="center" fz="0.875rem" c="#475569" fw={600}>
                    {item.value}%
                  </Text>
                </Flex>
              ))}
            </Flex>

            <Flex justify="space-between" wrap="wrap" gap="1rem">
              {pieChartData.slice(2, 4).map((item, index) => (
                <Flex key={`pie-chart-${index}`} align="center" gap="0.5rem">
                  <Box
                    w="1rem"
                    h="1rem"
                    bg={item.color}
                    style={{ borderRadius: '50%' }}
                  />
                  <Text ta="center" fz="0.875rem" c="#475569" fw={400}>
                    {item.name}
                  </Text>
                  <Text ta="center" fz="0.875rem" c="#475569" fw={600}>
                    {item.value}%
                  </Text>
                </Flex>
              ))}
            </Flex>
            <Flex justify="space-between" wrap="wrap" gap="1rem">
              {pieChartData.slice(4, 6).map((item, index) => (
                <Flex
                  key={`pie-chart-data-${index}`}
                  align="center"
                  gap="0.5rem"
                >
                  <Box
                    w="1rem"
                    h="1rem"
                    bg={item.color}
                    style={{ borderRadius: '50%' }}
                  />
                  <Text ta="center" fz="0.875rem" c="#475569" fw={400}>
                    {item.name}
                  </Text>
                  <Text ta="center" fz="0.875rem" c="#475569" fw={600}>
                    {item.value}%
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Stack>
        </Stack>
        <DonutChartComponent
          title="Gender breakdown"
          data={dataGenderBreakdown}
          chartLabelText="Total visitors"
          chartLabelValue="2,052"
          thickness={20}
          strokeWidth={5}
          startAngle={40}
          endAngle={400}
          paddingAngle={5}
          cornerRadius={10}
          flex={1}
        />
      </Flex>

      <Flex gap="1.5rem">
        <Stack gap="1.5rem" w="50%">
          <Stack
            mt="1.5rem"
            pt="1rem"
            pr="1.5rem"
            pb="1rem"
            pl="1.5rem"
            bg="#FFFFFF"
            bd="1px solid #0D0A2C14"
            style={{ borderRadius: '1.25rem' }}
          >
            <Flex justify="space-between">
              <Text fw={600} fz="1.125rem" c="#0F172A">
                Traffic
              </Text>
              <Select
                value={selectedOption}
                onChange={(value) => setSelectedOption(value)}
                data={selectUnits}
              />
            </Flex>

            <BarChart
              mt="1.5rem"
              h={300}
              data={trafficData}
              dataKey="month"
              withXAxis
              withYAxis
              tickLine="none"
              withTooltip={false}
              series={[{ name: 'traffic', color: '#CBD5E1' }]}
              barProps={{
                radius: 8,
                width: 1,
              }}
              yAxisProps={{
                ticks: [5000, 10000, 15000, 20000, 25000],
                domain: [5000, 25000],
                tickFormatter: (value) => `${(value / 1000).toFixed(0)}k`,
                tickMargin: 20,
                color: '#0F172A',
                tick: {
                  style: {
                    fontSize: '0.875rem',
                    fontWeight: 400,
                  },
                },
              }}
              xAxisProps={{
                tickFormatter: (month) => {
                  const monthNames = {
                    January: 'Jan',
                    February: 'Feb',
                    March: 'Mar',
                    April: 'Apr',
                    May: 'May',
                    June: 'Jun',
                    July: 'Jul',
                    August: 'Aug',
                    September: 'Sep',
                    October: 'Oct',
                    November: 'Nov',
                    December: 'Dec',
                  };
                  return monthNames[month] || month;
                },

                tick: {
                  style: {
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    fill: '#64748B',
                  },
                  dy: 10,
                },
              }}
              gridProps={{
                strokeDasharray: 'none',
                stroke: '#E2E8F0',
                strokeWidth: 1,
              }}
            />
          </Stack>

          <Stack
            p={24}
            bg="#FFFFFF"
            bd="1px solid #E2E8F0"
            gap="1rem"
            className={styles.radius}
          >
            <Flex justify="space-between">
              <Text fz="1.125rem" c="#0f172a" fw={600}>
                Schedule
              </Text>

              <Flex gap="0.75rem" align="center">
                <ChevronLeftIcon
                  height="1.5rem"
                  width="1.5rem"
                  color="#64748B"
                  cursor="pointer"
                  onClick={goToPreviousDay}
                />
                <Text fz="1.125rem" c="#0F172A" fw={500}>
                  {formattedDate}
                </Text>
                <ChevronRightIcon
                  height="1.5rem"
                  width="1.5rem"
                  color="#64748B"
                  cursor="pointer"
                  onClick={goToNextDay}
                />
              </Flex>
            </Flex>

            {schedulecards.length > 0 ? (
              <ScrollArea
                mr="-0.75rem"
                pr="0.5rem"
                type="always"
                scrollbarSize={8}
                scrollbars="y"
                offsetScrollbars
                classNames={{
                  scrollbar: styles.scrollbar,
                  thumb: styles.thumb,
                }}
                h="17.5rem"
              >
                <Stack gap={0}>
                  {schedulecards.map((card, index) => (
                    <Fragment key={`schedule-card-${index}`}>
                      <Divider
                        ml="1rem"
                        mr="0.5rem"
                        color="#F8FAFC"
                        size="md"
                      />

                      <ScheduleCard
                        key={`schedule-card-${index}`}
                        startDate={card.startDate}
                        startTime={card.startTime}
                        text={card.text}
                        name={card.name}
                        endDate={card.endDate}
                        endTime={card.endTime}
                        level={card.level}
                        type={card.type}
                        formattedDate={formattedDate}
                        onClick={() =>
                          openModal({
                            size: 640,
                            title: 'Schedule information',
                            titleIcon: (
                              <CalendarDaysIcon
                                height="1.25rem"
                                width="1.25rem"
                                color="#94A3B8"
                              />
                            ),
                            hasFooter: false,
                            content: (
                              <ScheduleInformationModal
                                scheduleId="testId"
                                eventId="testId"
                                timeZone="testZone"
                              />
                            ),
                          })
                        }
                      />

                      {index === schedulecards.length - 1 && (
                        <Divider
                          ml="1rem"
                          mr="0.5rem"
                          color="#F8FAFC"
                          size="md"
                        />
                      )}
                    </Fragment>
                  ))}
                </Stack>
              </ScrollArea>
            ) : (
              <Stack align="center" justify="center">
                <ExclamationTriangleIcon
                  height="3rem"
                  width="3rem"
                  color="#CBD5E1"
                />
                <Text fz="1rem" c="#475569" fw={600}>
                  No upcoming schedule
                </Text>
                <Text maw={540} fw={400} fz="0.875rem" c="#64748B" ta="center">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Text>
              </Stack>
            )}
          </Stack>
        </Stack>

        <Stack
          w="50%"
          mt="1.5rem"
          pt="1rem"
          pr="1.5rem"
          pb="1rem"
          pl="1.5rem"
          bg="#FFFFFF"
          bd="1px solid #0D0A2C14"
          className={styles.radius}
        >
          <Text c="#0F172A" fz="1.125rem" fw={600}>
            Length of stay (Top 50)
          </Text>
          {Top50Data.map((data, index) => (
            <LengthOfStayComponent
              key={`length-of-stay-${index}`}
              index={index}
              name={data.name}
              level={data.level}
              model={data.model}
              time={data.time}
              maxTime={maxTime}
            />
          ))}
        </Stack>
      </Flex>
    </>
  );
};

export default React.memo(EntityDashboard);
