import React from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

import buildingOffice from '../../assets/icons/solid/building-office-2.svg';
import clock from '../../assets/icons/solid/clock.svg';
import pin from '../../assets/icons/solid/map-pin.svg';
import { Location, Company } from '../../pages/Events/interfaces';

import styles from './EventCardForMobileView.module.css';

interface CardProps {
  eventId: string;
  eventName: string;
  startDateTime: Date;
  endDateTime: Date;
  location: Location;
  clientName: Company;
}

export const EventCardForMobileView = ({
  eventId,
  eventName,
  startDateTime,
  endDateTime,
  location,
  clientName,
}: CardProps) => {
  return (
    <>
      <Link to={`/event/${eventId}`}>
        <Card className={styles.card}>
          <Card.Header as="h5" className={styles.header}>
            {eventName}
          </Card.Header>
          <Card.Body>
            <Card.Title></Card.Title>
            <Card.Text>
              <img src={clock} alt="" /> {startDateTime.toDateString()} -{' '}
              {endDateTime.toDateString()}
            </Card.Text>
            <Card.Text>
              <img src={pin} alt=""></img> {location.description}
            </Card.Text>
            <Card.Text>
              <img src={buildingOffice} alt=""></img> {clientName.name}
            </Card.Text>
          </Card.Body>
        </Card>
      </Link>
    </>
  );
};
