import { CloudArrowUpIcon, CameraIcon } from '@heroicons/react/24/outline';
import { Grid, Stack, Text } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useEffect, useRef, useState } from 'react';
import { useTrackingObjectByEvent } from 'services/entity/trackingObject';

import CameraActivatedComponent from './CameraAndImageComponent';

const TrackingObjectImageContent = ({
  dataId = '',
  eventId = '',
  setFormState,
}: ModalFormProps & { eventId: string }) => {
  const openRef = useRef<() => void>(null);
  const [imageSource, setImageSource] = useState<string | null>(null);
  const [cameraActivated, setCameraActivated] = useState<boolean>(false);
  const { data: { eventParticipants } = {} } = useTrackingObjectByEvent(
    eventId,
    dataId,
  );

  useEffect(() => {
    if (eventParticipants && eventParticipants.length > 0) {
      if (eventParticipants[0].heroImage) {
        setImageSource(eventParticipants[0].heroImage.largeSource);
      }
    }
  }, [eventParticipants]);

  const resizeImage = (
    file: File,
    width: number,
    height: number,
    callback: (resizedDataUrl: string) => void,
  ) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height);
          const resizedDataUrl = canvas.toDataURL('image/png');
          callback(resizedDataUrl);
        }
      };
    };

    reader.readAsDataURL(file);
  };

  const handleDrop = (files: File[]) => {
    if (files.length > 0) {
      const file = files[0];
      resizeImage(file, 330, 330, (resizedDataUrl) => {
        setImageSource(resizedDataUrl);
        if (setFormState)
          setFormState((prev) => ({
            ...prev,
            image: file,
          }));
      });
    }
    setCameraActivated(true);
  };

  return (
    <>
      {cameraActivated || imageSource ? (
        <CameraActivatedComponent
          imageSource={imageSource}
          setImageSource={setImageSource}
          openRef={openRef}
        />
      ) : (
        <Grid gutter="xl">
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Dropzone
              openRef={openRef}
              onDrop={handleDrop}
              accept={['image/png', 'image/jpeg']}
              maxSize={5 * 1024 ** 2}
              activateOnClick={false}
            >
              <Stack
                align="center"
                justify="center"
                h="16.875rem"
                p="1.25rem"
                ta="center"
                style={{
                  border: '1.5px dashed var(--gray-300)',
                  borderRadius: '0.5rem',
                }}
              >
                <div>
                  <CloudArrowUpIcon
                    height="3.125rem"
                    width="3.125rem"
                    color="var(--gray-400)"
                    strokeWidth={0.8}
                  />
                </div>

                <Text inline fz={14} c="var(--blue-500)">
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      openRef.current?.();
                    }}
                  >
                    Click to upload
                  </span>
                  <span style={{ color: '#344155' }}> or drag and drop</span>
                </Text>

                <Stack align="center" gap={1}>
                  <Text inline fz={12} c="var(--gray-500)">
                    Recommended image size: 250x250 pixels.
                  </Text>
                  <Text inline fz={12} c="var(--gray-500)">
                    JPEG or PNG file.
                  </Text>
                </Stack>
              </Stack>
            </Dropzone>
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 6 }}>
            <Stack
              align="center"
              justify="center"
              h="16.875rem"
              p="1.25rem"
              ta="center"
              style={{
                border: '1.5px dashed var(--gray-300)',
                borderRadius: '0.5rem',
              }}
            >
              <div>
                <CameraIcon
                  height="3.125rem"
                  width="3.125rem"
                  color="var(--gray-400)"
                  strokeWidth={0.8}
                />
              </div>
              <Text
                inline
                fz={14}
                c="var(--blue-500)"
                style={{ cursor: 'pointer' }}
                onClick={() => setCameraActivated(true)}
              >
                Click to turn on camera
              </Text>
              <Stack align="center" gap={1}>
                <Text inline fz={12} c="var(--gray-500)">
                  Keep the object in frame and ensure
                </Text>
                <Text inline fz={12} c="var(--gray-500)">
                  the photo is clear.
                </Text>
              </Stack>
            </Stack>
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};

export default TrackingObjectImageContent;
