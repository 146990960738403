import dayjs from 'dayjs';
import {
  EventTags,
  GlobalTagDataItem,
  EntityLayout,
  HubSectionStatistics,
  TagByTrackingObjectAndEvent,
  TagEventDataItem,
  TrackingObjectByEventItem,
} from 'services/entity/types';
import { convertFirstLetterToUppercase } from 'utils/consts';
import { convertDateAndTime } from 'utils/createDateAndTime';
import { calculateTimeElapsed } from 'utils/dateFormatters';

import {
  AssignTagTableType,
  AssignTrackingObjectTableType,
  HubCardType,
  HubSectionDataType,
  TagTableType,
} from './types';

export const mapTrackingObjectByEventToTrackingObjectCard = (
  source: TrackingObjectByEventItem,
): HubCardType => ({
  id: source._id,
  cardType: 'trackingObject',
  trackingObjectType: source.trackedSubjectType.type ?? 'person',
  status: source.state,
  image: source?.heroImage?.largeSource ?? undefined,
  trackingObjectName: source.firstLastName,
  trackingObjectCode: source.externalId,
  lastSeen: calculateTimeElapsed(source.updatedAt),
  trackingObjectTypeName: source.trackedSubjectType.subType,
  sectionName: source.tagEventStatusEvent?.map((x) => x.sectionName),
  layoutName: source.tagEventStatusEvent[0]?.layoutName ?? '',
  trackingObjectTags: source?.eventTag,
});

export const mapHubSectionStatisticsToHubSectionStatisticsType = (
  source: HubSectionStatistics,
  type: string,
  total: string,
  online: string,
  idle: string,
  offline: string,
): HubSectionDataType => ({
  total: source?.[total] ?? 0,
  type: type,
  progressBarData: [
    {
      leftNumberProgressBar: [
        { value: source?.[online] ?? 0, type: 'online' },
        { value: source?.[idle] ?? 0, type: 'idle' },
        { value: source?.[offline] ?? 0, type: 'offline' },
      ],
      rightNumberProgressBar: {
        value: source?.[offline] ?? 0,
        type: 'offline',
      },
    },
  ],
});

export const mapTagEventStatusToAssignedTagTrackingObject = (
  tag: any,
  primaryTagId?: string,
  timezone?: string,
) => {
  return {
    primary: primaryTagId ? tag.tagId === primaryTagId : false,
    name: '',
    tagId: tag.tagId,
    address: tag.uniqueId,
    date: dayjs(convertDateAndTime(tag.updatedAt, timezone ?? '')).format(
      'DD MMM YYYY',
    ),
    time: dayjs(convertDateAndTime(tag.updatedAt, timezone ?? '')).format(
      'hh:mm A',
    ),
  };
};

export const mapTagByTrackingObjectAndEventToAssignTagTable = (
  tagData: TagByTrackingObjectAndEvent[],
): AssignTagTableType[] => {
  return tagData?.map((tag) => ({
    _id: tag.tagId,
    uniqueId: tag.uniqueId,
    number: tag.number,
    status: tag.status,
    participant: tag?.participant,
  }));
};

export const mapTagByTrackingObjectAndEventToUnassignTagTable = (
  tagData: EventTags[] | EventTags,
): AssignTagTableType[] => {
  if (Array.isArray(tagData)) {
    return tagData.map((tag) => ({
      _id: tag.tagId ?? tag._id,
      uniqueId: tag.uniqueId,
      number: tag.number,
      status: tag.status,
      participant: undefined,
    }));
  }

  return [
    {
      _id: tagData._id,
      uniqueId: tagData.uniqueId,
      number: tagData.number,
      status: tagData.status,
      participant: undefined,
    },
  ];
};

export const mapEntityLayoutToLayoutCard = (source: EntityLayout) => ({
  cardType: 'layout',
  id: source._id,
  layoutName: source.name,
  status: source.state,
  image: source?.presentation?.image?.largeSource ?? undefined,
  // maxCapacity: source.maxParticipants,
  // capacity: 0,
  numberOfDevices: source.eventLayoutDevices.length,
  lastSeen: calculateTimeElapsed(source.updatedAt),
  layoutAgeNumberRange: `${source?.participantMinAge} - ${source?.participantMaxAge}`,
});

export const mapTagsByEventToTagCard = (
  source: TagEventDataItem,
): HubCardType => ({
  id: source.tagId,
  cardType: 'tags',
  tagName: source?.tag.name ?? source?.number,
  tagCode: source?.number ?? source?.tag?.number,
  status: source?.state,
  lastSeen: calculateTimeElapsed(source.updatedAt),
  layoutName: source?.tagEventStatusEvent[0]?.layoutName ?? '',
  sectionName: source.tagEventStatusEvent?.map((x) => x.sectionName),
  macAddress: source?.uniqueId ?? '',
  trackingObject: {
    type: source?.eventParticipants[0]?.trackedSubjectType?.type ?? '',
    objects: source?.eventParticipants ?? [],
    names: source?.eventParticipants?.map((x) => x.firstLastName),
  },
});

export const mapTagToAddTagTable = (
  tagData: GlobalTagDataItem[] | GlobalTagDataItem,
): TagTableType[] => {
  if (Array.isArray(tagData)) {
    return tagData.map((tag) => ({
      _id: tag._id,
      status: tag?.status
        ? tag?.status === 'active'
          ? 'Enabled'
          : 'Disabled'
        : '',
      device: tag?.name ?? '',
      model: tag?.model ?? '',
      type: tag?.type ?? '',
      tagID: tag?.uniqueId ?? '',
    }));
  }

  return [
    {
      _id: tagData._id,
      device: tagData.name,
      status: tagData.status,
      model: tagData.model ?? '',
      tagID: tagData?.uniqueId ?? '',
      type: tagData?.type ?? '',
    },
  ];
};

export const mapTrackingObjectByEventToAssignTrackingObjectTableType = (
  source: TrackingObjectByEventItem[],
): AssignTrackingObjectTableType[] => {
  return source?.map((trackingObject) => ({
    _id: trackingObject._id,
    classification: trackingObject?.trackedSubjectType?.subType
      ? convertFirstLetterToUppercase(
          trackingObject?.trackedSubjectType?.subType,
        )
      : '',
    type: trackingObject?.trackedSubjectType?.type
      ? convertFirstLetterToUppercase(trackingObject?.trackedSubjectType?.type)
      : '',
    name: trackingObject?.firstLastName ?? '',
    registrationID: trackingObject?.externalId ?? '',
    status: trackingObject?.status
      ? trackingObject?.status === 'active'
        ? 'Enabled'
        : 'Disabled'
      : '',
    tags:
      trackingObject?.eventTag?.length > 1
        ? `${trackingObject?.eventTag[0]?.number} (+${
            trackingObject?.eventTag.length - 1
          })`
        : trackingObject?.eventTag?.length === 1
        ? trackingObject?.eventTag[0]?.number
        : '',
  }));
};
