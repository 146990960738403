import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid';
import {
  Button,
  Checkbox,
  Combobox,
  Divider,
  Flex,
  Text,
  TextInput,
  useCombobox,
} from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import { useFilters } from 'context/FilterProvider';
import { useEffect, useState } from 'react';

import classNames from './Filter.module.scss';
import { FilterProps } from './types';

const Filter = ({
  border = false,
  label,
  data,
  filterKey,
  clear,
}: FilterProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedItemsDraft, setSelectedItemsDraft] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const dropdownRef = useClickOutside(() =>
    setSelectedItemsDraft(selectedItems),
  );

  const { updateFilters, removeFilter } = useFilters();

  const combobox = useCombobox({
    onDropdownOpen: () => setIsDropdownOpen(true),
    onDropdownClose: () => setIsDropdownOpen(false),
  });

  const filteredItems = data.filter(({ value }) =>
    value.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const toggleSelection = (id: string) => {
    setSelectedItemsDraft((current) =>
      current.includes(id) ? current.filter((i) => i !== id) : [...current, id],
    );
  };

  const handleConfirm = () => {
    setSelectedItems(selectedItemsDraft);
    if (selectedItemsDraft.length) updateFilters(filterKey, selectedItemsDraft);
    else removeFilter(filterKey);

    combobox.closeDropdown();
  };

  const handleCancel = () => {
    setSelectedItemsDraft(selectedItems);
    combobox.closeDropdown();
  };

  useEffect(() => {
    if (clear) {
      setSelectedItemsDraft([]);
      setSelectedItems([]);
    }
  }, [clear]);

  return (
    <Combobox
      store={combobox}
      width="25rem"
      dropdownPadding={0}
      position="bottom-start"
      offset={0}
      withinPortal={false}
      positionDependencies={[selectedItemsDraft]}
    >
      <Combobox.Target>
        <Button
          bg={isDropdownOpen ? '#e2e8f0' : '#ffffff'}
          c={selectedItems.length ? '#3b82f6' : '#344155'}
          bd={border ? '1px solid #e2e8f0' : ''}
          radius="md"
          rightSection={<ChevronDownIcon height="1.25rem" width="1.25rem" />}
          onClick={() => combobox.toggleDropdown()}
          classNames={{ inner: classNames.buttonInner }}
        >
          {`${label}${
            selectedItems.length ? ` (${selectedItems.length})` : ''
          }`}
        </Button>
      </Combobox.Target>

      <Combobox.Dropdown ref={dropdownRef}>
        <TextInput
          my="0.5rem"
          mx="0.75rem"
          radius="md"
          leftSection={<MagnifyingGlassIcon height="1.25rem" width="1.25rem" />}
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <Combobox.Options>
          <ScrollArea.Autosize
            mah="18rem"
            type="scroll"
            variant="thumb"
            scrollbarSize={9}
          >
            {filteredItems.map(({ id, value }) => (
              <Combobox.Option
                key={`${id}-${value}`}
                bg={selectedItemsDraft.includes(id) ? '#eff6ff' : ''}
                value={value}
                onClick={() => toggleSelection(id)}
              >
                <Checkbox
                  size="xs"
                  my="0.625rem"
                  ml="0.5rem"
                  c="#475569"
                  label={value}
                  checked={selectedItemsDraft.includes(id)}
                  onChange={() => {}}
                  classNames={{
                    body: classNames.checkboxBody,
                    label: classNames.checkboxLabel,
                  }}
                />
              </Combobox.Option>
            ))}
          </ScrollArea.Autosize>
        </Combobox.Options>

        <Divider size={1} color="#e2e8f0" />
        <Flex py="0.5rem" px="0.75rem" align="center" justify="space-between">
          <Text size="sm" c="#64748B">
            {selectedItemsDraft.length} selected
          </Text>
          <Flex gap="0.75rem">
            <Button
              bg="#ffffff"
              c="#344155"
              radius="md"
              bd="1px solid #e2e8f0"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              bg="#3b82f6"
              c="#ffffff"
              radius="md"
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default Filter;
