import { Select } from '@mantine/core';
import { useState, useEffect } from 'react';
import styles from './TableFooter.module.css';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';
import { TableFooterType } from './types';

const TableFooter = ({
  currentCount,
  totalCount,
  rowsOnPage,
  rowsOptions = [],
  onRowsOnPageChange,
}: TableFooterType) => {
  const [value, setValue] = useState<string>(String(rowsOnPage));

  useEffect(() => {
    setValue(String(rowsOnPage));
  }, [rowsOnPage]);

  const handleSelectChange = (selectedValue) => {
    if (selectedValue !== null) {
      setValue(selectedValue);
      onRowsOnPageChange(Number(selectedValue));
    }
  };

  const [opened, setOpened] = useState(false);

  return (
    <div className={styles.container}>
      <div>
        <span>
          Showing {currentCount} of {totalCount} results
        </span>
      </div>
      <div className={styles.selectContainer}>
        <span>Rows on page: </span>
        <Select
          style={{ borderRadius: '0.5rem' }}
          className={styles.select}
          value={value}
          data={rowsOptions}
          onChange={handleSelectChange}
          onDropdownOpen={() => setOpened(true)}
          onDropdownClose={() => setOpened(false)}
          rightSection={
            opened ? (
              <ChevronUpIcon height={20} width={20} color="#334155" />
            ) : (
              <ChevronDownIcon height={20} width={20} color="#334155" />
            )
          }
        />
      </div>
    </div>
  );
};

export default TableFooter;
