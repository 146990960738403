import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SortingParamType } from 'components/SortingDropdown/types';
import { Filter } from 'context/types';
import {
  AddAdmissionByEvent,
  AdmissionScanData,
  ParticipantByEvent,
} from 'pages/Entities/Admission/types';
import { client } from 'services/api';

const admissionScanByEventId = async (
  eventId: string,
  searchTerm: string,
): Promise<AdmissionScanData> => {
  const url = `event/${eventId}/admission/scan?filter=${searchTerm}`;

  const response = await client.get(url);

  return response.data.data;
};

const listOfAdmissionByEvent = async (
  eventId: string,
  limit: number,
  admissionSearchTerm: string,
  filter: Filter,
  sortingParam?: SortingParamType,
): Promise<ParticipantByEvent[] | []> => {
  const params = new URLSearchParams({
    limit: limit.toString(),
    offset: '0',
    filter: admissionSearchTerm,
    fetchTotalCount: String(false),
    fetchDataAndFilterCount: String(true),
  });

  if (filter?.types?.length) {
    filter.types.forEach((type: string) => params.append('type[]', type));
  }

  if (filter?.classifications?.length) {
    filter.classifications.forEach((classification: string) =>
      params.append('subtype[]', classification),
    );
  }

  if (filter?.states?.length) {
    filter.states.forEach((state: string) => params.append('state[]', state));
  }

  if (filter?.statuses?.length) {
    filter.statuses.forEach((status: string) =>
      params.append('status[]', status),
    );
  }

  if (sortingParam) {
    params.append('sortField', sortingParam.field);
    params.append('sortOrder', sortingParam.order.toString());
  }

  const url = `event/${eventId}/participant/?${params.toString()}`;

  const response = await client.get(url);

  return response.data.data;
};

const addAdmissionByEvent = async (
  eventId: string,
  data: AddAdmissionByEvent,
) => {
  const url = `event/${eventId}/admission`;

  const response = await client.post(url, data);

  return response.data;
};

export const useAdmissionScan = (
  eventId: string | null,
  searchTerm: string | null,
) => {
  return useQuery<AdmissionScanData, Error>({
    queryKey: ['admissionScan', eventId, searchTerm],
    queryFn: () => admissionScanByEventId(eventId!, searchTerm!),
    enabled: !!eventId && !!searchTerm && eventId.length > 0,
  });
};

export const useListOfAdmissionByEvent = (
  eventId: string | null,
  limit: number,
  admissionSearchTerm: string,
  filter: Filter,
  sortingParam?: SortingParamType,
) => {
  return useQuery<ParticipantByEvent[] | [], Error>({
    queryKey: [
      'listOfAdmissionByEvent',
      eventId,
      limit,
      admissionSearchTerm,
      filter,
      sortingParam,
    ],
    queryFn: () =>
      listOfAdmissionByEvent(
        eventId!,
        limit,
        admissionSearchTerm,
        filter,
        sortingParam,
      ),
    enabled: !!eventId && eventId.length > 0 && limit > 0,
  });
};

export const useAddParticipantByEvent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      eventId,
      data,
    }: {
      eventId: string;
      data: AddAdmissionByEvent;
    }) => {
      return addAdmissionByEvent(eventId, data);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([
        'trackingObjectsByEvent',
        variables.eventId,
      ]);
    },
    onError: (error: Error) => {
      console.error('Greška:', error.message);
    },
  });
};
