import { staticMapUrl } from 'static-google-map';

import { EventLayout, EventLayoutPresentationType } from './interfaces';

const presentationSizeMap = {
  small: '80x60',
  medium: '160x120',
  large: '500x375',
};
const presentationImageKeyMap = {
  small: 'smallSource',
  medium: 'mediumSource',
  large: 'largeSource',
};

export type PresentationSize = keyof typeof presentationSizeMap;

export const getPresentationFromEventLayout = (
  eventLayout: EventLayout,
  size: PresentationSize = 'medium',
) => {
  if (!eventLayout.presentation?.type) {
    return void 0;
  }
  if (eventLayout.presentation.type === EventLayoutPresentationType.Image) {
    return eventLayout.presentation.image[presentationImageKeyMap[size]];
  }

  const { lat, lng } = eventLayout.presentation.geoRange.center;

  return staticMapUrl({
    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    size: presentationSizeMap[size] || presentationSizeMap.medium,
    center: `${lat},${lng}`,
    zoom: eventLayout.presentation.geoRange.zoom,
    scale: 1,
    format: 'jpg',
    maptype: 'roadmap',
  });
};
