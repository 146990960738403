import React from 'react';
import { ButtonGroup, Button } from '@lanaco/lnc-react-ui';
import { FilterButtonGroupProps } from './types';
import './FilterButtonGroup.css';
import styles from './FilterButtonGroup.module.css';

const FilterButtonGroup: React.FC<FilterButtonGroupProps> = (props) => {
  return (
    <div
      className={`${styles.filterButtonGroup} ${
        props.hasBorder ? styles.withBorder : ''
      }`}
      style={{
        width: props.widthButtonGroup,
        display: 'flex',
        alignItems: 'center',
        gap: '0.75rem',
      }}
    >
      <ButtonGroup>
        {props.buttons?.map((item, index) => (
          <Button
            btnType="basic"
            iconStyle="regular"
            className={`${styles.button} ${item.active ? styles.active : ''}`}
            onClick={(e) => {
              e.preventDefault();
              item.onClick?.();
            }}
            name={index.toString()}
            key={index}
            style={{ width: props.width }}
          >
            {item.indicator && <img src={item.indicator} alt="icon" />}
            {item.text}
            {item.text1 !== undefined && (
              <span className={styles.text1Style}>
                {typeof item.text1 === 'number' && item.text1 === 0
                  ? '0'
                  : item.text1}{' '}
              </span>
            )}
          </Button>
        ))}
      </ButtonGroup>

      {props.extraButton && (
        <div style={{ marginLeft: 'auto' }}>{props.extraButton}</div>
      )}
    </div>
  );
};

export default FilterButtonGroup;
