import { Icon as IconComponent } from '@lanaco/lnc-react-ui';
import { SVGProps } from 'react';

export interface SVGIconProps extends SVGProps<SVGSVGElement> {
  /**
   * The name of the icon to display.
   * @link https://fontawesome.com/v5/search?m=free
   */
  icon: string;
  size?: string;
  iconStyle?: 'solid' | 'regular';
}

export const Icon: React.FC<SVGIconProps> = ({ icon, size, ...props }) => {
  return (
    <IconComponent
      style={{
        ...props.style,
        fontSize: size,
      }}
      icon={icon}
      {...props}
    />
  );
};

Icon.displayName = 'Icon';
