import { Flex, ScrollArea, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import React from 'react';
import { convertFirstLetterToUppercase } from 'utils/consts';

import {
  MultipleDetectionModalProps,
  ParticipantData,
  TagData,
} from '../types';

import MultipleDetectionItem from './MultipleDetectionItem';

const MultipleDetectionModal = ({
  trackingObjects,
  tags,
  addTagToScanned,
  addParticipantToScanned,
}: MultipleDetectionModalProps) => {
  const handleAddTag = (id: string) => {
    const tag = tags.find((x) => x._id === id);
    if (tag) addTagToScanned(tag);
    modals.closeAll();
  };

  console.log(tags);

  const handleAddParticipant = (id: string) => {
    const trackingObject = trackingObjects.find((x) => x._id === id);
    if (trackingObject) addParticipantToScanned(trackingObject);
    modals.closeAll();
  };

  return (
    <Flex direction="column" gap="lg">
      <Text fz={14} c={'var(--gray-500)'}>
        {`We found ${
          trackingObjects?.length + tags?.length
        } entries from this scan. Please choose one that you need.`}
      </Text>

      <ScrollArea.Autosize mah="calc(100vh - 280px)" offsetScrollbars>
        <Flex direction="column" gap="md">
          {trackingObjects?.length > 0 && (
            <>
              <Text c={'var(--gray-900)'} fw={500}>
                Tracking object
              </Text>
              {trackingObjects?.map((x: ParticipantData) => (
                <MultipleDetectionItem
                  key={x?._id}
                  isTagItem={false}
                  upperRowTitle={x?.firstLastName ?? ''}
                  lowerFirstCell={
                    x?.trackedSubjectType?.subType
                      ? convertFirstLetterToUppercase(
                          x?.trackedSubjectType?.subType,
                        )
                      : ''
                  }
                  lowerSecondCell={x?.eventTags?.length.toString()}
                  id={x?._id}
                  handleAddParticipant={handleAddParticipant}
                />
              ))}
            </>
          )}

          {tags?.length > 0 && (
            <>
              <Text c={'var(--gray-900)'} fw={500}>
                Tags
              </Text>
              {tags?.map((x: TagData) => (
                <MultipleDetectionItem
                  isTagItem={true}
                  key={x?._id}
                  upperRowTitle={x?.tag?.name ?? x?.number}
                  upperRowSubtitle={x?.number ?? x?.tag?.number}
                  lowerFirstCell={x.uniqueId}
                  lowerSecondCell={x?.participants?.length.toString() ?? ''}
                  id={x?._id}
                  handleAddTag={handleAddTag}
                />
              ))}
            </>
          )}
        </Flex>
      </ScrollArea.Autosize>
    </Flex>
  );
};

export default React.memo(MultipleDetectionModal);
