import { ScrollArea } from '@mantine/core';
import Konva from 'konva';
import { map } from 'lodash';
import polygonClipping from 'polygon-clipping';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Stage, Layer, Image } from 'react-konva';
import useImage from 'use-image';

import { getCenterPoint } from '../consts';
import { InteractiveCanvasProps } from '../types';

import CanvasDevice from './CanvasDevice';
import CanvasPin from './CanvasPin';
import CanvasSection from './CanvasSection';

// Funckija za normalizaciju tacaka u odnosu na visinu i sirinu kanvasa
const normalizePoint = (
  x: number,
  y: number,
  width: number,
  height: number,
) => ({
  x: x / width,
  y: y / height,
});

// Funckija za denormalizaciju tacaka u odnosu na visinu i sirinu kanvasa
const denormalizePoint = (
  x: number,
  y: number,
  width: number,
  height: number,
) => ({
  x: x * width,
  y: y * height,
});

const InteractiveCanvas = ({
  imageUrl,
  height,
  width,
  scale,
  showSections = false,
  showPins = false,
  showDevices = false,
  drawMode = null,
  setDrawMode,
  sectionsData,
  newSectionPoints,
  setNewSectionPoints,
  devicesData,
  setDevicesData,
  hoveredDevice,
  setHoveredDevice,
  selectedDevice,
  setSelectedDevice,
}: InteractiveCanvasProps) => {
  const [currentPoints, setCurrentPoints] = useState<number[][]>([]);
  const stageRef = useRef<Konva.Stage | null>(null);

  const [image] = useImage(imageUrl);

  const handleSectionDrawing = (normalizedPoint: { x: number; y: number }) => {
    // Provjerava da li se ispunio uslov za minimalan broj tacaka (min 3)
    if (currentPoints.length > 2) {
      // Provjerava da li se kliknulo na pocetnu tacku da bi se zatvorio oblik
      // pomocu odredjivanja udaljenosti mjesta gdje se kliknulo u odnosu na tacku
      const [startX, startY] = currentPoints[0];
      const distance = Math.hypot(
        normalizedPoint.x - startX,
        normalizedPoint.y - startY,
      );

      // Udaljenost od tacke za koju se smatra da je klik na samu tacku
      if (distance < 0.02) {
        // Validacija presijecanja sekcije sa ostalim sekcijama
        const newPolygon = [...currentPoints, [startX, startY]];

        let intersects = false;

        if (!sectionsData) return;

        for (const section of sectionsData) {
          intersects =
            polygonClipping.intersection(
              [currentPoints as [number, number][]],
              [section?.points as [number, number][]],
            ).length > 0;

          if (intersects) break;
        }

        // Ako presijecanje sekcija postoji onda je potrebno ponovo nacrtati sekciju
        if (intersects) {
          setCurrentPoints([]);
          return;
        }

        // Kompletiranje crtanja sekcije
        setCurrentPoints(newPolygon);
        setNewSectionPoints && setNewSectionPoints(newPolygon);
        setDrawMode && setDrawMode(null);
        return;
      }
    }

    // Dodavanje nove tacke sekcije
    setCurrentPoints((prev) => [
      ...prev,
      [normalizedPoint.x, normalizedPoint.y],
    ]);
  };

  const handleDevicePositioning = (normalizedPoint: {
    x: number;
    y: number;
  }) => {
    if (!selectedDevice) return;

    // Azuriranje liste uredjaja sa novom lokacijom za odabrani uredjaj
    const updatedDevicesData = map(devicesData, (device) => {
      if (device.id === selectedDevice.id) {
        // Azuriranje ili dodavanje lokacije za odabrani uredjaj
        return {
          ...device,
          coordinates: {
            lat: normalizedPoint.x,
            lng: normalizedPoint.y,
          },
        };
      }
      return device;
    });

    // Ažuriranje stanja uredjaja
    setDevicesData && setDevicesData(updatedDevicesData);
    setSelectedDevice && setSelectedDevice(null);
  };

  // Funkcija za obradu klika na kanvasu
  const handleCanvasClick = (e: any) => {
    if (!drawMode) return;

    const stage = stageRef.current;
    if (!stage) return;

    const pointerPosition = stage.getPointerPosition();
    if (!pointerPosition) return;

    const scaleX = stage.scaleX();
    const scaleY = stage.scaleY();

    // Skaliranje tacaka u odnosu na vrijednost zuma
    const scaledX = pointerPosition.x / scaleX;
    const scaledY = pointerPosition.y / scaleY;

    // Normalizacija tacaka
    const normalizedPoint = normalizePoint(scaledX, scaledY, width, height);

    if (drawMode === 'section') handleSectionDrawing(normalizedPoint);
    if (drawMode === 'device') handleDevicePositioning(normalizedPoint);
  };

  // Resetovanje vrijednosti niza tacaka da bi se sekcija mogla ponovo nacrtat
  useEffect(() => {
    if (newSectionPoints?.length === 0) {
      setCurrentPoints([]);
    }
  }, [newSectionPoints]);

  return (
    <ScrollArea
      h={height}
      w={width}
      type="hover"
      styles={{ thumb: { backgroundColor: 'var(--gray-400-transparent)' } }}
    >
      <Stage
        height={height * scale}
        width={width * scale}
        scaleX={scale}
        scaleY={scale}
        ref={stageRef}
        onClick={handleCanvasClick}
      >
        <Layer>
          {image && (
            <Image
              image={image}
              height={height}
              width={width}
              listening={false}
            />
          )}

          {sectionsData &&
            sectionsData?.map((section) => {
              const denormalizedPoints = section.points.map(([x, y]) => {
                const { x: denormX, y: denormY } = denormalizePoint(
                  x,
                  y,
                  width,
                  height,
                );
                return [denormX, denormY];
              });

              const { x: centerX, y: centerY } = getCenterPoint(
                denormalizedPoints,
              );

              return (
                <Fragment key={section.id}>
                  {/* Iscrtavanje sekcija */}
                  {showSections && (
                    <CanvasSection
                      key={`section-${section.name}`}
                      points={denormalizedPoints.flat()}
                    />
                  )}

                  {/* Iscrtavanje pinova */}
                  {showPins && (
                    <CanvasPin
                      key={`pin-${centerX}-${centerY}`}
                      x={centerX}
                      y={centerY}
                      text={`${section.id}`}
                    />
                  )}
                </Fragment>
              );
            })}

          {/* Iscrtavanje uredjaja */}
          {devicesData &&
            devicesData.map((device) => {
              if (device?.coordinates?.lat && device?.coordinates?.lng) {
                const { x: denormX, y: denormY } = denormalizePoint(
                  device.coordinates.lat,
                  device.coordinates.lng,
                  width,
                  height,
                );

                return (
                  showDevices && (
                    <CanvasDevice
                      key={`device-${denormX}-${denormY}`}
                      x={denormX}
                      y={denormY}
                      type={device.type}
                      isHovered={hoveredDevice === device}
                      isSelected={selectedDevice === device}
                      onClick={() =>
                        setSelectedDevice && setSelectedDevice(device)
                      }
                      onMouseEnter={() =>
                        setHoveredDevice && setHoveredDevice(device)
                      }
                      onMouseLeave={() =>
                        setHoveredDevice && setHoveredDevice(null)
                      }
                    />
                  )
                );
              } else return null;
            })}

          {/* Iscrtavanje tacaka nove sekcije */}
          {/* U momentu kad je sekcija nacrtana, drawMode se iskljucuje i tacke se vise ne prikazuju */}
          {drawMode &&
            currentPoints?.map(([x, y]) => {
              const { x: denormX, y: denormY } = denormalizePoint(
                x,
                y,
                width,
                height,
              );

              return (
                <CanvasSection.Dot
                  key={`point-${x}-${y}`}
                  x={denormX - 6}
                  y={denormY - 6}
                />
              );
            })}

          {/* Iscrtavanje nove sekcije */}
          <CanvasSection
            points={currentPoints
              ?.map(([x, y]) => {
                const { x: denormX, y: denormY } = denormalizePoint(
                  x,
                  y,
                  width,
                  height,
                );
                return [denormX, denormY];
              })
              ?.flat()}
            closed={!drawMode}
          />
        </Layer>
      </Stage>
    </ScrollArea>
  );
};

export default InteractiveCanvas;
