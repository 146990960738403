import { notification } from '@lanaco/lnc-react-ui';
import { RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import { TLNeutralButton, TLSecondaryLoadingButton } from 'components/Button';
import { TLModal, TLModalFooter } from 'components/Modal';
import PromptModal from 'components/PromptModal/PromptModal';
import TanStackTable from 'components/TanStackTable/TanStackTable';
import { useRemoveParticipantFromTag } from 'hooks/event/tags/useRemoveParticipantFromTag';
import { TagTableData } from 'pages/Tags/interfaces';
import { useState } from 'react';
import { getErrorMsg } from 'utils/getErrorMsg';

import style from './EventParticipants.module.css';

interface Props {
  eventId: string;
  tagId: string;
  tagName?: string;
  participant?: string;
  participantId: string;
  multipleTags?: boolean;
  tags?: TagTableData[];
  //tagIds?: string[];
  closeModal: () => void;
}

const columnHelper = createColumnHelper<TagTableData>();

export const RemoveParticipantFromTag = ({
  eventId,
  tagId,
  participantId,
  tagName,
  participant,
  closeModal,
  multipleTags,
  tags,
}: Props) => {
  const hideDeleteModal = () => {
    closeModal();
  };

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [isLoading, setIsLoading] = useState(false);
  const selectedId = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    },
    [],
  );

  const mutation = useRemoveParticipantFromTag();

  const confirmRemoveTag = async () => {
    setIsLoading(true);
    mutation.mutate(
      {
        eventId: eventId,
        participantId: participantId,
        tagIds: selectedId.length !== 0 ? selectedId : [tagId],
      },
      {
        onSuccess: (data) => {
          if (data?.code === 201) {
            notification.success(
              'Tag successfully unassigned from participant.',
            );
          } else {
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          setIsLoading(false);
          hideDeleteModal();
        },
      },
    );
  };

  const participantTableColumns = [
    columnHelper.accessor('uniqueId', {
      header: 'Tag ID',
    }),
    columnHelper.accessor('name', {
      header: 'Tag',
    }),
    columnHelper.accessor('number', {
      header: 'Number',
    }),
  ];

  if (multipleTags === true) {
    return (
      <TLModal
        title={'Unassign tag from participant'}
        subtitle={`Select a tag you want to unassign from ${
          participant as string
        }`}
      >
        <TanStackTable
          getRowId={(row) => row._id}
          selectedRows={selectedRows}
          onRowSelectionChange={setSelectedRows}
          columns={participantTableColumns}
          data={tags!}
          enableRowSelection
          totalRows={tags?.length}
          onPageChange={() => {}}
        />

        <TLModalFooter
          className={style.modalFooter}
          cancelButton={
            <TLNeutralButton onClick={closeModal}>Cancel</TLNeutralButton>
          }
          actionButton={
            <TLSecondaryLoadingButton
              isLoading={isLoading}
              disabled={selectedId?.length === 0}
              onClick={confirmRemoveTag}
            >
              Unassign
            </TLSecondaryLoadingButton>
          }
        />
      </TLModal>
    );
  } else {
    return (
      <PromptModal
        title="Unassign tag?"
        warningMessage={
          <>
            Tag {tagName} will be unassigned from the participant {participant}.
          </>
        }
        confirmButtonName="Unassign"
        icon="user-minus"
        iconSize="24"
        isDeleteButton
        onConfirm={confirmRemoveTag}
        onCancel={hideDeleteModal}
      />
    );
  }
};
export default RemoveParticipantFromTag;
