import clsx from 'clsx';

import styles from './NotAvailable.module.css';

interface NotAvailableProps {
  message: string;
  className?: string;
  style?: React.CSSProperties;
}

export const NotAvailable = ({
  message,
  className,
  ...props
}: NotAvailableProps) => (
  <div className={clsx(styles.notAvailable, className)} {...props}>
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.3646 32.6358C28.6151 30.8863 26.3245 30.0077 24.0315 29.9999C21.718 29.992 19.4019 30.8706 17.6367 32.6358M42 24C42 33.9411 33.9411 42 24 42C14.0589 42 6 33.9411 6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24ZM19.5 19.5C19.5 20.3284 19.1642 21 18.75 21C18.3358 21 18 20.3284 18 19.5C18 18.6716 18.3358 18 18.75 18C19.1642 18 19.5 18.6716 19.5 19.5ZM18.75 19.5H18.765V19.53H18.75V19.5ZM30 19.5C30 20.3284 29.6642 21 29.25 21C28.8358 21 28.5 20.3284 28.5 19.5C28.5 18.6716 28.8358 18 29.25 18C29.6642 18 30 18.6716 30 19.5ZM29.25 19.5H29.265V19.53H29.25V19.5Z"
        stroke="#94A3B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <span className={styles.message}>{message}</span>
  </div>
);
