import { Box, Flex, Text } from '@mantine/core';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

import { MapPinProps } from './types';

const MapPin = ({ position, color = '#3b82f6', text }: MapPinProps) => {
  return (
    <AdvancedMarker position={position}>
      <Flex
        h={25}
        w={25}
        bg={color}
        bd="1px solid white"
        pos="relative"
        justify="center"
        align="center"
        style={{ borderRadius: 5 }}
      >
        <Box
          h={8}
          w={8}
          bg={color}
          pos="absolute"
          bottom={0}
          left="50%"
          style={{
            transform: 'rotate(45deg)',
            translate: '-50% 60%',
            borderRight: '1px solid white',
            borderBottom: '1px solid white',
          }}
        />
        <Text c="white" fz="10px">
          {text}
        </Text>
      </Flex>
    </AdvancedMarker>
  );
};

export default MapPin;
