import { TextInput } from '@lanaco/lnc-react-ui';
import { IconButton } from '@lanaco/lnc-react-ui';
import { useMediaQuery } from '@mantine/hooks';
import clsx from 'clsx';
import UserSettings from 'components/UserSettings/UserSettings';
// import { useAppDispatch } from 'hooks/useAppDispatch';
// import { useNavigate } from 'react-router';
// import { logout } from 'store/slices/userSlice/userSlice';

import { getSession } from 'services/auth';

import building from '../../assets/icons/building.svg';
import cog from '../../assets/icons/cog.svg';
import device from '../../assets/icons/device.svg';
import events from '../../assets/icons/events.svg';
import home from '../../assets/icons/home.svg';
import tag from '../../assets/icons/tag.svg';
import icon from '../../assets/images/logo.svg';

import MenuItem from './MenuItem';
import { MenuItemInterface } from './Sidebar.interface';
import style from './Sidebar.module.css';

type Props = {
  isOpen: any;
};

const menuItems: Array<MenuItemInterface> = [
  {
    name: 'home',
    path: '/',
    icon: home,
    label: 'Dashboard',
    requiredRole: 'institution',
  },
  {
    name: 'events',
    path: '/events',
    icon: events,
    label: 'Events',
  },
  {
    name: 'entities',
    path: '/entities',
    icon: events,
    label: 'Entities',
  },
  {
    name: 'devices',
    path: '/devices',
    icon: device,
    label: 'Devices',
    requiredRole: 'institution',
  },
  {
    name: 'tags',
    path: '/tags',
    icon: tag,
    label: 'Tags',
    requiredRole: 'institution',
  },
  // {
  //   name: 'participants',
  //   path: '/participants',
  //   icon: participant,
  //   label: 'Participant'
  // },
  {
    name: 'clients',
    path: '/clients',
    icon: building,
    label: 'Clients',
    requiredRole: 'institution',
  },
  {
    name: 'settings',
    path: '/settings',
    icon: cog,
    label: 'Settings',
    requiredRole: 'institution',
  },
];

const getInitials = (session: ReturnType<typeof getSession>['session']) => {
  const firstName = session?.firstName ?? 'U';
  const lastName = session?.lastName ?? 'U';

  return `${firstName[0]}${lastName[0]}`;
};

const Sidebar = (props: Props) => {
  // let dispatch = useAppDispatch();
  // let navigate = useNavigate();
  // const onClickLogout = () => {
  //   dispatch(logout());
  //   navigate('/login');
  // };
  const { session } = getSession();
  //radjeno za zahtjev Damira
  const isMobile = useMediaQuery('(max-width: 768px)');

  const onClickClose = () => {
    if (!props.isOpen) {
      const box = document.getElementById('sidebar');

      if (box != null) {
        box.style.setProperty('display', 'none');
      }
    }
  };
  return (
    <div
      className={clsx(style.sidebar, {
        [style.hidden]: props.isOpen || isMobile,
      })}
      id="sidebar"
    >
      <div>
        <div className={style.closeMenu}>
          <IconButton
            btnType={'outline'}
            icon="fas fa-times"
            size={'small'}
            color={'secondary'}
            iconStyle={'solid'}
            onClick={onClickClose}
          />
        </div>
        <img src={icon} alt="Logo"></img>

        <TextInput placeholder="Search"></TextInput>
        <div className={style.menu}>
          {isMobile ? (
            <div key={menuItems[2].name}>
              <MenuItem
                {...menuItems[2]}
                onClick={props.isOpen ? onClickClose : null}
              ></MenuItem>
            </div>
          ) : (
            menuItems
              .filter((mi) =>
                mi.requiredRole === 'institution'
                  ? !!session?.institutionId
                  : true,
              )
              .map((item: MenuItemInterface) => {
                return (
                  <div key={item.name}>
                    <MenuItem
                      {...item}
                      onClick={props.isOpen ? onClickClose : null}
                    ></MenuItem>
                  </div>
                );
              })
          )}
        </div>
      </div>
      <div>
        <UserSettings
          loggedUser={`${session?.firstName} ${session?.lastName}`}
          initials={getInitials(session)}
          role={'Client admin'}
          company={'Timeline'}
        ></UserSettings>
      </div>
    </div>
  );
};

export default Sidebar;
