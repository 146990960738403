import { IconButton } from '@lanaco/lnc-react-ui';
import { Container, Text } from '@mantine/core';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { createMRTColumnHelper, MRT_ColumnDef } from 'mantine-react-table';

import style from '../Board/EventBoard.module.css';

import { EventNotification } from './EventBoard.interface';
const columnHelper = createMRTColumnHelper<EventNotification>();

export const notificationTableColumns: MRT_ColumnDef<
  EventNotification,
  string
>[] = [
  columnHelper.accessor('updatedAt', {
    header: 'Time',
    size: 110,
    Cell: ({ cell }) => {
      const date = dayjs(new Date(cell.getValue()));
      return (
        <Text>
          <Text component="span" fw="bold">
            {date.format('HH:mm:ss')}
          </Text>
          <br />
          <Text fz="xs">{date.format('DD/MM/YYYY')}</Text>
        </Text>
      );
    },
  }),
  columnHelper.accessor('type', {
    header: 'Type',
    size: 90,
  }),
  columnHelper.display({
    id: 'message',
    header: 'Message',
    minSize: 200,
    Cell: ({ row }) => {
      const updatedAt = dayjs(new Date(row.original.updatedAt));
      const seen = dayjs(new Date(row.original.lastSeen));
      const message = row.original.message;

      return (
        <>
          <div style={{ display: 'block' }}>
            <Text component="span" fz="xs" fw="bold">
              {updatedAt.format('HH:mm:ss')}
            </Text>{' '}
            -{' '}
            <Text component="span" fz="xs">
              {updatedAt.format('DD/MM/YYYY')}
            </Text>
          </div>
          <Text component="span" fw="bold">
            {message}
          </Text>

          {/* <Text fz="sm" c="black">{row.original.message}</Text> */}

          <div style={{ display: 'block' }}>
            <Text component="span" fz={10}>
              {seen.format('HH:mm:ss')}
            </Text>{' '}
            -{' '}
            <Text component="span" fz={10}>
              {seen.format('DD/MM/YYYY')}
            </Text>
          </div>
        </>
      );
    },
    mantineTableHeadCellProps: {
      className: clsx(style.expandingRow, style.tableHeader),
    },
  }),
  {
    id: 'warningCount',
    accessorFn: (row) => row.timestamps.length,
    header: '#',
    size: 70,
    maxSize: 70,
    mantineTableHeadCellProps: {
      className: style.centeredTableHeader,
    },
    mantineTableBodyCellProps: {
      align: 'center',
    },
  },
  columnHelper.display({
    id: 'monitor',
    header: 'Monitor',
    size: 110,
    visibleInShowHideMenu: false,
    mantineTableHeadCellProps: {
      className: style.centeredTableHeader,
    },
    mantineTableBodyCellProps: {
      align: 'center',
    },
    Cell: ({ row, table }) => {
      // get if acked or not - might not need this
      // const value = row.getValue();

      // replace with monitor logic
      const handle = () => {
        table.options.meta?.unacknowledge?.(row.original);
      };

      return (
        <IconButton
          disabled={!Boolean(row.original.acknowledgeTill)}
          icon="play"
          onClick={handle}
        />
      );
    },
  }),
  columnHelper.display({
    id: 'ackDay',
    header: 'DAY',
    size: 90,
    visibleInShowHideMenu: false,
    mantineTableHeadCellProps: {
      className: style.centeredTableHeader,
    },
    mantineTableBodyCellProps: {
      align: 'center',
    },
    Cell: ({ row, table }) => {
      // get if acked or not - might not need this
      // const value = row.getValue();

      const handle = () => {
        table.options.meta?.acknowledgeDay?.(row.original);
      };

      return (
        <IconButton
          disabled={Boolean(row.original.acknowledgeTill)}
          icon="check"
          onClick={handle}
        />
      );
    },
  }),
  columnHelper.display({
    id: 'ackEvent',
    header: 'EVENT',
    size: 90,
    enableHiding: true,
    visibleInShowHideMenu: false,
    mantineTableHeadCellProps: {
      className: style.centeredTableHeader,
    },
    mantineTableBodyCellProps: {
      align: 'center',
    },
    Cell: ({ row, table }) => {
      // get if acked or not - might not need this
      // const value = row.getValue();

      const handle = () => {
        table.options.meta?.acknowledgeEvent?.(row.original);
      };

      return (
        <IconButton
          disabled={Boolean(row.original.acknowledgeTill)}
          icon="check-double"
          onClick={handle}
        />
      );
    },
  }),
  columnHelper.accessor('lastSeen', {
    header: 'Seen',
    size: 110,
    Cell: ({ cell }) => {
      const date = dayjs(new Date(cell.getValue()));
      return (
        <>
          <Text component="span" fw="bold" fz="sm">
            {date.format('HH:mm:ss')}
          </Text>
          <br />
          <Text fz="xs">{date.format('DD/MM/YYYY')}</Text>
        </>
      );
    },
  }),
];
