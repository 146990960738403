import { FlexGrid, FlexGridItem } from '@lanaco/lnc-react-ui';
import CustomMarker from 'components/CustomMarkerPin/CustomMarker';
import { Icon } from 'components/Icon/Icon';
import { useFetchSingleEventLayout } from 'hooks/event/layout/useFetchSingleEventLayout';
import { useEffect, useState } from 'react';
import ImageMarker, { type Marker } from 'react-image-marker';
import { formatDateTimeFull } from 'utils/dateFormatters';

import style from '../Events.module.css';
import {
  type EventLayoutPresentationImage,
  EventLayoutPresentationType,
} from '../Layout/interfaces';
import { LayoutLocationPicker } from '../Layout/LayoutScehemForm/LayoutLocationPicker';

type Props = {
  data: any;
  eventId: string;
  layoutId: string;
  sectionId: string;
  timezone: string;
};

const DetailsAbout = (props: Props) => {
  const { data: layoutData } = useFetchSingleEventLayout({
    eventId: props.eventId,
    layoutId: props.layoutId,
  });
  const differenceInTime =
    new Date(props?.data?.endDateTime).getTime() -
    new Date(props?.data?.startDateTime).getTime();

  let differenceInDays: string | number = '';

  const startDateTime = props?.data?.startDateTime
    ? new Date(props.data.startDateTime)
    : null;
  const endDateTime = props?.data?.endDateTime
    ? new Date(props.data.endDateTime)
    : null;

  if (startDateTime && endDateTime) {
    const differenceInTime = endDateTime.getTime() - startDateTime.getTime();
    differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
    // Ovdje koristite differenceInDays...
  }

  const presentationSource = (
    layoutData?.data?.presentation as EventLayoutPresentationImage
  )?.image?.largeSource;
  const onLayoutSchemeChange = (
    type: EventLayoutPresentationType,
    locationOrImage:
      | File
      | (google.maps.LatLngLiteral & { zoom: number | undefined }),
  ) => {};
  const isImage =
    layoutData?.data?.presentation?.type === EventLayoutPresentationType.Image;

  const [marker, setMarker] = useState<Array<Marker>>([]);

  useEffect(() => {
    if (
      props &&
      props.data &&
      props.data.coordinates &&
      props.data.coordinates.lat &&
      props.data.coordinates.lng
    ) {
      setMarker([
        {
          top: props.data.coordinates.lat as number,
          left: props.data.coordinates.lng as number,
        },
      ]);
    }
  }, [props, props?.data?.coordinates?.lat, props?.data?.coordinates?.lng]);

  return (
    <div className={style.timing_location_details_about}>
      <FlexGrid>
        <FlexGridItem XS={12} L={7}>
          <div className={style.timing_location_details_about_content}>
            <FlexGrid>
              <FlexGridItem XS={12} L={12}>
                <div
                  className={
                    style.timing_location_details_about_content_header_title
                  }
                >
                  Overview
                </div>
              </FlexGridItem>
              <FlexGridItem XS={12} L={6}>
                <label
                  className={style.timing_location_details_about_content_title}
                >
                  Layout
                </label>
                <p className={style.timing_location_details_about_content_item}>
                  {props.data?.eventLayout?.name}
                </p>{' '}
              </FlexGridItem>
              <FlexGridItem XS={12} L={6}>
                <label
                  className={style.timing_location_details_about_content_title}
                >
                  Number
                </label>
                <p className={style.timing_location_details_about_content_item}>
                  {props.data?.orderNumber}
                </p>{' '}
              </FlexGridItem>
              <FlexGridItem XS={12} L={6}>
                <label
                  className={style.timing_location_details_about_content_title}
                >
                  Start
                </label>
                <p className={style.timing_location_details_about_content_item}>
                  {formatDateTimeFull(
                    props?.data?.startDateTime!,
                    props?.timezone!,
                  )}
                </p>{' '}
              </FlexGridItem>
              <FlexGridItem XS={12} L={6}>
                <label
                  className={style.timing_location_details_about_content_title}
                >
                  End
                </label>
                <p className={style.timing_location_details_about_content_item}>
                  {formatDateTimeFull(
                    props?.data?.endDateTime!,
                    props?.timezone!,
                  )}
                </p>{' '}
              </FlexGridItem>
              <FlexGridItem XS={12} L={6}>
                <label
                  className={style.timing_location_details_about_content_title}
                >
                  Duration
                </label>
                <p className={style.timing_location_details_about_content_item}>
                  {differenceInDays !== '' && <>{differenceInDays} days</>}
                </p>{' '}
              </FlexGridItem>
              <FlexGridItem XS={12} L={6}>
                <label
                  className={style.timing_location_details_about_content_title}
                >
                  Capacity
                </label>
                <p className={style.timing_location_details_about_content_item}>
                  {props.data?.capacity}
                </p>{' '}
              </FlexGridItem>
              <FlexGridItem XS={12} L={6}>
                <label
                  className={style.timing_location_details_about_content_title}
                >
                  Organisation
                </label>
                <p className={style.timing_location_details_about_content_item}>
                  {props?.data?.information?.name}
                </p>{' '}
              </FlexGridItem>
              <FlexGridItem XS={12} L={6}>
                <label
                  className={style.timing_location_details_about_content_title}
                >
                  Contact person
                </label>
                <p className={style.timing_location_details_about_content_item}>
                  {props?.data?.information?.contactPerson[0]?.fullName}
                </p>{' '}
              </FlexGridItem>
            </FlexGrid>
          </div>
        </FlexGridItem>
        <FlexGridItem XS={12} L={5}>
          <>
            {!presentationSource && isImage && (
              <>
                <Icon
                  icon="camera"
                  className={style.layout_details_image_map_no_image}
                />
                <p className={style.layout_details_image_map_no_image_text}>
                  No image
                </p>
              </>
            )}
            {!presentationSource && !isImage && (
              <LayoutLocationPicker
                eventLayout={layoutData?.data}
                visible={true}
                onChange={onLayoutSchemeChange}
                timingLocationCoordinates={props?.data?.coordinates}
              />
            )}

            {presentationSource && isImage && (
              <ImageMarker
                src={
                  (
                    layoutData?.data
                      ?.presentation as EventLayoutPresentationImage
                  )?.image?.largeSource
                }
                markers={marker}
                markerComponent={CustomMarker}
              />
            )}
          </>
        </FlexGridItem>
      </FlexGrid>
    </div>
  );
};

export default DetailsAbout;
