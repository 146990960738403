import { Button } from '@lanaco/lnc-react-ui';
import { TLNeutralButton } from 'components/Button';
import ButtonGroupData from 'components/ButtonGroupData/ButtonGroupData';
import { TLCase, TLSwitch } from 'components/utils';

import arrowUp from '../../../assets/icons/outline/cloud-arrow-up.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import arrowDown from '../../../assets/icons/solid/arrow-down-tray.svg';
import power from '../../../assets/icons/solid/power.svg';
import trash from '../../../assets/icons/solid/trash.svg';
import xMark from '../../../assets/icons/solid/x-mark.svg';
import { CodebookAction } from '../interfaces';

import style from './TableHeader.module.css';

type Props = {
  formAddTitle: any;
  buttonAddTitle: any;
  actions: Array<CodebookAction>;
  selectedData?: any;
  setSelectedData?: any;
  onConfirmClick?: any;
  addClickHandler: any;
  buttonsGroup?: any;
};

export const TableHeader = (props: Props) => {
  return (
    <div className={style.tableHeader}>
      <ButtonGroupData buttons={props.buttonsGroup}></ButtonGroupData>
      <TLSwitch condition={props.selectedData?.length > 0}>
        <TLCase value>
          <div className={style.buttonGroup}>
            <Button
              className={style.actionButton}
              btnType={'outline'}
              iconStyle={'regular'}
              color={'neutral'}
              onClick={() => {
                props.setSelectedData([]);
              }}
            >
              <img
                width={20}
                height={20}
                src={xMark}
                alt="Clear selection"
              ></img>
              Clear selection
            </Button>
            {props.actions.includes(CodebookAction.export) && (
              <Button
                className={style.actionButton}
                btnType={'outline'}
                iconStyle={'regular'}
                color={'neutral'}
              >
                <img width={17} height={18} src={arrowDown} alt="Export"></img>
                Export
              </Button>
            )}
            {props.actions.includes(CodebookAction.disable) && (
              <Button
                className={style.actionButton}
                btnType={'outline'}
                iconStyle={'regular'}
                color={'danger'}
              >
                <img
                  className={style.disableIcon}
                  width={17}
                  height={18}
                  src={power}
                  alt="Power"
                ></img>{' '}
                Disable
              </Button>
            )}
            {props.actions.includes(CodebookAction.delete) && (
              <Button
                className={style.actionButton}
                width={17}
                height={18}
                btnType={'filled'}
                iconStyle={'regular'}
                color={'danger'}
              >
                <img
                  width={17}
                  height={18}
                  className={style.trashIcon}
                  src={trash}
                  alt="Delete"
                ></img>
                Delete
              </Button>
            )}
          </div>
        </TLCase>
        <TLCase value={false}>
          <div className={style.buttonGroup}>
            {props.actions.includes(CodebookAction.import) && (
              <Button
                className={style.importButton}
                btnType={'basic'}
                iconStyle={'regular'}
                color={'neutral'}
              >
                <img src={arrowUp} alt="Arrow up"></img>
                Import
              </Button>
            )}

            {props.actions.some(
              (a) => a === CodebookAction.all || a === CodebookAction.add,
            ) && (
              <TLNeutralButton
                className={style.addButton}
                onClick={props.addClickHandler}
              >
                <Plus className={style.addButtonImg} />
                {props.buttonAddTitle}
              </TLNeutralButton>
            )}
          </div>
        </TLCase>
      </TLSwitch>
    </div>
  );
};
