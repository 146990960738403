import { ClockIcon } from '@heroicons/react/24/outline';
import { Stack, TextInput, Flex, Textarea, ActionIcon } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import { identity, pickBy } from 'lodash';
import { CreateAndUpdateEntityReq } from 'pages/Entities/Home/types';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useRef, useEffect } from 'react';
import {
  useEntity,
  useTimezoneByEvent,
  useUpdateEvent,
} from 'services/entity/home';
import { returnDateAndTimeFromDateTime } from 'utils/dateFormatters';

import { CreateAndUpdateEntityAboutRegistration } from '../types';

import styles from './Modals.module.scss';

const RegistrationModal = ({
  setOnFormSubmit,
  setFormState,
  setIsFormValid,
  dataId,
}: ModalFormProps) => {
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);
  const { data: eventData } = useEntity(dataId ?? '');
  const { data: timezoneData } = useTimezoneByEvent(dataId ?? '');
  const { mutate: updateEventData } = useUpdateEvent();
  const form = useForm<CreateAndUpdateEntityAboutRegistration>({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      link: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      metadata: '',
    },
    validate: {
      startDate: (value) => (!value ? 'Start date is required' : null),
      endDate: (value) => (!value ? 'End date is required' : null),
      startTime: (value) => (!value ? 'Start time is required' : null),
      endTime: (value) => (!value ? 'End time is required' : null),
    },
  });

  const startTimePickerControl = (
    <ActionIcon
      variant="subtle"
      color="#0F172A"
      onClick={() => startTimeRef.current?.showPicker()}
    >
      <ClockIcon width={20} height={20} />
    </ActionIcon>
  );

  const endTimePickerControl = (
    <ActionIcon
      variant="subtle"
      color="#0F172A"
      onClick={() => endTimeRef.current?.showPicker()}
    >
      <ClockIcon width={20} height={20} />
    </ActionIcon>
  );

  useEffect(() => {
    if (eventData && timezoneData) {
      form.setFieldValue(
        'startDate',
        eventData?.registration?.startDate
          ? returnDateAndTimeFromDateTime(
              eventData.registration.startDate,
              timezoneData,
            ).date
          : '',
      );
      form.setFieldValue(
        'startTime',
        eventData?.registration?.startDate
          ? returnDateAndTimeFromDateTime(
              eventData.registration.startDate,
              timezoneData,
            ).time
          : '',
      );
      form.setFieldValue(
        'endDate',
        eventData?.registration?.finishDate
          ? returnDateAndTimeFromDateTime(
              eventData.registration.finishDate,
              timezoneData,
            ).date
          : '',
      );
      form.setFieldValue(
        'endTime',
        eventData?.registration?.finishDate
          ? returnDateAndTimeFromDateTime(
              eventData.registration.finishDate,
              timezoneData,
            ).time
          : '',
      );
      form.setFieldValue('link', eventData?.registration?.url ?? '');
      form.setFieldValue('metadata', eventData?.registration?.metadata ?? '');
    }
  }, [eventData, timezoneData]);

  useEffect(() => {
    if (form.isValid()) {
      setIsFormValid(true);
      const newFormState = form.values;
      if (setFormState) {
        const registrationStep: Partial<CreateAndUpdateEntityReq> = {
          registration: {
            url: newFormState.link,
            startDate:
              newFormState.startDate && newFormState.startTime
                ? newFormState.startDate + 'T' + newFormState.startTime + ':00Z'
                : null,
            finishDate:
              newFormState.endDate && newFormState.endTime
                ? newFormState.endDate + 'T' + newFormState.endTime + ':00Z'
                : null,
            metadata: newFormState.metadata,
          },
        };
        const cleandedData = pickBy(registrationStep, identity);
        setFormState((prevState) => ({
          ...prevState,
          ...cleandedData,
        }));
      }
    } else {
      setIsFormValid(false);
    }
  }, [form.values, form.isValid()]);

  const onSubmit = (data) => {
    if (dataId) {
      updateEventData({ data: data, eventId: dataId });
    }
  };

  useEffect(() => {
    if (setOnFormSubmit) {
      setOnFormSubmit(() => onSubmit);
    }
  }, [setOnFormSubmit]);

  return (
    <Stack gap="1.5rem">
      <TextInput
        label="Registration link"
        placeholder="e.g. https://www.company.com/event"
        classNames={{ label: styles.labelStyle }}
        key={form.key('link')}
        {...form.getInputProps('link')}
      />
      <Flex w="100%" gap="1.5rem" justify="space-between">
        <div style={{ flex: 1 }}>
          {' '}
          <DatePickerInput
            label="Start date"
            showAsterisk={true}
            value={form.values.startDate}
            onChange={(date) => form.setFieldValue('startDate', date)}
          />
        </div>

        <div style={{ flex: 1 }}>
          <TimeInput
            label="Start time"
            withAsterisk
            key={form.key('startTime')}
            ref={startTimeRef}
            rightSection={startTimePickerControl}
            {...form.getInputProps('startTime')}
          />
        </div>
      </Flex>

      <Flex w="100%" gap="1.5rem" justify="space-between">
        <div style={{ flex: 1 }}>
          <DatePickerInput
            label="End date"
            showAsterisk={true}
            value={form.values.endDate}
            onChange={(date) => form.setFieldValue('endDate', date)}
          />
        </div>

        <div style={{ flex: 1 }}>
          <TimeInput
            label="End time"
            withAsterisk
            key={form.key('endTime')}
            ref={endTimeRef}
            rightSection={endTimePickerControl}
            {...form.getInputProps('endTime')}
          />
        </div>
      </Flex>

      <Textarea
        label="Metadata"
        placeholder="Paste JSON metadata here"
        autosize
        minRows={6}
        classNames={{ label: styles.labelStyle }}
        key={form.key('metadata')}
        {...form.getInputProps('metadata')}
      />
    </Stack>
  );
};
export default RegistrationModal;
