import {
  ViewfinderCircleIcon,
  Square3Stack3DIcon,
  MapPinIcon,
  TagIcon,
} from '@heroicons/react/24/solid';
import { SortingParamType } from 'components/SortingDropdown/types';
import DeviceIcon from 'components/SvgIcons/DeviceIcon';
import { ModalWidth } from 'utils/consts';

import { HubSectionType, LayoutType } from './Hub/types';

export const hubSections: Array<HubSectionType> = [
  {
    type: 'trackingObjects',
    title: 'Tracking objects',
    addButton: 'Add tracking object',
    modalSize: ModalWidth.Large,
    modalTitle: 'Add new tracking object',
    modalSubTitles: [
      'Tracking object information',
      'Tracking object image',
      'Control access',
    ],
    icon: ViewfinderCircleIcon,
    hasFooter: true,
  },
  {
    type: 'layouts',
    title: 'Layout',
    addButton: 'Create layout',
    modalSize: ModalWidth.ExtraLarge,
    modalTitle: 'Creating layout',
    modalSubTitles: [],
    icon: Square3Stack3DIcon,
    hasFooter: true,
  },
  {
    type: 'sections',
    title: 'Sections',
    addButton: 'Create section',
    modalSize: ModalWidth.ExtraLarge,
    modalTitle: 'Creating section',
    modalSubTitles: [],
    icon: MapPinIcon,
    hasFooter: true,
  },
  {
    type: 'devices',
    title: 'Devices',
    addButton: 'Add device',
    modalSize: ModalWidth.ExtraLarge,
    modalTitle: 'Add devices',
    modalSubTitles: [],
    icon: DeviceIcon,
    hasFooter: false,
  },
  {
    type: 'tags',
    title: 'Tags',
    addButton: 'Add tag',
    modalSize: ModalWidth.ExtraLarge,
    modalTitle: 'Add tags',
    modalSubTitles: [],
    icon: TagIcon,
    hasFooter: false,
  },
];

export const hubTrackingObjectSortingParams: Array<SortingParamType> = [
  {
    field: 'firstLastName',
    order: 1,
    value: 'A-Z',
  },
  {
    field: 'firstLastName',
    order: -1,
    value: 'Z-A',
  },
  {
    field: 'createdAt',
    order: 1,
    value: 'Latest',
  },
  {
    field: 'createdAt',
    order: -1,
    value: 'Oldest',
  },
];

export const hubTagsSortingParams: Array<SortingParamType> = [
  {
    field: 'createdAt',
    order: 1,
    value: 'Latest',
  },
  {
    field: 'createdAt',
    order: -1,
    value: 'Oldest',
  },
];

export const tagAssignSortingParams: Array<SortingParamType> = [
  {
    field: 'tag',
    order: 1,
    value: 'Tag: A-Z',
  },
  {
    field: 'tag',
    order: -1,
    value: 'Tag: Z-A',
  },
  {
    field: 'number',
    order: 1,
    value: 'Number: A-Z',
  },
  {
    field: 'number',
    order: -1,
    value: 'Number: Z-A',
  },
];

export const mappingDevicesSortingParams: Array<SortingParamType> = [
  {
    field: 'mapped',
    order: 1,
    value: 'Mapped',
  },
  {
    field: 'mapped',
    order: -1,
    value: 'Non-Mapped',
  },
];

export const addDevicesSortingParams: Array<SortingParamType> = [
  {
    field: 'name',
    order: 1,
    value: 'A-Z',
  },
  {
    field: 'name',
    order: -1,
    value: 'Z-A',
  },
];

export const addTagsSortingParams: Array<SortingParamType> = [
  {
    field: 'createdAt',
    order: 1,
    value: 'Latest',
  },
  {
    field: 'createdAt',
    order: -1,
    value: 'Oldest',
  },
];

export const trackingObjectAssignSortingParams: Array<SortingParamType> = [
  {
    field: 'name',
    order: 1,
    value: 'A-Z',
  },
  {
    field: 'name',
    order: -1,
    value: 'Z-A',
  },
];

export const admissionSortingParams: Array<SortingParamType> = [
  {
    field: 'firstLastName',
    order: 1,
    value: 'A-Z',
  },
  {
    field: 'firstLastName',
    order: -1,
    value: 'Z-A',
  },
  {
    field: 'admissionDateTime',
    order: -1,
    value: 'Latest',
  },
  {
    field: 'admissionDateTime',
    order: 1,
    value: 'Oldest',
  },
];

export const addTrackingObjectSortingParams: Array<SortingParamType> = [
  {
    field: 'name',
    order: 1,
    value: 'A-Z',
  },
  {
    field: 'name',
    order: -1,
    value: 'Z-A',
  },
];

export const layoutTypes: Array<LayoutType> = [
  { id: 'image', value: 'Image', infoText: 'Upload layout image' },
  {
    id: 'dynamicMap',
    value: 'Dynamic map',
    infoText: 'Place pin on a map where you want',
  },
  {
    id: 'fixedMap',
    value: 'Fixed Map',
    infoText: 'Place pin on a map where you want',
  },
  {
    id: 'library',
    value: 'Library',
    infoText: 'Select one of preselected library',
  },
];
