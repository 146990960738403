import anthenaImage from '../../assets/images/device/anthena.png';
import anthenaRelayImage from '../../assets/images/device/anthenaRelay.png';
import processorImage from '../../assets/images/device/processor.png';
import relayImage from '../../assets/images/device/relay.png';
import switchImage from '../../assets/images/device/switch.png';

export const deviceType = {
  anthena: anthenaImage,
  anthenaRelay: anthenaRelayImage,
  processor: processorImage,
  relay: relayImage,
  switch: switchImage,
};

// Funckija za pronalazenje centralne tacke unutar sekcije
export const getCenterPoint = (points: Array<Array<number>>) => {
  const xValues = points.map((p) => p[0]);
  const yValues = points.map((p) => p[1]);

  const centerX = (Math.min(...xValues) + Math.max(...xValues)) / 2;
  const centerY = (Math.min(...yValues) + Math.max(...yValues)) / 2;

  return {
    x: centerX,
    y: centerY,
  };
};
