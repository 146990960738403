import { notification } from '@lanaco/lnc-react-ui';
import { RowSelectionState } from '@tanstack/react-table';
import PromptModal from 'components/PromptModal/PromptModal';
import { useMutateDeleteEventTimingLocation } from 'hooks/event/timing-location/useMutateDeleteTimingLocation';
import { useNavigate } from 'react-router-dom';
import { getErrorMsg } from 'utils/getErrorMsg';

interface DeleteTimingLocationModalProps {
  id: string;
  name?: string;
  eventName?: string;
  eventId: string;
  layoutId: string;
  timinglocationIds?: string[];
  redirectToTimingLocationTable?: boolean;
  closeModal: () => void;
  setSelectedRows: (selectedRows: RowSelectionState) => void;
}

export const DeleteEventTimingLocationModal = ({
  id,
  eventId,
  name,
  eventName,
  timinglocationIds,
  redirectToTimingLocationTable,
  layoutId,
  closeModal,
  setSelectedRows,
}: DeleteTimingLocationModalProps) => {
  const { mutate, isLoading } = useMutateDeleteEventTimingLocation();
  const navigate = useNavigate();

  const hideDeleteModal = () => {
    closeModal();
  };

  const confirmDeleteTimingLocation = async () => {
    mutate(
      {
        eventId,
        timingLocationId: id,
        timingLocationIds: timinglocationIds,
        layoutId: layoutId,
      },
      {
        onSuccess: (response) => {
          console.log(response);
          if (response && (response?.code === 200 || response?.code === 201)) {
            if (id === undefined) {
              notification.success('Timing locations successfully deleted.');
            } else {
              notification.success('Timing location successfully deleted.');
            }
          } else {
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          hideDeleteModal();
          setSelectedRows({});
          if (redirectToTimingLocationTable) {
            navigate(`event/${eventId}/timinglocations`);
          }
        },
      },
    );
  };

  return (
    <PromptModal
      title={
        id === undefined
          ? 'Remove timing locations?'
          : 'Remove timing location?'
      }
      isLoading={isLoading}
      warningMessage={
        id === undefined ? (
          <>
            Are you sure you want to delete {timinglocationIds?.length}
            <br />
            timing location from event from the database?
          </>
        ) : (
          <>
            You will remove {name} from {eventName}.
          </>
        )
      }
      confirmButtonName="Remove"
      icon="trash"
      iconSize="24"
      isDeleteButton
      onConfirm={() => {
        confirmDeleteTimingLocation();
      }}
      onCancel={hideDeleteModal}
    />
  );
};
