import { Group, Stack } from '@mantine/core';
import { RowSelectionState } from '@tanstack/table-core';
import { TLPrimaryButton } from 'components/Button';
import {
  EventAdmissionCard,
  EventAdmissionCardProps,
} from 'components/Card/EventAdmissionCard';
import { InputSearch } from 'components/Input';
import { TLModal } from 'components/Modal';
import { RowAction } from 'components/TanStackTable/TanStackTable';
import { useGetEventDetails } from 'hooks/event/details';
import { useFetchEventParticipants } from 'hooks/event/participants/useFetchEventParticipants';
import {
  MRT_ColumnDef,
  MantineReactTable,
  createMRTColumnHelper,
  useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import {
  EventAdmissionTableData,
  EventParticipantTableData,
} from '../Participants/EventParticipants.interface';

// import AddEventTagForm from './AddEventTagForm';
// import AddRemoveParticipantTagForm from './AddRemoveParticipantTagForm';
// import { EventTagTableData } from './EventTag.interface';
import styles from './EventAdmission.module.css';
import ScanTagParticipantForm from './ScanTagParticipantForm';

// import { RemoveEventTagModal } from './RemoveEventTagModal';

type Props = {};

const columnHelper = createMRTColumnHelper<EventAdmissionTableData>();

const tableColumns: MRT_ColumnDef<EventAdmissionTableData, any>[] = [
  columnHelper.accessor((p) => p.participant?.firstLastName, {
    header: 'Participant',
  }),
  columnHelper.accessor((p) => p.participant?.externalId, {
    header: 'Registration Id',
  }),
  columnHelper.accessor('number', {
    header: 'Tag number',
  }),
  columnHelper.accessor('uniqueId', {
    header: 'Unique Id',
  }),
];

const ROW_LIMIT = 10;

const EventAdmission = (props: Props) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const { width } = useWindowSize();
  //const [tagToEdit, setTagToEdit] = useState<EventTagTableData | null>(null);
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [
    participantToEdit,
    setParticipantToEdit,
  ] = useState<EventParticipantTableData | null>(null);

  const [replaceParticipant, setReplaceParticipant] = useState<boolean>(false);

  const { id: eventId } = useParams<{ id: string }>();

  const event = useGetEventDetails(eventId);

  const [admitted, setAdmitted] = useState<boolean>(true);

  const getHandleStatusFilterChangeCallback = (admitted: boolean) => () => {
    setPage(1);
    setAdmitted(admitted);
  };

  const { data, isFetching } = useFetchEventParticipants({
    eventId: eventId as string,
    offset: (page - 1) * ROW_LIMIT,
    limit: ROW_LIMIT,
    filter: search,
    admitted: admitted,
  });

  const _route = data?._route;

  const eventTags = useMemo(
    () =>
      data?.data
        .map((a) => a.tags.map((t) => ({ ...t, participant: a })))
        .flat() || [],
    [data],
  );

  const handleAddNewAdmissionClick = () => {
    showScanTagParticipantModal();
  };

  const [
    showScanTagParticipantModal,
    _hidesScanTagParticipantModal,
  ] = useModal(() => {
    const hideModal = () => {
      _hidesScanTagParticipantModal();
    };

    return (
      <TLModal
        title={'Scan'}
        subtitle={`Scanning tags and participant at ${event?.name}`}
        onClose={hideModal}
        hideFooter
      >
        <ScanTagParticipantForm
          onClose={hideModal}
          eventId={event?._id as string}
        />
      </TLModal>
    );
  }, []);

  const table = useMantineReactTable({
    // TODO: check what functions are needed
    // isLoading: isFetching,
    // onPageChange: handlePageChange,
    // headerTitle: <ButtonGroupData buttons={[]} />,
    // enableRowSelection: true,
    // showHeader: true,
    // rowActions: tableRowActions,
    // tableActions: tagTableActions,
    // selectedRows: selectedRows,
    // onRowSelectionChange: setSelectedRows,
    columns: tableColumns as MRT_ColumnDef<EventAdmissionTableData>[],
    data: eventTags as EventAdmissionTableData[],
    // state: {
    //   pagination: {
    //     pageIndex: page - 1,
    //     pageSize: ROW_LIMIT,
    //   },
    //   rowSelection: selectedRows,
    //   isLoading: isFetching,
    // },
    // pageCount: useMemo(() => Math.ceil(_route.total / ROW_LIMIT), [
    //   _route.total,
    // ]),
    // rowCount: _route.total,
    // onRowSelectionChange: setSelectedRows,
  });

  return (
    <div className={styles.table}>
      <Group mb="md" justify="space-between">
        <div>
          <InputSearch
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            className={styles.search}
          />
        </div>

        <TLPrimaryButton
          leadingIcon="plus"
          onClick={handleAddNewAdmissionClick}
        >
          Add
        </TLPrimaryButton>
      </Group>
      {width < 860 && (
        <Stack pb={96}>
          {eventTags.map((tag) => (
            <EventAdmissionCard
              key={tag._id}
              tag={(tag as unknown) as EventAdmissionCardProps['tag']}
            />
          ))}
        </Stack>
      )}
      {width >= 860 && <MantineReactTable table={table} />}
      {/* <TanStackTable
        getRowId={(row) => row._id}
        selectedRows={selectedRows}
        onRowSelectionChange={setSelectedRows}
        isLoading={isFetching}
        pageIndex={page}
        pageSize={ROW_LIMIT}
        totalRows={_route.total}
        pageCount={Math.ceil(_route.total / ROW_LIMIT)}
        data={flattenEventParticipantData}
        rowActions={tableRowActions}
        onPageChange={handlePageChange}
        headerTitle={<ButtonGroupData buttons={[]} />}
        // rowClassName={(row) =>
        //   row.status === 'disabled' ? 'text-gray-400' : ''
        // }
        tableActions={tagTableActions}
        enableRowSelection
        showHeader
      /> */}
    </div>
  );
};

export default EventAdmission;
