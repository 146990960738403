import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { EventParticipantTableData } from 'pages/Events/Participants/EventParticipants.interface';
import { client } from 'services/api';
import { Response } from 'types/response';
import { GET_EVENT_PARTICIPANTS } from 'utils/apiUrl';

import { eventParticipantBaseKey } from './eventParticipantBaseKey';

interface EventParticipantProps
  extends UseQueryOptions<Response<EventParticipantTableData[]>, AxiosError> {
  eventId: string;
  limit?: number;
  offset?: number;
  filter?: string;
  gender?: string;
  tagId?: string;
  admitted?: boolean;
}

export const useFetchEventParticipants = ({
  eventId,
  limit,
  offset,
  filter,
  gender,
  tagId,
  admitted,
  ...rest
}: EventParticipantProps) => {
  const queryClient = useQueryClient();
  const queryResult = useQuery<
    Response<EventParticipantTableData[]>,
    AxiosError
  >({
    queryKey: [
      eventParticipantBaseKey,
      eventId,
      limit,
      offset,
      filter,
      gender,
      tagId,
      admitted,
    ],
    queryFn: async () => {
      const retVal = await client.get<Response<EventParticipantTableData[]>>(
        GET_EVENT_PARTICIPANTS(
          eventId,
          limit,
          offset,
          filter,
          gender,
          tagId,
          admitted,
        ),
      );

      retVal.data.data.forEach(async (eventTag) =>
        queryClient.setQueryData<EventParticipantTableData>(
          [eventParticipantBaseKey, eventTag._id],
          eventTag,
        ),
      );
      return retVal.data;
    },
    ...rest,
  });

  return queryResult;
};
