export enum ModalWidth {
  Auto = 'auto',
  Small = 320,
  Medium = 640,
  Large = 800,
  ExtraLarge = 1280,
}

export const calculatePercentage = (maxNumber: number, number: number) => {
  if (number !== 0) {
    return Number(((number * 100) / maxNumber).toFixed(2));
  } else {
    return 0;
  }
};

export const genders = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const statuses = [
  {
    value: 'active',
    label: 'Enabled',
  },
  {
    value: 'disabled',
    label: 'Disabled',
  },
];

export const states = [
  {
    value: 'online',
    label: 'Online',
  },
  {
    value: 'offline',
    label: 'Offline',
  },
  {
    value: 'idle',
    label: 'Idle',
  },
];

export const convertFirstLetterToUppercase = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1);
