import { ErrorMessage } from '@hookform/error-message';
import { TextAreaInput, TextInput } from '@lanaco/lnc-react-ui';
import { Text } from '@mantine/core';
import clsx from 'clsx';
import {
  FieldErrors,
  FieldPath,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';

import { InputError } from '../InputError';

import styles from './InputGroup.module.scss';

interface InputGroupProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  name: FieldPath<T>;
  label: string;
  placeholder: string;
  withAsterisk?: boolean;
  errors?: FieldErrors;
  className?: string;
  mode?: 'input' | 'textarea';
}

export const InputGroup = <T extends FieldValues>({
  register,
  className,
  withAsterisk,
  name,
  label,
  placeholder,
  errors,
  mode = 'input',
}: InputGroupProps<T>) => {
  return (
    <label className={clsx(styles.inputGroup, className)}>
      {label && (
        <span className={styles.label}>
          {label}
          {withAsterisk && (
            <Text component="span" c="red">
              *
            </Text>
          )}
        </span>
      )}
      {mode === 'input' && (
        <TextInput {...register(name)} placeholder={placeholder} />
      )}
      {mode === 'textarea' && (
        <TextAreaInput {...register(name)} placeholder={placeholder} />
      )}

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <InputError message={message} />}
      />
    </label>
  );
};
