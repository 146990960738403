import { AxiosError } from 'axios';
import { AxiosErrorResponse } from 'services/response-interceptor';

export const getErrorMsg = (err: unknown): string => {
  const error = err as Error;

  if (error instanceof AxiosErrorResponse) {
    return error.response.data.message;
  }

  if (error instanceof AxiosError) {
    return error.response?.data.message ?? error.message;
  }

  return error.message;
};
