import { getSocket } from "services/socket";

import { EventNotification } from "./EventBoard.interface"

export enum EmitEvents {
  See = 'notification-see',
  Acknowledge = 'notification-acknowledge',
  Unacknowledge = 'notification-unacknowledge',
  StartTrackMuted = 'notification-track-muted',
  StopTrackMuted = 'notification-stop-track-muted',
  StartTrackLive = 'notification-track-live',
  StopTrackLive = 'notification-stop-track-live',
}

export enum ListenEvents {
  NewNotification = 'new-notification',
  NotificationUpdated = 'notification-updated',
  NotificationSeen = 'notification-seen',
  NotificationAcknowledged = 'notification-acknowledged',
  NotificationUnacknowledged = 'notification-unacknowledged',
}

// Cient to server
export type EmitEventsMap = {
  [EmitEvents.See]: (data: {notificationId: string}) => void;
  [EmitEvents.Acknowledge]: (data: {notificationId: string, acknowledgeTill: Date}) => void;
  [EmitEvents.Unacknowledge]: (data: {notificationId: string}) => void;
  [EmitEvents.StartTrackLive]: (child: string) => void;
  [EmitEvents.StartTrackMuted]: (child: string) => void;
  [EmitEvents.StopTrackLive]: (child: string) => void;
  [EmitEvents.StopTrackMuted]: (child: string) => void;
}

export type ListenEventsMap = {
  [ListenEvents.NewNotification]: (data: EventNotification) => void,
  [ListenEvents.NotificationUpdated]: (data: EventNotification) => void,
  [ListenEvents.NotificationSeen]: (data: EventNotification) => void,
  [ListenEvents.NotificationAcknowledged]: (data: EventNotification) => void,
  [ListenEvents.NotificationUnacknowledged]: (data: EventNotification) => void,
}

export function acknowledgeNotification(notification: EventNotification, acknowledgeTill: Date) {
  const socket = getSocket<ListenEventsMap, EmitEventsMap>().socket;

  socket.emit(EmitEvents.Acknowledge, {notificationId: notification.id, acknowledgeTill})
}

export function unacknowledgeNotification(notification: EventNotification) {
  const socket = getSocket<ListenEventsMap, EmitEventsMap>().socket;

  socket.emit(EmitEvents.Unacknowledge, {notificationId: notification.id})
}

export function markNotificationsAsSeen(notifications: EventNotification[]) {
  const socket = getSocket<ListenEventsMap, EmitEventsMap>().socket;

  for (const notification of notifications) {
    if (!notification.readDate) {
      socket.emit(EmitEvents.See, {notificationId: notification.id})
    }
  }
}