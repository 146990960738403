import { forwardRef } from 'react';

import { SvgIconProps } from './interface';

const DevicePlusIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  (
    {
      title,
      titleId,
      height = '1em',
      width = '1em',
      fill = 'currentColor',
      ...props
    },
    ref,
  ) => (
    <svg
      ref={ref}
      height={height}
      width={width}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M5.69416 2.43404C6.00674 2.70583 6.0398 3.17955 5.76801 3.49212C3.92048 5.6169 3.3404 8.46409 4.03226 11.044C4.13955 11.4441 3.90219 11.8554 3.50212 11.9627C3.10204 12.07 2.69074 11.8326 2.58345 11.4326C1.767 8.38801 2.44972 5.02234 4.63608 2.50788C4.90787 2.19531 5.38159 2.16225 5.69416 2.43404Z"
        fill={fill}
      />
      <path
        d="M8.22516 4.96577C8.55006 5.2227 8.60516 5.69437 8.34823 6.01927C7.41846 7.19498 7.13721 8.71889 7.50889 10.1067C7.61605 10.5068 7.37856 10.918 6.97845 11.0252C6.57834 11.1323 6.16711 10.8949 6.05995 10.4947C5.56956 8.66369 5.93865 6.64801 7.17167 5.08883C7.4286 4.76393 7.90027 4.70884 8.22516 4.96577Z"
        fill={fill}
      />
      <path
        d="M12.0001 8.14865C12.4143 8.14865 12.7501 8.48443 12.7501 8.89865V13.5486H18.15C18.6285 13.5486 19.0324 13.5486 19.3633 13.5756C19.7099 13.6039 20.0418 13.6656 20.3577 13.8265C20.8375 14.071 21.2276 14.4611 21.4721 14.9409C21.633 15.2568 21.6947 15.5887 21.723 15.9353C21.75 16.2662 21.75 16.6701 21.75 17.1486V17.7485C21.75 18.227 21.75 18.6309 21.723 18.9618C21.6947 19.3084 21.633 19.6403 21.4721 19.9562C21.2276 20.436 20.8375 20.8261 20.3577 21.0706C20.0418 21.2315 19.7099 21.2932 19.3633 21.3215C19.0324 21.3485 18.6285 21.3485 18.15 21.3485H5.85023C5.37168 21.3485 4.96776 21.3485 4.63686 21.3215C4.29033 21.2932 3.95838 21.2315 3.64251 21.0706C3.16269 20.8261 2.7726 20.436 2.52812 19.9562C2.36718 19.6403 2.30552 19.3084 2.27721 18.9618C2.25017 18.6309 2.25018 18.227 2.25019 17.7485V17.1486C2.25018 16.6701 2.25017 16.2662 2.27721 15.9353C2.30552 15.5887 2.36718 15.2568 2.52812 14.9409C2.7726 14.4611 3.1627 14.071 3.64251 13.8265C3.95837 13.6656 4.29033 13.6039 4.63686 13.5756C4.96775 13.5486 5.3717 13.5486 5.85023 13.5486H11.2501V8.89865C11.2501 8.48443 11.5859 8.14865 12.0001 8.14865Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2.25C18.4142 2.25 18.75 2.58579 18.75 3V5.25H21C21.4142 5.25 21.75 5.58579 21.75 6C21.75 6.41422 21.4142 6.75 21 6.75H18.75V9C18.75 9.41422 18.4142 9.75 18 9.75C17.5858 9.75 17.25 9.41422 17.25 9V6.75H15C14.5858 6.75 14.25 6.41422 14.25 6C14.25 5.58579 14.5858 5.25 15 5.25H17.25V3C17.25 2.58579 17.5858 2.25 18 2.25Z"
        fill={fill}
      />
    </svg>
  ),
);

DevicePlusIcon.displayName = 'DevicePlusIcon';

export default DevicePlusIcon;
