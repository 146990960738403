import {
  LockClosedIcon,
  BellIcon,
  FolderPlusIcon,
} from '@heroicons/react/20/solid';
import { ClockIcon } from '@heroicons/react/20/solid';
import {
  ActionIcon,
  Box,
  Flex,
  Stack,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import Select from 'components/Select/Select';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useEffect, useRef, useState } from 'react';

import { CreateLayoutLevelType } from '../types';

import styles from './Modals.module.scss';

const AddingLayoutLevelModal = ({
  formState,
  setFormState,
  isFormValid,
  setIsFormValid,
}: ModalFormProps) => {
  const [isPermissionSwitchedOn, setIsPermissionSwitchedOn] = useState(false);
  const [isNotificationSwitchedOn, setIsNotificationSwitchedOn] = useState(
    true,
  );
  const [isReportSwitchedOn, setIsReportSwitchedOn] = useState(true);

  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);

  const handlePermissionSwitchChange = (checked: boolean) => {
    setIsPermissionSwitchedOn(checked);
  };

  const handleNotificationSwitchChange = (checked: boolean) => {
    setIsNotificationSwitchedOn(checked);
  };

  const handleReportSwitchChange = (checked: boolean) => {
    setIsReportSwitchedOn(checked);
  };

  const form = useForm<CreateLayoutLevelType>({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      name: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      status: '',
      permission: false,
      notification: true,
      report: true,
      layoutSelect: '',
    },
    validate: {
      name: (value) => (!value ? 'Name is required' : null),
      startDate: (value) => (!value ? 'Start date is required' : null),
      startTime: (value) => (!value ? 'Start time is required' : null),
      endDate: (value) => (!value ? 'End date is required' : null),
      endTime: (value) => (!value ? 'End time is required' : null),
      layoutSelect: (value) => (!value ? 'Layout is required' : null),
    },
  });
  useEffect(() => {
    if (form.isValid()) {
      setIsFormValid(true);
      const newFormState = form.values;

      if (setFormState) {
        setFormState((prevState) => ({
          ...prevState,
          ...newFormState,
        }));
      }
    } else {
      setIsFormValid(false);
    }
  }, [form.values, form.isValid()]);

  return (
    <div>
      <Stack gap="1.5rem">
        <TextInput
          label="Name"
          placeholder="Entity level name"
          withAsterisk
          classNames={{ label: styles.labelStyle }}
          key={form.key('name')}
          {...form.getInputProps('name')}
        />
        <Select
          label="Layout"
          placeholder="Select layout"
          withAsterisk
          data={['Layout 1', 'Layout 2', 'Layout 3']}
          key={form.key('layoutSelect')}
          {...form.getInputProps('layoutSelect')}
          className={styles.dateAndTimePickerWrapper}
        />
        <Flex gap="1.5rem">
          {' '}
          <Box flex={1} className={styles.dateAndTimePickerWrapper}>
            <DatePickerInput
              label="Start date"
              showAsterisk={true}
              value={form.values.startDate}
              onChange={(date) => form.setFieldValue('startDate', date)}
            />
          </Box>
          <Box flex={1} className={styles.dateAndTimePickerWrapper}>
            <DatePickerInput
              label="End date"
              showAsterisk={true}
              value={form.values.endDate}
              onChange={(date) => form.setFieldValue('endDate', date)}
            />
          </Box>
        </Flex>
        <Flex gap="1.5rem">
          <Box flex={1} className={styles.dateAndTimePickerWrapper}>
            <TimeInput
              label="Start time"
              withAsterisk
              style={{ flex: 1 }}
              ref={startTimeRef}
              key={form.key('startTime')}
              {...form.getInputProps('startTime')}
              rightSection={
                <ActionIcon
                  variant="subtle"
                  color="gray"
                  onClick={() => startTimeRef.current?.showPicker()}
                >
                  <ClockIcon
                    height="1.25rem"
                    width="1.25rem"
                    style={{
                      stroke: '#0F172A',
                      strokeWidth: 1.2,
                      fill: '#FFFFFF',
                    }}
                  />
                </ActionIcon>
              }
            />
          </Box>
          <Box flex={1} className={styles.dateAndTimePickerWrapper}>
            <TimeInput
              label="End time"
              withAsterisk
              style={{ flex: 1 }}
              ref={endTimeRef}
              key={form.key('endTime')}
              {...form.getInputProps('endTime')}
              rightSection={
                <ActionIcon
                  variant="subtle"
                  color="gray"
                  onClick={() => endTimeRef.current?.showPicker()}
                >
                  <ClockIcon
                    height="1.25rem"
                    width="1.25rem"
                    style={{
                      stroke: '#0F172A',
                      strokeWidth: 1.2,
                      fill: '#FFFFFF',
                    }}
                  />
                </ActionIcon>
              }
            />
          </Box>
        </Flex>
        <Select
          label="Status"
          data={['Active', 'Inactive']}
          className={styles.dateAndTimePickerWrapper}
          key={form.key('status')}
          {...form.getInputProps('status')}
        />
        <Flex justify="space-between" align="center">
          <Stack gap={0}>
            <Text fw={500} fz="0.875rem" c="#64748B">
              Permission
            </Text>
            <Text fw={400} fz="0.875rem" c="#94A3B8">
              Vestibulum pellentesque feugiat erat
            </Text>
          </Stack>
          <Switch
            key={form.key('permission')}
            {...form.getInputProps('permission')}
            size="md"
            c="#0F172A"
            styles={{
              root: {
                width: '5rem',
              },
              track: {
                backgroundColor: isPermissionSwitchedOn ? '#059669' : '#e11d48',
                cursor: 'pointer',
              },
            }}
            checked={isPermissionSwitchedOn}
            label={isPermissionSwitchedOn ? 'On' : 'Out'}
            onChange={(e) =>
              handlePermissionSwitchChange(e.currentTarget.checked)
            }
            thumbIcon={
              isPermissionSwitchedOn ? (
                <BellIcon
                  height="0.8rem"
                  width="0.8rem"
                  strokeWidth={1.5}
                  stroke="#059669"
                  fill="none"
                />
              ) : (
                <LockClosedIcon
                  height="0.8rem"
                  width="0.8rem"
                  strokeWidth={1.5}
                  stroke="#E11D48"
                  fill="none"
                />
              )
            }
          />
        </Flex>

        <Flex justify="space-between" align="center">
          <Stack gap={0}>
            <Text fw={500} fz="0.875rem" c="#64748B">
              Notification
            </Text>
            <Text fw={400} fz="0.875rem" c="#94A3B8">
              Vestibulum pellentesque feugiat erat
            </Text>
          </Stack>
          <Switch
            key={form.key('notification')}
            {...form.getInputProps('notification')}
            size="md"
            c="#0F172A"
            styles={{
              root: {
                width: '5rem',
              },
              track: {
                backgroundColor: isNotificationSwitchedOn
                  ? '#059669'
                  : '#e11d48',
                cursor: 'pointer',
              },
            }}
            checked={isNotificationSwitchedOn}
            label={isNotificationSwitchedOn ? 'On' : 'Out'}
            onChange={(e) =>
              handleNotificationSwitchChange(e.currentTarget.checked)
            }
            thumbIcon={
              isNotificationSwitchedOn ? (
                <BellIcon
                  height="0.8rem"
                  width="0.8rem"
                  strokeWidth={1.5}
                  stroke="#059669"
                  fill="none"
                />
              ) : (
                <LockClosedIcon
                  height="0.8rem"
                  width="0.8rem"
                  strokeWidth={1.5}
                  stroke="#E11D48"
                  fill="none"
                />
              )
            }
          />
        </Flex>
        <Flex justify="space-between" align="center">
          <Stack gap={0}>
            <Text fw={500} fz="0.875rem" c="#64748B">
              Report
            </Text>
            <Text fw={400} fz="0.875rem" c="#94A3B8">
              Vestibulum pellentesque feugiat erat
            </Text>
          </Stack>
          <Switch
            key={form.key('report')}
            {...form.getInputProps('report')}
            size="md"
            c="#0F172A"
            styles={{
              root: {
                width: '5rem',
              },
              track: {
                backgroundColor: isReportSwitchedOn ? '#059669' : '#e11d48',
                cursor: 'pointer',
              },
            }}
            checked={isReportSwitchedOn}
            label={isReportSwitchedOn ? 'On' : 'Out'}
            onChange={(e) => handleReportSwitchChange(e.currentTarget.checked)}
            thumbIcon={
              isReportSwitchedOn ? (
                <FolderPlusIcon
                  height="0.8rem"
                  width="0.8rem"
                  strokeWidth={1.5}
                  stroke="#059669"
                  fill="none"
                />
              ) : (
                <LockClosedIcon
                  height="0.8rem"
                  width="0.8rem"
                  strokeWidth={1.5}
                  stroke="#E11D48"
                  fill="none"
                />
              )
            }
          />
        </Flex>
      </Stack>
    </div>
  );
};
export default AddingLayoutLevelModal;
