import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventAssignableTagsService } from 'services/event';

import { eventTagBaseKey, eventTagGetAssignableKey } from './baseKeys';

interface AddEventAssignableTagsProps {
  payload: string[];
  filter: string;
  eventId?: string;
}

export const useAddEventAssignableTags = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ eventId, payload }: AddEventAssignableTagsProps) => {
      if (!eventId) return;
      await EventAssignableTagsService.addAssignableTags(eventId, payload);
    },
    onSuccess: (_, { eventId, filter }) => {
      queryClient.invalidateQueries([eventTagBaseKey]);
      queryClient.invalidateQueries([eventTagGetAssignableKey]);
    },
  });
};
