import { notification } from '@lanaco/lnc-react-ui';
import { RowSelectionState, createColumnHelper } from '@tanstack/table-core';
import { TLNeutralButton, TLSecondaryLoadingButton } from 'components/Button';
import { InputSearch } from 'components/Input';
import { TLModalFooter } from 'components/Modal';
import { NotAvailable } from 'components/NotAvailable';
import TanStackTable from 'components/TanStackTable/TanStackTable';
import { useFetchEventTags } from 'hooks/event/tags';
import { useAddParticipantOnTag } from 'hooks/event/tags/useAddParticipantOnTag';
import { useState } from 'react';
import { getErrorMsg } from 'utils/getErrorMsg';

import { EventTagTableData } from '../Tags/EventTag.interface';

import style from './EventParticipants.module.css';

type Props = {
  eventId: string;
  oldTagIds: string[];
  participantName?: string;
  participantId: string;
  replaceTag: boolean;
  closeModal: () => void;
};

const columnHelper = createColumnHelper<EventTagTableData>();
const ROW_LIMIT = 5;

const AddRemoveEventTagOnParticipantForm = ({
  eventId,
  oldTagIds,
  participantId,
  participantName,
  replaceTag,
  closeModal,
}: Props) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const { data, isFetching } = useFetchEventTags({
    eventId: eventId as string,
    row_limit: ROW_LIMIT,
    offset: (page - 1) * ROW_LIMIT,
    filter: search,
    participantId: participantId,
  });

  const { _route, data: eventTags } = data || {
    _route: { returned: 0, total: 0 },
    data: [],
  };

  let mapSelected: { [key: string]: boolean } = {};
  for (let i = 0; i < oldTagIds.length; i++) {
    mapSelected[oldTagIds[i]] = true;
  }

  console.log(eventTags);
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>(
    replaceTag && oldTagIds != null && oldTagIds.length > 0 ? mapSelected : {},
  );

  const noParticipantsAvailable = !search && eventTags?.length === 0;

  console.log('event Tags', eventTags);

  const selectedId = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {
        let tagId = eventTags?.filter((s) => s._id === key)[0]?.tagId;
        acc.push(tagId);
      }
      return acc;
    },
    [],
  );

  console.log(selectedId);
  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  const mutation = useAddParticipantOnTag();

  const onAddHandler = async (e) => {
    e.preventDefault();
    mutation.mutate(
      {
        eventId: eventId,
        tagIds: selectedId.splice(oldTagIds.length, selectedId.length),
        participantId,
        oldTagIds: oldTagIds,
      },
      {
        onSuccess: () => {
          closeModal();
          // const tagName = eventTags?.filter(
          //   (s) => s.tag._id === selectedId[0],
          // )[0]?.tag.name;
          notification.success(`Tags assigned to ${participantName}.`);
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
      },
    );
  };

  const participantTableColumns = [
    columnHelper.accessor('uniqueId', {
      header: 'Tag ID',
    }),
    columnHelper.accessor('name', {
      header: 'Tag',
    }),
    columnHelper.accessor('number', {
      header: 'Number',
    }),
    columnHelper.accessor('participant.firstLastName', {
      header: 'Assigned to',
    }),
  ];

  return (
    <>
      <InputSearch
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <form onSubmit={onAddHandler}>
        {noParticipantsAvailable ? (
          <NotAvailable message="Oops, no participants available at the moment." />
        ) : (
          <TanStackTable
            getRowId={(row) => row._id}
            selectedRows={selectedRows}
            onRowSelectionChange={setSelectedRows}
            isLoading={isFetching}
            columns={participantTableColumns}
            pageIndex={page}
            pageSize={ROW_LIMIT}
            totalRows={_route.total}
            pageCount={Math.ceil(_route.total / ROW_LIMIT)}
            data={eventTags} //{replaceTag ? sortedEventTags : eventTags}
            onPageChange={handlePageChange}
            enableRowSelection={(row) =>
              oldTagIds.includes(row.id) ? false : true
            }
            rowClassName={(row) =>
              replaceTag && oldTagIds.includes(row._id) ? 'text-gray-400' : ''
            }
          />
        )}
        <TLModalFooter
          className={style.modalFooter}
          cancelButton={
            <TLNeutralButton onClick={closeModal}>Cancel</TLNeutralButton>
          }
          actionButton={
            <TLSecondaryLoadingButton
              isLoading={isFetching}
              disabled={selectedId?.length === 0}
              type="submit"
            >
              Add
            </TLSecondaryLoadingButton>
          }
        />
      </form>
    </>
  );
};

export default AddRemoveEventTagOnParticipantForm;
