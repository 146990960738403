import { useQuery } from '@tanstack/react-query';
import { client } from 'services/api';

import { GetAllClients } from './types';

const getClients = async (): Promise<GetAllClients[]> => {
  const url = 'clients/';

  const response = await client.get(url);

  return response.data.data;
};

export const useClients = () => {
  return useQuery<GetAllClients[], Error>({
    queryKey: ['getClients'],
    queryFn: () => getClients(),
  });
};
