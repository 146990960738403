import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventAdmissionService } from 'services/event/admission/event-admission';
import { EventTagAssignParticipantService } from 'services/event/tag/event-tag-assign-participant';

import { eventParticipantBaseKey } from '../participants/eventParticipantBaseKey';

interface Props {
  eventId: string;
  participantId?: string;
  participantExternalId: string;
  tagId?: string;
  tagNumber: string;
}

export const useMutateAddAdmission = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      eventId,
      participantId,
      participantExternalId,
      tagId,
      tagNumber,
    }: Props) => {
      if (!eventId) return;
      const payload = {
        eventId,
      };

      if (participantId) {
        payload['participantId'] = participantId;
      }
      if (tagId) {
        payload['tagId'] = tagId;
      }
      if (tagNumber) {
        payload['tagNumber'] = tagNumber;
      }
      if (participantExternalId) {
        payload['participantExternalId'] = participantExternalId;
      }

      const resp = await EventAdmissionService.createAdmission(
        payload as Parameters<typeof EventAdmissionService.createAdmission>[0],
      );
      return resp?.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([eventParticipantBaseKey]);
    },
  });

  return mutation;
};
