import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/24/solid';
import { ActionIcon, Flex, Loader, Text, TextInput } from '@mantine/core';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import SortingDropdown from 'components/SortingDropdown/SortingDropdown';
import { admissionSortingParams } from 'pages/Entities/consts';
import { EntityDetailsParams } from 'pages/Entities/Details/types';
import AdditionalFilters from 'pages/Entities/Hub/LeftHubContainer/AdditionalFilters/AdditionalFilters';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { states, statuses } from 'utils/consts';

import { ListOfAdmissionProps } from '../types';

import HubCardContainer from './../../Hub/LeftHubContainer/HubCardContainer/HubCardContainer';
import ListOfAdmissionItem from './ListOfAdmissionItem';

const ListOfAdmission = ({
  participantByEventIsLoading,
  debouncedSetAdmissionSearch,
  sortingParam,
  setSortingParam,
  setIsAtBottom,
  participantByEventData,
  isMobile,
}: ListOfAdmissionProps) => {
  const [showAdditionalFilters, setShowAdditionalFilters] = useState<boolean>(
    false,
  );
  const viewportRef = useRef<HTMLDivElement>(null);
  const { id: eventId = '' } = useParams<EntityDetailsParams>();

  useEffect(() => {
    const handleScroll = () => {
      if (viewportRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = viewportRef.current;
        const threshold = 100;
        const atBottom = scrollHeight - scrollTop - clientHeight <= threshold;

        if (setIsAtBottom) {
          setIsAtBottom(atBottom);
        }
      }
    };

    const viewport = viewportRef.current;
    if (viewport) {
      viewport.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (viewport) {
        viewport.removeEventListener('scroll', handleScroll);
      }
    };
  }, [setIsAtBottom]);

  return (
    <Flex
      h="55rem"
      p="1.5rem 0 1.5rem 1.5rem"
      bg="#ffffff"
      style={{ borderRadius: '0.75rem', border: '1px solid #e2e8f0' }}
      direction="column"
    >
      {participantByEventIsLoading ? (
        <Flex justify="center" align="center" h="100%">
          <Loader size="lg" />
        </Flex>
      ) : (
        <>
          <Flex mr="1.5rem" mb="1.5rem" justify="space-between" align="center">
            <Flex align="center">
              <ViewfinderCircleIcon
                height="1.5rem"
                width="1.5rem"
                color="#94a3b8"
              />
              <Text fz="1.125rem" fw={600} c="var(--gray-900)" ml="0.75rem">
                List of admissions
              </Text>
            </Flex>
          </Flex>

          <Flex mr="1.5rem" mb="1.5rem" justify="center">
            <TextInput
              size="sm"
              mr="0.75rem"
              fz="1rem"
              radius="md"
              flex={1}
              placeholder="Search"
              onChange={(e) => debouncedSetAdmissionSearch(e.target.value)}
              leftSection={
                <MagnifyingGlassIcon
                  height="1.25rem"
                  width="1.25rem"
                  color="#94A3B8"
                />
              }
            />
            <Flex align="center" gap="0.75rem">
              <SortingDropdown
                sortingParams={admissionSortingParams}
                sortingParam={sortingParam}
                setSortingParam={setSortingParam}
              />
              <ActionIcon
                bg="#ffffff"
                c={showAdditionalFilters ? '#3B82F6' : '#0F172A'}
                onClick={() => setShowAdditionalFilters((prev) => !prev)}
              >
                <AdjustmentsHorizontalIcon height="1.5rem" width="1.5rem" />
              </ActionIcon>
            </Flex>
          </Flex>

          {showAdditionalFilters && (
            <AdditionalFilters
              statuses={statuses.map(({ value, label }) => ({
                id: value,
                value: label,
              }))}
              states={states.map(({ value, label }) => ({
                id: value,
                value: label,
              }))}
              showTypeFilter={true}
              showClassificationFilter={true}
            />
          )}

          {isMobile ? (
            participantByEventData &&
            participantByEventData.length > 0 && (
              <ScrollArea
                h="44.5rem"
                mr="0.6rem"
                pr="0.5rem"
                viewportRef={viewportRef}
                type={isMobile ? 'never' : 'auto'}
              >
                <Flex direction="column" gap="0.75rem">
                  {participantByEventData.map((x, index) => (
                    <ListOfAdmissionItem
                      key={x.id}
                      id={x.id}
                      image={x.image}
                      trackingObjectType={x.trackingObjectType}
                      trackingObjectName={x.trackingObjectName}
                      trackingObjectCode={x.trackingObjectCode}
                      tags={x.trackingObjectTags}
                      trackingObjectTypeName={x.trackingObjectTypeName}
                      status={x.status}
                    />
                  ))}
                </Flex>
              </ScrollArea>
            )
          ) : (
            <HubCardContainer
              data={participantByEventData}
              eventId={eventId}
              setIsAtBottom={setIsAtBottom}
            />
          )}
        </>
      )}
    </Flex>
  );
};

export default ListOfAdmission;
