import { notification } from '@lanaco/lnc-react-ui';
import PromptModal from 'components/PromptModal/PromptModal';
import { useDeleteTag } from 'hooks/tag';
import { getErrorMsg } from 'utils/getErrorMsg';

import { TagTableData } from '../interfaces';

interface DeleteTagModalProps {
  id: string;
  name?: string;
  closeModal: () => void;
  onTagEdit: (device: TagTableData | null) => void;
}

export const DeleteTagModal = ({
  id,
  name,
  closeModal,
  onTagEdit,
}: DeleteTagModalProps) => {
  const { mutate, isLoading } = useDeleteTag();

  const hideDeleteModal = () => {
    closeModal();
    onTagEdit(null);
  };

  const confirmDeleteTag = async () => {
    mutate(
      {
        id,
      },
      {
        onSuccess: (data) => {
          if (data.status === 201) {
            notification.success('Tag successfully deleted.');
          } else {
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          hideDeleteModal();
        },
      },
    );
  };

  return (
    <PromptModal
      title="Delete tag?"
      isLoading={isLoading}
      warningMessage={
        <>
          Are you sure you want to delete tag {name}?
          <br />
          This action cannot be undone.
        </>
      }
      confirmButtonName="Delete"
      icon="exclamation-triangle"
      iconSize="24"
      iconColor="danger"
      isDeleteButton
      onConfirm={confirmDeleteTag}
      onCancel={hideDeleteModal}
    />
  );
};
