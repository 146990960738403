import { Grid, Tabs } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { SortingParamType } from 'components/SortingDropdown/types';
import { useFilters } from 'context/FilterProvider';
import { identity, pickBy } from 'lodash';
import debounce from 'lodash.debounce';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';
import {
  useAddParticipantByEvent,
  useAdmissionScan,
} from 'services/entity/admission';
import { useTrackingObjectsByEvent } from 'services/entity/trackingObject';

import { EntityDetailsParams } from '../Details/types';
import { HubCardType } from '../Hub/types';
import { openModal } from '../ModalManager/ModalsManager';

import AdmissionSection from './AdmissionSection/AdmissionSection';
import ListOfAdmission from './ListOfAdmission/ListOfAdmission';
import { mapAdmissionToHubCard } from './mapper';
import MultipleDetectionModal from './MultipleDetectionModal/MultipleDetectionModal';
import {
  AddAdmissionByEvent,
  AdmissionCardType,
  ParticipantData,
  TagData,
} from './types';

const EntityAdmission = () => {
  const [sortingParam, setSortingParam] = useState<SortingParamType | null>({
    field: 'admissionDateTime',
    order: -1,
    value: 'Latest',
  });
  const [qrCode, setQrCode] = useState<string>('');
  const [isUnknownCode, setIsUnknownCode] = useState<boolean>(false);
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [disableCameraButton, setDisableCameraButton] = useState<boolean>(
    false,
  );
  const { id: eventId = '' } = useParams<EntityDetailsParams>();
  const [limit, setLimit] = useState<number>(20);
  const [admissionSearchTerm, setAdmissionSearchTerm] = useState<string>('');
  const { data: admissionScanData } = useAdmissionScan(eventId, qrCode);
  const { filter } = useFilters();
  const inputScanRef = useRef<HTMLInputElement>(null);

  const {
    data: { eventParticipants } = {},
    isLoading: participantByEventIsLoading,
    error: participantByEventError,
    isError: participantByEventIsError,
  } = useTrackingObjectsByEvent(
    eventId,
    limit,
    admissionSearchTerm,
    filter,
    sortingParam ?? undefined,
  );

  const trackingObjectCards: HubCardType[] = useMemo(
    () =>
      eventParticipants ? eventParticipants.map(mapAdmissionToHubCard) : [],
    [eventParticipants],
  );

  const { mutate, isError, error, isSuccess } = useAddParticipantByEvent();
  const [scanned, setScanned] = useState<AdmissionCardType[] | []>([]);
  const [autoSubmit, setAutoSubmit] = useState<boolean>(false);
  const [allowMultitagging, setAllowMultitagging] = useState<boolean>(false);
  const [cameraDelay, setCameraDelay] = useState<number>(10000);
  const [isAtBottom, setIsAtBottom] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('admission');
  const [tempQrCode, setTempQrCode] = useState<string>('');
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const queryClient = useQueryClient();

  const focusInputScan = () => {
    inputScanRef.current?.focus();
  };

  const blurInputScan = () => {
    inputScanRef.current?.blur();
  };

  const handleScan = (data: string | null) => {
    if (data) {
      setIsScanning(false);
      setDisableCameraButton(true);
      const normalizedData = data.toLowerCase();
      const scannedData = normalizedData.includes('http')
        ? normalizedData.split('/').pop() || ''
        : normalizedData;

      setQrCode(scannedData);
      setTimeout(() => {
        setIsScanning(true);
        setDisableCameraButton(false);
      }, cameraDelay);
    }
  };

  const handleClearQrCode = () => {
    setQrCode('');
    setTempQrCode('');
    setIsUnknownCode(false);
    queryClient.setQueryData(['admissionScan', eventId, qrCode], null);
    if (!isScanning && !disableCameraButton) focusInputScan();
  };

  const debouncedSetAdmissionSearch = useCallback(
    debounce((value: string) => {
      if (value?.length >= 3) setAdmissionSearchTerm(value);
      else if (value?.length === 0) setAdmissionSearchTerm('');
    }, 500),
    [],
  );

  const toggleScanner = () => {
    setIsScanning(!isScanning);
  };

  const handleAddUnknownCode = (isTagCard: boolean) => {
    if (qrCode) {
      if (isTagCard) {
        if (
          scanned.filter((x) => x.isTagCard === true).length === 0 ||
          allowMultitagging
        ) {
          const admissionCardObj: AdmissionCardType = {
            _id: qrCode,
            title: 'Unknown name',
            isUnknown: true,
            isTagCard: true,
            subtitle: qrCode ?? '',
            leftContent: '',
            rightContent: 0,
          };
          setScanned([...scanned, admissionCardObj]);
        }
      } else {
        if (!scanned?.some((x) => x.isTagCard === false)) {
          const admissionCardObj: AdmissionCardType = {
            _id: qrCode,
            title: 'Unknown name',
            isUnknown: true,
            isTagCard: false,
            subtitle: qrCode ?? '',
            leftContent: '',
            rightContent: 0,
          };
          setScanned([admissionCardObj, ...scanned]);
        }
      }
    }
    handleClearQrCode();
  };

  const addTagToScanned = (scannedTag: TagData) => {
    if (
      scanned.filter((x) => x.isTagCard === true).length === 0 ||
      allowMultitagging
    ) {
      if (!scanned?.some((x) => x._id === scannedTag.tagId)) {
        const admissionCardObj: AdmissionCardType = {
          _id: scannedTag.tagId,
          isUnknown: false,
          title: scannedTag?.tag?.name ?? scannedTag?.number,
          isTagCard: true,
          subtitle: scannedTag?.number ?? scannedTag?.tag?.number,
          leftContent: scannedTag?.uniqueId,
          rightContent: scannedTag?.participants?.length ?? 0,
        };
        setScanned([...scanned, admissionCardObj]);
        handleClearQrCode();
      }
    } else {
      toast.error('Only one tag can be registered at a time');
    }
  };

  const addParticipantToScanned = (scannedParticipant: ParticipantData) => {
    if (
      !scanned?.some((x) => x._id === scannedParticipant._id) &&
      !scanned?.some((x) => x.isTagCard === false)
    ) {
      const admissionCardObj: AdmissionCardType = {
        _id: scannedParticipant?._id,
        isUnknown: false,
        title: scannedParticipant?.firstLastName ?? 'Unknown',
        isTagCard: false,
        subtitle: scannedParticipant?.externalId ?? '',
        leftContent:
          scannedParticipant?.trackedSubjectType?.subType ?? 'Participant',
        rightContent: scannedParticipant?.eventTags?.length ?? 0,
      };
      setScanned([admissionCardObj, ...scanned]);
    } else {
      toast.error('Only one participant can be registered at a time');
    }
    handleClearQrCode();
  };

  const handleDeleteAdmissionItem = (isTagCard: boolean, id: string) => {
    const indexToDelete = scanned?.findIndex(
      (x) => x._id === id && x.isTagCard === isTagCard,
    );

    if (indexToDelete !== -1) {
      scanned.splice(indexToDelete, 1);
      setScanned([...scanned]);
    }
    handleClearQrCode();
  };

  const handleAssign = () => {
    scanned
      .filter((x) => x.isTagCard === true)
      ?.forEach((y) => {
        const dataToAdd: AddAdmissionByEvent = {
          participantId:
            scanned.find((x) => x.isTagCard === false && x.isUnknown === false)
              ?._id ?? null,
          participantExternalId:
            scanned.find((x) => x.isTagCard === false)?.subtitle ?? '',
          tagId: y.isUnknown ? null : y?._id,
          tagNumber: y?.subtitle ?? '',
        };
        if (eventId) {
          mutate({
            eventId: eventId,
            data: pickBy(dataToAdd, identity) as AddAdmissionByEvent,
          });
        }
      });
  };

  useUpdateEffect(() => {
    if (admissionScanData) {
      if (
        admissionScanData?.eventParticipants.length > 1 ||
        admissionScanData?.eventTags.length > 1 ||
        (admissionScanData?.eventParticipants.length === 1 &&
          admissionScanData?.eventTags.length === 1)
      ) {
        setIsUnknownCode(false);
        openModal({
          size: 800,
          title: 'Multiple detection',
          hasFooter: false,
          content: (
            <MultipleDetectionModal
              trackingObjects={admissionScanData?.eventParticipants}
              tags={admissionScanData?.eventTags}
              addTagToScanned={addTagToScanned}
              addParticipantToScanned={addParticipantToScanned}
            />
          ),
        });
        handleClearQrCode();
        return;
      }

      if (admissionScanData?.eventTags?.length > 0) {
        // ovdje u niz ubacujemo tagove
        setIsUnknownCode(false);
        if (
          scanned.filter((x) => x.isTagCard === true).length === 0 ||
          allowMultitagging
        ) {
          addTagToScanned(admissionScanData?.eventTags[0]);
        }
      } else if (admissionScanData?.eventParticipants?.length > 0) {
        // ovdje u niz ubacujemo tracking objekte
        setIsUnknownCode(false);
        addParticipantToScanned(admissionScanData?.eventParticipants[0]);
      } else if (
        admissionScanData?.eventParticipants?.length === 0 &&
        admissionScanData?.eventTags?.length === 0
      ) {
        // dodajemo unknown
        setIsUnknownCode(true);
        blurInputScan();
      }
    }
  }, [admissionScanData]);

  useUpdateEffect(() => {
    if (autoSubmit && scanned?.length === 2) {
      if (
        scanned.some((x) => x.isTagCard === true) &&
        scanned.some((x) => x.isTagCard === false)
      ) {
        // radimo autosubmit
        const dataToAdd: AddAdmissionByEvent = {
          participantId: scanned.find((x) => x.isTagCard === false)?._id ?? '',
          participantExternalId:
            scanned.find((x) => x.isTagCard === false)?.subtitle ?? '',
          tagId: scanned.find((x) => x.isTagCard === true)?._id ?? '',
          tagNumber: scanned.find((x) => x.isTagCard === true)?.subtitle ?? '',
        };
        if (eventId) {
          mutate({
            eventId: eventId,
            data: pickBy(dataToAdd, identity) as AddAdmissionByEvent,
          });
        }
      }
    }
  }, [autoSubmit, scanned]);

  useUpdateEffect(() => {
    if (isError || participantByEventIsError) {
      toast.error(isError ? error.message : participantByEventError?.message);
    }
  }, [isError, participantByEventIsError]);

  useUpdateEffect(() => {
    if (isSuccess) {
      handleClearQrCode();
      setScanned([]);
    }
  }, [isSuccess]);

  useUpdateEffect(() => {
    if (isAtBottom) {
      setLimit((prev) => prev + 20);
    }
  }, [isAtBottom]);

  useEffect(() => {
    focusInputScan();
  }, []);

  if (isMobile) {
    return (
      <Tabs
        defaultValue={activeTab}
        onChange={(value) => setActiveTab(value || 'admission')}
      >
        <Tabs.List>
          <Tabs.Tab value="admission">Admission</Tabs.Tab>
          <Tabs.Tab value="list">List of Admissions</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="admission">
          {activeTab === 'admission' && (
            <AdmissionSection
              ref={inputScanRef}
              isMobile={isMobile}
              disableCameraButton={disableCameraButton}
              allowMultitagging={allowMultitagging}
              autoSubmit={autoSubmit}
              cameraDelay={cameraDelay}
              handleAddUnknownCode={handleAddUnknownCode}
              handleAssign={handleAssign}
              handleClearQrCode={handleClearQrCode}
              handleDeleteAdmissionItem={handleDeleteAdmissionItem}
              handleScan={handleScan}
              isScanning={isScanning}
              isUnknownCode={isUnknownCode}
              tempQrCode={tempQrCode}
              qrCode={qrCode}
              scanned={scanned}
              setAllowMultitagging={setAllowMultitagging}
              setAutoSubmit={setAutoSubmit}
              setCameraDelay={setCameraDelay}
              setIsUnknownCode={setIsUnknownCode}
              setTempQrCode={setTempQrCode}
              setQrCode={setQrCode}
              setScanned={setScanned}
              toggleScanner={toggleScanner}
            />
          )}
        </Tabs.Panel>

        <Tabs.Panel value="list">
          {activeTab === 'list' && (
            <ListOfAdmission
              isMobile={isMobile}
              debouncedSetAdmissionSearch={debouncedSetAdmissionSearch}
              participantByEventData={trackingObjectCards}
              participantByEventIsLoading={participantByEventIsLoading}
              setIsAtBottom={setIsAtBottom}
              setSortingParam={setSortingParam}
              sortingParam={sortingParam}
            />
          )}
        </Tabs.Panel>
      </Tabs>
    );
  }

  return (
    <Grid>
      <Grid.Col span={4}>
        <AdmissionSection
          ref={inputScanRef}
          isMobile={isMobile}
          disableCameraButton={disableCameraButton}
          allowMultitagging={allowMultitagging}
          autoSubmit={autoSubmit}
          cameraDelay={cameraDelay}
          handleAddUnknownCode={handleAddUnknownCode}
          handleAssign={handleAssign}
          handleClearQrCode={handleClearQrCode}
          handleDeleteAdmissionItem={handleDeleteAdmissionItem}
          handleScan={handleScan}
          isScanning={isScanning}
          isUnknownCode={isUnknownCode}
          tempQrCode={tempQrCode}
          qrCode={qrCode}
          scanned={scanned}
          setAllowMultitagging={setAllowMultitagging}
          setAutoSubmit={setAutoSubmit}
          setCameraDelay={setCameraDelay}
          setIsUnknownCode={setIsUnknownCode}
          setTempQrCode={setTempQrCode}
          setQrCode={setQrCode}
          setScanned={setScanned}
          toggleScanner={toggleScanner}
        />
      </Grid.Col>
      <Grid.Col span={8}>
        <ListOfAdmission
          isMobile={isMobile}
          debouncedSetAdmissionSearch={debouncedSetAdmissionSearch}
          participantByEventData={trackingObjectCards}
          participantByEventIsLoading={participantByEventIsLoading}
          setIsAtBottom={setIsAtBottom}
          setSortingParam={setSortingParam}
          sortingParam={sortingParam}
        />
      </Grid.Col>
    </Grid>
  );
};

export default React.memo(EntityAdmission);
