import { notification } from '@lanaco/lnc-react-ui';
import PromptModal from 'components/PromptModal/PromptModal';
import { useDeleteDevice } from 'hooks/event/devices/useDeleteDevice';
import { getErrorMsg } from 'utils/getErrorMsg';

import { EventDevice } from '../EventDevices.interface';

//import { DeviceTableData } from '../interfaces';

interface DeleteDeviceModalProps {
  eventId: string;
  deviceId: string;
  status?: string;
  macName?: string;
  closeModal: () => void;
  onDeviceEdit: (device: EventDevice | null) => void;
}

export const DeleteDeviceModal = ({
  eventId,
  deviceId,
  macName,
  closeModal,
  onDeviceEdit,
}: DeleteDeviceModalProps) => {
  const { mutate, isLoading } = useDeleteDevice();

  const closeDeleteModal = () => {
    onDeviceEdit(null);
    closeModal();
  };

  const confirmDeleteDevice = async () => {
    mutate(
      {
        eventId,
        deviceId,
      },
      {
        onSuccess: (data) => {
          if (data?.status === 201) {
            notification.success('Device successfully deleted.');
          } else {
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          closeDeleteModal();
        },
      },
    );
  };

  return (
    <PromptModal
      title="Delete device?"
      isLoading={isLoading}
      warningMessage={
        <>
          Are you sure you want to delete device {macName}?
          <br />
          This action cannot be undone.
        </>
      }
      confirmButtonName="Delete"
      icon="exclamation-triangle"
      iconSize="24"
      iconColor="danger"
      isDeleteButton
      onConfirm={confirmDeleteDevice}
      onCancel={closeModal}
    />
  );
};
