import { Pagination } from '@lanaco/lnc-react-ui';

import style from './TableFooter.module.css';

type Props = {
  totalRows: number;
  offset: number;
  limit: number;
  currentPage?: number;
  setCurrentPage?: any;
  setOffset?: any;
};

export const TableFooter = ({
  totalRows,
  offset,
  limit,
  currentPage,
  setCurrentPage,
  setOffset,
}: Props) => {
  let firstItemIndex = offset ? offset + 1 : 1;
  let lastItemIndex = limit ? offset + limit : 1;
  if (limit + offset > totalRows) {
    lastItemIndex = totalRows;
  }

  return (
    <div className={style.tableFooter}>
      <p>
        Showing <span>{firstItemIndex}</span> to <span>{lastItemIndex}</span> of{' '}
        <span>{totalRows}</span> results
      </p>
      <Pagination
        color={'neutral'}
        withFirstLast={false}
        totalNumberOfPages={totalRows && limit ? totalRows / limit : 1}
        currentPage={currentPage}
        pagesOffset={3}
        onPageChange={(page: any) => {
          setCurrentPage(page);
          setOffset(limit ? (page - 1) * limit : 1);
        }}
      />
    </div>
  );
};
