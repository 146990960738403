import { TagIcon, ViewfinderCircleIcon } from '@heroicons/react/24/solid';
import { Box, Button, Flex, Grid, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useUpdateEffect } from 'react-use';
import { useUpdatePrimaryTagTrackingObjectByEvent } from 'services/entity/trackingObject';

import { AssignedCardProps } from '../../types';

const AssignedCard = ({
  id,
  primary,
  name,
  address,
  role,
  date,
  time,
  eventId,
  trackingObjectId,
  setAssignedTags,
}: AssignedCardProps) => {
  const { hovered, ref } = useHover();
  const {
    mutate: updatePrimaryTag,
    isSuccess: updatePrimaryTagIsSuccess,
  } = useUpdatePrimaryTagTrackingObjectByEvent();

  const handlePrimary = () => {
    if (trackingObjectId && eventId && id) {
      updatePrimaryTag({
        eventId: eventId,
        trackingObjectId: trackingObjectId,
        tagId: id,
      });
    }
  };

  useUpdateEffect(() => {
    if (updatePrimaryTagIsSuccess) {
      if (setAssignedTags) {
        setAssignedTags((prev) =>
          prev.map((item) => ({
            ...item,
            primary: item.tagId === id,
          })),
        );
      }
    }
  }, [updatePrimaryTagIsSuccess]);

  return (
    <Flex
      p="0.5rem"
      bg={!primary && hovered ? '#f8fafc' : '#ffffff'}
      bd="1px solid #e2e8f0"
      align="center"
      ref={ref}
      style={{ borderRadius: '0.5rem' }}
    >
      <Box mr="0.5rem">
        {address ? (
          <TagIcon
            height="1.5rem"
            width="1.5rem"
            color={primary ? '#3b82f6' : '#64748b'}
          />
        ) : (
          <ViewfinderCircleIcon
            height="1.5rem"
            width="1.5rem"
            color={primary ? '#3b82f6' : '#64748b'}
          />
        )}
      </Box>
      <Grid w="100%" align="center" columns={8}>
        <Grid.Col span={4}>
          <Text fz="0.875rem" fw={500} c={primary ? '#3b82f6' : '#64748b'}>
            {name}
          </Text>
          <Text fz="0.875rem" fw={400} c={primary ? '#60a5fa' : '#94a3b8'}>
            {address || role}
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          <Text fz="0.875rem" fw={500} c={primary ? '#3b82f6' : '#64748b'}>
            {date}
          </Text>
          <Text fz="0.875rem" fw={400} c={primary ? '#60a5fa' : '#94a3b8'}>
            {time}
          </Text>
        </Grid.Col>
        <Grid.Col span={2}>
          {!primary && hovered && (
            <Button
              bg="transparent"
              c="#3b82f6"
              fz="0.875rem"
              fw={500}
              onClick={handlePrimary}
            >
              Set primary
            </Button>
          )}
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default AssignedCard;
