import { InputGroup } from 'components/Input';
import { ResponsiveRow } from 'components/utils/ResponsiveRow/ResponsiveRow';
import { useZodForm } from 'hooks/useZodForm';
import { useFormContext } from 'react-hook-form';

import { MultiStepFormContent } from '../MultiStepForm/MultiStepFormContent/MultiStepFormContent';
import { MultiStepFormContentFooter } from '../MultiStepForm/MultiStepFormContent/MultiStepFormContentFooter/MultiStepFormContentFooter';
import {
  contactPersonSchema,
  ContactPersonSchema,
  CreateTimingLocationSchema,
} from '../MultiStepForm/timingLocation-schema';

type ContactPersonFormProps = {
  defaultValues: ContactPersonSchema;
  step: number;
};

const ContactPersonForm = ({ step, defaultValues }: ContactPersonFormProps) => {
  const {
    setValue: setContextValue,
  } = useFormContext<CreateTimingLocationSchema>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useZodForm<ContactPersonSchema>(
    {
      defaultValues,
    },
    contactPersonSchema,
  );

  const onSubmit = (data: ContactPersonSchema) => {
    reset(data);
    setContextValue('contactPerson', data);
  };

  return (
    <MultiStepFormContent step={step}>
      <InputGroup
        register={register}
        label="Contact person"
        placeholder="e.g. John Doe"
        name="name"
        errors={errors}
      />
        <InputGroup
          register={register}
          label="Position"
          placeholder="e.g. Manager"
          name="position"
          errors={errors}
        />

      <ResponsiveRow>
        <InputGroup
          register={register}
          label="Email"
          placeholder="e.g. john@company.com"
          name="email"
          errors={errors}
        />

        <InputGroup
          register={register}
          label="Phone number"
          placeholder="e.g. 0491 570 006"
          name="phoneNumber"
          errors={errors}
        />

      </ResponsiveRow>

      <MultiStepFormContentFooter
        isValid={isValid}
        onConfirm={handleSubmit(onSubmit)}
      />
    </MultiStepFormContent>
  );
};

export default ContactPersonForm;
