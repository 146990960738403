import { useMutation, useQueryClient } from '@tanstack/react-query';
//import { TimingLocationAssignableDevicesService } from 'services/event/timingLocation/TimingLocationAssignableDevicesService.js';

import { RowSelectionState } from '@tanstack/table-core';
import {
  CreateEventTimingLocationsDevices,
  EventTimingLocationsDevices,
} from 'pages/Events/TimingLocations/TimingLocations.interfaces';
import { useEffect } from 'react';
import { TimingLocationAssignableDevicesService } from 'services/event/timingLocation/TimingLocationAssignableDevicesService';

import {
  eventTimingLocationDeviceAssignableKey,
  eventTimingLocationGatings,
} from '../baseKey';

interface AddEventAssignableDevicesProps {
  payload: CreateEventTimingLocationsDevices[];
  filter: string;
  eventId: string;
  layoutId: string;
  sectionId: string;
}

export const useAddEventTimingLocationAssignableDevices = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      eventId,
      layoutId,
      sectionId,
      payload,
    }: AddEventAssignableDevicesProps) => {
      if (!eventId) return;

      await TimingLocationAssignableDevicesService.addAssignableDevices(
        eventId,
        layoutId,
        sectionId,
        payload,
      );
    },
    onSuccess: (_, { eventId, layoutId, sectionId, filter }) => {
      queryClient.invalidateQueries([eventTimingLocationDeviceAssignableKey]);
      queryClient.invalidateQueries([eventTimingLocationGatings]);
    },
  });
};
