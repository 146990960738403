export const eventKeys = {
  all: [{ entity: 'event' }] as const,
  event: (eventId: string = '') => [{ ...eventKeys.all[0], eventId }] as const,
};

// export const eventKeys = {
//   all: [{ scope: 'event' }] as const,
//   event: (eventId?: string) =>
//     [{ ...eventKeys.all[0], entity: 'event', eventId }] as const,
//   eventLayouts: (eventId?: string) =>
//     [{ ...eventKeys.all[0], entity: 'eventLayout', eventId }] as const,
//   eventLayout: (eventId?: string, layoutId?: string) =>
//     [
//       { ...eventKeys.all[0], entity: 'eventLayout', eventId, layoutId },
//     ] as const,
//   eventLayoutTimingLocations: (eventId?: string) =>
//     [
//       { ...eventKeys.all[0], entity: 'eventLayoutTimingLocation', eventId },
//     ] as const,
//   eventLayoutTimingLocation: (eventId?: string, timingLocationId?: string) =>
//     [
//       {
//         ...eventKeys.all[0],
//         entity: 'eventLayoutTimingLocation',
//         eventId,
//         timingLocationId,
//       },
//     ] as const,
// };
