import Konva from 'konva';
import { useEffect, useRef } from 'react';
import { Group, Image, Rect } from 'react-konva';
import useImage from 'use-image';

import { deviceType } from '../consts';
import { CanvasDeviceProps } from '../types';

const CanvasDevice = ({
  x,
  y,
  type = 'processor',
  isHovered = false,
  isSelected = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: CanvasDeviceProps) => {
  const deviceRef = useRef<Konva.Image>(null);

  const [deviceImage] = useImage(deviceType[type]);

  useEffect(() => {
    if (deviceImage) {
      deviceRef?.current?.cache();
      deviceRef?.current?.filters([Konva.Filters.RGB]);
      // Podesavanje RGB vrijednosti ( #3B82F6 boja)
      deviceRef?.current?.red(59);
      deviceRef?.current?.green(130);
      deviceRef?.current?.blue(246);
      // Primjenjivanje filtera
      deviceRef?.current?.getLayer()?.batchDraw();
    }
    // x i y se nalaze u dep array zato sto kad ekran promijeni velicinu image izgubi boju
  }, [deviceImage, x, y]);

  return (
    <Group
      key={`device-${x}-${y}`}
      x={x - 12}
      y={y - 12}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Rect
        height={24}
        width={24}
        cornerRadius={8}
        fill="#eff6ff"
        stroke={isSelected ? '#10b981' : isHovered ? '#3b82f6' : '#94a3b8'}
        strokeWidth={1.5}
      />
      <Image
        image={deviceImage}
        x={5}
        y={5}
        height={14}
        width={14}
        ref={deviceRef}
      />
    </Group>
  );
};

export default CanvasDevice;
