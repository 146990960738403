import { ErrorMessage } from '@hookform/error-message';
import { TimeInput } from '@lanaco/lnc-react-ui';
import { Text } from '@mantine/core';
import {
  FieldErrors,
  FieldPath,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';

import { TimeInputError } from '../TimeInputError';

import styles from './TimeInputGroup.module.scss';

interface TimeInputGroupProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  name: FieldPath<T>;
  label: string;
  placeholder: string;
  errors?: FieldErrors;
  className?: string;
  mode?: 'string';
  onChange;
  value;
  //defaultValue?: string;
  withAsterisk?: boolean;
}

export const TimeInputGroup = <T extends FieldValues>({
  register,
  className,
  name,
  label,
  placeholder,
  errors,
  mode = 'string',
  onChange,
  value,
  withAsterisk,
}: //defaultValue,
TimeInputGroupProps<T>) => {
  return (
    <div className={styles.dateInputGroup}>
      <label htmlFor={name}>
        {label}
        {withAsterisk && (
          <Text component="span" c="red">
            *
          </Text>
        )}
      </label>
      {mode === 'string' && (
        <TimeInput
          //{...register(name)}
          {...register(name)}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          // defaultValue="07:54"
        />
      )}

      {/* <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <TimeInputError message={message} />}
      /> */}
    </div>
  );
};
