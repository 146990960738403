import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const createDateAndTime = (
  date: Date,
  time: string,
  timezone: string,
) => {
  //if (date !== undefined) {
  if (time !== undefined) {
  }
  const timeArray = time && time.split(':').map(Number);
  const [hours, minutes] =
    timeArray && timeArray.length === 2 ? timeArray : [undefined, undefined];

  if (hours !== undefined && minutes !== undefined) {
    date.setHours(hours, minutes, 0, 0);
  } else {
    // Ako su hours i minutes undefined, postavljamo samo datum bez vremena
    date.setHours(0, 0, 0, 0);
  }

  //let newDateAndTime = date.setHours(hours || 0, minutes || 0, 0, 0);
  return new Date(new Date(date).toString() + timezone);
  // }
};

export const convertDateAndTime = (dateTime: string, tz: string) =>
  dayjs.utc(dateTime).tz(tz).format('YYYY-MM-DD HH:mm:ss');

export const convertDateAndTimeToUtc = (dateTime: string, tz: string) => {
  const localTime = dayjs.tz(dateTime, tz);
  return localTime.utc().toISOString();
};

export const convertDate = (date: string, tz?: string) =>
  tz
    ? dayjs.utc(date).tz(tz).format('YYYY-MM-DD')
    : dayjs.utc(date).format('YYYY-MM-DD');

export const convertTime = (date: string, tz?: string) =>
  tz
    ? dayjs.utc(date).tz(tz).format('HH:mm:ss')
    : dayjs.utc(date).format('HH:mm:ss');

export const getTimeStatus = (
  startDateTime: string,
  finishDateTime: string,
  tz: string,
): string => {
  const now = dayjs().tz(tz);
  const start = dayjs.utc(startDateTime).tz(tz);
  const finish = dayjs.utc(finishDateTime).tz(tz);

  if (now.isBefore(start)) {
    return 'upcoming';
  } else if (now.isAfter(finish)) {
    return 'passed';
  } else {
    return 'live';
  }
};
