import { Box, Button, Flex, Image } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import { LayoutLibraryProps } from 'pages/Entities/Hub/types';

import LayoutLibraryCard from './LayoutLibraryCard';

const LayoutLibrary = ({
  height,
  layoutsData,
  selectedLayout,
  setSelectedLayout,
}: LayoutLibraryProps) => {
  const { ref: boxRef, height: elHeight, width: elWidth } = useElementSize();

  return (
    <Box h={height} ref={boxRef}>
      {selectedLayout ? (
        <Box
          h={elHeight}
          pos="relative"
          style={{ borderRadius: '0.5rem', overflow: 'hidden' }}
        >
          <Image
            src={
              layoutsData.find((layout) => layout.id === selectedLayout)?.image
            }
            rel="Chosen template picture"
            h={elHeight}
            w={elWidth}
            fit="fill"
          />
          <Button
            bg="#ffffff"
            c="#3b82f6"
            bd="1px solid #e2e8f0"
            pos="absolute"
            top="1rem"
            right="1rem"
            onClick={() => setSelectedLayout(null)}
          >
            Choose another layout
          </Button>
        </Box>
      ) : (
        <ScrollArea h={elHeight} type="auto" variant="thumb">
          <Flex mx="auto" gap="1rem" wrap="wrap">
            {layoutsData.map((layout, index) => (
              <LayoutLibraryCard
                key={`layout-library-card-${index}`}
                title={layout.title}
                image={layout.image}
                onClick={() => setSelectedLayout(layout.id)}
              />
            ))}
          </Flex>
        </ScrollArea>
      )}
    </Box>
  );
};

export default LayoutLibrary;
