import { notification } from '@lanaco/lnc-react-ui';
import { FormProps, useZodForm } from 'hooks/useZodForm';
import React, { ReactNode } from 'react';
import { FieldErrors, FieldValues, FormProvider } from 'react-hook-form';
import { ZodType } from 'zod';

import { MultiStepProvider } from './context/multi-step-context';
import style from './MultiStepForm.module.scss';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
> = {
  formProps: FormProps<TFieldValues, TContext>;
  schema: ZodType<TFieldValues>;
  totalSteps: number;
  header?: ReactNode;
  children: ReactNode;
  onSuccess: (data: TFieldValues, event?: React.BaseSyntheticEvent) => void;
  onReject?: (
    errors: FieldErrors<TFieldValues>,
    event?: React.BaseSyntheticEvent,
  ) => void;
};

export const MultiStepForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>({
  formProps,
  schema,
  totalSteps,
  header,
  children,
  onSuccess,
  onReject,
}: Props<TFieldValues, TContext>) => {
  const methods = useZodForm<TFieldValues, TContext>(formProps, schema);

  const { getValues, handleSubmit } = methods;

  const onValid = async (
    data: TFieldValues,
    event?: React.BaseSyntheticEvent,
  ) => {
    event?.preventDefault();

    const formData = getValues();
    const result = schema.safeParse(formData);

    if (result.success) {
      onSuccess(data, event);
    } else {
      const validationErrors = result.error.issues;

      notification.error(
        validationErrors.map((error) => <p>{error.message}</p>),
      );
    }
  };

  const onInvalid = (
    errors: FieldErrors<TFieldValues>,
    event?: React.BaseSyntheticEvent,
  ) => {
    onReject?.(errors, event);
  };

  return (
    <FormProvider {...methods}>
      <MultiStepProvider totalSteps={totalSteps}>
        {header}
        <div className={style.multiStep}>
          <form
            className="contents"
            onSubmit={handleSubmit(onValid, onInvalid)}
          >
            {children}
          </form>
        </div>
      </MultiStepProvider>
    </FormProvider>
  );
};
