import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventDeviceService } from 'services/event/device/EventDeviceService';

import { eventDeviceBaseKey } from './baseKeys';

interface DeleteDevicesProps {
  id: string;
  ids: string[];
}

export const useDeleteDevices = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, ids }: DeleteDevicesProps) =>
      await EventDeviceService.deleteEventDevices(id, ids),
    onSuccess: () => {
      queryClient.invalidateQueries([eventDeviceBaseKey]);
    },
  });
};
