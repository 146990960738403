import {
  ScrollArea as MantineScrollArea,
  ScrollAreaProps as MantineScrollAreaProps,
} from '@mantine/core';

import classNames from './ScrollArea.module.scss';

type ScrollAreaProps = {
  variant?: 'default' | 'thumb';
};

const defaultVariant = {
  scrollbar: classNames.defaultVariantScrollbar,
  thumb: classNames.defaultVariantThumb,
};

const thumbVariant = {
  thumb: classNames.thumbVariantThumb,
};

const ScrollArea = ({
  children,
  variant = 'default',
  ...props
}: ScrollAreaProps & MantineScrollAreaProps) => {
  return (
    <MantineScrollArea
      type="always"
      scrollbarSize={7}
      offsetScrollbars
      scrollbars="y"
      classNames={variant === 'default' ? defaultVariant : thumbVariant}
      {...props}
    >
      {children}
    </MantineScrollArea>
  );
};

const Autosize = ({
  children,
  variant = 'default',
  ...props
}: ScrollAreaProps & MantineScrollAreaProps) => {
  return (
    <MantineScrollArea.Autosize
      type="always"
      scrollbarSize={7}
      offsetScrollbars
      scrollbars="y"
      classNames={variant === 'default' ? defaultVariant : thumbVariant}
      {...props}
    >
      {children}
    </MantineScrollArea.Autosize>
  );
};

ScrollArea.Autosize = Autosize;

export default ScrollArea;
