import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Type } from 'pages/Events/interfaces';
import { client } from 'services/api';
import { Response } from 'types/response';
import { GET_EVENT_TYPES } from 'utils/apiUrl';

import { eventTypeBaseKey } from './baseKey';

export const useFetchEventTypes = () => {
  const queryClient = useQueryClient();
  const queryResult = useQuery<Response<Type[]>, AxiosError>({
    queryKey: [eventTypeBaseKey],
    queryFn: async () => {
      const retVal = await client.get<Response<Type[]>>(GET_EVENT_TYPES());

      retVal.data.data.forEach(async (type) =>
        queryClient.setQueryData<Type>([eventTypeBaseKey, type._id], type),
      );

      return retVal.data;
    },
  });
  return queryResult;
};
