import { Icon } from '@lanaco/lnc-react-ui';
import clsx from 'clsx';
import { InputHTMLAttributes, forwardRef } from 'react';

import styles from './InputSearch.module.css';

interface InputSearchProps extends InputHTMLAttributes<HTMLInputElement> {
  htmlFor?: string;
}

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
  ({ name = 'search', placeholder = 'Search', className, ...props }, ref) => (
    <label htmlFor="search" className={clsx(styles.inputSearch, className)}>
      <Icon icon="magnifying-glass" />
      <input id="search" placeholder={placeholder} ref={ref} {...props} />
    </label>
  ),
);
