import { useState } from 'react';
import { Divider } from '@mantine/core';
import FilterButtonGroup from 'components/FilterButtonGroup/FilterButtonGroup';
import HeaderTitle from 'components/HeaderTitle/HeaderTitle';
import { HeaderAndFilterGroupType } from './types';
import styles from './HeaderAndFilter.module.css';

const HeaderAndFilterGroup = ({
  title,
  icon,
  subtitle,
  buttons,
  width,
  extraButton,
  hasBorder,
  onSearch,
}: HeaderAndFilterGroupType) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
    <div className={styles.headerAndFilterGroup} style={{ width: width }}>
      <div className={styles.headerTitleContainer}>
        <HeaderTitle
          icon={icon}
          title={title}
          subtitle={subtitle}
          hasBorder={hasBorder}
          onSearch={(term) => {
            setSearchTerm(term);
            if (onSearch) onSearch(term);
          }}
        />
      </div>

      <Divider className={styles.separatorLine} />

      <div className={styles.filterButtonGroupContainer}>
        <FilterButtonGroup buttons={buttons} />
        {extraButton && (
          <div className={styles.extraButtonContainer}>{extraButton}</div>
        )}
      </div>
    </div>
  );
};

export default HeaderAndFilterGroup;
