import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { Stack, Text, TextInput, Button, FileButton } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useEffect, useRef, useState } from 'react';

import { CreateScheduleFirstStep } from '../types';

const ScheduleInformtionContent = ({
  formState,
  setFormState,
  isFormValid,
  setIsFormValid,
}: ModalFormProps) => {
  const openRef = useRef<() => void>(null);
  const [imageSource, setImageSource] = useState<string | null>(null);

  const form = useForm<CreateScheduleFirstStep>({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      title: '',
      image: '',
    },
    validate: {
      title: (value) => (!value ? 'Title is required' : null),
    },
  });

  useEffect(() => {
    if (form.isValid()) {
      setIsFormValid(true);
      const newFormState = form.values;

      if (setFormState) {
        setFormState((prevState) => ({
          ...prevState,
          ...newFormState,
        }));
      }
    } else {
      setIsFormValid(false);
    }
  }, [form.values, form.isValid()]);

  const resizeImage = (
    file: File,
    width: number,
    height: number,
    callback: (resizedDataUrl: string) => void,
  ) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height);
          const resizedDataUrl = canvas.toDataURL('image/png');
          callback(resizedDataUrl);
        }
      };
    };
    reader.readAsDataURL(file);
  };

  const handleDrop = (files: File[]) => {
    if (files.length > 0) {
      const file = files[0];
      resizeImage(file, 1920, 1080, (resizedDataUrl) => {
        setImageSource(resizedDataUrl);
        form.setFieldValue('image', resizedDataUrl.split(',')[1]);
      });
    }
  };

  const handleFileUpload = (file: File | null) => {
    if (file) {
      resizeImage(file, 1920, 1080, (resizedDataUrl) => {
        setImageSource(resizedDataUrl);
        form.setFieldValue('image', resizedDataUrl.split(',')[1]);
      });
    }
  };

  return (
    <Stack gap="1.5rem">
      <TextInput
        label="Title"
        placeholder="Entity title"
        c="#64748B"
        withAsterisk
        key={form.key('title')}
        {...form.getInputProps('title')}
      />

      <Dropzone
        openRef={openRef}
        onDrop={handleDrop}
        accept={['image/png', 'image/jpeg']}
        maxSize={5 * 1024 ** 2}
        activateOnClick={false}
      >
        <Stack
          align="center"
          justify="center"
          h="16.875rem"
          w="100%"
          ta="center"
          pos="relative"
          style={{
            border: !imageSource ? '1.5px dashed var(--gray-300)' : 'none',
            borderRadius: '0.5rem',
          }}
        >
          {!imageSource && (
            <div>
              <CloudArrowUpIcon
                height="3.125rem"
                width="3.125rem"
                color="var(--gray-400)"
                strokeWidth={0.8}
              />
            </div>
          )}

          {!imageSource ? (
            <Text inline fz={14} c="var(--blue-500)">
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => openRef.current?.()}
              >
                Click to upload
              </span>
              <span style={{ color: '#344155' }}> or drag and drop</span>
            </Text>
          ) : (
            <div
              style={{ position: 'relative', width: '100%', height: '100%' }}
            >
              <img
                src={imageSource}
                alt="Uploaded"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '0.5rem',
                }}
              />

              <FileButton
                onChange={handleFileUpload}
                accept="image/png,image/jpeg"
              >
                {(props) => (
                  <Button
                    {...props}
                    pos="absolute"
                    mt="1rem"
                    fz="0.875rem"
                    fw={500}
                    c="#3B82F6"
                    lh={20}
                    variant="default"
                    style={{
                      right: '1rem',
                      borderRadius: '0.5rem',
                    }}
                  >
                    Upload another image
                  </Button>
                )}
              </FileButton>
            </div>
          )}

          {!imageSource && (
            <Stack align="center" gap={1}>
              <Text inline fz={12} c="var(--gray-500)">
                Recommended image size: 1920x1080 pixels.
              </Text>
              <Text inline fz={12} c="var(--gray-500)">
                JPEG or PNG file.
              </Text>
            </Stack>
          )}
        </Stack>
      </Dropzone>
    </Stack>
  );
};

export default ScheduleInformtionContent;
