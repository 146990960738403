import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventTagAssignParticipantService } from 'services/event/tag/event-tag-assign-participant';

import { eventParticipantBaseKey } from '../participants/eventParticipantBaseKey';

import { eventTagBaseKey } from './baseKeys';

interface Props {
  eventId: string;
  tagIds: string[];
  participantId: string;
}

export const useRemoveParticipantFromTag = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ eventId, tagIds, participantId }: Props) => {
      if (!eventId) return;
      const resp = await EventTagAssignParticipantService.unassignParticipantFromEventTag(
        {
          eventId,
          tagIds,
          participantId,
        },
      );
      return resp?.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([eventTagBaseKey]);
      queryClient.invalidateQueries([eventParticipantBaseKey]);
    },
  });

  return mutation;
};
