import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import { DELETE_EVENT_TAG, DELETE_EVENT_TAGS } from 'utils/apiUrl';

interface DeleteParams {
  eventId: string;
  tagId?: string;
  tagIds?: string[];
}

export class EventTagsDeleteService extends ResponseInterceptor {
  static async deleteEventTag({
    eventId,
    tagId,
  }: DeleteParams): Promise<APIAxiosResponse<string[]>> {
    const res = await client.delete(DELETE_EVENT_TAG(eventId, tagId!));
    return this.handleResponse(res);
  }

  static async deleteEventTags({
    eventId,
    tagIds,
  }: DeleteParams): Promise<APIAxiosResponse<string[]>> {
    const response = await client.delete(DELETE_EVENT_TAGS(eventId), {
      data: { tagIds },
    });
    return this.handleResponse(response);
  }
}
