import { useQuery } from '@tanstack/react-query';
import { EventDetailsService } from 'services/event';

import { eventKeys } from '../../eventKeys';

export const useFetchEventDetails = (eventId?: string) =>
  useQuery({
    queryKey: eventKeys.event(eventId),
    queryFn: async () => {
      if (!eventId) return;

      const retVal = await EventDetailsService.getEventDetails(eventId);

      return retVal.data.data;
    },
  });
