import { useMutation, useQueryClient } from '@tanstack/react-query';
import { tagStatisticsBaseKey } from 'hooks/event/statistics';
import { TagsService } from 'services/tags';

import { tagBaseKey } from './baseKey';

interface DeleteTagProps {
  id: string;
}

export const useDeleteTag = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: DeleteTagProps) =>
      await TagsService.deleteTag(id),
    onSuccess: () => {
      queryClient.invalidateQueries([tagBaseKey]);
      queryClient.invalidateQueries([tagStatisticsBaseKey]);
    },
  });
};
