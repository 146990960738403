import { useQuery } from '@tanstack/react-query';
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
} from 'mantine-react-table';
import { ApiResult } from 'services/api';
import { EventLiveService } from 'services/event/eventLive/eventLive';
import { EventLiveData } from 'types/event/eventLiveData';

export const useFetchEventLiveData = (
  eventId: string,
  {
    pagination,
    columnFilters,
  }: {
    pagination: MRT_PaginationState;
    columnFilters: MRT_ColumnFiltersState;
  },
) => {
  return useQuery({
    staleTime: 1000 * 60 * 2,
    refetchInterval: 1000 * 60 * 2,
    queryKey: ['eventLiveData', { eventId, pagination, columnFilters }],
    queryFn: () => {
      if (!eventId)
        return Promise.resolve({
          code: 404,
          message: 'Event not found',
          data: [],
          _route: {
            url: '',
            total: 0,
          },
          errors: null,
          status: 'error',
          success: false,
        } as ApiResult<EventLiveData[]>);

      return EventLiveService.getEventLiveData(eventId, {
        offset: pagination.pageIndex * pagination.pageSize,
        limit: pagination.pageSize,
        ...columnFilters.reduce((acc, filter) => {
          if (filter.value) {
            acc[filter.id] = filter.value;
          }
          return acc;
        }, {}),
      }).then((res) => res.data);
    },
  });
};
