import { AxiosError } from 'axios';

import { APIAxiosResponse } from './types';

export class ResponseInterceptor {
  static handleResponse<T>(response: APIAxiosResponse<T>): APIAxiosResponse<T> {
    if (response.data.code >= 400) {
      throw new AxiosErrorResponse(response);
    } else if (response.status >= 400) {
      throw new AxiosError(response.statusText);
    } else {
      return response;
    }
  }
}

export class AxiosErrorResponse extends Error {
  readonly response: APIAxiosResponse;

  constructor(response: APIAxiosResponse) {
    super();
    this.response = response;
    this.name = 'Axios Error Response';
  }
}
