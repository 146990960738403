import { notification } from '@lanaco/lnc-react-ui';
import { RowSelectionState } from '@tanstack/react-table';
import PromptModal from 'components/PromptModal/PromptModal';
import { useDeleteClients } from 'hooks/client/useDeleteClients';
import { getErrorMsg } from 'utils/getErrorMsg';

import { ClientsTableData } from '../interfaces';

interface DeleteClientsModalProps {
  selectedIds: string[];
  closeModal: () => void;
  onClientEdit: (device: ClientsTableData | null) => void;
  setSelectedRows: (selectedRows: RowSelectionState) => void;
}

export const DeleteClientsModal = ({
  selectedIds,
  closeModal,
  onClientEdit,
  setSelectedRows,
}: DeleteClientsModalProps) => {
  const { mutate, isLoading } = useDeleteClients();

  const closeDeleteDevicesModal = () => {
    onClientEdit(null);
    closeModal();
  };

  const confirmDeleteClients = async () => {
    mutate(
      {
        ids: selectedIds,
      },
      {
        onSuccess: (data) => {
          if (data?.status === 200) {
            notification.success('Client successfully deleted.');
          } else {
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          setSelectedRows({});
          closeDeleteDevicesModal();
        },
      },
    );
  };

  return (
    <PromptModal
      title="Delete clients?"
      isLoading={isLoading}
      warningMessage={
        <>
          Are you sure you want to delete {selectedIds.length}
          <br />
          clients from the database?
        </>
      }
      confirmButtonName="Delete"
      icon="exclamation-triangle"
      iconSize="24"
      iconColor="danger"
      isDeleteButton
      onConfirm={confirmDeleteClients}
      onCancel={closeDeleteDevicesModal}
    />
  );
};
