import {
  PencilSquareIcon,
  ChartPieIcon,
  PlusIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import { Badge, Flex, Stack, Text, ScrollArea } from '@mantine/core';

import { openModal } from '../ModalManager/ModalsManager';

import ControlAccessCard from './ControlAccessCard';
import { setControlAccessModalContent } from './Modals/controlAccessModalsConfig';

const ControlAccessSettings = () => {
  const limitedAccessData = {
    description:
      'Maecenas a magna augue. Pellentesque imperdiet eros at nunc rutrum, vel dignissim leo efficitur. Fusce suscipit justo et facilisis sollicitudin. Praesent iaculis massa nec viverra suscipit. Nam a dolor eget sem dapibus ornare consectetur nec mi. Aliquam sagittis libero at arcu mattis, sit amet convallis ante malesuada.',
    amount: 96,
    total: 613,
    percent: '13%',
    badgeStatus: 'active',
  };

  const entityLevelCardData = [
    {
      title: 'Lorem lapsumim',
      entranceTime: '01 May 2024 - 09:00 AM',
      status: 'active',
      exitTime: '05 May 2024 - 01:00 PM',
      permissionStatus: 'on',
      notificationStatus: 'off',
      reportStatus: 'on',
    },
    {
      title: 'Lorem lapsumim',
      entranceTime: '01 May 2024 - 09:00 AM',
      status: 'active',
      exitTime: '05 May 2024 - 01:00 PM',
      permissionStatus: 'on',
      notificationStatus: 'off',
      reportStatus: 'on',
    },
    {
      title: 'Lorem lapsumim',
      entranceTime: '01 May 2024 - 09:00 AM',
      status: 'active',
      exitTime: '05 May 2024 - 01:00 PM',
      permissionStatus: 'on',
      notificationStatus: 'off',
      reportStatus: 'on',
    },
  ];
  const sectionLevelData = [
    {
      title: 'Section 1',
      entranceTime: '01 June 2024 - 09:00 AM',
      status: 'active',
      exitTime: '05 June 2024 - 01:00 PM',
      permissionStatus: 'on',
      notificationStatus: 'off',
      reportStatus: 'on',
    },
    {
      title: 'Section 2',
      entranceTime: '02 June 2024 - 10:00 AM',
      status: 'inactive',
      exitTime: '06 June 2024 - 02:00 PM',
      permissionStatus: 'off',
      notificationStatus: 'on',
      reportStatus: 'off',
    },
    {
      title: 'Section 3',
      entranceTime: '03 June 2024 - 11:00 AM',
      status: 'active',
      exitTime: '07 June 2024 - 03:00 PM',
      permissionStatus: 'on',
      notificationStatus: 'on',
      reportStatus: 'on',
    },
  ];
  const layoutLevelData = [
    {
      title: 'Layout 1',
      entranceTime: '01 June 2024 - 09:00 AM',
      status: 'active',
      exitTime: '05 June 2024 - 01:00 PM',
      permissionStatus: 'on',
      notificationStatus: 'off',
      reportStatus: 'on',
    },
    {
      title: 'Layout 2',
      entranceTime: '02 June 2024 - 10:00 AM',
      status: 'inactive',
      exitTime: '06 June 2024 - 02:00 PM',
      permissionStatus: 'off',
      notificationStatus: 'on',
      reportStatus: 'off',
    },
    {
      title: 'Layout 3',
      entranceTime: '03 June 2024 - 11:00 AM',
      status: 'active',
      exitTime: '07 June 2024 - 03:00 PM',
      permissionStatus: 'on',
      notificationStatus: 'on',
      reportStatus: 'on',
    },
  ];
  return (
    <div>
      <Stack mt="-0.5rem">
        <Flex gap="1.5rem">
          <Stack
            h="22rem"
            py="1.875rem"
            px="1.5rem"
            bg="#FFFFFF"
            bd="1px solid #E2E8F0"
            flex={1}
            style={{ borderRadius: '1.25rem' }}
          >
            <Flex justify="space-between">
              <Text fw={600} fz="1.125rem" c="#0F172A">
                Limited access
              </Text>
              <PencilSquareIcon
                height="1.5rem"
                width="1.5rem"
                color="#3B82F6"
              />
            </Flex>
            <Text fw={400} fz="0.875rem" c="#64748B">
              {limitedAccessData.description
                ? limitedAccessData.description
                : 'No description'}
            </Text>
            <Text fw={500} fz="0.875rem" c="#64748B" mb="0.25rem">
              Tracking objects
            </Text>
            <Flex justify="space-between" align="center">
              <Flex gap="0.375rem" align="center" mt="-1.5rem">
                <Text fw={600} fz="1.125rem" c="#0F172A">
                  {limitedAccessData.amount}
                </Text>
                <Text fw={500} fz="0.875rem" c="#64748B">
                  of {limitedAccessData.total} total
                </Text>
              </Flex>
              <Badge
                size="md"
                radius="1.3rem"
                bg="#EFF6FF"
                fz="0.875rem"
                c="#3B82F6"
                fw={500}
                leftSection={
                  <ChartPieIcon
                    height="1rem"
                    width="1rem"
                    stroke="#3B82F6"
                    color="#EFF6FF"
                    strokeWidth={1.5}
                  />
                }
              >
                {limitedAccessData.percent}
              </Badge>
            </Flex>
            <Badge
              bg={
                limitedAccessData.badgeStatus === 'active'
                  ? '#ecfdf5'
                  : '#f8fafc'
              }
              c={
                limitedAccessData.badgeStatus === 'active'
                  ? '#10B981'
                  : '#64748b'
              }
              size="md"
              mt="auto"
            >
              {limitedAccessData.badgeStatus}
            </Badge>
          </Stack>

          <Stack
            h="22rem"
            py="1.875rem"
            px="1.5rem"
            bg="#FFFFFF"
            bd="1px solid #E2E8F0"
            flex={1}
            style={{ borderRadius: '1.25rem' }}
          >
            <Flex justify="space-between">
              <Text fw={600} fz="1.125rem" c="#0F172A">
                Entity level
              </Text>
              <PlusIcon
                cursor="pointer"
                height="1.5rem"
                width="1.5rem"
                color="#3B82F6"
                onClick={() =>
                  openModal({
                    type: 'addingEntityLevel',
                    size: 640,
                    title: 'Adding entity level',
                    actionButtonLabel: 'Add',
                    actionButtonFn: () => {},
                    setModalContent: setControlAccessModalContent,
                  })
                }
              />
            </Flex>

            {entityLevelCardData.length > 0 ? (
              <ScrollArea mb="-1.875rem" type="never">
                {entityLevelCardData.map((item, index, data) => (
                  <ControlAccessCard
                    key={`entity-level-${index}`}
                    title={item.title}
                    entranceTime={item.entranceTime}
                    status={item.status}
                    exitTime={item.exitTime}
                    permissionStatus={item.permissionStatus}
                    notificationStatus={item.notificationStatus}
                    reportStatus={item.reportStatus}
                    hoverable
                    style={{
                      marginBottom:
                        data.length - 1 === index ? '1.875rem' : '1rem',
                    }}
                  />
                ))}
              </ScrollArea>
            ) : (
              <Stack my="auto" align="center" gap="0.8rem">
                <ExclamationTriangleIcon
                  height="3rem"
                  width="3rem"
                  color="#CBD5E1"
                />
                <Text fw={600} fz="1rem" c="#475569">
                  No active entities
                </Text>
                <Text fw={400} fz="1rem" c="#64748B">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. 
                </Text>
              </Stack>
            )}
          </Stack>
        </Flex>

        <Flex gap="1.5rem" mt="0.5rem">
          <Stack
            h="22rem"
            py="1.875rem"
            px="1.5rem"
            bg="#FFFFFF"
            bd="1px solid #E2E8F0"
            flex={1}
            style={{ borderRadius: '1.25rem' }}
          >
            <Flex justify="space-between">
              <Text fw={600} fz="1.125rem" c="#0F172A">
                Layout level
              </Text>
              <PlusIcon
                height="1.5rem"
                width="1.5rem"
                color="#3B82F6"
                cursor="pointer"
                onClick={() =>
                  openModal({
                    type: 'addingLayoutLevel',
                    size: 640,
                    title: 'Adding layout level',
                    actionButtonLabel: 'Add',
                    actionButtonFn: () => {},
                    setModalContent: setControlAccessModalContent,
                  })
                }
              />
            </Flex>
            {layoutLevelData.length > 0 ? (
              <ScrollArea mb="-1.875rem" type="never">
                {layoutLevelData.map((item, index, data) => (
                  <ControlAccessCard
                    key={`entity-level-${index}`}
                    title={item.title}
                    entranceTime={item.entranceTime}
                    status={item.status}
                    exitTime={item.exitTime}
                    permissionStatus={item.permissionStatus}
                    notificationStatus={item.notificationStatus}
                    reportStatus={item.reportStatus}
                    hoverable
                    style={{
                      marginBottom:
                        data.length - 1 === index ? '1.875rem' : '1rem',
                    }}
                  />
                ))}
              </ScrollArea>
            ) : (
              <Stack my="auto" align="center" gap="0.8rem">
                <ExclamationTriangleIcon
                  height="3rem"
                  width="3rem"
                  color="#CBD5E1"
                />
                <Text fw={600} fz="1rem" c="#475569">
                  No active layouts
                </Text>
                <Text fw={400} fz="1rem" c="#64748B">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. 
                </Text>
              </Stack>
            )}
          </Stack>
          <Stack
            h="22rem"
            py="1.875rem"
            px="1.5rem"
            bg="#FFFFFF"
            bd="1px solid #E2E8F0"
            flex={1}
            style={{ borderRadius: '1.25rem' }}
          >
            <Flex justify="space-between">
              <Text fw={600} fz="1.125rem" c="#0F172A">
                Section level
              </Text>
              <PlusIcon
                height="1.5rem"
                width="1.5rem"
                color="#3B82F6"
                cursor="pointer"
                onClick={() =>
                  openModal({
                    type: 'addingSectionLevel',
                    size: 640,
                    title: 'Adding section level',
                    actionButtonLabel: 'Add',
                    actionButtonFn: () => {},
                    setModalContent: setControlAccessModalContent,
                  })
                }
              />
            </Flex>
            {sectionLevelData.length > 0 ? (
              <ScrollArea mb="-1.875rem" type="never">
                {sectionLevelData.map((item, index, data) => (
                  <ControlAccessCard
                    key={`section-level-${index}`}
                    title={item.title}
                    entranceTime={item.entranceTime}
                    status={item.status}
                    exitTime={item.exitTime}
                    permissionStatus={item.permissionStatus}
                    notificationStatus={item.notificationStatus}
                    reportStatus={item.reportStatus}
                    hoverable
                    style={{
                      marginBottom:
                        data.length - 1 === index ? '1.875rem' : '1rem',
                    }}
                  />
                ))}
              </ScrollArea>
            ) : (
              <Stack my="auto" align="center" gap="0.8rem">
                <ExclamationTriangleIcon
                  height="3rem"
                  width="3rem"
                  color="#CBD5E1"
                />
                <Text fw={600} fz="1rem" c="#475569">
                  No active sections
                </Text>
                <Text fw={400} fz="1rem" c="#64748B">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua. 
                </Text>
              </Stack>
            )}
          </Stack>
        </Flex>
      </Stack>
    </div>
  );
};

export default ControlAccessSettings;
