import { ReactNode } from 'react';

export interface TLCaseProps {
  value: unknown;
  children: ReactNode;
}

export const TLCase = ({ children }: TLCaseProps) => {
  return <>{children}</>;
};
