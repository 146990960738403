import { Label, Tag, Text } from 'react-konva';

import { CanvasPinProps } from '../types';

const CanvasPin = ({ x, y, color = '#3b82f6', text }: CanvasPinProps) => {
  return (
    <Label x={x} y={y}>
      <Tag
        fill={color}
        pointerDirection="down"
        pointerWidth={8}
        pointerHeight={4}
        cornerRadius={5}
        stroke="white"
        strokeWidth={1.5}
      />
      <Text
        height={25}
        width={25}
        text={text}
        letterSpacing={1}
        fontSize={10}
        fill="white"
        stroke="white"
        strokeWidth={0.5}
        align="center"
        verticalAlign="middle"
      />
    </Label>
  );
};

export default CanvasPin;
