import clsx from 'clsx';
import { Icon } from 'components/Icon/Icon';
import React from 'react';

import styles from './Pagination.module.scss';
import { usePagination, DOTS } from './usePagination';

interface PaginationProps {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className?: string;
}

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const lastPage = paginationRange[paginationRange.length - 1];

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(Math.min(currentPage + 1, lastPage as number));
  };

  const onPrevious = () => {
    onPageChange(Math.max(currentPage - 1, 1));
  };

  return (
    <ul className={clsx(styles.paginationContainer, className)}>
      <li
        className={clsx(styles.paginationItem, styles.Arrow, {
          [styles.disabled]: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <Icon icon="chevron-left" />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li
              key={DOTS + index}
              className={clsx(styles.paginationItem, styles.dots)}
            >
              ...
            </li>
          );
        }

        return (
          <li
            key={index}
            className={clsx(styles.paginationItem, {
              [styles.active]: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={clsx(styles.paginationItem, styles.Arrow, {
          [styles.disabled]: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <Icon icon="chevron-right" />
      </li>
    </ul>
  );
};

export default Pagination;
