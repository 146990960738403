import { useQueryClient } from '@tanstack/react-query';
import {
  type RowSelectionState,
  createColumnHelper,
} from '@tanstack/react-table';
import {
  TLDangerButton,
  TLNeutralButton,
  TLPrimaryButton,
} from 'components/Button';
import ButtonGroupData from 'components/ButtonGroupData/ButtonGroupData';
import type { ButtonGroupDataInterface } from 'components/ButtonGroupData/ButtonGroupData.interface';
import { InputSearch } from 'components/Input/InputSearch';
import { ModalSize, TLModal } from 'components/Modal';
import QR from 'components/QR/QR';
import TanStackTable, {
  type RowAction,
} from 'components/TanStackTable/TanStackTable';
import { useGetEventDetails } from 'hooks/event/details';
import { useFetchEventTimingLocations } from 'hooks/event/timing-location';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { formatDateTimeFull } from '../../../utils/dateFormatters';
import style from '../Events.module.css';

import { DeleteEventTimingLocationModal } from './DeleteTimingLocationModal';
import styles from './EventTimingLocations.module.css';
import TimingLocationForm from './TimingLocationForm';
import type { EventTimingLocations } from './TimingLocations.interfaces';

const ROW_LIMIT = 10;

const EventTimingLocationsTab = () => {
  const { id } = useParams();
  const [search, setSearch] = useState('');
  const queryClient = useQueryClient();

  const [page, setPage] = useState(1);

  const handlePageChange = (pageNumber: number) => setPage(pageNumber);
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [timingLocationToEdit, setTimingLocationToEdit] =
    useState<EventTimingLocations | null>(null);

  const { data: timingLocations, isLoading } = useFetchEventTimingLocations({
    eventId: id as string,
    row_limit: ROW_LIMIT,
    offset: (page - 1) * ROW_LIMIT,
    filter: search,
  });

  const { _route, data: timingLocationsData } = timingLocations || {
    _route: { returned: 0, total: 0 },
    data: [],
  };

  const selectedIds = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    },
    [],
  );

  const columnHelper = createColumnHelper<EventTimingLocations>();

  const handleDeleteClick = (row: EventTimingLocations) => {
    setTimingLocationToEdit(row);

    showDeleteTimingLocationModal();
  };

  const handleQrClick = (row: EventTimingLocations) => {
    setTimingLocationToEdit(row);
    showQRModal();
  };

  const event = useGetEventDetails(id);

  const [showDeleteTimingLocationModal, _hideDeleteTimingLocationModal] =
    useModal(() => {
      const hideDeleteTagModal = () => {
        _hideDeleteTimingLocationModal();
      };
      return (
        <DeleteEventTimingLocationModal
          id={timingLocationToEdit?._id as string}
          timinglocationIds={selectedIds}
          name={timingLocationToEdit?.name}
          layoutId={timingLocationToEdit?.eventLayout._id as string}
          eventName={event?.name}
          eventId={event?._id as string}
          closeModal={hideDeleteTagModal}
          setSelectedRows={setSelectedRows}
        />
      );
    }, [
      timingLocationToEdit?.name,
      timingLocationToEdit?._id,
      selectedIds,
      timingLocationToEdit?.eventLayout._id,
    ]);

  const [showQRModal, hideQRModal] = useModal(() => {
    const hideQr = () => {
      hideQRModal();
    };
    return (
      <TLModal
        size={ModalSize.XS}
        title={event?.name}
        onClose={hideQr}
        className={style.qrModal}
      >
        <QR
          value={timingLocationToEdit?._id as string}
          qrId={timingLocationToEdit?._id as string}
        />
      </TLModal>
    );
  }, [timingLocationToEdit?._id]);

  const timingLocationsTableRowActions = (
    row: EventTimingLocations,
  ): RowAction[] => [
    {
      icon: 'qrcode',
      key: 'assign',
      label: 'View QR',
      onClick: () => {
        handleQrClick(row);
      },
    },

    {
      icon: 'pencil',
      key: 'unassign',
      label: 'Edit info', //row.status === 'disabled' ? 'Enable' : 'Disable',
      onClick: () => {
        handleAddEventTimingLocationsClick(row);
      },
    },
    {
      icon: 'trash',
      key: 'delete',
      label: 'Delete',
      color: 'danger',
      onClick: () => handleDeleteClick(row),
    },
  ];

  const eventTimingLocationsTableColumns = [
    columnHelper.accessor('name', {
      header: 'Timing location',
      cell: ({ row }) => (
        <Link
          to={`/event/${id}/layout/${row.original.eventLayout}/section/${row.original._id}`}
          style={{ color: '#0F172A', fontSize: '14px', fontWeight: '700' }}
        >
          {row.original.name}
        </Link>
      ),
    }),
    columnHelper.accessor('eventLayout.name', {
      header: 'Layout',
    }),
    columnHelper.accessor('startDateTime', {
      header: 'Start',
      cell: ({ cell }) => {
        const value = cell.getValue();
        if (!value) {
          return 'No date';
        }
        return formatDateTimeFull(value, event?.timezone);
      },
    }),
    columnHelper.accessor('endDateTime', {
      header: 'End',
      cell: ({ cell }) => {
        const value = cell.getValue();
        if (!value) {
          return 'No date';
        }
        return formatDateTimeFull(value, event?.timezone);
      },
    }),
    columnHelper.accessor('capacity', {
      header: 'Capacity',
    }),
    columnHelper.display({
      header: 'Devices',
      cell: ({ table }) => table.options.meta?.numberOfDevices,
    }),
  ];
  const buttons: Array<ButtonGroupDataInterface> = [
    {
      text: 'All',
      text1: _route.total ? _route.total.toString() : '0',
      indicator: '',
    },
  ];

  const [showCreateTimingLocationsModal, _hideCreateTimingLocationsModal] =
    useModal(() => {
      const hideModal = () => {
        _hideCreateTimingLocationsModal();
        setTimingLocationToEdit(null);
      };

      return (
        <TLModal size={ModalSize.CUSTOM80} onClose={hideModal} hideHeader>
          <TimingLocationForm
            onClose={hideModal}
            data={timingLocationToEdit}
            isEdit={!!timingLocationToEdit}
            eventId={id}
            timezone={event?.timezone as string}
          />
        </TLModal>
      );
    }, [timingLocationToEdit]);

  const handleDeleteEventTimingLocations = () => {
    setTimingLocationToEdit(null);
    showDeleteTimingLocationModal();
  };

  const handleAddEventTimingLocationsClick = (
    eventTimingLocation: EventTimingLocations | null,
  ) => {
    setTimingLocationToEdit(eventTimingLocation);
    showCreateTimingLocationsModal();
  };

  const clearSelectedRows = () => setSelectedRows({});

  const timingLocationsTableSelectedRowsActions = (
    <>
      <TLNeutralButton leadingIcon="times" onClick={clearSelectedRows}>
        Clear selection
      </TLNeutralButton>
      <TLNeutralButton leadingIcon="arrow-down" onClick={() => {}}>
        Export
      </TLNeutralButton>

      <TLDangerButton
        leadingIcon="trash"
        onClick={handleDeleteEventTimingLocations}
        className="text-white"
      >
        Remove
      </TLDangerButton>
    </>
  );

  const timingLocationsTableActions = (
    <>
      <TLNeutralButton leadingIcon="arrow-up" onClick={() => {}}>
        Import
      </TLNeutralButton>
      <TLPrimaryButton
        leadingIcon="plus"
        onClick={() => handleAddEventTimingLocationsClick(null)}
      >
        Create timing location
      </TLPrimaryButton>
    </>
  );

  return (
    <div className={styles.table}>
      <InputSearch
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        className={styles.search}
      />
      <TanStackTable
        getRowId={(row) => row._id}
        selectedRows={selectedRows}
        onRowSelectionChange={setSelectedRows}
        columns={eventTimingLocationsTableColumns}
        pageIndex={page}
        pageSize={ROW_LIMIT}
        totalRows={_route.total}
        pageCount={Math.ceil(_route.total / ROW_LIMIT)}
        data={timingLocationsData}
        rowActions={timingLocationsTableRowActions}
        onPageChange={handlePageChange}
        headerTitle={<ButtonGroupData buttons={buttons} />}
        tableActions={
          selectedIds.length > 0
            ? timingLocationsTableSelectedRowsActions
            : timingLocationsTableActions
        }
        meta={{
          numberOfDevices: event?.allowedDevices.length,
        }}
        isLoading={isLoading}
        enableRowSelection
        showHeader
      />
    </div>
  );
};

export default EventTimingLocationsTab;
