import { TextAreaInput } from '@lanaco/lnc-react-ui';
import { InputGroup } from 'components/Input';
import { DateInputGroup } from 'components/Input/DateInputGroup';
import { useDateField } from 'components/Input/hooks/useDateField';
import { useTimeField } from 'components/Input/hooks/useTimeField';
import { TimeInputGroup } from 'components/Input/TimeInput';
import { ResponsiveRow } from 'components/utils/ResponsiveRow/ResponsiveRow';
import { useZodForm } from 'hooks/useZodForm';
import { MultiStepFormContent } from 'pages/Events/MultiStepForm/MultiStepFormContent/MultiStepFormContent';
import { MultiStepFormContentFooter } from 'pages/Events/MultiStepForm/MultiStepFormContent/MultiStepFormContentFooter/MultiStepFormContentFooter';
import { useFormContext } from 'react-hook-form';

import { useMultiStep } from '../../MultiStepForm/context/multi-step-context';
import {
  EventFormSchema,
  RegistrationInfoSchema,
  registrationInfoSchema,
} from '../../MultiStepForm/event-schema';

type Props = {
  defaultValues: RegistrationInfoSchema;
  step: number;
};

export const EventRegistrationInfoForm = ({ step, defaultValues }: Props) => {
  const { next } = useMultiStep();

  const { setValue: setContextValue } = useFormContext<EventFormSchema>();

  console.log(defaultValues);

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useZodForm<RegistrationInfoSchema>(
    {
      defaultValues,
      mode: 'onBlur',
    },
    registrationInfoSchema,
  );

  const [{ startDate, finishDate }, onDateChange] = useDateField({
    names: ['startDate', 'finishDate'],
    setValue,
    watch,
  });

  let [
    { registrationStartTime, registrationFinishTime },
    onTimeChange,
  ] = useTimeField({
    names: ['registrationStartTime', 'registrationFinishTime'],
    setValue,
    watch,
  });

  const onSubmit = (data: RegistrationInfoSchema) => {
    reset(data);
    setContextValue('registration', data);

    next();
  };

  return (
    <MultiStepFormContent step={step}>
      <InputGroup
        register={register}
        label="Registration link"
        placeholder="e.g. https://www.company.com/event"
        name="url"
        errors={errors}
      />

      <ResponsiveRow>
        <DateInputGroup
          register={register}
          name="startDate"
          label="Start date"
          placeholder="e.g. 1 May 08:00 am"
          value={startDate}
          errors={errors}
          onChange={onDateChange}
        />
        <TimeInputGroup
          name="registrationStartTime"
          label="Start time"
          placeholder=""
          errors={errors}
          value={registrationStartTime}
          onChange={onTimeChange}
          register={register}
        />
      </ResponsiveRow>
      <ResponsiveRow>
        <DateInputGroup
          register={register}
          name="finishDate"
          label="Finish date"
          placeholder="e.g. 2 May 08:00 pm"
          value={finishDate}
          withLargeErrorMessage
          errors={errors}
          onChange={onDateChange}
        />
        <TimeInputGroup
          name="registrationFinishTime"
          label="Finish time"
          placeholder=""
          errors={errors}
          value={registrationFinishTime}
          onChange={onTimeChange}
          register={register}
        />
      </ResponsiveRow>

      {/* // TODO Smece  ne radi */}
      <TextAreaInput
        {...register('metadata')}
        name="metadata"
        label="Metadata"
        placeholder={'Paste JSON metadata here'}
        maxRows={12}
        errors={errors.metadata?.message}
      />

      <MultiStepFormContentFooter
        isValid={isValid}
        onConfirm={handleSubmit(onSubmit)}
      />
    </MultiStepFormContent>
  );
};
