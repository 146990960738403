import {
  WrenchScrewdriverIcon,
  UserIcon,
  Square3Stack3DIcon,
  MapPinIcon,
  TagIcon,
  UserGroupIcon,
  UserPlusIcon,
  SignalIcon,
  CpuChipIcon,
  HomeIcon,
  ServerIcon,
  MapIcon,
} from '@heroicons/react/24/solid';
import { Grid, Box, Badge, Flex, Checkbox, Image } from '@mantine/core';
import { multiProgressColors } from 'components/MultiProgressWrapper/consts';
import DeviceIcon from 'components/SvgIcons/DeviceIcon';
import DevicePlusIcon from 'components/SvgIcons/DevicePlusIcon';
import { useEntitiesContext } from 'context/EntityProvider';
import { isArray } from 'lodash';

import { HubCardProps } from '../../types';

import CardColumnContent from './CardColumnContent';
import CardSettingsDropdown from './CardSettingsDropdown';
import CardTitle from './CardTitle';

const HubCard = ({
  id,
  cardType,
  numberOfElements,
  status,
  image,
  trackingObjectType,
  trackingObjectTypeName,
  trackingObjectName,
  trackingObjectCode,
  trackingObjectTags,
  lastSeen,
  layoutName,
  sectionName,
  maxCapacity,
  capacity,
  layoutAgeNumberRange,
  numberOfDevices,
  trafficStatus,
  macAddress,
  mapped,
  deviceStatus,
  deviceName,
  deviceType,
  tagName,
  tagCode,
  trackingObject,
  handleClickOnCard,
}: HubCardProps) => {
  let cardLogoContent;

  const {
    showHubCardCheckbox,
    selectedHubCardIds,
    setSelectedHubCardIds,
    setIndeterminateHubCheckbox,
    setSelectAllHubCard,
  } = useEntitiesContext();

  const handleCheckedCard = (id: string) => {
    setSelectedHubCardIds((prev) => {
      const isAlreadySelected = prev.some((existingId) => existingId === id);
      const updatedIds = isAlreadySelected
        ? prev.filter((existingId) => existingId !== id)
        : [...prev, id];

      if (updatedIds.length === 0) {
        setIndeterminateHubCheckbox(false);
        setSelectAllHubCard(false);
      } else if (updatedIds.length === numberOfElements) {
        setIndeterminateHubCheckbox(false);
        setSelectAllHubCard(true);
      } else {
        setIndeterminateHubCheckbox(true);
        setSelectAllHubCard(false);
      }

      return updatedIds;
    });
  };

  if (image) {
    cardLogoContent = (
      <Image
        src={image}
        alt="logo image"
        h="3.75rem"
        w="3.75rem"
        style={{ borderRadius: '0.25rem' }}
      />
    );
  } else if (cardType === 'trackingObject' || cardType === 'admission') {
    cardLogoContent =
      trackingObjectType === 'asset' ? (
        <WrenchScrewdriverIcon
          width="2rem"
          height="2rem"
          color="var(--gray-300)"
        />
      ) : (
        <UserIcon width="2rem" height="2rem" color="var(--gray-300)" />
      );
  } else {
    cardLogoContent = null;
  }

  return (
    <Flex
      align="center"
      bd={
        showHubCardCheckbox &&
        selectedHubCardIds.some((existingId) => existingId === id)
          ? '1px solid var(--blue-500)'
          : '1px solid var(--gray-200)'
      }
      bg={
        showHubCardCheckbox &&
        selectedHubCardIds.some((existingId) => existingId === id)
          ? 'var(--blue-50)'
          : 'var(--gray-0)'
      }
      style={{
        borderRadius: '0.5rem',
      }}
      onClick={handleClickOnCard}
    >
      {showHubCardCheckbox && (
        <Flex justify="center" align="center" ml={20} mr={4}>
          <Checkbox
            checked={selectedHubCardIds?.some(
              (existingId) => existingId === id,
            )}
            styles={{ input: { cursor: 'pointer' } }}
            onClick={(e) => {
              e.stopPropagation();
              handleCheckedCard(id);
            }}
          />
        </Flex>
      )}

      <Box
        w="0.25rem"
        h="3.25rem"
        style={{ borderRadius: '6.25rem' }}
        m="0.75rem"
        bg={
          status === 'online' || status === 'active'
            ? 'var(--green-500)'
            : status === 'offline' || status === 'inactive'
            ? 'var(--gray-300)'
            : status === 'idle'
            ? 'var(--yellow-500)'
            : 'var(--gray-100)'
        }
      />

      {cardLogoContent && (
        <Flex
          w="3.75rem"
          h="3.75rem"
          bg="var(--gray-200)"
          mr="1rem"
          justify="center"
          align="center"
          style={{
            borderRadius: '0.25rem',
          }}
        >
          {cardLogoContent}
        </Flex>
      )}

      <Grid flex={1} gutter="xs">
        <Grid.Col span={6}>
          {(cardType === 'trackingObject' || cardType === 'admission') &&
          trackingObjectName ? (
            <CardTitle code={trackingObjectCode} title={trackingObjectName} />
          ) : cardType === 'layout' && layoutName ? (
            <CardTitle title={layoutName} />
          ) : cardType === 'section' && sectionName ? (
            !Array.isArray(sectionName) && <CardTitle title={sectionName} />
          ) : cardType === 'devices' && deviceName ? (
            <CardTitle
              title={deviceName}
              Icon={
                deviceType === 'anthena'
                  ? SignalIcon
                  : deviceType === 'processor'
                  ? CpuChipIcon
                  : deviceType === 'switch'
                  ? ServerIcon
                  : deviceType === 'relay'
                  ? DeviceIcon
                  : deviceType === 'anthenaRelay'
                  ? DevicePlusIcon
                  : DeviceIcon
              }
            />
          ) : cardType === 'tags' && tagName ? (
            <CardTitle title={tagName} Icon={TagIcon} code={tagCode} />
          ) : null}
        </Grid.Col>
        <Grid.Col span={6}>
          <Flex mr="1.25rem" justify="flex-end" align="center">
            {(cardType === 'layout' || cardType === 'section') &&
              trafficStatus && (
                <Badge
                  variant="light"
                  color={
                    multiProgressColors.find(
                      (item) =>
                        item.type.toLowerCase() === trafficStatus.toLowerCase(),
                    )?.color
                  }
                  mr={8}
                  tt="none"
                  p="0.625rem"
                >
                  {trafficStatus}
                </Badge>
              )}
            {cardType === 'devices' &&
              (deviceStatus === 'enabled' ? (
                <Badge
                  variant="light"
                  color="#10B981"
                  mr={8}
                  tt="none"
                  p="0.625rem"
                >
                  Enabled
                </Badge>
              ) : (
                <Badge
                  variant="light"
                  color="#64748B"
                  mr={8}
                  tt="none"
                  p="0.625rem"
                >
                  Disabled
                </Badge>
              ))}
            {lastSeen && (
              <Badge
                variant="light"
                color="#3B82F6"
                mr={8}
                tt="none"
                p="0.625rem"
              >
                {lastSeen}
              </Badge>
            )}
            {cardType === 'devices' && mapped && (
              <Badge
                variant="light"
                color="#3B82F6"
                mr={8}
                tt="none"
                p="0.625rem"
              >
                <MapIcon width="1.25rem" height="1.25rem" color="#3B82F6" />{' '}
                Mapped
              </Badge>
            )}
            <CardSettingsDropdown
              backgorundColor={
                showHubCardCheckbox &&
                selectedHubCardIds.some((existingId) => existingId === id)
                  ? 'var(--blue-50)'
                  : 'var(--gray-0)'
              }
              canUnassignTag={
                Array.isArray(trackingObjectTags)
                  ? trackingObjectTags.length > 0
                  : !!trackingObjectTags
              }
              canUnassignTrackingObject={
                cardType === 'tags' && trackingObject?.objects
                  ? Array.isArray(trackingObject?.objects)
                    ? trackingObject?.objects?.length > 0
                    : !!trackingObject?.objects
                  : false
              }
              dataId={id}
              cardType={cardType}
              tagName={tagName}
              trackingObjectTags={trackingObjectTags}
              trackingObjects={trackingObject?.objects}
              trackingObjectName={trackingObjectName}
            />
          </Flex>
        </Grid.Col>

        {/* Lower row */}
        <Grid.Col span={3}>
          <Flex align="center">
            {(cardType === 'trackingObject' || cardType === 'admission') &&
            trackingObjectType ? (
              <CardColumnContent
                Icon={
                  trackingObjectType === 'asset'
                    ? WrenchScrewdriverIcon
                    : UserIcon
                }
                name={
                  trackingObjectTypeName &&
                  trackingObjectTypeName?.charAt(0).toUpperCase() +
                    trackingObjectTypeName?.slice(1)
                }
              />
            ) : (cardType === 'layout' || cardType === 'section') &&
              capacity &&
              maxCapacity ? (
              <CardColumnContent
                Icon={UserGroupIcon}
                name={capacity + '/' + maxCapacity}
              />
            ) : (cardType === 'devices' || cardType === 'tags') &&
              macAddress ? (
              <CardColumnContent Icon={HomeIcon} name={macAddress} />
            ) : null}
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex align="center" justify="left">
            {(cardType === 'trackingObject' || cardType === 'devices') &&
            sectionName &&
            sectionName.length > 0 ? (
              <CardColumnContent
                Icon={MapPinIcon}
                name={sectionName}
                tooltipTitle="Sections"
              />
            ) : (cardType === 'layout' || cardType === 'section') &&
              numberOfDevices ? (
              <CardColumnContent
                Icon={DeviceIcon}
                name={numberOfDevices.toString()}
              />
            ) : cardType === 'tags' && trackingObject ? (
              <CardColumnContent
                Icon={
                  trackingObject?.type === 'person'
                    ? UserIcon
                    : WrenchScrewdriverIcon
                }
                name={trackingObject.names}
                tooltipTitle="Tracking objects"
              />
            ) : null}
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex align="center" justify="left">
            {(cardType === 'trackingObject' || cardType === 'tags') &&
            layoutName ? (
              <CardColumnContent Icon={Square3Stack3DIcon} name={layoutName} />
            ) : null}
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex align="center" justify="right">
            {(cardType === 'trackingObject' || cardType === 'admission') &&
            trackingObjectTags ? (
              <CardColumnContent
                Icon={TagIcon}
                name={
                  isArray(trackingObjectTags)
                    ? trackingObjectTags?.map((x) => x.number)
                    : trackingObjectTags.number
                }
                tooltipTitle="Tags"
                lastColumn={true}
              />
            ) : cardType === 'layout' && layoutAgeNumberRange ? (
              <CardColumnContent
                Icon={UserPlusIcon}
                name={layoutAgeNumberRange}
                lastColumn={true}
              />
            ) : cardType === 'section' || cardType === 'devices' ? (
              <CardColumnContent
                Icon={Square3Stack3DIcon}
                name={layoutName}
                lastColumn={true}
              />
            ) : cardType === 'tags' ? (
              <CardColumnContent
                Icon={MapPinIcon}
                name={sectionName}
                lastColumn={true}
              />
            ) : null}
          </Flex>
        </Grid.Col>
      </Grid>
    </Flex>
  );
};

export default HubCard;
