import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { CheckIcon } from '@heroicons/react/24/solid';
import { Group, Select as MantineSelect, SelectProps } from '@mantine/core';

const Select = ({ ...props }: SelectProps) => {
  const renderSelectOption: SelectProps['renderOption'] = ({
    option,
    checked,
  }) => (
    <Group flex={1}>
      {option.label}
      {checked && (
        <CheckIcon
          height="1.25rem"
          width="1.25rem"
          color="#3B82F6"
          style={{ marginInlineStart: 'auto' }}
        />
      )}
    </Group>
  );

  return (
    <MantineSelect
      renderOption={renderSelectOption}
      withCheckIcon={false}
      allowDeselect={false}
      rightSection={
        <ChevronDownIcon height="1.25rem" width="1.25rem" color="#0F172A" />
      }
      comboboxProps={{ dropdownPadding: 0, offset: 0 }}
      {...props}
    />
  );
};

export default Select;
