import { FormField } from '@lanaco/lnc-react-ui';
import { FlexGrid } from '@lanaco/lnc-react-ui';
import { FlexGridItem } from '@lanaco/lnc-react-ui';
import { TextInput } from '@lanaco/lnc-react-ui';
import { Dropdown } from '@lanaco/lnc-react-ui';
import { FlexBox } from '@lanaco/lnc-react-ui';
import { notification } from '@lanaco/lnc-react-ui';
import SubmitModal from 'components/SubmitModal/SubmitModal';
import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { client } from 'services/api';
import { INSERT_USER, UPDATE_USER } from 'utils/apiUrl';

import { IFormInput } from './interfaces';
import styles from './Settings.module.css';
type Props = {
  closeModal: any;
  data: any;
  isEdit: boolean;
};

const UserForm = (props: Props) => {
  // const [size, setSize] = useState('fluid');
  // let postData;
  const [spinner, setSpinner] = useState(false);

  console.log(props.data);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      setSpinner(true);

      if (props.isEdit) {
        const response = await client.put(UPDATE_USER(data.id), data);
        if (response.data.code === 201) {
          setSpinner(false);
          props.closeModal();
          notification.success('User successfully updated.');
        } else {
          setSpinner(false);
          notification.error('Unable to update user. ' + response.data.message);
        }
      } else {
        const response = await client.post(INSERT_USER, data);

        if (response.data.code === 201) {
          setSpinner(false);
          props.closeModal();
          notification.success('User successfully added');
        } else {
          setSpinner(false);
          notification.error('Unable to add user. ' + response.data.message);
        }
      }
    } catch (error) {
      setSpinner(false);
      notification.error(error);
    }
  };

  return (
    <div>
      <span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexGrid spacing={10}>
            <FlexGridItem XS={12} S={6} className={styles.labelText}>
              <FormField
                label="First name"
                text={
                  errors.firstName && <span>{errors.firstName.message}</span>
                }
                color={errors.firstName && 'danger'}
              >
                <TextInput
                  placeholder={'John'}
                  {...register('firstName', {
                    required: {
                      value: true,
                      message: 'Field first name is requied.',
                    },
                  })}
                  color={errors.firstName && 'danger'}
                  value={props.data && props.data.firstName}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6} className={styles.labelText}>
              <FormField
                label="Last name"
                text={errors.lastName && <span>{errors.lastName.message}</span>}
                color={errors.lastName && 'danger'}
              >
                <TextInput
                  placeholder={'John'}
                  {...register('lastName', {
                    required: {
                      value: true,
                      message: 'Field lasr name is requied.',
                    },
                  })}
                  color={errors.lastName && 'danger'}
                  value={props.data && props.data.lastName}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6} className={styles.labelText}>
              <FormField
                label="Email"
                text={errors.email && <span>{errors.email.message}</span>}
                color={errors.email && 'danger'}
              >
                <TextInput
                  placeholder={'John'}
                  {...register('email', {
                    required: {
                      value: true,
                      message: 'Field email is required.',
                    },
                  })}
                  color={errors.email && 'danger'}
                  value={props.data && props.data.email}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6} className={styles.labelText}>
              <FormField
                label="Password"
                text={errors.password && <span>{errors.password.message}</span>}
                color={errors.password && 'danger'}
              >
                <TextInput
                  placeholder={'John'}
                  {...register('password', {
                    required: {
                      value: true,
                      message: 'Field password is requied.',
                    },
                  })}
                  color={errors.password && 'danger'}
                  value={props.data && props.data.password}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6} className={styles.labelText}>
              <FormField
                label="Username"
                text={errors.userName && <span>{errors.userName.message}</span>}
                color={errors.userName && 'danger'}
              >
                <TextInput
                  placeholder={'John'}
                  {...register('userName', {
                    required: {
                      value: true,
                      message: 'Field userName is requied.',
                    },
                  })}
                  color={errors.userName && 'danger'}
                  value={props.data && props.data.userName}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6} className={styles.labelText}>
              <FormField
                label="Phone number"
                text={
                  errors.phoneNumber && (
                    <span>{errors.phoneNumber.message}</span>
                  )
                }
                color={errors.phoneNumber && 'danger'}
              >
                <TextInput
                  placeholder={'John'}
                  {...register('phoneNumber', {
                    required: {
                      value: true,
                      message: 'Field phone number is requied.',
                    },
                  })}
                  color={errors.phoneNumber && 'danger'}
                  value={props.data && props.data.phoneNumber}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6}>
              <FormField label="Gender">
                <Dropdown
                  {...register('gender')}
                  placeholder={'John'}
                  options={[
                    {
                      value: '1',
                      label: 'option 1',
                    },
                    {
                      value: '2',
                      label: 'option 2',
                    },
                    {
                      value: '3',
                      label: 'option 3',
                    },
                  ]}
                />
              </FormField>
            </FlexGridItem>

            <FlexGridItem XS={12} S={6}>
              <FormField label="Status">
                <Dropdown
                  {...register('accountStatus')}
                  placeholder={'John'}
                  options={[
                    {
                      value: '1',
                      label: 'option 1',
                    },
                    {
                      value: '2',
                      label: 'option 2',
                    },
                    {
                      value: '3',
                      label: 'option 3',
                    },
                  ]}
                />
              </FormField>
            </FlexGridItem>

            <FlexGridItem col={12}>
              <FlexBox justifyContent={'End'}>
                <SubmitModal
                  closeModal={props.closeModal}
                  spinner={spinner}
                  buttonText={'Add'}
                ></SubmitModal>
              </FlexBox>
            </FlexGridItem>
          </FlexGrid>
        </form>
      </span>
    </div>
  );
};
export default UserForm;
