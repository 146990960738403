import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventDeviceService } from 'services/event/device/EventDeviceService';

import { eventDeviceBaseKey } from './baseKeys';

interface DeleteDeviceProps {
  eventId: string;
  deviceId: string;
}

export const useDeleteDevice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ eventId, deviceId }: DeleteDeviceProps) =>
      await EventDeviceService.deleteEventDevice(eventId, deviceId),
    onSuccess: () => {
      queryClient.invalidateQueries([eventDeviceBaseKey]);
      // queryClient.invalidateQueries([deviceStatisticsBaseKey]);
    },
  });
};
