import { FaceFrownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { NotFoundCodeComponentProps } from '../types';

const NotFoundCodeComponent = ({
  code,
  handleClearQrCode,
}: NotFoundCodeComponentProps) => {
  const isMobile = useMediaQuery('(max-width: 1024px)');

  return (
    <Stack
      align="center"
      justify="center"
      h={isMobile ? '10.5rem' : '15.594rem'}
      maw="100%"
      ta="center"
      bg="#F8FAFC"
      style={{
        border: '1px solid var(--gray-200)',
        borderRadius: '0.75rem',
        position: 'relative',
        maxWidth: '100%',
        padding: isMobile ? '1rem' : '1.5rem',
      }}
    >
      <>
        <XMarkIcon
          height={isMobile ? '1.25rem' : '1.5rem'}
          width={isMobile ? '1.25rem' : '1.5rem'}
          color="var(--gray-900)"
          strokeWidth={1.5}
          style={{
            position: 'absolute',
            top: isMobile ? '0.25rem' : '0.5rem',
            right: isMobile ? '0.25rem' : '0.5rem',
            cursor: 'pointer',
          }}
          onClick={handleClearQrCode}
        />
        <FaceFrownIcon
          height={isMobile ? '2.5rem' : '3.125rem'}
          width={isMobile ? '2.5rem' : '3.125rem'}
          color="var(--gray-300)"
          strokeWidth={0.8}
        />
        <Stack align="center" gap={1}>
          <Text fz={isMobile ? 14 : 16} fw={600} c="var(--gray-600)">
            Not found!
          </Text>
          <Text
            fz={isMobile ? 14 : 16}
            fw={600}
            mb={isMobile ? '0.5rem' : '0.75rem'}
            c="var(--gray-600)"
            style={{
              wordBreak: 'break-word',
              maxWidth: '90%',
              whiteSpace: 'normal',
              overflowWrap: 'break-word',
            }}
          >
            {`“${code}”`}
          </Text>
          <Text fz={isMobile ? 12 : 14} fw={400} c="var(--gray-500)">
            Selected code was not found in our database, please choose type
            below.
          </Text>
        </Stack>
      </>
    </Stack>
  );
};

export default NotFoundCodeComponent;
