import { TagIcon } from '@heroicons/react/16/solid';
import { PlusIcon, ViewfinderCircleIcon } from '@heroicons/react/24/solid';
import { Flex, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { UnknownAdmissionCardProps } from '../types';

const UnknownAdmissionCard = ({
  isTagCard,
  handleAddUnknownCode,
}: UnknownAdmissionCardProps) => {
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const iconSize = isMobile ? '2.5rem' : '3.125rem';
  const plusIconSize = isMobile ? '3rem' : '3.75rem';
  const textSize = isMobile ? 12 : 14;
  const subTextSize = isMobile ? 10 : 12;
  const containerHeight = isMobile ? '5rem' : '6.25rem';

  return (
    <Stack
      align="center"
      justify="center"
      h={containerHeight}
      ta="center"
      bg="var(--blue-50)"
      style={{
        border: '2px dashed var(--blue-200)',
        borderRadius: '0.75rem',
        cursor: 'pointer',
      }}
      mt="1.5rem"
      onClick={() => handleAddUnknownCode(isTagCard)}
    >
      <Flex
        align="center"
        gap={isMobile ? 4 : 6}
        direction="row"
        justify="space-between"
        w="100%"
      >
        <Flex align="center" gap={isMobile ? 2 : 4} ml="1rem">
          {isTagCard ? (
            <TagIcon
              height={iconSize}
              width={iconSize}
              color="var(--blue-500)"
            />
          ) : (
            <ViewfinderCircleIcon
              height={iconSize}
              width={iconSize}
              color="var(--blue-500)"
            />
          )}
          <Flex
            direction="column"
            gap={isMobile ? 4 : 8}
            align="flex-start"
            ml="0.5rem"
          >
            <Text inline fz={textSize} fw={500} c="var(--blue-500)">
              {isTagCard ? 'Add as tag' : 'Add as tracking object'}
            </Text>
            <Text inline fz={subTextSize} fw={400} c="var(--blue-400)">
              Press to add
            </Text>
          </Flex>
        </Flex>

        <PlusIcon
          height={plusIconSize}
          width={plusIconSize}
          color="var(--blue-200)"
          style={{ marginRight: '1rem' }}
        />
      </Flex>
    </Stack>
  );
};

export default UnknownAdmissionCard;
