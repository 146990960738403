import { Grid, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useViewportSize } from '@mantine/hooks';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import { identity, pickBy } from 'lodash';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useEffect } from 'react';
import { useEntity } from 'services/entity/home';

import {
  CreateAndUpdateEntityReq,
  CreateAndUpdateEntityThirdStep,
} from '../types';

const EntitySocialMediaContent = ({
  setFormState,
  dataId,
  formState,
}: ModalFormProps) => {
  const { height } = useViewportSize();
  const form = useForm<CreateAndUpdateEntityThirdStep>({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      facebookUrl: formState?.socials?.facebook ?? '',
      instagramUrl: formState?.socials?.instagram ?? '',
      linkedinUrl: formState?.socials?.linkedin ?? '',
      tiktokUrl: formState?.socials?.tikTok ?? '',
      twitterUrl: formState?.socials?.twitter ?? '',
      youtubeUrl: formState?.socials?.youtube ?? '',
    },
  });
  const { data: eventData } = useEntity(dataId ?? '');

  useEffect(() => {
    if (eventData) {
      form.setFieldValue(
        'facebookUrl',
        eventData?.socials?.facebook ? eventData?.socials?.facebook : '',
      );
      form.setFieldValue(
        'instagramUrl',
        eventData?.socials?.instagram ? eventData?.socials?.instagram : '',
      );
      form.setFieldValue(
        'linkedinUrl',
        eventData?.socials?.linkedin ? eventData?.socials?.linkedin : '',
      );
      form.setFieldValue(
        'tiktokUrl',
        eventData?.socials?.tikTok ? eventData?.socials?.tikTok : '',
      );
      form.setFieldValue(
        'twitterUrl',
        eventData?.socials?.twitter ? eventData?.socials?.twitter : '',
      );
      form.setFieldValue(
        'youtubeUrl',
        eventData?.socials?.youtube ? eventData?.socials?.youtube : '',
      );
    }
  }, [eventData]);

  useEffect(() => {
    const newFormState = form.values;
    const thirdStep: Partial<CreateAndUpdateEntityReq> = {
      socials: {
        facebook: newFormState.facebookUrl,
        instagram: newFormState.instagramUrl,
        linkedin: newFormState.linkedinUrl,
        tikTok: newFormState.tiktokUrl,
        twitter: newFormState.twitterUrl,
        youtube: newFormState.youtubeUrl,
      },
    };
    if (setFormState) {
      const cleandedData = pickBy(thirdStep, identity);
      setFormState((prevState) => ({
        ...prevState,
        socials: cleandedData?.socials ?? null,
      }));
    }
  }, [form.values, setFormState]);

  return (
    <ScrollArea.Autosize
      mah={height - 12.75 * 24}
      mr="-1rem"
      variant="thumb"
      scrollbarSize={6}
      styles={{ root: { paddingRight: '0.5rem' } }}
    >
      <Grid>
        <Grid.Col span={12}>
          <TextInput
            placeholder="Paste Facebook link here"
            label="Facebook URL"
            key={form.key('facebookUrl')}
            {...form.getInputProps('facebookUrl')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            placeholder="Paste Instagram link here"
            label="Instagram URL"
            key={form.key('instagramUrl')}
            {...form.getInputProps('instagramUrl')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            placeholder="Paste LinkedIn link here"
            label="LinkedIn URL"
            key={form.key('linkedinUrl')}
            {...form.getInputProps('linkedinUrl')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            placeholder="Paste TikTok link here"
            label="TikTok URL"
            key={form.key('tiktokUrl')}
            {...form.getInputProps('tiktokUrl')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            placeholder="Paste Twitter link here"
            label="Twitter URL"
            key={form.key('twitterUrl')}
            {...form.getInputProps('twitterUrl')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            placeholder="Paste YouTube link here"
            label="YouTube URL"
            key={form.key('youtubeUrl')}
            {...form.getInputProps('youtubeUrl')}
          />
        </Grid.Col>
      </Grid>
    </ScrollArea.Autosize>
  );
};

export default EntitySocialMediaContent;
