import z from 'zod';

import { PHONE_REGEX, POSTAL_CODE_REGEX } from 'constants/regex';
const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
export const detailsSchema = z
  .object({
    name: z.string().nonempty({ message: 'Name is required' }),
    type: z.string().nonempty({ message: 'Type is required' }),
    startDateTime: z.date().optional().nullable(),
    startTime: z.string(),
    endDateTime: z.date().optional().nullable(),
    endTime: z.string(),
    capacity: z.any(),
  })
  .superRefine((schema, ctx) => {
    const startDate = schema.startDateTime;
    const finishDate = schema.endDateTime;
    if (startDate && finishDate) {
      const isStartDateGreater = startDate.getTime() > finishDate.getTime();

      if (isStartDateGreater) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Start date can not be greater then finish date',
          path: ['finishDate'],
          fatal: true,
        });
        return z.NEVER;
      }
    }
  });

export type DetailsSchema = z.infer<typeof detailsSchema>;

// export const positionSchema = z.object({
//   // layout: z.string().nonempty('Layout is required'),
//   // picture: z
//   //   .custom<File | string | null>(
//   //     (value) =>
//   //       value === null || value instanceof File || typeof value === 'string',
//   //   )
//   //   .superRefine((value, ctx) => {
//   //     if (typeof value === 'string') {
//   //       return value;
//   //     }

//   //     if (!value) {
//   //       ctx.addIssue({
//   //         code: z.ZodIssueCode.custom,
//   //         message: 'Please upload a file',
//   //         fatal: true,
//   //       });
//   //     }

//   //     return z.NEVER;
//   //   })
//   //   .refine(
//   //     (value) => (value as File).size <= MAX_FILE_SIZE_5MB,
//   //     `File size should be less than 5mb.`,
//   //   )
//   //   .refine(
//   //     (value) => ACCEPTED_IMAGE_TYPES.includes((value as File).type),
//   //     'Only .jpeg and .png types are allowed',
//   //   ),

//   layout: z.string().nonempty('Layout is required'),
//   picture: z
//     .custom<File | string | null>(
//       (value) =>
//         value === null || value instanceof File || typeof value === 'string',
//     )
//     .refine((value) => !!value, 'Please upload a file'),
// });

export const positionSchema = z.object({
  layout: z.string().nonempty('Layout is required'),
  coordinates: z
    .object({
      lat: z.number().optional(),
      lng: z.number().optional(),
    })
    .optional(),
});

export type PositionSchema = z.infer<typeof positionSchema>;

export const organisationInfoSchema = z.object({
  name: z.string(),
  description: z.string(),
  email: z
    .string()
    .optional()
    .refine(
      (value) => {
        if (!value) return true;
        return emailRegex.test(value);
      },
      {
        message: 'Invalid email format',
      },
    ),

  phoneNumber: z
    .string()
    .optional()
    .refine(
      (value) => {
        if (!value) return true;
        return PHONE_REGEX.test(value);
      },
      {
        message: 'Invalid phone number format',
      },
    ),
  website: z
    .string()
    .optional()
    .refine((url) => !url || urlRegex.test(url), {
      message: 'Invalid URL format',
    }),
  country: z.string(),

  postalCode: z
    .string()
    .optional()
    .refine(
      (value) => {
        if (value !== null && value !== undefined && value !== '') {
          return POSTAL_CODE_REGEX.test(value);
        }
        return true;
      },
      {
        message: 'Invalid postal code format',
      },
    ),
  address: z.string(),
});

export type OrganisationInfoSchema = z.infer<typeof organisationInfoSchema>;

export const organisationSocialMediaSchema = z.object({
  facebook: z.string().url().or(z.literal('')),
  instagram: z.string().url().or(z.literal('')),
  linkedin: z.string().url().or(z.literal('')),
  tikTok: z.string().url().or(z.literal('')),
  twitter: z.string().url().or(z.literal('')),
  youtube: z.string().url().or(z.literal('')),
});

export type OrganisationSocialMediaSchema = z.infer<
  typeof organisationSocialMediaSchema
>;

export const contactPersonSchema = z.object({
  name: z.string(),
  position: z.string(),
  email: z.string().email().or(z.literal('')),
  // email: z
  //   .string()
  //   .optional()
  //   .refine(
  //     (value) => {
  //       if (!value) return true;
  //       return emailRegex.test(value);
  //     },
  //     {
  //       message: 'Invalid email format',
  //     },
  //   ),

  phoneNumber: z
    .string()
    .regex(PHONE_REGEX, {
      message: 'Invalid phone number format',
    })
    .or(z.literal('')),
});

export type ContactPersonSchema = z.infer<typeof contactPersonSchema>;

export const createTimingLocationSchema = z.object({
  details: detailsSchema,
  position: positionSchema,
  organisationInfo: organisationInfoSchema,
  organisationSocialMedia: organisationSocialMediaSchema,
  contactPerson: contactPersonSchema,
});

export type CreateTimingLocationSchema = z.infer<
  typeof createTimingLocationSchema
>;

export const timingLocationPayloadSchema = z.object({
  name: z.string(),
  capacity: z.number(),
  startDateTime: z.date().optional().nullable(),
  endDateTime: z.date().optional().nullable(),
  layout: z.string(),
  coordinates: z.object({
    lat: z.number(),
    lng: z.number(),
  }),
  sectionForInformation: z.object({
    name: z.string(),
    description: z.string(),
    socialMedia: z.array(
      z.object({
        orderNumber: z.number(),
        code: z.string(),
        title: z.string(),
        shortDescription: z.string(),
        url: z.string(),
      }),
    ),
    website: z.string(),
    email: z.string(),
    phoneNumber: z.string(),
    country: z.string(),
    address: z.string(),
    postalCode: z.string(),
    contactPerson: z.array(
      z.object({
        fullName: z.string(),
        position: z.string(),
        phoneNumber: z.string(),
        email: z.string(),
      }),
    ),
  }),
});

export type TimingLocationPayload = z.infer<typeof timingLocationPayloadSchema>;
