import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ClientService } from 'services/client';
import { UpdateClientStatusPayload } from 'types/client';

import { clientBaseKey } from './baseKeys';

interface UpdateStatusClientProps {
  id: string;
  payload: UpdateClientStatusPayload;
}

export const useMutateStatusClient = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, payload }: UpdateStatusClientProps) =>
      (await ClientService.updateClientStatus(id, payload)).data,
    onSuccess: () => {
      queryClient.invalidateQueries([clientBaseKey]);
    },
  });
};
