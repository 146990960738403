import { notification } from '@lanaco/lnc-react-ui';
import { RowSelectionState } from '@tanstack/react-table';
import PromptModal from 'components/PromptModal/PromptModal';
import { useFetchEventDetails } from 'hooks/event/details';
import { useFetchEventLayouts } from 'hooks/event/layout';
import { useDeleteEventLayout } from 'hooks/event/layout/useDeleteEventLayout';
import { useState } from 'react';
import { getErrorMsg } from 'utils/getErrorMsg';

import { EventLayout } from '../interfaces';

interface DeleteEventLayoutModalProps {
  eventId: string;
  eventLayoutId: string;
  layoutIds: string[];
  closeModal: () => void;
  onEventLayoutEdit: (eventLayout: EventLayout | null) => void;
  setSelectedRows: (selectedRows: RowSelectionState) => void;
}

export const DeleteEventLayoutModal = ({
  eventId,
  eventLayoutId,
  layoutIds,
  closeModal,
  onEventLayoutEdit,
  setSelectedRows,
}: DeleteEventLayoutModalProps) => {
  const { mutate, isLoading } = useDeleteEventLayout();
  const [confirmedFirstStep, setConfirmedFirstStep] = useState(false);

  const { data: eventData } = useFetchEventDetails(eventId);

  const { data } = useFetchEventLayouts({
    eventId: eventId,
  });

  const filteredLayout = data?.data.filter(
    (item) => item._id === eventLayoutId,
  );

  const layoutName = filteredLayout && filteredLayout[0]?.name;

  const hideDeleteModal = () => {
    closeModal();
    onEventLayoutEdit(null);
  };

  const handleConfirmedFirstStep = () => {
    setConfirmedFirstStep(true);
  };
  const confirmDeleteEventLayout = async () => {
    mutate(
      {
        eventId: eventId,
        eventLayoutId: eventLayoutId,
        layoutIds: layoutIds,
      },
      {
        onSuccess: (data) => {
          if (data.status === 201) {
            if (eventLayoutId === undefined) {
              notification.success('Event Layouts successfully deleted.');
            } else {
              notification.success('Event Layout successfully deleted.');
            }
          } else {
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          hideDeleteModal();
          setSelectedRows({});
        },
      },
    );
  };

  return (
    <>
      {!confirmedFirstStep ? (
        <PromptModal
          title="Warning!?"
          isLoading={isLoading}
          warningMessage={
            <>
              This can result in problems with existing timing locations and
              cannot be undone.
            </>
          }
          confirmButtonName="Proceed"
          icon="exclamation-triangle"
          iconSize="24"
          iconColor="danger"
          isDeleteButton
          onConfirm={handleConfirmedFirstStep}
          onCancel={hideDeleteModal}
        />
      ) : (
        <PromptModal
          title={
            eventLayoutId === undefined ? 'Delete layouts?' : 'Delete layout?'
          }
          isLoading={isLoading}
          warningMessage={
            eventLayoutId === undefined ? (
              <>
                Are you sure you want to delete {layoutIds?.length}
                <br />
                layouts from event from the database?
              </>
            ) : (
              <>
                Are you sure you want to delete layout ${layoutName} from $
                {eventData?.name}?
              </>
            )
          }
          confirmButtonName="Delete"
          icon="trash"
          iconSize="24"
          iconColor="gray"
          isDeleteButton
          onConfirm={confirmDeleteEventLayout}
          onCancel={() => setConfirmedFirstStep(false)}
        />
      )}
    </>
  );
};
