import type { CreateEventTimingLocationsDevices } from 'pages/Events/TimingLocations/TimingLocations.interfaces';
import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import type { APIAxiosResponse } from 'services/types';
import { CREATE_EVENT_TIMING_LOCATION_ASSIGNABLE_DEVICES } from 'utils/apiUrl';

export class TimingLocationAssignableDevicesService extends ResponseInterceptor {
  //   static async addAssignableDevices(
  //     eventId: string,
  //     layoutId: string,
  //     sectionId: string,
  //     body: Array<CreateEventTimingLocationsDevices>,
  //   ): Promise<APIAxiosResponse<void>> {
  //     const res = await client.post(
  //       CREATE_EVENT_TIMING_LOCATION_ASSIGNABLE_DEVICES(
  //         eventId,
  //         layoutId,
  //         sectionId,
  //       ),
  //       {
  //         body: body,
  //       },
  //     );
  //     return this.handleResponse(res);
  //   }
  // }

  static async addAssignableDevices(
    eventId: string,
    layoutId: string,
    sectionId: string,
    gatings: CreateEventTimingLocationsDevices[],
  ): Promise<APIAxiosResponse<void>> {
    const res = await client.post(
      CREATE_EVENT_TIMING_LOCATION_ASSIGNABLE_DEVICES(
        eventId,
        layoutId,
        sectionId,
      ),
      gatings,
      {
        headers: {
          'Content-Type': 'application/json', // Dodatno: postavi Content-Type na application/json
        },
      },
    );
    return TimingLocationAssignableDevicesService.handleResponse(res);
  }
}
