import { NotificationContainer, ThemeProvider } from '@lanaco/lnc-react-ui';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider as MantineModalProvider } from '@mantine/modals';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { APIProvider as GoogleMapsAPIProvider } from '@vis.gl/react-google-maps';
import { LanacoTimeline } from 'assets/theme/timelineTheme.jsx';
import MainRouter from 'components/Router/MainRouter';
import { EntityProvider } from 'context/EntityProvider';
import { FilterProvider } from 'context/FilterProvider';
import { ModalProvider } from 'react-modal-hook';
import { BrowserRouter } from 'react-router-dom';
import './assets/css/fontawesome.css';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from 'ScrollToTop';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  },
});

function App() {
  return (
    <div className="App">
      <GoogleMapsAPIProvider
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
        libraries={['places', 'drawing']}
      >
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ToastContainer />
            <EntityProvider>
              <FilterProvider>
                <MantineProvider>
                  <MantineModalProvider>
                    <ThemeProvider theme={LanacoTimeline}>
                      <NotificationContainer
                        position={'top-center'}
                        autoClose={3000}
                        color={'primary'}
                        closeButton={false}
                      />
                      <ModalProvider>
                        <ScrollToTop />
                        <MainRouter></MainRouter>
                      </ModalProvider>
                    </ThemeProvider>
                  </MantineModalProvider>
                </MantineProvider>
              </FilterProvider>
            </EntityProvider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </BrowserRouter>
      </GoogleMapsAPIProvider>
    </div>
  );
}

export default App;
