import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { ActionIcon, Box, Button, Flex, Modal, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useEntitiesContext } from 'context/EntityProvider';
import { useState } from 'react';
import { ModalWidth } from 'utils/consts';

import { typeMap } from './consts';
import DeleteModal from './DeleteModal';
import { SingleStepModalProps } from './types';

const SingleStepModal = ({
  dataId,
  type = '',
  title,
  titleIcon = null,
  titleDescription,
  isCard = false,
  currentCardIndex = 0,
  cardsArray = [],
  hasHeader = true,
  hasFooter = true,
  actionButtonLabel = 'Save',
  actionButtonFn = () => {},
  removeButtonFn,
  closeButtonLabel = 'Cancel',
  closeButtonFn,
  content,
  setModalContent,
}: SingleStepModalProps) => {
  const [cardIndex, setCardIndex] = useState<number>(currentCardIndex);
  const [formState, setFormState] = useState<any>({});
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [onFormSubmit, setOnFormSubmit] = useState<
    (formStateData?: any) => void
  >(() => {});
  const [
    opened,
    { open: openConfirmModal, close: closeConfirmModal },
  ] = useDisclosure();

  const { breadcrumbs } = useEntitiesContext();

  return (
    <>
      <Modal
        size={ModalWidth.Small}
        withCloseButton={false}
        centered
        opened={opened}
        onClose={closeConfirmModal}
        zIndex={300}
      >
        <DeleteModal
          variant="warning"
          title={`Remove ${typeMap[type]}?`}
          body={
            <>
              Are you sure you want to delete{' '}
              <b>
                {cardsArray[cardIndex]?.trackingObjectName ||
                  cardsArray[cardIndex]?.layoutName}
              </b>{' '}
              {typeMap[type]} from{' '}
              <b>{breadcrumbs.at(1)?.title || 'database'}</b>?
            </>
          }
          actionButtonFn={() => {
            removeButtonFn?.(cardsArray[cardIndex]?.id);
            closeButtonFn?.();
          }}
          closeButtonFn={closeConfirmModal}
        />
      </Modal>

      <Flex direction="column" gap="2rem">
        {hasHeader && (
          <Box>
            <Flex justify="space-between" align="center">
              <Flex align="center" gap="0.75rem">
                {titleIcon}
                <Text fz="1.25rem" fw={600} c="#0f172a">
                  {cardsArray[cardIndex]?.trackingObjectName ||
                    cardsArray[cardIndex]?.layoutName ||
                    title}
                </Text>
              </Flex>

              <ActionIcon bg="transparent" onClick={closeButtonFn}>
                <XMarkIcon height="1.25rem" width="1.25rem" color="#475569" />
              </ActionIcon>
            </Flex>

            <Text fz="0.875rem" c="#475569">
              {titleDescription}
            </Text>
          </Box>
        )}

        <Box>
          {content
            ? content
            : setModalContent
            ? setModalContent({
                dataId: cardsArray[cardIndex]?.id || dataId,
                type,
                card: isCard,
                formState,
                setFormState,
                isFormValid,
                setIsFormValid,
                setOnFormSubmit,
              })
            : 'Modal has no content. Pass it via the "content" or "setModalContent" props'}
        </Box>

        {hasFooter &&
          (isCard ? (
            <Flex justify="space-between" align="center">
              <Flex gap="1rem">
                <ActionIcon
                  h="2.25rem"
                  w="2.25rem"
                  bg="#ffffff"
                  c="#0f172a"
                  bd="1px solid #e2e8f0"
                  disabled={cardIndex === 0}
                  onClick={() => setCardIndex((prev) => prev - 1)}
                >
                  <ChevronLeftIcon height="1.25rem" width="1.25rem" />
                </ActionIcon>
                <ActionIcon
                  h="2.25rem"
                  w="2.25rem"
                  bg="#ffffff"
                  c="#0f172a"
                  bd="1px solid #e2e8f0"
                  disabled={cardsArray.length - 1 === cardIndex}
                  onClick={() => {
                    setCardIndex((prev) => prev + 1);
                  }}
                >
                  <ChevronRightIcon height="1.25rem" width="1.25rem" />
                </ActionIcon>
              </Flex>

              <Flex gap="1rem">
                <Button
                  bg="#ffffff"
                  c="#0f172a"
                  bd="1px solid #e2e8f0"
                  onClick={() => {
                    actionButtonFn();
                  }}
                >
                  {actionButtonLabel}
                </Button>
                <ActionIcon
                  h="2.25rem"
                  w="2.25rem"
                  bg="#ffffff"
                  c="#f43f5e"
                  bd="1px solid #e2e8f0"
                  onClick={openConfirmModal}
                >
                  <TrashIcon height="1.25rem" width="1.25rem" />
                </ActionIcon>
              </Flex>
            </Flex>
          ) : (
            <Flex justify="flex-end" gap="1rem">
              <Button
                bg="#ffffff"
                bd="1px solid #e2e8f0"
                c="#344155"
                onClick={closeButtonFn}
              >
                {closeButtonLabel}
              </Button>
              <Button
                bg="#3b82f6"
                c="#ffffff"
                disabled={!isFormValid}
                onClick={() => {
                  onFormSubmit(formState);
                  actionButtonFn();
                  closeButtonFn?.();
                }}
              >
                {actionButtonLabel}
              </Button>
            </Flex>
          ))}
      </Flex>
    </>
  );
};

export default SingleStepModal;
