import { Checkbox, Flex } from '@mantine/core';
import ButtonGroupData from 'components/ButtonGroupData/ButtonGroupData';
import { ButtonGroupDataInterface } from 'components/ButtonGroupData/ButtonGroupData.interface';
import { useFetchEventBoard } from 'hooks/event/board/useFetchEventNotifications';
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_TableInstance,
  MRT_PaginationState,
} from 'mantine-react-table';
import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getSocket } from '../../../services/socket';
import style from '../Board/EventBoard.module.css';

import { EventNotification } from './EventBoard.interface';
import {
  EmitEvents,
  EmitEventsMap,
  ListenEvents,
  ListenEventsMap,
  acknowledgeNotification,
  markNotificationsAsSeen,
  unacknowledgeNotification,
} from './EventBoardSocketNotificationRoom';
import { notificationTableColumns } from './EventBoardTableColumns';

type Props = {};

const EventBoard = (_: Props) => {
  const { id: eventId } = useParams<{ id: string }>();
  const tableRef = useRef<MRT_TableInstance<EventNotification>>();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 1,
    pageSize: 50,
  });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [type, setType] = useState<'live' | 'muted'>('live');
  const [update, forceUpdate] = useReducer((x) => x + 1, 0);
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const [hotReload, setHotReload] = useState(true);

  const buttons: Array<ButtonGroupDataInterface> = [
    {
      text: 'Live',
      active: type === 'live',
      onClick: () => {
        if (tableRef.current) {
          const ackDayColumn = tableRef.current.getColumn('ackDay');
          const ackEventColumn = tableRef.current.getColumn('ackEvent');
          const monitorColumn = tableRef.current.getColumn('monitor');

          ackDayColumn?.toggleVisibility(true);
          ackEventColumn?.toggleVisibility(true);
          monitorColumn?.toggleVisibility(false);

          setType('live');
        }
      },
    },
    {
      text: 'Muted',
      active: type === 'muted',
      onClick: () => {
        if (tableRef.current) {
          const ackDayColumn = tableRef.current.getColumn('ackDay');
          const ackEventColumn = tableRef.current.getColumn('ackEvent');
          const monitorColumn = tableRef.current.getColumn('monitor');

          ackDayColumn?.toggleVisibility(false);
          ackEventColumn?.toggleVisibility(false);
          monitorColumn?.toggleVisibility(true);

          setType('muted');
        }
      },
    },
  ];

  useEffect(() => {
    const socket = getSocket<ListenEventsMap, EmitEventsMap>().socket;
    if (type === 'live') {
      socket.emit(EmitEvents.StopTrackMuted, `${eventId}`);
      socket.emit(EmitEvents.StartTrackLive, `${eventId}`);
    } else if (type === 'muted') {
      socket.emit(EmitEvents.StopTrackLive, `${eventId}`);
      socket.emit(EmitEvents.StartTrackMuted, `${eventId}`);
    }
  }, [type]);

  const { data, isFetching, error } = useFetchEventBoard({
    eventId: eventId as string,
    offset: pagination.pageIndex * pagination.pageSize,
    limit: pagination.pageSize,
    filter,
    type,
    update,
  });

  useEffect(() => {
    const socket = getSocket<ListenEventsMap, EmitEventsMap>().socket;
    const forcePageReloadHandler = (_: EventNotification) => {
      // TODO: Make this a bit more smarter, we do not care about certain events
      // in certain view (live|muted)
      if (hotReload) {
        forceUpdate();
      }
    };

    socket.on(ListenEvents.NewNotification, forcePageReloadHandler);
    socket.on(ListenEvents.NotificationUpdated, forcePageReloadHandler);
    socket.on(ListenEvents.NotificationAcknowledged, forcePageReloadHandler);
    socket.on(ListenEvents.NotificationUnacknowledged, forcePageReloadHandler);

    return () => {
      socket.off(ListenEvents.NewNotification, forcePageReloadHandler);
      socket.off(ListenEvents.NotificationUpdated, forcePageReloadHandler);
      socket.off(ListenEvents.NotificationAcknowledged, forcePageReloadHandler);
      socket.off(
        ListenEvents.NotificationUnacknowledged,
        forcePageReloadHandler,
      );
    };
  }, [update, hotReload]);

  const messageList = useMemo(() => data?.data ?? [], [data]);
  const rowCount = useMemo(() => data?._route.total ?? 0, [data]);

  useEffect(() => {
    markNotificationsAsSeen(messageList);
  }, [messageList]);

  const table = useMantineReactTable({
    columns: notificationTableColumns as any,
    data: messageList,
    enableSorting: false,
    layoutMode: 'semantic',
    meta: {
      acknowledgeDay(row) {
        const date = new Date();
        date.setHours(23, 59, 59);
        acknowledgeNotification(row, date);
      },
      acknowledgeEvent(row) {
        const finishDate = new Date();
        finishDate.setFullYear(finishDate.getFullYear() + 100);
        acknowledgeNotification(row, finishDate);
      },
      unacknowledge(row) {
        unacknowledgeNotification(row);
      },
    },
    renderTopToolbarCustomActions: () => (
      <Flex
        mih={50}
        gap="md"
        justify="flex-start"
        align="center"
        direction="row"
        wrap="wrap"
      >
        <ButtonGroupData buttons={buttons} />
        <Checkbox
          label="Hot-reload"
          checked={hotReload}
          onChange={() => setHotReload(!hotReload)}
        />
      </Flex>
    ),
    enableColumnResizing: false,
    defaultColumn: {
      mantineTableHeadCellProps: {
        className: style.tableHeader,
      },
    },
    state: {
      isFullScreen,
      pagination,
      showProgressBars: isFetching && update === 0,
      showAlertBanner: Boolean(error),
    },
    rowCount,
    onIsFullScreenChange: setIsFullScreen,
    mantineTableBodyCellProps: {
      style: {
        whiteSpace: 'wrap',
      },
    },
    // paginationDisplayMode: 'custom',
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onPaginationChange: setPagination,
    onGlobalFilterChange: (val) => {
      if (val !== filter) {
        setFilter(val);
      }
    },
    mantineToolbarAlertBannerProps: Boolean(error)
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    initialState: {
      columnVisibility: {
        monitor: false,
        type: false,
        warningCount: false,
        seen: false,
        lastSeen: false,
        updatedAt: false,
        lastSeenDateTime: false,
      },
      density: 'xs',
    },
  });

  tableRef.current = table;

  return (
    <div className={style.table}>
      <MantineReactTable table={table} />
    </div>
  );
};

export default EventBoard;
