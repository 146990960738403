import { useQuery } from '@tanstack/react-query';

import { client } from '../api';

const getEnumType = async (
  enumType: string,
  parentKey?: string,
): Promise<any[]> => {
  let url = `enumTypes/?type=${enumType}&showAll=true`;

  if (parentKey) {
    url += `&parentKey=${parentKey}`;
  }

  const response = await client.get(url);

  return response.data.data;
};

export const useEnumType = (enumType: string, parentKey?: string) => {
  return useQuery<any[], Error>({
    queryKey: ['enumType', enumType, parentKey],
    queryFn: () => getEnumType(enumType, parentKey),
  });
};
