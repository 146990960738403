import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Box, Divider, Flex, Grid, Popover, Stack, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import ZoomControl from 'components/Canvas/CanvasToolbox/ZoomControl';
import { CanvasSectionType, MapControlType } from 'components/Canvas/types';
import InteractiveMap from 'components/Map/InteractiveMap';
import QR from 'components/QR/QR';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { useEntityLayout } from 'services/entity/layout';
import { statuses } from 'utils/consts';

import MapControl from '../../../../../components/Canvas/CanvasToolbox/MapControl';
import InteractiveCanvas from '../../../../../components/Canvas/InteractiveCanvas/InteractiveCanvas';

// const sections: Array<CanvasSectionType> = [
//   {
//     id: 1,
//     name: 'nissan',
//     color: '#eab308',
//     // Tacke u normalizovanom obliku (visina: 503, sirina: 678)
//     points: [
//       [0.0159, 0.05899], // [8, 40]
//       [0.10934, 0.05899], // [55, 40]
//       [0.10934, 0.22123], // [55, 150]
//       [0.0159, 0.22123], // [8, 150]
//     ],
//   },
//   {
//     id: 22,
//     name: 'kia',
//     color: '#f43f5e',
//     points: [
//       [0.0159, 0.22418], // [8, 152]
//       [0.10934, 0.22418], // [40, 152]
//       [0.10934, 0.38938], // [40, 264]
//       [0.0159, 0.38938], // [8, 264]
//     ],
//   },
//   {
//     id: 333,
//     name: 'infiniti',
//     color: '#10b981',
//     points: [
//       [0.11332, 0.22418], // [57, 152]
//       [0.20278, 0.22418], // [102, 152]
//       [0.20278, 0.38938], // [102, 264]
//       [0.11332, 0.38938], // [57, 264]
//     ],
//   },
// ];

const LayoutDetailsModal = ({ dataId = '', eventId = '' }) => {
  const [openedPopover, setOpenedPopover] = useState<boolean>(false);
  const [mapControl, setMapControl] = useState<MapControlType>({
    sectionsPins: false,
    sectionsArea: false,
    devices: false,
  });
  const [scale, setScale] = useState<number>(1);
  const boxRef = useRef<HTMLDivElement | null>(null);

  const { height } = useViewportSize();

  const { data: entityLayoutData } = useEntityLayout(eventId, dataId);

  return (
    <Grid pr="1rem">
      <Grid.Col span={5}>
        <Box mb="1rem">
          <Text fz="0.875rem" fw={500} c="#0f172a">
            Description
          </Text>
          <Text fz="0.875rem" fw={400} c="#64748b">
            {entityLayoutData?.description.length
              ? entityLayoutData?.description
              : '-'}
          </Text>
        </Box>

        <Grid>
          <Grid.Col span={6}>
            <Stack gap="1rem">
              <Box>
                <Text fz="0.875rem" fw={500} c="#0f172a">
                  Start
                </Text>
                <Text fz="0.875rem" fw={400} c="#64748b">
                  {entityLayoutData?.startDateTime
                    ? dayjs(entityLayoutData.startDateTime).format(
                        'MMM hh:mm A',
                      )
                    : '-'}
                </Text>
              </Box>
              <Box>
                <Text fz="0.875rem" fw={500} c="#0f172a">
                  Capacity
                </Text>
                <Text fz="0.875rem" fw={400} c="#64748b">
                  {entityLayoutData?.maxParticipants ?? '-'}
                </Text>
              </Box>
              <Box>
                <Text fz="0.875rem" fw={500} c="#0f172a">
                  Validation
                </Text>
                <Popover
                  withArrow
                  opened={openedPopover}
                  onChange={setOpenedPopover}
                >
                  <Popover.Target>
                    <Text
                      span
                      bg="#ffffff"
                      c="#3B82F6"
                      fz="0.875rem"
                      fw={400}
                      style={{ cursor: 'pointer' }}
                      onClick={() => setOpenedPopover((o) => !o)}
                    >
                      View QR
                    </Text>
                  </Popover.Target>

                  <Popover.Dropdown fz="0.875rem">
                    {entityLayoutData && (
                      <QR
                        value={entityLayoutData?._id}
                        qrId={entityLayoutData?._id}
                      />
                    )}
                  </Popover.Dropdown>
                </Popover>
              </Box>
            </Stack>
          </Grid.Col>
          <Grid.Col span={6}>
            <Stack gap="1rem">
              <Box>
                <Text fz="0.875rem" fw={500} c="#0f172a">
                  End
                </Text>
                <Text fz="0.875rem" fw={400} c="#64748b">
                  {entityLayoutData?.endDateTime
                    ? dayjs(entityLayoutData.endDateTime).format('MMM hh:mm A')
                    : '-'}
                </Text>
              </Box>
              <Box>
                <Text fz="0.875rem" fw={500} c="#0f172a">
                  Age limit
                </Text>
                <Text fz="0.875rem" fw={400} c="#64748b">
                  {`${entityLayoutData?.participantMinAge ?? '/'} - ${
                    entityLayoutData?.participantMaxAge ?? '/'
                  }`}
                </Text>
              </Box>
              <Box>
                <Text fz="0.875rem" fw={500} c="#0f172a">
                  Status
                </Text>
                <Text fz="0.875rem" fw={400} c="#64748b">
                  {statuses.find(
                    (status) => status.value === entityLayoutData?.status,
                  )?.label ?? '-'}
                </Text>
              </Box>
            </Stack>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col py={0} pr={0} span={7}>
        <Box
          p="1rem"
          bd="1px solid #e2e8f0"
          style={{ borderRadius: '0.75rem' }}
        >
          <Flex justify="space-between" align="center">
            <Flex c="#94a3b8" gap="0.5rem">
              <InformationCircleIcon height="1.25rem" width="1.25rem" />
              <Text fz="0.875rem" fw={400}>
                Use map control for more informations
              </Text>
            </Flex>

            <MapControl mapControl={mapControl} setMapControl={setMapControl} />
          </Flex>

          <Divider my="1rem" c="#e2e8f0" />

          <Box pos="relative" ref={boxRef}>
            {boxRef.current && entityLayoutData?.presentation?.image && (
              <>
                <InteractiveCanvas
                  imageUrl={entityLayoutData?.presentation?.image?.largeSource}
                  height={height - 19 * 24}
                  width={boxRef.current?.offsetWidth}
                  scale={scale}
                  showSections={mapControl.sectionsArea}
                  showPins={mapControl.sectionsPins}
                  showDevices={mapControl.devices}
                  sectionsData={[]}
                />

                <ZoomControl
                  scale={scale}
                  setScale={setScale}
                  pos="absolute"
                  right="0.675rem"
                  bottom="1rem"
                />
              </>
            )}

            {entityLayoutData?.presentation?.geoRange && (
              <InteractiveMap
                height={height - 19 * 24}
                autozoom={entityLayoutData?.presentation?.geoRange?.autozoom}
                zoom={entityLayoutData?.presentation?.geoRange?.zoom}
                center={entityLayoutData?.presentation?.geoRange?.center}
                marker={entityLayoutData?.presentation?.geoRange?.center}
                gestureHandling="none"
              />
            )}
          </Box>
        </Box>
      </Grid.Col>
    </Grid>
  );
};

export default LayoutDetailsModal;
