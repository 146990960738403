import { notification } from '@lanaco/lnc-react-ui';
import { useMutateEvent } from 'hooks/event/useMutateEvent';
import { noop } from 'lodash';
import { getSession } from 'services/auth';
import { createDateAndTime } from 'utils/createDateAndTime';
import { formatDateTime } from 'utils/dateFormatters';
import { getErrorMsg } from 'utils/getErrorMsg';

import EventContactInfoForm from './About/EventContactInfoForm';
import EventCoverPhotoForm from './About/EventCoverPhotoForm/EventCoverPhotoForm';
import { EventInfoForm } from './About/EventInfoForm/EventInfoForm';
import { EventRegistrationInfoForm } from './About/EventRegistrationInfoForm/EventRegistrationInfoForm';
import EventSocialMediaForm from './About/EventSocialMediaForm';
import { Event } from './interfaces';
import {
  ContactInfoSchema,
  EventCoverPhotoSchema,
  EventFormSchema,
  eventFormSchema,
  EventInfoSchema,
  RegistrationInfoSchema,
  SocialMediaSchema,
} from './MultiStepForm/event-schema';
import { MultiStepForm } from './MultiStepForm/MultiStepForm';
import { MultiStepFormHeader } from './MultiStepForm/MultiStepFormHeader/MultiStepFormHeader';
type EventFormProps = {
  onAddSubmitHandler?: any;
  onEditSubmitHandler?: any;
  onClose?: () => void;
  data?: Event | null;
  isEdit: boolean;
  fromEventAbout?: boolean;
};

const stepLabels = [
  'Event info',
  'Cover photo',
  'Registration info',
  'Event social media',
  'Contact info',
];

const EventForm = ({
  data,
  isEdit,
  onClose,
  fromEventAbout,
}: EventFormProps) => {
  let userData = getSession().session;
  let companyId = '';
  if (userData?.institutionId !== undefined) {
    if (fromEventAbout) {
      companyId = `${data?.companyId._id}`;
    } else {
      companyId = `${data?.companyId}`;
    }
  } else if (userData?.companyId !== undefined) {
    companyId = userData.companyId;
  }

  const initEventInfo: EventInfoSchema = {
    name: data?.name || '',
    timezone: data?.timezone || '',
    startDate:
      data?.startDate !== undefined
        ? new Date(
            formatDateTime(
              data?.startDate?.toISOString() as string,
              data?.timezone!,
            ).date,
          )
        : new Date(),
    finishDate:
      data?.finishDate !== undefined
        ? new Date(
            formatDateTime(
              data?.finishDate?.toISOString() as string,
              data?.timezone!,
            ).date,
          )
        : new Date(),
    location: {
      latitude: data?.location.latitude || 0,
      longitude: data?.location.longitude || 0,
      description: data?.location.description || '',
    },
    eventUrl: data?.eventUrl || '',
    description: data?.description || '',
    type: data?.type || '',
    company: companyId,
    startTime:
      data?.startDate !== undefined
        ? formatDateTime(
            data?.startDate?.toISOString() as string,
            data?.timezone!,
          ).time
        : '',
    finishTime:
      data?.finishDate !== undefined
        ? formatDateTime(
            data?.finishDate?.toISOString() as string,
            data?.timezone!,
          ).time
        : '',
  };

  const initRegistrationInfoSchema: RegistrationInfoSchema = {
    url: data?.registration?.url || '',
    startDate:
      data?.registration?.startDate !== undefined
        ? new Date(
            formatDateTime(
              new Date(data?.registration?.startDate).toISOString(),
              data?.timezone!,
            ).date,
          )
        : undefined,
    finishDate:
      data?.registration?.finishDate !== undefined
        ? new Date(
            formatDateTime(
              new Date(data?.registration?.finishDate).toISOString(),
              data?.timezone!,
            ).date,
          )
        : undefined,

    metadata: JSON.stringify(data?.metadata ?? {}),

    registrationStartTime:
      data?.registration?.startDate !== undefined
        ? formatDateTime(
            new Date(data?.registration.startDate).toISOString(),
            data?.timezone!,
          ).time
        : '',

    registrationFinishTime:
      data?.registration?.finishDate !== undefined
        ? formatDateTime(
            new Date(data?.registration.finishDate).toISOString(),
            data?.timezone!,
          ).time
        : '',
  };

  const initSocialMediaSchema: SocialMediaSchema = {
    facebook: data?.socials.facebook || '',
    instagram: data?.socials.instagram || '',
    linkedin: data?.socials.linkedin || '',
    tikTok: data?.socials.tikTok || '',
    twitter: data?.socials.twitter || '',
    youtube: data?.socials.youtube || '',
  };

  const initContactInfoSchema: ContactInfoSchema = {
    name: data?.contactPerson.name || '',
    email: data?.contactPerson.email || '',
    phoneNumber: data?.contactPerson.phoneNumber || '',
  };

  const initEventCoverPhoto: EventCoverPhotoSchema = {
    heroImage: data?.heroImage?.largeSource || null,
  };

  const initCreateData: EventFormSchema = {
    eventInfo: initEventInfo,
    coverPhoto: initEventCoverPhoto,
    registration: initRegistrationInfoSchema,
    socials: initSocialMediaSchema,
    contactPerson: initContactInfoSchema,
  };

  const mutation = useMutateEvent(data?._id);

  const onSuccessHandler = async (data: EventFormSchema) => {
    const prepareCreateEventObj = {
      timezone: data.eventInfo.timezone,
      eventUrl: data.eventInfo.eventUrl ?? '',

      startDate: createDateAndTime(
        data?.eventInfo.startDate,
        data?.eventInfo?.startTime,
        data.eventInfo.timezone,
      ),

      finishDate: createDateAndTime(
        data?.eventInfo.finishDate,
        data?.eventInfo?.finishTime,
        data.eventInfo.timezone,
      ),
      name: data.eventInfo.name,
      description: data.eventInfo.description,
      type: data.eventInfo.type,
      companyId:
        data.eventInfo.company !== 'undefined' ? data.eventInfo.company : '',
      heroImage: data.coverPhoto.heroImage,
      location: {
        description: data.eventInfo.location.description ?? '',
        latitude: data.eventInfo.location.latitude,
        longitude: data.eventInfo.location.longitude,
      },
      metadata: JSON.parse(data.registration.metadata),
      registration: {
        finishDate:
          data?.registration?.finishDate! &&
          createDateAndTime(
            data?.registration?.finishDate!,
            data?.registration?.registrationFinishTime,
            data.eventInfo.timezone,
          ),

        startDate:
          data?.registration.startDate! &&
          createDateAndTime(
            data?.registration.startDate!,
            data?.registration?.registrationStartTime,
            data.eventInfo.timezone,
          ),
        url: data.registration.url ?? '',
      },
      contactPerson: {
        name: data.contactPerson.name,
        phoneNumber: data.contactPerson.phoneNumber ?? '',
        email: data.contactPerson.email ?? '',
      },
      socials: {
        facebook: data.socials.facebook ?? '',
        instagram: data.socials.instagram ?? '',
        linkedin: data.socials.linkedin ?? '',
        tikTok: data.socials.tikTok ?? '',
        twitter: data.socials.twitter ?? '',
        youtube: data.socials.youtube ?? '',
      },
    };

    await mutation
      .mutateAsync(prepareCreateEventObj, {
        onSuccess: () => {
          notification.success(
            `Event successfully ${isEdit ? 'updated' : 'created'}.`,
          );

          onClose?.();
          if (isEdit) {
            window.location.reload();
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
      })
      .catch(noop);
  };

  return (
    <MultiStepForm
      formProps={{ defaultValues: initCreateData, mode: 'all' }}
      schema={eventFormSchema}
      header={
        <MultiStepFormHeader
          title={!isEdit ? 'Create event' : 'Edit event'}
          stepLabels={stepLabels}
          onClose={onClose}
        />
      }
      totalSteps={5}
      onSuccess={onSuccessHandler}
    >
      <EventInfoForm
        defaultValues={initEventInfo}
        step={1}
        onCancel={onClose}
        userIsInstitution={userData?.institutionId !== undefined ? true : false}
      />

      <EventCoverPhotoForm step={2} defaultValues={initEventCoverPhoto} />

      <EventRegistrationInfoForm
        step={3}
        defaultValues={initRegistrationInfoSchema}
      />

      <EventSocialMediaForm step={4} defaultValues={initSocialMediaSchema} />

      <EventContactInfoForm
        step={5}
        isSubmitting={mutation.isLoading}
        defaultValues={initContactInfoSchema}
      />
    </MultiStepForm>
  );
};

export default EventForm;
