import { refineJson } from 'utils/zod';
import z from 'zod';

import { PHONE_REGEX } from 'constants/regex';
const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
export const eventInfoSchema = z
  .object({
    name: z.string().nonempty({ message: 'Name is required' }),
    company: z.string(),
    timezone: z.string().nonempty({ message: 'Timezone is required' }),
    startDate: z.date(),
    startTime: z.string().nonempty({ message: 'Start time is required' }),
    finishDate: z.date(),
    finishTime: z.string().nonempty({ message: 'Finish time is required' }),
    location: z.object({
      description: z.string(),
      latitude: z.number(),
      longitude: z.number(),
    }),
    eventUrl: z
      .string()
      .optional()
      .refine((url) => !url || urlRegex.test(url), {
        message: 'Invalid URL format',
      }),
    description: z.string(),
    type: z.string().nonempty({ message: 'Type is required' }),
  })
  .superRefine((schema, ctx) => {
    const isStartDateGreater =
      schema.startDate.getTime() > schema.finishDate.getTime();

    if (isStartDateGreater) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Start date can not be greater then finish date',
        path: ['finishDate'],
        fatal: true,
      });

      return z.NEVER;
    }
  });

export type EventInfoSchema = z.infer<typeof eventInfoSchema>;

export const registrationInfoSchema = z
  .object({
    startDate: z.date().optional(),
    registrationStartTime: z.string(),
    finishDate: z.date().optional(),
    registrationFinishTime: z.string(),
    url: z
      .string()
      .optional()
      .refine((url) => !url || urlRegex.test(url), {
        message: 'Invalid URL format',
      }),
    metadata: z.string(),
  })
  .superRefine((schema, ctx) => {
    try {
      return refineJson(schema.metadata);
    } catch (error) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Json format is incorrect',
        path: ['metadata'],
        fatal: true,
      });
    }

    return z.NEVER;
  })
  .superRefine((schema, ctx) => {
    const startDate = schema.startDate;
    const finishDate = schema.finishDate;
    if (startDate && finishDate) {
      const isStartDateGreater = startDate.getTime() > finishDate.getTime();

      if (isStartDateGreater) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Start date can not be greater then finish date',
          path: ['finishDate'],
          fatal: true,
        });
      }
    }

    return z.NEVER;
  });

export type RegistrationInfoSchema = z.infer<typeof registrationInfoSchema>;

export const socialMediaSchema = z.object({
  facebook: z.string().url().or(z.literal('')),
  instagram: z.string().url().or(z.literal('')),
  linkedin: z.string().url().or(z.literal('')),
  tikTok: z.string().url().or(z.literal('')),
  twitter: z.string().url().or(z.literal('')),
  youtube: z.string().url().or(z.literal('')),
});

export type SocialMediaSchema = z.infer<typeof socialMediaSchema>;

export const contactInfoSchema = z.object({
  name: z.string(),

  email: z
    .string()
    .optional()
    .refine(
      (value) => {
        if (!value) return true;
        return emailRegex.test(value);
      },
      {
        message: 'Invalid email format',
      },
    ),
  phoneNumber: z
    .string()
    .optional()
    .refine(
      (value) => {
        if (!value) return true;
        return PHONE_REGEX.test(value);
      },
      {
        message: 'Invalid phone number format',
      },
    ),
});

export type ContactInfoSchema = z.infer<typeof contactInfoSchema>;

const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/png'];
const MAX_FILE_SIZE_5MB = 1024 * 1024 * 5;
export const eventCoverPhotoSchema = z.object({
  heroImage: z
    .custom<File | string | null>(
      (value) =>
        value === null || value instanceof File || typeof value === 'string',
    )
    .superRefine((value, ctx) => {
      if (typeof value === 'string') {
        return value;
      }

      if (!value) {
        return undefined;
      }

      if (value.size > MAX_FILE_SIZE_5MB) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'File size should be less than 5mb.',
        });
      }

      if (!ACCEPTED_IMAGE_TYPES.includes(value.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Only .jpeg and .png types are allowed',
        });
      }

      return z.NEVER;
    }),
});

export type EventCoverPhotoSchema = z.infer<typeof eventCoverPhotoSchema>;

export const eventFormSchema = z.object({
  eventInfo: eventInfoSchema,
  coverPhoto: eventCoverPhotoSchema,
  registration: registrationInfoSchema,
  socials: socialMediaSchema,
  contactPerson: contactInfoSchema,
});

export type EventFormSchema = z.infer<typeof eventFormSchema>;

const eventPayloadSchema = z.object({
  timezone: z.string(),
  eventUrl: z.string(),
  startDate: z.date(),
  finishDate: z.date(),
  name: z.string(),
  description: z.string(),
  type: z.string(),
  companyId: z.string(),
  location: z.object({
    description: z.string(),
    latitude: z.number(),
    longitude: z.number(),
  }),
  heroImage: z.unknown(),
  metadata: z.record(z.any()),
  registration: z.object({
    startDate: z.date(),
    finishDate: z.date(),
    url: z.string(),
  }),
  contactPerson: z.object({
    name: z.string(),
    email: z.string(),
    phoneNumber: z.string(),
  }),
  socials: z.object({
    facebook: z.string(),
    instagram: z.string(),
    linkedin: z.string(),
    tikTok: z.string(),
    twitter: z.string(),
    youtube: z.string(),
  }),
});

export type EventPayload = z.infer<typeof eventPayloadSchema>;
