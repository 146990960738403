import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { client } from 'services/api';
import { Response } from 'types/response';
import { GET_EVENT_BOARD_STATE } from 'utils/apiUrl';

import { eventBoardBaseKey } from './eventBoardBaseKey';

interface EventNotificationStateProps
  extends UseQueryOptions<Response<{totalCount: number; totalCountNotSeen: number;}>, AxiosError> {
  eventId,
  update: number,
}

export const useFetchEventBoardState = ({
  eventId,
  update,
  ...rest
}: EventNotificationStateProps) => {
  const queryResult = useQuery<
    Response<{totalCount: number; totalCountNotSeen: number;}>,
    AxiosError
  >({
    queryKey: [
      eventBoardBaseKey,
      eventId,
      update
    ],
    queryFn: async () => {
      const retVal = await client.get<Response<{totalCount: number; totalCountNotSeen: number;}>>(GET_EVENT_BOARD_STATE(eventId));

      return retVal.data;
    },
    ...rest,
  });

  return queryResult;
};
