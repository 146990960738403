import { RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import mapPNG from 'assets/images/map.png';
import { TLButton, TLDangerButton, TLNeutralButton } from 'components/Button';
import ButtonGroupData from 'components/ButtonGroupData/ButtonGroupData';
import { ButtonGroupDataInterface } from 'components/ButtonGroupData/ButtonGroupData.interface';
import { Icon } from 'components/Icon/Icon';
import { InputSearch } from 'components/Input/InputSearch';
import { TLModal } from 'components/Modal';
import QR from 'components/QR/QR';
import TanStackTable, {
  RowAction,
} from 'components/TanStackTable/TanStackTable';
import { useFetchEventDetails } from 'hooks/event/details';
import { useFetchEventLayouts } from 'hooks/event/layout/useFetchEventLayouts';
import { useFetchSingleEventStatistics } from 'hooks/event/statistics/useFetchSingleEventStatistics';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useParams } from 'react-router';
import { formatDateTimeFull } from 'utils/dateFormatters';

import { DeleteEventLayoutModal } from './DeleteEventLayoutModal/DeleteEventLayoutModal';
import styles from './EventLayout.module.scss';
import { EventLayout } from './interfaces';
import LayoutDetails from './LayoutDetails/LayoutDetails';
import LayoutForm from './LayoutForm';
import { getPresentationFromEventLayout } from './util';

type Props = {};
const ROW_LIMIT = 10;
const columnHelper = createColumnHelper<EventLayout>();

export const EventLayouts = (props: Props) => {
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const params = useParams();
  const [layoutToEdit, setLayoutToEdit] = useState<EventLayout | null>(null);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('all');

  const { data, isFetching } = useFetchEventLayouts({
    offset: (page - 1) * ROW_LIMIT,
    rowLimit: ROW_LIMIT,
    status,
    eventId: params.id,
    filter: search,
  });

  const { _route, data: eventLayouts } = data || {
    _route: { returned: 0, total: 0 },
    data: [],
  };

  const { id } = useParams<{ id: string }>();
  const { data: statistics } = useFetchSingleEventStatistics({
    eventId: id as string,
  });

  const { data: event } = useFetchEventDetails(id as string);

  const getHandleStatusFilterChangeCallback = (status: string) => () => {
    setPage(1);
    setStatus(status);
  };
  const handleShowLayoutDetailsModal = (layout: EventLayout) => {
    setLayoutToEdit(layout);
    showLayoutDetailsModal();
  };

  const [showQrCodeModal, _hideQrCodeModal] = useModal(() => {
    const hideQrCodeModal = () => {
      setLayoutToEdit(null);
      _hideQrCodeModal();
    };
    const typedLayout = layoutToEdit!;

    return (
      <TLModal
        title={typedLayout.name}
        onClose={hideQrCodeModal}
        className={styles.qrModal}
        titleIcon="layer-group"
      >
        <QR value={typedLayout._id} qrId={typedLayout._id} />
      </TLModal>
    );
  }, [layoutToEdit]);

  const [showLayoutDetailsModal, _hideLayoutDetailsModal] = useModal(() => {
    const hideLayoutDetailsModal = () => {
      setLayoutToEdit(null);
      _hideLayoutDetailsModal();
    };

    return (
      <TLModal
        titleIcon="layer-group"
        title={layoutToEdit?.name}
        onClose={hideLayoutDetailsModal}
        className={styles.layout_details_modal}
      >
        <LayoutDetails
          layout={layoutToEdit!}
          timezone={event?.timezone as string}
        />
      </TLModal>
    );
  }, [layoutToEdit]);

  const selectedIds = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    },
    [],
  );

  const [showDeleteModal, _hideDeleteModal] = useModal(() => {
    const hideDeleteModal = () => {
      setLayoutToEdit(null);
      _hideDeleteModal();
    };

    return (
      <DeleteEventLayoutModal
        eventId={params.id!}
        closeModal={hideDeleteModal}
        eventLayoutId={layoutToEdit?._id!}
        layoutIds={selectedIds}
        onEventLayoutEdit={hideDeleteModal}
        setSelectedRows={setSelectedRows}
      />
    );
  }, [layoutToEdit, selectedIds]);

  const [showCreateOrUpdateModal, _hideCreateOrUpdateModal] = useModal(() => {
    const hideCreateOrUpdateModal = () => {
      setLayoutToEdit(null);
      _hideCreateOrUpdateModal();
    };

    return (
      <LayoutForm
        eventId={params.id!}
        isEdit={!!layoutToEdit}
        data={layoutToEdit}
        closeModal={hideCreateOrUpdateModal}
        onCancelHandler={hideCreateOrUpdateModal}
        timezone={event?.timezone as string}
      />
    );
  }, [layoutToEdit]);

  const buttons: Array<ButtonGroupDataInterface> = [
    {
      text: 'All',
      text1: statistics?.eventLayouts.total.toString(),
      onClick: getHandleStatusFilterChangeCallback('all'),
    },
    {
      text: 'Enabled',
      text1: statistics?.eventLayouts.active.toString(),
      onClick: getHandleStatusFilterChangeCallback('active'),
    },
    {
      text: 'Disabled',
      text1: statistics?.eventLayouts.disabled.toString(),
      onClick: getHandleStatusFilterChangeCallback('disabled'),
    },
  ];

  const columns = [
    columnHelper.accessor('name', {
      header: 'Layout',
      minSize: 250,
      cell: ({ row }) => {
        const { name, description } = row.original;
        const presentationSource = getPresentationFromEventLayout(row.original);

        return (
          <div
            onClick={() => handleShowLayoutDetailsModal(row.original)}
            className={styles.layoutCellContainer}
          >
            {presentationSource && (
              <img
                className={styles.presentation}
                src={presentationSource}
                alt="presentation for layout"
              />
            )}
            {!presentationSource && (
              <div className={styles.layoutImageNoPresentationContainer}>
                <img src={mapPNG} alt="placeholder presentation" />{' '}
                <div className={styles.layoutImageNoPresentationOverlay}>
                  <Icon
                    icon="camera"
                    className={styles.layoutImageMapNoImageIcon}
                  />
                  <p className={styles.layoutImageMapNoImageText}>No image</p>
                </div>
              </div>
            )}
            <div className={styles.layoutTextContainer}>
              <div className={styles.layoutName}>{name}</div>
              <div className={styles.layoutDescription}>{description}</div>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor(
      (layout) =>
        layout.startDateTime &&
        formatDateTimeFull(
          layout.startDateTime.toISOString(),
          event?.timezone!,
        ),
      {
        id: 'start',
        header: 'Start',
      },
    ),
    columnHelper.accessor(
      (layout) =>
        layout.endDateTime &&
        formatDateTimeFull(layout.endDateTime!.toISOString(), event?.timezone!),
      {
        id: 'end',
        header: 'End',
      },
    ),
    columnHelper.accessor('maxParticipants', {
      header: 'Capacity',
    }),
    columnHelper.accessor(
      (layout) => {
        const { participantMinAge, participantMaxAge } = layout;
        if (
          participantMinAge !== undefined &&
          participantMaxAge !== undefined
        ) {
          const ageLimitString = `${participantMinAge} - ${participantMaxAge}`;
          return ageLimitString;
        }
      },

      {
        id: 'ageLimit',
        header: 'Age limit',
      },
    ),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (row) => {
        return (
          <span>
            {row.getValue() === 'active' ? 'enabled' : row.getValue()}
          </span>
        );
      },
    }),
  ];

  const handleAddLayout = () => {
    setLayoutToEdit(null);
    showCreateOrUpdateModal();
  };

  const showQrCode = (row: EventLayout) => {
    setLayoutToEdit(row);
    showQrCodeModal();
  };
  const handleDeleteClick = (row: EventLayout) => {
    setLayoutToEdit(row);
    showDeleteModal();
  };

  const handleRemoveEventLayouts = () => {
    setLayoutToEdit(null);
    showDeleteModal();
  };

  const handleToggleEnabledClick = (row: EventLayout) => {
    setLayoutToEdit(row);
    showCreateOrUpdateModal();
  };

  const eventLayoutTableRowActions = (row: EventLayout): RowAction[] => {
    const actions = [
      {
        key: 'edit',
        icon: 'pencil',
        label: 'Edit layout',
        onClick: () => handleToggleEnabledClick(row),
      },
      {
        key: 'delete',
        icon: 'trash',
        label: 'Delete layout',
        onClick: () => handleDeleteClick(row),
      },
    ];

    if (row.validationQR) {
      actions.unshift({
        key: 'qr',
        icon: 'qrcode',
        label: 'View QR',
        onClick: () => showQrCode(row),
      });
    }

    return actions;
  };

  const clearSelectedRows = () => setSelectedRows({});

  const eventLayoutSelectedRowsActions = (
    <>
      <TLNeutralButton leadingIcon="times" onClick={clearSelectedRows}>
        Clear selection
      </TLNeutralButton>
      <TLNeutralButton leadingIcon="arrow-down" onClick={() => {}}>
        Export
      </TLNeutralButton>
      <TLDangerButton
        leadingIcon="trash"
        onClick={handleRemoveEventLayouts}
        className="text-white"
      >
        Remove
      </TLDangerButton>
    </>
  );

  const eventLayoutTableActions = (
    <>
      <TLButton leadingIcon="plus" onClick={handleAddLayout}>
        New layout
      </TLButton>
    </>
  );
  const handlePageChange = (page: number) => setPage(page);

  return (
    <div className={styles.divTable}>
      <InputSearch
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        className={styles.search}
      />
      <TanStackTable
        getRowId={(row) => row._id}
        selectedRows={selectedRows}
        onRowSelectionChange={setSelectedRows}
        columns={columns}
        pageIndex={page}
        pageSize={ROW_LIMIT}
        totalRows={_route.total}
        pageCount={Math.ceil(_route.total / ROW_LIMIT)}
        data={eventLayouts}
        rowActions={eventLayoutTableRowActions}
        onPageChange={handlePageChange}
        headerTitle={<ButtonGroupData buttons={buttons} />}
        isLoading={isFetching}
        rowClassName={(row) =>
          row.status === 'disabled' ? 'text-gray-400' : ''
        }
        tableActions={
          selectedIds.length > 0
            ? eventLayoutSelectedRowsActions
            : eventLayoutTableActions
        }
        enableRowSelection
        showHeader
      />
    </div>
  );
};
