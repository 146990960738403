import { Icon } from 'components/Icon/Icon';
import ProgressSteps from 'components/ProgressSteps/ProgressSteps';
import { CSSProperties } from 'react';

import { useMultiStep } from '../context/multi-step-context';

import styles from './MultiStepFormHeader.module.scss';

type Props = {
  title: string;
  stepLabels: string[];
  iconSize?: string;
  iconName?: string;
  style?: CSSProperties;
  className?: string;
  onClose?: () => void;
};

export const MultiStepFormHeader = ({
  title,
  stepLabels,
  iconSize = '24px',
  iconName = 'times',
  style,
  className,
  onClose,
}: Props) => {
  const { currentStep, totalSteps } = useMultiStep();

  // console.log('Trenutni koraci', currentStep, 'ukupno koraci', totalSteps);

  return (
    <div className={className} style={style}>
      <div className={styles.progressSteps}>
        <span>{title}</span>
        <Icon
          size={iconSize}
          icon={iconName}
          className={styles.closeModalIcon}
          onClick={onClose}
        />
      </div>

      <ProgressSteps
        title={stepLabels[currentStep - 1]}
        currentStep={currentStep}
        totalSteps={totalSteps}
      />
    </div>
  );
};
