import { forwardRef } from 'react';

import { SvgIconProps } from './interface';

const TagMinusIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  (
    {
      title,
      titleId,
      height = '1em',
      width = '1em',
      fill = 'currentColor',
      ...props
    },
    ref,
  ) => (
    <svg
      ref={ref}
      height={height}
      width={width}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.11578 3.25C2.53305 3.25 1.25 4.53305 1.25 6.11578V10.2406C1.25 11.0007 1.55193 11.7296 2.08937 12.267L11.2418 21.4195C12.12 22.2976 13.524 22.5523 14.6306 21.8279C16.7006 20.4727 18.4727 18.7006 19.8279 16.6306C20.5523 15.524 20.2976 14.12 19.4195 13.2418L10.267 4.08937C9.72958 3.55193 9.00065 3.25 8.2406 3.25H4.11578ZM5.19044 8.26511C5.78397 8.26511 6.26511 7.78397 6.26511 7.19044C6.26511 6.59692 5.78397 6.11578 5.19044 6.11578C4.59692 6.11578 4.11578 6.59692 4.11578 7.19044C4.11578 7.78397 4.59692 8.26511 5.19044 8.26511Z"
        fill={fill}
      />
      <path
        d="M22.75 5C22.75 4.58579 22.4142 4.25 22 4.25H16C15.5858 4.25 15.25 4.58579 15.25 5C15.25 5.41421 15.5858 5.75 16 5.75H22C22.4142 5.75 22.75 5.41421 22.75 5Z"
        fill={fill}
      />
    </svg>
  ),
);

TagMinusIcon.displayName = 'TagMinusIcon';

export default TagMinusIcon;
