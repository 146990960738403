import styles from './Stat.module.css';
import { StatProps } from './types';

const Stat = ({ title, color, amount, total, percent }: StatProps) => {
  const formattedPercent =
    percent !== undefined
      ? Number.isInteger(percent)
        ? percent.toFixed(0)
        : percent.toFixed(2)
      : '0';

  return (
    <div className={styles.content}>
      <div className={styles.titleContainer}>
        {color && (
          <div
            className={styles.colorCircle}
            style={{ backgroundColor: color }}
          />
        )}
        <p className={styles.label}>{title}</p>
      </div>
      <div className={styles.amountAndTotal}>
        <p className={styles.amount}>{amount}</p>
        <p className={styles.total}>{total}</p>
        {percent !== undefined && (
          <div className={styles.percent}>
            <span>{formattedPercent}%</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Stat;
