import { Badge } from '@lanaco/lnc-react-ui';
import { createColumnHelper, RowSelectionState } from '@tanstack/react-table';
import clsx from 'clsx';
import {
  TLDangerButton,
  TLNeutralButton,
  TLPrimaryButton,
} from 'components/Button';
import ButtonGroupData from 'components/ButtonGroupData/ButtonGroupData';
import { ButtonGroupDataInterface } from 'components/ButtonGroupData/ButtonGroupData.interface';
import { InputSearch } from 'components/Input/InputSearch';
import { TLModal } from 'components/Modal';
import TanStackTable, {
  RowAction,
} from 'components/TanStackTable/TanStackTable';
import { useGetEventDetails } from 'hooks/event/details';
import { useFetchEventDevices } from 'hooks/event/devices';
import { useFetchSingleEventStatistics } from 'hooks/event/statistics/useFetchSingleEventStatistics';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useParams } from 'react-router-dom';
import { formatDateTimeFull } from 'utils/dateFormatters';

import idle from '../../../assets/icons/indicator-idle.svg';
import offline from '../../../assets/icons/indicator-offline.svg';
import online from '../../../assets/icons/indicator-online.svg';

import { DeleteDeviceModal } from './DeleteEventDeviceModal/DeleteDeviceModal';
import { DeleteDevicesModal } from './DeleteEventDevicesModal/DeleteDevicesModal';
import EventDeviceForm from './EventDeviceForm';
import { EventDevice } from './EventDevices.interface';
import style from './EventDevices.module.css';
const columnHelper = createColumnHelper<EventDevice>();
const ROW_LIMIT = 10;
const deviceStatusToBadgeColorMap = {
  offline: 'bg-gray-50 text-gray-500',
  online: 'bg-green-50 text-green-500',
  idle: 'bg-yellow-50 text-yellow-500',
};
type Props = {};

const EventDevices = (props: Props) => {
  const [status, setStatus] = useState('all');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [deviceToEdit, setDeviceToEdit] = useState<EventDevice | null>(null);

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});

  const selectedIds = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    },
    [],
  );

  const { id } = useParams<{ id: string }>();
  const event = useGetEventDetails(id);
  const { data, isFetching } = useFetchEventDevices({
    eventId: id as string,
    offset: (page - 1) * ROW_LIMIT,
    row_limit: ROW_LIMIT,
    status,
    filter: search,
  });

  const { _route, data: eventDevices } = data || {
    _route: { returned: 0, total: 0 },
    data: [],
  };

  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  const tableColumns = [
    columnHelper.accessor('macName', {
      header: 'Device',
    }),
    columnHelper.accessor('macAddress', {
      header: 'MAC address',
    }),
    columnHelper.accessor('updatedAt', {
      header: 'Last seen',
      cell: (row) => formatDateTimeFull(row.getValue(), event?.timezone!),
    }),
    columnHelper.accessor('lastLocation', {
      header: 'Last Location',
    }),
    columnHelper.accessor('deviceStatus', {
      header: 'State',
      cell: (row) => (
        <Badge
          className={clsx(
            'capitalize',
            deviceStatusToBadgeColorMap[row.getValue() as string],
          )}
        >
          {row.getValue()}
        </Badge>
      ),
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (row) => <span className="capitalize">{row.getValue()}</span>,
    }),
  ];

  const clearSelectedRows = () => setSelectedRows({});

  //const exportSelectedRows = () => {};

  const [showInsertDeviceModal, hideInsertDeviceModal] = useModal(() => {
    const closeModal = () => {
      setDeviceToEdit(null);
      hideInsertDeviceModal();
    };

    return (
      <TLModal title="Add devices" onClose={closeModal} hideFooter>
        <EventDeviceForm
          closeModal={closeModal}
          eventName={id as string}
          eventId={id as string}
        />
      </TLModal>
    );
  }, [deviceToEdit]);

  const [showDeleteDevicesModal, closeDeleteDevicesModal] = useModal(
    () => (
      <DeleteDevicesModal
        id={id as string}
        selectedIds={selectedIds}
        onDeviceEdit={setDeviceToEdit}
        closeModal={closeDeleteDevicesModal}
        setSelectedRows={setSelectedRows}
      />
    ),
    [selectedIds],
  );

  const [showDeleteDeviceModal, hideDeleteDeviceModal] = useModal(
    () => (
      <DeleteDeviceModal
        eventId={id as string}
        deviceId={deviceToEdit?._id as string}
        macName={deviceToEdit?.macName}
        onDeviceEdit={setDeviceToEdit}
        closeModal={hideDeleteDeviceModal}
      />
    ),
    [deviceToEdit?.macName, id],
  );

  const handleDeleteClick = (row: EventDevice) => {
    showDeleteDeviceModal();
    setDeviceToEdit(row);
  };
  const handleAddDeviceClick = () => {
    setDeviceToEdit(null);
    showInsertDeviceModal();
  };

  //const handleImportDeviceClick = () => {};

  const getHandleStatusFilterChangeCallback = (status: string) => () => {
    setPage(1);
    setStatus(status);
  };

  const { data: singleEventStatistics } = useFetchSingleEventStatistics({
    eventId: id as string,
  });

  const buttons: Array<ButtonGroupDataInterface> = [
    {
      text: 'All',
      text1: singleEventStatistics?.devices?.total.toString(),
      indicator: '',
      onClick: getHandleStatusFilterChangeCallback('all'),
    },
    {
      text: 'Online',
      text1: singleEventStatistics?.devices?.online.toString(),
      indicator: online,
      onClick: getHandleStatusFilterChangeCallback('online'),
    },
    {
      text: 'Idle',
      text1: singleEventStatistics?.devices?.idle.toString(),
      indicator: idle,
      onClick: getHandleStatusFilterChangeCallback('idle'),
    },
    {
      text: 'Offline',
      text1: singleEventStatistics?.devices?.offline.toString(),
      indicator: offline,
      onClick: getHandleStatusFilterChangeCallback('offline'),
    },
  ];

  const deviceTableSelectedRowsActions = (
    <>
      <TLNeutralButton leadingIcon="times" onClick={clearSelectedRows}>
        Clear selected
      </TLNeutralButton>
      {/* <TLNeutralButton
        leadingIcon="arrow-down-tray"
        onClick={exportSelectedRows}
      >
        Export
      </TLNeutralButton> */}
      <TLDangerButton
        leadingIcon="trash"
        onClick={showDeleteDevicesModal}
        className="text-white"
      >
        Remove
      </TLDangerButton>
    </>
  );

  const deviceTableActions = (
    <>
      {/* <TLNeutralButton
        leadingIcon="cloud-arrow-up"
        onClick={handleImportDeviceClick}
      >
        Import
      </TLNeutralButton> */}
      <TLPrimaryButton leadingIcon="plus" onClick={handleAddDeviceClick}>
        Add
      </TLPrimaryButton>
    </>
  );

  const deviceTableRowActions = (row: EventDevice): RowAction[] => [
    {
      icon: 'trash',
      key: 'delete',
      label: 'Remove device',
      color: 'danger',
      onClick: () => handleDeleteClick(row),
    },
  ];

  return (
    <div className={style.table}>
      <InputSearch
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        className={style.search}
      />
      <TanStackTable
        getRowId={(row) => row._id}
        selectedRows={selectedRows}
        onRowSelectionChange={setSelectedRows}
        columns={tableColumns}
        pageIndex={page}
        pageSize={ROW_LIMIT}
        totalRows={_route.total}
        pageCount={Math.ceil(_route.total / ROW_LIMIT)}
        data={eventDevices}
        rowActions={deviceTableRowActions}
        onPageChange={handlePageChange}
        headerTitle={<ButtonGroupData buttons={buttons} />}
        isLoading={isFetching}
        rowClassName={(row) =>
          row.status === 'disabled' ? 'text-gray-400' : ''
        }
        tableActions={
          selectedIds.length > 0
            ? deviceTableSelectedRowsActions
            : deviceTableActions
        }
        statusIndicatorClassName={(row) => {
          if (row.status === 'online') return 'bg-green-500';
          if (row.status === 'idle') return 'bg-yellow-500';
          return 'bg-gray-300';
        }}
        enableRowSelection
        showHeader
      />
    </div>
  );
};

export default EventDevices;
