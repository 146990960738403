import { hubSections } from 'pages/Entities/consts';
import { HubSectionType } from 'pages/Entities/Hub/types';
import { BreadcrumbConfig } from 'pages/Entities/types';
import { createContext, ReactNode, useContext, useState } from 'react';

import { EntityContextType } from './types';

const EntityContext = createContext<EntityContextType | undefined>(undefined);

export const useEntitiesContext = (): EntityContextType => {
  const context = useContext(EntityContext);
  if (!context)
    throw new Error('useEntitiesContext must be used within a EntityProvider');

  return context;
};

export const EntityProvider = ({ children }: { children: ReactNode }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Array<BreadcrumbConfig>>([
    { title: 'Entities', href: '/entities' },
  ]);
  const [showHubCardCheckbox, setShowHubCardCheckbox] = useState<boolean>(
    false,
  );
  const [selectAllHubCards, setSelectAllHubCard] = useState<boolean>(false);
  const [
    indeterminateHubCheckbox,
    setIndeterminateHubCheckbox,
  ] = useState<boolean>(false);
  const [selectedHubCardIds, setSelectedHubCardIds] = useState<string[]>([]);

  const [selectAllAccessControl, setSelectAllAccessControl] = useState<boolean>(
    false,
  );
  const [
    indeterminateControlAccessCheckbox,
    setIndeterminateControlAccessCheckbox,
  ] = useState<boolean>(false);
  const [selectedAccessControlIds, setSelectedAccessControlIds] = useState<
    string[]
  >([]);
  const [selectedSection, setSelectedSection] = useState<HubSectionType>(
    hubSections[0],
  );

  const addBreadcrumb = (title: string, href: string) => {
    setBreadcrumbs((prev) => [...prev, { title, href }]);
  };

  const removeBreadcrumbs = (breadCrumbIndex: number) => {
    setBreadcrumbs(breadcrumbs.filter((_, index) => index <= breadCrumbIndex));
  };

  const initialBreadcrumbsState = () => {
    setBreadcrumbs([{ title: 'Entities', href: '/entities' }]);
  };

  const handleResetState = (selectionType: string) => {
    if (selectionType !== selectedSection.type) {
      setShowHubCardCheckbox(false);
      setSelectAllHubCard(false);
      setSelectAllAccessControl(false);
      setIndeterminateHubCheckbox(false);
      setIndeterminateControlAccessCheckbox(false);
      setSelectedAccessControlIds([]);
      setSelectedHubCardIds([]);
    }
  };

  return (
    <EntityContext.Provider
      value={{
        breadcrumbs,
        addBreadcrumb,
        removeBreadcrumbs,
        initialBreadcrumbsState,
        showHubCardCheckbox,
        setShowHubCardCheckbox,
        selectAllHubCards,
        setSelectAllHubCard,
        indeterminateHubCheckbox,
        setIndeterminateHubCheckbox,
        selectedHubCardIds,
        setSelectedHubCardIds,
        selectedAccessControlIds,
        setSelectedAccessControlIds,
        selectAllAccessControl,
        setSelectAllAccessControl,
        indeterminateControlAccessCheckbox,
        setIndeterminateControlAccessCheckbox,
        selectedSection,
        setSelectedSection,
        handleResetState,
      }}
    >
      {children}
    </EntityContext.Provider>
  );
};
