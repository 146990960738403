import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  EventLayoutPresentation,
  EventLayoutPresentationType,
} from 'pages/Events/Layout/interfaces';
import { Presentation } from 'pages/Events/Layout/LayoutForm';
import { client } from 'services/api';
import { POST_EVENT_LAYOUT_PRESENTATION } from 'utils/apiUrl';

import { singleEventStatisticsBaseKey } from '../statistics/baseKeys';

import { eventLayoutBaseKey } from './eventLayoutBaseKey';

export const useMutateEventLayoutPresentation = (eventId: string) => {
  const queryClient = useQueryClient();

  const retVal = useMutation<
    EventLayoutPresentation,
    unknown,
    Presentation & { eventLayoutId: string }
  >({
    mutationFn: async (presentation) => {
      let presentationPayload:
        | FormData
        | {
            type: string;
            geoRange: {
              center: {
                lat: number;
                lng: number;
              };
              zoom: number;
            };
          };

      if (presentation?.type === EventLayoutPresentationType.Image) {
        presentationPayload = new FormData();
        presentationPayload.append('type', EventLayoutPresentationType.Image);
        presentationPayload.append('presentationImage', presentation.image!);
      } else {
        presentationPayload = {
          type: EventLayoutPresentationType.GoogleMap,
          geoRange: presentation?.geoRange!,
        };
      }

      const { data } = await client.post(
        POST_EVENT_LAYOUT_PRESENTATION(eventId, presentation.eventLayoutId),
        presentationPayload,
        {
          headers: {
            'Content-Type':
              presentation.type === EventLayoutPresentationType.Image
                ? 'multipart/form-data'
                : 'application/json',
          },
        },
      );

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([eventLayoutBaseKey, eventId]);
      queryClient.invalidateQueries([singleEventStatisticsBaseKey]);
    },
  });

  return retVal;
};
