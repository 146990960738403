import { Box } from '@mantine/core';

import { StatusIndicatorProps } from './types';

const StatusIndicator = ({
  startDate,
  endDate,
  startTime = '',
  endTime = '',
  height,
  state,
}: StatusIndicatorProps) => {
  const currentDate = new Date();

  let status = 'upcoming';

  if (currentDate > endDate) {
    status = 'past';
  } else if (currentDate >= startDate) {
    status = 'live';
  }

  if (currentDate.toDateString() === endDate.toDateString() && endTime) {
    const [time, period] = endTime.split(' ');
    const [hours, minutes] = time.split(':');
    let adjustedHours = parseInt(hours);

    if (period === 'PM' && adjustedHours !== 12) {
      adjustedHours += 12;
    }

    if (period === 'AM' && adjustedHours === 12) {
      adjustedHours = 0;
    }

    const eventEndTime = new Date();
    eventEndTime.setHours(adjustedHours, parseInt(minutes), 0, 0);

    if (currentDate >= eventEndTime) {
      status = 'past';
    } else {
      status = 'live';
    }
  }

  if (currentDate.toDateString() === startDate.toDateString() && startTime) {
    const [time, period] = startTime.split(' ');
    const [hours, minutes] = time.split(':');
    let adjustedHours = parseInt(hours);

    if (period === 'PM' && adjustedHours !== 12) {
      adjustedHours += 12;
    }

    if (period === 'AM' && adjustedHours === 12) {
      adjustedHours = 0;
    }

    const eventStartTime = new Date();
    eventStartTime.setHours(adjustedHours, parseInt(minutes), 0, 0);

    if (currentDate < eventStartTime) {
      status = 'upcoming';
    } else {
      status = 'live';
    }
  }

  const indicatorColor =
    state === 'active'
      ? '#10B981'
      : state === 'inactive'
      ? '#475569'
      : status === 'live'
      ? '#F43F5E'
      : status === 'past'
      ? '#64748B'
      : '#CBD5E1';

  return (
    <Box
      w={4}
      h={height}
      bg={indicatorColor}
      style={{
        borderRadius: '100px',
        flexShrink: 0,
      }}
    />
  );
};

export default StatusIndicator;
