import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import {
  DELETE_TIMING_LOCATION_DEVICE,
  DELETE_TIMING_LOCATION_DEVICES,
} from 'utils/apiUrl';

export class TimingLocationDeviceService extends ResponseInterceptor {
  static async deleteTimingLocationDevice(
    eventId: string,
    layoutId: string,
    sectionId: string,
    gatingId: string,
  ): Promise<APIAxiosResponse<string>> {
    const response = await client.delete(
      DELETE_TIMING_LOCATION_DEVICE(eventId, layoutId, sectionId, gatingId),
    );
    return this.handleResponse(response);
  }

  static async deleteTimingLocationDevices(
    eventId: string,
    layoutId: string,
    sectionId: string,
    ids: string[],
  ): Promise<APIAxiosResponse<string[]>> {
    const response = await client.delete(
      DELETE_TIMING_LOCATION_DEVICES(eventId, layoutId, sectionId),
      {
        data: { ids },
      },
    );
    return this.handleResponse(response);
  }
}
