import {
  ArrowsPointingInIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { ActionIcon, Stack, StackProps } from '@mantine/core';

import { ZoomControlProps } from '../types';

const ZoomControl = ({
  scale,
  setScale,
  ...props
}: ZoomControlProps & StackProps) => {
  const handleZoomIn = () => {
    if (scale < 7) setScale((prev) => Math.min(prev * 1.2, 10));
  };

  const handleZoomOut = () => {
    if (scale > 1) setScale((prev) => Math.max(prev / 1.2, 0.5));
  };

  const handleResetZoom = () => {
    setScale(1);
  };

  return (
    <Stack {...props}>
      <ActionIcon
        bg="#ffffff"
        c="#64748b"
        bd="1px solid #e2e8f0"
        onClick={handleResetZoom}
      >
        <ArrowsPointingInIcon height="1.5rem" width="1.5rem" />
      </ActionIcon>
      <ActionIcon
        bg="#ffffff"
        c="#64748b"
        bd="1px solid #e2e8f0"
        onClick={handleZoomIn}
      >
        <PlusIcon height="1.5rem" width="1.5rem" />
      </ActionIcon>
      <ActionIcon
        bg="#ffffff"
        c="#64748b"
        bd="1px solid #e2e8f0"
        onClick={handleZoomOut}
      >
        <MinusIcon height="1.5rem" width="1.5rem" />
      </ActionIcon>
    </Stack>
  );
};

export default ZoomControl;
