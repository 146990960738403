import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { EventNotification } from 'pages/Events/Board/EventBoard.interface';
import { client } from 'services/api';
import { Response } from 'types/response';
import { GET_EVENT_BOARD_LIVE, GET_EVENT_BOARD_MUTED } from 'utils/apiUrl';

import { eventBoardBaseKey } from './eventBoardBaseKey';

interface EventNotificationProps
  extends UseQueryOptions<Response<EventNotification[]>, AxiosError> {
  eventId,
  limit,
  offset,
  filter,
  type: 'live' | 'muted',
  update: number,
}

export const useFetchEventBoard = ({
  eventId,
  limit,
  offset,
  filter,
  type,
  update,
  ...rest
}: EventNotificationProps) => {
  const queryClient = useQueryClient();
  const queryResult = useQuery<
    Response<EventNotification[]>,
    AxiosError
  >({
    queryKey: [
      eventBoardBaseKey,
      eventId,
      limit,
      offset,
      filter,
      type,
      update
    ],
    queryFn: async () => {
      const retVal = await client.get<Response<EventNotification[]>>(
        type === 'muted' ? 
        GET_EVENT_BOARD_MUTED(
          eventId,
          limit,
          offset,
          filter,
        ) :
        GET_EVENT_BOARD_LIVE(
          eventId,
          limit,
          offset,
          filter,
        ),
      );

      retVal.data.data.forEach(async (eventTag) =>
        queryClient.setQueryData<EventNotification>(
          [eventBoardBaseKey, eventTag.id],
          eventTag,
        ),
      );
      return retVal.data;
    },
    ...rest,
  });

  return queryResult;
};
