import { forwardRef } from 'react';

import { SvgIconProps } from './interface';

const DeviceIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  (
    {
      title,
      titleId,
      height = '1em',
      width = '1em',
      fill = 'currentColor',
      ...props
    },
    ref,
  ) => (
    <svg
      ref={ref}
      height={height}
      width={width}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M3.6941 0.434044C4.00668 0.705834 4.03974 1.17956 3.76795 1.49213C1.92042 3.6169 1.34034 6.4641 2.0322 9.04403C2.13949 9.44411 1.90213 9.85541 1.50206 9.9627C1.10198 10.07 0.690677 9.83264 0.583389 9.43256C-0.233058 6.38802 0.449656 3.02234 2.63602 0.507892C2.90781 0.195317 3.38153 0.162254 3.6941 0.434044Z"
        fill={fill}
      />
      <path
        d="M16.3028 0.434083C16.6154 0.162293 17.0891 0.195356 17.3609 0.507931C19.5473 3.02238 20.23 6.38806 19.4136 9.4326C19.3063 9.83267 18.895 10.07 18.4949 9.96274C18.0948 9.85545 17.8575 9.44415 17.9647 9.04407C18.6566 6.46414 18.0765 3.61694 16.229 1.49217C15.9572 1.17959 15.9903 0.705872 16.3028 0.434083Z"
        fill={fill}
      />
      <path
        d="M13.775 2.96573C14.0999 2.7088 14.5715 2.7639 14.8285 3.0888C16.0615 4.64797 16.4306 6.66365 15.9402 8.4947C15.833 8.89482 15.4218 9.1323 15.0217 9.02514C14.6216 8.91798 14.3841 8.50676 14.4912 8.10665C14.8629 6.71886 14.5817 5.19494 13.6519 4.01923C13.395 3.69433 13.4501 3.22267 13.775 2.96573Z"
        fill={fill}
      />
      <path
        d="M6.2251 2.96578C6.55 3.22271 6.6051 3.69438 6.34817 4.01927C5.4184 5.19499 5.13714 6.7189 5.50883 8.10669C5.61598 8.5068 5.3785 8.91803 4.97839 9.02518C4.57827 9.13234 4.16705 8.89486 4.05989 8.49475C3.56949 6.6637 3.93859 4.64802 5.17161 3.08884C5.42854 2.76394 5.9002 2.70884 6.2251 2.96578Z"
        fill={fill}
      />
      <path
        d="M10 6.14865C10.4142 6.14865 10.75 6.48444 10.75 6.89865V11.5486H16.1499H16.1499C16.6285 11.5486 17.0324 11.5486 17.3633 11.5756C17.7098 11.6039 18.0417 11.6656 18.3576 11.8265C18.8374 12.071 19.2275 12.4611 19.472 12.9409C19.6329 13.2568 19.6946 13.5887 19.7229 13.9353C19.75 14.2662 19.7499 14.6701 19.7499 15.1486V15.7485C19.7499 16.227 19.75 16.631 19.7229 16.9619C19.6946 17.3084 19.6329 17.6403 19.472 17.9562C19.2275 18.436 18.8374 18.8261 18.3576 19.0706C18.0417 19.2315 17.7098 19.2932 17.3633 19.3215C17.0324 19.3485 16.6284 19.3485 16.1499 19.3485H3.85017C3.37162 19.3485 2.9677 19.3485 2.6368 19.3215C2.29027 19.2932 1.95831 19.2315 1.64244 19.0706C1.16263 18.8261 0.772537 18.436 0.528062 17.9562C0.367118 17.6403 0.305457 17.3084 0.277145 16.9619C0.250109 16.6309 0.250119 16.227 0.25013 15.7485V15.1487C0.250119 14.6701 0.250109 14.2662 0.277145 13.9353C0.305457 13.5887 0.367118 13.2568 0.528062 12.9409C0.772537 12.4611 1.16263 12.071 1.64244 11.8265C1.95831 11.6656 2.29027 11.6039 2.6368 11.5756C2.96769 11.5486 3.37159 11.5486 3.85013 11.5486H3.85017H9.25003V6.89865C9.25003 6.48444 9.58582 6.14865 10 6.14865Z"
        fill={fill}
      />
    </svg>
  ),
);

DeviceIcon.displayName = 'DeviceIcon';

export default DeviceIcon;
