import QRCode from 'react-qr-code';
type Props = {
  value: string;
  qrId?: string;
};

const QR = (props: Props) => (
  <>
    <QRCode size={256} value={props.value} viewBox={`0 0 256 256`} />
    <div style={{ textAlign: 'center' }}>{props.qrId}</div>
  </>
);

export default QR;
