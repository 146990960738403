import { XMarkIcon } from '@heroicons/react/24/solid';
import { TextInput } from '@mantine/core';
import React, { useState, useEffect, useRef } from 'react';

interface GoogleAutocompleteProps {
  onAddressSelect: (
    lat: number,
    lng: number,
    place: string,
    metadata: any,
  ) => void;
  name?: string;
}

const GoogleAutocomplete: React.FC<GoogleAutocompleteProps> = ({
  onAddressSelect,
  name,
}) => {
  const [place, setPlace] = useState<string>('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ['geocode'],
        },
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const lat = place.geometry?.location?.lat() || 0;
        const lng = place.geometry?.location?.lng() || 0;
        const formattedAddress = place.formatted_address || '';
        const metadata = place;

        onAddressSelect(lat, lng, formattedAddress, metadata);
        setPlace(formattedAddress);
      });
    }
  }, [onAddressSelect]);

  useEffect(() => {
    if (name) setPlace(name);
  }, [name]);

  const handleClear = () => {
    setPlace('');
    onAddressSelect(0, 0, '', null);
  };

  return (
    <TextInput
      ref={inputRef}
      label="Address"
      placeholder="e.g. 749 Williamson Lane"
      value={place}
      onChange={(e) => setPlace(e.target.value)}
      rightSection={
        place ? (
          <XMarkIcon
            height={20}
            width={20}
            color="var(--gray-900)"
            cursor="pointer"
            onClick={handleClear}
          />
        ) : null
      }
    />
  );
};

export default GoogleAutocomplete;
