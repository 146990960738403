import {
  ArrowTurnUpLeftIcon,
  CameraIcon,
  MagnifyingGlassIcon,
  QrCodeIcon,
} from '@heroicons/react/24/solid';
import {
  ActionIcon,
  Button,
  Divider,
  Flex,
  Grid,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import CustomQrScanner from 'components/CustomQrScanner/CustomQrScanner';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import { forwardRef } from 'react';

import AdmissionCard from '../AdmissionCard/AdmissionCard';
import AdmissionDropdown from '../Dropdown/AdmissionDropdown';
import NotFoundCodeComponent from '../NotFoundCodeComponent/NotFoundCodeComponent';
import PlaceHolderAdmissionCard from '../PlaceHolderAdmissionCard/PlaceHolderAdmissionCard';
import UnknownAdmissionCard from '../PlaceHolderAdmissionCard/UnknownAdmissionCard';
import { AdmissionSectionProps } from '../types';

const AdmissionSection = forwardRef<HTMLInputElement, AdmissionSectionProps>(
  (
    {
      autoSubmit,
      isMobile,
      disableCameraButton,
      setAutoSubmit,
      allowMultitagging,
      setAllowMultitagging,
      cameraDelay,
      setCameraDelay,
      qrCode,
      setQrCode,
      isUnknownCode,
      setIsUnknownCode,
      toggleScanner,
      isScanning,
      tempQrCode,
      setTempQrCode,
      handleScan,
      scanned,
      setScanned,
      handleDeleteAdmissionItem,
      handleClearQrCode,
      handleAddUnknownCode,
      handleAssign,
    }: AdmissionSectionProps,
    ref,
  ) => {
    const handleError = (err) => {
      console.error(err);
    };

    return (
      <Flex
        h="100%"
        bg="#ffffff"
        bd="1px solid #e2e8f0"
        direction="column"
        style={{ borderRadius: '0.75rem', flexGrow: 1 }}
      >
        <Flex
          direction="column"
          align="flex-start"
          justify="sp"
          p={isMobile ? '1rem 0 1rem 1rem' : '1.5rem 0 1.5rem 1.5rem'}
        >
          <Flex align="center" justify="space-between" w="100%">
            <Flex align="center">
              <QrCodeIcon height="1.5rem" width="1.5rem" color="#94a3b8" />
              <Text
                fz={isMobile ? '1rem' : '1.125rem'}
                fw={600}
                c="var(--gray-900)"
                ml="0.75rem"
              >
                Admission
              </Text>
            </Flex>
            <AdmissionDropdown
              autoSubmit={autoSubmit}
              setAutoSubmit={setAutoSubmit}
              allowMultitagging={allowMultitagging}
              setAllowMultitagging={setAllowMultitagging}
              cameraDelay={cameraDelay}
              setCameraDelay={setCameraDelay}
            />
          </Flex>
          <Text fz={isMobile ? '0.75rem' : '0.875rem'} c="#64748B" mt="0.25rem">
            Enter or scan for tracking object and tag.
          </Text>
        </Flex>

        <Divider
          ml={isMobile ? '1rem' : '1.5rem'}
          mr={isMobile ? '1rem' : '1.5rem'}
          mb={isMobile ? '1rem' : '1.5rem'}
        />

        <Flex
          justify="center"
          mx={isMobile ? '1rem' : '1.5rem'}
          direction="column"
        >
          <Flex align="center">
            <TextInput
              ref={ref}
              size={isMobile ? 'xs' : 'sm'}
              mr="0.75rem"
              fz="1rem"
              radius="md"
              flex={1}
              placeholder="Search"
              value={tempQrCode}
              onChange={(e) => setTempQrCode(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const normalizedData = tempQrCode.toLowerCase();
                  setQrCode(
                    normalizedData.includes('http')
                      ? normalizedData.split('/').pop() || ''
                      : normalizedData,
                  );
                  if (tempQrCode === '') {
                    setIsUnknownCode(false);
                  }
                }
              }}
              leftSection={
                <MagnifyingGlassIcon
                  height={isMobile ? '1rem' : '1.25rem'}
                  width={isMobile ? '1rem' : '1.25rem'}
                  color="#94A3B8"
                />
              }
            />

            <Flex align="center" gap="0.75rem">
              <ActionIcon
                bg="#ffffff"
                onClick={toggleScanner}
                size={isMobile ? 'md' : 'lg'}
                disabled={disableCameraButton}
              >
                <CameraIcon
                  height={isMobile ? '1.25rem' : '1.5rem'}
                  width={isMobile ? '1.25rem' : '1.5rem'}
                  color={
                    disableCameraButton ? 'var(--gray-400)' : 'var(--gray-900)'
                  }
                />
              </ActionIcon>
            </Flex>
          </Flex>

          <Text mt="0.2rem" fz="0.75rem" c="#94A3B8" ta="left">
            <ArrowTurnUpLeftIcon width="0.75rem" height="0.65rem" /> Press enter
            to search
          </Text>
        </Flex>
        <Grid mx={isMobile ? 8 : 16}>
          <Grid.Col span={12}>
            <ScrollArea
              style={{ height: '100%', mr: '-0.75rem', pr: '0.5rem' }}
              mr="-0.75rem"
              pr="0.5rem"
              type={isMobile ? 'never' : 'always'}
              scrollbarSize={8}
              scrollbars="y"
              offsetScrollbars
            >
              {!isUnknownCode && (
                <Grid.Col span={12}>
                  {isScanning && (
                    <CustomQrScanner
                      onScan={handleScan}
                      onError={handleError}
                    />
                  )}
                  {!scanned?.some((x) => x.isTagCard === false) && (
                    <PlaceHolderAdmissionCard
                      isCollapsed={isScanning}
                      isTagCard={false}
                    />
                  )}

                  {scanned?.length > 0 && (
                    <Flex direction="column" gap={12} mt={12}>
                      {scanned.map((x) => (
                        <AdmissionCard
                          key={x._id}
                          id={x._id}
                          isCollapsedCard={
                            isScanning ||
                            (scanned.filter((x) => x.isTagCard === true)
                              .length > 1 &&
                              x.isTagCard)
                          }
                          isTagCard={x.isTagCard}
                          isWarningState={x.rightContent > 0}
                          title={x.title}
                          subTitle={x.subtitle ?? ''}
                          leftContent={x.leftContent}
                          rightContent={x.rightContent}
                          trackingObjectImage={null}
                          handleDelete={handleDeleteAdmissionItem}
                        />
                      ))}
                    </Flex>
                  )}
                </Grid.Col>
              )}

              {!isUnknownCode && (
                <Grid.Col span={12}>
                  {!scanned?.some((x) => x.isTagCard === true) && (
                    <PlaceHolderAdmissionCard
                      isCollapsed={isScanning}
                      isTagCard={true}
                    />
                  )}
                </Grid.Col>
              )}
              {isUnknownCode && (
                <Grid.Col span={12}>
                  <NotFoundCodeComponent
                    code={qrCode ?? ''}
                    handleClearQrCode={handleClearQrCode}
                  />
                  <UnknownAdmissionCard
                    isTagCard={false}
                    handleAddUnknownCode={handleAddUnknownCode}
                  />
                  <UnknownAdmissionCard
                    isTagCard={true}
                    handleAddUnknownCode={handleAddUnknownCode}
                  />
                </Grid.Col>
              )}
            </ScrollArea>
          </Grid.Col>
        </Grid>

        <Divider mt="auto" />
        <Stack bg="#F8FAFC" style={{ borderRadius: '0.75rem' }}>
          <Flex
            justify="space-between"
            mx={isMobile ? '8px' : '12px'}
            mb={isMobile ? '1rem' : '1.5rem'}
            mt={isMobile ? '1rem' : '1.5rem'}
            gap={isMobile ? 12 : 24}
          >
            <Button
              variant="outline"
              color="var(--gray-900)"
              bg="#fff"
              size={isMobile ? 'sm' : 'md'}
              style={{
                flex: 1,
                border: '1px solid #E2E8F0',
              }}
              onClick={() => {
                setScanned([]);
                handleClearQrCode();
              }}
            >
              Clear
            </Button>
            <Button
              variant="outline"
              color="var(--gray-900)"
              bg="#fff"
              size={isMobile ? 'sm' : 'md'}
              style={{
                flex: 1,
                border: '1px solid #E2E8F0',
              }}
              onClick={(e) => {
                e.preventDefault();
                handleAssign();
              }}
              disabled={
                !(
                  scanned?.some((x) => x.isTagCard === true) &&
                  scanned?.some((x) => x.isTagCard === false)
                )
              }
            >
              Assign
            </Button>
          </Flex>
        </Stack>
      </Flex>
    );
  },
);

export default AdmissionSection;
