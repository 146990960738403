import { InputFileButton } from 'components/Button/InputFileButton/InputFileButton';
import { ChangeEvent } from 'react';

import styles from './UploadAnother.module.scss';

type UploadAnotherProps = {
  btnText?: string;
  picture: File | string;
  onUploadImage: (file: File | null) => void;
};

export const UploadAnother = ({
  btnText = 'Upload another image',
  picture,
  onUploadImage,
}: UploadAnotherProps) => {
  const uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] ?? null;

    onUploadImage(file);
  };

  return (
    <div className={styles.uploadAnother}>
      <div className={styles.inputFileButtonWrapper}>
        <InputFileButton text={btnText} onUploadImage={uploadImage} />
      </div>

      <div className={styles.uploadedImage}>
        <img
          src={
            typeof picture === 'string' ? picture : URL.createObjectURL(picture)
          }
          alt=""
        />
      </div>
    </div>
  );
};
