import { notification } from '@lanaco/lnc-react-ui';
import PromptModal from 'components/PromptModal/PromptModal';
import { useMutateStatusClient } from 'hooks/client/useMutateStatusClient';
import { getErrorMsg } from 'utils/getErrorMsg';

import { ClientsTableData } from '../interfaces';

interface ToggleEnabledClientModalProps {
  id: string;
  status?: string;
  name?: string;
  closeModal: () => void;
  onClientEdit: (device: ClientsTableData | null) => void;
}

export const ToggleEnabledClientModal = ({
  id,
  name,
  status,
  closeModal,
  onClientEdit,
}: ToggleEnabledClientModalProps) => {
  const { mutate, isLoading } = useMutateStatusClient();

  const closeToggleEnabledModal = () => {
    onClientEdit(null);
    closeModal();
  };

  const toggleClientEnabled = () => {
    mutate(
      {
        id,
        payload: { status: status === 'active' ? 'disabled' : 'active' },
      },
      {
        onSuccess: (data) => {
          if (data?.status !== status) {
            const msg = `Client successfully ${
              status === 'active' ? 'disabled' : 'enabled'
            }`;
            notification.success(msg);
          } else {
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          closeToggleEnabledModal();
        },
      },
    );
  };

  return (
    <PromptModal
      title={status === 'active' ? 'Disable client?' : 'Enable client'}
      isLoading={isLoading}
      confirmButtonName={status === 'active' ? 'Disable' : 'Enable'}
      icon="power-off"
      warningMessage={
        <>
          Client {name} will be {status === 'active' ? 'disabled' : 'enabled'}.
          <br />
          You can {status === 'active' ? 'enable' : 'disable'} it again at any
          time.
        </>
      }
      onConfirm={toggleClientEnabled}
      onCancel={closeModal}
    />
  );
};
