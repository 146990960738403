import { SetModalContentParams } from 'pages/Entities/ModalManager/types';

import EntityInformationsContent from './EntityInformationsContent';
import EntityScanningContent from './EntityScanningContent';
import EntitySocialMediaContent from './EntitySocialMediaContent';

export const setEntityModalContent = ({
  step,
  dataId,
  formState,
  setFormState,
  isFormValid,
  setIsFormValid,
  setOnFormSubmit,
}: SetModalContentParams) => {
  return (
    <>
      {step === 1 && (
        <EntityInformationsContent
          formState={formState}
          dataId={dataId}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
          setOnFormSubmit={setOnFormSubmit}
        />
      )}
      {step === 2 && (
        <EntityScanningContent
          formState={formState}
          dataId={dataId}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
          setOnFormSubmit={setOnFormSubmit}
        />
      )}
      {step === 3 && (
        <EntitySocialMediaContent
          formState={formState}
          dataId={dataId}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
          setOnFormSubmit={setOnFormSubmit}
        />
      )}
    </>
  );
};
