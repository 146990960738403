import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { EventTagTableData } from 'pages/Events/Tags/EventTag.interface';
import { client } from 'services/api';
import { Response } from 'types/response';
import { GET_EVENT_ASSIGNABLE_TAGS } from 'utils/apiUrl';

import { eventTagGetAssignableKey } from './baseKeys';

interface TagsProps {
  eventId: string;
  limit?: number;
  offset?: number;
  filter?: string;
  participantId?: string;
}

export const useFetchEventAssignableTags = ({
  eventId,
  limit,
  offset,
  filter,
  participantId,
}: TagsProps) => {
  const queryClient = useQueryClient();
  const queryResult = useQuery<Response<EventTagTableData[]>, AxiosError>({
    queryKey: [
      eventTagGetAssignableKey,
      eventId,
      limit,
      offset,
      filter,
      participantId,
    ],
    queryFn: async () => {
      const retVal = await client.get<Response<EventTagTableData[]>>(
        GET_EVENT_ASSIGNABLE_TAGS(eventId, limit, offset, filter),
        { params: { participantId } },
      );

      retVal.data.data.forEach(async (eventTag) =>
        queryClient.setQueryData<EventTagTableData>(
          [eventTagGetAssignableKey, eventTag._id],
          eventTag,
        ),
      );
      return retVal.data;
    },
  });

  return queryResult;
};
