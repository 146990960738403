import { FormField } from '@lanaco/lnc-react-ui';
import { FlexGrid } from '@lanaco/lnc-react-ui';
import { FlexGridItem } from '@lanaco/lnc-react-ui';
import { TextInput } from '@lanaco/lnc-react-ui';
import { Dropdown } from '@lanaco/lnc-react-ui';
import { FlexBox } from '@lanaco/lnc-react-ui';
import { notification } from '@lanaco/lnc-react-ui';
import { useQueryClient } from '@tanstack/react-query';
import SubmitModal from 'components/SubmitModal/SubmitModal';
import { tagStatisticsBaseKey } from 'hooks/event/statistics';
import { tagBaseKey } from 'hooks/tag/baseKey';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { client } from 'services/api';
import { INSERT_TAG, UPDATE_TAG } from 'utils/apiUrl';
import { statusOptions } from 'utils/dropdown-options';

import { IFormInput } from './interfaces';

type Props = {
  closeModal: any;
  // submitForm: any;
  data: any;
  isEdit: boolean;
};

const TagForm = (props: Props) => {
  const [spinner, setSpinner] = useState(false);
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormInput>();

  //const { onChangeDropdown, status } = useDropdown('status', watch, setValue);

  useEffect(() => {
    reset(props.data);
  }, [props.data, reset]);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      setSpinner(true);
      if (props.isEdit) {
        const response = await client.put(UPDATE_TAG(data._id), data);
        if (response.data.code === 201) {
          setSpinner(false);
          props.closeModal();
          notification.success('Tag successfully updated.');
        } else {
          setSpinner(false);
          notification.error('Unable to update tag. ' + response.data.message);
        }
      } else {
        const response = await client.post(INSERT_TAG, data);
        if (response.data.code === 201) {
          setSpinner(false);
          props.closeModal();
          notification.success('Tag successfully added.');
        } else {
          setSpinner(false);
          notification.error('Unable to add tag. ' + response.data.message);
        }
      }
    } catch (error) {
      setSpinner(false);
      notification.error(error);
    } finally {
      queryClient.invalidateQueries([tagBaseKey]);
      queryClient.invalidateQueries([tagStatisticsBaseKey]);
    }
  };

  return (
    <div>
      <span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexGrid spacing={10}>
            <input
              type="hidden"
              {...register('_id')}
              value={props.data && props.data.id}
            />
            <FlexGridItem XS={12} S={12}>
              <FormField
                label="Tag name"
                text={errors.name && <span>{errors.name.message}</span>}
                color={errors.name && 'danger'}
              >
                <TextInput
                  placeholder={'Tag name'}
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'Name is required.',
                    },
                  })}
                  value={props.data && props.data.name}
                  color={errors.name && 'danger'}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6}>
              <FormField
                label="Tag ID"
                text={errors.uniqueId && <span>{errors.uniqueId.message}</span>}
                color={errors.uniqueId && 'danger'}
              >
                <TextInput
                  placeholder={'e.g. ?'}
                  {...register('uniqueId', {
                    required: {
                      value: true,
                      message: 'Unique Id is required.',
                    },
                  })}
                  color={errors.uniqueId && 'danger'}
                  value={props.data && props.data.uniqueId}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6}>
              <FormField
                label="Tag number"
                text={errors.number && <span>{errors.number.message}</span>}
                color={errors.number && 'danger'}
              >
                <TextInput
                  placeholder={'e.g. ?'}
                  value={props.data && props.data.number}
                  {...register('number', {
                    required: {
                      value: true,
                      message: 'Number is required.',
                    },
                  })}
                  color={errors.number && 'danger'}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6}>
              <FormField label="Manufacturer">
                <TextInput
                  placeholder={'e.g. ?'}
                  value={props.data && props.data.manufacturer}
                  {...register('manufacturer')}
                  color={errors.manufacturer && 'danger'}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6}>
              <FormField label="Tag capacitance">
                <TextInput
                  disabled
                  placeholder={'e.g. ?'}
                  {...register('capacitance')}
                  value={props.data && props.data.capacitance}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6}>
              <FormField label="Tag Q">
                <TextInput
                  disabled
                  placeholder={'e.g. ?'}
                  {...register('q')}
                  value={props.data && props.data.q}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6}>
              <FormField label="Tag frequency">
                <TextInput
                  disabled
                  placeholder={'e.g. ?'}
                  {...register('frequency')}
                  value={props.data && props.data.frequency}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6}>
              <FormField label="Status">
                <Dropdown
                  placeholder={'Status'}
                  {...register('status')}
                  options={statusOptions}
                  value={props.data && props.data.status}
                  // value={status}
                  // onChange={onChangeDropdown}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem XS={12} S={6}>
              <FormField label="Tag resistance">
                <TextInput
                  disabled
                  placeholder={'e.g. ?'}
                  {...register('resistance')}
                  value={props.data && props.data.resistance}
                />
              </FormField>
            </FlexGridItem>
            <FlexGridItem col={12}>
              <FlexBox justifyContent={'End'} gap={'8px'}>
                <SubmitModal
                  closeModal={props.closeModal}
                  spinner={spinner}
                  buttonText={props.isEdit ? 'Save' : 'Add'}
                ></SubmitModal>
              </FlexBox>
            </FlexGridItem>
          </FlexGrid>
        </form>
      </span>
    </div>
  );
};

export default TagForm;
