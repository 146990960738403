import { Spinner } from '@lanaco/lnc-react-ui';
import clsx from 'clsx';

import { SpinnerColor, SpinnerSize } from './interfaces';
import style from './Spinner.module.css';

interface TLLoadingSpinnerProps {
  label?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: SpinnerSize;
  color?: SpinnerColor;
}

export const TLLoadingSpinner = ({
  color = SpinnerColor.PRIMARY,
  size = SpinnerSize.SMALL,
  className,
  ...props
}: TLLoadingSpinnerProps) => (
  <div className={clsx(style.loadingSpinner, className)}>
    <Spinner color={color} size={size} {...props} />
  </div>
);
