import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Button, Menu, Switch } from '@mantine/core';
import { useState } from 'react';

import { MapControlProps } from '../types';

const MapControl = ({ mapControl, setMapControl }: MapControlProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>();

  return (
    <Menu
      width={234}
      position="bottom-end"
      offset={0}
      onOpen={() => setIsDropdownOpen(true)}
      onClose={() => setIsDropdownOpen(false)}
    >
      <Menu.Target>
        <Button
          bg={isDropdownOpen ? '#e2e8f0' : '#ffffff'}
          c="#344155"
          bd="1px solid #e2e8f0"
          fz="0.875rem"
          fw={500}
          rightSection={<ChevronDownIcon height="1.25rem" width="1.25rem" />}
        >
          Map control
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          rightSection={
            <Switch
              size="md"
              checked={mapControl.sectionsPins}
              onChange={() =>
                setMapControl((prev) => {
                  return {
                    ...prev,
                    sectionsPins: !prev.sectionsPins,
                  };
                })
              }
              styles={{
                track: {
                  backgroundColor: mapControl.sectionsPins
                    ? '#059669'
                    : '#e11d48',
                  cursor: 'pointer',
                },
              }}
            />
          }
          closeMenuOnClick={false}
          styles={{ item: { cursor: 'default' } }}
        >
          Sections pins
        </Menu.Item>
        <Menu.Item
          rightSection={
            <Switch
              size="md"
              checked={mapControl.sectionsArea}
              onChange={() =>
                setMapControl((prev) => {
                  return {
                    ...prev,
                    sectionsArea: !prev.sectionsArea,
                  };
                })
              }
              styles={{
                track: {
                  backgroundColor: mapControl.sectionsArea
                    ? '#059669'
                    : '#e11d48',
                  cursor: 'pointer',
                },
              }}
            />
          }
          closeMenuOnClick={false}
          styles={{ item: { cursor: 'default' } }}
        >
          Sections area
        </Menu.Item>
        <Menu.Item
          rightSection={
            <Switch
              size="md"
              checked={mapControl.devices}
              onChange={() =>
                setMapControl((prev) => {
                  return {
                    ...prev,
                    devices: !prev.devices,
                  };
                })
              }
              styles={{
                track: {
                  backgroundColor: mapControl.devices ? '#059669' : '#e11d48',
                  cursor: 'pointer',
                },
              }}
            />
          }
          closeMenuOnClick={false}
          styles={{ item: { cursor: 'default' } }}
        >
          Devices
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default MapControl;
