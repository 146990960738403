import {
  ArrowsUpDownIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  XMarkIcon,
  TrashIcon,
  PlusIcon,
  EllipsisHorizontalIcon,
  PencilSquareIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/20/solid';
import { Button, Menu } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { FilterButtonGroupType } from 'components/FilterButtonGroup/types';
import HeaderAndFilterGroup from 'components/HeaderAndFilterGroup/HeaderAndFilterGroup';
import TableFooter from 'components/TableFooter/TableFooter';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Icons,
  useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useRef, useState } from 'react';

import active from '../../../assets/icons/indicator-online.svg';
import inactive from '../../../assets/icons/indicator-past.svg';
import { openModal } from '../ModalManager/ModalsManager';

import styles from './EntityControlAccess.module.scss';
import { setControlAccessModalContent } from './Modals/controlAccessModalsConfig';
import { ControlAccesTrackingObjectsTableType } from './types';

const heroIcons: Partial<MRT_Icons> = {
  IconArrowsSort: (props: any) => (
    <ArrowsUpDownIcon width={20} height={20} color="#64748B" />
  ),
  IconSortAscending: (props: any) => (
    <ArrowUpIcon width={20} height={20} color="#64748B" />
  ),
  IconSortDescending: (props: any) => (
    <ArrowDownIcon width={20} height={20} color="#64748B" />
  ),
};

const ControlAccessTrackingObjects = () => {
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [rowsOnPage, setRowsOnPage] = useState<number>(5);
  const [currentRowCount, setCurrentRowCount] = useState<number>(5);
  const [limit, setLimit] = useState<number>(5);

  const hasSelection = Object.keys(rowSelection).length > 0;

  const { height } = useViewportSize();
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleRowsOnPageChange = (newRows: number) => {
    setRowsOnPage(newRows);
    setLimit(newRows);
  };

  const buttons: FilterButtonGroupType[] = [
    {
      text: 'All',
      indicator: '',
      onClick: () => {},
    },
    {
      text: 'Active',
      indicator: active,
      onClick: () => {},
    },
    {
      text: 'Inactive',
      indicator: inactive,
      onClick: () => {},
    },
  ];

  const columns = useMemo<
    MRT_ColumnDef<ControlAccesTrackingObjectsTableType>[]
  >(
    () => [
      {
        maxSize: 5,
        minSize: 2,
        id: 'statusIndicator',
        header: '',

        // Cell: ({ row }) => {
        //   const status = row.original.status.toLowerCase();
        //   return (
        //     <StatusIndicator
        //       height="2rem"
        //       startDate={new Date()}
        //       endDate={new Date()}
        //       status1={status === 'active' ? 'active' : 'inactive'}
        //     />
        //   );
        // },
      },
      {
        maxSize: 220,
        minSize: 180,
        accessorKey: 'type',
        header: 'Type',
        enableSorting: true,
        Cell: ({ cell }) => {
          return (
            <div className={styles.firstColumn}>
              {cell.getValue() as React.ReactNode}
            </div>
          );
        },
      },
      {
        maxSize: 200,
        minSize: 180,
        accessorKey: 'subType',
        header: 'Sub-Type',
        enableSorting: true,
      },
      {
        maxSize: 220,
        minSize: 180,
        accessorKey: 'name',
        header: 'Name',
        enableSorting: true,
      },
      {
        maxSize: 220,
        minSize: 180,
        accessorKey: 'externalId',
        header: 'External ID',
        enableSorting: true,
      },
      {
        maxSize: 220,
        minSize: 180,
        accessorKey: 'tagId',
        header: 'Tag ID',
        enableSorting: true,
      },
      {
        maxSize: 40,
        minSize: 30,
        id: 'actions',
        enableSorting: false,
        header: '',
        accessorKey: 'actions',
        Cell: () => (
          <Menu position="bottom-end">
            <Menu.Target>
              <button className={styles.actionMenuItem}>
                <EllipsisHorizontalIcon
                  color="#475569"
                  height={20}
                  width={20}
                />
              </button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item className={styles.menuItem} onClick={() => {}}>
                <PencilSquareIcon width={20} height={20} color="#3b82f6" />
                Edit
              </Menu.Item>
              <Menu.Item className={styles.menuItem} onClick={() => {}}>
                <TrashIcon width={20} height={20} color="#f43f5e" />
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ),
      },
    ],
    [],
  );
  const data = useMemo<ControlAccesTrackingObjectsTableType[]>(
    () => [
      {
        type: 'Your Day at a Glance',
        subType: 'Avery Sterling',
        name: 'BMW',
        externalId: 'BMW',
        tagId: 'BMW',
      },
      {
        type: 'Your Day at a Glance',
        subType: 'Avery Sterling',
        name: 'BMW',
        externalId: 'BMW',
        tagId: 'BMW',
      },
      {
        type: 'Your Day at a Glance',
        subType: 'Avery Sterling',
        name: 'BMW',
        externalId: 'BMW',
        tagId: 'BMW',
      },
      {
        type: 'Your Day at a Glance',
        subType: 'Avery Sterling',
        name: 'BMW',
        externalId: 'BMW',
        tagId: 'BMW',
      },
      {
        type: 'Your Day at a Glance',
        subType: 'Avery Sterling',
        name: 'BMW',
        externalId: 'BMW',
        tagId: 'BMW',
      },
      {
        type: 'Your Day at a Glance',
        subType: 'Avery Sterling',
        name: 'BMW',
        externalId: 'BMW',
        tagId: 'BMW',
      },
      {
        type: 'Your Day at a Glance',
        subType: 'Avery Sterling',
        name: 'BMW',
        externalId: 'BMW',
        tagId: 'BMW',
      },
      {
        type: 'Your Day at a Glance',
        subType: 'Avery Sterling',
        name: 'BMW',
        externalId: 'BMW',
        tagId: 'BMW',
      },
      {
        type: 'Your Day at a Glance',
        subType: 'Avery Sterling',
        name: 'BMW',
        externalId: 'BMW',
        tagId: 'BMW',
      },
    ],
    [],
  );
  const table = useMantineReactTable({
    columns,
    data,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableColumnPinning: true,
    icons: heroIcons,
    enableSorting: true,
    manualPagination: true,
    enableRowSelection: true,
    enableStickyHeader: true,
    enablePagination: false,

    initialState: {
      columnPinning: {
        right: ['actions'],
        left: ['statusIndicator'],
      },
    },
    mantineSelectAllCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantineSelectCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantineTableContainerProps: {
      ref: scrollRef,
      style: {
        maxHeight: height > 750 ? height / 1.8 : height / 2.2,
      },
    },

    mantinePaperProps: {
      style: {
        '--mrt-pinned-column-background-color': '#ffffff',
        '--mrt-pinned-row-background-color': '#333',
        boxShadow: 'none',
        borderRadius: 0,
        marginTop: -10,
        height: height > 750 ? height / 1.8 : height / 2.2,
        position: 'sticky',
        zIndex: 10,
        bottom: 0,
      },
    },

    mantineTableBodyRowProps: ({ row }) => {
      return {
        className: row.getIsSelected() ? styles.selectedRow : '',
      };
    },

    state: {
      rowSelection,
    },

    onRowSelectionChange: setRowSelection,
  });
  return (
    <div>
      {' '}
      <div className={styles.container}>
        <div className={styles.tableContainer}>
          <HeaderAndFilterGroup
            icon={ViewfinderCircleIcon}
            title="Tracking objects"
            buttons={buttons}
            hasBorder={false}
            width="100%"
            extraButton={
              hasSelection ? (
                <>
                  <Button
                    bg="#ffffff"
                    bd="1px solid #e2e8f0"
                    c="#344155"
                    mr={12}
                    radius={8}
                    leftSection={<XMarkIcon height={20} width={20} />}
                    onClick={() => setRowSelection({})}
                  >
                    Clear selection
                  </Button>
                  <Button
                    c="#f43f5e"
                    bg="#ffffff"
                    bd="1px solid #e2e8f0"
                    radius={8}
                    leftSection={
                      <TrashIcon
                        height="1.25rem"
                        width="1.25rem"
                        color="#f43f5e"
                      />
                    }
                  >
                    Delete
                  </Button>
                </>
              ) : (
                <Button
                  bg="#3B82F6"
                  radius="0.5rem"
                  leftSection={<PlusIcon height="1.25rem" width="1.25rem" />}
                  onClick={() =>
                    openModal({
                      type: 'addingTrackingObject',
                      size: 1440,
                      title: 'Adding tracking object',
                      titleDescription:
                        'Select a tracking object you want to assign to “TimeLine Tracking Object 001” control access',
                      hasFooter: false,
                      actionButtonFn: () => {},
                      setModalContent: setControlAccessModalContent,
                    })
                  }
                >
                  Add tracking object
                </Button>
              )
            }
          />
          <MantineReactTable table={table} />
          <TableFooter
            totalCount={10}
            currentCount={currentRowCount}
            rowsOptions={['2', '5', '7', '10']}
            rowsOnPage={rowsOnPage}
            onRowsOnPageChange={handleRowsOnPageChange}
          />
        </div>
      </div>
    </div>
  );
};
export default ControlAccessTrackingObjects;
