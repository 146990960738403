import { Card, Group, Text } from '@mantine/core';
import { EventParticipant, EventTag } from 'pages/Events/interfaces';

export interface EventAdmissionCardProps {
  tag: EventTag & { participant: EventParticipant };
}

export const EventAdmissionCard = ({ tag }: EventAdmissionCardProps) => {
  return (
    <Card withBorder padding="lg" radius="md">
      <Card.Section withBorder pt="md" inheritPadding bg="gray.1">
        <Group>
          <Text fz="lg" fw={500}>
            {tag.participant.firstLastName}
          </Text>
          <Text fz="sm" c="dimmed">
            {tag.participant.externalId}
          </Text>
        </Group>
      </Card.Section>

      <Card.Section inheritPadding py="md">
        <Text fz="md" fw={500} c="dimmed">
          Tag
        </Text>

        <Group>
          <Text fz="lg" fw={500}>
            {tag.number}
          </Text>
          <Text fz="sm" c="dimmed">
            {tag.uniqueId}
          </Text>
        </Group>
      </Card.Section>
    </Card>
  );
};
