import { notification } from '@lanaco/lnc-react-ui';
import { PaginationState } from '@tanstack/react-table';
import { RowSelectionState } from '@tanstack/table-core';
import { TLNeutralButton, TLSecondaryLoadingButton } from 'components/Button';
import { InputSearch } from 'components/Input';
import { TLModalFooter } from 'components/Modal';
import { NotAvailable } from 'components/NotAvailable';
import {
  useAddEventAssignableTags,
  useFetchEventAssignableTags,
} from 'hooks/event/tags';
import {
  MRT_ColumnDef,
  MantineReactTable,
  createMRTColumnHelper,
  useMantineReactTable,
} from 'mantine-react-table';
import { useState } from 'react';
import { getErrorMsg } from 'utils/getErrorMsg';

import { EventTagTableData } from './EventTag.interface';
import style from './EventTag.module.css';

type Props = {
  eventId: string;
  closeModal: () => void;
  eventName: string;
};

const columnHelper = createMRTColumnHelper<EventTagTableData>();
const ROW_LIMIT = 7;

const AddEventTagForm = ({ closeModal, eventName, eventId }: Props) => {
  const [search, setSearch] = useState('');
  const [pagination, onPaginationChange] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: ROW_LIMIT,
  });

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});

  const { data, isFetching } = useFetchEventAssignableTags({
    eventId: eventId as string,
    limit: ROW_LIMIT,
    offset: pagination.pageIndex * ROW_LIMIT,
    filter: search,
  });

  const { _route, data: eventAssignableTagData } = data || {
    _route: { returned: 0, total: 0 },
    data: [],
  };

  const noTagsAvailable = !search && eventAssignableTagData?.length === 0;

  const tagIds = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    },
    [],
  );

  const selectedTags = `${tagIds.length} ${tagIds.length > 1 ? 'tags' : 'tag'}`;

  const clearSelectedRows = () => setSelectedRows({});

  const mutation = useAddEventAssignableTags();

  const onAddHandler = async (e) => {
    e.preventDefault();
    mutation.mutate(
      { eventId: eventId, filter: search, payload: tagIds },
      {
        onSuccess: () => {
          clearSelectedRows();
          notification.success(`${selectedTags} added to ${eventName}`);
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
      },
    );
  };

  const eventTagTableColumns = [
    columnHelper.accessor('name', {
      header: 'Tag',
    }),
    columnHelper.accessor('number', {
      header: 'Number',
    }),
    columnHelper.accessor('uniqueId', {
      header: 'Tag ID',
    }),
  ] as MRT_ColumnDef<EventTagTableData, unknown>[];

  const table = useMantineReactTable({
    columns: eventTagTableColumns,
    data: eventAssignableTagData,
    getRowId: (row) => row._id,
    state: {
      rowSelection: selectedRows,
      isLoading: isFetching,
      pagination,
    },
    rowCount: _route.total,
    onRowSelectionChange: setSelectedRows,
    pageCount: Math.ceil(_route.total / ROW_LIMIT),
    manualPagination: true,
    onPaginationChange,
    enableRowSelection: true,
  });

  return (
    <div>
      <InputSearch
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={style.inputSearch}
      ></InputSearch>
      <form onSubmit={onAddHandler}>
        {!noTagsAvailable ? (
          <MantineReactTable table={table} />
        ) : (
          <NotAvailable message="Oops, no tags available at the moment." />
        )}
        <TLModalFooter
          className={style.modalFooter}
          cancelButton={
            <TLNeutralButton onClick={closeModal}>Cancel</TLNeutralButton>
          }
          actionButton={
            <TLSecondaryLoadingButton
              isLoading={isFetching}
              disabled={tagIds?.length === 0}
              type="submit"
            >
              Add
            </TLSecondaryLoadingButton>
          }
        />
      </form>
    </div>
  );
};

export default AddEventTagForm;
