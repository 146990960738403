import {
  FolderPlusIcon,
  BellSlashIcon,
  LockOpenIcon,
  LockClosedIcon,
  FolderMinusIcon,
  BellIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Badge, Flex, FlexProps, Stack, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { ControlAccessCardType } from './types';

const ControlAccessCard = ({
  title,
  entranceTime,
  status,
  nameOfLevel,
  exitTime,
  permissionStatus,
  notificationStatus,
  reportStatus,
  hoverable = false,
  style,
  ...props
}: ControlAccessCardType & FlexProps) => {
  const { hovered, ref } = useHover();
  return (
    <Flex
      ref={ref}
      p="0.75rem"
      bg={hoverable && hovered ? 'var(--gray-200)' : 'var(--gray-0)'}
      bd="1px solid var(--gray-200)"
      justify="space-between"
      wrap="nowrap"
      style={{
        borderRadius: '6px',
        ...style,
      }}
      {...props}
    >
      <Stack gap="0.5rem">
        <Text fz="0.875rem" c="var(--gray-900)">
          {title}
        </Text>
        <Text fz="0.875rem" c="var(--gray-500)">
          {entranceTime}
        </Text>
        <Badge
          variant="light"
          color={status === 'active' ? 'var(--green-500)' : 'var(--gray-500)'}
          tt="none"
        >
          {status === 'active' ? 'Active' : 'Inactive'}
        </Badge>
      </Stack>

      <Stack gap="0.5rem">
        <Text fz="0.875rem" c="var(--gray-500)" ta="right" flex={1}>
          {hoverable && hovered ? (
            <>
              <Flex gap={12} justify="flex-end">
                <PencilIcon
                  height="1.5rem"
                  width="1.5rem"
                  color="#94a3b8"
                  cursor="pointer"
                />
                <TrashIcon
                  height="1.5rem"
                  width="1.5rem"
                  color="#94a3b8"
                  cursor="pointer"
                />
              </Flex>
            </>
          ) : (
            nameOfLevel
          )}
        </Text>
        <Text fz="0.875rem" c="var(--gray-500)" ta="right">
          {exitTime}
        </Text>
        <Flex justify="flex-end" gap="0.75rem">
          {permissionStatus === 'on' ? (
            <LockOpenIcon
              width="1.25rem"
              height="1.25rem"
              color="var(--green-600)"
            />
          ) : (
            <LockClosedIcon
              width="1.25rem"
              height="1.25rem"
              color="var(--red-600)"
            />
          )}
          {notificationStatus === 'on' ? (
            <BellIcon
              width="1.25rem"
              height="1.25rem"
              color="var(--green-600)"
            />
          ) : (
            <BellSlashIcon
              width="1.25rem"
              height="1.25rem"
              color="var(--red-600)"
            />
          )}
          {reportStatus === 'on' ? (
            <FolderPlusIcon
              width="1.25rem"
              height="1.25rem"
              color="var(--green-600)"
            />
          ) : (
            <FolderMinusIcon
              width="1.25rem"
              height="1.25rem"
              color="var(--red-600)"
            />
          )}
        </Flex>
      </Stack>
    </Flex>
  );
};

export default ControlAccessCard;
