import clsx from 'clsx';
import { TLButtonProps, TLButton } from 'components/Button';

import style from './ButtonActionGroup.module.css';

export enum ButtonPosition {
  START = 'start',
  CENTER = 'center',
  END = 'end',
}

export type ButtonActionGroupProps = {
  buttonProps: TLButtonProps[];
  position?: ButtonPosition;
} & React.HTMLAttributes<HTMLDivElement>;

const positionClassNameMap: Record<ButtonPosition, string> = {
  [ButtonPosition.START]: style.start,
  [ButtonPosition.CENTER]: style.center,
  [ButtonPosition.END]: style.end,
};

export const ButtonActionGroup = ({
  buttonProps,
  position = ButtonPosition.END,
  className,
  ...rest
}: ButtonActionGroupProps) => {
  return (
    <div
      className={clsx(
        style.buttonActionGroup,
        positionClassNameMap[position],
        className,
      )}
      {...rest}
    >
      {buttonProps.map((buttonProp) => (
        <TLButton {...buttonProp} />
      ))}
    </div>
  );
};
