import { InputGroup } from 'components/Input';
import { DropDownInputGroup } from 'components/Input/DropDownInputGroup';
import { useDropdownField } from 'components/Input/hooks/useDropdownField';
import { ResponsiveRow } from 'components/utils/ResponsiveRow/ResponsiveRow';
import { useZodForm } from 'hooks/useZodForm';
import { useFormContext } from 'react-hook-form';

import { useMultiStep } from '../MultiStepForm/context/multi-step-context';
import { MultiStepFormContent } from '../MultiStepForm/MultiStepFormContent/MultiStepFormContent';
import { MultiStepFormContentFooter } from '../MultiStepForm/MultiStepFormContent/MultiStepFormContentFooter/MultiStepFormContentFooter';
import {
  CreateTimingLocationSchema,
  OrganisationInfoSchema,
  organisationInfoSchema,
} from '../MultiStepForm/timingLocation-schema';

type OrganisationInfoFormProps = {
  defaultValues: OrganisationInfoSchema;
  step: number;
  onCancel?: () => void;
};

export const OrganisationInfoForm = ({
  defaultValues,
  step,
  onCancel,
}: OrganisationInfoFormProps) => {
  const { next } = useMultiStep();
  const countries = require('i18n-iso-countries');
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  const countriesObjects = countries.getNames('en', { select: 'official' });

  const countriesNames = Object.entries(countriesObjects).map(
    ([key, value]) => ({
      value: value as string,
      label: value as string,
    }),
  );

  const {
    setValue: setContextValue,
  } = useFormContext<CreateTimingLocationSchema>();

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useZodForm<OrganisationInfoSchema>(
    {
      defaultValues,
      mode: 'onBlur',
    },
    organisationInfoSchema,
  );

  const [{ country }, onDropdownChange] = useDropdownField({
    names: ['country'],
    setValue,
    watch,
  });

  const onSubmit = (data: OrganisationInfoSchema) => {
    reset(data);
    setContextValue('organisationInfo', data);

    next();
  };

  return (
    <MultiStepFormContent step={step}>
      <InputGroup
        register={register}
        label="Organisation name"
        placeholder="Short description about the organisation"
        name="name"
        errors={errors}
      />

      <InputGroup
        register={register}
        mode="textarea"
        label="Description"
        placeholder="Short description about the event"
        name="description"
        errors={errors}
      />

      <ResponsiveRow>
        <InputGroup
          register={register}
          label="Email"
          placeholder="e.g. info@company.com"
          name="email"
          errors={errors}
        />

        <InputGroup
          register={register}
          label="Phone number"
          placeholder="e.g. 0491 570 006"
          name="phoneNumber"
          errors={errors}
        />
      </ResponsiveRow>

      <InputGroup
        register={register}
        label="Website"
        placeholder="e.g. https://www.company.com"
        name="website"
        errors={errors}
      />

      <ResponsiveRow>
        <DropDownInputGroup
          register={register}
          label="Country"
          placeholder="Select country"
          name="country"
          value={country}
          errors={errors}
          options={countriesNames}
          onChange={onDropdownChange}
        />

        <InputGroup
          register={register}
          label="Postal code"
          placeholder="e.g. 11000"
          name="postalCode"
          errors={errors}
        />
      </ResponsiveRow>

      <InputGroup
        register={register}
        label="Address"
        placeholder="e.g. 749 Williamson Lane"
        name="address"
        errors={errors}
      />

      <MultiStepFormContentFooter
        isValid={isValid}
        onConfirm={handleSubmit(onSubmit)}
      />
    </MultiStepFormContent>
  );
};

export default OrganisationInfoForm;
