import { CubeIcon, HandRaisedIcon } from '@heroicons/react/24/outline';
import { ActionIcon, Flex, FlexProps } from '@mantine/core';

import { DrawControlProps } from '../types';

const DrawControl = ({
  drawMode,
  setDrawMode,
  drawingDisabled,
  ...props
}: DrawControlProps & FlexProps) => {
  return (
    <Flex {...props}>
      <ActionIcon
        h="2.25rem"
        w="2.25rem"
        bg={drawMode ? '#ffffff' : '#eff6ff'}
        c={drawMode ? '#94a3b8' : '#3b82f6'}
        bd={`1px solid ${drawMode ? '#e2e8f0' : '#3b82f6'}`}
        onClick={() => setDrawMode(null)}
      >
        <HandRaisedIcon height="1.5rem" width="1.5rem" />
      </ActionIcon>

      <ActionIcon
        h="2.25rem"
        w="2.25rem"
        bg={drawMode ? '#eff6ff' : '#ffffff'}
        c={drawMode ? '#3b82f6' : '#94a3b8'}
        bd={`1px solid ${drawMode ? '#3b82f6' : '#e2e8f0'}`}
        disabled={drawingDisabled}
        onClick={() => setDrawMode('section')}
      >
        <CubeIcon height="1.5rem" width="1.5rem" />
      </ActionIcon>
    </Flex>
  );
};

export default DrawControl;
