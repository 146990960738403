import { Box, Flex, Text } from '@mantine/core';
import MultiProgress from 'react-multi-progress';
import { calculatePercentage } from 'utils/consts';

import { multiProgressColors } from './consts';
import { MultiProgressWrapperProps } from './types';

const MultiProgressWrapper = ({
  leftNumberProgressBar,
  rightNumberProgressBar,
  total,
}: MultiProgressWrapperProps) => {
  const elements = leftNumberProgressBar.map((item) => {
    const colorObj = multiProgressColors.find(
      (mapping) => mapping.type === item.type,
    );

    if (leftNumberProgressBar.some((item) => item.type === 'untracked')) {
      const untrackedItem = leftNumberProgressBar.find(
        (x) => x.type === 'untracked',
      );

      if (untrackedItem) {
        return {
          value: calculatePercentage(total - untrackedItem.value, item.value),
          color: colorObj ? colorObj.color : 'gray',
        };
      }
    }

    return {
      value: calculatePercentage(total, item.value),
      color: colorObj ? colorObj.color : 'gray',
    };
  });

  return (
    <Flex direction="column">
      <Flex justify="space-between">
        <Flex align="center" direction="column">
          <Text fz="0.875rem" fw={500} c="#0f172a">
            {leftNumberProgressBar
              .filter(
                (item) => item.type !== 'offline' && item.type !== 'untracked',
              )
              .map((x) => `${x.value.toLocaleString()} ${x.type}`)
              .join(' / ')}
          </Text>
        </Flex>

        <Flex align="center" direction="column">
          <Text
            fz="0.875rem"
            fw={500}
            c="#94aab8"
          >{`${rightNumberProgressBar?.value.toLocaleString()} ${
            rightNumberProgressBar?.type
          }`}</Text>
        </Flex>
      </Flex>

      <Box mt="0.75rem">
        <MultiProgress height={8} elements={elements} />
      </Box>
    </Flex>
  );
};

export default MultiProgressWrapper;
