import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TimingLocationDeviceService } from 'services/event/timingLocation/TimingLocationDeviceService';

import { eventTimingLocationGatings } from '../baseKey';

interface DeleteDeviceProps {
  eventId: string;
  layoutId: string;
  sectionId: string;
  gatingId: string;
}

export const useDeleteDevice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      eventId,
      layoutId,
      sectionId,
      gatingId,
    }: DeleteDeviceProps) =>
      await TimingLocationDeviceService.deleteTimingLocationDevice(
        eventId,
        layoutId,
        sectionId,
        gatingId,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([eventTimingLocationGatings]);
    },
  });
};
