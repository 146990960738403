import { useMutation, useQueryClient } from '@tanstack/react-query';
import { tagStatisticsBaseKey } from 'hooks/event/statistics';
import { TagsService } from 'services/tags';

import { tagBaseKey } from './baseKey';

interface DeleteTagsProps {
  ids: string[];
}

export const useDeleteTags = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ ids }: DeleteTagsProps) =>
      await TagsService.deleteTags(ids),
    onSuccess: () => {
      queryClient.invalidateQueries([tagBaseKey]);
      queryClient.invalidateQueries([tagStatisticsBaseKey]);
    },
  });
};
