import { notification } from '@lanaco/lnc-react-ui';
import PromptModal from 'components/PromptModal/PromptModal';
import { useDeleteDevice } from 'hooks/event/timing-location/useDeleteDevice';
import { getErrorMsg } from 'utils/getErrorMsg';

interface DeleteDeviceModalProps {
  eventId: string;
  layoutId: string;
  sectionId: string;
  gatingId: string;
  closeModal: () => void;
}

export const DeleteTimingLocationDeviceModal = ({
  eventId,
  layoutId,
  sectionId,
  gatingId,
  closeModal,
}: DeleteDeviceModalProps) => {
  const { mutate, isLoading } = useDeleteDevice();

  const closeDeleteModal = () => {
    closeModal();
  };

  const confirmDeleteDevice = async () => {
    mutate(
      {
        eventId,
        layoutId,
        sectionId,
        gatingId,
      },
      {
        onSuccess: (data) => {
          if (data?.status === 201) {
            notification.success('Device successfully deleted.');
          } else {
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          closeDeleteModal();
        },
      },
    );
  };

  return (
    <PromptModal
      title="Delete device?"
      isLoading={isLoading}
      warningMessage={
        <>
          Are you sure you want to delete device {gatingId}?
          <br />
          This action cannot be undone.
        </>
      }
      confirmButtonName="Delete"
      icon="exclamation-triangle"
      iconSize="24"
      iconColor="danger"
      isDeleteButton
      onConfirm={confirmDeleteDevice}
      onCancel={closeModal}
    />
  );
};
