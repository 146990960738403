import { ErrorMessage } from '@hookform/error-message';
import { NumberInput } from '@lanaco/lnc-react-ui';
import clsx from 'clsx';
import {
  FieldErrors,
  FieldPath,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';

import { NumberInputError } from '../NumberInputError';

import styles from './NumberInputGroup.module.scss';

interface NumberInputGroupProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  name: FieldPath<T>;
  label: string;
  placeholder: string;
  errors?: FieldErrors;
  className?: string;
  mode?: 'number';
  onChange;
  value;
  min?: number;
  max?: number;
}

export const NumberInputGroup = <T extends FieldValues>({
  register,
  className,
  name,
  label,
  placeholder,
  errors,
  mode = 'number',
  onChange,
  value,
  min,
  max,
}: NumberInputGroupProps<T>) => {
  return (
    <label className={clsx(styles.inputGroup, className)}>
      <span className={styles.label}>{label}</span>

      {mode === 'number' && (
        <NumberInput
          {...register(name)}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          min={0}
          max={max}
        />
      )}

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <NumberInputError message={message} />}
      />
    </label>
  );
};
