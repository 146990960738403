import { generatePath } from 'react-router-dom';

//******************** */
//AUTH - authentication
//******************** */
export const AUTH_LOGIN = '/auth/login';
export const AUTH_LOGIN_FOR = '/auth/loginFor';
export const AUTH_REFRESH_TOKEN = '/auth/refreshToken';
export const REGISTER = '/auth/register';
export const SEND_CONFIRMATION_CODE = '/auth/changePassword';
export const VERIFY_EMAIL = '/auth/verifyEmail';
export const CHANGE_PASSWORD = '/auth/changePassword';
export const RESEND_VERIFICATION_EMAIL = '/auth/resendVerificationEmail';

//******************** */
//DEVICE
//******************** */
export const GET_ALL_DEVICES = (
  limit?: number,
  offset?: number,
  status?: string,
  filter?: string,
) =>
  '/device?'.concat(
    new URLSearchParams({
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      status: status || '',
      filter: filter || '',
    }).toString(),
  );
export const GET_DEVICE = (deviceId: string) => '/device/' + deviceId;
export const DELETE_DEVICE = (deviceId: string) =>
  generatePath('/device/:deviceId', { deviceId });
export const DELETE_DEVICES = () => '/device';
export const UPDATE_DEVICE = (deviceId: string) =>
  generatePath('/device/:deviceId', { deviceId });
export const INSERT_DEVICE = '/device';
export const TOGGLE_DEVICE_STATUSES = () => '/device/toggleStatus';
//******************** */
//EVENT
//******************** */
export const GET_ALL_EVENTS = (
  limit: number,
  offset: number,
  status?: string | null,
  filter?: string | null,
) =>
  '/event?'.concat(
    new URLSearchParams({
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      status: status || '',
      filter: filter || '',
    }).toString(),
  );
export const DELETE_EVENTS = () => '/event';
export const INSERT_EVENT = '/event';
export const GET_EVENT = (eventId: string) => '/event/' + eventId;
export const GET_EVENT_LIVE_DATA = (eventId: string) =>
  generatePath('/event/:eventId/live', { eventId });

export const GET_EVENT_BOARD_LIVE = (
  eventId: string,
  limit?: number,
  offset?: number,
  filter?: string,
) => {
  return generatePath('/event/:eventId/notification/unacknowledged?', {
    eventId,
  }).concat(
    new URLSearchParams({
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      filter: filter || '',
    }).toString(),
  );
};
export const GET_EVENT_BOARD_MUTED = (
  eventId: string,
  limit?: number,
  offset?: number,
  filter?: string,
) => {
  return generatePath('/event/:eventId/notification/acknowledged?', {
    eventId,
  }).concat(
    new URLSearchParams({
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      filter: filter || '',
    }).toString(),
  );
};
export const GET_EVENT_BOARD_STATE = (eventId: string) => {
  return generatePath('/event/:eventId/notification/state?', {
    eventId,
  }).concat(new URLSearchParams().toString());
};
export const GET_EVENT_PARTICIPANTS = (
  eventId: string,
  limit?: number,
  offset?: number,
  filter?: string,
  gender?: string,
  tagId?: string,
  admitted?: boolean,
) => {
  return generatePath('/event/:eventId/participant?', { eventId }).concat(
    new URLSearchParams({
      tagId: tagId?.toString() || '',
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      filter: filter || '',
      gender: gender || '',
      admitted: admitted?.toString() || '',
    }).toString(),
  );
};
export const UPDATE_EVENT = (eventId: string) => '/event/' + eventId;
export const SET_EVENT_HERO_IMAGE = (eventId: string) =>
  generatePath('/event/:eventId/hero', { eventId });
// export const GET_EVENT_TIMING_LOCATIONS = (eventId: string) =>
//   generatePath('/event/:eventId/section', { eventId });
export const GET_EVENT_TIMING_LOCATIONS = (
  eventId: string,
  limit?: number,
  offset?: number,
  filter?: string,
) =>
  generatePath('/event/:eventId/section?', { eventId }).concat(
    new URLSearchParams({
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      filter: filter || '',
    }).toString(),
  );
export const CREATE_TIMING_LOCATION = (eventId: string, layoutId: string) =>
  generatePath('/event/:eventId/layout/:layoutId/section', {
    eventId,
    layoutId,
  });
export const UPDATE_TIMING_LOCATION = (
  eventId: string,
  layoutId: string,
  sectionId: string,
) =>
  generatePath('/event/:eventId/layout/:layoutId/section/:sectionId', {
    eventId,
    layoutId,
    sectionId,
  });

export const DELETE_EVENT_TIMING_LOCATIONS = (eventId: string) =>
  generatePath('/event/:eventId/section', {
    eventId,
  });

export const DELETE_EVENT_TIMING_LOCATION = (
  eventId: string,
  timingLocationId: string,
  layoutId: string,
) =>
  generatePath('/event/:eventId/layout/:layoutId/section/:timingLocationId', {
    eventId,
    layoutId,
    timingLocationId,
  });
export const DELETE_EVENT = (eventId: string) => '/event/' + eventId;
export const UPDATE_EVENT_PARTICIPANT = (
  eventId: string,
  participantId: string,
) =>
  generatePath('/event/:eventId/participant/:participantId', {
    eventId,
    participantId,
  });
export const CREATE_EVENT_PARTICIPANT = (eventId: string) =>
  generatePath('/event/:eventId/participant', { eventId });
export const GET_EVENT_DEVICES = (
  eventId: string,
  limit?: number,
  offset?: number,
  status?: string,
  filter?: string,
) =>
  generatePath(
    '/event/:eventId/device?'.concat(
      new URLSearchParams({
        limit: limit?.toString() || '',
        offset: offset?.toString() || '',
        status: status || '',
        filter: filter || '',
      }).toString(),
    ),
    { eventId },
  );
export const GET_EVENT_TAGS = (
  eventId: string,
  limit?: number,
  offset?: number,
  filter?: string,
  participantId?: string,
) =>
  generatePath('/event/:eventId/tag?', { eventId }).concat(
    new URLSearchParams({
      participantId: participantId?.toString() || '',
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      filter: filter || '',
    }).toString(),
  );
export const GET_EVENT_ASSIGNABLE_TAGS = (
  eventId: string,
  limit?: number,
  offset?: number,
  filter?: string,
) =>
  generatePath('event/:eventId/tag/assignable?', { eventId }).concat(
    new URLSearchParams({
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      filter: filter || '',
    }).toString(),
  );

export const CREATE_EVENT_ASSIGNABLE_TAGS = (eventId: string) =>
  generatePath('/event/:eventId/tag/addMany', { eventId });
export const GET_EVENT_ASSIGNABLE_DEVICES = (
  eventId: string,
  limit: number,
  offset: number,
  filter: string,
) =>
  generatePath('/event/:eventId/device/assignable?', { eventId }).concat(
    new URLSearchParams({
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      filter: filter || '',
    }).toString(),
  );
export const DELETE_EVENT_TAG = (eventId: string, tagId: string) =>
  generatePath('/event/:eventId/tag/:tagId', { eventId, tagId });

export const DELETE_EVENT_TAGS = (eventId: string) =>
  generatePath('/event/:eventId/tag', { eventId });

export const DELETE_EVENT_DEVICE = (eventId: string, deviceId: string) =>
  generatePath('/event/:eventId/device/:deviceId', { eventId, deviceId });

export const DELETE_EVENT_DEVICES = (eventId: string) =>
  generatePath('/event/:eventId/device', { eventId });

export const CREATE_EVENT_ASSIGNABLE_DEVICES = (eventId: string) =>
  generatePath('/event/:eventId/device/addMany', { eventId });

export const CREATE_EVENT_ASSIGNABLE_DEVICE = (eventId: string) =>
  generatePath('/event/:eventId/device/add', { eventId });

export const ASSIGN_ONE_OR_MORE_PARTICIPANT_ON_TAG = (
  eventId: string,
  participantId,
) =>
  generatePath('/event/:eventId/participant/:participantId/assign', {
    eventId,
    participantId,
  });
export const UNASSIGN_ONE_OR_MORE_PARTICIPANT_FROM_TAG = (
  eventId: string,
  participantId,
) =>
  generatePath('/event/:eventId/participant/:participantId/unassign', {
    eventId,
    participantId,
  });

export const DELETE_EVENT_PARTICIPANT = (
  eventId: string,
  participantId: string,
) =>
  generatePath('/event/:eventId/participant/:participantId', {
    eventId,
    participantId,
  });

export const DELETE_EVENT_PARTICIPANTS = (eventId: string) =>
  generatePath('/event/:eventId/participant', { eventId });
//******************** */
//TAG
//******************** */
export const GET_ALL_TAGS = (
  limit?: number,
  offset?: number,
  status?: string,
  filter?: string,
) =>
  '/tag?'.concat(
    new URLSearchParams({
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      status: status || '',
      filter: filter || '',
    }).toString(),
  );

export const GET_TAG = (tagId: string) => '/tag/' + tagId;
export const INSERT_TAG = '/tag';
export const DELETE_TAG = (tagId: string) => '/tag/' + tagId;
export const DELETE_TAGS = () => '/tag';
export const UPDATE_TAG = (tagId: string) => '/tag/' + tagId;
export const TOGGLE_TAG_STATUSES = () => '/tag/toggleStatus';
//******************** */
//USER
//******************** */
export const GET_ALL_USERS = '/user/';
export const GET_USER = (deviceId: string) => '/user/' + deviceId;
export const INSERT_USER = '/user';
export const UPDATE_USER = (userId: string) => '/user/' + userId;

//******************** */

export const GET_EVENT_LAYOUT = (
  eventId: string,
  offset?: number,
  limit?: number,
  status?: string,
  filter?: string,
) =>
  generatePath('/event/:eventId/layout?', { eventId }).concat(
    new URLSearchParams({
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      status: status || '',
      filter: filter || '',
    }).toString(),
  );
export const DELETE_EVENT_LAYOUT = (eventId: string, layoutId: string) =>
  generatePath('/event/:eventId/layout/:layoutId', { eventId, layoutId });

export const DELETE_EVENT_LAYOUTS = (eventId: string) =>
  generatePath('/event/:eventId/layout', { eventId });

export const PUT_EVENT_LAYOUT = (eventId: string, layoutId: string) =>
  generatePath('/event/:eventId/layout/:layoutId', { eventId, layoutId });
export const POST_EVENT_LAYOUT = (eventId: string) =>
  generatePath('/event/:eventId/layout', { eventId });
export const POST_EVENT_LAYOUT_PRESENTATION = (
  eventId: string,
  layoutId: string,
) =>
  generatePath('/event/:eventId/layout/:layoutId/presentation', {
    eventId,
    layoutId,
  });

export const GET_PARTICIPANT_STATISTICS = () => '/statistics/participant';
export const GET_EVENT_STATISTICS = () => '/statistics/event';
export const GET_SINGLE_EVENT_STATISTICS = (eventId: string) =>
  generatePath('/statistics/event/:eventId', { eventId });
export const GET_DEVICE_STATISTICS = () => '/statistics/device';
export const GET_TAG_STATISTICS = () => '/statistics/tag';
export const GET_CLIENTS_STATISTICS = () => '/statistics/clients';
//******************** */
//Clients
//******************** */

export const GET_ALL_CLIENTS = (
  limit?: number,
  offset?: number,
  status?: string,
  filter?: string,
) =>
  '/clients?'.concat(
    new URLSearchParams({
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      status: status || '',
      filter: filter || '',
    }).toString(),
  );
export const GET_CLIENT = (clientId: string) => '/clients/' + clientId;
export const DELETE_CLIENT = (clientId: string) =>
  generatePath('/clients/:clientId', { clientId });
export const DELETE_CLIENTS = () => '/clients';
export const UPDATE_CLIENT = (clientId: string) =>
  generatePath('/clients/:clientId', { clientId });
export const INSERT_CLIENT = '/clients';
export const TOGGLE_CLIENT_STATUSES = () => '/clients/toggleStatus';
export const GET_EVENT_TYPES = () => '/event/types';

export const POST_EVENT_HERO_IMAGE = (eventId: string) =>
  generatePath('/event/:eventId/hero', {
    eventId,
  });

export const GET_TIMING_LOCATION_DETAILS = (
  eventId: string,
  layoutId: string,
  sectionId: string,
) =>
  generatePath('/event/:eventId/layout/:layoutId/section/:sectionId', {
    eventId,
    layoutId,
    sectionId,
  });

export const GET_SINGLE_EVENT_LAYOUT = (eventId: string, layoutId: string) =>
  generatePath('/event/:eventId/layout/:layoutId', {
    eventId,
    layoutId,
  });

export const GET_TIMING_LOCATION_GATINGS = (
  eventId: string,
  layoutId: string,
  sectionId: string,
  offset?: number,
  limit?: number,
  status?: string,
  filter?: string,
) =>
  generatePath('/event/:eventId/layout/:layoutId/section/:sectionId/gating?', {
    eventId,
    layoutId,
    sectionId,
  }).concat(
    new URLSearchParams({
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      status: status || '',
      filter: filter || '',
    }).toString(),
  );

export const GET_TIMING_LOCATION_ASSIGNABLE_DEVICES = (
  eventId: string,
  layoutId: string,
  sectionId: string,
  limit?: number,
  offset?: number,

  filter?: string,
) =>
  generatePath(
    '/event/:eventId/layout/:layoutId/section/:sectionId/gating/devices?',
    {
      eventId,
      layoutId,
      sectionId,
    },
  ).concat(
    new URLSearchParams({
      limit: limit?.toString() || '',
      offset: offset?.toString() || '',
      filter: filter || '',
    }).toString(),
  );

export const CREATE_EVENT_TIMING_LOCATION_ASSIGNABLE_DEVICES = (
  eventId: string,
  layoutId: string,
  sectionId: string,
) =>
  generatePath(
    '/event/:eventId/layout/:layoutId/section/:sectionId/gating/addMany',
    {
      eventId,
      layoutId,
      sectionId,
    },
  );
export const DELETE_TIMING_LOCATION_DEVICE = (
  eventId: string,
  layoutId: string,
  sectionId: string,
  gatingId: string,
) =>
  generatePath(
    '/event/:eventId/layout/:layoutId/section/:sectionId/gating/:gatingId',
    { eventId, layoutId, sectionId, gatingId },
  );

export const DELETE_TIMING_LOCATION_DEVICES = (
  eventId: string,
  layoutId: string,
  sectionId: string,
) =>
  generatePath('/event/:eventId/layout/:layoutId/section/:sectionId/gating', {
    eventId,
    layoutId,
    sectionId,
  });

export const GET_SINGLE_EVENT_LAYOUT_SECTION_GATING = (
  eventId: string,
  layoutId: string,
  sectionId: string,
  gatingId: string,
) =>
  generatePath(
    '/event/:eventId/layout/:layoutId/section/:sectionId/gating/:gatingId',
    {
      eventId,
      layoutId,
      sectionId,
      gatingId,
    },
  );

export const REGISTER_DEVICE = (
  eventId: string,
  layoutId: string,
  sectionId: string,
  gatingId: string,
) =>
  generatePath(
    '/event/:eventId/layout/:layoutId/section/:sectionId/gating/:gatingId/register',
    {
      eventId,
      layoutId,
      sectionId,
      gatingId,
    },
  );

export const UNREGISTER_DEVICE = (
  eventId: string,
  layoutId: string,
  sectionId: string,
  gatingId: string,
) =>
  generatePath(
    '/event/:eventId/layout/:layoutId/section/:sectionId/gating/:gatingId/unregister',
    {
      eventId,
      layoutId,
      sectionId,
      gatingId,
    },
  );

export const CREATE_EVENT_ADMISSION = (eventId: string) =>
  generatePath('/event/:eventId/admission', { eventId });

export const GET_REGISTER_FCM_TOKEN = '/notification/registerfcmtoken';
export const GET_UNREGISTER_FCM_TOKEN = '/notification/unregisterfcmtoken';
