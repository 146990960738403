import { forwardRef } from 'react';

import { SvgIconProps } from './interface';

const TagPlusIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  (
    {
      title,
      titleId,
      height = '1em',
      width = '1em',
      fill = 'currentColor',
      ...props
    },
    ref,
  ) => (
    <svg
      ref={ref}
      height={height}
      width={width}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.11578 3.25C2.53305 3.25 1.25 4.53305 1.25 6.11578V10.2406C1.25 11.0007 1.55193 11.7296 2.08937 12.267L11.2418 21.4195C12.12 22.2977 13.524 22.5523 14.6306 21.8279C16.7006 20.4727 18.4727 18.7006 19.8279 16.6306C20.5523 15.524 20.2977 14.12 19.4195 13.2418L10.267 4.08937C9.72957 3.55193 9.00065 3.25 8.2406 3.25H4.11578ZM5.19044 8.26511C5.78396 8.26511 6.26511 7.78396 6.26511 7.19044C6.26511 6.59692 5.78396 6.11578 5.19044 6.11578C4.59692 6.11578 4.11578 6.59692 4.11578 7.19044C4.11578 7.78396 4.59692 8.26511 5.19044 8.26511Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 1.25C19.4142 1.25 19.75 1.58579 19.75 2V4.25H22C22.4142 4.25 22.75 4.58579 22.75 5C22.75 5.41421 22.4142 5.75 22 5.75H19.75V8C19.75 8.41421 19.4142 8.75 19 8.75C18.5858 8.75 18.25 8.41421 18.25 8V5.75H16C15.5858 5.75 15.25 5.41421 15.25 5C15.25 4.58579 15.5858 4.25 16 4.25H18.25V2C18.25 1.58579 18.5858 1.25 19 1.25Z"
        fill={fill}
      />
    </svg>
  ),
);

TagPlusIcon.displayName = 'TagPlusIcon';

export default TagPlusIcon;
