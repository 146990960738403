import React, { ReactNode } from 'react';

import { useMultiStep } from '../context/multi-step-context';

import styles from './MultiStepFormContent.module.scss';

type Props = {
  children: ReactNode;
  step: number;
};

export const MultiStepFormContent = ({ children, step }: Props) => {
  const { currentStep } = useMultiStep();

  return currentStep === step ? (
    <div className={styles.multiStepFormContent}>{children}</div>
  ) : null;
};
