import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { EventDevice } from 'pages/Events/Devices/EventDevices.interface';
import { client } from 'services/api';
import { Response } from 'types/response';
import { GET_EVENT_DEVICES } from 'utils/apiUrl';

import { eventDeviceBaseKey } from './baseKeys';
interface Props {
  eventId: string;
  offset: number;
  row_limit: number;
  status?: string;
  filter?: string;
}

export const useFetchEventDevices = ({
  eventId,
  offset,
  row_limit,
  status,
  filter,
}: Props) => {
  const queryClient = useQueryClient();
  const queryResult = useQuery<Response<EventDevice[]>, AxiosError>({
    queryKey: [eventDeviceBaseKey, eventId, row_limit, offset, status, filter],
    queryFn: async () => {
      // if (!eventId) return new EventDevice[];

      const retVal = await client.get<Response<EventDevice[]>>(
        GET_EVENT_DEVICES(eventId, row_limit, offset, status, filter),
      );

      retVal.data.data.forEach(async (eventDevice) =>
        queryClient.setQueryData<EventDevice>(
          [eventDeviceBaseKey, eventDevice._id],
          eventDevice,
        ),
      );
      return retVal.data;
    },
  });
  return queryResult;
};
