import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/16/solid';
import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Text,
  TextInput,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import SortingDropdown from 'components/SortingDropdown/SortingDropdown';
import { SortingParamType } from 'components/SortingDropdown/types';
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from 'mantine-react-table';
import { addTrackingObjectSortingParams } from 'pages/Entities/consts';
import AdditionalFilters from 'pages/Entities/Hub/LeftHubContainer/AdditionalFilters/AdditionalFilters';
import { FilterData } from 'pages/Entities/Hub/types';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useMemo, useState } from 'react';

import { AddTrackingObjectType } from '../types';

import styles from './Modals.module.scss';

const types: Array<FilterData> = [
  { id: '1', value: 'Asset' },
  { id: '2', value: 'Person' },
];
const statuses: Array<FilterData> = [
  { id: '1', value: 'Online' },
  { id: '2', value: 'Offline' },
];

const data: AddTrackingObjectType[] = [
  {
    name: 'Tracking Object 01234',
    type: 'Asset',
    classification: 'Tools',
    registrationId: 'VRN59FZD3KX',
    tags: 'TL TAG 003 (+2)',
    status: 'Online',
  },
  {
    name: 'Tracking Object 01234',
    type: 'Asset',
    classification: 'Tools',
    registrationId: 'VRN59FZD3KX',
    tags: 'TL TAG 003 (+2)',
    status: 'Online',
  },
  {
    name: 'Tracking Object 01234',
    type: 'Asset',
    classification: 'Tools',
    registrationId: 'VRN59FZD3KX',
    tags: 'TL TAG 003 (+2)',
    status: 'Offline',
  },
  {
    name: 'Tracking Object 01234',
    type: 'Asset',
    classification: 'Tools',
    registrationId: 'VRN59FZD3KX',
    tags: 'TL TAG 003 (+2)',
    status: 'Online',
  },
];

const AddingTrackingObjectModal = ({
  formState,
  setFormState,
  isFormValid,
  setIsFormValid,
}: ModalFormProps) => {
  const [sortingParam, setSortingParam] = useState<SortingParamType | null>(
    null,
  );
  const [showAdditionalFilters, setShowAdditionalFilters] = useState<boolean>(
    false,
  );
  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>(
    {},
  );
  const columns = useMemo<MRT_ColumnDef<AddTrackingObjectType>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',

        Cell: ({ cell }) => {
          return (
            <div className={`${styles.firstColumn}`}>
              {cell.getValue() as React.ReactNode}
            </div>
          );
        },
      },
      {
        header: 'Type',
        accessorKey: 'type',
      },
      {
        header: 'classification',
        accessorKey: 'Classification',
      },
      {
        header: 'Registration ID',
        accessorKey: 'registrationId',
      },
      {
        header: 'Tags',
        accessorKey: 'tags',
      },
      {
        header: 'Status',
        accessorKey: 'status',

        Cell: ({ row }) => {
          const status = row.original.status;
          return (
            <Badge
              variant="light"
              color={status === 'Online' ? '#10B981' : '#64748B'}
              radius={12}
              fz="0.75rem"
              tt="none"
            >
              {status}
            </Badge>
          );
        },
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    enableRowSelection: true,

    state: {
      rowSelection,
    },

    onRowSelectionChange: (newRowSelection) => {
      setRowSelection(newRowSelection);
    },

    mantineTableBodyRowProps: ({ row }) => {
      return {
        className: row.getIsSelected() ? styles.selectedRow : '',
      };
    },

    mantineSelectAllCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantineSelectCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantinePaperProps: {
      style: {
        boxShadow: 'none',
        borderRadius: '0.5rem',
        borderColor: '#E2E8F0',
      },
    },
  });

  return (
    <div className={styles.tableContainer}>
      <Flex mr="0.5rem" mb="1.5rem" justify="center">
        <TextInput
          size="sm"
          mr="0.75rem"
          fz="1rem"
          radius="md"
          flex={1}
          placeholder="Search"
          leftSection={
            <MagnifyingGlassIcon
              height="1.25rem"
              width="1.25rem"
              color="#94A3B8"
            />
          }
        />
        <Flex align="center" gap="0.75rem">
          <SortingDropdown
            sortingParams={addTrackingObjectSortingParams}
            sortingParam={sortingParam}
            setSortingParam={setSortingParam}
          />
          <ActionIcon
            bg="#ffffff"
            c={showAdditionalFilters ? '#3B82F6' : '#0F172A'}
            onClick={() => setShowAdditionalFilters((prev) => !prev)}
          >
            <AdjustmentsHorizontalIcon height="1.5rem" width="1.5rem" />
          </ActionIcon>
        </Flex>
      </Flex>

      {showAdditionalFilters && <AdditionalFilters statuses={statuses} />}

      <MantineReactTable table={table} />

      <Flex mt="2rem" justify="space-between" align="center">
        <Text>{Object.keys(rowSelection).length} rows selected</Text>

        <Flex justify="flex-end" gap="1rem">
          <Button
            bg="#ffffff"
            bd="1px solid #e2e8f0"
            c="#344155"
            onClick={modals.closeAll}
          >
            Cancel
          </Button>
          <Button
            bg="#3b82f6"
            c="#ffffff"
            onClick={() => {
              modals.closeAll();
            }}
          >
            Add
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};
export default AddingTrackingObjectModal;
