import { Group, Text } from '@mantine/core';
import { RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import {
  TLDangerButton,
  TLNeutralButton,
  TLPrimaryButton,
} from 'components/Button';
import ButtonGroupData, {
  ButtonGroupDataInterface,
} from 'components/ButtonGroupData/ButtonGroupData';
import { InputSearch } from 'components/Input/InputSearch';
import { ModalSize, TLModal } from 'components/Modal';
import TanStackTable, {
  RowAction,
} from 'components/TanStackTable/TanStackTable';
import { useGetEventDetails } from 'hooks/event/details';
import { useFetchEventParticipants } from 'hooks/event/participants';
import { useFetchSingleEventStatistics } from 'hooks/event/statistics/useFetchSingleEventStatistics';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useParams } from 'react-router';

import AddRemoveTagOnParticipantForm from './AddRemoveEventTagOnParticipantForm';
import { CreateOrUpdateParticipantForm } from './CreateOrUpdateParticipantForm';
import { EventParticipantTableData } from './EventParticipants.interface';
import style from './EventParticipants.module.css';
import { RemoveEventParticipantModal } from './RemoveEventParticipantModal';
import RemoveParticipantFromTag from './RemoveParticipantFromTag';

type Props = {};

const columnHelper = createColumnHelper<EventParticipantTableData>();
const ROW_LIMIT = 10;

const EventParticipants = (props: Props) => {
  const [status, setStatus] = useState('all');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  //const [offset, setOffset] = useState<number>(0);

  const [
    participantToEdit,
    setParticipantToEdit,
  ] = useState<EventParticipantTableData | null>(null);

  const [replaceTag, setReplaceTag] = useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});

  const { id: eventId } = useParams<{ id: string }>();

  const selectedIds = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    },
    [],
  );

  const event = useGetEventDetails(eventId);

  const getHandleStatusFilterChangeCallback = (status: string) => () => {
    setPage(1);
    setStatus(status);
  };

  const { data, isFetching } = useFetchEventParticipants({
    eventId: eventId as string,
    offset: (page - 1) * ROW_LIMIT,
    limit: ROW_LIMIT,
    filter: search,
    gender: status,
  });

  const { _route, data: eventParticipantData } = data || {
    _route: { returned: 0, total: 0 },
    data: [],
  };

  //console.log(eventParticipantData);
  const { data: statistics } = useFetchSingleEventStatistics({
    eventId: eventId as string,
  });

  const buttons: Array<ButtonGroupDataInterface> = [
    {
      text: 'All',
      text1: _route.total ? _route.total.toString() : '0',
      indicator: '',
      onClick: getHandleStatusFilterChangeCallback('all'),
    },
    {
      text: 'Male',
      text1: statistics?.participants.gender.male?.toString(),
      indicator: '',
      onClick: getHandleStatusFilterChangeCallback('male'),
    },
    {
      text: 'Female',
      text1: statistics?.participants.gender.female?.toString(),
      indicator: '',
      onClick: getHandleStatusFilterChangeCallback('female'),
    },
    {
      text: 'Other',
      text1: statistics?.participants.gender.other?.toString(),
      indicator: '',
      onClick: getHandleStatusFilterChangeCallback('other'),
    },
  ];
  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  const handleAddEventParticipantClick = () => {
    setParticipantToEdit(null);
    showInsertParticipantModal();
  };

  const handleAssignUnassignTag = (
    row: EventParticipantTableData,
    replace: boolean,
  ) => {
    setParticipantToEdit(row);
    setReplaceTag(replace);
    showAssignParticipantModal();
  };

  const [
    showAssignParticipantModal,
    _hideAssignParticipantModal,
  ] = useModal(() => {
    const hideAssignParticipantModal = () => {
      _hideAssignParticipantModal();
    };
    console.log('p', participantToEdit);
    let a = participantToEdit?.tags.map(({ _id }) => _id) as string[];
    console.log(a);
    return (
      <TLModal
        title={'Assign tag to participant'}
        subtitle={`Select a tag you want to assign to ${
          participantToEdit?.firstLastName as string
        }`}
        onClose={hideAssignParticipantModal}
        size={ModalSize.L}
      >
        <AddRemoveTagOnParticipantForm
          eventId={eventId!}
          oldTagIds={participantToEdit?.tags.map(({ _id }) => _id) as string[]}
          participantId={participantToEdit?._id as string}
          participantName={participantToEdit?.firstLastName}
          replaceTag={replaceTag}
          closeModal={hideAssignParticipantModal}
        />
      </TLModal>
    );
  }, [
    participantToEdit?._id,
    participantToEdit?.tags,
    eventId,
    participantToEdit?.firstLastName,
  ]);

  const handleEditEventParticipantClick = (row: EventParticipantTableData) => {
    setParticipantToEdit(row);
    showInsertParticipantModal();
  };

  const handleRemoveClick = (row: EventParticipantTableData) => {
    setParticipantToEdit(row);
    showRemoveParticipantModal();
  };

  const handleRemoveEventParticipants = () => {
    setParticipantToEdit(null);
    showRemoveParticipantModal();
  };

  const handleRemoveParticipantClick = (row: EventParticipantTableData) => {
    setParticipantToEdit(row);
    showRemoveTagFromParticipantModal();
  };

  const [
    showRemoveTagFromParticipantModal,
    _hideRemoveTagFromParticipantModal,
  ] = useModal(() => {
    const hideRemoveTagFromParticipantModal = () => {
      _hideRemoveTagFromParticipantModal();
    };
    return (
      <RemoveParticipantFromTag
        eventId={event!._id}
        tagId={participantToEdit!.tags[0]._id as string}
        tagName={participantToEdit?.tags[0]?.uniqueId}
        participant={participantToEdit?.firstLastName}
        participantId={participantToEdit?._id as string}
        multipleTags={participantToEdit?.tags?.length! > 1}
        tags={participantToEdit?.tags}
        closeModal={hideRemoveTagFromParticipantModal}
      />
    );
  }, [participantToEdit?.tagId, participantToEdit?._id, selectedIds]);

  const [
    showRemoveParticipantModal,
    _hideRemoveParticipantModal,
  ] = useModal(() => {
    const hideRemoveParticipantModal = () => {
      _hideRemoveParticipantModal();
    };
    return (
      <RemoveEventParticipantModal
        participantId={participantToEdit?._id as string}
        participantName={participantToEdit?.firstLastName}
        eventName={event?.name}
        participantIds={selectedIds}
        eventId={event!._id}
        closeModal={hideRemoveParticipantModal}
        setSelectedRows={setSelectedRows}
      />
    );
  }, [participantToEdit?.firstName, participantToEdit?._id, selectedIds]);

  const [
    showInsertParticipantModal,
    _hideInsertParticipantModal,
  ] = useModal(() => {
    const hideModal = () => {
      setParticipantToEdit(null);
      _hideInsertParticipantModal();
    };

    return (
      <TLModal
        title={'Add Participants'}
        subtitle={`Manage Participants at ${event?.name}`}
        onClose={hideModal}
        size={ModalSize.CUSTOM80}
        hideFooter
      >
        <CreateOrUpdateParticipantForm
          onClose={_hideInsertParticipantModal}
          participant={participantToEdit!}
          eventId={event?._id as string}
          isEditMode={participantToEdit === null ? false : true}
        />
      </TLModal>
    );
  }, [participantToEdit]);

  const clearSelectedRows = () => setSelectedRows({});

  const ParticipantTableRowActions = (
    row: EventParticipantTableData,
  ): RowAction[] => [
    {
      icon: 'pencil',
      key: 'edit',
      label: 'Edit',
      onClick: () => handleEditEventParticipantClick(row),
    },
    {
      icon: 'tag',
      key: 'assign',
      label: 'Assign tag',
      className: row.tags.length === 0 ? 'show' : 'hide',
      onClick: () => handleAssignUnassignTag(row, false),
    },
    {
      icon: 'arrows-rotate',
      key: 'assignToAnother',
      label: 'Assign another tag',
      className: row.tags.length === 0 ? 'hide' : 'show',
      onClick: () => handleAssignUnassignTag(row, true),
    },
    {
      icon: 'tag',
      key: 'unassign',
      label: 'Unassign tag',
      className: row.tags.length === 0 ? 'hide' : 'show',
      onClick: () => handleRemoveParticipantClick(row),
    },
    {
      icon: 'trash',
      key: 'delete',
      label: 'Remove participant',
      color: 'danger',
      onClick: () => handleRemoveClick(row),
    },
  ];

  const eventParticipantTableColumns = [
    columnHelper.accessor('firstLastName', {
      header: 'Participant',
    }),
    columnHelper.accessor('externalId', {
      header: 'Registration ID',
    }),
    // columnHelper.accessor('tagId.uniqueId', {
    //   header: 'Tag ID',
    //   cell: (row) => (
    //     <span>
    //       {row.getValue() == null ? ( //null or undefined
    //         <span className={style.unassigned}>Unassiged</span>
    //       ) : (
    //         row.getValue()
    //       )}
    //     </span>
    //   ),
    // }),
    columnHelper.accessor('tags', {
      header: 'Tag ID',
      cell: (row) => {
        const tags = row.getValue();

        return (
          <>
            {tags.map((tag) => (
              <Group
                key={tag._id}
                wrap="nowrap"
                align="center"
                justify="space-between"
              >
                <Text component="span">{tag.number}</Text>
                <Text fz="xs" lh={1} component="span">
                  {tag.uniqueId}
                </Text>
              </Group>
            ))}
          </>
        );
      },
    }),
    columnHelper.accessor('organisation', {
      header: 'Organisation',
    }),
    columnHelper.accessor('lastLocation', {
      header: 'Last location',
    }),
  ];

  const ParticipantTableSelectedRowsActions = (
    <>
      <TLNeutralButton leadingIcon="times" onClick={clearSelectedRows}>
        Clear selection
      </TLNeutralButton>
      <TLNeutralButton leadingIcon="arrow-down" onClick={() => {}}>
        Export
      </TLNeutralButton>
      <TLDangerButton
        leadingIcon="trash"
        onClick={handleRemoveEventParticipants}
        className="text-white"
      >
        Remove
      </TLDangerButton>
    </>
  );

  const ParticipantTableActions = (
    <>
      <TLNeutralButton leadingIcon="arrow-up" onClick={() => {}}>
        Import
      </TLNeutralButton>
      <TLPrimaryButton
        leadingIcon="plus"
        onClick={handleAddEventParticipantClick}
      >
        Add
      </TLPrimaryButton>
    </>
  );

  return (
    <div className={style.table}>
      <InputSearch
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        className={style.search}
      />
      <div className={style.divTable}>
        <TanStackTable
          getRowId={(row) => row._id}
          selectedRows={selectedRows}
          onRowSelectionChange={setSelectedRows}
          isLoading={isFetching}
          columns={eventParticipantTableColumns}
          pageIndex={page}
          pageSize={ROW_LIMIT}
          totalRows={_route.total}
          pageCount={Math.ceil(_route.total / ROW_LIMIT)}
          data={eventParticipantData}
          rowActions={ParticipantTableRowActions}
          onPageChange={handlePageChange}
          headerTitle={<ButtonGroupData buttons={buttons} />}
          tableActions={
            selectedIds.length > 0
              ? ParticipantTableSelectedRowsActions
              : ParticipantTableActions
          }
          enableRowSelection
          showHeader
        />
      </div>
    </div>
  );
};

export default EventParticipants;
