import { modals } from '@mantine/modals';
import { ModalWidth } from 'utils/consts';

import DeleteModal from './DeleteModal';
import MultiStepModal from './MultiStepModal';
import SingleStepModal from './SingleStepModal';
import { ModalProps, DeleteModalProps } from './types';

export const openModal = ({
  /** Id koji se prosljeđuju modalima za dohvatanje podataka i prikazivanje. */
  dataId,
  /** Id modala. */
  modalId = `modal-${Math.random()}`,
  /** Tip sadržaja modala. Prosljeđuje se zajedno sa setModalContent jer se pomoću njega određuje koji će sadržaj setModalContent prikazati. */
  type = 'none',
  /** Postavlja se kada želimo centrirat modal na sredini ekrana */
  centered = false,
  /** Širina modala. */
  size = ModalWidth.Large,
  /** Naslov modala. */
  title,
  /** Ikonica pored naslova. */
  titleIcon,
  /** Opis ispod naslova. */
  titleDescription,
  /** Lista podnaslova koraka višestepenog modala. Prosljeđuje se zajedno sa setModalContent. */
  subtitles = [],
  /** Određuje da li je modal višestepen. Prosljeđuje se zajedno sa type, subtitles i setModalContent. */
  isMultistep = false,
  /** Postavlja se kada se modal poziva iz kartice. Prosljeđuje se zajedno sa currentCardIndex, cardsArray i removeButtonFn. */
  isCard = false,
  /** Odnosi se na indeks trenutne kartice (kartice na koju se kliknulo). */
  currentCardIndex,
  /** Niz objekata kartica koji nam omugaćava kretanje kroz kartice. */
  cardsArray,
  /** Postavlja se kada ne želimo heder renderovat. */
  hasHeader,
  /** Postavlja se kada ne želimo futer renderovat. */
  hasFooter,
  /** Labela dugmeta primarne akcije. */
  actionButtonLabel,
  /** Funkcija dugmete primarne akcije. */
  actionButtonFn,
  /** Labela dugmeta akcije zatvaranja modala. */
  closeButtonLabel,
  /** Funkcija dugmeta za uklanjanje. */
  removeButtonFn,
  /** Omogućava prosljeđivanje sadržaja modala. */
  content,
  /** Funckija koja se koristi za mapiranje odgovarajućeg sadržaja modala. */
  setModalContent,
}: ModalProps) => {
  modals.open({
    modalId,
    size,
    centered,
    withCloseButton: false,
    closeOnClickOutside: false,
    children: (
      <>
        {isMultistep ? (
          <MultiStepModal
            dataId={dataId}
            type={type}
            title={title}
            maxSteps={subtitles.length}
            subtitles={subtitles}
            actionButtonFn={actionButtonFn}
            closeButtonFn={() => modals.close(modalId)}
            setModalContent={setModalContent}
          />
        ) : (
          <SingleStepModal
            dataId={dataId}
            type={type}
            title={title}
            titleIcon={titleIcon}
            titleDescription={titleDescription}
            isCard={isCard}
            currentCardIndex={currentCardIndex}
            cardsArray={cardsArray}
            hasHeader={hasHeader}
            hasFooter={hasFooter}
            actionButtonLabel={actionButtonLabel}
            actionButtonFn={actionButtonFn}
            closeButtonLabel={closeButtonLabel}
            closeButtonFn={() => modals.close(modalId)}
            removeButtonFn={removeButtonFn}
            content={content}
            setModalContent={setModalContent}
          />
        )}
      </>
    ),
    onClose: () => {
      modals.closeAll();
    },
  });
};

export const openRemoveModal = ({
  modalId = `remove-modal-${Math.random()}`,
  size = ModalWidth.Auto,
  variant = 'warning',
  title,
  body,
  actionButtonLabel,
  actionButtonFn,
  closeButtonLabel,
}: DeleteModalProps) => {
  modals.open({
    modalId,
    size,
    centered: true,
    withCloseButton: false,
    children: (
      <DeleteModal
        variant={variant}
        title={title}
        body={body}
        actionButtonLabel={actionButtonLabel}
        actionButtonFn={() => {
          actionButtonFn();
          modals.close(modalId);
        }}
        closeButtonLabel={closeButtonLabel}
        closeButtonFn={() => modals.close(modalId)}
      />
    ),
  });
};
