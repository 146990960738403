import {
  FieldValues,
  Path,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

export const useTimeField = <T extends FieldValues, K extends Path<T>>({
  names,
  setValue,
  watch,
}: {
  names: K[];
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
}) => {
  const values = watch(names).reduce((acc, curr, index) => {
    acc[names[index]] = curr;
    return acc;
  }, {} as Record<K, T[K]>);

  const onTimeValueChange = (data, element) => {
    if (!data.target.name) {
      return;
    }

    setValue(data.target.name as Path<T>, element, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  return [values, onTimeValueChange] as const;
};
