import {
  Dropdown,
  FlexBox,
  FlexGrid,
  FlexGridItem,
  FormField,
  TextAreaInput,
  TextInput,
} from '@lanaco/lnc-react-ui';
import { DateInputGroup } from 'components/Input/DateInputGroup';
import { DropDownInputGroup } from 'components/Input/DropDownInputGroup';
import { useDateField } from 'components/Input/hooks/useDateField';
import { useDropdownField } from 'components/Input/hooks/useDropdownField';
import { useNumberField } from 'components/Input/hooks/useNumberField';
import { useTimeField } from 'components/Input/hooks/useTimeField';
import { NumberInputGroup } from 'components/Input/NumberInput';
import { TimeInputGroup } from 'components/Input/TimeInput';
import ProgressSteps from 'components/ProgressSteps/ProgressSteps';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { formatDateTime } from 'utils/dateFormatters';

import style from '../Events.module.css';

type Props = {
  currentStepIndex: number;
  timezone: string;
  isEdit: boolean;
};

function counter(maxValue = 100) {
  return Array.from({ length: maxValue }, (_, i) => i);
}

const statusOptions = [
  {
    value: 'active',
    label: 'Enabled',
  },
  {
    value: 'disabled',
    label: 'Disabled',
  },
  {
    value: 'passive',
    label: 'Passive',
  },
];

const ages = counter().map((i) => ({ value: i, label: i }));

const LayoutInfoForm = ({ currentStepIndex, timezone, isEdit }: Props) => {
  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    // Postavite inicijalne vrijednosti ovdje
    setValue(
      'startDateTime',
      startDateTime !== undefined
        ? new Date(
            formatDateTime(
              new Date(startDateTime).toISOString(),
              timezone!,
            ).date,
          )
        : undefined,
    );
    setValue(
      'endDateTime',
      endDateTime !== undefined
        ? new Date(
            formatDateTime(new Date(endDateTime).toISOString(), timezone!).date,
          )
        : undefined,
    );

    if (isEdit) {
      setValue(
        'startTime',
        startDateTime !== undefined
          ? formatDateTime(new Date(startDateTime).toISOString(), timezone!)
              .time
          : '',
      );
      setValue(
        'endTime',
        endDateTime !== undefined
          ? formatDateTime(new Date(endDateTime).toISOString(), timezone!).time
          : '',
      );
    } else {
      setValue('startTime', undefined);
      setValue('endTime', undefined);
    }
    setValue('status', 'active');
    // Dodajte dodatne linije za svako svojstvo koje želite inicijalizirati
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredStatusOptions = statusOptions.filter(
    (option) => option.value !== 'disabled',
  );

  const minAge = watch('participantMinAge');
  const maxAge = watch('participantMaxAge');

  const setValueHandler = (name: string, value: unknown) => {
    setValue(name, value, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  let [{ startDateTime, endDateTime }, onDateChange] = useDateField({
    names: ['startDateTime', 'endDateTime'],
    setValue,
    watch,
  });

  const [{ status }, onChangeDropdown] = useDropdownField({
    names: ['status'],
    setValue,
    watch,
  });

  const onMinAgeChange = (data) => {
    setValueHandler('participantMinAge', data.value);
    setValueHandler('participantMaxAge', data.value);
  };

  const onMaxAgeChange = (data) =>
    setValueHandler(
      'participantMaxAge',
      data.value === -1
        ? data.value
        : Math.max(data.value, minAge ? minAge : 0),
    );

  const [{ maxParticipants }, onNumberChange] = useNumberField({
    names: ['maxParticipants'],
    setValue,
    watch,
  });

  let [{ startTime, endTime }, onTimeChange] = useTimeField({
    names: ['startTime', 'endTime'],
    setValue,
    watch,
  });

  return (
    <div>
      <ProgressSteps
        title="Layout info"
        currentStep={currentStepIndex}
        totalSteps={2}
      />
      <span className={style.event_progress_body}>
        <FlexGrid spacing={10}>
          <FlexGridItem XS={12} S={12}>
            <FormField
              label="Name"
              text={errors.name && errors.name.message}
              color={errors.name && 'danger'}
            >
              <TextInput
                placeholder="Layout name"
                {...register('name', {
                  required: {
                    value: true,
                    message: 'Field name is required.',
                  },
                })}
                color={errors.name && 'danger'}
              />
            </FormField>
          </FlexGridItem>

          <FlexGridItem XS={12} S={12}>
            <FormField
              label="Description"
              text={errors.description && errors.description.message}
              color={errors.description && 'danger'}
            >
              <TextAreaInput
                placeholder={'Short description about the layout'}
                {...register('description')}
              />
            </FormField>
          </FlexGridItem>

          <FlexGridItem XS={12} S={6}>
            <FormField
              text={errors.startDateTime && errors.startDateTime.message}
              color={errors.startDateTime && 'danger'}
            >
              <DateInputGroup
                name="startDateTime"
                label="Start date"
                placeholder="e.g. 01/01/2023"
                errors={errors}
                value={startDateTime}
                onChange={onDateChange}
                register={register}
                withLargeErrorMessage={false}
              />
            </FormField>
          </FlexGridItem>
          <FlexGridItem XS={12} S={6}>
            <FormField
              text={errors.startTime && errors.startTime.message}
              color={errors.startTime && 'danger'}
            >
              <TimeInputGroup
                name="startTime"
                label="Start time"
                placeholder=""
                errors={errors}
                value={startTime}
                onChange={onTimeChange}
                register={register}
              />
            </FormField>
          </FlexGridItem>

          <FlexGridItem XS={12} S={6}>
            <FormField
              text={errors.endDateTime && errors.endDateTime.message}
              color={errors.endDateTime && 'danger'}
            >
              <DateInputGroup
                name="endDateTime"
                label="End date"
                placeholder="e.g. 01/01/2023"
                errors={errors}
                value={endDateTime}
                onChange={onDateChange}
                register={register}
                withLargeErrorMessage={false}
              />
            </FormField>
          </FlexGridItem>
          <FlexGridItem XS={12} S={6}>
            <FormField
              text={errors.endTime && errors.endTime.message}
              color={errors.endTime && 'danger'}
            >
              <TimeInputGroup
                name="endTime"
                label="End time"
                placeholder=""
                errors={errors}
                value={endTime}
                onChange={onTimeChange}
                register={register}
              />
            </FormField>
          </FlexGridItem>

          <FlexGridItem XS={12} S={12}>
            <FormField label="Age limit of participants">
              <FlexGrid alignItems="Center">
                <FlexGridItem XS={12} S={6} M={12} L={5.5}>
                  <Dropdown
                    placeholder="Min"
                    options={ages}
                    isClearable={false}
                    value={{ value: minAge, label: minAge }}
                    {...register('participantMinAge')}
                    onChange={onMinAgeChange}
                  />
                </FlexGridItem>
                <FlexGridItem XS={12} S={2} M={12} L={1}>
                  <FlexBox justifyContent="SpaceAround" alignItems="Center">
                    to
                  </FlexBox>
                </FlexGridItem>

                <FlexGridItem XS={12} S={5} M={12} L={5.5}>
                  <Dropdown
                    placeholder={'Max'}
                    isClearable={false}
                    options={ages}
                    value={{ value: maxAge, label: maxAge }}
                    {...register('participantMaxAge')}
                    onChange={onMaxAgeChange}
                  />
                </FlexGridItem>
              </FlexGrid>
            </FormField>
          </FlexGridItem>

          <FlexGridItem XS={12} S={6}>
            <NumberInputGroup
              register={register}
              label="Capacity"
              placeholder="Capacity"
              name="maxParticipants"
              errors={errors}
              value={maxParticipants !== undefined ? maxParticipants : 0}
              onChange={onNumberChange}
            />
          </FlexGridItem>

          <FlexGridItem XS={12} S={6}>
            <DropDownInputGroup
              register={register}
              placeholder={'Status'}
              name="status"
              label="Status"
              errors={errors}
              onChange={onChangeDropdown}
              value={status}
              options={filteredStatusOptions}
            />
          </FlexGridItem>
        </FlexGrid>
      </span>
    </div>
  );
};

export default LayoutInfoForm;
