import { Flex, Stack, StackProps, Text } from '@mantine/core';

import { DashboardCardType } from '../types';

const DashboardCard = ({
  titleIcon: TitleIcon,
  title,
  value1,
  value1Color = '#0891B2',
  icon: Icon,
  text,
  value2,
  ...props
}: DashboardCardType & StackProps) => {
  return (
    <Stack
      py="0.75rem"
      px="1rem"
      bg="#FFFFFF"
      bd="1px solid #0D0A2C14"
      style={{ borderRadius: '1.25rem' }}
      {...props}
    >
      <Flex gap="0.9rem" align="center">
        {TitleIcon && (
          <TitleIcon height="1.5rem" width="1.5rem" color="#94A3B8" />
        )}
        <Text fw={600} fz="1.125rem" c="#0F172A">
          {title}
        </Text>
      </Flex>
      <Flex justify="space-between" align="center ">
        <Text fw={700} fz="2rem" c={value1Color}>
          {value1}
        </Text>

        {Icon && <Icon height="2.2rem" width="2.2rem" color="#94A3B8" />}
      </Flex>
      <Flex justify="space-between">
        <Text fw={400} fz="0.875rem" c="#0F172A">
          {text}
        </Text>
        <Text fw={600} fz="0.875rem" c="#1E293B">
          {value2}
        </Text>
      </Flex>
    </Stack>
  );
};

export default DashboardCard;
