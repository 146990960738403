import { Button } from '@lanaco/lnc-react-ui';
import { forwardRef } from 'react';

import { ButtonColor, ButtonType, TLButtonProps } from '../interfaces';

export const TLNeutralButton = forwardRef<HTMLButtonElement, TLButtonProps>(
  ({ type = 'button', ...props }, ref) => (
    <Button
      ref={ref}
      type={type}
      color={ButtonColor.NEUTRAL}
      btnType={ButtonType.OUTLINE}
      {...props}
    />
  ),
);
