import { Event as EventEntity } from 'pages/Events/interfaces';
import { EventPayload } from 'pages/Events/MultiStepForm/event-schema';
import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import { INSERT_EVENT, SET_EVENT_HERO_IMAGE, UPDATE_EVENT } from 'utils/apiUrl';

interface UpdateParams {
  eventId: string;
}

export class EventService extends ResponseInterceptor {
  static async createEvent(
    body: EventPayload,
  ): Promise<APIAxiosResponse<EventEntity>> {
    const res = await client.post(INSERT_EVENT, body);

    return this.handleResponse(res);
  }

  static async updateEvent(
    { eventId }: UpdateParams,
    body: EventPayload,
  ): Promise<APIAxiosResponse<EventEntity>> {
    const res = await client.put(UPDATE_EVENT(eventId), body);

    return this.handleResponse(res);
  }

  static async setHeroImage(
    { eventId }: UpdateParams,
    body: FormData,
  ): Promise<APIAxiosResponse<EventEntity>> {
    const res = await client.post(SET_EVENT_HERO_IMAGE(eventId), body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return this.handleResponse(res);
  }
}
