import { InputGroup } from 'components/Input';
import { DateInputGroup } from 'components/Input/DateInputGroup';
import { DropDownInputGroup } from 'components/Input/DropDownInputGroup';
import { GooglePlacesAutocomplete } from 'components/Input/GooglePlacesAutocomplete/GooglePlacesAutocomplete';
import { useDateField } from 'components/Input/hooks/useDateField';
import { useDropdownField } from 'components/Input/hooks/useDropdownField';
import { useTimeField } from 'components/Input/hooks/useTimeField';
import { TimeInputGroup } from 'components/Input/TimeInput';
import { ResponsiveRow } from 'components/utils/ResponsiveRow/ResponsiveRow';
import { useFetchClients } from 'hooks/client';
import { useFetchEventTypes } from 'hooks/event/useFetchTypes';
import { useZodForm } from 'hooks/useZodForm';
import { MultiStepFormContent } from 'pages/Events/MultiStepForm/MultiStepFormContent/MultiStepFormContent';
import { MultiStepFormContentFooter } from 'pages/Events/MultiStepForm/MultiStepFormContent/MultiStepFormContentFooter/MultiStepFormContentFooter';
import { useFormContext } from 'react-hook-form';

import { useMultiStep } from '../../MultiStepForm/context/multi-step-context';
import {
  EventFormSchema,
  eventInfoSchema,
  EventInfoSchema,
} from '../../MultiStepForm/event-schema';
export type EventInfoFormProps = {
  defaultValues: EventInfoSchema;
  step: number;
  onCancel?: () => void;
  userIsInstitution: boolean;
};

export const EventInfoForm = ({
  defaultValues,
  step,
  onCancel,
  userIsInstitution,
}: EventInfoFormProps) => {
  const { next } = useMultiStep();

  const { setValue: setContextValue } = useFormContext<EventFormSchema>();

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useZodForm<EventInfoSchema>(
    {
      defaultValues,
      mode: 'onBlur',
    },
    eventInfoSchema,
  );

  const { data } = useFetchClients({
    offset: 0,
    row_limit: 100,
  });

  const clients = (data?.data || []).map((c) => ({
    value: c._id,
    label: c.status === 'active' ? c.name : c.name + ' Passive',
  }));

  const timeZones = [
    { value: '-12:00', label: '(UTC-12:00) International Date Line West' },
    { value: '-11:00', label: '(UTC-11:00) Coordinated Universal Time-11' },
    { value: '-10:00', label: '(UTC-10:00) Hawaii' },
    { value: '-09:00', label: '(UTC-09:00) Alaska' },
    { value: '-08:00', label: '(UTC-08:00) Pacific Time (US & Canada)' },
    { value: '-07:00', label: '(UTC-07:00) Mountain Time (US & Canada)' },
    { value: '-06:00', label: '(UTC-06:00) Central Time (US & Canada)' },
    { value: '-05:00', label: '(UTC-05:00) Eastern Time (US & Canada)' },
    { value: '-04:00', label: '(UTC-04:00) Atlantic Time (Canada)' },
    { value: '-03:00', label: '(UTC-03:00) Buenos Aires, Georgetown' },
    { value: '-02:00', label: '(UTC-02:00) Mid-Atlantic' },
    { value: '-01:00', label: '(UTC-01:00) Cape Verde Islands' },
    { value: '+00:00', label: '(UTC) Coordinated Universal Time' },
    {
      value: '+01:00',
      label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    },
    { value: '+02:00', label: '(UTC+02:00) Athens, Bucharest, Istanbul' },
    { value: '+03:00', label: '(UTC+03:00) Moscow, St. Petersburg, Volgograd' },
    { value: '+04:00', label: '(UTC+04:00) Abu Dhabi, Muscat' },
    { value: '+05:00', label: '(UTC+05:00) Islamabad, Karachi' },
    { value: '+06:00', label: '(UTC+06:00) Astana, Dhaka' },
    { value: '+07:00', label: '(UTC+07:00) Bangkok, Hanoi, Jakarta' },
    {
      value: '+08:00',
      label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    },
    { value: '+09:00', label: '(UTC+09:00) Osaka, Sapporo, Tokyo' },
    { value: '+10:00', label: '(UTC+10:00) Canberra, Melbourne, Sydney' },
    { value: '+11:00', label: '(UTC+11:00) Solomon Islands, New Caledonia' },
    { value: '+12:00', label: '(UTC+12:00) Auckland, Wellington' },
  ];

  const { data: eventType } = useFetchEventTypes();

  const eventTypes = (eventType?.data || []).map((c) => ({
    value: c.type,
    label: c.description,
  }));

  const [{ company, type, timezone }, onDropdownChange] = useDropdownField({
    names: ['company', 'type', 'timezone'],
    setValue,
    watch,
  });

  const [{ location }, onLocationChange] = useDropdownField<
    EventInfoSchema,
    'location'
  >({
    names: ['location'],
    setValue,
    watch,
  });

  const [{ startDate, finishDate }, onDateChange] = useDateField({
    names: ['startDate', 'finishDate'],
    setValue,
    watch,
  });

  let [{ startTime, finishTime }, onTimeChange] = useTimeField({
    names: ['startTime', 'finishTime'],
    setValue,
    watch,
  });

  const onSubmit = (data: EventInfoSchema) => {
    setContextValue('eventInfo', data);

    next();
  };

  return (
    <MultiStepFormContent step={step}>
      <DropDownInputGroup
        withAsterisk
        register={register}
        label="Type"
        placeholder="Select type"
        name="type"
        value={type}
        errors={errors}
        options={eventTypes}
        onChange={onDropdownChange}
      />
      <InputGroup
        withAsterisk
        register={register}
        label="Name"
        placeholder="Enter event name"
        name="name"
        errors={errors}
      />

      {userIsInstitution && (
        <DropDownInputGroup
          withAsterisk
          register={register}
          label="Client"
          placeholder="Select client"
          name="company"
          value={company}
          errors={errors}
          options={clients}
          onChange={onDropdownChange}
        />
      )}

      <DropDownInputGroup
        withAsterisk
        register={register}
        label="TimeZone Offset"
        placeholder="Select TimeZone Offset"
        name="timezone"
        value={timezone}
        errors={errors}
        options={timeZones}
        onChange={onDropdownChange}
      />

      <ResponsiveRow>
        <DateInputGroup
          withAsterisk
          register={register}
          name="startDate"
          label="Start date"
          placeholder="e.g. 01/01/2023"
          errors={errors}
          value={startDate}
          onChange={onDateChange}
        />
        <TimeInputGroup
          withAsterisk
          name="startTime"
          label="Start time"
          placeholder=""
          errors={errors}
          value={startTime}
          onChange={onTimeChange}
          register={register}
        />
      </ResponsiveRow>
      <ResponsiveRow>
        <DateInputGroup
          withAsterisk
          register={register}
          name="finishDate"
          label="Finish date"
          placeholder="e.g. 01/01/2023"
          value={finishDate}
          errors={errors}
          withLargeErrorMessage={errors.finishDate?.message !== 'Required'}
          onChange={onDateChange}
        />
        <TimeInputGroup
          withAsterisk
          name="finishTime"
          label="Finish time"
          placeholder=""
          errors={errors}
          value={finishTime}
          onChange={onTimeChange}
          register={register}
        />
      </ResponsiveRow>

      <GooglePlacesAutocomplete
        register={register}
        label="Location"
        placeholder="Enter location"
        name="location"
        value={location.description || undefined}
        errors={errors}
        onChange={onLocationChange}
      />

      <InputGroup
        register={register}
        label="Link"
        placeholder="e.g. https://www.company.com/event"
        name="eventUrl"
        errors={errors}
      />

      <InputGroup
        register={register}
        mode="textarea"
        label="Description"
        placeholder="Short description about the event"
        name="description"
        errors={errors}
      />

      <MultiStepFormContentFooter
        isValid={isValid}
        onConfirm={handleSubmit(onSubmit)}
        onCancel={onCancel}
      />
    </MultiStepFormContent>
  );
};
