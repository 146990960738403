import { Spinner } from '@lanaco/lnc-react-ui';
import clsx from 'clsx';

import { SpinnerColor, SpinnerSize } from './interfaces';
import styles from './Spinner.module.css';

interface SpinnerProps {
  label?: string;
  className?: string;
  style?: React.CSSProperties;
  size?: SpinnerSize;
  color?: SpinnerColor;
}

export const TLSpinner = ({
  color = SpinnerColor.PRIMARY,
  size = SpinnerSize.SMALL,
  className,
  ...props
}: SpinnerProps) => (
  <Spinner
    className={clsx(
      { [styles.spinner]: size === SpinnerSize.XSMALL },
      className,
    )}
    color={color}
    size={size === SpinnerSize.XSMALL ? undefined : size}
    {...props}
  />
);
