import axios, { AxiosError, AxiosResponse } from 'axios';
import { AUTH_REFRESH_TOKEN } from 'utils/apiUrl';

import { getSession, logOut, setSession } from './auth';

export enum ResponseStatus {
  OK,
  FAIL,
  ERROR,
}

export interface ApiResultRoute {
  limit?: number;
  next?: string;
  offset?: number;
  prev?: string;
  returned?: number;
  total?: number;
  url: string;
}

export interface ResponseErrorShared {
  success: boolean;
  code: number;
  status: ResponseStatus;
  message: string;
  data: unknown;
}

export interface ResponseError extends ResponseErrorShared {
  _route: { url: string };
}

export interface ResponseValidationError extends ResponseErrorShared {
  errors: unknown[];
}

export type APIAxiosError<
  T = ResponseError | ResponseValidationError
> = AxiosError<T>;

export type APIAxiosErrorResponse = APIAxiosError['response'];

export interface ApiResult<T = unknown> {
  code: number;
  data: T;
  errors: unknown;
  message: string;
  status: string;
  success: boolean;
  _route: ApiResultRoute;
}

const SERVER_BASE =
  process.env.REACT_APP_API_LOCATION ||
  'https://timelineapidevenv.azurewebsites.net';
const API_VERSION = 'v1';
const baseURL = `${SERVER_BASE}/api/${API_VERSION}`;
const headers = {
  'Content-Type': 'application/json',
};

let refreshingToken = false;

export const client = axios.create({
  headers: headers,
  baseURL: baseURL,
});

client.interceptors.response.use(
  _responseSuccessInterceptor,
  _responseErrorInterceptor,
);
client.interceptors.request.use(_requestInterceptor);

function _responseSuccessInterceptor(response: AxiosResponse<ApiResult>) {
  return response;
}

function _requestInterceptor(config: any) {
  const session = getSession();
  if (config.headers) {
    config.headers['Authorization'] = `Bearer ${session?.accessToken}`;
  }
  return config;
}
function _responseErrorInterceptor(error: any) {
  if (!error.response) return Promise.reject(error);

  const { code, message } = error.response.data;

  // Token missing or invalid
  if (code === 401 && message === 'jwt malformed') {
    logOut();
    window.location.href = '/login';
    console.warn('[Token missing or invalid]');
  }

  // Token has epxired
  if (code === 401 && message === 'jwt expired') {
    return _tokenExpired(error);
  }
  return error.response;
}

// let subscribers: Array<(at: string) => any> = [];

async function _tokenExpired(error: any) {
  try {
    const { response: errorResponse } = error;
    const { refreshToken } = getSession();

    if (!refreshToken) {
      return Promise.reject(error);
    }

    // const retryOriginalRequest = new Promise((resolve) => {
    //   addSubscribe((access_token: string) => {
    //     errorResponse.config.headers.Authorization = `Bearer ${access_token}`;
    //     resolve(axios(errorResponse.config));
    //   });
    // });

    if (!refreshingToken) {
      refreshingToken = true;

      try {
        const response = await client.post(AUTH_REFRESH_TOKEN, {
          refreshToken: refreshToken,
        });
        if (response.status >= 200 && response.status <= 300) {
        }

        refreshingToken = false;
        setSession(
          response.data.data.accessToken,
          response.data.data.refreshToken,
        );

        //onRefreshed(response.data.data.accessToken);
        errorResponse.config.headers = {
          Authorization: 'Bearer ' + response.data.data.accessToken,
          'Content-Type': 'application/json',
        };

        return client(errorResponse.config);
      } catch (error) {
        refreshingToken = false;
        logOut();
        return Promise.reject(error);
      }
    }
  } catch (error) {
    // Any errors to refresh should result in logout
    refreshingToken = false;
    logOut();
  }
}

// function addSubscribe(cb: (at: string) => any) {
//   console.log(cb);
//   subscribers.push(cb);
// }

// function onRefreshed(accessToken: string) {
//   subscribers.forEach((callback) => callback(accessToken));
//   subscribers = [];
// }
