import clsx from 'clsx';
import { TLNeutralButton, TLPrimaryButton } from 'components/Button';
import React from 'react';

import { useMultiStep } from '../../context/multi-step-context';

import styles from './MultiStepFormContentFooter.module.scss';

type Props = {
  isSubmitting?: boolean;
  isValid?: boolean;
  clearDisabled?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  onClear?: () => void;
};

export const MultiStepFormContentFooter = ({
  isSubmitting = false,
  isValid = true,
  clearDisabled = false,
  onCancel,
  onConfirm,
  onClear,
}: Props) => {
  const { isFirstStep, isLastStep, back, next } = useMultiStep();

  const onCancelHandler = () => {
    onCancel ? onCancel?.() : back();
  };

  const onConfirmHandler = () => {
    onConfirm ? onConfirm?.() : next();
  };

  return (
    <div
      className={clsx(
        styles.multiStepFormContentFooter,
        onClear && styles.withClearButton,
      )}
    >
      {onClear && (
        <TLNeutralButton disabled={clearDisabled} onClick={onClear}>
          Clear
        </TLNeutralButton>
      )}

      <div className={styles.buttonActions}>
        <TLNeutralButton onClick={onCancelHandler}>
          {isFirstStep ? 'Cancel' : 'Back'}
        </TLNeutralButton>

        <TLPrimaryButton
          disabled={!isValid || isSubmitting}
          onClick={onConfirmHandler}
          type={isLastStep ? 'submit' : 'button'}
        >
          {isLastStep ? 'Done' : 'Next'}
        </TLPrimaryButton>
      </div>
    </div>
  );
};
