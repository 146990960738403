import { useQuery } from '@tanstack/react-query';
import {
  EventTimingLocationDetails,
  EventTimingLocations,
} from 'pages/Events/TimingLocations/TimingLocations.interfaces';
import { client } from 'services/api';
import { GET_TIMING_LOCATION_DETAILS } from 'utils/apiUrl';

export const useFetchEventTimingLocationDetails = (
  eventId: string,
  layoutId: string,
  sectionId: string,
) => {
  const queryResult = useQuery<any>({
    queryKey: ['eventTimingLocation', eventId, layoutId, sectionId],
    queryFn: async () => {
      //if (!eventId) return [];

      const retVal = await client.get(
        GET_TIMING_LOCATION_DETAILS(eventId, layoutId, sectionId),
      );

      return retVal.data.data;
    },
  });

  return queryResult;
};
