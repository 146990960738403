import clsx from 'clsx';
import DragAndDropImageInputGroup from 'components/DragAndDropImage/DragAndDropImageInputGroup';
import React, { useState } from 'react';

import { EventLayoutPresentationType } from '../interfaces';

import styles from './LayoutImageUpload.module.css';

export interface LayoutImageUploadProps {
  source?: string;
  onChange: (type: EventLayoutPresentationType.Image, image: File) => void;
}

export const LayoutImageUpload = ({
  source,
  onChange,
}: LayoutImageUploadProps) => {
  const [image, setImage] = useState<string | null>(null);

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      onChange(EventLayoutPresentationType.Image, event.target.files[0]);
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  const pictureChangedHandler = (newPicture: File | null) => {
    getBase64(newPicture).then((res) => {
      setImage(res as string);
      onChange(EventLayoutPresentationType.Image, newPicture as File);
    });
  };

  const imageSource = image || source;
  return (
    <div className={styles.imagePreviewContainer}>
      <label
        htmlFor="imageInput"
        className={clsx(
          styles.imageInputLabel,
          (image !== null || imageSource != null) && styles.imageInputWithImage,
        )}
      >
        {!imageSource ? (
          <DragAndDropImageInputGroup
            name={'image'}
            dndFileText={
              <div className={styles.dndFileText}>
                <span> Recommended image size: 1920x1080 pixels.</span>
                <span> JPEG or PNG file.</span>
              </div>
            }
            selectFileText={'Click to upload'}
            onUploadImage={pictureChangedHandler}
          />
        ) : (
          <>
            <span className={styles.imageInputLabelSpan}>Upload an image</span>
            <input
              id="imageInput"
              className={styles.imageInput}
              onChange={onImageChange}
              accept="image/*"
              type="file"
            />
          </>
        )}
      </label>
      {imageSource && (
        <img
          className={styles.imagePreview}
          src={imageSource}
          alt="preview of event layout"
        />
      )}
    </div>
  );
};
