import { FlexGrid, FlexGridItem } from '@lanaco/lnc-react-ui';
import { IconButton } from '@lanaco/lnc-react-ui';
import Sidebar from 'components/Sidebar/Sidebar';
import { useState } from 'react';

import { Children } from './Layout.interface';
import style from './Layout.module.css';

const Layout = ({ children }: Children) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const onClickMenu = () => {
    if (!isNavExpanded) {
      setIsNavExpanded(isNavExpanded);

      const box = document.getElementById('sidebar');

      if (box != null) {
        box.style.setProperty('display', 'flex');
        box.style.setProperty('position', 'fixed');
        if (window.innerWidth < 574) box.style.setProperty('width', 'auto');
        else box.style.setProperty('width', 'auto');
      }
    }
  };

  return (
    <div className={style.wrapper}>
      <FlexGrid>
        <FlexGridItem XS={12} L={2}>
          <Sidebar isOpen={isNavExpanded} />
        </FlexGridItem>
        <FlexGridItem XS={12} L={10}>
          <div className={style.content}>
            <div className={style.menu}>
              <IconButton
                btnType={'tinted'}
                icon="fas fa-bars"
                size={'large'}
                color={'secondary'}
                iconStyle={'solid'}
                onClick={onClickMenu}
              />
            </div>
            {children}
          </div>
        </FlexGridItem>
      </FlexGrid>
    </div>
  );
};

export default Layout;
