import { useWindowSize } from 'react-use';
import { Flex, Text, TextInput } from '@mantine/core';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { HeaderTitleType } from './types';
import styles from './HeaderTitle.module.css';

const Header = ({
  title,
  subtitle,
  hasBorder,
  onSearch,
  icon: Icon,
}: HeaderTitleType) => {
  const headerClassName = hasBorder === false ? styles.noBorder : styles.header;
  const { width } = useWindowSize();

  return (
    <Flex
      justify="space-between"
      direction={width < 576 ? 'column' : 'row'}
      p={width < 576 ? '1rem' : ''}
      className={headerClassName}
    >
      <Flex
        justify="flex-end"
        align="flex-start"
        gap="0.25rem"
        direction="column"
      >
        <Flex gap="0.8rem" align="center" justify="center">
          {Icon && <Icon height="1.5rem" width="1.5rem" color="#94A3B8" />}
          <Text fz="1.5rem" fw={600} lh="2rem" c="#0f172a">
            {title}
          </Text>
        </Flex>

        <Text fz="0.875rem" c="#475569">
          {subtitle}
        </Text>
      </Flex>

      <TextInput
        w="17.5rem"
        mt="auto"
        radius="0.5rem"
        placeholder="Search"
        leftSection={
          <MagnifyingGlassIcon height={16.25} width={16.25} color="#94A3B8" />
        }
        onChange={(e) => onSearch && onSearch(e.target.value)}
        styles={{
          input: {
            color: '#64748B',
          },
        }}
      />
    </Flex>
  );
};

export default Header;
