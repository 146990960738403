import { DonutChart as MantineDonutChart } from '@mantine/charts';
import { Box, Divider, Flex, Stack, StackProps, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

import { DonutChartProps } from '../types';

const DonutChart = ({
  title,
  data,
  chartLabelText,
  chartLabelValue,
  startAngle = 180,
  endAngle = 0,
  showDivider = false,
  thickness = 50,
  strokeWidth = 0,
  paddingAngle,
  cornerRadius,
  ...props
}: DonutChartProps & StackProps) => {
  const isHalfCircle = Math.abs(endAngle - startAngle) === 180;
  const height1 = isHalfCircle ? 150 : 300;
  const formattedData = data.map((item) => ({
    ...item,
    value:
      typeof item.value === 'string' && item.value.includes('%')
        ? parseFloat(item.value.replace('%', ''))
        : Number(item.value),
  }));

  const { height, width } = useViewportSize();
  const responsiveWidth = width * 0.2;
  const responsiveHeight = height * 0.26;
  const responsiveSize = Math.min(responsiveWidth, responsiveHeight);

  const hasLabel = chartLabelText && chartLabelText !== '';

  return (
    <Stack
      py="1rem"
      px="1.5rem"
      gap="2rem"
      bg="#FFFFFF"
      bd="1px solid #0D0A2C14"
      style={{ borderRadius: '1.25rem' }}
      {...props}
    >
      <Text fw={600} fz="1.125rem" c="#0F172A">
        {title}
      </Text>
      <Stack justify="center" align="center" pos="relative">
        {hasLabel && (
          <Text
            fz="0.875rem"
            c="#475569"
            fw={400}
            pos="absolute"
            style={{
              top: isHalfCircle ? '70%' : '40%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {chartLabelText}
          </Text>
        )}

        <Box
          pos="relative"
          h={height1}
          style={{
            overflow: 'hidden',
            borderRadius: '0.625rem',
          }}
        >
          {height && width && (
            <MantineDonutChart
              data={formattedData}
              paddingAngle={paddingAngle}
              h={height * 0.26}
              w={width * 0.2}
              size={responsiveSize}
              thickness={thickness}
              withTooltip={false}
              startAngle={startAngle}
              endAngle={endAngle}
              strokeWidth={strokeWidth}
              pieProps={{ cornerRadius: cornerRadius }}
            />
          )}
        </Box>

        <Text
          fz="2rem"
          c="#1E293B"
          fw={700}
          pos="absolute"
          style={{
            top: hasLabel ? (isHalfCircle ? '90%' : '50%') : '45%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {chartLabelValue}
        </Text>
      </Stack>

      <Box>{showDivider && <Divider c="#E2E8F0" />}</Box>

      {data.length === 4 ? (
        <Stack>
          <Flex wrap="wrap" gap="1.5rem" justify="center">
            {data.slice(0, 2).map((item, index) => (
              <Flex key={index} align="center" gap="0.5rem">
                <Box
                  w="1rem"
                  h="1rem"
                  bg={item.color}
                  style={{ borderRadius: '50%' }}
                />
                <Text ta="center" fz="0.875rem" c="#475569" fw={400}>
                  {item.label}
                </Text>
                <Text ta="center" fz="0.875rem" c="#475569" fw={600}>
                  {item.value}
                </Text>
              </Flex>
            ))}
          </Flex>
          <Flex wrap="wrap" gap="1.5rem" justify="center">
            {data.slice(2, 4).map((item, index) => (
              <Flex key={index} align="center" gap="0.5rem">
                <Box
                  w="1rem"
                  h="1rem"
                  bg={item.color}
                  style={{ borderRadius: '50%' }}
                />
                <Text ta="center" fz="0.875rem" c="#475569" fw={400}>
                  {item.label}
                </Text>
                <Text ta="center" fz="0.875rem" c="#475569" fw={600}>
                  {item.value}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Stack>
      ) : (
        <Flex wrap="wrap" gap="1.5rem" justify="center">
          {data.map((item, index) => (
            <Flex key={index} align="center" gap="0.5rem">
              <Box
                w="1rem"
                h="1rem"
                bg={item.color}
                style={{ borderRadius: '50%' }}
              />
              <Text ta="center" fz="0.875rem" c="#475569" fw={400}>
                {item.label}
              </Text>
              <Text ta="center" fz="0.875rem" c="#475569" fw={600}>
                {item.value}
              </Text>
            </Flex>
          ))}
        </Flex>
      )}
    </Stack>
  );
};

export default DonutChart;
