import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/16/solid';
import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Text,
  TextInput,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { SortingParamType } from 'components/SortingDropdown/types';
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from 'mantine-react-table';
import { addDevicesSortingParams } from 'pages/Entities/consts';
import React, { useMemo, useState } from 'react';

import SortingDropdown from '../../../../../components/SortingDropdown/SortingDropdown';
import AdditionalFilters from '../../LeftHubContainer/AdditionalFilters/AdditionalFilters';
import { DeviceTableType, FilterData } from '../../types';
import styles from '../TableModals.module.css';

const types: Array<FilterData> = [
  { id: '1', value: 'Processor' },
  { id: '2', value: 'Relay' },
  { id: '3', value: 'Anthena' },
  { id: '4', value: 'Anthena & Relay' },
];

const statuses: Array<FilterData> = [
  { id: '1', value: 'Enabled' },
  { id: '2', value: 'Disabled' },
  { id: '3', value: 'Online' },
];

const states: Array<FilterData> = [
  { id: '1', value: ' Online' },
  { id: '2', value: 'Offline' },
];

const data: DeviceTableType[] = [
  {
    deviceName: 'Device 1234',
    type: 'Processor',
    macAdress: 'F00D585S',
    manufacturer: 'Lenovo',
    state: 'Offline',
    status: 'Disabled',
  },
  {
    deviceName: 'Device 1234',
    type: 'Processor',
    macAdress: 'F00D585S',
    manufacturer: 'Lenovo',
    state: 'Offline',
    status: 'Enabled',
  },
  {
    deviceName: 'Device 1234',
    type: 'Processor',
    macAdress: 'F00D585S',
    manufacturer: 'Lenovo',
    state: 'Online',
    status: 'Enabled',
  },
  {
    deviceName: 'Device 1234',
    type: 'Processor',
    macAdress: 'F00D585S',
    manufacturer: 'Lenovo',
    state: 'Online',
    status: 'Enabled',
  },
  {
    deviceName: 'Device 1234',
    type: 'Processor',
    macAdress: 'F00D585S',
    manufacturer: '',
    state: 'Offline',
    status: 'Disabled',
  },
];

const AddDevicesModal = () => {
  const [sortingParam, setSortingParam] = useState<SortingParamType | null>(
    null,
  );
  const [showAdditionalFilters, setShowAdditionalFilters] = useState<boolean>(
    false,
  );
  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>(
    {},
  );

  const columns = useMemo<MRT_ColumnDef<DeviceTableType>[]>(
    () => [
      {
        header: 'Device name',
        accessorKey: 'deviceName',

        Cell: ({ cell }) => {
          return (
            <div className={`${styles.firstColumn}`}>
              {cell.getValue() as React.ReactNode}
            </div>
          );
        },
      },
      {
        header: 'Type',
        accessorKey: 'type',
      },
      {
        header: 'Mac adress',
        accessorKey: 'macAdress',
      },
      {
        header: 'Manufacturer',
        accessorKey: 'manufacturer',

        Cell: ({ cell }) => {
          const value = cell.getValue();
          return <>{value ? value : '-'}</>;
        },
      },
      {
        header: 'State',
        accessorKey: 'state',

        Cell: ({ row }) => {
          const state = row.original.state;
          return (
            <Badge
              variant="light"
              color={state === 'Online' ? '#10B981' : '#64748B'}
              radius={12}
              fz="0.75rem"
              tt="none"
            >
              {state}
            </Badge>
          );
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',

        Cell: ({ row }) => {
          const status = row.original.status;
          return (
            <Badge
              variant="light"
              color={status === 'Enabled' ? '#10B981' : '#64748B'}
              radius={12}
              fz="0.75rem"
              tt="none"
            >
              {status}
            </Badge>
          );
        },
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    enableRowSelection: true,

    state: {
      rowSelection,
    },

    onRowSelectionChange: (newRowSelection) => {
      setRowSelection(newRowSelection);
    },

    mantineTableBodyRowProps: ({ row }) => {
      return {
        className: row.getIsSelected() ? styles.selectedRow : '',
      };
    },

    mantineSelectAllCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantineSelectCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantinePaperProps: {
      style: {
        boxShadow: 'none',
        borderRadius: '0.5rem',
        borderColor: '#E2E8F0',
      },
    },
  });

  return (
    <div className={styles.tableContainer}>
      <Flex mr="0.5rem" mb="1.5rem" justify="center">
        <TextInput
          size="sm"
          mr="0.75rem"
          fz="1rem"
          radius="md"
          flex={1}
          placeholder="Search"
          leftSection={
            <MagnifyingGlassIcon
              height="1.25rem"
              width="1.25rem"
              color="#94A3B8"
            />
          }
        />
        <Flex align="center" gap="0.75rem">
          <SortingDropdown
            sortingParams={addDevicesSortingParams}
            sortingParam={sortingParam}
            setSortingParam={setSortingParam}
          />
          <ActionIcon
            bg="#ffffff"
            c={showAdditionalFilters ? '#3B82F6' : '#0F172A'}
            onClick={() => setShowAdditionalFilters((prev) => !prev)}
          >
            <AdjustmentsHorizontalIcon height="1.5rem" width="1.5rem" />
          </ActionIcon>
        </Flex>
      </Flex>

      {showAdditionalFilters && (
        <AdditionalFilters statuses={statuses} states={states} />
      )}

      <MantineReactTable table={table} />

      <Flex mt="2rem" justify="space-between" align="center">
        <Text>{Object.keys(rowSelection).length} rows selected</Text>

        <Flex justify="flex-end" gap="1rem">
          <Button
            bg="#ffffff"
            bd="1px solid #e2e8f0"
            c="#344155"
            onClick={modals.closeAll}
          >
            Cancel
          </Button>
          <Button
            bg="#3b82f6"
            c="#ffffff"
            onClick={() => {
              modals.closeAll();
            }}
          >
            Add
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};

export default AddDevicesModal;
