import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

import {
  DropDownInputGroup,
  DropDownInputGroupProps,
  DropdownOnChange,
} from '../DropDownInputGroup';

type Props<T extends FieldValues> = Omit<
  DropDownInputGroupProps<T>,
  'options' | 'onInputChange' | 'value'
> & {
  value?: string;
};

export const GooglePlacesAutocomplete = <T extends FieldValues>({
  name,
  label,
  placeholder,
  errors,
  value,
  register,
  onChange,
}: Props<T>) => {
  const {
    ready,
    value: placeValue,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({ defaultValue: value });

  const onSelectedAddress: DropdownOnChange<T> = async (option, action) => {
    const address = (option?.value ?? '') as string;

    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);

    onChange?.(
      {
        label: address,
        value: { description: address, latitude: lat, longitude: lng },
      },
      action,
    );
  };

  const options = useMemo(() => {
    if (value === placeValue) {
      return [{ label: value ?? '', value: value ?? '' }];
    }

    if (value !== placeValue && status === 'OK') {
      return data.map(({ description }) => ({
        label: description,
        value: description,
      }));
    }

    return [];
  }, [data, placeValue, status, value]);

  return (
    <DropDownInputGroup
      register={register}
      options={options}
      value={placeValue}
      name={name}
      label={label}
      placeholder={placeholder}
      isDisabled={!ready}
      errors={errors}
      defaultInputValue={placeValue}
      onChange={onSelectedAddress}
      onInputChange={setValue}
    />
  );
};
