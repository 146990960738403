import { Badge, notification } from '@lanaco/lnc-react-ui';
import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper, RowSelectionState } from '@tanstack/react-table';
import clsx from 'clsx';
import {
  TLDangerButton,
  TLNeutralButton,
  TLPrimaryButton,
} from 'components/Button';
import ButtonGroupData from 'components/ButtonGroupData/ButtonGroupData';
import { ButtonGroupDataInterface } from 'components/ButtonGroupData/ButtonGroupData.interface';
import Header from 'components/Header/Header';
import { TLModal } from 'components/Modal';
import { StatInterface } from 'components/Stat/Stat.interface';
import Stats from 'components/Stats/Stats';
import TanStackTable, {
  RowAction,
} from 'components/TanStackTable/TanStackTable';
import { tagStatisticsBaseKey } from 'hooks/event/statistics/baseKeys';
import { useFetchTagStatistics } from 'hooks/event/statistics/useFetchTagStatistics';
import { useFetchTags } from 'hooks/tag';
import { tagBaseKey } from 'hooks/tag/baseKey';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { client } from 'services/api';
import { Response } from 'types/response';
import { TOGGLE_TAG_STATUSES } from 'utils/apiUrl';

import idle from '../../assets/icons/indicator-idle.svg';
import offline from '../../assets/icons/indicator-offline.svg';
import online from '../../assets/icons/indicator-online.svg';
import arrowSmallDown from '../../assets/icons/mini/arrow-small-down.svg';
import arrowSmallUp from '../../assets/icons/mini/arrow-small-up.svg';

import { DeleteTagModal } from './DeleteTagModal/DeleteTagModal';
import { DeleteTagsModal } from './DeleteTagsModal/DeleteTagsModal';
import { TagTableData } from './interfaces';
import TagForm from './TagForm';
import { ToggleEnabledTagModal } from './ToggleEnabledTagModal/ToggleEnabledTagModal';

interface TagsProps {}

const columnHelper = createColumnHelper<TagTableData>();
const ROW_LIMIT = 10;

const tagStatusToBadgeColorMap = {
  offline: 'bg-gray-50 text-gray-500',
  online: 'bg-green-50 text-green-500',
  idle: 'bg-yellow-50 text-yellow-500',
};

const Tags = (props: TagsProps) => {
  const queryClient = useQueryClient();

  const [status, setStatus] = useState('all');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [tagToEdit, setTagToEdit] = useState<TagTableData | null>(null);
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});

  const selectedIds = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    },
    [],
  );

  const [showInsertTagModal, _hideInsertTagModal] = useModal(() => {
    const hideModal = () => {
      setTagToEdit(null);
      _hideInsertTagModal();
    };

    return (
      <TLModal
        title={!tagToEdit ? 'Add new tag' : 'Edit tag'}
        onClose={hideModal}
      >
        <TagForm closeModal={hideModal} data={tagToEdit} isEdit={!!tagToEdit} />
      </TLModal>
    );
  }, [tagToEdit]);

  const [
    showToggleEnabledTagModal,
    hideToggleEnabledTagModal,
  ] = useModal(
    () => (
      <ToggleEnabledTagModal
        id={tagToEdit?._id as string}
        closeModal={hideToggleEnabledTagModal}
        onTagEdit={setTagToEdit}
        name={tagToEdit?.name}
        status={tagToEdit?.status}
      />
    ),
    [tagToEdit?._id, tagToEdit?.status, tagToEdit?.name],
  );

  const [showDeleteTagModal, _hideDeleteTagModal] = useModal(() => {
    const hideDeleteTagModal = () => {
      setTagToEdit(null);
      _hideDeleteTagModal();
    };

    return (
      <DeleteTagModal
        id={tagToEdit?._id as string}
        name={tagToEdit?.name}
        onTagEdit={setTagToEdit}
        closeModal={hideDeleteTagModal}
      />
    );
  }, [tagToEdit?.name, tagToEdit?._id]);

  const [showDeleteTagsModal, hideDeleteTagsModal] = useModal(
    () => (
      <DeleteTagsModal
        selectedIds={selectedIds}
        onTagEdit={setTagToEdit}
        closeModal={hideDeleteTagsModal}
        setSelectedRows={setSelectedRows}
      />
    ),
    [selectedIds],
  );

  const { data, isFetching } = useFetchTags({
    offset: (page - 1) * ROW_LIMIT,
    row_limit: ROW_LIMIT,
    status,
    filter: search,
  });

  const { _route, data: tags } = data || {
    _route: { returned: 0, total: 0 },
    data: [],
  };

  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  const isMassEnabling = selectedIds.some(
    (id) =>
      queryClient.getQueryData<TagTableData>([tagBaseKey, id])?.status ===
      'disabled',
  );

  const getHandleStatusFilterChangeCallback = (status: string) => () => {
    setPage(1);
    setStatus(status);
  };

  const { data: tagStatistics } = useFetchTagStatistics();

  const enabledTags =
    tagStatistics && tagStatistics?.totalTags - tagStatistics?.disabledTags;

  const buttons: Array<ButtonGroupDataInterface> = [
    {
      text: 'All',
      text1: tagStatistics?.totalTags?.toString(),
      indicator: '',
      onClick: getHandleStatusFilterChangeCallback('all'),
    },
    {
      text: 'Online',
      text1: tagStatistics?.onlineTags?.toString(),
      indicator: online,
      onClick: getHandleStatusFilterChangeCallback('online'),
    },
    {
      text: `Idle ${tagStatistics?.idleTags || 0}`,
      indicator: idle,
      onClick: getHandleStatusFilterChangeCallback('idle'),
    },
    {
      text: 'Offline',
      text1: tagStatistics?.offlineTags?.toString(),
      indicator: offline,
      onClick: getHandleStatusFilterChangeCallback('offline'),
    },
    {
      text: 'Disabled',
      text1: tagStatistics?.disabledTags?.toString(),
      indicator: '',
      onClick: getHandleStatusFilterChangeCallback('disabled'),
    },
  ];

  const stats: Array<StatInterface> = [
    {
      title: 'Enabled',
      amount: enabledTags + ' of ' + tagStatistics?.totalTags + ' total',
      icon: arrowSmallUp,
      badge: 'secondary',
      value: 12,
    },
    {
      title: 'Average per event',
      amount: tagStatistics?.averageTagsPerEvent?.toString(),
      icon: arrowSmallUp,
      badge: 'secondary',
      value: 2.02,
    },
    {
      title: 'Required in next 30 days',
      amount: '76',
      icon: arrowSmallDown,
      badge: 'danger',
      value: 4.05,
    },
  ];

  const tagTableColumns = [
    columnHelper.accessor('name', {
      header: 'Tag',
    }),
    columnHelper.accessor('number', {
      header: 'Number',
    }),
    columnHelper.accessor('uniqueId', {
      header: 'Tag ID',
    }),
    columnHelper.accessor('tagStatus', {
      header: 'State',
      cell: (row) => (
        <Badge
          className={clsx(
            'capitalize',
            tagStatusToBadgeColorMap[row.getValue()],
          )}
        >
          {row.getValue()}
        </Badge>
      ),
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (row) => <span className="capitalize">{row.getValue()}</span>,
    }),
  ];

  const handleEditClick = (row: TagTableData) => {
    setTagToEdit(row);
    showInsertTagModal();
  };

  const handleAddTagClick = () => {
    setTagToEdit(null);
    showInsertTagModal();
  };

  const handleDeleteClick = (row: TagTableData) => {
    showDeleteTagModal();
    setTagToEdit(row);
  };

  const handleToggleEnabledClick = (row: TagTableData) => {
    showToggleEnabledTagModal();
    setTagToEdit(row);
  };

  const clearSelectedRows = () => setSelectedRows({});

  const toggleTagStatus = async () => {
    const status = isMassEnabling ? 'active' : 'disabled';

    try {
      const response = await client.put<
        Response<{ matchedCount: number; modifiedCount: number }>
      >(TOGGLE_TAG_STATUSES(), {
        ids: selectedIds,
        status,
      });

      const { matchedCount, modifiedCount } = response.data.data;

      notification.success(
        `${modifiedCount} of ${matchedCount} Tags successfully ${
          isMassEnabling ? 'enabled' : 'disabled'
        }`,
      );
    } catch (error) {
      notification.error(error);
    } finally {
      queryClient.invalidateQueries([tagBaseKey]);
      queryClient.invalidateQueries([tagStatisticsBaseKey]);
    }
  };

  const tagTableRowActions = (row: TagTableData): RowAction[] => [
    {
      icon: 'pencil',
      key: 'edit',
      label: 'Edit',
      onClick: () => handleEditClick(row),
    },
    {
      icon: 'power-off',
      key: 'toggle-enabled',
      label: row.status === 'disabled' ? 'Enable' : 'Disable',
      onClick: () => handleToggleEnabledClick(row),
    },
    {
      icon: 'trash',
      key: 'delete',
      label: 'Delete',
      color: 'danger',
      onClick: () => handleDeleteClick(row),
    },
  ];

  const tagTableSelectedRowsActions = (
    <>
      <TLNeutralButton leadingIcon="times" onClick={clearSelectedRows}>
        Clear selection
      </TLNeutralButton>
      <TLNeutralButton leadingIcon="arrow-down" onClick={() => {}}>
        Export
      </TLNeutralButton>
      <TLNeutralButton
        leadingIcon="power-off"
        onClick={toggleTagStatus}
        className="text-red-500"
      >
        {isMassEnabling ? 'Enable' : 'Disable'}
      </TLNeutralButton>
      <TLDangerButton
        leadingIcon="trash"
        onClick={showDeleteTagsModal}
        className="text-white"
      >
        Delete
      </TLDangerButton>
    </>
  );

  const tagTableActions = (
    <>
      <TLPrimaryButton leadingIcon="plus" onClick={handleAddTagClick}>
        New tag
      </TLPrimaryButton>
    </>
  );

  return (
    <div>
      <Header title="Tags" onSearch={(e) => setSearch(e)}></Header>
      <Stats stats={stats}></Stats>
      <TanStackTable
        getRowId={(row) => row._id}
        selectedRows={selectedRows}
        onRowSelectionChange={setSelectedRows}
        isLoading={isFetching}
        statusIndicatorClassName={(row) => {
          if (row.tagStatus === 'online') return 'bg-green-500';
          if (row.tagStatus === 'idle') return 'bg-yellow-500';
          return 'bg-gray-300';
        }}
        columns={tagTableColumns}
        pageIndex={page}
        pageSize={ROW_LIMIT}
        totalRows={_route.total}
        pageCount={Math.ceil(_route.total / ROW_LIMIT)}
        data={tags}
        rowActions={tagTableRowActions}
        onPageChange={handlePageChange}
        headerTitle={<ButtonGroupData buttons={buttons} />}
        rowClassName={(row) =>
          row.status === 'disabled' ? 'text-gray-400' : ''
        }
        tableActions={
          selectedIds.length > 0 ? tagTableSelectedRowsActions : tagTableActions
        }
        enableRowSelection
        showHeader
      />
    </div>
  );
};

export default Tags;
