import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventPayload } from 'pages/Events/MultiStepForm/event-schema';
import { EventService } from 'services/event';

import { eventBaseKey } from './baseKey';

export const useMutateEvent = (eventId?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ heroImage, ...payload }: EventPayload) => {
      let res = eventId
        ? await EventService.updateEvent({ eventId }, payload)
        : await EventService.createEvent(payload);

      if (res?.data.data?._id && heroImage instanceof File) {
        const formData = new FormData();

        formData.append('heroImage', heroImage);

        res = await EventService.setHeroImage(
          { eventId: res.data.data._id },
          formData,
        );
      }

      return res?.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([eventBaseKey]);
      queryClient.invalidateQueries([eventBaseKey, eventId]);
    },
  });
};
