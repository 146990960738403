import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TimingLocationDeviceService } from 'services/event/timingLocation/TimingLocationDeviceService';

import { eventTimingLocationGatings } from '../baseKey';

interface DeleteDevicesProps {
  eventId: string;
  layoutId: string;
  sectionId: string;
  ids: string[];
}

export const useDeleteDevices = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      eventId,
      layoutId,
      sectionId,
      ids,
    }: DeleteDevicesProps) =>
      await TimingLocationDeviceService.deleteTimingLocationDevices(
        eventId,
        layoutId,
        sectionId,
        ids,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([eventTimingLocationGatings]);
    },
  });
};
