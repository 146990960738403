import clsx from 'clsx';
import { TLNeutralButton, TLSecondaryButton } from 'components/Button';
import { ReactNode } from 'react';

import styles from './TLModalFooter.module.scss';

export type TLModalFooterProps = {
  cancelButton?: ReactNode;
  actionButton?: ReactNode;
  onClose?: () => void;
  onConfirm?: () => void;
  moreInfo?: string;
  className?: string;
  style?: React.CSSProperties;
};

export const TLModalFooter = ({
  actionButton,
  cancelButton,
  onClose,
  onConfirm,
  className,
  moreInfo,
  ...props
}: TLModalFooterProps) => (
  <div className={clsx(styles.footer, className)} {...props}>
    {!!moreInfo && <span>{moreInfo}</span>}
    <div className={styles.actionGroup}>
      {cancelButton ?? (
        <TLNeutralButton onClick={onClose}>Cancel</TLNeutralButton>
      )}
      {actionButton ?? (
        <TLSecondaryButton onClick={onConfirm}>Save</TLSecondaryButton>
      )}
    </div>
  </div>
);
