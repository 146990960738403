import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import {
  DELETE_EVENT_TIMING_LOCATION,
  DELETE_EVENT_TIMING_LOCATIONS,
} from 'utils/apiUrl';

interface DeleteParams {
  eventId: string;
  timingLocationId?: string;
  timingLocationIds?: string[];
  layoutId?: string;
}

export class EventTimingLocationDeleteService extends ResponseInterceptor {
  static async deleteEventTimingLocation({
    eventId,
    timingLocationId,
    layoutId,
  }: DeleteParams): Promise<APIAxiosResponse<string[]>> {
    const res = await client.delete(
      DELETE_EVENT_TIMING_LOCATION(eventId, timingLocationId!, layoutId!),
    );
    return this.handleResponse(res);
  }

  static async deleteEventTimingLocations({
    eventId,
    timingLocationIds,
    layoutId,
  }: DeleteParams): Promise<APIAxiosResponse<string[]>> {
    const response = await client.delete(
      DELETE_EVENT_TIMING_LOCATIONS(eventId),
      {
        data: { ids: timingLocationIds },
      },
    );
    return this.handleResponse(response);
  }
}
