import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/16/solid';
import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Text,
  TextInput,
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { SortingParamType } from 'components/SortingDropdown/types';
import { useFilters } from 'context/FilterProvider';
import { debounce } from 'lodash';
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from 'mantine-react-table';
import { hubTrackingObjectSortingParams } from 'pages/Entities/consts';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import {
  useAssignTagsForTrackingObjectByEvent,
  useTrackingObjectsByEvent,
  useUnassignTagsForTrackingObjectByEvent,
} from 'services/entity/trackingObject';
import { convertFirstLetterToUppercase, states, statuses } from 'utils/consts';

import SortingDropdown from '../../../../../components/SortingDropdown/SortingDropdown';
import AdditionalFilters from '../../LeftHubContainer/AdditionalFilters/AdditionalFilters';
import { mapTrackingObjectByEventToAssignTrackingObjectTableType } from '../../mapper';
import {
  AssignTrackingObjectTableType,
  TrackingObjectAssignModalProps,
} from '../../types';
import styles from '../TableModals.module.css';

const TrackingObjectAssignModal = ({
  eventId,
  isAssignModal,
  trackingObjects,
  dataId,
}: TrackingObjectAssignModalProps) => {
  const [sortingParam, setSortingParam] = useState<SortingParamType | null>(
    null,
  );
  const [showAdditionalFilters, setShowAdditionalFilters] = useState<boolean>(
    false,
  );
  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>(
    {},
  );
  console.log('eventId', eventId);
  console.log('tagId', dataId);

  const [isAtBottom, setIsAtBottom] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(20);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
  const scrollRef = useRef<HTMLDivElement>(null);
  const { height } = useViewportSize();
  const [filteredData, setFilteredData] = useState<
    AssignTrackingObjectTableType[] | []
  >([]);
  const { filter } = useFilters();
  const {
    data: { eventParticipants } = {},
    isLoading: isLoadingTrackingObject,
  } = useTrackingObjectsByEvent(
    eventId ?? '',
    limit,
    debouncedSearchTerm,
    filter,
    sortingParam ?? undefined,
  );
  const { mutate: assignTagMutate } = useAssignTagsForTrackingObjectByEvent();
  const {
    mutate: unassignTagMutate,
  } = useUnassignTagsForTrackingObjectByEvent();

  const selectedId = useMemo(() => {
    return Object.keys(rowSelection).map(
      (rowId) => filteredData[Number(rowId)]?._id,
    );
  }, [rowSelection, filteredData]);

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      if (value?.length >= 3) setDebouncedSearchTerm(value);
      else if (value?.length === 0) setDebouncedSearchTerm('');
    }, 500),
    [],
  );

  const handleSubmitModal = () => {
    if (dataId && eventId)
      (isAssignModal ? assignTagMutate : unassignTagMutate)({
        eventId,
        trackingObjectId: selectedId[0],
        tagIds: [dataId],
      });
  };

  useEffect(() => {
    if (eventParticipants && isAssignModal) {
      setFilteredData(
        mapTrackingObjectByEventToAssignTrackingObjectTableType(
          eventParticipants,
        ),
      );
    } else if (!isAssignModal) {
      if (trackingObjects && trackingObjects?.length > 0) {
        const assignedTrackingObject: AssignTrackingObjectTableType = {
          _id: trackingObjects[0]?._id ?? '',
          classification: trackingObjects[0]?.trackedSubjectType?.subType
            ? convertFirstLetterToUppercase(
                trackingObjects[0]?.trackedSubjectType?.subType,
              )
            : '',
          type: trackingObjects[0]?.trackedSubjectType?.type
            ? convertFirstLetterToUppercase(
                trackingObjects[0]?.trackedSubjectType?.type,
              )
            : '',
          name: trackingObjects[0]?.firstLastName ?? '',
          registrationID: trackingObjects[0]?.externalId ?? '',
          status: trackingObjects[0]?.status
            ? trackingObjects[0]?.status === 'active'
              ? 'Enabled'
              : 'Disabled'
            : '',
          tags: '',
        };
        setFilteredData([assignedTrackingObject, ...filteredData]);
      }
    }
  }, [eventParticipants]);

  useUpdateEffect(() => {
    if (isAtBottom) {
      setLimit((prev) => prev + 20);
    }
  }, [isAtBottom]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        const threshold = 100;
        const atBottom = scrollHeight - scrollTop - clientHeight <= threshold;

        if (setIsAtBottom) {
          setIsAtBottom(atBottom);
        }
      }
    };

    const viewport = scrollRef.current;
    if (viewport) {
      viewport.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (viewport) {
        viewport.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const columns = useMemo<MRT_ColumnDef<AssignTrackingObjectTableType>[]>(
    () => [
      {
        maxSize: 300,
        minSize: 220,
        header: 'Name',
        accessorKey: 'name',
        Cell: ({ cell }) => {
          return (
            <div className={`${styles.firstColumn}`}>
              {cell.getValue() as React.ReactNode}
            </div>
          );
        },
      },
      {
        maxSize: 220,
        minSize: 180,
        header: 'Type',
        accessorKey: 'type',
      },
      {
        maxSize: 200,
        minSize: 150,
        header: 'Classification',
        accessorKey: 'classification',
      },
      {
        maxSize: 220,
        minSize: 200,
        header: 'Registration ID',
        accessorKey: 'registrationID',
      },
      {
        maxSize: 220,
        minSize: 200,
        header: 'Tags',
        accessorKey: 'tags',
      },

      {
        maxSize: 220,
        minSize: 150,
        header: 'Status',
        accessorKey: 'status',
        Cell: ({ row }) => {
          const status = row.original.status;
          if (status.toLowerCase() === 'enabled') {
            return (
              <Badge
                color="#ECFDF5"
                radius={12}
                style={{
                  color: '#10B981',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                }}
              >
                Enabled
              </Badge>
            );
          } else if (status.toLowerCase() === 'disabled') {
            return (
              <Badge
                color="#F8FAFC"
                radius={12}
                style={{
                  color: '#64748B',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                }}
              >
                Disabled
              </Badge>
            );
          } else if (status.toLowerCase() === 'offline') {
            return (
              <Badge
                color="#F8FAFC"
                radius={12}
                style={{
                  color: '#64748B',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                }}
              >
                Offline
              </Badge>
            );
          } else if (status.toLowerCase() === 'online') {
            return (
              <Badge
                color="#ECFDF5"
                radius={12}
                style={{
                  color: '#10B981',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                }}
              >
                Online
              </Badge>
            );
          }
        },
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data: filteredData,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    enablePagination: true,
    manualPagination: true,
    rowCount: filteredData.length,

    state: {
      rowSelection,
      isLoading: isLoadingTrackingObject,
    },

    onRowSelectionChange: (newRowSelection) => {
      setRowSelection(newRowSelection);
    },

    mantineTableContainerProps: {
      ref: scrollRef,
      style: {
        maxHeight: height > 750 ? height / 1.8 : height / 2,
      },
    },

    mantineTableBodyRowProps: ({ row }) => {
      return {
        className: row.getIsSelected() ? styles.selectedRow : '',
      };
    },

    mantineSelectAllCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantineSelectCheckboxProps: {
      iconColor: 'var(--blue-500)',
      size: '1rem',
    },

    mantinePaperProps: {
      style: {
        boxShadow: 'none',
        borderRadius: '0.5rem',
        borderColor: '#E2E8F0',
      },
    },
  });

  return (
    <div className={styles.tableContainer}>
      {isAssignModal && (
        <Flex mr="0.5rem" mb="1.5rem" justify="center">
          <TextInput
            size="sm"
            mr="0.75rem"
            fz="1rem"
            radius="md"
            flex={1}
            placeholder="Search"
            onChange={(e) => debouncedSetSearchTerm(e.target.value)}
            leftSection={
              <MagnifyingGlassIcon
                height="1.25rem"
                width="1.25rem"
                color="#94A3B8"
              />
            }
          />
          <Flex align="center" gap="0.75rem">
            <SortingDropdown
              sortingParams={hubTrackingObjectSortingParams}
              sortingParam={sortingParam}
              setSortingParam={setSortingParam}
            />
            <ActionIcon
              bg="#ffffff"
              c={showAdditionalFilters ? '#3B82F6' : '#0F172A'}
              onClick={() => setShowAdditionalFilters((prev) => !prev)}
            >
              <AdjustmentsHorizontalIcon height="1.5rem" width="1.5rem" />
            </ActionIcon>
          </Flex>
        </Flex>
      )}

      {showAdditionalFilters && (
        <AdditionalFilters
          statuses={statuses.map(({ value, label }) => ({
            id: value,
            value: label,
          }))}
          states={states.map(({ value, label }) => ({
            id: value,
            value: label,
          }))}
          showTypeFilter={true}
          showClassificationFilter={true}
        />
      )}

      <MantineReactTable table={table} />

      <Flex mt="2rem" justify="space-between" align="center">
        <Text></Text>

        <Flex justify="flex-end" gap="1rem">
          <Button
            bg="#ffffff"
            bd="1px solid #e2e8f0"
            c="#344155"
            onClick={modals.closeAll}
          >
            Cancel
          </Button>
          <Button
            bg="#3b82f6"
            c="#ffffff"
            onClick={() => {
              handleSubmitModal();
              modals.closeAll();
            }}
          >
            {isAssignModal ? 'Assign' : 'Unassign'}
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};

export default TrackingObjectAssignModal;
