import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import { EventLiveData } from 'types/event/eventLiveData';
import { GET_EVENT_LIVE_DATA } from 'utils/apiUrl';

export class EventLiveService extends ResponseInterceptor {
  static async getEventLiveData(
    eventId: string,
    params: Record<string, unknown>,
  ): Promise<APIAxiosResponse<EventLiveData[]>> {
    const res = await client.get(GET_EVENT_LIVE_DATA(eventId), { params });

    return this.handleResponse(res);
  }
}
