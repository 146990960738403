import {
  TagIcon,
  HomeIcon,
  ViewfinderCircleIcon,
  TrashIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import {
  ActionIcon,
  Box,
  Flex,
  Group,
  Image,
  Stack,
  Text,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { AdmissionCardProps } from '../types';

const AdmissionCard = ({
  id,
  isCollapsedCard,
  isTagCard,
  title,
  subTitle,
  leftContent,
  rightContent,
  trackingObjectImage,
  isWarningState,
  handleDelete,
}: AdmissionCardProps) => {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  return (
    <>
      {!isCollapsedCard && (
        <Stack
          h={isMobile ? '10.5rem' : '15.594rem'}
          style={{
            border: `1px solid var(--${
              isWarningState ? 'red-400' : 'gray-200'
            })`,
            borderRadius: '0.5rem',
            position: 'relative',
          }}
        >
          <Box
            style={{
              position: 'absolute',
              top: '0.75rem',
              right: '0.75rem',
              zIndex: 1,
            }}
          >
            <ActionIcon bg="#fff" onClick={() => handleDelete(isTagCard, id)}>
              <TrashIcon
                height={isMobile ? '0.75rem' : '1.25rem'}
                width={isMobile ? '0.75rem' : '1.25rem'}
                color="var(--gray-400)"
              />
            </ActionIcon>
          </Box>
          <Box
            style={{
              marginTop: '1.5rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {trackingObjectImage ? (
              <Image
                src={trackingObjectImage}
                alt="Image"
                width={isMobile ? '3.5rem' : '6.25rem'}
                height={isMobile ? '3.5rem' : '6.25rem'}
                radius="md"
              />
            ) : (
              <Box
                w={isMobile ? '3.5rem' : '6.25rem'}
                h={isMobile ? '3.5rem' : '6.25rem'}
                style={{
                  backgroundColor: 'var(--gray-100)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '0.5rem',
                }}
              >
                {isTagCard ? (
                  <TagIcon
                    height={isMobile ? '1.75rem' : '3.125rem'}
                    width={isMobile ? '1.75rem' : '3.125rem'}
                    color="var(--gray-300)"
                  />
                ) : (
                  <ViewfinderCircleIcon
                    height={isMobile ? '1.75rem' : '3.125rem'}
                    width={isMobile ? '1.75rem' : '3.125rem'}
                    color="var(--gray-300)"
                  />
                )}
              </Box>
            )}
          </Box>
          <Box style={{ textAlign: 'center' }}>
            <Text fz={isMobile ? '0.75rem' : '1.125rem'} c="var(--gray-900)">
              {title.length > 30 ? title.slice(0, 30) + '...' : title}
            </Text>
            <Text fz={isMobile ? '0.75rem' : '1.125rem'} c="var(--gray-500)">
              {subTitle.length > 28 ? subTitle.slice(0, 28) + '...' : subTitle}
            </Text>
          </Box>
          <Box style={{ position: 'relative', height: '100%' }}>
            <Box
              style={{
                position: 'absolute',
                bottom: '0.75rem',
                left: '0.75rem',
              }}
            >
              <Group gap={8}>
                {isTagCard ? (
                  <HomeIcon
                    height={isMobile ? '0.75rem' : '1.25rem'}
                    width={isMobile ? '0.75rem' : '1.25rem'}
                    color="var(--gray-400)"
                  />
                ) : (
                  <UserIcon
                    height={isMobile ? '0.75rem' : '1.25rem'}
                    width={isMobile ? '0.75rem' : '1.25rem'}
                    color="var(--gray-400)"
                  />
                )}
                <Text fz={isMobile ? '0.5rem' : '0.875rem'}>{leftContent}</Text>
              </Group>
            </Box>
            <Box
              style={{
                position: 'absolute',
                bottom: '0.75rem',
                right: '1rem',
              }}
            >
              <Group gap={8}>
                {isTagCard ? (
                  <ViewfinderCircleIcon
                    height={isMobile ? '0.75rem' : '1.25rem'}
                    width={isMobile ? '0.75rem' : '1.25rem'}
                    color={`var(--${isWarningState ? 'red-400' : 'gray-400'})`}
                  />
                ) : (
                  <TagIcon
                    height={isMobile ? '0.75rem' : '1.25rem'}
                    width={isMobile ? '0.75rem' : '1.25rem'}
                    color={`var(--${isWarningState ? 'red-400' : 'gray-400'})`}
                  />
                )}
                <Text
                  fz={isMobile ? '0.5rem' : '0.875rem'}
                  c={`var(--${isWarningState ? 'red-400' : 'gray-400'})`}
                >
                  {rightContent}
                </Text>
              </Group>
            </Box>
          </Box>
        </Stack>
      )}
      {isCollapsedCard && (
        <Flex
          h={isMobile ? '3.5rem' : '6.25rem'}
          style={{
            border: `1px solid var(--${
              isWarningState ? 'red-400' : 'gray-200'
            })`,
            borderRadius: '0.5rem',
            position: 'relative',
            flexDirection: 'row',
            alignItems: 'center',
            padding: isMobile ? '0.5rem' : '0.75rem',
            gap: '0.75rem',
          }}
        >
          <Flex
            style={{
              width: isMobile ? '2.5rem' : '3.75rem',
              height: isMobile ? '2.5rem' : '3.75rem',
              backgroundColor: 'var(--gray-100)',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              border: '1px solid var(--gray-200)',
              flexShrink: 0,
            }}
          >
            {trackingObjectImage ? (
              <Image
                src={trackingObjectImage}
                alt="Image"
                width={isMobile ? '2.5rem' : '3.75rem'}
                height={isMobile ? '2.5rem' : '3.75rem'}
                radius="md"
              />
            ) : isTagCard ? (
              <TagIcon
                height={isMobile ? '0.75rem' : '1.5rem'}
                width={isMobile ? '0.75rem' : '1.5rem'}
                color="var(--gray-300)"
              />
            ) : (
              <ViewfinderCircleIcon
                height={isMobile ? '0.75rem' : '1.5rem'}
                width={isMobile ? '0.75rem' : '1.5rem'}
                color="var(--gray-300)"
              />
            )}
          </Flex>
          <Flex
            style={{
              flexDirection: 'column',
              flexGrow: 1,
              gap: isMobile ? '0.2rem' : '0.375rem',
            }}
          >
            <Text
              fz={isMobile ? '0.5rem' : '0.875rem'}
              c="var(--gray-900)"
              fw={500}
              truncate="end"
            >
              {title}
            </Text>
            <Text
              fz={isMobile ? '0.5rem' : '0.875rem'}
              c="var(--gray-500)"
              fw={400}
              truncate="end"
            >
              {subTitle}
            </Text>
            {leftContent && (
              <Flex style={{ alignItems: 'center' }}>
                {isTagCard ? (
                  <HomeIcon
                    height={isMobile ? '0.75rem' : '1.25rem'}
                    width={isMobile ? '0.75rem' : '1.25rem'}
                    color={`var(--${isWarningState ? 'red-400' : 'gray-400'})`}
                  />
                ) : (
                  <UserIcon
                    height={isMobile ? '0.75rem' : '1.25rem'}
                    width={isMobile ? '0.75rem' : '1.25rem'}
                    color={`var(--${isWarningState ? 'red-400' : 'gray-400'})`}
                  />
                )}
                <Text
                  fz={isMobile ? '0.5rem' : '0.875rem'}
                  ml="0.25rem"
                  c={`var(--${isWarningState ? 'red-400' : 'gray-500'})`}
                >
                  {leftContent}
                </Text>
              </Flex>
            )}
          </Flex>

          <Flex
            style={{
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
              height: '100%',
              marginLeft: 'auto',
            }}
          >
            <ActionIcon bg="#fff" onClick={() => handleDelete(isTagCard, id)}>
              <TrashIcon
                height={isMobile ? '0.75rem' : '1.25rem'}
                width={isMobile ? '0.75rem' : '1.25rem'}
                color="var(--gray-400)"
              />
            </ActionIcon>

            <Flex style={{ alignItems: 'center' }}>
              {isTagCard ? (
                <ViewfinderCircleIcon
                  height={isMobile ? '0.75rem' : '1.25rem'}
                  width={isMobile ? '0.75rem' : '1.25rem'}
                  color={`var(--${isWarningState ? 'red-400' : 'gray-400'})`}
                />
              ) : (
                <TagIcon
                  height={isMobile ? '0.75rem' : '1.25rem'}
                  width={isMobile ? '0.75rem' : '1.25rem'}
                  color={`var(--${isWarningState ? 'red-400' : 'gray-400'})`}
                />
              )}
              <Text
                fz={isMobile ? '0.5rem' : '0.875rem'}
                ml="0.25rem"
                c={`var(--${isWarningState ? 'red-400' : 'gray-500'})`}
              >
                {rightContent}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default AdmissionCard;
