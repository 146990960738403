import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventAssignableDevicesService } from 'services/event/device/EventAssignableDevicesService';

import { eventDeviceAssignableKey, eventDeviceBaseKey } from './baseKeys';

interface AddEventAssignableDevicesProps {
  payload: string[];
  filter: string;
  eventId?: string;
}

export const useAddEventAssignableDevices = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      eventId,
      payload,
    }: AddEventAssignableDevicesProps) => {
      if (!eventId) return;
      await EventAssignableDevicesService.addAssignableDevices(
        eventId,
        payload,
      );
    },
    onSuccess: (_, { eventId, filter }) => {
      queryClient.invalidateQueries([eventDeviceBaseKey]);
      queryClient.invalidateQueries([eventDeviceAssignableKey]);
    },
  });
};
