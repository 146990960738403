import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import { UpdateTagStatusPayload } from 'types/tag';
import { DELETE_TAG, DELETE_TAGS, UPDATE_TAG } from 'utils/apiUrl';

import { TagResponse } from './types';

export class TagsService extends ResponseInterceptor {
  static async updateTagStatus(
    id: string,
    payload: UpdateTagStatusPayload,
  ): Promise<APIAxiosResponse<TagResponse>> {
    const response = await client.put(UPDATE_TAG(id), payload);
    return this.handleResponse(response);
  }

  static async deleteTag(id: string): Promise<APIAxiosResponse<string>> {
    const response = await client.delete(DELETE_TAG(id));
    return this.handleResponse(response);
  }

  static async deleteTags(ids: string[]): Promise<APIAxiosResponse<string[]>> {
    const response = await client.delete(DELETE_TAGS(), {
      data: { ids },
    });
    return this.handleResponse(response);
  }
}
