import { InputGroup } from 'components/Input';
import { useZodForm } from 'hooks/useZodForm';
import { useFormContext } from 'react-hook-form';

import { useMultiStep } from '../MultiStepForm/context/multi-step-context';
import { MultiStepFormContent } from '../MultiStepForm/MultiStepFormContent/MultiStepFormContent';
import { MultiStepFormContentFooter } from '../MultiStepForm/MultiStepFormContent/MultiStepFormContentFooter/MultiStepFormContentFooter';
import {
  CreateTimingLocationSchema,
  organisationSocialMediaSchema,
  OrganisationSocialMediaSchema,
} from '../MultiStepForm/timingLocation-schema';

type OrganisationSocialMediaFormProps = {
  defaultValues: OrganisationSocialMediaSchema;
  step: number;
};

const OrganisationSocialMediaForm = ({
  step,
  defaultValues,
}: OrganisationSocialMediaFormProps) => {
  const { next } = useMultiStep();

  const {
    setValue: setContextValue,
  } = useFormContext<CreateTimingLocationSchema>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useZodForm<OrganisationSocialMediaSchema>(
    {
      defaultValues,
    },
    organisationSocialMediaSchema,
  );

  const onSubmit = (data: OrganisationSocialMediaSchema) => {
    reset(data);
    setContextValue('organisationSocialMedia', data);

    next();
  };

  return (
    <MultiStepFormContent step={step}>
      <InputGroup
        register={register}
        label="Facebook URL"
        placeholder="Paste Facebook link here"
        name="facebook"
        errors={errors}
      />

      <InputGroup
        register={register}
        label="Instagram URL"
        placeholder="Paste Instagram link here"
        name="instagram"
        errors={errors}
      />

      <InputGroup
        register={register}
        label="LinkedIn URL"
        placeholder="Paste LinkedIn link here"
        name="linkedin"
        errors={errors}
      />

      <InputGroup
        register={register}
        label="TikTok URL"
        placeholder="Paste TikTok link here"
        name="tikTok"
        errors={errors}
      />

      <InputGroup
        register={register}
        label="Twitter URL"
        placeholder="Paste Twitter link here"
        name="twitter"
        errors={errors}
      />

      <InputGroup
        register={register}
        label="YouTube URL"
        placeholder="Paste YouTube link here"
        name="youtube"
        errors={errors}
      />

      <MultiStepFormContentFooter
        isValid={isValid}
        onConfirm={handleSubmit(onSubmit)}
      />
    </MultiStepFormContent>
  );
};

export default OrganisationSocialMediaForm;
