import { ClockIcon } from '@heroicons/react/24/outline';
import { ActionIcon, Grid, Stack } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import dayjs from 'dayjs';
import { identity, pickBy } from 'lodash';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useEffect, useRef } from 'react';
import { useEntity, useTimezoneByEvent } from 'services/entity/home';
import {
  convertDateAndTime,
  convertDateAndTimeToUtc,
} from 'utils/createDateAndTime';

import {
  CreateAndUpdateEntityReq,
  CreateAndUpdateEntitySecondStep,
} from '../types';

const EntityScanningContent = ({
  formState,
  setFormState,
  setIsFormValid,
  dataId,
}: ModalFormProps) => {
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);
  const { data: eventData } = useEntity(dataId ?? '');
  const { data: timezoneData } = useTimezoneByEvent(dataId ?? '');
  const form = useForm<CreateAndUpdateEntitySecondStep>({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      eventStartDate: formState?.startDate
        ? dayjs(
            convertDateAndTime(formState.startDate, formState?.timezone),
          ).format('YYYY-MM-DD')
        : dayjs(
            convertDateAndTime(formState.eventStartDate, formState?.timezone),
          ).format('YYYY-MM-DD'),
      eventStartTime: formState?.startDate
        ? dayjs(
            convertDateAndTime(formState.startDate, formState?.timezone),
          ).format('HH:mm')
        : dayjs(
            convertDateAndTime(formState.eventStartDate, formState?.timezone),
          ).format('HH:mm'),
      eventEndDate: formState?.finishDate
        ? dayjs(
            convertDateAndTime(formState.finishDate, formState?.timezone),
          ).format('YYYY-MM-DD')
        : dayjs(
            convertDateAndTime(formState.eventFinishDate, formState?.timezone),
          ).format('YYYY-MM-DD'),
      eventEndTime: formState?.finishDate
        ? dayjs(
            convertDateAndTime(formState.finishDate, formState?.timezone),
          ).format('HH:mm')
        : dayjs(
            convertDateAndTime(formState.eventFinishDate, formState?.timezone),
          ).format('HH:mm'),
    },
    validate: (values) => {
      const {
        eventStartDate,
        eventStartTime,
        eventEndDate,
        eventEndTime,
      } = values;
      const anyFilled = [
        eventStartDate,
        eventStartTime,
        eventEndDate,
        eventEndTime,
      ].some(Boolean);

      return {
        eventStartDate:
          anyFilled && !eventStartDate ? 'Start date is required' : null,
        eventStartTime:
          anyFilled && !eventStartTime ? 'Start time is required' : null,
        eventEndDate:
          anyFilled && !eventEndDate ? 'End date is required' : null,
        eventEndTime:
          anyFilled && !eventEndTime ? 'End time is required' : null,
      };
    },
  });

  useEffect(() => {
    if (eventData && timezoneData) {
      form.setFieldValue(
        'eventStartDate',
        dayjs(convertDateAndTime(eventData.startDate, timezoneData)).format(
          'YYYY-MM-DD',
        ),
      );
      form.setFieldValue(
        'eventStartTime',
        dayjs(convertDateAndTime(eventData.startDate, timezoneData)).format(
          'HH:mm',
        ),
      );
      form.setFieldValue(
        'eventEndDate',
        dayjs(convertDateAndTime(eventData.finishDate, timezoneData)).format(
          'YYYY-MM-DD',
        ),
      );
      form.setFieldValue(
        'eventEndTime',
        dayjs(convertDateAndTime(eventData.finishDate, timezoneData)).format(
          'HH:mm',
        ),
      );
    }
  }, [eventData, timezoneData]);

  useEffect(() => {
    if (form.isValid()) {
      setIsFormValid(true);
      const newFormState = form.values;
      const timezone = formState?.timezone;
      const secondStep: Partial<CreateAndUpdateEntityReq> = {
        startDate:
          newFormState.eventStartDate && newFormState.eventStartTime && timezone
            ? convertDateAndTimeToUtc(
                newFormState.eventStartDate + 'T' + newFormState.eventStartTime,
                timezone,
              )
            : formState?.eventStartDate,
        finishDate:
          newFormState.eventEndDate && newFormState.eventEndTime && timezone
            ? convertDateAndTimeToUtc(
                newFormState.eventEndDate + 'T' + newFormState.eventEndTime,
                timezone,
              )
            : formState?.eventFinishDate,
      };
      if (setFormState) {
        const cleandedData = pickBy(secondStep, identity);
        setFormState((prevState) => ({
          ...prevState,
          ...cleandedData,
        }));
      }
    } else {
      setIsFormValid(false);
    }
  }, [form.values, setFormState]);

  const startTimePickerControl = (
    <ActionIcon
      variant="subtle"
      color="#0F172A"
      onClick={() => startTimeRef.current?.showPicker()}
    >
      <ClockIcon width={20} height={20} />
    </ActionIcon>
  );

  const endTimePickerControl = (
    <ActionIcon
      variant="subtle"
      color="#0F172A"
      onClick={() => endTimeRef.current?.showPicker()}
    >
      <ClockIcon width={20} height={20} />
    </ActionIcon>
  );
  return (
    <Grid>
      <Grid.Col span={6}>
        <Stack>
          <DatePickerInput
            label="Start date"
            value={form.values.eventStartDate}
            onChange={(date) => form.setFieldValue('eventStartDate', date)}
          />

          <DatePickerInput
            label="End date"
            value={form.values.eventEndDate}
            onChange={(date) => form.setFieldValue('eventEndDate', date)}
          />
        </Stack>
      </Grid.Col>
      <Grid.Col span={6}>
        <Stack>
          <TimeInput
            label="Start time"
            key={form.key('eventStartTime')}
            ref={startTimeRef}
            rightSection={startTimePickerControl}
            {...form.getInputProps('eventStartTime')}
          />

          <TimeInput
            label="End time"
            key={form.key('eventEndTime')}
            ref={endTimeRef}
            rightSection={endTimePickerControl}
            {...form.getInputProps('eventEndTime')}
          />
        </Stack>
      </Grid.Col>
    </Grid>
  );
};

export default EntityScanningContent;
