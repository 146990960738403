import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventTimingLocationDeleteService } from 'services/event/timingLocation/TimingLocationDeleteService';

import { eventTimingLocationBaseKey } from './eventTimingLocationBaseKey';

interface DeleteEventTimingLocationProps {
  eventId: string;
  timingLocationId?: string;
  timingLocationIds?: string[];
  layoutId: string;
}

export const useMutateDeleteEventTimingLocation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      eventId,
      timingLocationId,
      timingLocationIds,
      layoutId,
    }: DeleteEventTimingLocationProps) => {
      if (!eventId) return;
      const resp =
        timingLocationId === undefined
          ? await EventTimingLocationDeleteService.deleteEventTimingLocations({
              eventId,
              timingLocationIds,
              layoutId,
            })
          : await EventTimingLocationDeleteService.deleteEventTimingLocation({
              eventId,
              timingLocationId,
              layoutId,
            });

      return resp?.data;
    },
    onSuccess: (_, { eventId, timingLocationId, layoutId }) => {
      queryClient.invalidateQueries([eventTimingLocationBaseKey]);
    },
  });

  return mutation;
};
