import { format } from 'date-fns';
import { useFetchEventLiveData } from 'hooks/event/eventLive/useFetchEventLiveData';
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MantineReactTable,
  createMRTColumnHelper,
  useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { EventLiveData } from 'types/event/eventLiveData';

import styles from './EventLive.module.scss';

const columnHelper = createMRTColumnHelper<EventLiveData>();

const columns: MRT_ColumnDef<EventLiveData, any>[] = [
  columnHelper.accessor('name', {
    id: 'section',
    header: 'Section',
  }),
  columnHelper.accessor('tagNumber', {
    header: 'Tag',
  }),
  columnHelper.accessor('uniqueId', {
    header: 'Tag MAC',
  }),
  columnHelper.accessor('macName', {
    header: 'Device',
  }),
  columnHelper.accessor('macAddress', {
    header: 'Device MAC',
  }),
  columnHelper.accessor('firstLastName', {
    header: 'Participant',
  }),
  columnHelper.accessor('tagDateTime', {
    header: 'Time',
    // check if cell.getValue is valid date, then format it
    Cell: ({ cell }) => {
      const date = new Date(cell.getValue());
      return date.toString() === 'Invalid Date'
        ? cell.getValue()
        : format(date, 'yyyy-MM-dd HH:mm:ss');
    },
  }),
];

export const EventLive = () => {
  const { id } = useParams();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const {
    data: eventLiveDataResponse,
    isLoading,
    isFetching,
  } = useFetchEventLiveData(id || '', {
    pagination,
    columnFilters,
  });

  const data = useMemo(() => eventLiveDataResponse?.data || [], [
    eventLiveDataResponse,
  ]);
  const total = eventLiveDataResponse?._route?.total || 0;

  const table = useMantineReactTable({
    data,
    columns,
    enableSorting: false,
    manualPagination: true,
    manualFiltering: true,
    rowCount: total,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    enableColumnActions: false,
    initialState: {
      showColumnFilters: true,
    },
    state: {
      columnFilters,
      pagination,
      isLoading,
      showProgressBars: isFetching,
    },
  });

  return (
    <div className={styles.tableContainer}>
      <MantineReactTable table={table} />
    </div>
  );
};
