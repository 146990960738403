import { TagIcon, ViewfinderCircleIcon } from '@heroicons/react/24/solid';
import { Flex, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { PlaceHolderAdmissionCardProps } from '../types';

const PlaceHolderAdmissionCard = ({
  isCollapsed,
  isTagCard,
}: PlaceHolderAdmissionCardProps) => {
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const iconSize = isMobile ? '2.5rem' : '3.125rem';
  const textSize = isMobile ? 10 : 12;
  const containerHeight = isCollapsed
    ? isMobile
      ? '4.5rem'
      : '6.25rem'
    : isMobile
    ? '10.5rem'
    : '15.594rem';

  return (
    <>
      {!isTagCard && (
        <Stack
          align="center"
          justify="center"
          h={containerHeight}
          ta="center"
          bg="#F8FAFC"
          style={{
            border: '2px dashed var(--gray-300)',
            borderRadius: '0.75rem',
          }}
        >
          {isCollapsed ? (
            <Flex align="center" gap={isMobile ? 4 : 6}>
              <ViewfinderCircleIcon
                height={iconSize}
                width={iconSize}
                color="var(--gray-300)"
                strokeWidth={0.8}
              />
              <Text inline fz={textSize} c="var(--gray-400)">
                Tracking object
              </Text>
            </Flex>
          ) : (
            <>
              <ViewfinderCircleIcon
                height={iconSize}
                width={iconSize}
                color="var(--gray-300)"
                strokeWidth={0.8}
              />
              <Stack align="center" gap={1}>
                <Text inline fz={textSize} c="var(--gray-400)">
                  Tracking object
                </Text>
              </Stack>
            </>
          )}
        </Stack>
      )}
      {isTagCard && (
        <Stack
          align="center"
          justify="center"
          h={containerHeight}
          ta="center"
          bg="#F8FAFC"
          style={{
            border: '2px dashed var(--gray-300)',
            borderRadius: '0.75rem',
          }}
        >
          {isCollapsed ? (
            <Flex align="center" gap={isMobile ? 4 : 6}>
              <TagIcon
                height={iconSize}
                width={iconSize}
                color="var(--gray-300)"
                strokeWidth={0.8}
              />
              <Text inline fz={textSize} c="var(--gray-400)">
                Tag
              </Text>
            </Flex>
          ) : (
            <>
              <TagIcon
                height={iconSize}
                width={iconSize}
                color="var(--gray-300)"
                strokeWidth={0.8}
              />
              <Stack align="center" gap={1}>
                <Text inline fz={textSize} c="var(--gray-400)">
                  Tag
                </Text>
              </Stack>
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export default PlaceHolderAdmissionCard;
