import { SetModalContentParams } from 'pages/Entities/ModalManager/types';

import AddingControlAccessModal from './AddingControlAccessModal';
import AddingEntityLevelModal from './AddingEntityLevelModal';
import AddingLayoutLevelModal from './AddingLayoutLevelModal';
import AddingSectionLevelModal from './AddingSectionLevelModal';
import AddingTrackingObjectModal from './AddingTrackingObjectModal';

export const setControlAccessModalContent = ({
  type,
  step,
  formState,
  setFormState,
  isFormValid,
  setIsFormValid,
}: SetModalContentParams) => {
  switch (type) {
    case 'addingControlAccess':
      return (
        <AddingControlAccessModal
          formState={formState}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
        />
      );
    case 'addingTrackingObject':
      return (
        <AddingTrackingObjectModal
          formState={formState}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
        />
      );
    case 'addingEntityLevel':
      return (
        <AddingEntityLevelModal
          formState={formState}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
        />
      );
    case 'addingLayoutLevel':
      return (
        <AddingLayoutLevelModal
          formState={formState}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
        />
      );
    case 'addingSectionLevel':
      return (
        <AddingSectionLevelModal
          formState={formState}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
        />
      );
  }
};
