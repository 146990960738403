import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { client } from 'services/api';
import { GET_SINGLE_EVENT_STATISTICS } from 'utils/apiUrl';

import { singleEventStatisticsBaseKey } from './baseKeys';
import { statisticsRefetchInterval } from './statisticsRefetchInterval';

interface SingleEventStatisticsProps {
  eventId: string;
}

interface SingleEventStatistics {
  eventLayouts: {
    total: number;
    active: number;
    disabled: number;
  };
  totalTimingLocations: number;
  devices: {
    total: number;
    idle: number;
    online: number;
    offline: number;
  };
  tags: {
    total: number;
    idle: number;
    online: number;
    offline: number;
  };

  eventLayoutSectionStatuses: {
    active: number;
    disabled: number;
    total: number;
    online: number;
    idle: number;
    offline: number;
  };

  participants: {
    age: {
      unknown: number;
      //age0_17: number;
    };
    gender: {
      other: number;
      female: number;
      male: number;
    };
  };
}

export const useFetchSingleEventStatistics = ({
  eventId,
}: SingleEventStatisticsProps) => {
  const queryResult = useQuery<SingleEventStatistics, AxiosError>({
    queryKey: [singleEventStatisticsBaseKey, eventId],
    refetchInterval: statisticsRefetchInterval,
    queryFn: async () => {
      const retVal = await client.get<{ data: SingleEventStatistics }>(
        GET_SINGLE_EVENT_STATISTICS(eventId),
      );

      return retVal.data.data;
    },
  });

  return queryResult;
};
