import { notification } from '@lanaco/lnc-react-ui';
import { RowSelectionState } from '@tanstack/react-table';
import PromptModal from 'components/PromptModal/PromptModal';
import { useMutateDeleteEventTag } from 'hooks/event/tags';
import { getErrorMsg } from 'utils/getErrorMsg';

interface RemoveEventTagModalProps {
  id: string;
  name?: string;
  eventName?: string;
  eventId: string;
  tagIds?: string[];
  closeModal: () => void;
  setSelectedRows: (selectedRows: RowSelectionState) => void;
}

export const RemoveEventTagModal = ({
  id,
  eventId,
  name,
  eventName,
  tagIds,
  closeModal,
  setSelectedRows,
}: RemoveEventTagModalProps) => {
  const { mutate, isLoading } = useMutateDeleteEventTag();

  const hideDeleteModal = () => {
    closeModal();
  };
  console.log(id);
  const confirmRemoveTag = async () => {
    console.log(eventId, id, tagIds);
    mutate(
      {
        eventId,
        tagId: id,
        tagIds: tagIds,
      },
      {
        onSuccess: (response) => {
          // console.log(result);
          if (response?.code === 201) {
            console.log(id);
            if (id === undefined) {
              notification.success('Tags successfully removed.');
            } else {
              notification.success('Tag successfully removed.');
            }
          } else {
            console.log(response);
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          hideDeleteModal();
          setSelectedRows({});
        },
      },
    );
  };

  return (
    <PromptModal
      title={id === undefined ? 'Remove tags?' : 'Remove tag?'}
      isLoading={isLoading}
      warningMessage={
        id === undefined ? (
          <>
            Are you sure you want to delete {tagIds?.length}
            <br />
            tags from the database?
          </>
        ) : (
          <>
            You will remove {name} from {eventName}.
          </>
        )
      }
      confirmButtonName="Remove"
      icon="trash"
      iconSize="24"
      isDeleteButton
      onConfirm={confirmRemoveTag}
      onCancel={hideDeleteModal}
    />
  );
};
