import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Box, Divider, Flex, Grid, ScrollArea, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import ZoomControl from 'components/Canvas/CanvasToolbox/ZoomControl';
import { MapControlType } from 'components/Canvas/types';
import dayjs from 'dayjs';
import { mapTagEventStatusToAssignedTagTrackingObject } from 'pages/Entities/Hub/mapper';
import { AssignedCardProps } from 'pages/Entities/Hub/types';
import { useEffect, useRef, useState } from 'react';
import { useTimezoneByEvent } from 'services/entity/home';
import { useTrackingObjectByEvent } from 'services/entity/trackingObject';
import { convertFirstLetterToUppercase } from 'utils/consts';
import { calculateTimeElapsed } from 'utils/dateFormatters';

import MapPicture from '../../../../../../assets/images/MapPicture.png';
import MapControl from '../../../../../../components/Canvas/CanvasToolbox/MapControl';
import InteractiveCanvas from '../../../../../../components/Canvas/InteractiveCanvas/InteractiveCanvas';
import AssignedCard from '../../Components/AssignedCard';

import classNames from './TrackingObjectDetailsModal.module.scss';

const TrackingObjectDetailsModal = ({ dataId = '', eventId = '' }) => {
  const [mapControl, setMapControl] = useState<MapControlType>({
    sectionsPins: false,
    sectionsArea: false,
    devices: false,
  });
  const { data: { eventParticipants } = {} } = useTrackingObjectByEvent(
    eventId,
    dataId,
  );
  const { data: timezoneData } = useTimezoneByEvent(eventId);
  const [scale, setScale] = useState<number>(1);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const [assignedTags, setAssignedTags] = useState<AssignedCardProps[] | []>(
    [],
  );
  const { height } = useViewportSize();

  useEffect(() => {
    if (eventParticipants) {
      if (
        eventParticipants[0] &&
        eventParticipants[0]?.tagEventStatus.length > 0 &&
        timezoneData
      ) {
        setAssignedTags(
          eventParticipants[0]?.tagEventStatus?.map((x) =>
            mapTagEventStatusToAssignedTagTrackingObject(
              x,
              eventParticipants[0].tagId,
              timezoneData,
            ),
          ),
        );
      } else {
        setAssignedTags([]);
      }
    }
  }, [eventParticipants, timezoneData]);

  return (
    <Grid pr="1rem">
      {eventParticipants && (
        <>
          <Grid.Col p={0} span={5}>
            <ScrollArea
              h={height - 10.75 * 24}
              p="0.5rem"
              pl="1rem"
              type="always"
              scrollbarSize={7}
              offsetScrollbars
              scrollbars="y"
              classNames={{
                scrollbar: classNames.scrollbar,
                thumb: classNames.thumb,
              }}
            >
              <Grid>
                <Grid.Col span={6}>
                  <Flex direction="column" gap="1rem">
                    <Box>
                      {eventParticipants[0].trackedSubjectType.type ===
                      'person' ? (
                        <>
                          <Text fz="0.875rem" fw={500} c="#0f172a">
                            First name
                          </Text>
                          <Text fz="0.875rem" fw={400} c="#64748b">
                            {eventParticipants[0].firstName ?? '-'}
                          </Text>
                        </>
                      ) : (
                        <>
                          <Text fz="0.875rem" fw={500} c="#0f172a">
                            Name
                          </Text>
                          <Text fz="0.875rem" fw={400} c="#64748b">
                            {eventParticipants[0].firstLastName ?? '-'}
                          </Text>
                        </>
                      )}
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Type
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {eventParticipants[0].trackedSubjectType.type
                          ? convertFirstLetterToUppercase(
                              eventParticipants[0].trackedSubjectType.type,
                            )
                          : '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Date of Birth
                      </Text>
                      {eventParticipants[0].birthday ? (
                        <Text fz="0.875rem" fw={400} c="#64748b">
                          {dayjs(eventParticipants[0].birthday).format(
                            'YYYY-MM-DD',
                          )}
                        </Text>
                      ) : (
                        '-'
                      )}
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Email
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {eventParticipants[0].email ?? '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Country
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {eventParticipants[0].country ||
                        eventParticipants[0].country?.length > 0
                          ? eventParticipants[0].country
                          : '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Organisation
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {eventParticipants[0].organisationId ?? '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Registration ID
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {eventParticipants[0]?.externalId?.length > 0
                          ? eventParticipants[0]?.externalId
                          : '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Control Access
                      </Text>
                      {eventParticipants[0]?.eventAccess?.map((x, index) => (
                        <Text
                          key={`${index}-${x.name}`}
                          fz="0.875rem"
                          fw={400}
                          c="#64748b"
                        >
                          {x.name}
                        </Text>
                      ))}
                    </Box>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Flex direction="column" gap="1rem">
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Last name
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {eventParticipants[0]?.lastName ?? '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Classification
                      </Text>
                      {eventParticipants[0].trackedSubjectType.subType ? (
                        <Text fz="0.875rem" fw={400} c="#64748b">
                          {eventParticipants[0].trackedSubjectType.subType
                            ? convertFirstLetterToUppercase(
                                eventParticipants[0].trackedSubjectType.subType,
                              )
                            : '-'}
                        </Text>
                      ) : (
                        <Text fz="0.875rem" fw={400} c="#64748b">
                          -
                        </Text>
                      )}
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Gender
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {eventParticipants[0].gender
                          ? convertFirstLetterToUppercase(
                              eventParticipants[0].gender,
                            )
                          : '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Phone
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {eventParticipants[0].phoneNumber ||
                        eventParticipants[0].phoneNumber?.length > 0
                          ? eventParticipants[0].phoneNumber
                          : '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Postal code
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {eventParticipants[0].postcode ||
                        eventParticipants[0].postcode?.length > 0
                          ? eventParticipants[0].postcode
                          : '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Section
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {eventParticipants[0]?.tagEventStatusEvent[0]
                          ?.sectionName ?? '-'}
                      </Text>
                    </Box>
                    <Box>
                      <Text fz="0.875rem" fw={500} c="#0f172a">
                        Last seen
                      </Text>
                      <Text fz="0.875rem" fw={400} c="#64748b">
                        {eventParticipants[0]?.updatedAt
                          ? calculateTimeElapsed(
                              eventParticipants[0]?.updatedAt,
                            )
                          : '-'}
                      </Text>
                    </Box>
                  </Flex>
                </Grid.Col>
              </Grid>
              <Text mt="1rem" fz="0.875rem" fw={500} c="#0f172a">
                Assigned tags ({assignedTags.length})
              </Text>
              <Flex mt="0.75rem" gap="0.75rem" direction="column">
                {assignedTags.map((assignedTag) => (
                  <AssignedCard
                    key={`${assignedTag.name}-${assignedTag.address}`}
                    id={assignedTag.tagId}
                    eventId={eventId}
                    trackingObjectId={dataId}
                    primary={assignedTag.primary}
                    name={assignedTag.name}
                    address={assignedTag.address}
                    date={assignedTag.date}
                    time={assignedTag.time}
                    setAssignedTags={setAssignedTags}
                  />
                ))}
              </Flex>
            </ScrollArea>
          </Grid.Col>
          <Grid.Col py={0} pr={0} span={7}>
            <Box
              p="1rem"
              bd="1px solid #e2e8f0"
              className={classNames.mapControlBox}
            >
              <Flex justify="space-between" align="center">
                <Flex c="#94a3b8" gap="0.5rem">
                  <InformationCircleIcon height="1.25rem" width="1.25rem" />
                  <Text fz="0.875rem" fw={400}>
                    Use map control for more informations
                  </Text>
                </Flex>

                <MapControl
                  mapControl={mapControl}
                  setMapControl={setMapControl}
                />
              </Flex>

              <Divider my="1rem" c="#e2e8f0" />

              <Box pos="relative" ref={boxRef}>
                {boxRef.current && (
                  <InteractiveCanvas
                    imageUrl={MapPicture}
                    height={height - 17 * 24}
                    width={boxRef.current?.offsetWidth}
                    scale={scale}
                    showSections={mapControl.sectionsArea}
                    showPins={mapControl.sectionsPins}
                    showDevices={mapControl.devices}
                  />
                )}

                <ZoomControl
                  scale={scale}
                  setScale={setScale}
                  pos="absolute"
                  right="0.675rem"
                  bottom="1rem"
                />
              </Box>
            </Box>
          </Grid.Col>
        </>
      )}
    </Grid>
  );
};

export default TrackingObjectDetailsModal;
