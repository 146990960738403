import { notification } from '@lanaco/lnc-react-ui';
import { RowSelectionState } from '@tanstack/react-table';
import PromptModal from 'components/PromptModal/PromptModal';
import { useDeleteDevices } from 'hooks/event/devices/useDeleteDevices';
import { getErrorMsg } from 'utils/getErrorMsg';

import { EventDevice } from '../EventDevices.interface';

interface DeleteDevicesModalProps {
  id: string;
  selectedIds: string[];
  closeModal: () => void;
  onDeviceEdit: (device: EventDevice | null) => void;
  setSelectedRows: (selectedRows: RowSelectionState) => void;
}

export const DeleteDevicesModal = ({
  id,
  selectedIds,
  closeModal,
  onDeviceEdit,
  setSelectedRows,
}: DeleteDevicesModalProps) => {
  const { mutate, isLoading } = useDeleteDevices();

  const closeDeleteDevicesModal = () => {
    onDeviceEdit(null);
    closeModal();
  };

  const confirmDeleteDevices = async () => {
    mutate(
      {
        id,
        ids: selectedIds,
      },
      {
        onSuccess: (data) => {
          if (data?.status === 201) {
            notification.success('Device successfully deleted.');
          } else {
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          setSelectedRows({});
          closeDeleteDevicesModal();
        },
      },
    );
  };

  return (
    <PromptModal
      title="Delete devices?"
      isLoading={isLoading}
      warningMessage={
        <>
          Are you sure you want to delete {selectedIds.length}
          <br />
          devices from the database?
        </>
      }
      confirmButtonName="Delete"
      icon="exclamation-triangle"
      iconSize="24"
      iconColor="danger"
      isDeleteButton
      onConfirm={confirmDeleteDevices}
      onCancel={closeDeleteDevicesModal}
    />
  );
};
