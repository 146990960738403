import { Box, BoxProps, Flex, Image, rgba, Text } from '@mantine/core';
import { useState } from 'react';

import { LayoutChangerProps } from '../types';

const LayoutChanger = ({
  layouts,
  activeLayout,
  setActiveLayout,
  ...props
}: LayoutChangerProps & BoxProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <Box pos="relative" {...props}>
      <Image
        src={activeLayout.picture}
        alt="Layout picture"
        h="5rem"
        w="5rem"
        style={{ borderRadius: '0.5rem' }}
      />

      <Box
        h="5rem"
        w="5rem"
        bg={rgba('#000000', 0.4)}
        bd={`2px solid ${isMenuOpen ? '#3b82f6' : '#94a3b8'}`}
        pos="absolute"
        top={0}
        right={0}
        style={{ borderRadius: '0.5rem', cursor: 'pointer' }}
        onClick={() => setIsMenuOpen((prev) => !prev)}
      />

      <Text
        w="5rem"
        c="#ffffff"
        fz="0.75rem"
        ta="center"
        pos="absolute"
        bottom="0.5rem"
        style={{ cursor: 'pointer' }}
      >
        {activeLayout.name}
      </Text>

      <Flex
        h="1.25rem"
        w="1.25rem"
        bg="#3b82f6"
        c="#ffffff"
        bd="1px solid #ffffff"
        fz="0.75rem"
        justify="center"
        align="center"
        pos="absolute"
        top="-0.5rem"
        right="-0.5rem"
        style={{ borderRadius: '50rem' }}
      >
        {activeLayout.devices.filter((device) => device.coordinates).length}
      </Flex>

      {isMenuOpen && (
        <Flex
          bg="#ffffff"
          p="0.5rem"
          gap="0.5rem"
          pos="absolute"
          top={0}
          left="5.75rem"
          style={{ borderRadius: '0.5rem' }}
        >
          {layouts.map((layout) => (
            <Box
              key={`layout-${layout.name}`}
              h="4.375rem"
              w="3.375rem"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setActiveLayout(layout);
                setIsMenuOpen(false);
              }}
            >
              <Image
                src={layout.picture}
                alt="Layout picture"
                h="3.375rem"
                w="3.375rem"
                bd={
                  activeLayout === layout
                    ? '2px solid #3b82f6'
                    : '1px solid #94a3b8'
                }
                style={{ borderRadius: '0.5rem' }}
              />
              <Text mt="0.25rem" truncate="end" c="#0f172a" fz="0.75rem">
                {layout.name}
              </Text>
            </Box>
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default LayoutChanger;
