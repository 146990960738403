import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import { CREATE_EVENT_ASSIGNABLE_DEVICES } from 'utils/apiUrl';

export class EventAssignableDevicesService extends ResponseInterceptor {
  static async addAssignableDevices(
    eventId: string,
    body: string[],
  ): Promise<APIAxiosResponse<void>> {
    const res = await client.post(CREATE_EVENT_ASSIGNABLE_DEVICES(eventId), {
      deviceIds: body,
    });
    return this.handleResponse(res);
  }
}
