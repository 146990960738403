import {
  InformationCircleIcon,
  PencilIcon,
  ClockIcon,
  MapPinIcon,
  BuildingOfficeIcon,
  GlobeAmericasIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  QrCodeIcon,
  PhotoIcon,
  EllipsisHorizontalIcon,
  CalendarDaysIcon,
} from '@heroicons/react/20/solid';
import {
  PhoneIcon,
  ExclamationTriangleIcon,
  ArrowTopRightOnSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Stack,
  Text,
  FileButton,
  Divider,
  Anchor,
  Code,
  Modal,
  Popover,
  Menu,
} from '@mantine/core';
import QR from 'components/QR/QR';
import ScheduleCard from 'components/ScheduleCard/ScheduleCard';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Fragment, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useUpdateEffect } from 'react-use';
import {
  useDeleteEvent,
  useEntity,
  useEntitySchedules,
  useTimezoneByEvent,
  useUploadEntityImage,
} from 'services/entity/home';
import { convertDateAndTime, getTimeStatus } from 'utils/createDateAndTime';

import noBannerImage from '../../../assets/images/NoBannerImage.png';
import { setEntityModalContent } from '../Home/Modals/entityModalsConfig';
import { openModal } from '../ModalManager/ModalsManager';
import { mapEventScheduledToScheduleCard } from '../Schedule/mapper';

import classNames from './EntityAbout.module.scss';
import { setAboutModalContent } from './Modals/aboutModalsConfig';
import ScheduleInformationModal from './Modals/ScheduleInformationModal';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const EntityAbout = () => {
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [file, setFile] = useState<File | null>(null);
  const { id } = useParams();
  const { data: eventData } = useEntity(id ?? '');
  const { data: timezoneData } = useTimezoneByEvent(id ?? '');
  const [openMetadataJson, setOpenMetadataJson] = useState<boolean>(false);
  const { data: entityScheduledData } = useEntitySchedules(id ?? '');
  const [openedPopover, setOpenedPopover] = useState<boolean>(false);
  const { mutate: mutateEntityImage } = useUploadEntityImage();
  const {
    mutate: mutateDeleteEntity,
    isSuccess: isSuccessDeleteEvent,
  } = useDeleteEvent();
  const navigate = useNavigate();

  const formattedDate = currentDate.tz(timezoneData).format('DD MMM YYYY');

  const goToPreviousDay = () => {
    setCurrentDate((prevDate) => prevDate.tz(timezoneData).subtract(1, 'day'));
  };

  const goToNextDay = () => {
    setCurrentDate((prevDate) => prevDate.tz(timezoneData).add(1, 'day'));
  };

  const filteredScheduledData = useMemo(() => {
    if (!timezoneData) return [];

    return entityScheduledData?.filter((scheduledData) => {
      const start = dayjs.utc(scheduledData.startDateTime).tz(timezoneData);
      const end = dayjs.utc(scheduledData.endDateTime).tz(timezoneData);
      const current = currentDate.tz(timezoneData);

      return (
        (current.isAfter(start, 'day') || current.isSame(start, 'day')) &&
        (current.isBefore(end, 'day') || current.isSame(end, 'day'))
      );
    });
  }, [entityScheduledData, timezoneData, currentDate]);

  const getBadgeColor = (status: string) => {
    switch (status) {
      case 'live':
        return '#F43F5E';
      case 'upcoming':
        return '#EAB308';
      case 'passed':
        return '#3B82F6';
      default:
        return '#ffff';
    }
  };

  useUpdateEffect(() => {
    if (file && id) {
      mutateEntityImage({ image: file, eventId: id });
    }
  }, [file, id]);

  useUpdateEffect(() => {
    if (isSuccessDeleteEvent) {
      navigate('/entities');
    }
  }, [isSuccessDeleteEvent]);

  return (
    <>
      <Box
        bd="1px solid #e2e8f0"
        mb="2rem"
        pos="relative"
        className={classNames.bannerBox}
      >
        <Image
          h={400}
          w="100%"
          fit="cover"
          src={eventData?.heroImage?.largeSource || null}
          fallbackSrc={noBannerImage}
        />
        {!eventData?.heroImage?.largeSource && (
          <Text
            fz="2.5rem"
            fw={600}
            c="#CBD5E1"
            pos="absolute"
            top="50%"
            left="50%"
            className={classNames.noBanner}
          >
            No banner image
          </Text>
        )}

        <Flex
          pos="absolute"
          bottom="0"
          left="0"
          w="100%"
          justify="space-between"
          p="1.5rem"
          wrap="wrap"
        >
          <Stack gap="2px">
            <Flex gap="0.5rem" align="center" wrap="wrap">
              {eventData?.startDate && eventData?.finishDate && timezoneData && (
                <Badge
                  color={getBadgeColor(
                    getTimeStatus(
                      eventData?.startDate,
                      eventData?.finishDate,
                      timezoneData,
                    ),
                  )}
                  fz="12px"
                  fw={600}
                >
                  {getTimeStatus(
                    eventData?.startDate,
                    eventData?.finishDate,
                    timezoneData,
                  )}
                </Badge>
              )}
              <Text c="#FFFFFF" fw={600} fz="0.875rem" tt="uppercase">
                {eventData?.startDate &&
                  eventData?.finishDate &&
                  timezoneData &&
                  `${dayjs(
                    convertDateAndTime(eventData?.startDate, timezoneData),
                  )
                    .format('ddd DD MMM YYYY')
                    .toLowerCase()} - ${dayjs(
                    convertDateAndTime(eventData?.finishDate, timezoneData),
                  )
                    .format('ddd DD MMM YYYY')
                    .toLowerCase()}`}
              </Text>
            </Flex>
            <Text fw={600} fz="1.875rem" lh="2.25rem" c="#FFFFFF">
              {eventData?.name}
            </Text>
          </Stack>

          <Flex gap="0.75rem" className={classNames.buttonsPosition}>
            <Popover
              withArrow
              opened={openedPopover}
              onChange={setOpenedPopover}
            >
              <Popover.Target>
                <Button
                  bg="#ffffff"
                  fw={500}
                  fz="0.875rem"
                  c="#344155"
                  onClick={() => setOpenedPopover((o) => !o)}
                  leftSection={
                    <QrCodeIcon
                      height="1.25rem"
                      width="1.25rem"
                      color="#94A3B8"
                    />
                  }
                >
                  View QR
                </Button>
              </Popover.Target>
              <Popover.Dropdown fz="0.875rem">
                {id && <QR value={id} qrId={id} />}
              </Popover.Dropdown>
            </Popover>
            <FileButton onChange={setFile} accept="image/png,image/jpeg">
              {(props) => (
                <Button
                  {...props}
                  bg="#ffffff"
                  fw={500}
                  fz="0.875rem"
                  c="#344155"
                  leftSection={
                    <PhotoIcon
                      height="1.25rem"
                      width="1.25rem"
                      color="#94A3B8"
                    />
                  }
                >
                  {eventData?.heroImage ? 'Replace banner' : 'Add banner'}
                </Button>
              )}
            </FileButton>

            <Menu position="bottom-end">
              <Menu.Target>
                <Button bg="#ffffff">
                  <EllipsisHorizontalIcon
                    color="#475569"
                    height={20}
                    width={20}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                      border: 'none',
                      background: 'transparent',
                    }}
                  />
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '2.25rem',
                    fontWeight: 300,
                    lineHeight: '1rem',
                    minWidth: '11rem',
                    borderRadius: '0.625rem',
                    color: 'var(--gray-500) !important',
                  }}
                  onClick={() => {
                    if (id) mutateDeleteEntity({ eventId: id });
                  }}
                >
                  <TrashIcon width={20} height={20} color="#f43f5e" />
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
      </Box>

      <Flex h="50rem" gap="2rem">
        <Stack w="40%" justify="space-between">
          <Stack
            p="1.5rem"
            bg="#FFFFFF"
            bd="1px solid #E2E8F0"
            gap="0.75rem"
            className={classNames.radius}
          >
            <Flex justify="space-between" align="center">
              <Text fw={600} fz="1.125rem" c="#0F172A">
                Entity informations
              </Text>

              <Button
                bg="#ffffff"
                c="#344155"
                justify="right"
                fw={500}
                leftSection={
                  <PencilIcon
                    height="1.25rem"
                    width="1.25rem"
                    color="#94A3B8"
                  />
                }
                onClick={() =>
                  openModal({
                    title: 'Edit entity',
                    dataId: id,
                    subtitles: [
                      'Entity informations',
                      'Entity scanning content',
                      'Entity social media',
                    ],
                    isMultistep: true,
                    size: 640,
                    setModalContent: setEntityModalContent,
                  })
                }
              >
                Edit info
              </Button>
            </Flex>

            {eventData?.description && (
              <Text fz="0.875rem" c="#0F172A">
                {eventData?.description}
              </Text>
            )}

            <Flex align="center" gap={14}>
              <Box w="1.25rem">
                <ClockIcon height="1.25rem" width="1.25rem" color="#94A3B8" />
              </Box>
              {eventData?.startDate && eventData?.finishDate && timezoneData && (
                <Text fz="14px" c="#0F172A">
                  {`${dayjs(
                    convertDateAndTime(eventData?.startDate, timezoneData),
                    'YYYY-MM-DD HH:mm:ss',
                  ).format('DD MMM YYYY hh:mm A')} - ${dayjs(
                    convertDateAndTime(eventData?.finishDate, timezoneData),
                    'YYYY-MM-DD HH:mm:ss',
                  ).format('DD MMM YYYY hh:mm A')}`}
                </Text>
              )}
            </Flex>

            {eventData?.location && (
              <Flex align="center" gap={14}>
                <Box w="1.25rem">
                  <MapPinIcon
                    height="1.25rem"
                    width="1.25rem"
                    color="#94A3B8"
                  />
                </Box>

                <Text fz="14px" c="#0F172A">
                  {eventData?.location?.address}
                </Text>
              </Flex>
            )}

            {eventData?.companyId?.name && (
              <Flex align="center" gap={14}>
                <Box w="1.25rem">
                  <BuildingOfficeIcon
                    height="1.25rem"
                    width="1.25rem"
                    color="#94A3B8"
                  />
                </Box>
                <Text fz="14px" c="#0F172A">
                  {eventData?.companyId?.name}
                </Text>
              </Flex>
            )}
            {eventData?.eventUrl && (
              <Flex align="center" gap={14}>
                <Box w="1.25rem">
                  <ArrowTopRightOnSquareIcon
                    height="1.25rem"
                    width="1.25rem"
                    color="#94A3B8"
                  />
                </Box>
                <Anchor
                  href={eventData?.eventUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  fz="0.875rem"
                >
                  {eventData?.eventUrl}
                </Anchor>
              </Flex>
            )}
            {eventData?.socials && (
              <Flex align="center" gap={14}>
                <Box w="1.25rem">
                  <GlobeAmericasIcon
                    height="1.25rem"
                    width="1.25rem"
                    color="#94A3B8"
                  />
                </Box>

                <Flex c="#0F172A" gap="12px" wrap="wrap">
                  <Text fz="0.875rem">
                    {eventData?.socials?.facebook ?? ''}
                  </Text>
                  <Text fz="0.875rem">
                    {eventData?.socials?.instagram ?? ''}
                  </Text>
                  <Text fz="0.875rem">
                    {eventData?.socials?.linkedin ?? ''}
                  </Text>
                  <Text fz="0.875rem">{eventData?.socials?.tikTok ?? ''}</Text>
                  <Text fz="0.875rem">{eventData?.socials?.twitter ?? ''}</Text>
                  <Text fz="0.875rem">{eventData?.socials?.youtube ?? ''}</Text>
                </Flex>
              </Flex>
            )}
          </Stack>

          <Stack
            p="1.5rem"
            bg="#FFFFFF"
            bd="1px solid #E2E8F0"
            gap="12px"
            className={classNames.radius}
          >
            <Flex justify="space-between" align="center">
              <Flex align="center" gap="0.75rem">
                <InformationCircleIcon
                  height="1.5rem"
                  width="1.5rem"
                  color="#94A3B8"
                />
                <Text fw={600} fz="1.125rem" c="#0F172A">
                  Registration
                </Text>
              </Flex>

              <Button
                bg="#ffffff"
                c="#344155"
                justify="right"
                fw={500}
                leftSection={
                  <PencilIcon
                    height="1.25rem"
                    width="1.25rem"
                    color="#94A3B8"
                  />
                }
                onClick={() =>
                  openModal({
                    type: 'registration',
                    dataId: id,
                    size: 640,
                    title: 'Registration',
                    actionButtonLabel: 'Save',
                    actionButtonFn: () => {},
                    setModalContent: setAboutModalContent,
                  })
                }
              >
                Edit info
              </Button>
            </Flex>

            <Grid>
              <Grid.Col span={3}>
                <Stack gap="1.25rem">
                  <Text c="#0F172A" fz="0.875rem">
                    Link
                  </Text>
                  <Text c="#0F172A" fz="0.875rem">
                    Duration
                  </Text>
                  <Text c="#0F172A" fz="0.875rem">
                    Metadata
                  </Text>
                </Stack>
              </Grid.Col>

              <Grid.Col span={9}>
                <Stack gap="1.15rem">
                  <Text c="#0F172A" fz="0.875rem">
                    {eventData?.registration?.url ? (
                      <Anchor
                        href={eventData.registration.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        fz="0.875rem"
                      >
                        {eventData.registration.url}
                      </Anchor>
                    ) : (
                      'No information.'
                    )}
                  </Text>

                  <Text
                    c="#0F172A"
                    fz="0.875rem"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {eventData?.registration?.startDate &&
                    eventData?.registration?.finishDate &&
                    timezoneData
                      ? `${dayjs(
                          convertDateAndTime(
                            eventData.registration.startDate,
                            timezoneData,
                          ),
                          'YYYY-MM-DD HH:mm:ss',
                        ).format('DD MMM YYYY hh:mm A')} - ${dayjs(
                          convertDateAndTime(
                            eventData.registration.finishDate,
                            timezoneData,
                          ),
                          'YYYY-MM-DD HH:mm:ss',
                        ).format('DD MMM YYYY hh:mm A')}`
                      : 'No information.'}
                  </Text>
                  {eventData?.registration?.metadata ? (
                    <>
                      <Text
                        c="var(--gray-900)"
                        fz="0.875rem"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setOpenMetadataJson(true)}
                      >
                        View JSON data
                      </Text>
                      <Modal
                        opened={openMetadataJson}
                        onClose={() => setOpenMetadataJson(false)}
                        title="Metadata"
                      >
                        <Code block>
                          {JSON.stringify(
                            eventData.registration.metadata,
                            null,
                            2,
                          )}
                        </Code>
                      </Modal>
                    </>
                  ) : (
                    <Text c="#64748B" fz="0.875rem">
                      No information.
                    </Text>
                  )}
                </Stack>
              </Grid.Col>
            </Grid>
          </Stack>

          <Stack
            p="1.5rem"
            bg="#FFFFFF"
            bd="1px solid #E2E8F0"
            gap="12px"
            className={classNames.radius}
          >
            <Flex justify="space-between" align="center">
              <Flex align="center" gap="0.75rem">
                <PhoneIcon height="1.5rem" width="1.5rem" color="#94A3B8" />
                <Text fw={600} fz="1.125rem" c="#0F172A">
                  Contact
                </Text>
              </Flex>

              <Button
                bg="#ffffff"
                c="#344155"
                justify="right"
                fw={500}
                leftSection={
                  <PencilIcon
                    height="1.25rem"
                    width="1.25rem"
                    color="#94A3B8"
                  />
                }
                onClick={() =>
                  openModal({
                    type: 'contact',
                    size: 640,
                    dataId: id,
                    title: 'Contact',
                    actionButtonLabel: 'Save',
                    actionButtonFn: () => {},
                    setModalContent: setAboutModalContent,
                  })
                }
              >
                Edit info
              </Button>
            </Flex>

            <Grid>
              <Grid.Col span={4}>
                <Stack gap="1.25rem">
                  <Text c="#0F172A" fz="0.875rem">
                    Phone number
                  </Text>
                  <Text c="#0F172A" fz="0.875rem">
                    Email
                  </Text>
                  <Text c="#0F172A" fz="0.875rem">
                    Contact person(s)
                  </Text>
                </Stack>
              </Grid.Col>

              <Grid.Col span={8}>
                <Stack gap="1.15rem">
                  <Text c="#0F172A" fz="0.875rem">
                    {eventData?.contactPerson?.phoneNumber ? (
                      <Text c="#0F172A" fz="0.875rem">
                        {eventData?.contactPerson?.phoneNumber}
                      </Text>
                    ) : (
                      'No information.'
                    )}
                  </Text>

                  <Text c="#0F172A" fz="0.875rem">
                    {eventData?.contactPerson?.email ? (
                      <Text c="#0F172A" fz="0.875rem">
                        {eventData?.contactPerson?.email}
                      </Text>
                    ) : (
                      'No information.'
                    )}
                  </Text>
                  <Text c="#0F172A" fz="0.875rem">
                    {eventData?.contactPerson?.name ? (
                      <Text c="#0F172A" fz="0.875rem">
                        {eventData?.contactPerson?.name}
                      </Text>
                    ) : (
                      'No information.'
                    )}
                  </Text>
                </Stack>
              </Grid.Col>
            </Grid>
          </Stack>
        </Stack>

        <Stack
          w="60%"
          p={24}
          bg="#FFFFFF"
          bd="1px solid #E2E8F0"
          gap="1rem"
          className={classNames.radius}
        >
          <Flex justify="space-between">
            <Text fz="1.125rem" c="#0f172a" fw={600}>
              Schedule
            </Text>

            <Flex gap="0.75rem" align="center">
              <ChevronLeftIcon
                height="1.5rem"
                width="1.5rem"
                color="#64748B"
                cursor="pointer"
                onClick={goToPreviousDay}
              />
              <Text fz="1.125rem" c="#0F172A" fw={500}>
                {formattedDate}
              </Text>
              <ChevronRightIcon
                height="1.5rem"
                width="1.5rem"
                color="#64748B"
                cursor="pointer"
                onClick={goToNextDay}
              />
            </Flex>
          </Flex>

          {filteredScheduledData && filteredScheduledData.length > 0 ? (
            <ScrollArea mr="-0.75rem" pr="0.5rem">
              <Stack gap={0}>
                {filteredScheduledData.map(
                  (filteredData, index, filteredArray) => {
                    const mappedCard = mapEventScheduledToScheduleCard(
                      filteredData,
                    );

                    return (
                      <Fragment key={`schedule-card-${filteredData._id}`}>
                        <Divider
                          ml="1rem"
                          mr="0.5rem"
                          color="#F8FAFC"
                          size="md"
                        />
                        <ScheduleCard
                          key={`schedule-card-${filteredData._id}`}
                          {...mappedCard}
                          formattedDate={formattedDate}
                          onClick={() =>
                            openModal({
                              size: 640,
                              title: 'Schedule information',
                              titleIcon: (
                                <CalendarDaysIcon
                                  height="1.25rem"
                                  width="1.25rem"
                                  color="#94A3B8"
                                />
                              ),
                              hasFooter: false,
                              content: (
                                <ScheduleInformationModal
                                  scheduleId={filteredData._id}
                                  eventId={id ?? ''}
                                  timeZone={timezoneData ?? ''}
                                />
                              ),
                            })
                          }
                        />
                        {index === filteredArray.length - 1 && (
                          <Divider
                            ml="1rem"
                            mr="0.5rem"
                            color="#F8FAFC"
                            size="md"
                          />
                        )}
                      </Fragment>
                    );
                  },
                )}
              </Stack>
            </ScrollArea>
          ) : (
            <Stack h="100%" align="center" justify="center">
              <ExclamationTriangleIcon
                height="3rem"
                width="3rem"
                color="#CBD5E1"
              />
              <Text fz="1rem" c="#475569" fw={600}>
                No upcoming schedule
              </Text>
              {/* <Text maw={540} fw={400} fz="0.875rem" c="#64748B" ta="center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Text> */}
            </Stack>
          )}
        </Stack>
      </Flex>
    </>
  );
};

export default EntityAbout;
