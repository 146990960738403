import clsx from 'clsx';
import { useCallback, useEffect, useRef } from 'react';

import { EventLayout, EventLayoutPresentationType } from '../interfaces';

import styles from './LayoutLocationPicker.module.css';

const getMapConfig = (
  position: google.maps.LatLngLiteral,
  zoomOverride = 13,
): google.maps.MapOptions => ({
  center: {
    lat: position.lat,
    lng: position.lng,
  },
  zoom: zoomOverride,
  disableDefaultUI: true,
  zoomControl: true,
});

export interface LayoutLocationPickerProps {
  visible: boolean;
  eventLayout?: EventLayout;
  timingLocationCoordinates?: {
    lat?: number;
    lng?: number;
  };

  onChange: (
    type: EventLayoutPresentationType.GoogleMap,
    location: google.maps.LatLngLiteral & { zoom: number | undefined },
  ) => void;
}

export const LayoutLocationPicker = ({
  visible,
  eventLayout,
  timingLocationCoordinates,
  onChange,
}: LayoutLocationPickerProps) => {
  const mapDivRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<google.maps.Map | null>(null);

  const bindEvents = useCallback(
    (map: google.maps.Map) => {
      // create callback for updating state on map center change
      const handleChange = (map: google.maps.Map) => () =>
        onChange(EventLayoutPresentationType.GoogleMap, {
          ...map.getCenter()!.toJSON(),
          zoom: map.getZoom(),
        });

      const marker = new google.maps.Marker({
        position: map.getCenter(),
        map,
        visible:
          timingLocationCoordinates === undefined ||
          (timingLocationCoordinates !== undefined &&
            timingLocationCoordinates?.lat === undefined &&
            timingLocationCoordinates?.lng === undefined)
            ? false
            : true,
      });

      const onChangeCallback = handleChange(map);
      map.addListener('center_changed', () => {
        marker.setPosition(map.getCenter());
        onChangeCallback();
      });
      map.addListener('click', (event) => {
        timingLocationCoordinates !== undefined
          ? marker.setVisible(true)
          : marker.setVisible(false);
        marker.setPosition(event.latLng);
        map.setCenter(event.latLng);
        onChangeCallback();
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange],
  );

  useEffect(() => {
    if (visible && mapDivRef.current) {
      if (
        eventLayout?.presentation?.type ===
        EventLayoutPresentationType.GoogleMap
      ) {
        mapInstanceRef.current = new window.google.maps.Map(
          mapDivRef.current,
          getMapConfig(
            timingLocationCoordinates === undefined ||
              (timingLocationCoordinates !== undefined &&
                timingLocationCoordinates?.lat === undefined &&
                timingLocationCoordinates?.lng === undefined)
              ? eventLayout.presentation.geoRange.center
              : (timingLocationCoordinates as google.maps.LatLngLiteral),
            eventLayout.presentation.geoRange.zoom,
          ),
        );
        bindEvents(mapInstanceRef.current);
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            mapInstanceRef.current = new window.google.maps.Map(
              mapDivRef.current!,
              getMapConfig({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              }),
            );

            bindEvents(mapInstanceRef.current);
          },
          (error) => {
            if (error.code === 1) {
              mapInstanceRef.current = new window.google.maps.Map(
                mapDivRef.current!,
                getMapConfig({
                  lat: 45.813,
                  lng: 15.977,
                }),
              );

              bindEvents(mapInstanceRef.current);
            } else {
              alert('map error');
              console.log('map error', error);
            }
          },
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bindEvents, visible]);

  return (
    <div
      ref={mapDivRef}
      className={clsx(styles.mapDiv, visible !== true && styles.hide)}
    ></div>
  );
};
