import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { Badge, Button, Flex, Text, TextInput } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { debounce } from 'lodash';
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from 'mantine-react-table';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';
import { useUpdateEffect } from 'react-use';
import {
  useAssignTagsForTrackingObjectByEvent,
  useTagByTrackingObjectAndEvent,
  useUnassignTagsForTrackingObjectByEvent,
} from 'services/entity/trackingObject';

import {
  mapTagByTrackingObjectAndEventToAssignTagTable,
  mapTagByTrackingObjectAndEventToUnassignTagTable,
} from '../../mapper';
import {
  AssignTagTableType,
  TagAssingToTrackingObjectModalProps,
} from '../../types';
import styles from '../TableModals.module.css';

const TagAssingToTrackingObjectModal = ({
  dataId,
  eventId,
  isAssignModal,
  trackingObjectTags,
}: TagAssingToTrackingObjectModalProps) => {
  // const [sortingParam, setSortingParam] = useState<SortingParamType | null>(
  //   null,
  // );
  // const [showAdditionalFilters, setShowAdditionalFilters] = useState<boolean>(
  //   false,
  // );
  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>(
    {},
  );
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isAtBottom, setIsAtBottom] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(20);
  const [filteredData, setFilteredData] = useState<AssignTagTableType[] | []>(
    [],
  );
  const {
    data: tagData,
    isLoading: tagIsLoading,
  } = useTagByTrackingObjectAndEvent(
    eventId,
    dataId,
    limit,
    debouncedSearchTerm,
  );
  const { mutate: assignTagMutate } = useAssignTagsForTrackingObjectByEvent();
  const {
    mutate: unassignTagMutate,
  } = useUnassignTagsForTrackingObjectByEvent();
  const { height } = useViewportSize();

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      if (value?.length >= 3) setDebouncedSearchTerm(value);
      else if (value?.length === 0) setDebouncedSearchTerm('');
    }, 500),
    [],
  );

  const selectedIds = useMemo(() => {
    return Object.keys(rowSelection).map(
      (rowId) => filteredData[Number(rowId)]?._id,
    );
  }, [rowSelection, filteredData]);

  const handleSubmitModal = () => {
    if (dataId && eventId)
      (isAssignModal ? assignTagMutate : unassignTagMutate)({
        eventId,
        trackingObjectId: dataId,
        tagIds: selectedIds,
      });
  };

  useEffect(() => {
    if (isAssignModal && tagData) {
      setFilteredData(mapTagByTrackingObjectAndEventToAssignTagTable(tagData));
    } else if (!isAssignModal && trackingObjectTags) {
      setFilteredData(
        mapTagByTrackingObjectAndEventToUnassignTagTable(trackingObjectTags)
          .filter((tag) =>
            debouncedSearchTerm
              ? tag.uniqueId
                  .toLowerCase()
                  .includes(debouncedSearchTerm.toLowerCase()) ||
                tag.number
                  .toLowerCase()
                  .includes(debouncedSearchTerm.toLowerCase())
              : true,
          )
          .slice(0, limit),
      );
    }
  }, [tagData, isAssignModal, trackingObjectTags, limit, debouncedSearchTerm]);

  useUpdateEffect(() => {
    if (isAtBottom) {
      setLimit((prev) => prev + 20);
    }
  }, [isAtBottom]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        const threshold = 100;
        const atBottom = scrollHeight - scrollTop - clientHeight <= threshold;

        if (setIsAtBottom) {
          setIsAtBottom(atBottom);
        }
      }
    };

    const viewport = scrollRef.current;
    if (viewport) {
      viewport.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (viewport) {
        viewport.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const columns = useMemo<MRT_ColumnDef<AssignTagTableType>[]>(
    () => [
      {
        maxSize: 300,
        minSize: 220,
        header: 'Tag ID',
        accessorKey: 'uniqueId',
        Cell: ({ cell }) => {
          return (
            <div className={`${styles.firstColumn}`}>
              {cell.getValue() as React.ReactNode}
            </div>
          );
        },
      },
      {
        maxSize: 220,
        minSize: 180,
        header: 'Tag',
        accessorKey: 'number',
      },
      {
        maxSize: 220,
        minSize: 200,
        header: 'Assigned to',
        accessorFn: (row) => row.participant?.firstLastName || '',
      },
      {
        maxSize: 220,
        minSize: 150,
        header: 'Status',
        accessorKey: 'status',
        Cell: ({ row }) => {
          const status = row.original.status;
          if (status.toLowerCase() === 'enabled') {
            return (
              <Badge
                color="#ECFDF5"
                radius={12}
                style={{
                  color: '#10B981',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                }}
              >
                Enabled
              </Badge>
            );
          } else if (status.toLowerCase() === 'disabled') {
            return (
              <Badge
                color="#F8FAFC"
                radius={12}
                style={{
                  color: '#64748B',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                }}
              >
                Disabled
              </Badge>
            );
          } else if (status.toLowerCase() === 'offline') {
            return (
              <Badge
                color="#F8FAFC"
                radius={12}
                style={{
                  color: '#64748B',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                }}
              >
                Offline
              </Badge>
            );
          } else if (status.toLowerCase() === 'online') {
            return (
              <Badge
                color="#ECFDF5"
                radius={12}
                style={{
                  color: '#10B981',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                }}
              >
                Online
              </Badge>
            );
          } else if (status.toLowerCase() === 'active') {
            return (
              <Badge
                color="#ECFDF5"
                radius={12}
                style={{
                  color: '#10B981',
                  textTransform: 'none',
                  fontSize: '0.75rem',
                }}
              >
                Online
              </Badge>
            );
          }
        },
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data: filteredData,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    enableRowSelection: true,
    enablePagination: true,
    manualPagination: true,
    rowCount: filteredData.length,

    state: {
      rowSelection,
      isLoading: tagIsLoading,
    },

    onRowSelectionChange: (newRowSelection) => {
      setRowSelection(newRowSelection);
    },

    mantineTableContainerProps: {
      ref: scrollRef,
      style: {
        maxHeight: height > 750 ? height / 1.8 : height / 2,
      },
    },

    mantineTableBodyRowProps: ({ row }) => {
      return {
        className: row.getIsSelected() ? styles.selectedRow : '',
      };
    },

    mantineSelectAllCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantineSelectCheckboxProps: {
      iconColor: 'white',
      size: '1rem',
    },

    mantinePaperProps: {
      style: {
        boxShadow: 'none',
        borderRadius: '0.5rem',
        borderColor: '#E2E8F0',
      },
    },
  });

  return (
    <div className={styles.tableContainer}>
      <Flex mb="1.5rem" justify="center">
        <TextInput
          size="sm"
          fz="1rem"
          radius="md"
          flex={1}
          placeholder="Search"
          onChange={(e) => debouncedSetSearchTerm(e.target.value)}
          leftSection={
            <MagnifyingGlassIcon
              height="1.25rem"
              width="1.25rem"
              color="#94A3B8"
            />
          }
        />
        {/* <Flex align="center" gap="0.75rem">
          <SortingDropdown
            sortingParams={tagAssignSortingParams}
            sortingParam={sortingParam}
            setSortingParam={setSortingParam}
          />
          <ActionIcon
            bg="#ffffff"
            c={showAdditionalFilters ? '#3B82F6' : '#0F172A'}
            onClick={() => setShowAdditionalFilters((prev) => !prev)}
          >
            <AdjustmentsHorizontalIcon height="1.5rem" width="1.5rem" />
          </ActionIcon>
        </Flex> */}
      </Flex>

      {/* {showAdditionalFilters && <AdditionalFilters statuses={statuses} />} */}

      <MantineReactTable table={table} />

      <Flex mt="2rem" justify="space-between" align="center">
        <Text>{Object.keys(rowSelection).length} rows selected</Text>

        <Flex justify="flex-end" gap="1rem">
          <Button
            bg="#ffffff"
            bd="1px solid #e2e8f0"
            c="#344155"
            onClick={modals.closeAll}
          >
            Cancel
          </Button>
          <Button
            bg="#3b82f6"
            c="#ffffff"
            onClick={() => {
              modals.closeAll();
              handleSubmitModal();
            }}
          >
            {isAssignModal ? 'Assign' : 'Unassign'}
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};

export default TagAssingToTrackingObjectModal;
