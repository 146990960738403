import { Button, Flex } from '@mantine/core';
import Filter from 'components/Filter/Filter';
import { useFilters } from 'context/FilterProvider';
import { has, some } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useEnumType } from 'services/enum/enum';

import { AdditionalFiltersProps } from '../../types';

const AdditionalFilters = ({
  statuses = [],
  traffics = [],
  durations = [],
  states = [],
  mappings = [],
  showTypeFilter = false,
  showClassificationFilter = false,
}: AdditionalFiltersProps) => {
  const keys = ['statuses', 'traffics', 'durations', 'states', 'mapped'];
  const [filterClear, setFilterClear] = useState<boolean>(false);

  const { filter, removeFilters } = useFilters();
  const { data: trackingObjectType } = useEnumType('trackingObjectType');
  const { data: trackingObjectPersonTypeClassification } = useEnumType(
    'trackingObjectType',
    'person',
  );
  const { data: trackingObjectAssetTypeClassification } = useEnumType(
    'trackingObjectType',
    'asset',
  );

  const combinedData = useMemo(() => {
    const dataMap = {
      person: trackingObjectPersonTypeClassification,
      asset: trackingObjectAssetTypeClassification,
    };

    return (filter?.types || [])
      .flatMap(
        (type) =>
          dataMap[type]?.map(({ key, name }) => ({ id: key, value: name })) ||
          [],
      )
      .filter(Boolean);
  }, [
    filter,
    trackingObjectPersonTypeClassification,
    trackingObjectAssetTypeClassification,
  ]);

  useEffect(() => {
    if (filterClear) setFilterClear(false);
  }, [filterClear]);

  return (
    <Flex mb="1.5rem" gap="0.75rem">
      {statuses.length > 0 && (
        <Filter
          label="Status"
          data={statuses}
          filterKey={keys[0]}
          clear={filterClear}
          border
        />
      )}
      {showTypeFilter && trackingObjectType && (
        <Filter
          label="Type"
          data={trackingObjectType
            ?.map(({ key, name }) => ({
              id: key,
              value: name,
            }))
            ?.filter((y) => y.id === 'asset' || y.id === 'person')}
          filterKey="types"
          clear={filterClear}
          border
        />
      )}
      {showClassificationFilter &&
        combinedData &&
        (filter?.types?.some((x) => x === 'person') ||
          filter?.types?.some((x) => x === 'asset')) && (
          <Filter
            label="Classification"
            data={combinedData}
            filterKey="classifications"
            clear={filterClear}
            border
          />
        )}
      {traffics.length > 0 && (
        <Filter
          label="Traffic"
          data={traffics}
          filterKey={keys[1]}
          clear={filterClear}
          border
        />
      )}
      {durations.length > 0 && (
        <Filter
          label="Duration"
          data={durations}
          filterKey={keys[2]}
          clear={filterClear}
          border
        />
      )}
      {states.length > 0 && (
        <Filter
          label="State"
          data={states}
          filterKey={keys[3]}
          clear={filterClear}
          border
        />
      )}
      {mappings.length > 0 && (
        <Filter
          label="Mapped"
          data={mappings}
          filterKey={keys[4]}
          clear={filterClear}
          border
        />
      )}

      {some(keys, (key) => has(filter, key)) && (
        <Button
          bg="#ffffff"
          c="#f43f5e"
          onClick={() => {
            setFilterClear(true);
            removeFilters(keys);
          }}
        >
          Clear filters
        </Button>
      )}
    </Flex>
  );
};

export default AdditionalFilters;
