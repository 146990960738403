import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DeviceStatistics } from 'pages/Devices/interfaces';
import { client } from 'services/api';
import { GET_DEVICE_STATISTICS } from 'utils/apiUrl';

import { deviceStatisticsBaseKey } from './baseKeys';
import { statisticsRefetchInterval } from './statisticsRefetchInterval';

export const useFetchDeviceStatistics = () => {
  const queryResult = useQuery<DeviceStatistics, AxiosError>({
    queryKey: [deviceStatisticsBaseKey],
    refetchInterval: statisticsRefetchInterval,
    queryFn: async () => {
      const retVal = await client.get<{ data: DeviceStatistics }>(
        GET_DEVICE_STATISTICS(),
      );

      return retVal.data.data;
    },
  });

  return queryResult;
};
