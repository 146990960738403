import { RowSelectionState, createColumnHelper } from '@tanstack/table-core';
import {
  TLDangerButton,
  TLNeutralButton,
  TLPrimaryButton,
} from 'components/Button';
import ButtonGroupData from 'components/ButtonGroupData/ButtonGroupData';
import { ButtonGroupDataInterface } from 'components/ButtonGroupData/ButtonGroupData.interface';
import { InputSearch } from 'components/Input/InputSearch';
import { ModalSize, TLModal } from 'components/Modal';
import TanStackTable, {
  RowAction,
} from 'components/TanStackTable/TanStackTable';
import { useGetEventDetails } from 'hooks/event/details';
import { useFetchEventTags } from 'hooks/event/tags';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useParams } from 'react-router-dom';

import { EventParticipantTableData } from '../Participants/EventParticipants.interface';
import RemoveParticipantFromTag from '../Participants/RemoveParticipantFromTag';

import AddEventTagForm from './AddEventTagForm';
import AddRemoveParticipantTagForm from './AddRemoveParticipantTagForm';
import { EventTagTableData } from './EventTag.interface';
import styleTags from './EventTag.module.css';
import { RemoveEventTagModal } from './RemoveEventTagModal';

type Props = {};

const columnHelper = createColumnHelper<EventTagTableData>();
const ROW_LIMIT = 10;

const EventTags = (props: Props) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [tagToEdit, setTagToEdit] = useState<EventTagTableData | null>(null);
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [
    participantToEdit,
    setParticipantToEdit,
  ] = useState<EventParticipantTableData | null>(null);

  const [replaceParticipant, setReplaceParticipant] = useState<boolean>(false);

  const { id: eventId } = useParams<{ id: string }>();

  const event = useGetEventDetails(eventId);

  const { data, isFetching } = useFetchEventTags({
    eventId: eventId as string,
    offset: (page - 1) * ROW_LIMIT,
    row_limit: ROW_LIMIT,
    filter: search,
  });

  const { _route, data: eventTagData } = data || {
    _route: { returned: 0, total: 0 },
    data: [],
  };

  const selectedIds = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {
        var tagId = eventTagData?.filter((s) => s._id === key)[0]?.tag._id;
        acc.push(tagId);
      }
      return acc;
    },
    [],
  );
  const buttons: Array<ButtonGroupDataInterface> = [
    {
      text: 'All',
      text1: _route.total ? _route.total.toString() : '0',
      indicator: '',
    },
  ];
  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  const handleAddEventTagClick = () => {
    showInsertTagModal();
  };

  const handleRemoveClick = (row: EventTagTableData) => {
    setTagToEdit(row);
    showDeleteTagModal();
  };

  const handleRemoveEventTags = () => {
    setTagToEdit(null);
    showDeleteTagModal();
  };

  const handleAssignParticipantTags = (
    row: EventTagTableData,
    replace: boolean,
  ) => {
    setTagToEdit(row);
    setParticipantToEdit(row.participant);
    setReplaceParticipant(replace);
    showAssignParticipantModal();
  };

  const [
    showAssignParticipantModal,
    _hideAssignParticipantModal,
  ] = useModal(() => {
    const hideAssignParticipantModal = () => {
      _hideAssignParticipantModal();
    };
    return (
      <TLModal
        title={'Assign tag to participant'}
        subtitle={`Select a participant you want to assign ${
          tagToEdit?.tag.name as string
        }`}
        onClose={hideAssignParticipantModal}
        size={ModalSize.L}
      >
        <AddRemoveParticipantTagForm
          eventId={event!._id}
          oldParticipantId={participantToEdit?._id!}
          tagId={tagToEdit?.tag._id as string}
          tagName={tagToEdit?.tag.name as string}
          //participantName={participantToEdit?.firstLastName}
          replaceParticipant={replaceParticipant}
          closeModal={hideAssignParticipantModal}
        />
      </TLModal>
    );
  }, [
    tagToEdit?.tag.name,
    tagToEdit?._id,
    selectedIds,
    participantToEdit?.firstLastName,
  ]);

  const handleRemoveParticipantClick = (row: EventTagTableData) => {
    setTagToEdit(row);
    setParticipantToEdit(row.participant);
    showRemoveParticipantModal();
  };

  const [showDeleteTagModal, _hideDeleteTagModal] = useModal(() => {
    const hideDeleteTagModal = () => {
      _hideDeleteTagModal();
    };
    return (
      <RemoveEventTagModal
        id={tagToEdit?.tag._id as string}
        tagIds={selectedIds}
        name={tagToEdit?.tag.name}
        eventName={event?.name}
        eventId={event!._id}
        closeModal={hideDeleteTagModal}
        setSelectedRows={setSelectedRows}
      />
    );
  }, [tagToEdit?.tag.name, tagToEdit?._id, selectedIds]);

  const [
    showRemoveParticipantModal,
    _hideRemoveParticipantModal,
  ] = useModal(() => {
    const hideRemoveParticipantModal = () => {
      _hideRemoveParticipantModal();
    };
    console.log(participantToEdit);
    return (
      <RemoveParticipantFromTag
        eventId={event!._id}
        tagId={tagToEdit!.tag._id}
        tagName={tagToEdit?.tag.name}
        participant={participantToEdit?.firstLastName}
        participantId={participantToEdit?._id as string}
        closeModal={hideRemoveParticipantModal}
      />
    );
  }, [tagToEdit?.tag.name, tagToEdit?._id, participantToEdit?._id]);

  const [showInsertTagModal, _hideInsertTagModal] = useModal(() => {
    const hideModal = () => {
      setTagToEdit(null);
      _hideInsertTagModal();
    };

    return (
      <TLModal
        title={'Add tags'}
        subtitle={`Manage tags at ${event?.name}`}
        onClose={hideModal}
        hideFooter
        size={ModalSize.CUSTOM80}
      >
        <AddEventTagForm
          closeModal={hideModal}
          eventName={event?.name as string}
          eventId={event?._id as string}
        />
      </TLModal>
    );
  }, [tagToEdit]);

  const clearSelectedRows = () => setSelectedRows({});

  const tagTableRowActions = (row: EventTagTableData): RowAction[] => [
    {
      icon: 'user-plus',
      key: 'assign',
      label: 'Assign to participant',
      className: row.participant == null ? 'show' : 'hide',
      onClick: () => handleAssignParticipantTags(row, false),
    },
    {
      icon: 'arrows-rotate',
      key: 'assignToAnother',
      label: 'Assign to another participant',
      className: row.participant == null ? 'hide' : 'show',
      onClick: () => handleAssignParticipantTags(row, true),
    },
    {
      icon: 'user-minus',
      key: 'unassign',
      label: 'Unassign from participant',
      className: row.participant == null ? 'hide' : 'show',
      onClick: () => handleRemoveParticipantClick(row),
    },
    {
      icon: 'trash',
      key: 'delete',
      label: 'Remove Tag',
      color: 'danger',
      onClick: () => handleRemoveClick(row),
    },
  ];

  const eventTagTableColumns = [
    columnHelper.accessor('tag.name', {
      header: 'Tag',
    }),
    columnHelper.accessor('number', {
      header: 'Number',
    }),
    columnHelper.accessor('uniqueId', {
      header: 'Tag ID',
    }),
    columnHelper.accessor('participant.firstLastName', {
      header: 'Participant',
      cell: (row) => (
        <span>
          {row.getValue() == null ? ( //null or undefined
            <span className={styleTags.unassigned}>Unassigned</span>
          ) : (
            row.getValue()
          )}
        </span>
      ),
    }),
    columnHelper.accessor('lastSeen', {
      header: 'Last seen',
    }),
    columnHelper.accessor('timingLocation', {
      header: 'Timing Location',
    }),
  ];

  const tagTableSelectedRowsActions = (
    <>
      <TLNeutralButton leadingIcon="times" onClick={clearSelectedRows}>
        Clear selection
      </TLNeutralButton>
      <TLNeutralButton leadingIcon="arrow-down" onClick={() => {}}>
        Export
      </TLNeutralButton>
      <TLDangerButton
        leadingIcon="trash"
        onClick={handleRemoveEventTags}
        className="text-white"
      >
        Remove
      </TLDangerButton>
    </>
  );

  const tagTableActions = (
    <>
      <TLNeutralButton leadingIcon="arrow-up" onClick={() => {}}>
        Import
      </TLNeutralButton>
      <TLPrimaryButton leadingIcon="plus" onClick={handleAddEventTagClick}>
        Add
      </TLPrimaryButton>
    </>
  );

  return (
    <div className={styleTags.divTable}>
      <InputSearch
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        className={styleTags.search}
      />
      <TanStackTable
        getRowId={(row) => row._id}
        selectedRows={selectedRows}
        onRowSelectionChange={setSelectedRows}
        isLoading={isFetching}
        columns={eventTagTableColumns}
        pageIndex={page}
        pageSize={ROW_LIMIT}
        totalRows={_route.total}
        pageCount={Math.ceil(_route.total / ROW_LIMIT)}
        data={eventTagData}
        rowActions={tagTableRowActions}
        onPageChange={handlePageChange}
        headerTitle={<ButtonGroupData buttons={buttons} />}
        rowClassName={(row) =>
          row.status === 'disabled' ? 'text-gray-400' : ''
        }
        tableActions={
          selectedIds.length > 0 ? tagTableSelectedRowsActions : tagTableActions
        }
        enableRowSelection
        showHeader
      />
    </div>
  );
};

export default EventTags;
