import { z } from 'zod';

const arbitraryJsonSchema = z.unknown();

// Define a refinement function to validate and refine the JSON
export function refineJson(jsonString: string) {
  try {
    const jsonObject = JSON.parse(jsonString);

    if (typeof jsonObject === 'object' && !Array.isArray(jsonObject)) {
      return arbitraryJsonSchema.parse(jsonObject);
    } else {
      throw new Error('Invalid JSON format: Must be a JSON object or array.');
    }
  } catch (error) {
    if (error instanceof z.ZodError) {
      // Zod validation error occurred
      const errorMessage = `Invalid JSON format: ${error.message}`;
      throw new Error(errorMessage);
    } else {
      // Syntax error or other non-Zod error
      throw new Error('Invalid JSON format');
    }
  }
}
