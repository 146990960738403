import {
	type RowSelectionState,
	createColumnHelper,
} from "@tanstack/react-table";
import { TLNeutralButton } from "components/Button";
import ButtonGroupData from "components/ButtonGroupData/ButtonGroupData";
import type { ButtonGroupDataInterface } from "components/ButtonGroupData/ButtonGroupData.interface";
import { EventCardForMobileView } from "components/Card/EventCardForMobileView";
import Header from "components/Header/Header";
import { TLModal } from "components/Modal";
import TanStackTable, {
	type RowAction,
} from "components/TanStackTable/TanStackTable";
import { useFetchEvents } from "hooks/event/useFetchEvents";
import { useState } from "react";
import { useModal } from "react-modal-hook";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import { formatDateTimeFull } from "utils/dateFormatters";

import live from "../../../assets/icons/indicator-live.svg";
import offline from "../../../assets/icons/indicator-offline.svg";
import past from "../../../assets/icons/indicator-past.svg";
import EventForm from "../EventForm";
import type { Event } from "../interfaces";

import { DeleteEventModal } from "./Event/DeleteEventModal";



const ROW_LIMIT = 10;

const columnHelper = createColumnHelper<Event>();

const Events = () => {
	const [status, setStatus] = useState("all");
	
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");
	const [eventToEdit, setEventToEdit] = useState<Event | null>(null);
	const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
	const { width } = useWindowSize();

	const selectedIds = Object.entries(selectedRows).reduce<string[]>(
		(acc, [key, value]) => {
			if (value) {
				acc.push(key);
			}
			return acc;
		},
		[],
	);

	const { data, isFetching } = useFetchEvents({
		offset: (page - 1) * ROW_LIMIT,
		limit: ROW_LIMIT,
		status,
		filter: search,
	});

	const { _route, data: events } = data || {
		_route: { returned: 0, total: 0 },
		data: [],
	};

	const [showInsertEventModal, _hideInsertEventModal] = useModal(() => {
		const hideInsertEventModal = () => {
			_hideInsertEventModal();
			setEventToEdit(null);
		};

		return (
			<TLModal hideHeader onClose={hideInsertEventModal}>
				<EventForm
					onClose={hideInsertEventModal}
					data={eventToEdit}
					isEdit={!!eventToEdit}
				/>
			</TLModal>
		);
	}, [eventToEdit]);

	const [showDeleteEventModal, _hideDeleteEventModal] = useModal(() => {
		const hideDeleteEventModal = () => {
			_hideDeleteEventModal();
			setEventToEdit(null);
		};

		return (
			<DeleteEventModal
				id={eventToEdit!._id}
				name={eventToEdit!.name}
				closeModal={hideDeleteEventModal}
				onEventEdit={setEventToEdit}
			/>
		);
	}, [eventToEdit]);

	// const [showDeleteEventsModal, _hideDeleteEventsModal] = useModal(() => {
	//   const hideDeleteEventsModal = () => {
	//     _hideDeleteEventsModal();
	//     setEventToEdit(null);
	//   };

	//   return (
	//     <DeleteEventsModal
	//       selectedIds={selectedIds}
	//       closeModal={hideDeleteEventsModal}
	//     />
	//   );
	// }, [selectedIds]);

	const handlePageChange = (page: number) => {
		setPage(page);
	};

	const handleStatusFilterChange = (status: string) => {
		setPage(1);
		setStatus(status);
	};

	const handleEventEdit = (event: Event | null) => {
		setEventToEdit(event);
		showInsertEventModal();
	};

	const handleEventDelete = (event: Event) => {
		setEventToEdit(event);
		showDeleteEventModal();
	};

	const clearSelectedRows = () => setSelectedRows({});

	const eventTableRowActions = (row: Event): RowAction[] => [
		{
			icon: "pencil",
			key: "edit",
			label: "Edit",
			onClick: () => handleEventEdit(row),
		},
		{
			icon: "trash",
			key: "delete",
			label: "Delete",
			color: "danger",
			onClick: () => handleEventDelete(row),
		},
	];

	const statusFilterButtons: ButtonGroupDataInterface[] = [
		{
			text: "All",
			indicator: "",
			onClick: () => handleStatusFilterChange("all"),
		},
		{
			text: "Live",
			indicator: live,
			onClick: () => handleStatusFilterChange("live"),
		},
		{
			text: "Upcoming",
			indicator: offline,
			onClick: () => handleStatusFilterChange("upcoming"),
		},
		{
			text: "Past",
			indicator: past,
			onClick: () => handleStatusFilterChange("past"),
		},
	];

	const eventTableSelectedRowsActions = (
		<>
			<TLNeutralButton leadingIcon="times" onClick={clearSelectedRows}>
				Clear selection
			</TLNeutralButton>
			{/* <TLDangerButton leadingIcon="trash" onClick={showDeleteEventsModal}>
        Delete
      </TLDangerButton> */}
		</>
	);

	const eventTableActions = (
		<TLNeutralButton leadingIcon="plus" onClick={() => handleEventEdit(null)}>
			Add new event
		</TLNeutralButton>
	);

	const eventColumns = [
		columnHelper.accessor("name", {
			header: "Name",
			cell: ({ row }) => (
				<Link
					to={`/event/${row.original._id}`}
					style={{ color: "#0F172A", fontSize: "14px", fontWeight: "700" }}
				>
					{row.original.name}
				</Link>
			),
		}),
		columnHelper.accessor("startDate", {
			header: "Start/End",
			cell: ({ row }) => (
				<>
					{formatDateTimeFull(
						row.original.startDate.toISOString(),
						row.original.timezone,
					)}
					-{" "}
					{formatDateTimeFull(
						row.original.finishDate.toISOString(),
						row.original.timezone,
					)}
				</>
			),
		}),
		columnHelper.accessor("location.description", {
			header: "Location",
		}),
		columnHelper.accessor("client.name", {
			header: "Client",
		}),
	];

	return (
		<div>
			<Header
				title="Events"
				subtitle="Keep track of all your live events, upcoming and past."
				onSearch={(e) => setSearch(e)}
			/>

			{width < 860 ? (
				<div>
					{events.map((event) => (
						<EventCardForMobileView
							key={event._id}
							eventId={event._id}
							eventName={event.name}
							startDateTime={event.startDate}
							endDateTime={event.finishDate}
							location={event.location}
							clientName={event.client}
						/>
					))}
				</div>
			) : (
				<TanStackTable
					getRowId={(row) => row._id}
					selectedRows={selectedRows}
					onRowSelectionChange={setSelectedRows}
					isLoading={isFetching}
					statusIndicatorClassName={(row) => {
						if (row.eventStatus === "live") {
							return "bg-red-500";
						}

						if (row.eventStatus === "upcoming") {
							return "bg-gray-300";
						}

						if (row.eventStatus === "past") {
							return "bg-gray-500";
						}
					}}
					columns={eventColumns}
					pageIndex={page}
					pageSize={ROW_LIMIT}
					totalRows={_route.total}
					pageCount={Math.ceil(_route.total / ROW_LIMIT)}
					data={events}
					rowActions={eventTableRowActions}
					onPageChange={handlePageChange}
					headerTitle={<ButtonGroupData buttons={statusFilterButtons} />}
					rowClassName={(row) =>
						row.status === "disabled" ? "text-gray-400" : ""
					}
					tableActions={
						selectedIds.length > 0
							? eventTableSelectedRowsActions
							: eventTableActions
					}
					enableRowSelection
					showHeader
				/>
			)}
		</div>
	);
};

export default Events;
