import { useQuery, useQueryClient } from '@tanstack/react-query';
import { EventTimingLocationsDevicesTableData } from 'pages/Events/TimingLocations/TimingLocations.interfaces';
import { client } from 'services/api';
import { Response } from 'types';
import { GET_TIMING_LOCATION_GATINGS } from 'utils/apiUrl';
interface Props {
  eventId: string;
  layoutId: string;
  sectionId: string;
  offset: number;
  row_limit: number;
  status?: string;
}

export const useFetchEventTimingLocationGatings = ({
  eventId,
  layoutId,
  sectionId,
  offset,
  row_limit,
  status,
}: Props) => {
  const queryClient = useQueryClient();
  const queryResult = useQuery<any>({
    queryKey: [
      'eventTimingLocationGatings',
      eventId,
      layoutId,
      sectionId,
      row_limit,
      offset,
      status,
    ],
    queryFn: async () => {
      // if (!eventId) return new EventDevice[];

      const retVal = await client.get<Response<any[]>>(
        GET_TIMING_LOCATION_GATINGS(
          eventId,
          layoutId,
          sectionId,
          row_limit,
          offset,
          status,
        ),
      );

      retVal.data.data.forEach(async (device) =>
        queryClient.setQueryData<any>(
          ['eventTimingLocationGatings', device._id],
          device,
        ),
      );
      return retVal.data;
    },
  });

  return queryResult;
};
