import { Button, Spinner } from '@lanaco/lnc-react-ui';

import styles from './SubmitModal.module.css';

type Props = {
  buttonText: any;
  closeModal: any;
  spinner: any;
};

const SubmitModal = (props: Props) => {
  return (
    <div className={styles.buttons}>
      <Button
        btnType={'outline'}
        color={'neutral'}
        type="button"
        onClick={props.closeModal}
      >
        Cancel
      </Button>
      <Button
        btnType={'filled'}
        color={!props.spinner ? 'primary' : 'neutral'}
        disabled={props.spinner}
      >
        {!props.spinner && props.buttonText}
        {props.spinner && <Spinner size={'small'} color={'secondary'} />}
      </Button>
    </div>
  );
};

export default SubmitModal;
