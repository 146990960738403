import clsx from 'clsx';
import { ReactNode, CSSProperties } from 'react';

import styles from './NumberInputError.module.scss';

export interface NumberInputErrorProps {
  message: ReactNode;
  className?: string;
  largeMessage?: boolean;
  style?: CSSProperties;
}

export const NumberInputError = ({
  className,
  style,
  message,
  largeMessage,
}: NumberInputErrorProps) => {
  return (
    <span
      style={style}
      className={clsx(
        styles.inputError,
        largeMessage && styles.largeMessage,
        className,
      )}
    >
      {message}
    </span>
  );
};
