import { notification } from '@lanaco/lnc-react-ui';
import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  Overlay,
  Popover,
  PopoverDropdown,
  ScrollArea,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconSearch, IconX } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { ButtonColor, ButtonType, TLButton } from 'components/Button';
import { ButtonActionGroup } from 'components/ButtonActionGroup';
import { useMutateAddAdmission } from 'hooks/event/admission/useMutateAddAdmission';
import { useFetchEventParticipants } from 'hooks/event/participants';
import { eventParticipantBaseKey } from 'hooks/event/participants/eventParticipantBaseKey';
import { useFetchEventTags } from 'hooks/event/tags';
import { eventTagBaseKey } from 'hooks/event/tags/baseKeys';
import { useRef, useState } from 'react';
// import QrReader from 'react-qr-reader';
import { client } from 'services/api';
import type { Response } from 'types';
import { GET_EVENT_PARTICIPANTS, GET_EVENT_TAGS } from 'utils/apiUrl';
import { getErrorMsg } from 'utils/getErrorMsg';
import { wait } from 'utils/wait';

import type { EventParticipantTableData } from '../Participants/EventParticipants.interface';
import type { EventTagTableData } from '../Tags/EventTag.interface';

import type { IFormInput } from './interfaces';
import QrReader from './QRReader';
import styles from './ScanTagParticipantForm.module.scss';

interface Props {
  eventId: string;
  onClose: any;
}

const RECORD_LIMIT = 100;
const ScanTagParticipantForm = ({ eventId, onClose }: Props) => {
  const queryClient = useQueryClient();
  const participantScanButtonRef = useRef<HTMLButtonElement>(null);
  const participantInputRef = useRef<HTMLInputElement>(null);
  const tagInputRef = useRef<HTMLInputElement>(null);
  const form = useForm<IFormInput>();
  const [
    _participantPopoverOpen,
    participantPopoverCallbacks,
  ] = useDisclosure();
  const [_tagPopoverOpen, tagPopoverCallbacks] = useDisclosure();
  const [didScan, didScanCallbacks] = useDisclosure();
  const [autoSubmit, setAutoSubmit] = useState(false);

  const [participantFilter, setParticipantFilter] = useState('');
  const [tagFilter, setTagFilter] = useState('');
  const [selected] = useState<'environment' | 'user'>('environment');
  const [startTagScan, setStartTagScan] = useState(false);
  const [startParticipantScan, setStartParticipantScan] = useState(false);

  const eventParticipants = useFetchEventParticipants({
    limit: RECORD_LIMIT,
    eventId: eventId as string,
    filter: participantFilter,
    enabled: !!participantFilter && participantFilter.length > 0,
  });
  const eventTags = useFetchEventTags({
    row_limit: RECORD_LIMIT,
    eventId: eventId as string,
    filter: tagFilter,
    enabled: !!tagFilter && tagFilter.length > 0,
  });

  const participantPopoverOpen =
    ((eventParticipants.data?._route.returned || 0) as number) > 1 &&
    _participantPopoverOpen;
  const tagPopoverOpen =
    (eventTags.data?.data.length || 0) > 1 && _tagPopoverOpen;

  const mutation = useMutateAddAdmission();

  const onConfirm = ({
    participantValue: externalId,
    tagValue: tagNumber,
    participantId,
    tagId,
  }: IFormInput) => {
    if ((!participantId && !externalId) || (!tagId && !tagNumber)) {
      notification.error('Please scan participant and tag');
      return;
    }

    mutation.mutate(
      {
        tagId,
        eventId,
        participantId,
        participantExternalId: externalId,
        tagNumber,
      },
      {
        onSuccess: (response) => {
          notification.success(
            <Text>
              Admission
              <br />
              {response?.data.participant.firstLastName}
              <br />
              Tag: {response?.data.eventTag.number}
            </Text>,
            {
              autoClose: 5000,
            },
          );
          form.setValues({
            participantValue: '',
            tagValue: '',
            participantId: '',
            tagId: '',
          });
          setStartParticipantScan(false);
          setStartTagScan(false);
          setParticipantFilter('');
          setTagFilter('');
          participantScanButtonRef.current?.focus();
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
      },
    );
  };

  const handleClearParticipant = () => {
    form.setFieldValue('participantValue', '');
    setParticipantFilter('');
    participantInputRef.current?.select();
  };

  const handleSearchParticipant = async (
    _event?: unknown | null,
    override?: string,
    autofocus = true,
  ) => {
    const participantValue =
      override || form.getTransformedValues().participantValue;

    if (participantValue) {
      const response = await client.get<Response<EventParticipantTableData[]>>(
        GET_EVENT_PARTICIPANTS(eventId, RECORD_LIMIT, 0, participantValue),
      );
      queryClient.setQueryData(
        [eventParticipantBaseKey, eventId, RECORD_LIMIT, 0, participantValue],
        response,
        { updatedAt: Date.now() },
      );
      setParticipantFilter(participantValue);

      const participants = response.data?.data;

      if (participants && participants.length === 1) {
        form.setFieldValue('participantValue', participants[0].externalId!);
        form.setFieldValue('participantId', participants[0]._id!);
        if (autofocus) {
          tagInputRef.current?.select();
        }
        return participants[0];
      } else if (!participants || participants.length === 0) {
        notification.error('Participant not found');
        form.setFieldValue('participantId', '');
        if (autofocus) {
          tagInputRef.current?.select();
        }
      } else if (participants.length > 1) {
        notification.error('Multiple participants found');
        form.setFieldValue('participantId', '');
        participantPopoverCallbacks.open();
      }
    } else {
      notification.error('Please scan participant');
    }

    return null;
  };

  const handleSelectParticipant = (participant: EventParticipantTableData) => {
    form.setFieldValue('participantValue', participant.externalId!);
    form.setFieldValue('participantId', participant._id!);
    setParticipantFilter(participant.externalId!);
    participantPopoverCallbacks.close();

    if (didScan) {
      didScanCallbacks.close();
      setStartTagScan(true);
    }
    tagInputRef.current?.select();
  };

  const handleSelectTag = (tag: EventTagTableData) => {
    form.setFieldValue('tagValue', tag.number);
    form.setFieldValue('tagId', tag.tagId);
    setTagFilter(tag.number);
    tagPopoverCallbacks.close();

    if (autoSubmit) {
      form.onSubmit(onConfirm)();
    }
  };

  const handleSearchTag = async (
    _event?: unknown | null,
    override?: string,
  ) => {
    const tagValue = override || form.getTransformedValues().tagValue;

    if (tagValue) {
      const response = await client.get<Response<EventTagTableData[]>>(
        GET_EVENT_TAGS(eventId, RECORD_LIMIT, 0, tagValue),
      );
      queryClient.setQueryData(
        [eventTagBaseKey, eventId, RECORD_LIMIT, 0, tagValue],
        response,
        { updatedAt: Date.now() },
      );

      setTagFilter(tagValue);

      const tags = response.data?.data;
      if (tags?.length === 1) {
        form.setFieldValue('tagValue', tags[0].number);
        form.setFieldValue('tagId', tags[0].tagId);

        return tags[0];
      } else if (!tags || tags.length === 0) {
        notification.error('Tag not found');
        form.setFieldValue('tagId', '');
      } else if (tags.length > 1) {
        notification.error('Multiple tags found');
        form.setFieldValue('tagId', '');
        tagPopoverCallbacks.open();
      }
    } else {
      notification.error('Please scan tag');
    }
  };

  const handleParticipantScan = async (scanData) => {
    if (scanData && scanData !== '') {
      form.setFieldValue('participantValue', scanData);
      setStartParticipantScan(false);
      didScanCallbacks.open();

      await handleSearchParticipant(null, scanData, false);
      await wait(1000);
      setStartTagScan(true);
    }
  };

  const handleTagScan = async (scanData: string) => {
    if (scanData && scanData !== '' && scanData.length >= 17) {
      form.setFieldValue('tagValue', scanData.trim().slice(-17));

      if (!form.values.participantValue) {
        notification.error('Please scan participant');
        return;
      }

      setStartTagScan(false);

      if (await handleSearchTag(null, scanData)) {
        if (autoSubmit) {
          await wait(500);
          form.onSubmit(onConfirm)();
        }
      }
    }
  };

  const handleTagKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearchTag().then(async (response) => {
        if (response && autoSubmit) {
          await wait(500);
          form.onSubmit(onConfirm)();
        }
      });
    }
  };

  const handleParticipantKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearchParticipant();
    }
  };

  const handleClearTag = () => {
    form.setFieldValue('tagValue', '');
    form.setFieldValue('tagId', '');
    setTagFilter('');
    tagInputRef.current?.select();
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <form className={styles.form} onSubmit={form.onSubmit(onConfirm)}>
      {(participantPopoverOpen || tagPopoverOpen) && (
        <Overlay radius="lg" color="#000" backgroundOpacity={0.2} />
      )}
      <div className={styles.formContainer}>
        <div className={styles.scanInputGroupContainer}>
          <TLButton
            btnType={ButtonType.FILLED}
            color={ButtonColor.PRIMARY}
            ref={participantScanButtonRef}
            onClick={(e) => {
              e.preventDefault();
              setStartParticipantScan(!startParticipantScan);
              setStartTagScan(false);
              if (!startParticipantScan) {
                form.setFieldValue('participantValue', '');
              }
            }}
          >
            {startParticipantScan ? 'Stop Scan' : 'Start Scan'}
          </TLButton>
          <div className={styles.container}>
            <ActionIcon size={36} onClick={handleClearParticipant}>
              <IconX />
            </ActionIcon>
            <div className={styles.inputItemContainer}>
              <Popover
                trapFocus
                opened={participantPopoverOpen}
                width="target"
                shadow="xl"
                offset={0}
                zIndex={901}
              >
                <Popover.Target>
                  <TextInput
                    ref={participantInputRef}
                    name="participantValue"
                    placeholder="Participant"
                    className={styles.inputItem}
                    {...form.getInputProps('participantValue')}
                    onKeyDown={handleParticipantKeyDown}
                    onChange={(e) => {
                      form.setFieldValue('participantId', '');
                      form.setFieldValue(e.target.name, e.target.value);
                      setParticipantFilter('');
                    }}
                  />
                </Popover.Target>
                <PopoverDropdown>
                  <Stack gap="xs">
                    <Group justify="space-between" wrap="nowrap">
                      <Text c="red" fz="sm">
                        {(eventParticipants.data?.data.length || 0) > 1 &&
                          `WARNING: ${eventParticipants.data?.data.length} participants found`}
                      </Text>

                      <ActionIcon
                        bg="red"
                        c="white"
                        onClick={participantPopoverCallbacks.close}
                      >
                        <IconX />
                      </ActionIcon>
                    </Group>
                    <ScrollArea.Autosize mah={300}>
                      <Stack py="xs" px="sm">
                        {eventParticipants.data?.data.map(
                          (participant, index) => (
                            <Button
                              key={participant._id}
                              color="gray"
                              autoFocus={index === 0}
                              onClick={() =>
                                handleSelectParticipant(participant)
                              }
                            >
                              {participant.firstLastName}
                            </Button>
                          ),
                        )}
                      </Stack>
                    </ScrollArea.Autosize>
                  </Stack>
                </PopoverDropdown>
              </Popover>

              {form.values.participantId && (
                <Text c="black">
                  {
                    eventParticipants.data?.data.find(
                      (participant) =>
                        participant._id === form.values.participantId,
                    )?.firstLastName
                  }{' '}
                  selected
                </Text>
              )}
              {!form.values.participantId &&
                !!participantFilter &&
                eventParticipants.data?.data &&
                eventParticipants.data.data.length > 1 && (
                  <Text
                    c={eventParticipants.data.data.length > 1 ? 'red' : 'black'}
                    fz="sm"
                  >
                    WARNING: {eventParticipants.data.data.length} participants
                    found
                  </Text>
                )}
            </div>

            <ActionIcon
              size={36}
              loading={eventParticipants.isFetching}
              onClick={handleSearchParticipant}
            >
              <IconSearch />
            </ActionIcon>
          </div>
        </div>
        {startParticipantScan && (
          <QrReader
            preferredCamera={selected}
            onError={handleError}
            onScan={handleParticipantScan}
          />
          // <QrReader
          //   className={styles.qrReader}
          //   facingMode={selected}
          //   delay={1000}
          //   onError={handleError}
          //   onScan={handleParticipantScan}
          // />
        )}

        <hr />

        <div className={styles.scanInputGroupContainer}>
          <TLButton
            btnType={ButtonType.FILLED}
            color={ButtonColor.PRIMARY}
            onClick={(e) => {
              e.preventDefault();
              setStartTagScan(!startTagScan);
              setStartParticipantScan(false);
              if (!startTagScan) {
                form.setFieldValue('tagValue', '');
              }
            }}
          >
            {startTagScan ? 'Stop Scan' : 'Start Scan'}
          </TLButton>
          <div className={styles.container}>
            <ActionIcon size={36} onClick={handleClearTag}>
              <IconX />
            </ActionIcon>
            <div className={styles.inputItemContainer}>
              <Popover
                trapFocus
                width="target"
                opened={tagPopoverOpen}
                offset={0}
                shadow="xl"
                zIndex={901}
                position="top"
              >
                <Popover.Target>
                  <TextInput
                    ref={tagInputRef}
                    name="tagValue"
                    placeholder="Tag"
                    className={styles.inputItem}
                    {...form.getInputProps('tagValue')}
                    onKeyDown={handleTagKeyDown}
                    onChange={(e) => {
                      form.setFieldValue('tagId', '');
                      form.setFieldValue(e.target.name, e.target.value);
                      setTagFilter('');
                    }}
                  />
                </Popover.Target>
                <PopoverDropdown>
                  <Stack gap="xs">
                    <Group justify="space-between" wrap="nowrap">
                      <Text c="red" fz="sm">
                        {(eventTags.data?.data.length || 0) > 1 &&
                          `WARNING: ${eventTags.data?.data.length} tags found`}
                      </Text>

                      <ActionIcon
                        bg="red"
                        c="white"
                        onClick={tagPopoverCallbacks.close}
                      >
                        <IconX />
                      </ActionIcon>
                    </Group>
                    <ScrollArea.Autosize mah={300}>
                      <Stack py="xs" px="sm">
                        {eventTags.data?.data.map((tag, index) => (
                          <Button
                            key={tag._id}
                            color="gray"
                            autoFocus={index === 0}
                            onClick={() => handleSelectTag(tag)}
                          >
                            {tag.number}
                          </Button>
                        ))}
                      </Stack>
                    </ScrollArea.Autosize>
                  </Stack>
                </PopoverDropdown>
              </Popover>
              {form.values.tagId && (
                <Text c="black">
                  {
                    eventTags.data?.data.find(
                      (tag) => tag.tagId === form.values.tagId,
                    )?.uniqueId
                  }{' '}
                  selected
                </Text>
              )}
              {!form.values.tagId &&
                !!tagFilter &&
                eventTags.data?.data &&
                eventTags.data.data.length > 1 && (
                  <Text
                    c={eventTags.data.data.length > 1 ? 'red' : 'black'}
                    fz="sm"
                  >
                    WARNING: {eventTags.data.data.length} tags found
                  </Text>
                )}
            </div>

            <ActionIcon
              size={36}
              loading={eventTags.isFetching}
              onClick={handleSearchTag}
            >
              <IconSearch />
            </ActionIcon>
          </div>
          {startTagScan && (
            <QrReader
              preferredCamera={selected}
              onError={handleError}
              onScan={handleTagScan}
            />
            // <QrReader
            //   className={styles.qrReader}
            //   facingMode={selected}
            //   delay={1000}
            //   onError={handleError}
            //   onScan={handleTagScan}
            // />
          )}
        </div>
      </div>
      <Stack align="end">
        <Checkbox
          label="Auto submit"
          checked={autoSubmit}
          onChange={() => setAutoSubmit(!autoSubmit)}
        />
        <ButtonActionGroup
          className={styles.buttonActionGroup}
          buttonProps={[
            {
              id: 'cancel',
              text: 'Cancel',
              btnType: ButtonType.OUTLINE,
              color: ButtonColor.NEUTRAL,
              onClick: onClose,
              type: 'button',
            },
            {
              id: 'confirm',
              text: 'Register',
              type: 'submit',
              btnType: ButtonType.FILLED,
              color: ButtonColor.PRIMARY,
            },
          ]}
        />
      </Stack>
    </form>
  );
};

export default ScanTagParticipantForm;
