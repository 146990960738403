import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { client } from 'services/api';
import { Response } from 'types';
import { DocumentStatus } from 'types/enums';
import { GET_CLIENTS_STATISTICS } from 'utils/apiUrl';

import { clientStatisticsBaseKey } from './baseKeys';
import { statisticsRefetchInterval } from './statisticsRefetchInterval';

export type Client = {
  _id: string;
  client: string;
  name: string;
  status: DocumentStatus;
  totalEvents: number;
};

export type ClientStatistics = Client[];

export const useFetchClientStatistics = () => {
  const queryResult = useQuery<Response<Client[]>, AxiosError>({
    queryKey: [clientStatisticsBaseKey],
    refetchInterval: statisticsRefetchInterval,
    queryFn: async () => {
      const retVal = await client.get<Response<Client[]>>(
        GET_CLIENTS_STATISTICS(),
      );

      return retVal.data;
    },
  });

  return queryResult;
};
