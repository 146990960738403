import { notification } from "@lanaco/lnc-react-ui";
import { useMutateTimingLocation } from "hooks/event/timing-location/useMutateTimingLocation";
import { useNavigate } from "react-router-dom";
import { createDateAndTime } from "utils/createDateAndTime";
import { formatDateTime } from "utils/dateFormatters";
import { getErrorMsg } from "utils/getErrorMsg";

import { MultiStepForm } from "../MultiStepForm/MultiStepForm";
import { MultiStepFormHeader } from "../MultiStepForm/MultiStepFormHeader/MultiStepFormHeader";
import {
	type ContactPersonSchema,
	type CreateTimingLocationSchema,
	type DetailsSchema,
	type OrganisationInfoSchema,
	type OrganisationSocialMediaSchema,
	type PositionSchema,
	createTimingLocationSchema,
} from "../MultiStepForm/timingLocation-schema";

import ContactPersonForm from "./ContactPersonForm";
import DetailsForm from "./DetailsForm";
import OrganisationInfoForm from "./OrganisationInfoForm";
import OrganisationSocialMediaForm from "./OrganisationSocialMediaForm";
import PositionForm from "./PositionForm";
import type { EventTimingLocations } from "./TimingLocations.interfaces";
type TimingLocationFormProps = {
	onClose?: () => void;

	data: EventTimingLocations | null;
	isEdit: boolean;
	eventId: any;
	timezone: string;
	fromDetails?: boolean;
};

const stepLabels = [
	"Details",
	"Position",
	"Organisation info",
	"Organisation social media",
	"Contact person",
];

const TimingLocationForm = ({
	data,
	isEdit,
	onClose,
	eventId,
	timezone,
	fromDetails,
}: TimingLocationFormProps) => {
	const initDetails: DetailsSchema = {
		name: data?.name || "",
		type: data?.type || "",
		startDateTime:
			data?.startDateTime !== undefined
				? new Date(
						formatDateTime(
							new Date(data?.startDateTime).toISOString(),
							timezone!,
						).date,
					)
				: undefined,

		endDateTime:
			data?.endDateTime !== undefined
				? new Date(
						formatDateTime(new Date(data?.endDateTime).toISOString(), timezone!)
							.date,
					)
				: undefined,
		capacity: data?.capacity || 0,
		startTime:
			data?.startDateTime !== undefined
				? formatDateTime(new Date(data?.startDateTime).toISOString(), timezone!)
						.time
				: "",
		endTime:
			data?.endDateTime !== undefined
				? formatDateTime(new Date(data?.endDateTime).toISOString(), timezone!)
						.time
				: "",
	};

	const initPositionData: PositionSchema = {
		layout: data?.eventLayout?._id || "",
		coordinates: {
			lat: data?.coordinates?.lat,
			lng: data?.coordinates?.lng,
		},
	};
	const sectionId = data?._id;

	const initOrganisationInfoData: OrganisationInfoSchema = {
		name: data?.information.name || "",
		description: data?.information.description || "",
		email: data?.information.email || "",
		phoneNumber: data?.information.phoneNumber || "",
		website: data?.information.website || "",
		country: data?.information.country || "",
		postalCode: data?.information.postalCode || "",
		address: data?.information.address || "",
	};

	const initOrganisationSocialMediaData: OrganisationSocialMediaSchema = {
		facebook: data?.information.socialMedia.facebookURL || "",
		instagram: data?.information.socialMedia.instagramURL || "",
		linkedin: data?.information.socialMedia.linkedInURL || "",
		tikTok: data?.information.socialMedia.tikTokURL || "",
		twitter: data?.information.socialMedia.twitterURL || "",
		youtube: data?.information.socialMedia.youtubeURL || "",
	};

	const initContactInfoData: ContactPersonSchema = {
		name: data?.information.contactPerson[0]?.fullName || "",
		position: data?.information.contactPerson[0]?.position || "",
		email: data?.information.contactPerson[0]?.email || "",
		phoneNumber: data?.information.contactPerson[0]?.phoneNumber || "",
	};

	const initCreateData: CreateTimingLocationSchema = {
		details: initDetails,
		position: initPositionData,
		organisationInfo: initOrganisationInfoData,
		organisationSocialMedia: initOrganisationSocialMediaData,
		contactPerson: initContactInfoData,
	};

	const mutation = useMutateTimingLocation(
		eventId,
		data?._id,
		data?.eventLayout._id, //this is old event layout id used in update
	);

	const navigate = useNavigate();
	const onSuccessHandler = async (data: CreateTimingLocationSchema) => {
		const prepareCreateTimingLocationObj = {
			name: data.details.name,
			type: data.details.type,
			capacity: data.details.capacity !== undefined ? data.details.capacity : 0,
			startDateTime:
				data?.details?.startDateTime! &&
				createDateAndTime(
					data?.details?.startDateTime!,
					data?.details?.startTime,
					timezone,
				),
			endDateTime:
				data?.details?.endDateTime! &&
				createDateAndTime(
					data?.details?.endDateTime!,
					data?.details?.endTime,
					timezone,
				),
			layout: data.position.layout,
			coordinates: {
				lat: data.position.coordinates?.lat as number,
				lng: data.position.coordinates?.lng as number,
			},

			sectionForInformation: {
				name: data.organisationInfo.name,
				description: data.organisationInfo.description,
				socialMedia: [
					{
						orderNumber: 0,
						code: "",
						title: "",
						shortDescription: "",
						url: data.organisationSocialMedia.facebook,
					},
				],
				website: data.organisationInfo.website ?? "",
				email: data.organisationInfo.email ?? "",
				phoneNumber: data.organisationInfo.phoneNumber ?? "",
				country: data.organisationInfo.country,
				address: data.organisationInfo.address,
				postalCode: data.organisationInfo.postalCode ?? "",
				contactPerson: [
					{
						fullName: data.contactPerson.name,
						position: data.contactPerson.position,
						phoneNumber: data.contactPerson.phoneNumber ?? "",
						email: data.contactPerson.email ?? "",
					},
				],
			},
		};

		mutation.mutate(prepareCreateTimingLocationObj, {
			onSuccess: () => {
				notification.success(
					`Timing location successfully ${isEdit ? "updated" : "created"}.`,
				);

				onClose?.();
				if (fromDetails!) {
					const newPath = `/event/${eventId}/layout/${prepareCreateTimingLocationObj.layout}/section/${sectionId}`;
					navigate(newPath);
					window.location.reload();
				}
			},
			onError: (error) => {
				notification.error(getErrorMsg(error));
			},
		});
	};

	return (
		<MultiStepForm
			formProps={{ defaultValues: initCreateData, mode: "all" }}
			schema={createTimingLocationSchema}
			header={
				<MultiStepFormHeader
					title={isEdit ? "Edit timing location" : "Create timing location"}
					stepLabels={stepLabels}
					onClose={onClose}
				/>
			}
			totalSteps={5}
			onSuccess={onSuccessHandler}
		>
			<DetailsForm defaultValues={initDetails} step={1} onCancel={onClose} />
			<PositionForm
				defaultValues={initPositionData}
				step={2}
				onCancel={onClose}
				eventId={eventId}
			/>

			<OrganisationInfoForm
				defaultValues={initOrganisationInfoData}
				step={3}
				onCancel={onClose}
			/>

			<OrganisationSocialMediaForm
				step={4}
				defaultValues={initOrganisationSocialMediaData}
			/>

			<ContactPersonForm defaultValues={initContactInfoData} step={5} />
		</MultiStepForm>
	);
};

export default TimingLocationForm;
