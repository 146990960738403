export const parseJwt = (token: string) => {
  try {
    window.Buffer = window.Buffer || require('buffer').Buffer;
    let base64Payload = token.split('.')[1];
    let payloadBuffer = window.Buffer.from(base64Payload, 'base64');

    return JSON.parse(payloadBuffer.toString());
  } catch (e) {
    console.log(e);
    return null;
  }
};
