import {
  FieldValues,
  Path,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

export const useDateField = <T extends FieldValues, K extends Path<T>>({
  names,
  setValue,
  watch,
}: {
  names: K[];
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
}) => {
  const values = watch(names).reduce((acc, curr, index) => {
    acc[names[index]] = curr; //|| new Date();
    return acc;
  }, {} as Record<K, T[K] | Date>);

  const onDropdownValueChange = (date, event) => {
    let name = event?.target?.name;

    if (!name) {
      name = event?.target?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement
        ?.querySelector('input')
        ?.getAttribute('name');
    }

    if (!name) {
      return;
    }

    setValue(name, date, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  return [values, onDropdownValueChange] as const;
};
