import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { Box, Button, Image as MantineImage, Stack, Text } from '@mantine/core';
import { Dropzone as MantineDropzone } from '@mantine/dropzone';
import { useElementSize } from '@mantine/hooks';
import { useEffect, useState } from 'react';

import { DropzoneProps } from './types';

const Dropzone = ({
  height = '10rem',
  initialImage = null,
  setImageFile,
  openRef,
}: DropzoneProps) => {
  const [image, setImage] = useState<string | null>(null);
  const { ref: boxRef, height: imgHeight, width: imgWidth } = useElementSize();

  const handleDrop = (file: Array<File>) => {
    if (file.length > 0) {
      setImageFile(file[0]);
      setImage(URL.createObjectURL(file[0]));
    }
  };

  useEffect(() => {
    setImage(initialImage);
  }, [initialImage]);

  return image ? (
    <Box
      h={height}
      bd="1.5px solid #e2e8f0"
      pos="relative"
      style={{ borderRadius: '0.5rem', overflow: 'hidden' }}
      ref={boxRef}
    >
      <MantineImage
        src={image}
        rel="Template picture"
        h={imgHeight}
        w={imgWidth}
        fit="fill"
      />
      <Button
        bg="#ffffff"
        c="#f43f5e"
        bd="1px solid #e2e8f0"
        pos="absolute"
        top="1rem"
        left="1rem"
        onClick={() => setImage(null)}
      >
        Clear image
      </Button>
      <Button
        bg="#ffffff"
        c="#3b82f6"
        bd="1px solid #e2e8f0"
        pos="absolute"
        top="1rem"
        right="1rem"
        onClick={() => {
          openRef.current?.();
        }}
      >
        Upload another image
      </Button>
    </Box>
  ) : (
    <MantineDropzone
      h={height}
      openRef={openRef}
      onDrop={handleDrop}
      accept={['image/png', 'image/jpeg']}
      maxSize={5 * 1024 ** 2}
      activateOnClick={false}
      styles={{ inner: { height } }}
    >
      <Stack
        h={height}
        p="1.25rem"
        bd="1.5px dashed #cbd5e1"
        gap="0.25rem"
        align="center"
        justify="center"
        style={{
          borderRadius: '0.5rem',
        }}
      >
        <CloudArrowUpIcon
          height="3rem"
          width="3rem"
          color="#94a3b8"
          strokeWidth={0.8}
        />

        <Box>
          <Text
            span
            c="#3b82f6"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              openRef.current?.();
            }}
          >
            Click to upload{' '}
          </Text>
          <Text span c="#344155">
            or drag and drop
          </Text>
        </Box>

        <Text fz={12} c="#64748b">
          JPEG or PNG file.
        </Text>
      </Stack>
    </MantineDropzone>
  );
};

export default Dropzone;
