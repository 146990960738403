import {
  Cell,
  flexRender,
  RowData,
  SortDirection,
  Table,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { Icon } from 'components/Icon/Icon';
import { ReactNode } from 'react';

import styles from '../TanStackTable.module.scss';

export type TableMarkupProps<T extends RowData> = {
  table: Table<T>;
  rowClassName?: string | ((row: T) => string | undefined);
  cellClassName?: string | ((cell: Cell<T, unknown>) => string);
  tableClassName?: string;
};

const sortDirectionIconMap: Record<SortDirection, ReactNode> = {
  asc: <Icon icon="arrow-up" className={styles.sortIcon} />,
  desc: <Icon icon="arrow-down" className={styles.sortIcon} />,
};

export function TableMarkup<T extends RowData>({
  table,
  rowClassName,
  cellClassName,
  tableClassName,
}: TableMarkupProps<T>) {
  const headerGroups = table.getHeaderGroups();

  return (
    <table className={tableClassName}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                className={clsx({
                  [styles.statusIndicatorHeader]:
                    header.column.id === 'statusIndicator',
                  [styles.sortableColumn]: header.column.getCanSort(),
                })}
                style={{
                  width: header.getSize(),
                }}
                colSpan={header.colSpan}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
                {
                  sortDirectionIconMap[
                    header.column.getIsSorted() as SortDirection
                  ]
                }
                <div
                  className={clsx(styles.headerDivider, {
                    [styles.resizer]: header.column.getCanResize(),
                  })}
                  onMouseDown={header.getResizeHandler()}
                  onTouchStart={header.getResizeHandler()}
                />
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className={
              typeof rowClassName === 'function'
                ? rowClassName(row.original)
                : rowClassName
            }
          >
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className={clsx(
                  'tanstack-table-cell',
                  typeof cellClassName === 'function'
                    ? cellClassName(cell)
                    : cellClassName,
                )}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TableMarkup;
