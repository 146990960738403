import { DonutChart } from '@mantine/charts';
import { Box, Flex, Stack, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

import { TemperatureAndHumidityDonutChartProps } from '../types';

const TemperatureAndHumidityDonutChart = ({
  title,
  circles,
  lowest,
  highest,
}: TemperatureAndHumidityDonutChartProps) => {
  const chartData = [
    { name: 'Segment 1', value: 30, color: '#3B82F6' },
    { name: 'Segment 2', value: 25, color: '#60A5FA' },
    { name: 'Segment 3', value: 20, color: '#FACC15' },
    { name: 'Segment 4', value: 20, color: '#EAB308' },
    { name: 'Segment 5', value: 25, color: '#FB7185' },
    { name: 'Segment 6', value: 20, color: '#F43F5E' },
  ];
  const { height, width } = useViewportSize();
  const responsiveWidth = width * 0.3;
  const responsiveHeight = height * 0.43;
  const responsiveSize = Math.min(responsiveWidth, responsiveHeight);

  return (
    <Stack
      gap="2rem"
      py="1rem"
      px="1.5rem"
      bg="#FFFFFF"
      bd="1px solid #0D0A2C14"
      style={{ borderRadius: '1.25rem' }}
    >
      <Text fw={600} fz="1.125rem" c="#0F172A">
        {title}
      </Text>

      <Flex
        justify="center"
        style={{
          overflow: 'hidden',
          height: '250px',
          position: 'relative',
        }}
      >
        {height && width && (
          <DonutChart
            h={responsiveHeight}
            w={responsiveWidth}
            size={responsiveSize}
            data={chartData}
            startAngle={180}
            endAngle={0}
            paddingAngle={5}
            thickness={25}
            pieProps={{
              cornerRadius: 10,
            }}
            withTooltip={false}
          />
        )}

        <Flex
          pos="absolute"
          top="70%"
          left="50%"
          style={{ transform: 'translate(-50%, -50%)' }}
          align="center"
          gap="3.3rem"
        >
          <Stack gap="0.005rem" justify="center" ta="center">
            <Text fz="1.125rem" c="#475569" fw={400}>
              Lowest
            </Text>
            <Text c="#1E293B" fz="2rem" fw={700}>
              {lowest}
            </Text>
          </Stack>

          <Stack gap="0.005rem" justify="center" ta="center">
            <Text fz="1.125rem" c="#475569" fw={400}>
              Highest
            </Text>
            <Text c="#1E293B" fz="2rem" fw={700}>
              {highest}
            </Text>
          </Stack>
        </Flex>
      </Flex>

      <Flex gap="1.5rem" justify="center">
        {circles.map((circle, index) => (
          <Flex key={index} align="center" gap="0.5rem">
            <Box
              w="1rem"
              h="1rem"
              bg={circle.color}
              style={{ borderRadius: '50%' }}
            />
            <Text ta="center" fz="0.875rem" c="#475569" fw={400}>
              {circle.text}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Stack>
  );
};

export default TemperatureAndHumidityDonutChart;
