import {
  Dropdown,
  FlexBox,
  FlexGrid,
  FlexGridItem,
  FormField,
  TextInput,
  notification,
} from '@lanaco/lnc-react-ui';
import { useQueryClient } from '@tanstack/react-query';
import SubmitModal from 'components/SubmitModal/SubmitModal';
import { deviceBaseKey } from 'hooks/device/baseKeys';
import { deviceStatisticsBaseKey } from 'hooks/event/statistics/baseKeys';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { client } from 'services/api';
import { INSERT_DEVICE, UPDATE_DEVICE } from 'utils/apiUrl';
import { statusOptions } from 'utils/dropdown-options';

import styles from './Device.module.css';
import { IFormInput } from './interfaces';

type Props = {
  closeModal: any;
  data: any;
  isEdit: boolean;
};

const DeviceForm = (props: Props) => {
  const [spinner, setSpinner] = useState(false);
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormInput>();
  //   {
  //   defaultValues: {
  //     id: props.data.id
  //   }
  // });

  useEffect(() => {
    reset(props.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);
  //const navigate = useNavigate();
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    try {
      setSpinner(true);
      if (props.isEdit) {
        const response = await client.put(UPDATE_DEVICE(data.id), data);

        if (response.data.code === 201) {
          setSpinner(false);
          props.closeModal();
          notification.success('Device successfully updated');
        } else {
          setSpinner(false);
          notification.error(
            'Unable to update device. ' + response.data.message,
          );
        }
      } else {
        const response = await client.post(INSERT_DEVICE, data);
        if (response.data.code === 201) {
          setSpinner(false);
          props.closeModal();
          //window.location.reload();
          //window.location.href = '/devices';
          notification.success('Device successfully added.');
          // setTimeout(() => {
          //   window.location.reload();
          // }, 4000);
        } else {
          setSpinner(false);
          notification.error('Unable to add device. ' + response.data.message);
        }
      }
    } catch (error) {
      setSpinner(false);
      notification.error(error);
    } finally {
      queryClient.invalidateQueries([deviceBaseKey]);
      queryClient.invalidateQueries([deviceStatisticsBaseKey]);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FlexGrid spacing={10}>
        <input
          type="hidden"
          {...register('id')}
          value={props.data && props.data.id}
        />

        <FlexGridItem XS={12} S={12} className={styles.labelText}>
          <FormField
            label="Device name"
            text={errors.macName && <span>{errors.macName.message}</span>}
            color={errors.macName && 'danger'}
          >
            <TextInput
              placeholder={'e.g. Main receiver'}
              {...register('macName', {
                required: {
                  value: true,
                  message: 'Field Mac name is requied.',
                },
              })}
              color={errors.macName && 'danger'}
              value={props.data && props.data.macName}
            />
          </FormField>
        </FlexGridItem>
        <FlexGridItem XS={12} S={6}>
          <FormField label="Manufacturer">
            {/* <Dropdown
            {...register('manufacturer')}
            placeholder={'John'}
            options={[
              {
                value: '1',
                label: 'option 1'
              },
              {
                value: '2',
                label: 'option 2'
              },
              {
                value: '3',
                label: 'option 3'
              }
            ]}
          /> */}
            <TextInput
              placeholder={'e.g. ?'}
              {...register('manufacturer')}
              value={props.data && props.data.manufacturer}
            />
          </FormField>
        </FlexGridItem>
        <FlexGridItem XS={12} S={6}>
          <FormField
            label="Mac address"
            text={errors.macAddress && <span>{errors.macAddress.message}</span>}
            color={errors.macAddress && 'danger'}
          >
            <TextInput
              placeholder={'e.g. 44-B6-Be-E5-8B-3C'}
              {...register('macAddress', {
                required: {
                  value: true,
                  message: 'Field Mac address is required.',
                },
              })}
              color={errors.macAddress && 'danger'}
              value={props.data && props.data.macAddress}
            />
          </FormField>
        </FlexGridItem>
        <FlexGridItem XS={12} S={6}>
          <FormField label="Location/warehouse">
            {/* <Dropdown
            {...register('location')}
            placeholder={'John'}
            options={[
              {
                value: '1',
                label: 'option 1'
              },
              {
                value: '2',
                label: 'option 2'
              },
              {
                value: '3',
                label: 'option 3'
              }
            ]}
          /> */}
            <TextInput
              placeholder={'e.g. ?'}
              {...register('location')}
              value={props.data && props.data.location}
            />
          </FormField>
        </FlexGridItem>
        <FlexGridItem XS={12} S={6}>
          <FormField label="GSM plan">
            <TextInput
              placeholder={'e.g. ?'}
              {...register('gsmPlan')}
              value={props.data && props.data.gsmPlan}
            />
          </FormField>
        </FlexGridItem>
        <FlexGridItem XS={12} S={6}>
          <FormField label="Status">
            <Dropdown
              {...register('status')}
              placeholder={'Active'}
              options={statusOptions}
            />
          </FormField>
        </FlexGridItem>
        <FlexGridItem XS={12} S={6}>
          <FormField label="GSM IMEI">
            <TextInput
              placeholder={'e.g. 862823014053740'}
              {...register('gsmImei')}
              value={props.data && props.data.gsmImei}
            />
          </FormField>
        </FlexGridItem>
        <FlexGridItem col={12}>
          <FlexBox justifyContent={'End'}>
            <SubmitModal
              closeModal={props.closeModal} //props.closeModal}
              spinner={spinner}
              buttonText={'Save'}
            ></SubmitModal>
          </FlexBox>
        </FlexGridItem>
      </FlexGrid>
    </form>
  );
};

export default DeviceForm;
