import { notification } from '@lanaco/lnc-react-ui';
import { createColumnHelper, RowSelectionState } from '@tanstack/react-table';
import { TLNeutralButton, TLSecondaryLoadingButton } from 'components/Button';
import { InputSearch } from 'components/Input';
import { TLModalFooter } from 'components/Modal';
import { NotAvailable } from 'components/NotAvailable';
import TanStackTable from 'components/TanStackTable/TanStackTable';
import { useFetchEventAssignableDevices } from 'hooks/event/devices';
import { useAddEventAssignableDevices } from 'hooks/event/devices/useAddEventAssignableDevices';
import { useState } from 'react';
import { getErrorMsg } from 'utils/getErrorMsg';

import style from '../Events.module.css';

import { EventDevice } from './EventDevices.interface';
const ROW_LIMIT = 10;
const columnHelper = createColumnHelper<EventDevice>();

type Props = {
  closeModal: any;
  eventName: string;
  eventId: string;
};

const EventDeviceForm = (props: Props) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const selectedIds = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    },
    [],
  );

  const { data, isFetching } = useFetchEventAssignableDevices({
    eventId: props.eventId,
    offset: (page - 1) * ROW_LIMIT,
    row_limit: ROW_LIMIT,
    filter: search,
  });

  const { _route, data: eventAssignableDevices } = data || {
    _route: { returned: 0, total: 0 },
    data: [],
  };
  console.log(eventAssignableDevices);
  const mutation = useAddEventAssignableDevices();
  const noDevicesAvailable =
    eventAssignableDevices?.length === 0 || eventAssignableDevices == null;

  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  const tableColumns = [
    columnHelper.accessor('macName', {
      header: 'Device',
    }),
    columnHelper.accessor('macAddress', {
      header: 'MAC address',
    }),
  ];

  const selectedDevices = `${selectedIds.length} ${
    selectedIds.length > 1 ? 'Devices' : 'Device'
  }`;

  const onAddHandler = async (e) => {
    e.preventDefault();
    setSelectedRows({});
    mutation.mutate(
      { eventId: props.eventId, filter: '', payload: selectedIds },
      {
        onSuccess: () => {
          notification.success(
            `${selectedDevices} added to event ${props.eventName}`,
          );
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
      },
    );
  };

  return (
    <div className={style.add_event_devices}>
      <InputSearch
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
      ></InputSearch>
      <form onSubmit={onAddHandler}>
        {noDevicesAvailable ? (
          <NotAvailable message="Oops, no devices available at the moment." />
        ) : (
          <TanStackTable
            getRowId={(row) => row._id}
            selectedRows={selectedRows}
            onRowSelectionChange={setSelectedRows}
            columns={tableColumns}
            pageIndex={page}
            pageSize={ROW_LIMIT}
            totalRows={_route.total}
            pageCount={Math.ceil(_route.total / ROW_LIMIT)}
            data={eventAssignableDevices || []}
            onPageChange={handlePageChange}
            isLoading={isFetching}
            rowClassName={(row) =>
              row.status === 'disabled' ? 'text-gray-400' : ''
            }
            enableRowSelection
            showHeader
          />
        )}
        <TLModalFooter
          style={{ paddingTop: '2rem' }}
          cancelButton={
            <TLNeutralButton onClick={props.closeModal}>Cancel</TLNeutralButton>
          }
          actionButton={
            <TLSecondaryLoadingButton
              isLoading={false}
              disabled={selectedIds?.length === 0}
              type="submit"
            >
              Add
            </TLSecondaryLoadingButton>
          }
          moreInfo={
            selectedDevices.length > 0 ? `${selectedDevices} selected` : ''
          }
        />
      </form>
    </div>
  );
};

export default EventDeviceForm;
