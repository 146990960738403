import { Link, useLocation } from 'react-router-dom';

import { MenuItemInterface } from './Sidebar.interface';
import style from './Sidebar.module.css';

const MenuItem = (menuItem: MenuItemInterface) => {
  let location = useLocation();
  return (
    <div
      className={
        location.pathname === menuItem.path
          ? style.menuItem + ' ' + style.active
          : style.menuItem
      }
      id={menuItem.name}
    >
      <Link
        className={
          location.pathname === menuItem.path
            ? style.link + ' ' + style.active
            : style.link
        }
        to={menuItem.path}
        onClick={menuItem.onClick}
      >
        <img src={menuItem.icon} alt={menuItem.name}></img>
        {menuItem.label}
      </Link>
    </div>
  );
};

export default MenuItem;
