import QrScanner from 'qr-scanner';
import { useEffect, useRef, useState } from 'react';

import QrFrame from '../../assets/qr-frame.svg';

type QRReaderProps = {
  onError: (err: Error | string) => void;
  onScan: (data: string) => void;
};

const CustomQrScanner: React.FC<QRReaderProps> = ({ onError, onScan }) => {
  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);

  const containerRef = useRef<HTMLDivElement>(null);

  const onScanSuccess = (result: QrScanner.ScanResult) => {
    onScan(result.data);
  };

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: (err) => onError(err),
        preferredCamera: 'environment',
        maxScansPerSecond: 5,
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn)
      alert(
        'Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.',
      );
  }, [qrOn]);

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: '100%',
        paddingTop: '70%',
        overflow: 'hidden',
        borderRadius: '12px',
        maxWidth: '500px',
        margin: '0 auto 12px',
      }}
    >
      <video
        ref={videoEl}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '12px',
        }}
      />
      <div
        ref={qrBoxEl}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '70%',
          maxWidth: '250px',
          pointerEvents: 'none',
        }}
      >
        <img
          src={QrFrame}
          alt="QR Frame"
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      </div>
    </div>
  );
};

export default CustomQrScanner;
