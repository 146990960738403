import { ScheduleCardProps } from 'components/ScheduleCard/types';
import { EntityScheduled } from 'services/entity/types';

export const mapEventScheduledToScheduleCard = (
  entityScheduled: EntityScheduled,
): ScheduleCardProps => {
  const startDate = new Date(entityScheduled.startDateTime);
  const endDate = new Date(entityScheduled.endDateTime);

  return {
    startDate: startDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }),
    startTime: startDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }),
    text: entityScheduled?.title ?? '-',
    name: entityScheduled?.hostName ?? '-',
    endTime: endDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }),
    endDate: endDate.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }),
    level: entityScheduled?.eventLayout?.name ?? '-',
    type: entityScheduled?.eventLayoutSection?.information?.name ?? '-',
  };
};
