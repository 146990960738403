import { Badge, TabItem, Tabs } from '@lanaco/lnc-react-ui';
import { Icon } from 'components/Icon/Icon';
import { TLLoadingSpinner } from 'components/Spinner';
import { useFetchEventDetails } from 'hooks/event/details';
import PageNotFound from 'pages/PageNotFound';
import { useNavigate, useParams } from 'react-router';

import chevronRight from '../../assets/icons/solid/chevron-right.svg';

import EventAbout from './About/EventAbout';
import EventAdmission from './Admission/EventAdmission';
import EventBoard from './Board/EventBoard';
import EventDevices from './Devices/EventDevices';
import { TabItemsInterface } from './Event.interface';
import style from './Events.module.css';
import { EventLayouts } from './Layout/EventLayout';
import EventParticipants from './Participants/EventParticipants';
import EventTags from './Tags/EventTags';
import EventTimingLocationsTab from './TimingLocations/EventTimingLocations';

type Props = {};

const tabItems: Array<TabItemsInterface> = [
  {
    name: 'about',
    icon: 'info-circle',
    label: 'About',
  },
  {
    name: 'admission',
    icon: 'tags',
    label: 'Admission',
  },
  {
    name: 'board',
    icon: 'clipboard',
    label: 'Board',
    badge: <Badge color="danger">3</Badge>,
  },
];

const tabMap = {
  about: EventAbout,
  layout: EventLayouts,
  timinglocations: EventTimingLocationsTab,
  devices: EventDevices,
  tags: EventTags,
  participants: EventParticipants,
  board: EventBoard,
  admission: EventAdmission,
};

const EventDetails = (props: Props) => {
  const navigate = useNavigate();

  const { id, tab } = useParams();

  const { data: event = null, isLoading } = useFetchEventDetails(id);

  if (isLoading) return <TLLoadingSpinner />;

  const Component = tabMap[tab || 'about'];

  return (
    <div className={style.page}>
      <div className={style.header}>
        <div className={style.breadcrumb}>
          <div className={style.breadcrumb_item1}>
            <div>Events</div>
          </div>
          <div className={style.breadcrumb_item2}>
            <img src={chevronRight} alt=""></img>
          </div>
          <div className={style.breadcrumb_item3}>
            <div>{event?.name}</div>
          </div>
        </div>

        <Tabs className={style.tabs} color="secondary">
          {tabItems.map((item: TabItemsInterface, index: number) => {
            return (
              <TabItem
                active={item.name === tab || (index === 0 && !tab)}
                onClick={() => navigate(`/event/${id}/${item.name}`)}
                className={style.tab}
                color="secondary"
                key={item.name}
              >
                {typeof item.icon === 'string' ? (
                  <Icon size="24px" icon={item.icon} />
                ) : (
                  item.icon
                )}
                {item.label}
                {item.badge}
              </TabItem>
            );
          })}
        </Tabs>

        {Component ? <Component /> : <PageNotFound />}
      </div>
    </div>
  );
};

export default EventDetails;
