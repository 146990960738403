import { useZodForm } from 'hooks/useZodForm';
import {
  EventCoverPhotoSchema,
  eventCoverPhotoSchema,
  EventFormSchema,
} from 'pages/Events/MultiStepForm/event-schema';
import { MultiStepFormContent } from 'pages/Events/MultiStepForm/MultiStepFormContent/MultiStepFormContent';
import { MultiStepFormContentFooter } from 'pages/Events/MultiStepForm/MultiStepFormContent/MultiStepFormContentFooter/MultiStepFormContentFooter';
import { useFormContext } from 'react-hook-form';

import { useMultiStep } from '../../MultiStepForm/context/multi-step-context';

import styles from './EventCoverPhotoForm.module.scss';
import { UploadCoverPhoto } from './UploadCoverPhoto/UploadCoverPhoto';

type EventCoverPhotoFormProps = {
  step: number;
  defaultValues: EventCoverPhotoSchema;
};

const EventCoverPhotoForm = ({
  step,
  defaultValues,
}: EventCoverPhotoFormProps) => {
  const { next } = useMultiStep();

  const { setValue: setContextValue } = useFormContext<EventFormSchema>();

  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useZodForm<EventCoverPhotoSchema>(
    {
      defaultValues,
      mode: 'all',
    },
    eventCoverPhotoSchema,
  );

  const picture = watch('heroImage');

  const pictureChangedHandler = (newPicture: File | null) => {
    console.log('newPicture', newPicture);
    setValue('heroImage', newPicture, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const onSubmit = (data: EventCoverPhotoSchema) => {
    reset(data);
    setContextValue('coverPhoto', data);

    next();
  };

  return (
    <MultiStepFormContent step={step}>
      <div className={styles.eventCoverPhotoForm}>
        <UploadCoverPhoto
          control={control}
          name="heroImage"
          dndFileText={
            <div className={styles.dndFileText}>
              <span> Recommended image size: 1920x1080 pixels.</span>
              <span> JPEG or PNG file.</span>
            </div>
          }
          selectFileText="Click to upload"
          picture={picture}
          errors={errors}
          onUploadImage={pictureChangedHandler}
        />
      </div>

      <MultiStepFormContentFooter
        clearDisabled={!picture}
        isValid={isValid}
        onConfirm={handleSubmit(onSubmit)}
        onClear={() => pictureChangedHandler(null)}
      />
    </MultiStepFormContent>
  );
};

export default EventCoverPhotoForm;
