import {
  AdjustmentsHorizontalIcon,
  PlusIcon,
  TrashIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { MapIcon } from '@heroicons/react/24/solid';
import {
  ActionIcon,
  Button,
  Checkbox,
  Flex,
  Loader,
  Text,
  TextInput,
} from '@mantine/core';
import SortingDropdown from 'components/SortingDropdown/SortingDropdown';
import { SortingParamType } from 'components/SortingDropdown/types';
import { useEntitiesContext } from 'context/EntityProvider';
import { useFilters } from 'context/FilterProvider';
import { debounce } from 'lodash';
import {
  hubTagsSortingParams,
  hubTrackingObjectSortingParams,
} from 'pages/Entities/consts';
import { EntityDetailsParams } from 'pages/Entities/Details/types';
import {
  openModal,
  openRemoveModal,
} from 'pages/Entities/ModalManager/ModalsManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useUpdateEffect } from 'react-use';
import {
  useDeleteEntityLayout,
  useEntityLayouts,
} from 'services/entity/layout';
import { useDeleteTagsByEvent, useTagsByEvent } from 'services/entity/tag';
import {
  useDeleteTrackingObjectByEvent,
  useTrackingObjectsByEvent,
} from 'services/entity/trackingObject';
import { states, statuses } from 'utils/consts';

import {
  mapTagsByEventToTagCard,
  mapTrackingObjectByEventToTrackingObjectCard,
  mapEntityLayoutToLayoutCard,
} from '../mapper';
import MappingDevicesModal from '../Modals/DeviceModals/MappingDevicesModal';
import { setHubModalContent } from '../Modals/hubModalsConfig';
import { HubCardType } from '../types';

import AdditionalFilters from './AdditionalFilters/AdditionalFilters';
import SettingsDropdown from './Dropdowns/SettingsDropdown';
import HubCardContainer from './HubCardContainer/HubCardContainer';
import classNames from './LeftHubContainer.module.scss';

const sections = [
  {
    id: '1',
    cardType: 'section',
    status: 'online',
    image: '/layoutImageTest.png',
    lastSeen: '2 days',
    layoutName: 'First floor',
    maxCapacity: 150,
    capacity: 100,
    sectionName: 'BMW',
    numberOfDevices: 45,
    trafficStatus: 'Slow',
  },
  {
    id: '2',
    cardType: 'section',
    status: 'offline',
    image: '/layoutImageTest.png',
    lastSeen: '1 month',
    layoutName: 'Second floor',
    maxCapacity: 150,
    capacity: 100,
    sectionName: 'Audi',
    numberOfDevices: 45,
    trafficStatus: 'Busy',
  },
  {
    id: '3',
    cardType: 'section',
    status: 'online',
    image: '/layoutImageTest.png',
    lastSeen: '2 days',
    layoutName: 'First floor',
    maxCapacity: 150,
    capacity: 100,
    sectionName: 'Skoda',
    numberOfDevices: 45,
    trafficStatus: 'Quiet',
  },
  {
    id: '4',
    cardType: 'section',
    status: 'offline',
    image: '/layoutImageTest.png',
    lastSeen: '1 month',
    layoutName: 'Second floor',
    maxCapacity: 150,
    capacity: 100,
    sectionName: 'Volkswagen',
    numberOfDevices: 45,
    trafficStatus: 'Heavy',
  },
];

const devices = [
  {
    id: '1',
    cardType: 'devices',
    deviceType: 'anthena',
    deviceName: 'TimeLine Device 001',
    status: 'online',
    deviceStatus: 'enabled',
    lastSeen: '2 days',
    layoutName: 'First floor',
    sectionName: 'BMW',
    mapped: true,
    macAddress: 'M0:M3:T1:00:00:03',
  },
  {
    id: '2',
    cardType: 'devices',
    deviceType: 'processor',
    deviceName: 'TimeLine Device 002',
    status: 'online',
    deviceStatus: 'active',
    lastSeen: '2 days',
    layoutName: 'Second floor',
    sectionName: 'BMW',
    mapped: false,
    macAddress: 'M0:M3:T1:00:00:04',
  },
  {
    id: '3',
    cardType: 'devices',
    deviceType: 'switch',
    deviceName: 'TimeLine Device 003',
    status: 'offline',
    deviceStatus: 'disabled',
    lastSeen: '2 days',
    layoutName: 'Second floor',
    sectionName: 'BMW',
    mapped: false,
    macAddress: 'M0:M3:T1:00:00:04',
  },
  {
    id: '4',
    cardType: 'devices',
    deviceType: 'relay',
    deviceName: 'TimeLine Device 004',
    status: 'online',
    deviceStatus: 'disabled',
    lastSeen: '15m',
    layoutName: 'Second floor',
    sectionName: 'BMW',
    mapped: true,
    macAddress: 'M0:M3:T1:00:00:04',
  },
  {
    id: '5',
    cardType: 'devices',
    deviceType: 'anthenaRelay',
    deviceName: 'TimeLine Device 005',
    status: 'online',
    deviceStatus: 'disabled',
    lastSeen: '2 days',
    layoutName: 'Second floor',
    sectionName: 'BMW',
    mapped: false,
    macAddress: 'M0:M3:T1:00:00:04',
  },
];

const LeftHubContainer = () => {
  const [sortingParam, setSortingParam] = useState<SortingParamType | null>(
    null,
  );
  const [showAdditionalFilters, setShowAdditionalFilters] = useState<boolean>(
    false,
  );
  const { id: eventId = '' } = useParams<EntityDetailsParams>();
  const [isAtBottom, setIsAtBottom] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(20);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
  const { filter } = useFilters();
  const {
    data: entityTrackingObjectsData,
    isLoading: isLoadingTrackingObject,
  } = useTrackingObjectsByEvent(
    eventId,
    limit,
    debouncedSearchTerm,
    filter,
    sortingParam ?? undefined,
  );
  const { data: entityLayoutsData } = useEntityLayouts(
    eventId,
    limit,
    debouncedSearchTerm,
    filter,
    sortingParam,
  );
  const {
    data: { eventTags } = {},
    isLoading: isLoadingEventTag,
  } = useTagsByEvent(
    eventId,
    limit,
    debouncedSearchTerm,
    filter,
    sortingParam ?? undefined,
  );
  const {
    mutate: mutateDeleteTrackingObject,
    isSuccess: isSuccessDeleteTrackingObject,
  } = useDeleteTrackingObjectByEvent();
  const {
    mutate: mutateDeleteEntityLayout,
    isSuccess: isSuccessDeleteEntityLayout,
  } = useDeleteEntityLayout();
  const {
    mutate: mutateDeleteTags,
    isSuccess: isSuccessDeleteTags,
  } = useDeleteTagsByEvent();

  const trackingObjects: HubCardType[] = useMemo(
    () =>
      entityTrackingObjectsData
        ? entityTrackingObjectsData.eventParticipants.map(
            mapTrackingObjectByEventToTrackingObjectCard,
          )
        : [],
    [entityTrackingObjectsData],
  );
  const layouts: Array<HubCardType> = useMemo(
    () =>
      entityLayoutsData
        ? entityLayoutsData.eventLayouts.map(mapEntityLayoutToLayoutCard)
        : [],
    [entityLayoutsData],
  );

  const tags: HubCardType[] = useMemo(
    () => (eventTags ? eventTags.map(mapTagsByEventToTagCard) : []),
    [eventTags],
  );

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      if (value?.length >= 3) setDebouncedSearchTerm(value);
      else if (value?.length === 0) setDebouncedSearchTerm('');
    }, 500),
    [],
  );

  const {
    showHubCardCheckbox,
    selectAllHubCards,
    setSelectAllHubCard,
    indeterminateHubCheckbox,
    setIndeterminateHubCheckbox,
    selectedHubCardIds,
    setSelectedHubCardIds,
    selectedSection,
  } = useEntitiesContext();

  const clearCheckboxes = () => {
    setSelectAllHubCard(false);
    setIndeterminateHubCheckbox(false);
    setSelectedHubCardIds([]);
  };

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (indeterminateHubCheckbox) {
      clearCheckboxes();
    } else {
      if (event.currentTarget.checked) {
        if (selectedSection.type === 'layouts') {
          setSelectedHubCardIds(layouts?.map((x) => x.id));
        } else if (selectedSection.type === 'trackingObjects') {
          setSelectedHubCardIds(trackingObjects?.map((x) => x.id));
        } else if (selectedSection.type === 'tags') {
          setSelectedHubCardIds(tags?.map((x) => x.id));
        } else if (selectedSection.type === 'devices') {
        } else if (selectedSection.type === 'sections') {
        }
      } else {
        setSelectedHubCardIds([]);
      }

      setSelectAllHubCard(event.currentTarget.checked);
    }
  };

  const handleDelete = () => {
    if (!eventId) return;
    if (selectedSection.type === 'trackingObjects')
      selectedHubCardIds?.forEach((x) =>
        mutateDeleteTrackingObject({
          eventId,
          trackingObjectId: x,
        }),
      );
    if (selectedSection.type === 'layouts')
      mutateDeleteEntityLayout({ eventId, layoutIds: selectedHubCardIds });
    if (selectedSection.type === 'devices') console.log(`Device`);
    if (selectedSection.type === 'tags')
      mutateDeleteTags({ eventId: eventId, tagIds: selectedHubCardIds });
  };

  useUpdateEffect(() => {
    if (isAtBottom) {
      setLimit((prev) => prev + 20);
    }
  }, [isAtBottom]);

  useUpdateEffect(() => {
    if (
      isSuccessDeleteTrackingObject ||
      isSuccessDeleteEntityLayout ||
      isSuccessDeleteTags
    ) {
      setSelectedHubCardIds([]);
      clearCheckboxes();
    }
  }, [
    isSuccessDeleteTrackingObject,
    isSuccessDeleteEntityLayout,
    isSuccessDeleteTags,
  ]);

  return (
    <Flex
      h="55rem"
      miw="43.625rem"
      p="1.5rem 0 1.5rem 1.5rem"
      bg="#ffffff"
      bd="1px solid #e2e8f0"
      direction="column"
      justify={
        isLoadingTrackingObject || isLoadingEventTag ? 'center' : undefined
      }
      align={
        isLoadingTrackingObject || isLoadingEventTag ? 'center' : undefined
      }
      classNames={{ root: classNames.mainContainer }}
    >
      {(isLoadingTrackingObject || isLoadingEventTag) && <Loader size="md" />}

      {!(isLoadingTrackingObject || isLoadingEventTag) && (
        <>
          <Flex mr="1.5rem" mb="1.5rem" justify="space-between" align="center">
            <Flex align="center">
              <selectedSection.icon
                height="1.5rem"
                width="1.5rem"
                color="#94a3b8"
                className={classNames.logo}
              />
              <Text fz="1.125rem" fw={600} c="#0f172a">
                {selectedSection.title}
              </Text>
            </Flex>

            <Flex align="center" gap="0.75rem">
              {showHubCardCheckbox && selectedHubCardIds.length ? (
                <>
                  <Button
                    bg="#ffffff"
                    c="#344155"
                    bd="1px solid #e2e8f0"
                    leftSection={<XMarkIcon height="1rem" width="1rem" />}
                    onClick={clearCheckboxes}
                  >
                    Clear selection
                  </Button>

                  <Button
                    bg="#ffffff"
                    c="#f43f5e"
                    bd="1px solid #e2e8f0"
                    leftSection={<TrashIcon height="1.25rem" width="1.25rem" />}
                    onClick={() =>
                      openRemoveModal({
                        title: 'Remove selection',
                        body: `Are you sure you want to delete selected ${
                          selectedSection.type === 'trackingObjects'
                            ? 'tracking objects'
                            : selectedSection.type
                        } from database?`,
                        actionButtonFn: handleDelete,
                      })
                    }
                  >
                    Remove selected
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    bg="#3b82f6"
                    leftSection={<PlusIcon height="1.25rem" width="1.25rem" />}
                    onClick={() =>
                      openModal({
                        type: selectedSection.type,
                        size: selectedSection.modalSize,
                        title: selectedSection.modalTitle,
                        subtitles: selectedSection.modalSubTitles,
                        isMultistep: selectedSection.type === 'trackingObjects',
                        hasFooter: selectedSection.hasFooter,
                        setModalContent: setHubModalContent,
                      })
                    }
                  >
                    {selectedSection.addButton}
                  </Button>
                  {selectedSection.type === 'devices' && (
                    <Button
                      bg="#ffffff"
                      c="#3b82f6"
                      bd="1px solid #e2e8f0"
                      leftSection={<MapIcon height="1.25rem" width="1.25rem" />}
                      onClick={() =>
                        openModal({
                          size: '80rem',
                          titleIcon: (
                            <MapIcon
                              height="1.5rem"
                              width="1.5rem"
                              color="#94a3b8"
                            />
                          ),
                          title: 'Mapping devices',
                          hasFooter: false,
                          content: <MappingDevicesModal />,
                        })
                      }
                    >
                      Map devices
                    </Button>
                  )}
                </>
              )}
              <SettingsDropdown />
            </Flex>
          </Flex>
          <Flex mr="1.5rem" mb="1.5rem" justify="center">
            {showHubCardCheckbox && (
              <Checkbox
                my="auto"
                ml="0.5rem"
                mr="1.25rem"
                checked={selectAllHubCards}
                indeterminate={indeterminateHubCheckbox}
                styles={{ input: { cursor: 'pointer' } }}
                onChange={handleChecked}
              />
            )}

            <TextInput
              size="sm"
              mr="0.75rem"
              fz="1rem"
              radius="md"
              flex={1}
              placeholder="Search"
              onChange={(e) => debouncedSetSearchTerm(e.target.value)}
              leftSection={
                <MagnifyingGlassIcon
                  height="1.25rem"
                  width="1.25rem"
                  color="#94A3B8"
                />
              }
            />

            <Flex align="center" gap="0.75rem">
              <SortingDropdown
                sortingParams={
                  selectedSection.type === 'trackingObjects'
                    ? hubTrackingObjectSortingParams
                    : selectedSection.type === 'tags'
                    ? hubTagsSortingParams
                    : []
                }
                sortingParam={sortingParam}
                setSortingParam={setSortingParam}
              />
              <ActionIcon
                bg="#ffffff"
                c={showAdditionalFilters ? '#3B82F6' : '#0F172A'}
                onClick={() => setShowAdditionalFilters((prev) => !prev)}
              >
                <AdjustmentsHorizontalIcon height="1.5rem" width="1.5rem" />
              </ActionIcon>
            </Flex>
          </Flex>
          {showAdditionalFilters && (
            <AdditionalFilters
              statuses={statuses.map(({ value, label }) => ({
                id: value,
                value: label,
              }))}
              states={states.map(({ value, label }) => ({
                id: value,
                value: label,
              }))}
              showTypeFilter={
                selectedSection.type === 'trackingObjects' ? true : false
              }
              showClassificationFilter={
                selectedSection.type === 'trackingObjects' ? true : false
              }
            />
          )}
          <HubCardContainer
            data={
              selectedSection.type === 'trackingObjects'
                ? trackingObjects
                : selectedSection.type === 'layouts'
                ? layouts
                : selectedSection.type === 'sections'
                ? sections
                : selectedSection.type === 'devices'
                ? devices
                : tags
            }
            eventId={eventId}
            setIsAtBottom={setIsAtBottom}
          />
        </>
      )}
    </Flex>
  );
};

export default LeftHubContainer;
