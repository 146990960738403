import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import { UpdateDeviceStatusPayload } from 'types';
import { DELETE_DEVICE, DELETE_DEVICES, UPDATE_DEVICE } from 'utils/apiUrl';

import { DeviceResponse } from './types';

export class DeviceService extends ResponseInterceptor {
  static async updateDeviceStatus(
    id: string,
    payload: UpdateDeviceStatusPayload,
  ): Promise<APIAxiosResponse<DeviceResponse>> {
    const response = await client.put(UPDATE_DEVICE(id), payload);
    return this.handleResponse(response);
  }

  static async deleteDevice(id: string): Promise<APIAxiosResponse<string>> {
    const response = await client.delete(DELETE_DEVICE(id));
    return this.handleResponse(response);
  }

  static async deleteDevices(
    ids: string[],
  ): Promise<APIAxiosResponse<string[]>> {
    const response = await client.delete(DELETE_DEVICES(), {
      data: { ids },
    });
    return this.handleResponse(response);
  }
}
