import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { client } from 'services/api';
import { GET_EVENT_STATISTICS } from 'utils/apiUrl';

import { eventStatisticsBaseKey } from './baseKeys';
import { statisticsRefetchInterval } from './statisticsRefetchInterval';

interface EventStatistics {
  totalEvents: number;
  averageUniqueEventsPerMonth: number;
  eventsFromLastFiveMonthsToNextSixMonths: {
    month: Date;
    eventCount: number;
  }[];
  activeEvents: number;
  upcomingEvents: number;
  pastEvents: number;
}

export const useFetchEventStatistics = () => {
  const queryResult = useQuery<EventStatistics, AxiosError>({
    queryKey: [eventStatisticsBaseKey],
    refetchInterval: statisticsRefetchInterval,
    queryFn: async () => {
      const retVal = await client.get<{ data: EventStatistics }>(
        GET_EVENT_STATISTICS(),
      );

      retVal.data.data.eventsFromLastFiveMonthsToNextSixMonths = retVal.data.data.eventsFromLastFiveMonthsToNextSixMonths.map(
        (event) => ({
          ...event,
          month: new Date(event.month),
        }),
      );

      return retVal.data.data;
    },
  });

  return queryResult;
};
