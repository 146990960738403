import { Button } from '@lanaco/lnc-react-ui';
import { SpinnerColor, SpinnerSize, TLSpinner } from 'components/Spinner';
import { TLCase, TLSwitch } from 'components/utils';

import { ButtonColor, TLLoadingButtonProps } from '../interfaces';

export const TLPrimaryLoadingButton = ({
  isLoading,
  text,
  color,
  children,
  ...props
}: TLLoadingButtonProps) => (
  <Button
    color={isLoading ? ButtonColor.NEUTRAL : ButtonColor.PRIMARY}
    disabled={isLoading}
    {...props}
  >
    <TLSwitch condition={isLoading}>
      <TLCase value>
        <TLSpinner color={SpinnerColor.PRIMARY} size={SpinnerSize.XSMALL} />
      </TLCase>
      <TLCase value={false}>{text || children}</TLCase>
    </TLSwitch>
  </Button>
);
