import { Event } from 'pages/Events/interfaces';
import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import { GET_EVENT } from 'utils/apiUrl';

export class EventDetailsService extends ResponseInterceptor {
  static async getEventDetails(
    eventId: string,
  ): Promise<APIAxiosResponse<Event>> {
    const res = await client.get(GET_EVENT(eventId));
    return this.handleResponse(res);
  }
}
