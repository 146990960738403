import {
  Button,
  FlexGrid,
  FlexGridItem,
  Spinner,
  TextInput,
  Dropdown,
  FormField,
  PasswordInput,
  notification,
  NotificationMessage,
  NotificationContainer,
} from '@lanaco/lnc-react-ui';
import { useAppSelector } from 'hooks/useAppDispatch';
import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { client } from 'services/api';
import { getUser } from 'store/slices/userSlice/userSlice';
import { REGISTER } from 'utils/apiUrl';

import { RegistrationData } from './Registration.interfaces';
import style from './Registration.module.css';

type Props = {};

const Registration = (props: Props) => {
  // let dispatch = useAppDispatch();
  let user = useAppSelector(getUser).user;
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationData>();

  // const onClickHandler = async () => {
  //   let data: LoginData = {
  //     email: 'test_user_for_both@gmail.com',
  //     password: 'password123$',
  //   };
  //   dispatch(loginUser(data));
  // };

  const onSubmit: SubmitHandler<RegistrationData> = async (data) => {
    setLoading(true);
    if (data.password !== data.confirmPassword) {
      notification.error(
        <NotificationMessage title={'Error'}>
          Passwords do not match.
        </NotificationMessage>,
        {},
      );
      setLoading(false);
      return;
    } else if (data.email !== data.confirmEmail) {
      notification.error(
        <NotificationMessage title={'Error'}>
          Email addresses do not match.
        </NotificationMessage>,
        {},
      );
      setLoading(false);
      return;
    }

    data.gender = 'male';
    const response = await client.post(REGISTER, data);

    if (response.data.code === 201) {
      notification.success(
        <NotificationMessage title={'Info'}>
          User successfully registered.
        </NotificationMessage>,
        {},
      );
      setLoading(false);
    } else {
      notification.error(
        <NotificationMessage title={'Error'}>
          {response.data.message}
        </NotificationMessage>,
        {},
      );
      setLoading(false);
    }
  };
  if (user.loggedIn) {
    return <Navigate to="/" />;
  }

  const onClickHandler = async () => {
    navigate('/login');
  };

  return (
    <>
      <NotificationContainer
        position={'top-center'}
        autoClose={3000}
        color={'primary'}
        closeButton={false}
      />

      <FlexGrid>
        <FlexGridItem XS={12} M={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={style.center}>
              <div className={style.sidebar}>
                <span className={style.naslov1}>Registration</span>

                <FormField
                  // label="First name"
                  text={
                    errors.firstName && <span>{errors.firstName?.message}</span>
                  }
                  color={errors.firstName && 'danger'}
                >
                  <TextInput
                    placeholder={'First name'}
                    {...register('firstName', {
                      required: {
                        value: false,
                        message: 'Field First name is required.',
                      },
                    })}
                    color={errors.firstName && 'danger'}
                  />
                </FormField>

                <FormField
                  // label="Email"
                  text={
                    errors.lastName && <span>{errors.lastName?.message}</span>
                  }
                  color={errors.lastName && 'danger'}
                >
                  <TextInput
                    placeholder={'Last name'}
                    {...register('lastName', {
                      required: {
                        value: false,
                        message: 'Field Last name is required.',
                      },
                    })}
                    color={errors.lastName && 'danger'}
                  />
                </FormField>

                <FormField>
                  <Dropdown
                    {...register('gender')}
                    placeholder={'Gender'}
                    options={[
                      {
                        value: 'male',
                        label: 'Male',
                      },
                      {
                        value: 'female',
                        label: 'Female',
                      },
                      {
                        value: 'other',
                        label: 'Other',
                      },
                    ]}
                  />
                </FormField>

                <FormField
                  // label="Email"
                  text={errors.email && <span>{errors.email?.message}</span>}
                  color={errors.email && 'danger'}
                >
                  <TextInput
                    placeholder={'Email'}
                    {...register('email', {
                      required: {
                        value: true,
                        message: 'Field Email is required.',
                      },
                    })}
                    color={errors.email && 'danger'}
                  />
                </FormField>

                <FormField
                  // label="Confirm Email"
                  text={
                    errors.confirmEmail && (
                      <span>{errors.confirmEmail?.message}</span>
                    )
                  }
                  color={errors.confirmEmail && 'danger'}
                >
                  <TextInput
                    placeholder={'Confirm Email'}
                    {...register('confirmEmail', {
                      required: 'Field Confirm Email is required.',
                    })}
                    color={errors.confirmEmail && 'danger'}
                  />
                </FormField>

                <FormField
                  // label="Password"
                  text={
                    errors.password && <span>{errors.password?.message}</span>
                  }
                  color={errors.password && 'danger'}
                >
                  <PasswordInput
                    placeholder={'Password'}
                    {...register('password', {
                      required: {
                        value: true,
                        message: 'Field Password is required.',
                      },
                    })}
                    color={errors.password && 'danger'}
                  />
                </FormField>

                <FormField
                  // label="Confirm Password"
                  text={
                    errors.confirmPassword && (
                      <span>{errors.confirmPassword?.message}</span>
                    )
                  }
                  color={errors.confirmPassword && 'danger'}
                >
                  <PasswordInput
                    placeholder={'Confirm Password'}
                    {...register('confirmPassword', {
                      required: 'Field Confirm Password is required.',
                    })}
                    color={errors.confirmPassword && 'danger'}
                  />
                </FormField>

                <div className={style.forgottenPass}>
                  {/* <Button type="submit" className={style.loginButton}>
                    Register
                  </Button> */}
                  <Button className={style.loginButton} type="submit">
                    {' '}
                    {loading ? <Spinner size={'small'} /> : null} Register{' '}
                  </Button>
                  <Button
                    onClick={onClickHandler}
                    className={style.loginButton}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </FlexGridItem>
      </FlexGrid>
    </>
  );
};

export default Registration;
