import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import { Group, Popover, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

type Prop = {
  label?: string;
  mb?: string;
  showAsterisk?: boolean;
  value: string | null;
  onChange: (date: string | null) => void;
  errorMessage?: string | null;
};

const DatePickerInput = ({
  label,
  mb,
  showAsterisk = false,
  value,
  onChange,
  errorMessage,
}: Prop) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState<Date | null>(
    value ? new Date(value) : null,
  );

  useEffect(() => {
    if (value) {
      setLocalValue(new Date(value));
    }
  }, [value]);

  const formattedDate = localValue ? format(localValue, 'MMM d yyyy') : '';

  const handleDateChange = (date: Date | null) => {
    setLocalValue(date);
    onChange(date ? format(date, 'yyyy-MM-dd') : null);
  };

  return (
    <Group>
      <Popover opened={opened} onChange={setOpened} position="bottom" withArrow>
        <Popover.Target>
          <TextInput
            label={label ?? null}
            mb={mb ?? '0'}
            value={formattedDate ?? undefined}
            error={errorMessage}
            placeholder="May 1 1988"
            onClick={() => setOpened((prev) => !prev)}
            readOnly
            withAsterisk={showAsterisk}
            rightSection={
              <CalendarDaysIcon
                width="1.25rem"
                height="1.25rem"
                color={'var(--gray-900)'}
              />
            }
            w={'100%'}
            rightSectionWidth="1.875rem"
          />
        </Popover.Target>
        <Popover.Dropdown>
          <DatePicker value={localValue} onChange={handleDateChange} />
        </Popover.Dropdown>
      </Popover>
    </Group>
  );
};

export default DatePickerInput;
