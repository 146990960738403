import DragAndDropImageInputGroup from 'components/DragAndDropImage/DragAndDropImageInputGroup';
import { ReactNode } from 'react';
import { Control, FieldErrors, FieldPath, FieldValues } from 'react-hook-form';

import { UploadAnother } from './UploadAnother/UploadAnother';
import styles from './UploadCoverPhoto.module.scss';

export interface UploadCoverPhotoProps<T extends FieldValues> {
  name: FieldPath<T>;
  control?: Control<T>;
  picture: File | string | null;
  errors?: FieldErrors;
  dndFileText?: ReactNode;
  selectFileText?: string;
  onUploadImage: (file: File | null) => void;
}

export const UploadCoverPhoto = <T extends FieldValues>({
  name,
  control,
  errors,
  picture,
  dndFileText,
  selectFileText,
  onUploadImage,
}: UploadCoverPhotoProps<T>) => {
  return (
    <div className={styles.uploadCoverPhoto}>
      {!picture ? (
        <DragAndDropImageInputGroup
          name={name}
          control={control}
          errors={errors}
          dndFileText={dndFileText}
          selectFileText={selectFileText}
          onUploadImage={onUploadImage}
        />
      ) : (
        <UploadAnother picture={picture} onUploadImage={onUploadImage} />
      )}
    </div>
  );
};
