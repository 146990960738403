import {
  CpuChipIcon,
  MapIcon,
  ServerIcon,
  SignalIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import { Badge, Box, Flex, Stack, Text } from '@mantine/core';
import DeviceIcon from 'components/SvgIcons/DeviceIcon';
import DevicePlusIcon from 'components/SvgIcons/DevicePlusIcon';

import { DeviceMappingCardProps } from '../../types';

const DeviceMappingCard = ({
  state = 'online',
  date,
  time,
  type = 'relay',
  name,
  macAddress,
  isMapped = false,
  status = 'enabled',
  isSelected,
  isHovered,
  style,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onDelete,
}: DeviceMappingCardProps) => {
  return (
    <Flex
      py="0.75rem"
      px="1rem"
      bg={isSelected ? '#eff6ff' : '#ffffff'}
      bd={
        isSelected
          ? '1px solid #10b981'
          : isHovered
          ? '1px solid #3b82f6'
          : '1px solid #e2e8f0'
      }
      style={{ borderRadius: '0.75rem', cursor: 'pointer', ...style }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box
        h="4.25rem"
        w="0.25rem"
        mr="1rem"
        bg={
          state === 'online'
            ? '#10b981'
            : state === 'idle'
            ? '#eab308'
            : '#cbd5e1'
        }
        style={{ borderRadius: '1rem' }}
      />

      <Stack mr="auto" justify="space-between" gap={0}>
        <Text c="#64748b" fz="0.875rem">
          {date} | {time}
        </Text>

        <Flex pl="0.125rem" align="center" gap="0.25rem">
          {type === 'anthena' ? (
            <SignalIcon height="1rem" width="1rem" color="#94a3b8" />
          ) : type === 'processor' ? (
            <CpuChipIcon height="1rem" width="1rem" color="#94a3b8" />
          ) : type === 'switch' ? (
            <ServerIcon height="1rem" width="1rem" color="#94a3b8" />
          ) : type === 'relay' ? (
            <DeviceIcon height="1rem" width="1rem" color="#94a3b8" />
          ) : type === 'anthenaRelay' ? (
            <DevicePlusIcon height="1rem" width="1rem" color="#94a3b8" />
          ) : null}

          <Text c="#0f172a" fz="0.875rem" fw={500}>
            {name}
          </Text>
        </Flex>

        <Text c="#64748b" fz="0.875rem">
          {macAddress}
        </Text>
      </Stack>

      <Stack justify="space-between">
        <Flex gap="0.5rem">
          {isMapped && (
            <Badge
              variant="light"
              color="#3b82f6"
              px="0.5rem"
              tt="none"
              fw={400}
            >
              <MapIcon
                height="1rem"
                width="1rem"
                color="#3b82f6"
                style={{ marginRight: '0.5rem' }}
              />
              Mapped
            </Badge>
          )}

          <Badge
            variant="light"
            color={status === 'enabled' ? '#10b981' : '#64748b'}
            px="0.5rem"
            tt="none"
            fw={400}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </Badge>
        </Flex>

        {isHovered && (
          <TrashIcon
            height="1.25rem"
            width="1.25rem"
            color="red"
            style={{ alignSelf: 'flex-end' }}
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          />
        )}
      </Stack>
    </Flex>
  );
};

export default DeviceMappingCard;
