import { EllipsisVerticalIcon } from '@heroicons/react/16/solid';
import { ActionIcon, Box, Menu, Stack, Switch } from '@mantine/core';
import { useEffect, useState } from 'react';

import { AdmissionDropdownProps } from '../types';

const AdmissionDropdown = ({
  autoSubmit,
  setAutoSubmit,
  allowMultitagging,
  setAllowMultitagging,
  cameraDelay,
  setCameraDelay,
}: AdmissionDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>();

  const handleAutoSubmit = () => {
    setAutoSubmit((prev) => !prev);
    if (allowMultitagging) {
      setAllowMultitagging((prev) => !prev);
    }
  };

  const handleAllowMultiTagging = () => {
    setAllowMultitagging((prev) => !prev);
    if (autoSubmit) {
      setAutoSubmit((prev) => !prev);
    }
  };

  const handleCameraDelay = (secondDelay: number) => {
    switch (secondDelay) {
      case 1:
        setCameraDelay(1000 * 1);
        break;
      case 2:
        setCameraDelay(1000 * 2);
        break;
      case 3:
        setCameraDelay(1000 * 3);
        break;
    }
  };

  return (
    <Menu
      width={300}
      position="bottom-end"
      offset={0}
      onOpen={() => setIsDropdownOpen(true)}
      onClose={() => setIsDropdownOpen(false)}
    >
      <Menu.Target>
        <ActionIcon
          bg={isDropdownOpen ? '#e2e8f0' : '#ffffff'}
          c="#475569"
          mr="0.75rem"
        >
          <EllipsisVerticalIcon
            height="1.5rem"
            width="1.5rem"
            color="var(--gray-600)"
          />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          rightSection={
            <Box style={{ display: 'flex', gap: '0' }}>
              <Stack
                bg={cameraDelay === 1000 ? 'var(--blue-500)' : '#FFF'}
                style={{
                  width: '3rem',
                  height: '2rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid var(--gray-200)',
                  borderRadius: '8px 0 0 8px',
                  cursor: 'pointer',
                }}
                onClick={() => handleCameraDelay(1)}
              >
                <span
                  style={{
                    color: cameraDelay === 1000 ? '#FFF' : 'var(--gray-900)',
                  }}
                >
                  1s
                </span>
              </Stack>
              <Stack
                bg={cameraDelay === 2000 ? 'var(--blue-500)' : '#FFF'}
                style={{
                  width: '3rem',
                  height: '2rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid var(--gray-200)',
                  cursor: 'pointer',
                }}
                onClick={() => handleCameraDelay(2)}
              >
                <span
                  style={{
                    color: cameraDelay === 2000 ? '#FFF' : 'var(--gray-900)',
                  }}
                >
                  2s
                </span>
              </Stack>
              <Stack
                bg={cameraDelay === 3000 ? 'var(--blue-500)' : '#FFF'}
                style={{
                  width: '3rem',
                  height: '2rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid var(--gray-200)',
                  borderRadius: '0 8px 8px 0',
                  cursor: 'pointer',
                }}
                onClick={() => handleCameraDelay(3)}
              >
                <span
                  style={{
                    color: cameraDelay === 3000 ? '#FFF' : 'var(--gray-900)',
                  }}
                >
                  3s
                </span>
              </Stack>
            </Box>
          }
          styles={{ item: { cursor: 'default' } }}
        >
          Camera delay
        </Menu.Item>
        <Menu.Divider m={0} />
        <Menu.Item
          rightSection={
            <Switch
              size="md"
              checked={autoSubmit}
              onChange={handleAutoSubmit}
              styles={{
                track: {
                  backgroundColor: autoSubmit ? '#059669' : '#e11d48',
                  cursor: 'pointer',
                },
              }}
            />
          }
          styles={{ item: { cursor: 'default' } }}
          closeMenuOnClick={false}
        >
          Auto submit
        </Menu.Item>
        <Menu.Item
          rightSection={
            <Switch
              size="md"
              checked={allowMultitagging}
              onChange={handleAllowMultiTagging}
              styles={{
                track: {
                  backgroundColor: allowMultitagging ? '#059669' : '#e11d48',
                  cursor: 'pointer',
                },
              }}
            />
          }
          styles={{ item: { cursor: 'default' } }}
          closeMenuOnClick={false}
        >
          Allow multi-tagging
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default AdmissionDropdown;
