import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventParticipantsService } from 'services/event/participant/event-participants';
import { EventParticipantPayload } from 'types/event';

import { eventParticipantBaseKey } from './eventParticipantBaseKey';

export const useMutateEventParticipant = (eventId?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: EventParticipantPayload) => {
      if (!eventId) return;

      const res = payload._id
        ? await EventParticipantsService.updateParticipant(
            {
              eventId,
              participantId: payload._id,
            },
            payload,
          )
        : await EventParticipantsService.createParticipant(eventId, payload);

      return res?.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([eventParticipantBaseKey, eventId]);
    },
  });
};
