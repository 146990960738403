import clsx from 'clsx';
import ButtonGroupData from 'components/ButtonGroupData/ButtonGroupData';
import ProgressSteps from 'components/ProgressSteps/ProgressSteps';
import { useState } from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';

import {
  EventLayout,
  EventLayoutPresentationImage,
  EventLayoutPresentationType,
} from '../interfaces';
import { Presentation } from '../LayoutForm';

import { LayoutImageUpload } from './LayoutImageUpload';
import { LayoutLocationPicker } from './LayoutLocationPicker';
import styles from './LayoutSchemeForm.module.css';

type Props = {
  currentStepIndex: number;
  register: UseFormRegister<EventLayout>;
  formState: Partial<FieldErrorsImpl>;
  clear: number;
  eventLayout?: EventLayout;
  setPresentation: (presentation: Presentation | null) => void;
};

const LayoutSchemeForm = (props: Props) => {
  const [tab, setTab] = useState(
    props.eventLayout?.presentation?.type === 'googlemap' ? 'map' : 'image',
  );

  const onSelectTab = (tab: string) => () => {
    props.setPresentation(null);
    setTab(tab);
  };

  const onLayoutSchemeChange = (
    type: EventLayoutPresentationType,
    locationOrImage:
      | File
      | (google.maps.LatLngLiteral & { zoom: number | undefined }),
  ) => {
    const { lat, lng, zoom } = locationOrImage as google.maps.LatLngLiteral & {
      zoom: number | undefined;
    };

    const image = locationOrImage as File;

    props.setPresentation(
      type === EventLayoutPresentationType.GoogleMap
        ? {
            type,
            geoRange: {
              center: { lat, lng },
              zoom: zoom || 13,
            },
          }
        : {
            type,
            image,
          },
    );
  };

  const layoutSchemeTabs = [
    {
      text: 'Image',
      onClick: onSelectTab('image'),
    },
    {
      text: 'Map',
      onClick: onSelectTab('map'),
    },
  ];

  return (
    <div className={styles.eventCoverPhotoForm}>
      <ProgressSteps
        title="Layout scheme"
        currentStep={props.currentStepIndex}
        totalSteps={2}
      />
      <ButtonGroupData
        buttons={layoutSchemeTabs}
        widthButtonGroup={'100%'}
        width={'50%'}
      ></ButtonGroupData>
      <div
        className={clsx(
          styles.contentContainer,
          tab !== 'image' && styles.hidden,
        )}
      >
        <LayoutImageUpload
          source={
            (props.eventLayout?.presentation as EventLayoutPresentationImage)
              ?.image?.largeSource
          }
          onChange={onLayoutSchemeChange}
        />
      </div>

      <div
        className={clsx(
          styles.contentContainer,
          tab !== 'map' && styles.hidden,
        )}
      >
        <LayoutLocationPicker
          eventLayout={props.eventLayout}
          visible={tab === 'map'}
          onChange={onLayoutSchemeChange}
        />
      </div>
    </div>
  );
};

export default LayoutSchemeForm;
