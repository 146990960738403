import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { EventDevice } from 'pages/Events/Devices/EventDevices.interface';
import { client } from 'services/api';
import { Response } from 'types';
import { GET_EVENT_ASSIGNABLE_DEVICES } from 'utils/apiUrl';

import { eventDeviceAssignableKey } from './baseKeys';

interface Props {
  eventId;
  offset;
  row_limit;
  filter;
}

export const useFetchEventAssignableDevices = ({
  eventId,
  offset,
  row_limit,
  filter,
}: Props) => {
  const queryResult = useQuery<Response<EventDevice[]>, AxiosError>({
    queryKey: [eventDeviceAssignableKey, eventId, row_limit, offset, filter],
    queryFn: async () => {
      //if (!eventId) return [];

      const retVal = await client.get<Response<EventDevice[]>>(
        GET_EVENT_ASSIGNABLE_DEVICES(eventId, row_limit, offset, filter),
      );

      return retVal.data;
    },
  });

  return queryResult;
};
