import { TextInput, FlexBox, FlexGridItem } from '@lanaco/lnc-react-ui';
import { useWindowSize } from 'react-use';

import { HeaderInterface } from './Header.interface';
import style from './Header.module.css';
const Header = (props: HeaderInterface) => {
  const { width } = useWindowSize();
  return (
    <FlexBox
      justifyContent="SpaceBetween"
      alignItems="Center"
      className={style.header}
      direction={width < 576 ? 'Column' : 'Row'}
    >
      <FlexBox direction="Column" alignItems="Start">
        <p className={style.title}>{props.title}</p>
        <p>{props.subtitle}</p>
      </FlexBox>
      <TextInput
        placeholder="Search"
        onChange={(e) => props.onSearch(e.target.value)}
        className={style.search}
      ></TextInput>
    </FlexBox>
  );
};

export default Header;
