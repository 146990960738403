import { Badge, TabItem, Tabs } from '@lanaco/lnc-react-ui';
import { Icon } from 'components/Icon/Icon';
import { TLLoadingSpinner } from 'components/Spinner';
import { useFetchEventBoardState } from 'hooks/event/board/useFetchEventNotificationsState';
import { useFetchEventDetails } from 'hooks/event/details';
import PageNotFound from 'pages/PageNotFound';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getSession } from 'services/auth';
import { getSocket } from 'services/socket';

import chevronRight from '../../assets/icons/solid/chevron-right.svg';
import { ReactComponent as DeviceIcon } from '../../assets/icons/solid/device.svg';

import EventAbout from './About/EventAbout';
import EventAdmission from './Admission/EventAdmission';
import EventBoard from './Board/EventBoard';
import {
  EmitEvents,
  EmitEventsMap,
  ListenEvents,
  ListenEventsMap,
} from './Board/EventBoardSocketNotificationRoom';
import EventDevices from './Devices/EventDevices';
import { TabItemsInterface } from './Event.interface';
import { EventLive } from './EventLive/EventLive';
import style from './Events.module.css';
import { EventLayouts } from './Layout/EventLayout';
import EventParticipants from './Participants/EventParticipants';
import EventTags from './Tags/EventTags';
import EventTimingLocationsTab from './TimingLocations/EventTimingLocations';

type Props = {};

const tabItems: Array<TabItemsInterface> = [
  {
    name: 'about',
    icon: 'info-circle',
    label: 'About',
  },
  {
    name: 'layout',
    icon: 'layer-group',
    label: 'Layout',
    requiredRole: 'institution',
  },
  {
    name: 'timinglocations',
    icon: 'map-marker-alt',
    label: 'Timing locations',
    requiredRole: 'institution',
  },
  {
    name: 'devices',
    icon: <DeviceIcon />,
    label: 'Devices',
    requiredRole: 'institution',
  },
  {
    name: 'tags',
    icon: 'tag',
    label: 'Tags',
    requiredRole: 'institution',
  },
  {
    name: 'participants',
    icon: 'user',
    label: 'Participants',
    requiredRole: 'institution',
  },
  {
    name: 'admission',
    icon: 'tags',
    label: 'Admission',
  },
  {
    name: 'board',
    icon: 'clipboard',
    label: 'Board',
    // badge: <Badge color="danger">3</Badge>,
  },
  {
    name: 'live',
    icon: 'video',
    label: 'Live',
  },
];

const tabMap = {
  about: EventAbout,
  layout: EventLayouts,
  timinglocations: EventTimingLocationsTab,
  devices: EventDevices,
  tags: EventTags,
  participants: EventParticipants,
  board: EventBoard,
  admission: EventAdmission,
  live: EventLive,
};

const EventDetails = (props: Props) => {
  const navigate = useNavigate();
  const { session } = getSession();
  const { id, tab } = useParams();
  const { data: event = null, isLoading } = useFetchEventDetails(id);
  const [update, forceUpdate] = useReducer((x) => x + 1, 0);
  const { data } = useFetchEventBoardState({ eventId: id, update });
  const boardState = useMemo(
    () => data?.data ?? { totalCountNotSeen: 0, totalCount: 0 },
    [data],
  );

  useEffect(() => {
    const socket = getSocket<ListenEventsMap, EmitEventsMap>().socket;
    socket.emit(EmitEvents.StartTrackLive, `${id}`);
    const forcePageReloadHandler = (_) => {
      forceUpdate();
    };

    socket.on(ListenEvents.NewNotification, forcePageReloadHandler);
    socket.on(ListenEvents.NotificationUpdated, forcePageReloadHandler);
    socket.on(ListenEvents.NotificationAcknowledged, forcePageReloadHandler);
    socket.on(ListenEvents.NotificationUnacknowledged, forcePageReloadHandler);

    return () => {
      socket.emit(EmitEvents.StopTrackLive, `${id}`);
      socket.off(ListenEvents.NewNotification, forcePageReloadHandler);
      socket.off(ListenEvents.NotificationUpdated, forcePageReloadHandler);
      socket.off(ListenEvents.NotificationAcknowledged, forcePageReloadHandler);
      socket.off(
        ListenEvents.NotificationUnacknowledged,
        forcePageReloadHandler,
      );
    };
  }, [update]);

  if (isLoading) return <TLLoadingSpinner />;

  const Component = tabMap[tab || 'about'];
  return (
    <div className={style.page}>
      <div className={style.header}>
        <div className={style.breadcrumb}>
          <div className={style.breadcrumb_item1}>
            {/* <div>Events</div> */}
            <Link to="/events">Events</Link>
          </div>
          <div className={style.breadcrumb_item2}>
            <img src={chevronRight} alt=""></img>
          </div>
          <div className={style.breadcrumb_item3}>
            <div>{event?.name}</div>
          </div>
        </div>

        <Tabs className={style.tabs} color="secondary">
          {tabItems
            .filter((ti) =>
              ti.requiredRole === 'institution'
                ? !!session?.institutionId
                : true,
            )
            .map((item: TabItemsInterface, index: number) => {
              return (
                <TabItem
                  active={item.name === tab || (index === 0 && !tab)}
                  onClick={() => navigate(`/event/${id}/${item.name}`)}
                  className={style.tab}
                  color="secondary"
                  key={item.name}
                >
                  {typeof item.icon === 'string' ? (
                    <Icon size="24px" icon={item.icon} />
                  ) : (
                    item.icon
                  )}
                  {item.label}
                  {item.name === 'board' && boardState.totalCountNotSeen > 0 ? (
                    <Badge color="danger">{boardState.totalCountNotSeen}</Badge>
                  ) : (
                    item.badge
                  )}
                </TabItem>
              );
            })}
        </Tabs>

        {Component ? <Component /> : <PageNotFound />}
      </div>
    </div>
  );
};

export default EventDetails;
