import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Breadcrumbs, Flex, Tabs, Text } from '@mantine/core';
import { useEntitiesContext } from 'context/EntityProvider';
import { useNavigate, useParams } from 'react-router';

import { controlAccessDetailsTabs } from '../tabsConfig';

import { ControlAccessDetailsParams } from './types';

const ControlAccessDetails = () => {
  const navigate = useNavigate();
  const { id, controlAccessId, tab } = useParams<ControlAccessDetailsParams>();
  const { breadcrumbs, removeBreadcrumbs } = useEntitiesContext();

  return (
    <>
      <Flex justify="space-between" mb="1.25rem">
        <Breadcrumbs separator={<ChevronRightIcon />} mb="1rem">
          {breadcrumbs.map((item, index, array) => (
            <Text
              key={index}
              c="#64748b"
              onClick={() => {
                if (index !== array.length - 1) {
                  removeBreadcrumbs(index);
                  navigate(item.href);
                }
              }}
            >
              {item.title}
            </Text>
          ))}
        </Breadcrumbs>
      </Flex>

      <Tabs defaultValue={tab || 'settings'}>
        <Tabs.List>
          {Object.entries(controlAccessDetailsTabs).map(([key, tabObj]) => (
            <Tabs.Tab
              key={key}
              value={key}
              leftSection={<tabObj.icon />}
              onClick={() =>
                navigate(
                  `/entities/${id}/control-access/${controlAccessId}/${key}`,
                )
              }
            >
              {tabObj.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {Object.entries(controlAccessDetailsTabs).map(([key, tabObj]) => (
          <Tabs.Panel key={key} value={key}>
            <tabObj.panel />
          </Tabs.Panel>
        ))}
      </Tabs>
    </>
  );
};

export default ControlAccessDetails;
