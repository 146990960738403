import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { TagStatistics } from 'pages/Tags/interfaces';
import { client } from 'services/api';
import { GET_TAG_STATISTICS } from 'utils/apiUrl';

import { tagStatisticsBaseKey } from './baseKeys';
import { statisticsRefetchInterval } from './statisticsRefetchInterval';

export const useFetchTagStatistics = () => {
  const queryResult = useQuery<TagStatistics, AxiosError>({
    queryKey: [tagStatisticsBaseKey],
    refetchInterval: statisticsRefetchInterval,
    queryFn: async () => {
      const retVal = await client.get<{ data: TagStatistics }>(
        GET_TAG_STATISTICS(),
      );

      return retVal.data.data;
    },
  });

  return queryResult;
};
