import { CloudArrowUpIcon, CameraIcon } from '@heroicons/react/24/outline';
import { Button, Card, Center, Grid } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { CameraAndImageComponentProps } from 'pages/Entities/Hub/types';
import { useRef } from 'react';
import Webcam from 'react-webcam';

const CameraAndImageComponent = ({
  imageSource,
  setImageSource,
  openRef,
}: CameraAndImageComponentProps) => {
  const webcamRef = useRef<Webcam>(null);
  const { width } = useViewportSize();
  const capture = () => {
    if (webcamRef.current) {
      const image = webcamRef.current.getScreenshot();
      if (image) setImageSource(image);
    }
  };

  return (
    <>
      {width >= 768 ? (
        <Grid
          justify="center"
          h="22.063rem"
          bg="var(--gray-200)"
          style={{
            borderRadius: '0.5rem',
          }}
        >
          <Grid.Col
            span={{ base: 3 }}
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'flex-start',
            }}
          >
            {imageSource ? (
              <Button
                bg="var(--gray-0)"
                c="#344155"
                ml="0.5rem"
                onClick={() => {
                  setImageSource(null);
                }}
              >
                Take a new photo
              </Button>
            ) : (
              <Button
                bg="var(--gray-0)"
                c="var(--blue-500)"
                ml="0.5rem"
                onClick={capture}
              >
                Take a photo
              </Button>
            )}
          </Grid.Col>

          <Grid.Col
            span={{ base: 6 }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!imageSource ? (
              <Webcam
                ref={webcamRef}
                audio={false}
                screenshotFormat="image/png"
                style={{
                  marginBottom: '0.625rem',
                  width: '20.625rem',
                  height: '20.625rem',
                  borderRadius: '0.5rem',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <img
                src={imageSource}
                alt="Captured"
                style={{
                  borderRadius: '0.5rem',
                  width: '20.625rem',
                  height: '20.625rem',
                  objectFit: 'cover',
                }}
              />
            )}
          </Grid.Col>
          <Grid.Col
            span={{ base: 3 }}
            style={{
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'flex-start',
            }}
          >
            {imageSource && (
              <Button
                bg="var(--gray-0)"
                c="var(--blue-500)"
                mr="0.5rem"
                onClick={() => {
                  openRef.current?.();
                }}
              >
                Upload new image
              </Button>
            )}
          </Grid.Col>
        </Grid>
      ) : (
        <Card
          h="22.063rem"
          bg="var(--gray-200)"
          style={{
            justifyContent: 'center',
            borderRadius: '0.5rem',
            alignItems: 'center',
          }}
        >
          <Center style={{ position: 'relative' }}>
            {!imageSource ? (
              <Webcam
                ref={webcamRef}
                audio={false}
                screenshotFormat="image/png"
                style={{
                  width: '20.625rem',
                  height: '20.625rem',
                  marginBottom: '0.625rem',
                  borderRadius: '0.5rem',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <img
                src={imageSource}
                alt="Captured"
                style={{
                  width: '20.625rem',
                  height: '20.625rem',
                  borderRadius: '0.5rem',
                  objectFit: 'cover',
                }}
              />
            )}{' '}
            {imageSource ? (
              <CameraIcon
                width="2.5rem"
                height="2.5rem"
                style={{
                  position: 'absolute',
                  borderRadius: '0.5rem',
                  top: '0.5rem',
                  left: '0.5rem',
                  padding: '0.5rem',
                  backgroundColor: 'var(--gray-0)',
                  color: 'var(--gray-900)',
                  cursor: 'pointer',
                }}
                onClick={() => setImageSource(null)}
              />
            ) : (
              <CameraIcon
                width="2.5rem"
                height="2.5rem"
                style={{
                  position: 'absolute',
                  borderRadius: '0.5rem',
                  top: '0.5rem',
                  left: '0.5rem',
                  padding: '0.5rem',
                  backgroundColor: 'var(--gray-0)',
                  color: 'var(--gray-900)',
                  cursor: 'pointer',
                }}
                onClick={capture}
              />
            )}{' '}
            {imageSource && (
              <CloudArrowUpIcon
                width="2.5rem"
                height="2.5rem"
                style={{
                  borderRadius: '0.5rem',
                  position: 'absolute',
                  top: '0.5rem',
                  right: '0.5rem',
                  padding: '0.5rem',
                  backgroundColor: 'var(--gray-0)',
                  color: 'var(--blue-500)',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  openRef.current?.();
                }}
              />
            )}
          </Center>
        </Card>
      )}
    </>
  );
};

export default CameraAndImageComponent;
