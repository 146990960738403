import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

type MultiStepProviderProps = {
  totalSteps: number;
  children: ReactNode;
};

type MultiStepContext = {
  currentStep: number;
  totalSteps: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  next: () => void;
  back: () => void;
};

const Context = createContext<MultiStepContext | null>(null);

export const MultiStepProvider = ({
  totalSteps,
  children,
}: MultiStepProviderProps) => {
  const [currentStep, setCurrentStep] = useState(1);

  const isLastStep = currentStep === totalSteps;

  const next = useCallback(() => {
    setCurrentStep((step: number) => {
      if (isLastStep) return step;

      return step + 1;
    });
  }, [isLastStep]);

  const back = useCallback(() => {
    setCurrentStep((step: number) => {
      if (step <= 1) return step;
      return step - 1;
    });
  }, []);

  const contextValue = useMemo(
    () =>
      ({
        currentStep,
        totalSteps,
        isFirstStep: currentStep === 1,
        isLastStep,
        next,
        back,
      } as MultiStepContext),
    [currentStep, totalSteps, isLastStep, next, back],
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export const useMultiStep = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useMultiStep must be used within MultiStepProvider!');
  }

  return context as MultiStepContext;
};
