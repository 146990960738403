// import React, { useRef, useState } from 'react';
// import arrowSmallUp from '../../assets/icons/mini/arrow-small-up.svg';
// import arrowSmallDown from '../../assets/icons/mini/arrow-small-down.svg';
// import { StatInterface } from 'components/Stat/Stat.interface';

type Props = {};

//   const modalRef = useRef<any>();
//   const [modalTitle, setModalTitle] = useState('');

//   const elementClickedHandler = (event: React.MouseEvent<HTMLElement>) => {
//     event.preventDefault();
//     if (modalRef.current) {
//       modalRef.current.open();
//       setModalTitle(event.currentTarget.innerHTML);
//     }
//   };

// const stats: Array<StatInterface> = [
//   {
//     title: 'Average per device',
//     amount: '3',
//     icon: arrowSmallUp,
//     badge: 'secondary',
//     value: 12
//   },
//   {
//     title: 'Average per event',
//     amount: '98',
//     icon: arrowSmallUp,
//     badge: 'secondary',
//     value: 5.02
//   },
//   {
//     title: 'Average per month',
//     amount: '76',
//     icon: arrowSmallDown,
//     badge: 'danger',
//     value: 43.05
//   }
// ];
// const devices: Array<DeviceTableData> = [
//   {
//     device: 'Device 1',
//     macAddress: 'F008D1669EBC',
//     manufacturer: 'Manufacturer',
//     lastSeen: new Date(Date.now()).toLocaleDateString(),
//     lastLocation: 'Ground Level',
//     state: 'Online',
//     status: 'Enabled'
//   },
//   {
//     device: 'Device 2',
//     macAddress: 'F00812312669EBC',
//     manufacturer: 'Manufacturer',
//     lastSeen: new Date(Date.now()).toLocaleDateString(),
//     lastLocation: '1st level',
//     state: 'Idle',
//     status: 'Enabled'
//   },
//   {
//     device: 'Device 3',
//     macAddress: 'F008D1669EBC',
//     manufacturer: 'Manufacturer',
//     lastSeen: new Date(Date.now()).toLocaleDateString(),
//     lastLocation: 'Ground Level',
//     state: 'Online',
//     status: 'Enabled'
//   },
//   {
//     device: 'Device 4',
//     macAddress: 'F00812312D1669EBC',
//     manufacturer: 'Manufacturer',
//     lastSeen: new Date(Date.now()).toLocaleDateString(),
//     lastLocation: 'Ground Level',
//     state: 'Offline',
//     status: 'Disabled'
//   }
// ];

// const columns: Array<DeviceTableColumn> = [
//   {
//     id: 'device',
//     displayName: 'Device',
//     accessor: 'device',
//     width: 17,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div style={{ width: '100%', textAlign: 'left' }}>{rowData.device}</div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'macAddress',
//     displayName: 'Mac address',
//     accessor: 'macAddress',
//     width: 17,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div style={{ width: '100%', textAlign: 'left' }}>
//           {rowData.macAddress}
//         </div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'manufacturer',
//     displayName: 'Manufacturer',
//     accessor: 'manufacturer',
//     width: 14,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div style={{ width: '100%', textAlign: 'left' }}>
//           {rowData.manufacturer}
//         </div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'lastSeen',
//     displayName: 'Last seen',
//     accessor: 'lastSeen',
//     width: 14,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div style={{ width: '100%', textAlign: 'left' }}>
//           {rowData.lastSeen}
//         </div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'lastLocation',
//     displayName: 'Last location',
//     accessor: 'lastLocation',
//     width: 13,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div style={{ width: '100%', textAlign: 'left' }}>
//           {rowData.lastLocation}
//         </div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'state',
//     displayName: 'State',
//     accessor: 'state',
//     width: 10,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div style={{ width: '100%', textAlign: 'left' }}>{rowData.state}</div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'status',
//     displayName: 'Status',
//     accessor: 'status',
//     width: 10,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div style={{ width: '100%', textAlign: 'left' }}>{rowData.status}</div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'options',
//     displayName: '',
//     width: 8,
//     accessor: 'status',
//     render: (rowData: any) => {
//       return (
//         <TableOptionsButton
//           actions={[CodebookAction.all]}
//           editLabel="Edit device"
//           deleteLabel="Delete device"
//           disableLabel="Disable device"
//           status={rowData.status}
//         ></TableOptionsButton>
//       );
//     },
//     sortable: true
//   }
// ];

//const Participants = (props: Props) => {
// let [deviceList, setDeviceList] = useState<Array<DeviceTableData>>(devices);
// const columns: Array<DeviceTableColumn> = [
//   {
//     id: 'device',
//     displayName: 'Device',
//     accessor: 'device',
//     width: 17,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div
//           style={{
//             width: '100%',
//             textAlign: 'left',
//             fontWeight: 'bold',
//             color: '#0F172A',
//             cursor: 'pointer'
//           }}
//           onClick={elementClickedHandler}
//         >
//           {rowData.device}
//         </div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'macAddress',
//     displayName: 'Mac address',
//     accessor: 'macAddress',
//     width: 17,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div style={{ width: '100%', textAlign: 'left' }}>
//           {rowData.macAddress}
//         </div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'manufacturer',
//     displayName: 'Manufacturer',
//     accessor: 'manufacturer',
//     width: 14,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div style={{ width: '100%', textAlign: 'left' }}>
//           {rowData.manufacturer}
//         </div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'lastSeen',
//     displayName: 'Last seen',
//     accessor: 'lastSeen',
//     width: 14,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div style={{ width: '100%', textAlign: 'left' }}>
//           {rowData.lastSeen}
//         </div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'lastLocation',
//     displayName: 'Last location',
//     accessor: 'lastLocation',
//     width: 13,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div style={{ width: '100%', textAlign: 'left' }}>
//           {rowData.lastLocation}
//         </div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'state',
//     displayName: 'State',
//     accessor: 'state',
//     width: 10,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div style={{ width: '100%', textAlign: 'left' }}>
//           {rowData.state}
//         </div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'status',
//     displayName: 'Status',
//     accessor: 'status',
//     width: 10,
//     render: (rowData: DeviceTableData) => {
//       return (
//         <div style={{ width: '100%', textAlign: 'left' }}>
//           {rowData.status}
//         </div>
//       );
//     },
//     sortable: true
//   },
//   {
//     id: 'options',
//     displayName: '',
//     width: 8,
//     accessor: 'status',
//     render: (rowData: any) => {
//       return (
//         <TableOptionsButton
//           actions={[CodebookAction.all]}
//           editLabel="Edit device"
//           deleteLabel="Delete device"
//           disableLabel="Disable device"
//           status={rowData.status}
//         ></TableOptionsButton>
//       );
//     },
//     sortable: true
//   }
// ];
// let [deviceList, setDeviceList] = useState<Array<DeviceTableData>>(devices);
const Participants = (props: Props) => {
  // const modalRef = useRef<any>();
  // const [modalTitle, setModalTitle] = useState('');

  // const elementClickedHandler = (event: React.MouseEvent<HTMLElement>) => {
  //   event.preventDefault();
  //   if (modalRef.current) {
  //     modalRef.current.open();
  //     setModalTitle(event.currentTarget.innerHTML);
  //   }
  // };

  // const stats: Array<StatInterface> = [
  //   {
  //     title: 'Average per device',
  //     amount: '3',
  //     icon: arrowSmallUp,
  //     badge: 'secondary',
  //     value: 12,
  //   },
  //   {
  //     title: 'Average per event',
  //     amount: '98',
  //     icon: arrowSmallUp,
  //     badge: 'secondary',
  //     value: 5.02,
  //   },
  //   {
  //     title: 'Average per month',
  //     amount: '76',
  //     icon: arrowSmallDown,
  //     badge: 'danger',
  //     value: 43.05,
  //   },
  // ];
  // const devices: Array<DeviceTableData> = [
  //   {
  //     device: 'Device 1',
  //     macAddress: 'F008D1669EBC',
  //     manufacturer: 'Manufacturer',
  //     lastSeen: new Date(Date.now()).toLocaleDateString(),
  //     lastLocation: 'Ground Level',
  //     state: 'Online',
  //     status: 'Enabled'
  //   },
  //   {
  //     device: 'Device 2',
  //     macAddress: 'F00812312669EBC',
  //     manufacturer: 'Manufacturer',
  //     lastSeen: new Date(Date.now()).toLocaleDateString(),
  //     lastLocation: '1st level',
  //     state: 'Idle',
  //     status: 'Enabled'
  //   },
  //   {
  //     device: 'Device 3',
  //     macAddress: 'F008D1669EBC',
  //     manufacturer: 'Manufacturer',
  //     lastSeen: new Date(Date.now()).toLocaleDateString(),
  //     lastLocation: 'Ground Level',
  //     state: 'Online',
  //     status: 'Enabled'
  //   },
  //   {
  //     device: 'Device 4',
  //     macAddress: 'F00812312D1669EBC',
  //     manufacturer: 'Manufacturer',
  //     lastSeen: new Date(Date.now()).toLocaleDateString(),
  //     lastLocation: 'Ground Level',
  //     state: 'Offline',
  //     status: 'Disabled'
  //   }
  // ];

  // const columns: Array<DeviceTableColumn> = [
  //   {
  //     id: 'device',
  //     displayName: 'Device',
  //     accessor: 'device',
  //     width: 17,
  //     render: (rowData: DeviceTableData) => {
  //       return (
  //         <div
  //           style={{
  //             width: '100%',
  //             textAlign: 'left',
  //             fontWeight: 'bold',
  //             color: '#0F172A',
  //             cursor: 'pointer'
  //           }}
  //           onClick={elementClickedHandler}
  //         >
  //           {rowData.device}
  //         </div>
  //       );
  //     },
  //     sortable: true
  //   },
  //   {
  //     id: 'macAddress',
  //     displayName: 'Mac address',
  //     accessor: 'macAddress',
  //     width: 17,
  //     render: (rowData: DeviceTableData) => {
  //       return (
  //         <div style={{ width: '100%', textAlign: 'left' }}>
  //           {rowData.macAddress}
  //         </div>
  //       );
  //     },
  //     sortable: true
  //   },
  //   {
  //     id: 'manufacturer',
  //     displayName: 'Manufacturer',
  //     accessor: 'manufacturer',
  //     width: 14,
  //     render: (rowData: DeviceTableData) => {
  //       return (
  //         <div style={{ width: '100%', textAlign: 'left' }}>
  //           {rowData.manufacturer}
  //         </div>
  //       );
  //     },
  //     sortable: true
  //   },
  //   {
  //     id: 'lastSeen',
  //     displayName: 'Last seen',
  //     accessor: 'lastSeen',
  //     width: 14,
  //     render: (rowData: DeviceTableData) => {
  //       return (
  //         <div style={{ width: '100%', textAlign: 'left' }}>
  //           {rowData.lastSeen}
  //         </div>
  //       );
  //     },
  //     sortable: true
  //   },
  //   {
  //     id: 'lastLocation',
  //     displayName: 'Last location',
  //     accessor: 'lastLocation',
  //     width: 13,
  //     render: (rowData: DeviceTableData) => {
  //       return (
  //         <div style={{ width: '100%', textAlign: 'left' }}>
  //           {rowData.lastLocation}
  //         </div>
  //       );
  //     },
  //     sortable: true
  //   },
  //   {
  //     id: 'state',
  //     displayName: 'State',
  //     accessor: 'state',
  //     width: 10,
  //     render: (rowData: DeviceTableData) => {
  //       return (
  //         <div style={{ width: '100%', textAlign: 'left' }}>
  //           {rowData.state}
  //         </div>
  //       );
  //     },
  //     sortable: true
  //   },
  //   {
  //     id: 'status',
  //     displayName: 'Status',
  //     accessor: 'status',
  //     width: 10,
  //     render: (rowData: DeviceTableData) => {
  //       return (
  //         <div style={{ width: '100%', textAlign: 'left' }}>
  //           {rowData.status}
  //         </div>
  //       );
  //     },
  //     sortable: true
  //   },
  //   {
  //     id: 'options',
  //     displayName: '',
  //     width: 8,
  //     accessor: 'status',
  //     render: (rowData: any) => {
  //       return (
  //         <TableOptionsButton
  //           actions={[CodebookAction.all]}
  //           editLabel="Edit device"
  //           deleteLabel="Delete device"
  //           disableLabel="Disable device"
  //           status={rowData.status}
  //         ></TableOptionsButton>
  //       );
  //     },
  //     sortable: true
  //   }
  // ];
  // let [deviceList, setDeviceList] = useState<Array<DeviceTableData>>(devices);
  // const [loading, setLoading] = useState(true);
  // const [selectedEntirePage, setSelectedEntirePage] = useState<boolean>(false);
  // const [selectedData, setSelectedData] = useState<Array<DeviceTableData>>([]);

  return (
    <div></div>
    //<div className={styles.Participants}>
    /*{ <Header title="Participants"></Header>
      <div className={styles.stats}>
        {stats.map((item: StatInterface, index) => {
          return (
            <div
              key={item.title}
              className={
                index !== stats.length - 1 ? styles.stat : styles.statLast
              }
            >
              <Stat
                title={item.title}
                amount={item.amount}
                icon={item.icon}
                badge={item.badge}
                value={item.value}
              ></Stat>
            </div>
          );
        })}
      </div>
      <Table
        addFormComponent={<AddParticipantForm></AddParticipantForm>}
        addFormTitle="Add new participant"
        addButtonTitle="Add participant"
        selectedData={selectedData}
        selectedEntirePage={selectedEntirePage}
        data={deviceList}
        columns={columns}
        enableRowStatusIndicator
        getRowStatusIndicatorColor={(rowData: DeviceTableData) => {
          switch (rowData.state) {
            case 'Online':
              return '#10B981';
            case 'Offline':
              return '#CBD5E1';
            case 'Idle':
              return '#EAB308';
            default:
              return '';
          }
        }}
        loading={loading}
        actions={[CodebookAction.all]}
      ></Table> }
      ></Table>
      <Modal title={modalTitle}>
        <DetailsParticipantForm
          firstName="Test"
          lastName="Test5"
        ></DetailsParticipantForm>
      </Modal>
    </div>*/
  );
};

export default Participants;
