import {
  TagIcon,
  UserIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/solid';
import {
  Card,
  Text,
  Group,
  Collapse,
  Box,
  Image,
  Stack,
  Flex,
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import CardSettingsDropdown from 'pages/Entities/Hub/LeftHubContainer/HubCard/CardSettingsDropdown';
import { useState } from 'react';
import { convertFirstLetterToUppercase } from 'utils/consts';

import { ListOfAdmissionItemProps } from '../types';

const ListOfAdmissionItem = ({
  image,
  trackingObjectType,
  trackingObjectName,
  trackingObjectCode,
  tags,
  trackingObjectTypeName,
  status,
  id,
}: ListOfAdmissionItemProps) => {
  const [opened, setOpened] = useState<boolean>(false);
  const { width } = useViewportSize();

  return (
    <Card
      padding="lg"
      bg={opened ? 'var(--gray-50)' : '#FFFFFF'}
      style={{
        minHeight: '4.75rem',
        cursor: 'pointer',
        border: '1px solid var(--gray-200)',
        borderRadius: '8px',
        position: 'relative',
      }}
      onClick={() => setOpened((o) => !o)}
    >
      <div
        style={{
          position: 'absolute',
          top: '1.25rem',
          bottom: '1.25rem',
          left: '0.75rem',
          width: '4px',
          backgroundColor:
            status === 'online' || status === 'active'
              ? 'var(--green-500)'
              : status === 'offline' || status === 'inactive'
              ? 'var(--gray-300)'
              : 'var(--gray-300)',
          borderRadius: '2px',
        }}
      />

      <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {image ? (
          <Box
            w={opened ? '1.56rem' : '3.125rem'}
            h={opened ? '1.56rem' : '3.125rem'}
            ml={8}
            style={{
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          >
            <Image
              src={image}
              alt="image"
              width="100%"
              height="100%"
              fit="cover"
            />
          </Box>
        ) : trackingObjectType ? (
          <Box
            w={opened ? '1.56rem' : '3.125rem'}
            h={opened ? '1.56rem' : '3.125rem'}
            bg="var(--gray-100)"
            ml={8}
            style={{
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {trackingObjectType === 'asset' ? (
              <WrenchScrewdriverIcon
                width={opened ? '1rem' : '2rem'}
                height={opened ? '1rem' : '2rem'}
                color="var(--gray-300)"
              />
            ) : (
              <UserIcon
                width={opened ? '1rem' : '2rem'}
                height={opened ? '1rem' : '2rem'}
                color="var(--gray-300)"
              />
            )}
          </Box>
        ) : null}

        <Box style={{ flex: 1 }}>
          <Flex align="center" gap={2}>
            <Text
              fw={500}
              fz={12}
              style={{
                maxWidth: 150,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              c="var(--gray-900)"
            >
              {trackingObjectName ? trackingObjectName : '-'}
              {trackingObjectCode ? ` (${trackingObjectCode})` : ''}
            </Text>
          </Flex>
          {!opened && tags && (Array.isArray(tags) ? tags.length > 0 : true) && (
            <Flex align="center" gap={2}>
              <TagIcon
                height="1.15rem"
                width="1.15rem"
                color="var(--gray-400)"
              />
              <Text
                fz={11}
                c="dimmed"
                truncate="end"
                style={{ maxWidth: width - 320 }}
              >
                {Array.isArray(tags)
                  ? `${tags[0]?.number}(${tags[0]?.uniqueId})`
                  : `${tags.number}(${tags.uniqueId})`}
              </Text>
            </Flex>
          )}
        </Box>
      </Box>

      <Box
        style={{
          position: 'absolute',
          right: '0.2rem',
          top: '1.25rem',
        }}
      >
        <CardSettingsDropdown
          canUnassignTag={Array.isArray(tags) ? tags.length > 0 : !!tags}
          dataId={id}
          cardType="admission"
          trackingObjectTags={tags}
          trackingObjectName={trackingObjectName}
        />
      </Box>

      <Collapse in={opened}>
        <Box mt="md" pl={10}>
          <Flex align="center" gap={2} mb="0.75rem">
            {trackingObjectType === 'person' ? (
              <UserIcon
                height="1.25rem"
                width="1.25rem"
                color="var(--gray-400)"
              />
            ) : trackingObjectType === 'asset' ? (
              <WrenchScrewdriverIcon
                height="1.25rem"
                width="1.25rem"
                color="var(--gray-400)"
              />
            ) : null}
            <Text fz={12} c="var(--gray-400)">
              {trackingObjectTypeName
                ? convertFirstLetterToUppercase(trackingObjectTypeName)
                : ''}
            </Text>
          </Flex>

          {tags && (Array.isArray(tags) ? tags.length > 0 : true) && (
            <Group justify="space-between" align="flex-start">
              <Stack gap="0.75rem" style={{ overflow: 'hidden' }}>
                {Array.isArray(tags) ? (
                  tags.map((x) => (
                    <Flex align="center" gap={2} key={x._id}>
                      <TagIcon
                        height="1.25rem"
                        width="1.25rem"
                        color="var(--gray-400)"
                      />
                      <Text
                        fz={12}
                        c="var(--gray-400)"
                        truncate="end"
                        style={{ maxWidth: width - 260 }}
                      >
                        {x.number}
                      </Text>
                    </Flex>
                  ))
                ) : (
                  <Flex align="center" gap={2}>
                    <TagIcon
                      height="1.25rem"
                      width="1.25rem"
                      color="var(--gray-400)"
                    />
                    <Text
                      fz={12}
                      c="var(--gray-400)"
                      truncate="end"
                      style={{ maxWidth: width - 260 }}
                    >
                      {tags.number}
                    </Text>
                  </Flex>
                )}
              </Stack>
            </Group>
          )}
        </Box>
      </Collapse>
    </Card>
  );
};

export default ListOfAdmissionItem;
