import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

import { UserState } from './interfaces';
import { loginUser, logoutUser } from './userActions';

const initialState: UserState = {
  user: {
    id: '',
    loggedIn: false,
    firstname: '',
    lastname: ''
  },
  loading: 'idle'
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserData: (state, action: PayloadAction<any>) => {
      state.user = {
        id: action.payload.userId,
        loggedIn: true,
        firstname: action.payload.firstName,
        lastname: action.payload.lastName
      };
    },
    logout: (state) => {
      logoutUser();
      state.user = {
        id: '',
        loggedIn: false,
        firstname: '',
        lastname: ''
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(
      loginUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = 'succeeded';
        state.user = {
          id: action.payload.userId,
          loggedIn: true,
          firstname: action.payload.firstName,
          lastname: action.payload.lastName
        };
      }
    );
    builder.addCase(loginUser.rejected, (state) => {
      state.loading = 'failed';
    });
  }
});

export const getUser = (state: RootState) => state.user;

export const { getUserData, logout } = userSlice.actions;

export default userSlice.reducer;
