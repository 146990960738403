import { Bars3BottomRightIcon, CheckIcon } from '@heroicons/react/24/solid';
import { ActionIcon, Menu } from '@mantine/core';
import { useState } from 'react';

import { SortingDropdownProps } from './types';

const SortingDropdown = ({
  sortingParams,
  sortingParam,
  setSortingParam,
}: SortingDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>();

  return (
    <Menu
      width={160}
      position="bottom-end"
      offset={0}
      closeOnItemClick={false}
      onOpen={() => setIsDropdownOpen(true)}
      onClose={() => setIsDropdownOpen(false)}
    >
      <Menu.Target>
        <ActionIcon bg={isDropdownOpen ? '#e2e8f0' : '#ffffff'} c="#0F172A">
          <Bars3BottomRightIcon height="1.5rem" width="1.5rem" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        {sortingParams.map((currentSortingParam) => (
          <Menu.Item
            key={`sorting-item-${currentSortingParam.value}`}
            c={sortingParam === currentSortingParam ? '#3B82F6' : '#475569'}
            rightSection={
              sortingParam === currentSortingParam ? (
                <CheckIcon height="1.25rem" width="1.25rem" color="#3B82F6" />
              ) : null
            }
            onClick={() =>
              setSortingParam((prev) =>
                prev === currentSortingParam ? null : currentSortingParam,
              )
            }
          >
            {currentSortingParam.value}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default SortingDropdown;
