import { Flex } from '@mantine/core';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import { useEntitiesContext } from 'context/EntityProvider';
import { openModal } from 'pages/Entities/ModalManager/ModalsManager';
import { useEffect, useRef } from 'react';
import { useDeleteEntityLayout } from 'services/entity/layout';
import { ModalWidth } from 'utils/consts';

import { setHubModalContent } from '../../Modals/hubModalsConfig';
import { CardContainerProps } from '../../types';
import HubCard from '../HubCard/HubCard';

const CardContainer = ({
  data,
  eventId,
  isMobile,
  setIsAtBottom,
}: CardContainerProps) => {
  const { selectedSection } = useEntitiesContext();
  const viewportRef = useRef<HTMLDivElement>(null);

  const { mutate: mutateDeleteEntityLayout } = useDeleteEntityLayout();

  const handleDelete = (id) => {
    if (!eventId) return;
    if (selectedSection.type === 'trackingObjects')
      console.log(`Tracking object id: ${id}`);
    if (selectedSection.type === 'layouts')
      mutateDeleteEntityLayout({ eventId, layoutIds: [id] });
    if (selectedSection.type === 'devices') console.log(`Device id: ${id}`);
    if (selectedSection.type === 'tags') console.log(`Tag id: ${id}`);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (viewportRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = viewportRef.current;
        const threshold = 100;
        const atBottom = scrollHeight - scrollTop - clientHeight <= threshold;

        if (setIsAtBottom) {
          setIsAtBottom(atBottom);
        }
      }
    };

    const viewport = viewportRef.current;
    if (viewport) {
      viewport.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (viewport) {
        viewport.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <ScrollArea
      h="44.5rem"
      mr="0.6rem"
      pr="0.5rem"
      type={isMobile ? 'never' : 'auto'}
      viewportRef={viewportRef}
    >
      <Flex direction="column" gap="0.75rem">
        {data?.map((x, currentCardIndex, cardsArray) => (
          <HubCard
            key={x.id}
            id={x.id}
            numberOfElements={data.length}
            trackingObjectType={x.trackingObjectType}
            image={x.image}
            cardType={x.cardType}
            status={x.status}
            trackingObjectName={x.trackingObjectName}
            trackingObjectCode={x.trackingObjectCode}
            lastSeen={x.lastSeen}
            trackingObjectTypeName={x.trackingObjectTypeName}
            trackingObjectTags={x.trackingObjectTags}
            layoutName={x.layoutName}
            sectionName={x.sectionName}
            maxCapacity={x.maxCapacity}
            capacity={x.capacity}
            layoutAgeNumberRange={x.layoutAgeNumberRange}
            numberOfDevices={x.numberOfDevices}
            trafficStatus={x.trafficStatus}
            deviceName={x.deviceName}
            deviceStatus={x.deviceStatus}
            deviceType={x.deviceType}
            macAddress={x.macAddress}
            mapped={x.mapped}
            tagCode={x.tagCode}
            tagName={x.tagName}
            trackingObject={x.trackingObject}
            handleClickOnCard={() => {
              if (selectedSection.type === 'sections') {
                // navigate()
              } else
                openModal({
                  dataId: x.id,
                  type: selectedSection.type,
                  size: ModalWidth.ExtraLarge,
                  isCard: true,
                  title: x.trackingObjectName || x.layoutName || x.tagName,
                  titleIcon: (
                    <selectedSection.icon
                      height="1.25rem"
                      width="1.25rem"
                      color="#94a3b8"
                    />
                  ),
                  actionButtonLabel:
                    selectedSection.type === 'trackingObjects'
                      ? 'Edit tracking object'
                      : selectedSection.type === 'layouts'
                      ? 'Edit layout'
                      : 'Edit',
                  actionButtonFn: () =>
                    openModal({
                      dataId: x.id,
                      type: selectedSection.type,
                      size: selectedSection.modalSize,
                      title: selectedSection.modalTitle,
                      subtitles: selectedSection.modalSubTitles,
                      isMultistep: selectedSection.type === 'trackingObjects',
                      hasFooter: selectedSection.hasFooter,
                      setModalContent: setHubModalContent,
                    }),
                  removeButtonFn: (id) => handleDelete(id),
                  cardsArray,
                  currentCardIndex,
                  setModalContent: setHubModalContent,
                });
            }}
          />
        ))}
      </Flex>
    </ScrollArea>
  );
};

export default CardContainer;
