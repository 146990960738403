import { useMutation, useQueryClient } from '@tanstack/react-query';
import { eventStatisticsBaseKey } from 'hooks/event/statistics';
import { client } from 'services/api';
import { DELETE_EVENT } from 'utils/apiUrl';

import { eventBaseKey } from './baseKey';

interface DeleteEventProps {
  id: string;
}

export const useDeleteEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: DeleteEventProps) =>
      await client.delete(DELETE_EVENT(id)),
    onSuccess: () => {
      queryClient.invalidateQueries([eventBaseKey]);
      queryClient.invalidateQueries([eventStatisticsBaseKey]);
    },
  });
};
