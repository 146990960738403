import { ReactNode } from 'react';
import MultiProgress from 'react-multi-progress';

import style from './ProgressSteps.module.scss';

type Props = {
  title: ReactNode;
  currentStep: number;
  totalSteps: number;
};

const ProgressSteps = (props: Props) => {
  const steps = Array.from({ length: props.totalSteps }, (_, i) =>
    i < props.currentStep ? true : false,
  );

  return (
    <div>
      <div className={style.progressStepsTitle}>{props.title}</div>

      <div className={style.progressSteps}>
        {steps.map((step, index) => (
          <MultiProgress
            key={index}
            elements={[
              {
                value: 100,
                color: step ? '#3B82F6' : '#F1F5F9',
              },
            ]}
            height="6"
          />
        ))}
      </div>
    </div>
  );
};

export default ProgressSteps;
