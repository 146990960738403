import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { EventLayout } from 'pages/Events/Layout/interfaces';
import { client } from 'services/api';
import type { Response } from 'types/response';
import { GET_EVENT_LAYOUT, GET_SINGLE_EVENT_LAYOUT } from 'utils/apiUrl';
import { stringToDate } from 'utils/stringToDate';

import { eventLayoutBaseKey } from './eventLayoutBaseKey';

interface Props {
  eventId: string;
  layoutId: string;
}

export const useFetchSingleEventLayout = ({ eventId, layoutId }: Props) => {
  const queryResult = useQuery<Response<EventLayout> | null, AxiosError>({
    queryKey: [eventLayoutBaseKey, eventId, layoutId],
    queryFn: async () => {
      if (!eventId || !layoutId) {
        return null;
      }
      const retVal = await client.get<Response<EventLayout>>(
        GET_SINGLE_EVENT_LAYOUT(eventId, layoutId),
      );

      return retVal.data;
    },
  });

  return queryResult;
};
