import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventTagsDeleteService } from 'services/event/tag/event-tags-delete';

import { eventTagBaseKey } from './baseKeys';

interface DeleteEventTagProps {
  eventId: string;
  tagId?: string;
  tagIds?: string[];
}

export const useMutateDeleteEventTag = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ eventId, tagId, tagIds }: DeleteEventTagProps) => {
      if (!eventId) return;
      const resp =
        tagId === undefined
          ? await EventTagsDeleteService.deleteEventTags({ eventId, tagIds })
          : await EventTagsDeleteService.deleteEventTag({ eventId, tagId });

      return resp?.data;
    },
    onSuccess: (_, { eventId, tagId }) => {
      queryClient.invalidateQueries([eventTagBaseKey]);
      // queryClient.invalidateQueries([eventTagGetAssignableKey]);
    },
  });

  return mutation;
};
