import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SortingParamType } from 'components/SortingDropdown/types';
import { Filter } from 'context/types';
import { client } from 'services/api';

import { GlobalTagData, TagData, TagEventData } from './types';

const getTagsByEvent = async (
  eventId: string,
  limit: number,
  searchTerm: string,
  filter: Filter,
  sortingParam?: SortingParamType,
): Promise<TagEventData> => {
  const params = new URLSearchParams({
    limit: limit.toString(),
    offset: '0',
    filter: searchTerm,
    fetchTotalCount: String(false),
    fetchDataAndFilterCount: String(true),
  });

  if (filter?.states?.length) {
    filter.states.forEach((state: string) => params.append('state[]', state));
  }

  if (filter?.statuses?.length) {
    filter.statuses.forEach((status: string) =>
      params.append('status[]', status),
    );
  }

  if (sortingParam) {
    params.append('sortField', sortingParam.field);
    params.append('sortOrder', sortingParam.order.toString());
  }

  const url = `event/${eventId}/tag/all?${params.toString()}`;

  const response = await client.get(url);

  return response.data.data;
};

const getTagByEvent = async (
  eventId: string,
  tagId: string,
): Promise<TagData> => {
  const url = `event/${eventId}/tag/${tagId}`;

  const response = await client.get(url);

  return response.data.data;
};

const getTags = async (
  limit: number,
  searchTerm: string,
  filter: Filter,
  sortingParam?: SortingParamType,
): Promise<GlobalTagData> => {
  const params = new URLSearchParams({
    limit: limit.toString(),
    offset: '0',
    filter: searchTerm,
    fetchTotalCount: String(false),
    fetchDataAndFilterCount: String(true),
  });

  if (filter?.states?.length) {
    filter.states.forEach((state: string) => params.append('state[]', state));
  }

  if (filter?.statuses?.length) {
    filter.statuses.forEach((status: string) =>
      params.append('status[]', status),
    );
  }

  if (sortingParam) {
    params.append('sortField', sortingParam.field);
    params.append('sortOrder', sortingParam.order.toString());
  }

  const url = `/tag/all?${params.toString()}`;

  const response = await client.get(url);

  return response.data.data;
};

const addTagsToEvent = async (eventId: string, tagIds: string[] | []) => {
  const url = `event/${eventId}/tag/addMany`;

  const response = await client.post(url, { tagIds: tagIds });

  return response.data;
};

const deleteTagsByEvent = async (eventId: string, tagIds: string[]) => {
  const url = `event/${eventId}/tag/`;

  const response = await client.delete(url, { data: tagIds });

  return response.data;
};

export const useTagsByEvent = (
  eventId: string | null,
  limit: number,
  searchTerm: string,
  filter: Filter,
  sortingParam?: SortingParamType,
) => {
  return useQuery<TagEventData, Error>({
    queryKey: ['tagsByEvent', eventId, limit, searchTerm, filter, sortingParam],
    queryFn: () =>
      getTagsByEvent(eventId!, limit, searchTerm, filter, sortingParam),
    enabled: !!eventId && eventId.length > 0 && limit > 0,
  });
};

export const useTagByEvent = (eventId: string, tagId: string) => {
  return useQuery<TagData, Error>({
    queryKey: ['tagByEvent', eventId, tagId],
    queryFn: () => getTagByEvent(eventId, tagId),
    enabled: !!eventId && eventId.length > 0 && !!tagId && tagId.length > 0,
  });
};

export const useTags = (
  limit: number,
  searchTerm: string,
  filter: Filter,
  sortingParam?: SortingParamType,
) => {
  return useQuery<GlobalTagData, Error>({
    queryKey: ['tags', limit, searchTerm, filter, sortingParam],
    queryFn: () => getTags(limit, searchTerm, filter, sortingParam),
    enabled: limit > 0,
  });
};

export const useAddTagsToEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      eventId,
      tagIds,
    }: {
      eventId: string;
      tagIds: string[] | [];
    }) => addTagsToEvent(eventId, tagIds),

    onSuccess: (response, { eventId }) => {
      queryClient.invalidateQueries({
        queryKey: ['tagsByEvent', eventId],
      });
      queryClient.invalidateQueries({
        queryKey: ['hubSectionStatistics', eventId, 'tag'],
      });
    },
    onError: (error: Error) => {
      console.error('Error: ', error.message);
    },
  });
};

export const useDeleteTagsByEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ eventId, tagIds }: { eventId: string; tagIds: string[] }) =>
      deleteTagsByEvent(eventId, tagIds),

    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['tagsByEvent', variables.eventId]);
      queryClient.invalidateQueries({
        queryKey: ['hubSectionStatistics', variables.eventId, 'tag'],
      });
    },
    onError: (error: Error) => {
      console.error('Error: ', error.message);
    },
  });
};
