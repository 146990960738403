import { DropdownItem, DropdownMenu } from "@lanaco/lnc-react-ui";
import {
	type ColumnDef,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	type RowData,
	type RowSelectionState,
	type Table,
	type TableOptions,
	useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { Icon, type SVGIconProps } from "components/Icon/Icon";
import IndeterminateCheckbox from "components/IndeterminateCheckbox/IndeterminateCheckbox";
import { TLLoadingSpinner } from "components/Spinner";
import type React from "react";
import { type SetStateAction, useEffect, useMemo, useState } from "react";

import Pagination from "./components/Pagination";
import TableMarkup, { type TableMarkupProps } from "./components/TableMarkup";
import styles from "./TanStackTable.module.scss";

export interface RowAction {
	key: string;
	/**
	 * The name of the icon to display.
	 * @link https://fontawesome.com/v5/search?m=free
	 */
	icon: SVGIconProps["icon"];
	label: string;
	color?:
		| "primary"
		| "secondary"
		| "success"
		| "warning"
		| "danger"
		| "information"
		| "neutral";
	className?: string;
	onClick: () => void;
}

export interface TanStackTableProps<T extends RowData>
	extends Pick<
			TableOptions<T>,
			| "pageCount"
			| "data"
			| "columns"
			| "enableRowSelection"
			| "defaultColumn"
			| "enableSorting"
			| "onRowSelectionChange"
			| "enableMultiRowSelection"
		>,
		Pick<TableMarkupProps<T>, "cellClassName" | "rowClassName"> {
	pageSize?: number;
	totalRows?: number;
	pageIndex?: number;
	isLoading?: boolean;
	showHeader?: boolean;
	rowActions?: RowAction[] | ((row: T) => RowAction[]);
	headerTitle?: React.ReactNode;
	selectedRows?: RowSelectionState;
	tableActions?: React.ReactNode;
	statusIndicatorClassName?: string | ((row: T) => string | undefined);
	onPageChange: (page: number) => void;
	getRowId: TableOptions<T>["getRowId"];
	meta?: TableOptions<T>["meta"];
	initialState?: TableOptions<T>["initialState"];
	tableRef?: React.MutableRefObject<Table<T> | undefined>;
	tableClassName?: string;
	tableContainerClassName?: string;
}

export const TanStackTable = <T extends RowData>({
	data,
	columns: columnProps,
	pageSize = 10,
	tableRef,
	isLoading,
	totalRows = 0,
	pageCount = 0,
	pageIndex = 1,
	showHeader,
	rowActions,
	headerTitle,
	tableActions,
	rowClassName,
	selectedRows,
	cellClassName,
	defaultColumn,
	enableSorting = false,
	enableRowSelection,
	statusIndicatorClassName,
	onRowSelectionChange,
	onPageChange,
	getRowId,
	meta,
	initialState,
	enableMultiRowSelection,
	tableClassName,
	tableContainerClassName,
}: TanStackTableProps<T>) => {
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

	const handleRowSelectionChange = (
		rowSelection: SetStateAction<RowSelectionState>,
	) => {
		setRowSelection(rowSelection);
		onRowSelectionChange?.(rowSelection);
	};

	const columns = useMemo(() => {
		const retVal: ColumnDef<T>[] = enableRowSelection
			? [
					{
						id: "select-buttons",
						enableResizing: false,
						maxSize: 48,
						header: ({ table }) => (
							<IndeterminateCheckbox
								checked={table.getIsAllRowsSelected()}
								indeterminate={table.getIsSomeRowsSelected()}
								onChange={table.getToggleAllRowsSelectedHandler()}
							/>
						),
						cell: ({ row }) => (
							<IndeterminateCheckbox
								checked={row.getIsSelected()}
								indeterminate={row.getIsSomeSelected()}
								onChange={row.getToggleSelectedHandler()}
							/>
						),
					},
					...columnProps,
				]
			: columnProps;

		if (statusIndicatorClassName) {
			retVal.unshift({
				id: "statusIndicator",
				header: "",
				enableResizing: false,
				maxSize: 10,
				size: 10,
				cell: ({ row }) => {
					const className =
						typeof statusIndicatorClassName === "function"
							? statusIndicatorClassName(row.original)
							: statusIndicatorClassName;

					return <span className={clsx(styles.statusIndicator, className)} />;
				},
			});
		}

		if (rowActions) {
			retVal.push({
				id: "actions",
				header: "",
				enableResizing: false,
				maxSize: 52,
				cell: ({ row }) => {
					const actions =
						typeof rowActions === "function"
							? rowActions(row.original)
							: rowActions;

					// application breaks when this is uncommented
					return (
						<div className={styles.dropdownContainer}>
							<DropdownMenu
								control={
									<button className={styles.actionsDropdown}>
										<Icon icon="ellipsis-h" size="24px" />
									</button>
								}
								placement="bottom-end"
								zIndex={2}
							>
								{actions.map(({ label, ...action }, index) => (
									<DropdownItem {...action}>{label}</DropdownItem>
								))}
							</DropdownMenu>
						</div>
					);
				},
			});
		}

		return retVal;
	}, [columnProps, enableRowSelection, rowActions, statusIndicatorClassName]);

	const table = useReactTable<T>({
		data,
		columns,
		defaultColumn: {
			cell: (props) => props.getValue(),
			...defaultColumn,
		},
		pageCount,
		enableSorting,
		initialState,
		enableRowSelection,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		enableColumnResizing: true,
		columnResizeMode: "onChange",
		onRowSelectionChange: handleRowSelectionChange,
		getRowId,
		enableMultiRowSelection,
		manualPagination: true,
		meta,
		state: {
			rowSelection: selectedRows || rowSelection,
			pagination: {
				pageSize,
				pageIndex,
			},
		},
	});

	useEffect(() => {
		if (tableRef) {
			tableRef.current = table;
		}
	}, [selectedRows, table, tableRef]);

	return (
		<div className={clsx(styles.tableContainer, tableContainerClassName)}>
			{showHeader && (
				<div className={styles.tableHeader}>
					{headerTitle}
					{tableActions && (
						<div className={styles.tableActions}>{tableActions}</div>
					)}
				</div>
			)}
			<div
				className={clsx(styles.loaderContainer, {
					[styles.emptyTable]: !data.length,
				})}
			>
				<TableMarkup
					table={table}
					rowClassName={rowClassName}
					tableClassName={tableClassName}
					cellClassName={(cell) =>
						clsx(
							typeof cellClassName === "function"
								? cellClassName(cell)
								: cellClassName,
							{
								[styles.statusIndicatorCell]:
									cell.column.id === "statusIndicator",
								[styles.actionsCell]: cell.column.id === "actions",
								[styles.selectButtonsCell]: cell.column.id === "select-buttons",
							},
						)
					}
				/>
				{isLoading && <TLLoadingSpinner className={styles.loader} />}
			</div>
			<div className={styles.tableFooter}>
				{table.getIsSomeRowsSelected() || table.getIsAllRowsSelected() ? (
					<span className={styles.selectedPageCount}>
						<strong>{table.getSelectedRowModel().flatRows.length}</strong> of{" "}
						<strong>{totalRows}</strong> results selected
					</span>
				) : (
					<span>
						{totalRows > 0 && (
							<>
								Showing <strong>{(pageIndex - 1) * pageSize + 1}</strong> to{" "}
								<strong>
									{(pageIndex - 1) * pageSize +
										Math.min(pageSize, table.getRowModel().rows.length)}
								</strong>{" "}
								of <strong>{totalRows}</strong> results
							</>
						)}
						{totalRows === 0 && <strong>No results found</strong>}
					</span>
				)}
				<Pagination
					currentPage={pageIndex}
					onPageChange={(i) => onPageChange(i)}
					pageSize={pageSize}
					totalCount={totalRows}
				/>
			</div>
		</div>
	);
};

export default TanStackTable;
