import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SortingParamType } from 'components/SortingDropdown/types';
import { Filter } from 'context/types';
import { CreateTrackingObject } from 'pages/Entities/Hub/types';
import { client } from 'services/api';

import { TagByTrackingObjectAndEvent, TrackingObjectByEvent } from './types';

const uploadTrackingObjectImage = async (
  image: File,
  eventId: string,
  trackingObjectId: string,
) => {
  const formData = new FormData();
  formData.append('heroImage', image);

  const url = `event/${eventId}/participant/${trackingObjectId}/hero`;

  const response = await client.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response.data;
};

const addTrackingObjectByEvent = async (
  eventId: string,
  data: CreateTrackingObject,
) => {
  const url = `event/${eventId}/participant/`;

  const response = await client.post(url, data);

  return response.data;
};

const updateTrackingObjectByEvent = async (
  eventId: string,
  trackingObjectId: string,
  data: CreateTrackingObject,
) => {
  const url = `event/${eventId}/participant/${trackingObjectId}`;

  const response = await client.put(url, data);

  return response.data;
};

const updatePrimaryTagTrackingObjectByEvent = async (
  eventId: string,
  trackingObjectId: string,
  tagId: string,
) => {
  const url = `event/${eventId}/participant/${trackingObjectId}`;

  const response = await client.put(url, { tagId: tagId });

  return response.data;
};

const assignTagsForTrackingObjectByEvent = async (
  eventId: string,
  trackingObjectId: string,
  tagIds: string[] | [],
) => {
  const url = `event/${eventId}/participant/${trackingObjectId}/assign`;

  const response = await client.post(url, { tagIds: tagIds });

  return response.data;
};

const unassignTagsForTrackingObjectByEvent = async (
  eventId: string,
  trackingObjectId: string,
  tagIds: string[] | [],
) => {
  const url = `event/${eventId}/participant/${trackingObjectId}/unassign`;

  const response = await client.post(url, { tagIds: tagIds });

  return response.data;
};

const deleteTrackingObjectByEvent = async (
  eventId: string,
  trackingObjectId: string,
) => {
  const url = `event/${eventId}/participant/${trackingObjectId}`;

  const response = await client.delete(url);

  return response.data;
};

const getTrackingObjectsByEvent = async (
  eventId: string,
  limit: number,
  searchTerm: string,
  filter: Filter,
  sortingParam?: SortingParamType,
): Promise<TrackingObjectByEvent> => {
  const params = new URLSearchParams({
    limit: limit.toString(),
    offset: '0',
    filter: searchTerm,
    fetchTotalCount: String(false),
    fetchDataAndFilterCount: String(true),
  });

  if (filter?.types?.length) {
    filter.types.forEach((type: string) => params.append('type[]', type));
  }

  if (filter?.classifications?.length) {
    filter.classifications.forEach((classification: string) =>
      params.append('subtype[]', classification),
    );
  }

  if (filter?.states?.length) {
    filter.states.forEach((state: string) => params.append('state[]', state));
  }

  if (filter?.statuses?.length) {
    filter.statuses.forEach((status: string) =>
      params.append('status[]', status),
    );
  }

  if (sortingParam) {
    params.append('sortField', sortingParam.field);
    params.append('sortOrder', sortingParam.order.toString());
  }

  const url = `event/${eventId}/participant/all?${params.toString()}`;

  const response = await client.get(url);

  return response.data.data;
};

const getTrackingObjectByEvent = async (
  eventId: string,
  trackingObjectId: string,
): Promise<TrackingObjectByEvent> => {
  const params = new URLSearchParams({
    fetchTotalCount: String(false),
    fetchDataAndFilterCount: String(true),
  });

  if (trackingObjectId) {
    params.append('id[]', trackingObjectId);
  }

  const url = `event/${eventId}/participant/all?${params.toString()}`;

  const response = await client.get(url);

  return response.data.data;
};

const getTagByTrackingObjectAndEvent = async (
  eventId: string,
  trackingObjectId: string,
  limit: number,
  searchTerm: string,
): Promise<TagByTrackingObjectAndEvent[] | []> => {
  const params = new URLSearchParams({
    fetchTotalCount: String(false),
    fetchDataAndFilterCount: String(true),
    limit: limit.toString(),
    offset: '0',
    filter: searchTerm,
    participantId: trackingObjectId,
  });

  const url = `event/${eventId}/tag/?${params.toString()}`;

  const response = await client.get(url);

  return response.data.data;
};

export const useUploadTrackingObjectImage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      image,
      eventId,
      trackingObjectId,
    }: {
      image: File;
      eventId: string;
      trackingObjectId: string;
    }) => {
      return uploadTrackingObjectImage(image, eventId, trackingObjectId);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['trackingObjectsByEvent', variables.eventId],
      });
    },
    onError: (error: Error) => {
      console.error('Error:', error.message);
    },
  });
};

export const useAddTrackingObjectByEvent = () => {
  const uploadTrackingObjectImage = useUploadTrackingObjectImage();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      eventId,
      data,
    }: {
      eventId: string;
      data: CreateTrackingObject;
    }) => addTrackingObjectByEvent(eventId, data),

    onSuccess: (response, { eventId, data }) => {
      const trackingObjectId = response.data._id;

      if (data.image) {
        uploadTrackingObjectImage.mutate({
          image: data.image,
          eventId,
          trackingObjectId,
        });
      }

      queryClient.invalidateQueries({
        queryKey: ['trackingObjectsByEvent', eventId],
      });
    },
    onError: (error: Error) => {
      console.error('Error: ', error.message);
    },
  });
};

export const useTrackingObjectsByEvent = (
  eventId: string | null,
  limit: number,
  searchTerm: string,
  filter: Filter,
  sortingParam?: SortingParamType,
) => {
  return useQuery<TrackingObjectByEvent, Error>({
    queryKey: [
      'trackingObjectsByEvent',
      eventId,
      limit,
      searchTerm,
      filter,
      sortingParam,
    ],
    queryFn: () =>
      getTrackingObjectsByEvent(
        eventId!,
        limit,
        searchTerm,
        filter,
        sortingParam,
      ),
    enabled: !!eventId && eventId.length > 0 && limit > 0,
  });
};

export const useDeleteTrackingObjectByEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      eventId,
      trackingObjectId,
    }: {
      eventId: string;
      trackingObjectId: string;
    }) => deleteTrackingObjectByEvent(eventId, trackingObjectId),

    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([
        'trackingObjectsByEvent',
        variables.eventId,
      ]);
    },
    onError: (error: Error) => {
      console.error('Error: ', error.message);
    },
  });
};

export const useTrackingObjectByEvent = (
  eventId: string,
  trackingObjectId: string,
) => {
  return useQuery<TrackingObjectByEvent, Error>({
    queryKey: ['trackingObjectByEvent', eventId, trackingObjectId],
    queryFn: () => getTrackingObjectByEvent(eventId, trackingObjectId),
    enabled:
      !!eventId &&
      eventId.length > 0 &&
      !!trackingObjectId &&
      trackingObjectId.length > 0,
  });
};

export const useUpdateTrackingObjectByEvent = () => {
  const uploadTrackingObjectImage = useUploadTrackingObjectImage();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      eventId,
      trackingObjectId,
      data,
    }: {
      eventId: string;
      trackingObjectId: string;
      data: CreateTrackingObject;
    }) => updateTrackingObjectByEvent(eventId, trackingObjectId, data),

    onSuccess: (response, { eventId, data }) => {
      const trackingObjectId = response.data._id;

      if (data.image) {
        uploadTrackingObjectImage.mutate({
          image: data.image,
          eventId,
          trackingObjectId,
        });
      }

      queryClient.invalidateQueries({
        queryKey: ['trackingObjectsByEvent', eventId],
      });
    },
    onError: (error: Error) => {
      console.error('Error: ', error.message);
    },
  });
};

export const useUpdatePrimaryTagTrackingObjectByEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      eventId,
      trackingObjectId,
      tagId,
    }: {
      eventId: string;
      trackingObjectId: string;
      tagId: string;
    }) =>
      updatePrimaryTagTrackingObjectByEvent(eventId, trackingObjectId, tagId),

    onSuccess: (response, { eventId }) => {
      queryClient.invalidateQueries({
        queryKey: ['trackingObjectsByEvent', eventId],
      });
    },
    onError: (error: Error) => {
      console.error('Error: ', error.message);
    },
  });
};

export const useTagByTrackingObjectAndEvent = (
  eventId: string,
  trackingObjectId: string,
  limit: number,
  searchTerm: string,
) => {
  return useQuery<TagByTrackingObjectAndEvent[] | [], Error>({
    queryKey: [
      'tagByTrackingObjectAndEvent',
      eventId,
      trackingObjectId,
      limit,
      searchTerm,
    ],
    queryFn: () =>
      getTagByTrackingObjectAndEvent(
        eventId,
        trackingObjectId,
        limit,
        searchTerm,
      ),
    enabled:
      !!eventId &&
      eventId.length > 0 &&
      !!trackingObjectId &&
      trackingObjectId.length > 0,
  });
};

export const useAssignTagsForTrackingObjectByEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      eventId,
      trackingObjectId,
      tagIds,
    }: {
      eventId: string;
      trackingObjectId: string;
      tagIds: string[] | [];
    }) => assignTagsForTrackingObjectByEvent(eventId, trackingObjectId, tagIds),

    onSuccess: (response, { eventId }) => {
      queryClient.invalidateQueries({
        queryKey: ['trackingObjectsByEvent', eventId],
      });
      queryClient.invalidateQueries({
        queryKey: ['listOfAdmissionByEvent', eventId],
      });
      queryClient.invalidateQueries({
        queryKey: ['tagsByEvent', eventId],
      });
    },
    onError: (error: Error) => {
      console.error('Error: ', error.message);
    },
  });
};

export const useUnassignTagsForTrackingObjectByEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      eventId,
      trackingObjectId,
      tagIds,
    }: {
      eventId: string;
      trackingObjectId: string;
      tagIds: string[] | [];
    }) =>
      unassignTagsForTrackingObjectByEvent(eventId, trackingObjectId, tagIds),

    onSuccess: (response, { eventId }) => {
      queryClient.invalidateQueries({
        queryKey: ['trackingObjectsByEvent', eventId],
      });
      queryClient.invalidateQueries({
        queryKey: ['listOfAdmissionByEvent', eventId],
      });
      queryClient.invalidateQueries({
        queryKey: ['tagsByEvent', eventId],
      });
    },
    onError: (error: Error) => {
      console.error('Error: ', error.message);
    },
  });
};
