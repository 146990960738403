import { Grid } from '@mantine/core';

// import FiltersHubContainer from './FiltersHubContainer/FiltersHubContainer';
import LeftHubContainer from './LeftHubContainer/LeftHubContainer';
import RightHubContainer from './RightHubContainer/RightHubContainer';

const EntityHub = () => {
  return (
    <>
      {/* <FiltersHubContainer
        trackingObjects={trackingObjects}
        sections={sections}
        layouts={layouts}
        devices={devices}
        tags={tags}
      /> */}

      <Grid>
        <Grid.Col span="auto">
          <LeftHubContainer />
        </Grid.Col>

        <Grid.Col span="content">
          <RightHubContainer />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default EntityHub;
