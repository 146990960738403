import { XMarkIcon } from '@heroicons/react/24/solid';
import { ActionIcon, Box, Button, Flex, Text } from '@mantine/core';
import LineStepIndicator from 'components/LineStepIndicator/LineStepIndicator';
import { useState } from 'react';

import { MultiStepModalProps } from './types';

const MultiStepModal = ({
  dataId,
  type,
  title = 'Title',
  maxSteps,
  subtitles,
  actionButtonFn = () => {},
  closeButtonFn,
  setModalContent,
}: MultiStepModalProps) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [formState, setFormState] = useState<any>({});
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [onFormSubmit, setOnFormSubmit] = useState<
    (formStateData?: any) => void
  >(() => {});

  return (
    <Flex direction="column" gap="2rem">
      <Box>
        <Flex justify="space-between">
          <Text fs="0.75rem" fw={500} c="#64748b">
            {title}
          </Text>
          <ActionIcon
            bg="#ffffff"
            onClick={() => {
              closeButtonFn && closeButtonFn();
              setFormState({});
              setIsFormValid(false);
            }}
          >
            <XMarkIcon height="1.25rem" width="1.25rem" color="#475569" />
          </ActionIcon>
        </Flex>

        <LineStepIndicator activeStep={activeStep} titles={subtitles} />
      </Box>

      <Box>
        {setModalContent
          ? setModalContent({
              dataId,
              type,
              step: activeStep,
              formState,
              setFormState,
              isFormValid,
              setIsFormValid,
              setOnFormSubmit,
            })
          : 'Modal has no content. Pass it via the "content" or "setModalContent" props'}
      </Box>

      <Flex justify="space-between">
        <Button
          bg="#ffffff"
          bd="1px solid #e2e8f0"
          c="#344155"
          onClick={() => {
            if (activeStep > 1 && activeStep !== maxSteps)
              setActiveStep(activeStep - 1);
            else {
              closeButtonFn && closeButtonFn();
              setFormState({});
            }
          }}
        >
          {activeStep > 1 && activeStep !== maxSteps ? 'Back' : 'Cancel'}
        </Button>

        <Flex gap="1rem">
          <Button
            bg="#ffffff"
            bd="1px solid #e2e8f0"
            c="#3b82f6"
            disabled={!isFormValid}
            onClick={() => {
              if (activeStep !== maxSteps) setActiveStep(activeStep + 1);
              else setActiveStep(activeStep - 1);
            }}
          >
            {activeStep !== maxSteps ? 'Next' : 'Back'}
          </Button>
          <Button
            bg="#3b82f6"
            c="#ffffff"
            disabled={!isFormValid}
            onClick={() => {
              // kada se klikne ovdje submituje se forma i salje se formState
              onFormSubmit(formState);
              actionButtonFn();
              setActiveStep(1);
              closeButtonFn && closeButtonFn();
              setFormState({});
              setIsFormValid(false);
            }}
          >
            {activeStep !== maxSteps ? 'Add & Close' : 'Add'}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MultiStepModal;
