import { TrackingObjectByEventItem } from 'services/entity/types';

import { HubCardType } from '../Hub/types';

export const mapAdmissionToHubCard = (
  participant: TrackingObjectByEventItem,
): HubCardType => {
  return {
    cardType: 'admission',
    id: participant._id,
    status: participant.state,
    trackingObjectName: participant.firstLastName,
    trackingObjectCode: participant?.externalId ?? '',
    trackingObjectType: participant.trackedSubjectType.type,
    trackingObjectTypeName: participant.trackedSubjectType.subType,
    trackingObjectTags: participant?.eventTag,
    image: participant?.heroImage?.largeSource ?? undefined,
  };
};
