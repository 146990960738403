import type { TimingLocationPayload } from 'pages/Events/MultiStepForm/timingLocation-schema';
import type { EventTimingLocations as TimingLocationEntity } from 'pages/Events/TimingLocations/TimingLocations.interfaces';
import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import type { APIAxiosResponse } from 'services/types';
import { CREATE_TIMING_LOCATION, UPDATE_TIMING_LOCATION } from 'utils/apiUrl';

export class TimingLocationService extends ResponseInterceptor {
  static async createTimingLocation(
    eventId: string,
    layoutId: string,
    body: TimingLocationPayload,
  ): Promise<APIAxiosResponse<TimingLocationEntity>> {
    const res = await client.post(
      CREATE_TIMING_LOCATION(eventId, layoutId),
      body,
    );
    return TimingLocationService.handleResponse(res);
  }

  static async updateTimingLocation(
    eventId: string,
    oldLayoutId: string,
    sectionId: string,
    body: TimingLocationPayload,
  ): Promise<APIAxiosResponse<TimingLocationEntity>> {
    const res = await client.put(
      UPDATE_TIMING_LOCATION(eventId, oldLayoutId, sectionId),
      body,
    );

    return TimingLocationService.handleResponse(res);
  }
}
