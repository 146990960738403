import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { client } from 'services/api';
import { GET_PARTICIPANT_STATISTICS } from 'utils/apiUrl';

import { participantStatisticsBaseKey } from './baseKeys';
import { statisticsRefetchInterval } from './statisticsRefetchInterval';

interface ParticipantStatistics {
  averageByEvent: number;
  eventsFromLastSixMonthsToNextFiveWithParticipants: {
    month: Date;
    participants: number;
  }[];
  totalParticipants: number;
  totalParticipantsFromCurrentlyActiveEvents: number;
  totalParticipantsInPastEvents: number;
  totalParticipantsInUpcomingEvents: number;
}

export const useFetchParticipantStatistics = () => {
  const queryResult = useQuery<ParticipantStatistics, AxiosError>({
    queryKey: [participantStatisticsBaseKey],
    refetchInterval: statisticsRefetchInterval,
    queryFn: async () => {
      const retVal = await client.get(GET_PARTICIPANT_STATISTICS());

      retVal.data.data.eventsFromLastSixMonthsToNextFiveWithParticipants = retVal.data.data.eventsFromLastSixMonthsToNextFiveWithParticipants.map(
        (event) => ({
          ...event,
          month: new Date(event.month),
        }),
      );

      return retVal.data.data;
    },
  });

  return queryResult;
};
