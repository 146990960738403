import type { DocumentStatus } from 'types/enums';

import type { Image } from './Layout/interfaces';
import type { EventLayoutSection } from './TimingLocations/interfaces';

export enum TrackedSubjectType {
  ASSET = 'asset',
  PERSON = 'person',
}

export enum AssetSubtype {
  OTHER = 'other',
  Tools = 'tools',
  Machine = 'machine',
  Vehicle = 'vehicle',
  Inventory = 'inventory',
  Valuables = 'valuables',
  Documents = 'documents',
  ITEquipment = 'it_equipment',
  Other = 'other',
}

export enum PersonSubtype {
  Media = 'media',
  Visitor = 'visitor',
  Employee = 'employee',
  Participant = 'participant',
  Other = 'other',
}

export interface EventParticipant {
  _id: string;
  eventId: string;
  tagId?: string;
  firstName?: string;
  lastName?: string;
  firstLastName: string;
  lastFirstName: string;
  gender?: string;
  birthday?: string;
  email?: string | null;
  externalId?: string;
  phoneNumber?: string | null;
  postcode?: string | null;
  country?: string | null;
  status: DocumentStatus;
  organisation?: string;
  trackedSubjectType: {
    type: TrackedSubjectType;
    assetSubtype?: AssetSubtype;
    personSubtype?: PersonSubtype;
  };
}

export interface Company {
  assignedAt: string;
  companyId: string;
  _id: string;
}

interface Owner {
  companyId: string;
}

interface Tag {
  _id: string;
  institutionId: string;
  company: Company;
  companyHistory: Company[];
  number: string;
  uniqueId: string;
  status: string;
  owner: Owner;
  __v: number;
  createdAt: string;
  updatedAt: string;
  manufacturer: string;
  name: string;
}

export interface EventTag {
  _id: string;
  eventId: string;
  tagId: string;
  number: string;
  uniqueId: string;
  status: string;
  __v: number;
  createdAt: string;
  updatedAt: string;
  tag: Tag;
  participant: EventParticipant;
  section: EventLayoutSection;
  lastSeen: string;
}

export type EventStatus = 'live' | 'upcoming' | 'past';

export interface Company {
  _id: string;
  institutionId: string;
  name: string;
  nameShort: string;
  status: string;
  code: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface Event {
  _id: string;
  institutionId: number;
  allowedDevices: Array<string>;
  companyId: Company;
  timezone: string;
  startDate: Date;
  finishDate: Date;
  name: string;
  description: string;
  status: string;
  type: string;
  client?: Company;
  eventStatus: EventStatus;
  registration?: Registration;
  metadata: object;
  socials: SocialMedia;
  contactPerson: ContactInfo;
  location: Location;
  eventUrl: string;
  heroImage: Image;
}

export interface IFormFile {
  id: string;
  institutionId: number;
  companyId: number;
  startDate: Date;
  finishDate: Date;
  name: string;
  description: string;
  status: string;
}

export interface ContactInfo {
  name: string;
  email: string;
  phoneNumber: string;
}

export interface SocialMedia {
  facebook: string;
  instagram: string;
  linkedin: string;
  tikTok: string;
  twitter: string;
  youtube: string;
}

export interface Registration {
  startDate: Date;
  finishDate: Date;
  url: string;
}

export interface Location {
  description: string;
  latitude: number;
  longitude: number;
}

export interface Type {
  _id: string;
  type: string;
  description: string;
  group: string;
  tags: string[];
  orderNumber: number;
}
