import { FlexGrid, FlexGridItem } from '@lanaco/lnc-react-ui';
import { Link } from 'react-router-dom';

import style from '../Events.module.css';

type Props = {
  data?: any;
};

const DetailsOrganisationInfo = (props: Props) => {
  return (
    <div className={style.timing_location_details_organisation_info}>
      <div className={style.timing_location_details_organisation_info_header}>
        <FlexGrid>
          <FlexGridItem XS={12} L={6}>
            <label
              className={style.timing_location_details_about_content_title}
              style={{ fontWeight: '600', fontSize: '18px' }}
            >
              {props?.data?.name}
            </label>
            <p className={style.timing_location_details_about_content_item}>
              {props?.data?.description}
            </p>{' '}
          </FlexGridItem>
        </FlexGrid>
      </div>
      <div
        className={
          style.timing_location_details_organisation_info_details_contact_person
        }
      >
        <div
          className={style.timing_location_details_organisation_info_details}
        >
          <FlexGrid>
            <FlexGridItem XS={12} L={12}>
              <div
                className={
                  style.timing_location_details_about_content_header_title
                }
              >
                Details
              </div>
            </FlexGridItem>
            <FlexGridItem XS={12} L={6}>
              <label
                className={style.timing_location_details_about_content_title}
              >
                Email
              </label>
              <p className={style.timing_location_details_about_content_item}>
                {props.data?.email}
              </p>{' '}
            </FlexGridItem>
            <FlexGridItem XS={12} L={6}>
              <label
                className={style.timing_location_details_about_content_title}
              >
                Phone number
              </label>
              <p className={style.timing_location_details_about_content_item}>
                {props.data?.phoneNumber}
              </p>{' '}
            </FlexGridItem>
            <FlexGridItem XS={12} L={6}>
              <label
                className={style.timing_location_details_about_content_title}
              >
                Address
              </label>
              <p className={style.timing_location_details_about_content_item}>
                {props.data?.address}
              </p>{' '}
            </FlexGridItem>
            <FlexGridItem XS={12} L={6}>
              <label
                className={style.timing_location_details_about_content_title}
              >
                Postal code
              </label>
              <p className={style.timing_location_details_about_content_item}>
                {props.data?.postalCode}
              </p>{' '}
            </FlexGridItem>
            <FlexGridItem XS={12} L={6}>
              <label
                className={style.timing_location_details_about_content_title}
              >
                Country
              </label>
              <p className={style.timing_location_details_about_content_item}>
                {props.data?.country}
              </p>{' '}
            </FlexGridItem>
            <FlexGridItem XS={12} L={6}>
              <label
                className={style.timing_location_details_about_content_title}
              >
                Website
              </label>
              <p className={style.timing_location_details_about_content_item}>
                <Link
                  to={{ pathname: `${'//{props.website}'}` }}
                  target={'_blank'}
                >
                  {props.data?.website}
                </Link>
              </p>{' '}
            </FlexGridItem>

            <FlexGridItem XS={12} L={12}>
              <label
                className={style.timing_location_details_about_content_title}
              >
                Social media
              </label>
              <p
                className={
                  style.timing_location_details_about_content_item_social_media
                }
              >
                {props.data?.socialMedia[0] && (
                  <Link
                    to={{
                      pathname: `${'//' + props.data?.socialMedia[0].url}`,
                    }}
                    target={'_blank'}
                  >
                    Facebook
                  </Link>
                )}
                {props.data?.socialMedia[1] && (
                  <Link
                    to={{
                      pathname: `${'//' + props.data?.socialMedia[1].url}`,
                    }}
                    target={'_blank'}
                  >
                    Instagram
                  </Link>
                )}
                {props.data?.socialMedia[2] && (
                  <Link
                    to={{
                      pathname: `${'//' + props.data?.socialMedia[2].url}`,
                    }}
                    target={'_blank'}
                  >
                    LinkedIn
                  </Link>
                )}
                {props.data?.socialMedia[3] && (
                  <Link
                    to={{
                      pathname: `${'//' + props.data?.socialMedia[3].url}`,
                    }}
                    target={'_blank'}
                  >
                    TikTok
                  </Link>
                )}
                {props.data?.socialMedia[4] && (
                  <Link
                    to={{
                      pathname: `${'//' + props.data?.socialMedia[4].url}`,
                    }}
                    target={'_blank'}
                  >
                    Twitter
                  </Link>
                )}
                {props.data?.socialMedia[5] && (
                  <Link
                    to={{
                      pathname: `${'//' + props.data?.socialMedia[5].url}`,
                    }}
                    target={'_blank'}
                  >
                    YouTube
                  </Link>
                )}
              </p>{' '}
            </FlexGridItem>
          </FlexGrid>
        </div>
        <div
          className={
            style.timing_location_details_organisation_info_contact_person
          }
        >
          <FlexGrid>
            <FlexGridItem XS={12} L={12}>
              <div
                className={
                  style.timing_location_details_about_content_header_title
                }
              >
                Contact person
              </div>
            </FlexGridItem>
            <FlexGridItem XS={12} L={12}>
              <label
                className={style.timing_location_details_about_content_title}
              >
                Name
              </label>
              <p className={style.timing_location_details_about_content_item}>
                {props.data.contactPerson[0]?.fullName}
              </p>{' '}
            </FlexGridItem>
            <FlexGridItem XS={12} L={12}>
              <label
                className={style.timing_location_details_about_content_title}
              >
                Position
              </label>
              <p className={style.timing_location_details_about_content_item}>
                {props.data.contactPerson[0]?.position}
              </p>{' '}
            </FlexGridItem>
            <FlexGridItem XS={12} L={12}>
              <label
                className={style.timing_location_details_about_content_title}
              >
                Phone number
              </label>
              <p className={style.timing_location_details_about_content_item}>
                {props.data.contactPerson[0]?.phoneNumber}
              </p>{' '}
            </FlexGridItem>
            <FlexGridItem XS={12} L={12}>
              <label
                className={style.timing_location_details_about_content_title}
              >
                Email
              </label>
              <p className={style.timing_location_details_about_content_item}>
                {props.data.contactPerson[0]?.email}
              </p>{' '}
            </FlexGridItem>
          </FlexGrid>
        </div>
      </div>
    </div>
  );
};

export default DetailsOrganisationInfo;
