import {
  ChartPieIcon,
  MapIcon,
  HomeIcon,
  InformationCircleIcon,
  ClipboardIcon,
  LockOpenIcon,
  ClipboardDocumentCheckIcon,
  CogIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/24/solid';

import EntityAbout from './About/EntityAbout';
import EntityAdmission from './Admission/EntityAdmission';
import ControlAccessSettings from './ControlAccess/ControlAccessSettings';
import ControlAccessTrackingObjects from './ControlAccess/ControlAccessTrackingObjects';
import EntityControlAccess from './ControlAccess/EntityControlAccess';
import EntityDashboard from './Dashboard/EntityDashboard';
import EntityHub from './Hub/EntityHub';
import EntityMonitoring from './Monitoring/EntityMonitoring';
import EntitySchedule from './Schedule/EntitySchedule';
import EntitySettings from './Settings/EntitySettings';
import { TabConfig } from './types';

export const entityDetailsTabs: Record<string, TabConfig> = {
  hub: {
    label: 'Hub',
    icon: ChartPieIcon,
    panel: EntityHub,
  },
  monitoring: {
    label: 'Monitoring',
    icon: MapIcon,
    panel: EntityMonitoring,
  },
  dashboard: {
    label: 'Dashboard',
    icon: HomeIcon,
    panel: EntityDashboard,
  },
  about: {
    label: 'About',
    icon: InformationCircleIcon,
    panel: EntityAbout,
  },
  schedule: {
    label: 'Schedule',
    icon: ClipboardIcon,
    panel: EntitySchedule,
  },
  control: {
    label: 'Control Access',
    icon: LockOpenIcon,
    panel: EntityControlAccess,
  },
  admission: {
    label: 'Admission',
    icon: ClipboardDocumentCheckIcon,
    panel: EntityAdmission,
  },
  settings: {
    label: 'Settings',
    icon: CogIcon,
    panel: EntitySettings,
  },
};

export const controlAccessDetailsTabs: Record<string, TabConfig> = {
  settings: {
    label: 'Settings',
    icon: CogIcon,
    panel: ControlAccessSettings,
  },
  trackingObjects: {
    label: 'Tracking objects',
    icon: ViewfinderCircleIcon,
    panel: ControlAccessTrackingObjects,
  },
};
