import { createAsyncThunk } from '@reduxjs/toolkit';
import { parseJwt } from 'config/utils';
import {
  LoginData,
  LoginResponse,
  LoginResponseData,
} from 'pages/Login/interfaces';
import { client } from 'services/api';
import { logOut, setSession } from 'services/auth';
import { getSocket } from 'services/socket';
import { AUTH_LOGIN } from 'utils/apiUrl';

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (data: LoginData) => {
    let resp = await client.post(AUTH_LOGIN, data);
    if (resp && resp.data) {
      let response: LoginResponse = resp.data;

      if (response.success) {
        let { accessToken, refreshToken }: LoginResponseData = response.data;
        const userData = await parseJwt(accessToken);
        setSession(accessToken, refreshToken);
        getSocket().reconnect();
        if (accessToken) {
          return userData;
        }
      }
    }
  },
);

export const loginFor = async () => { };

export const logoutUser = async () => {
  await logOut();
  getSocket().disconnect();
};
