import { notification } from '@lanaco/lnc-react-ui';
import PromptModal from 'components/PromptModal/PromptModal';
import { useDeleteEvent } from 'hooks/event';
import { Event } from 'pages/Events/interfaces';
import { useNavigate } from 'react-router';
import { getErrorMsg } from 'utils/getErrorMsg';

interface DeleteEventModalProps {
  id: string;
  name?: string;
  closeModal: () => void;
  onEventEdit: (device: Event | null) => void;
  redirectToHome?: boolean;
}

export const DeleteEventModal = ({
  id,
  name,
  closeModal,
  onEventEdit,
  redirectToHome,
}: DeleteEventModalProps) => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useDeleteEvent();

  const hideDeleteModal = () => {
    closeModal();
    onEventEdit(null);
  };

  const confirmDeleteEvent = async () => {
    mutate(
      {
        id,
      },
      {
        onSuccess: (data) => {
          if (data.status === 201) {
            notification.success('Event successfully deleted.');
            if (redirectToHome) {
              navigate('/events');
            }
          } else {
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          hideDeleteModal();
        },
      },
    );
  };

  return (
    <PromptModal
      title="Delete event?"
      isLoading={isLoading}
      warningMessage={
        <>
          Are you sure you want to delete event {name}?
          <br />
          This action cannot be undone.
        </>
      }
      confirmButtonName="Delete"
      icon="exclamation-triangle"
      iconSize="24"
      iconColor="danger"
      isDeleteButton
      onConfirm={confirmDeleteEvent}
      onCancel={hideDeleteModal}
    />
  );
};
