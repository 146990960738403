import {
  Button,
  FlexGrid,
  FlexGridItem,
  FormField,
  PasswordInput,
  TextInput,
} from '@lanaco/lnc-react-ui';
import { useAppSelector } from 'hooks/useAppDispatch';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { client } from 'services/api';
import { getUser } from 'store/slices/userSlice/userSlice';
import { CHANGE_PASSWORD, SEND_CONFIRMATION_CODE } from 'utils/apiUrl';

import {
  ResetPasswordData,
  ResetPasswordEmail,
} from './ResetPassword.interfaces';
import style from './ResetPassword.module.css';

type Props = {};

const ResetPassword = (props: Props) => {
  // let dispatch = useAppDispatch();
  let user = useAppSelector(getUser).user;
  const [confirmationCodeIsSend, setConfirmationCodeIsSend] = useState(false);
  const [nextIsClicked, setnextIsClicked] = useState(false);
  const [userExists, setUserExists] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ResetPasswordData>();
  const navigate = useNavigate();
  const onSubmitSendVerificationCode: SubmitHandler<ResetPasswordEmail> = async (
    data,
  ) => {
    const response = await client.post(SEND_CONFIRMATION_CODE, data);

    if (response.data.code === 200) {
      setConfirmationCodeIsSend(true);
    } else {
      setConfirmationCodeIsSend(false);
      setUserExists(true);
    }
  };

  const onSubmit: SubmitHandler<ResetPasswordData> = async (data) => {
    const response = await client.put(CHANGE_PASSWORD, data);

    if (response.data.code === 200) {
      // notification.success(
      //   <NotificationMessage title={'Info'}>
      //     User successfully registered.
      //   </NotificationMessage>,
      //   {},
      // );
      navigate('/login');
    } else {
      // notification.error(
      //   <NotificationMessage title={'Error'}>
      //     {response.data.message}
      //   </NotificationMessage>,
      //   {},
      // );
    }
  };

  if (user.loggedIn) {
    return <Navigate to="/" />;
  }

  const onClickHandler: SubmitHandler<ResetPasswordData> = async (data) => {
    setnextIsClicked(true);
  };

  return (
    <FlexGrid>
      <FlexGridItem XS={12} M={12}>
        <div className={style.center}>
          <div className={style.sidebar}>
            <span className={style.naslov1}>Reset password</span>

            {!confirmationCodeIsSend && (
              <div>
                <div className={style.naslov2}>
                  Please enter your email so we can send you a confirmation
                  code.
                </div>
                <form
                  onSubmit={handleSubmit(onSubmitSendVerificationCode)}
                  className={style.resetPassword}
                >
                  <FormField
                    // label="Email"
                    text={errors.email && <span>{errors.email?.message}</span>}
                    color={errors.email && 'danger'}
                  >
                    <TextInput
                      placeholder={'Email'}
                      {...register('email', {
                        required: {
                          value: true,
                          message: 'Field Email is required.',
                        },
                      })}
                      color={errors.email && 'danger'}
                    />
                  </FormField>
                  {userExists ? (
                    <label className={style.errorLabel}>
                      We cannot find account with this email.
                    </label>
                  ) : null}
                  <Button className={style.loginButton}>
                    Send confirmation code
                  </Button>
                  <div className={style.forgottenPass}>
                    <a href="/login">Back to login</a>
                  </div>
                </form>
              </div>
            )}
            {confirmationCodeIsSend && !nextIsClicked && (
              <form
                className={style.resetPassword}
                onSubmit={handleSubmit(onClickHandler)}
              >
                <span className={style.naslov2}>
                  A verfication code has been send to email
                  info@aniactivity.com. Check your inbox or spam.
                </span>
                <FormField
                  // label="Email"
                  text={errors.code && <span>{errors.code?.message}</span>}
                  color={errors.code && 'danger'}
                >
                  <TextInput
                    placeholder={'Code'}
                    {...register('code', {
                      required: {
                        value: true,
                        message: 'Field Code is requied.',
                      },
                    })}
                    color={errors.code && 'danger'}
                  />
                </FormField>
                <button className={style.loginButton}>Next</button>
                <div className={style.forgottenPass}>
                  <NavLink to={'/login'}>Back to login</NavLink>
                </div>
              </form>
            )}

            {nextIsClicked && (
              <>
                <span className={style.naslov2}>
                  Provide a new password for your account.
                </span>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={style.resetPassword}
                >
                  <FormField
                    // label="Password"
                    text={
                      errors.password && <span>{errors.password?.message}</span>
                    }
                    color={errors.password && 'danger'}
                  >
                    <PasswordInput
                      placeholder={'Password'}
                      {...register('password', {
                        required: {
                          value: true,
                          message: 'Field Password is requied.',
                        },
                        minLength: {
                          value: 8,
                          message: 'Password must be at least 8 characters',
                        },
                      })}
                      color={errors.password && 'danger'}
                    />
                  </FormField>
                  <FormField
                    // label="Password"
                    text={
                      errors.passwordConfirm && (
                        <span>{errors.passwordConfirm?.message}</span>
                      )
                    }
                    color={errors.passwordConfirm && 'danger'}
                  >
                    <PasswordInput
                      placeholder={'Password Confirm'}
                      {...register('passwordConfirm', {
                        required: {
                          value: true,
                          message: 'Field Password Confirm is requied.',
                        },
                        minLength: {
                          value: 8,
                          message: 'Password must be at least 8 characters',
                        },
                        validate: (value) =>
                          value === watch('password') ||
                          'Passwords do not match',
                      })}
                      color={errors.passwordConfirm && 'danger'}
                    />
                  </FormField>
                  {/* <span>
                    {' '}
                    {watch('passwordConfirm') !== watch('password') &&
                    getValues('passwordConfirm') ? (
                      <p>Passwords not match</p>
                    ) : null}
                  </span> */}
                  <button className={style.loginButton}>Reset</button>
                </form>
              </>
            )}
          </div>
        </div>
      </FlexGridItem>
    </FlexGrid>
  );
};

export default ResetPassword;
