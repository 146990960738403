import { format } from 'date-fns';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DateTime } from 'luxon';
dayjs.extend(relativeTime);
// export const formatDateTime = (_date: Date | string): string => {
//   const date = new Date(_date);
//   const currentYear = new Date().getFullYear();

//   if (date.getFullYear() === currentYear) {
//     return format(date, 'dd MMM hh:mm a');
//   }

//   return format(date, 'dd MMM yyyy hh:mm a');
// };

export const formatDate = (_date: Date | string): string => {
  const date = new Date(_date);
  const currentYear = new Date().getFullYear();

  if (date.getFullYear() === currentYear) {
    return format(date, 'dd MMM');
  }

  return format(date, 'dd MMM yyyy');
};

export const formatDateTime = (
  dateTime: Date | string,
  timezone: string,
): { date: string; time: string } => {
  if (dateTime) {
    const formatedDateTime = DateTime.fromISO(dateTime, {
      zone: 'UTC' + timezone,
    });

    const formattedDate = formatedDateTime.toFormat('dd MMM yyyy');
    const formattedTime = formatedDateTime.toFormat('HH:mm');

    return { date: formattedDate, time: formattedTime };
  } else {
    return { date: '', time: '' };
  }
};

export const formatDateTimeFull = (
  dateTime: Date | string,
  timezone?: string,
): string => {
  if (dateTime) {
    const formattedDate = DateTime.fromISO(dateTime, {
      zone: timezone || 'UTC',
    });

    return formattedDate.toFormat('dd MMM yyyy hh:mm a');
  } else {
    return '';
  }
};

export const calculateTimeElapsed = (timestamp: string) => {
  return dayjs(timestamp).fromNow();
};

export const returnDateAndTimeFromDateTime = (
  isoString: string,
  timeZone: string,
): { date: string; time: string } => {
  const dateTime = dayjs.utc(isoString).tz(timeZone, true);
  return {
    date: dateTime.format('YYYY-MM-DD'),
    time: dateTime.format('HH:mm'),
  };
};
