import { PlusIcon } from '@heroicons/react/24/solid';
import { ActionIcon, Flex, Loader, Text } from '@mantine/core';
import HubMultiProgress from 'components/MultiProgressWrapper/MultiProgressWrapper';

import { HubSectionProps } from '../../types';

import classNames from './HubSection.module.scss';

const HubSection = ({
  active,
  titleIcon: TitleIcon,
  title,
  totalNumber,
  progressBarData,
  handleClickOnSection,
  handleClickOnAddButton,
  loader,
}: HubSectionProps) => {
  return (
    <Flex
      w="25.625rem"
      mb="0.75rem"
      bd="1px solid var(--gray-200)"
      direction="column"
      classNames={{ root: classNames.container }}
      onClick={handleClickOnSection}
    >
      {loader ? (
        <Flex justify="center" align="center" h="6rem">
          <Loader color="blue" />
        </Flex>
      ) : (
        <>
          <Flex
            h="3.75rem"
            p="0 1rem"
            bg={active ? 'var(--blue-100)' : 'var(--gray-0)'}
            justify="space-between"
            align="center"
          >
            <Flex align="center">
              <TitleIcon
                height="1.5rem"
                width="1.5rem"
                color={active ? 'var(--blue-500)' : 'var(--gray-400)'}
                className={classNames.icon}
              />
              <Text
                inline
                c={active ? 'var(--blue-500)' : 'var(--gray-900)'}
                fz="1.125rem"
                fw={600}
              >
                {title}
              </Text>
            </Flex>

            <Flex align="center" gap="0.75rem">
              <Text
                inline
                c={active ? 'var(--blue-500)' : 'var(--gray-500)'}
                fz="1.125rem"
                fw={500}
              >
                {totalNumber?.toLocaleString()}
              </Text>

              <ActionIcon
                w="1.75rem"
                h="1.75rem"
                bg={active ? 'var(--neutral-0)' : 'var(--blue-50)'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickOnAddButton();
                }}
              >
                <PlusIcon
                  height="1.5rem"
                  width="1.5rem"
                  color="var(--blue-500)"
                />
              </ActionIcon>
            </Flex>
          </Flex>

          <Flex
            p="1rem 1.5rem 1.5rem 1.5rem"
            bg={active ? 'var(--blue-50)' : 'var(--gray-50)'}
            direction="column"
            gap="1rem"
          >
            {totalNumber &&
              progressBarData?.map((x, index) => (
                <HubMultiProgress
                  key={index}
                  leftNumberProgressBar={x.leftNumberProgressBar}
                  rightNumberProgressBar={x.rightNumberProgressBar}
                  total={totalNumber}
                />
              ))}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default HubSection;
