import { ExclamationTriangleIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Box, Button, Center, Flex, Text } from '@mantine/core';

import { DeleteModalProps } from './types';

const DeleteModal = ({
  variant = 'warning',
  title,
  body,
  actionButtonLabel = 'Delete',
  actionButtonFn,
  closeButtonLabel = 'Cancel',
  closeButtonFn,
}: Omit<DeleteModalProps, 'modalId' | 'size'>) => {
  return (
    <Box w="17rem">
      <Center
        h="3rem"
        w="3rem"
        mx="auto"
        mb="1.25rem"
        bg={variant === 'warning' ? '#ffe4e6' : '#f1f5f9'}
        style={{ borderRadius: '100%' }}
      >
        {variant === 'warning' ? (
          <ExclamationTriangleIcon
            height="1.25rem"
            width="1.25rem"
            color="#e11d48"
          />
        ) : (
          <TrashIcon height="1.25rem" width="1.25rem" color="#64748b" />
        )}
      </Center>
      <Text mb="0.5rem" c="#0f172a" ta="center" fw={500}>
        {title}
      </Text>
      <Text mb="1.5rem" fz={14} c="#0f172a" ta="center">
        {body}
      </Text>

      <Flex gap="0.5rem">
        <Button
          bg="#ffffff"
          c="#344155"
          bd="1px solid #e2e8f0"
          flex={1}
          onClick={closeButtonFn}
        >
          {closeButtonLabel}
        </Button>
        <Button bg="#f43f5e" flex={1} onClick={actionButtonFn}>
          {actionButtonLabel}
        </Button>
      </Flex>
    </Box>
  );
};

export default DeleteModal;
