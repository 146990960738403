import { useQuery } from '@tanstack/react-query';
import { EventTimingLocations } from 'pages/Events/TimingLocations/TimingLocations.interfaces';
import { client } from 'services/api';
import { Response } from 'types/response';
import { GET_EVENT_TIMING_LOCATIONS } from 'utils/apiUrl';

import { eventTimingLocationBaseKey } from './eventTimingLocationBaseKey';

interface TimingLocationProps {
  eventId: string;
  row_limit: number;
  offset: number;
  filter?: string;
  name?: string;
}

export const useFetchEventTimingLocations = ({
  eventId,
  row_limit,
  offset,
  filter,
}: TimingLocationProps) => {
  const queryResult = useQuery<Response<EventTimingLocations[]>>({
    queryKey: [eventTimingLocationBaseKey, eventId, row_limit, offset, filter],
    queryFn: async () => {
      if (!eventId) return [];

      const retVal = await client.get(
        GET_EVENT_TIMING_LOCATIONS(eventId, row_limit, offset, filter),
      );

      return retVal.data;
    },
  });

  return queryResult;
};
