import { SetModalContentParams } from 'pages/Entities/ModalManager/types';

import AddDevicesModal from './DeviceModals/AddDevicesModal';
import AddLayoutModal from './LayoutModals/AddLayoutModal';
import LayoutDetailsModal from './LayoutModals/LayoutDetailsModal';
import AddSectionModal from './SectionModals/AddSectionModal';
import AddTagsModal from './TagModals/AddTagsModal';
import TagDetailsModal from './TagModals/DetailsModal/TagDetailsModal';
import ControlAccessContent from './TrackingObjectModals/CreateModal/ControlAccessContent/ControlAccessContent';
import TrackingObjectImageContent from './TrackingObjectModals/CreateModal/TrackingObjectImageContent/TrackingObjectImageContent';
import TrackingObjectInformationContent from './TrackingObjectModals/CreateModal/TrackingObjectInformationContent';
import TrackingObjectDetailsModal from './TrackingObjectModals/DetailsModal/TrackingObjectDetailsModal';

export const setHubModalContent = ({
  dataId,
  type,
  card = false,
  step,
  formState,
  setFormState,
  isFormValid,
  setIsFormValid,
  setOnFormSubmit,
}: SetModalContentParams) => {
  const eventId = window.location.href.split('/').at(4) ?? '';

  switch (type) {
    case 'trackingObjects':
      return card ? (
        <TrackingObjectDetailsModal eventId={eventId} dataId={dataId} />
      ) : (
        <>
          {step === 1 && (
            <TrackingObjectInformationContent
              eventId={eventId}
              dataId={dataId}
              formState={formState}
              setFormState={setFormState}
              isFormValid={isFormValid}
              setIsFormValid={setIsFormValid}
              setOnFormSubmit={setOnFormSubmit}
            />
          )}
          {step === 2 && (
            <TrackingObjectImageContent
              eventId={eventId}
              dataId={dataId}
              formState={formState}
              setFormState={setFormState}
              isFormValid={isFormValid}
              setIsFormValid={setIsFormValid}
            />
          )}
          {step === 3 && (
            <ControlAccessContent
              eventId={eventId}
              dataId={dataId}
              formState={formState}
              setFormState={setFormState}
              isFormValid={isFormValid}
              setIsFormValid={setIsFormValid}
            />
          )}
        </>
      );
    case 'layouts':
      return card ? (
        <LayoutDetailsModal dataId={dataId} eventId={eventId} />
      ) : (
        <AddLayoutModal
          eventId={eventId}
          dataId={dataId}
          formState={formState}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
          setOnFormSubmit={setOnFormSubmit}
        />
      );
    case 'sections':
      return card ? (
        <div>Sections card modal</div>
      ) : (
        <AddSectionModal
          dataId={dataId}
          formState={formState}
          setFormState={setFormState}
          isFormValid={isFormValid}
          setIsFormValid={setIsFormValid}
          setOnFormSubmit={setOnFormSubmit}
        />
      );
    case 'devices':
      return card ? <div>Devices card modal</div> : <AddDevicesModal />;
    case 'tags':
      return card ? <TagDetailsModal dataId={dataId} /> : <AddTagsModal />;
    default:
      return <div>Modal has no content due to inappropriate type</div>;
  }
};
