import { Button, notification, Spinner } from '@lanaco/lnc-react-ui';
import { TLModal } from 'components/Modal';
import PromptModal from 'components/PromptModal/PromptModal';
import { useMutateEventLayout } from 'hooks/event/layout';
import { useMutateEventLayoutPresentation } from 'hooks/event/layout/useMutateEventLayoutPresentation';
import { useMultiStepForm } from 'hooks/useMultistepForm';
import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { createDateAndTime } from 'utils/createDateAndTime';

import style from '../Events.module.css';

import { EventLayout, EventLayoutPresentationType } from './interfaces';
import LayoutInfoForm from './LayoutInfoForm';
import LayoutSchemeForm from './LayoutScehemForm/LayoutSchemeForm';

export type Presentation =
  | {
      type: EventLayoutPresentationType.Image;
      image: File;
    }
  | {
      type: EventLayoutPresentationType.GoogleMap;
      geoRange: {
        center: { lat: number; lng: number };
        zoom: number;
      };
    };

type Props = {
  eventId: string;
  onCancelHandler: any;
  data?: EventLayout | null;
  isEdit: boolean;
  timezone: string;
  closeModal: () => void;
};

const LayoutForm = (props: Props) => {
  const id = props.eventId;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clear, setClear] = useState(Math.random());
  const presentationRef = React.useRef<Presentation | null>(null);
  const { mutateAsync, isLoading } = useMutateEventLayout(id!);
  const [confirmedFirstStep, setConfirmedFirstStep] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const { mutateAsync: mutateLayoutAsync } = useMutateEventLayoutPresentation(
    id!,
  );

  const hookForm = useForm<EventLayout>({
    defaultValues: props.data!,
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = hookForm;

  useEffect(() => {
    if (props.data) {
      reset(props.data);
    }
  }, [props.data, props.data?._id, reset]);

  const setPresentation = (presentation: Presentation | null) => {
    presentationRef.current = presentation;
  };

  const {
    currentStep: step,
    back,
    next,
    isFirstStep,
    isLastStep,
  } = useMultiStepForm([
    <LayoutInfoForm
      currentStepIndex={1}
      timezone={props.timezone!}
      isEdit={props.isEdit}
    />,
    <LayoutSchemeForm
      currentStepIndex={2}
      clear={clear}
      formState={errors}
      eventLayout={props.data!}
      setPresentation={setPresentation}
      register={register}
    />,
  ]);

  const handleConfirmedFirstStep = () => {
    setConfirmedFirstStep(true);
  };

  const onSubmit: SubmitHandler<EventLayout> = async (data) => {
    console.log(data);
    //const payload = getModifiedFields<EventLayout>(data, dirtyFields);
    if (!isLastStep) return next();
    setIsSubmitting(true);
    if (data.maxParticipants === undefined) {
      data.maxParticipants = 0;
    }
    data.startDateTime =
      data.startDateTime !== undefined
        ? createDateAndTime(
            data.startDateTime!,
            data.startTime!,
            props.timezone,
          )
        : null;
    // data.startDateTime! &&
    //   createDateAndTime(data.startDateTime!, data.startTime!, props.timezone);
    data.endDateTime =
      data.endDateTime !== undefined
        ? createDateAndTime(data.endDateTime!, data.endTime!, props.timezone)
        : null;

    if (!isLastStep) return next();
    // if (!presentationRef.current && !props.data) {
    //   notification.error(
    //     'Please upload an image or select a location on the map',
    //   );
    //   return;
    // }

    const { _id: eventLayoutId } = await mutateAsync({
      ...data,
      _id: props.data?._id,
      newPresentation: presentationRef.current,
    });

    if (presentationRef.current) {
      mutateLayoutAsync({ ...presentationRef.current, eventLayoutId });
    }

    props.closeModal();
  };

  useEffect(() => {
    if (isFormSubmitted && isSubmitting) {
      setIsFormSubmitted(false);
    }
  }, [isFormSubmitted, isSubmitting]);

  return (
    <div>
      {!confirmedFirstStep && props.data ? (
        <PromptModal
          title="Warning!?"
          isLoading={isLoading}
          warningMessage={
            <>
              This can result in problems with existing timing locations and
              cannot be undone.
            </>
          }
          confirmButtonName="Proceed"
          icon="exclamation-triangle"
          iconSize="24"
          iconColor="danger"
          isDeleteButton
          onConfirm={handleConfirmedFirstStep}
          onCancel={props.onCancelHandler}
        />
      ) : (
        <TLModal
          title={props.data ? 'Edit layout' : 'Create layout'}
          onClose={props.onCancelHandler}
        >
          <FormProvider {...hookForm}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>{step}</div>

              <div className={style.event_progress_actions}>
                <div className={style.event_progress_actions_back_next}>
                  {isFirstStep && (
                    <>
                      <Button type="button" onClick={props.onCancelHandler}>
                        Cancel
                      </Button>
                      <Button type="submit">Next</Button>
                    </>
                  )}
                  {!isFirstStep && (
                    <>
                      <Button type="button" onClick={back}>
                        Back
                      </Button>
                      <Button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? <Spinner size={'small'} /> : 'Done'}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </form>
          </FormProvider>
        </TLModal>
      )}
      ;
    </div>
  );
};

export default LayoutForm;
