import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { ClockIcon } from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { ActionIcon, Flex, Select, Stack, Textarea, Text } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useEffect, useRef, useState } from 'react';

import { CreateScheduleSecondStep } from '../types';

const AgendaDetailsContent = ({
  formState,
  setFormState,
  isFormValid,
  setIsFormValid,
}: ModalFormProps) => {
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);

  const [warning, setWarning] = useState<string | null>(null);

  const existingSchedules = [
    {
      startDate: '2024-12-14',
      startTime: '15:32',
      endDate: '2024-12-14',
      endTime: '16:32',
    },
    {
      startDate: '2024-12-12',
      startTime: '09:00',
      endDate: '2024-12-12',
      endTime: '10:00',
    },
    {
      startDate: '2024-12-15',
      startTime: '12:00',
      endDate: '2024-12-15',
      endTime: '13:00',
    },
  ];

  const form = useForm<CreateScheduleSecondStep>({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      entityLayoutId: '',
      sectionId: '',
      description: '',
    },
    validate: {
      startDate: (value) => (!value ? 'Start date is required' : null),
      startTime: (value) => (!value ? 'Start time is required' : null),
      endDate: (value) => (!value ? 'End date is required' : null),
      endTime: (value) => (!value ? 'End time is required' : null),
      entityLayoutId: (value) => (!value ? 'EntityLayout is required' : null),
    },
  });

  useEffect(() => {
    if (form.isValid()) {
      setIsFormValid(true);
      const newFormState = form.values;

      if (setFormState) {
        setFormState((prevState) => ({
          ...prevState,
          ...newFormState,
        }));
      }
    } else {
      setIsFormValid(false);
    }

    const selectedStartDate = form.values.startDate;
    const selectedStartTime = form.values.startTime;
    const selectedEndDate = form.values.endDate;
    const selectedEndTime = form.values.endTime;

    const conflict = existingSchedules.some((schedule) => {
      return (
        selectedStartDate === schedule.startDate &&
        selectedStartTime === schedule.startTime &&
        selectedEndDate === schedule.endDate &&
        selectedEndTime === schedule.endTime
      );
    });

    if (conflict) {
      setWarning('That time slot is already reserved for another event.');
    } else {
      setWarning(null);
    }
  }, [form.values, form.isValid()]);

  const entityLayoutOptions = [
    { id: '1', name: 'Option1' },
    { id: '2', name: 'Option2' },
    { id: '3', name: 'Option3' },
  ];

  const sectionOptions = [
    { id: '1', name: 'Option1' },
    { id: '2', name: 'Option2' },
    { id: '3', name: 'Option3' },
  ];

  return (
    <Stack gap="1.5rem">
      {warning && (
        <Flex
          bg="#FEFCE8"
          pt="1rem"
          pb="1rem"
          pl="1.15rem"
          bd="1px solid #CA8A04 "
          gap="0.78rem"
          style={{ borderRadius: '0.5rem', alignItems: 'center' }}
        >
          <ExclamationTriangleIcon
            height="1.5rem"
            width="1.5rem"
            color="#FEFCE8"
            stroke="#CA8A04"
            strokeWidth="1.5px"
          />
          <Text color="#CA8A04" fz="0.875rem" fw={600}>
            {warning}
          </Text>
        </Flex>
      )}

      <Flex gap="1.5rem">
        <div style={{ flex: 1 }}>
          <DatePickerInput
            label="Start date"
            showAsterisk={true}
            value={form?.values.startDate ?? null}
            onChange={(date) => {
              if (date) form?.setFieldValue('startDate', date);
            }}
          />
        </div>

        <div style={{ flex: 1 }}>
          <TimeInput
            label="Start time"
            withAsterisk
            style={{ flex: 1 }}
            ref={startTimeRef}
            key={form.key('startTime')}
            {...form.getInputProps('startTime')}
            rightSection={
              <ActionIcon
                variant="subtle"
                color="gray"
                onClick={() => startTimeRef.current?.showPicker()}
              >
                <ClockIcon
                  height="1.25rem"
                  width="1.25rem"
                  style={{
                    stroke: '#0F172A',
                    strokeWidth: 1.2,
                    fill: '#FFFFFF',
                  }}
                />
              </ActionIcon>
            }
          />
        </div>
      </Flex>

      <Flex gap="1.5rem">
        <div style={{ flex: 1 }}>
          <DatePickerInput
            label="End date"
            showAsterisk={true}
            value={form?.values.endDate ?? null}
            onChange={(date) => {
              if (date) form?.setFieldValue('endDate', date);
            }}
          />
        </div>

        <div style={{ flex: 1 }}>
          <TimeInput
            label="End time"
            withAsterisk
            style={{ flex: 1 }}
            ref={endTimeRef}
            key={form.key('endTime')}
            {...form.getInputProps('endTime')}
            rightSection={
              <ActionIcon
                variant="subtle"
                color="gray"
                onClick={() => endTimeRef.current?.showPicker()}
              >
                <ClockIcon
                  height="1.25rem"
                  width="1.25rem"
                  style={{
                    stroke: '#0F172A',
                    strokeWidth: 1.2,
                    fill: '#FFFFFF',
                  }}
                />
              </ActionIcon>
            }
          />
        </div>
      </Flex>

      <Flex gap="1.5rem">
        <div style={{ flex: 1 }}>
          <Select
            label="Entity layout"
            placeholder="Select entity layout"
            withAsterisk
            value={form.values.entityLayoutId}
            data={entityLayoutOptions?.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            rightSection={
              <ChevronDownIcon
                width="1.25rem"
                height="1.25rem"
                color="var(--gray-900)"
              />
            }
            {...form.getInputProps('entityLayoutId')}
          />
        </div>

        <div style={{ flex: 1 }}>
          <Select
            label="Section"
            placeholder="Select section"
            disabled={!form.values.entityLayoutId}
            data={sectionOptions?.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            rightSection={
              <ChevronDownIcon
                width="1.25rem"
                height="1.25rem"
                color="var(--gray-900)"
              />
            }
            {...form.getInputProps('sectionId')}
          />
        </div>
      </Flex>

      <Textarea
        label="Description"
        placeholder="Short description about the schedule"
        autosize
        minRows={4}
        key={form?.key('description')}
        {...form.getInputProps('description')}
      />
    </Stack>
  );
};

export default AgendaDetailsContent;
