import { Flex, Stack, Text, Divider, Progress } from '@mantine/core';
import { LengthOfStayProps } from '../types';

const LengthOfStay = ({
  name,
  level,
  model,
  time,
  maxTime,
  index,
}: LengthOfStayProps) => {
  const parseTime = (time: string) => {
    const [days, hours] = time
      .split('d')
      .map((part) => part.trim())
      .filter(Boolean);
    const daysInt = parseInt(days);
    const hoursInt = parseInt(hours.replace('h', '').trim());
    return { days: daysInt, hours: hoursInt };
  };

  const calculateProgress = (time: string, maxTime: string) => {
    const { days, hours } = parseTime(time);
    const { days: maxDays, hours: maxHours } = parseTime(maxTime);

    const totalTimeInHours = days * 24 + hours;
    const maxTimeInHours = maxDays * 24 + maxHours;

    return (totalTimeInHours / maxTimeInHours) * 100;
  };

  const progressValue = calculateProgress(time, maxTime);

  const textColor = index === 0 ? '#3B82F6' : '#64748B';

  return (
    <Stack gap="0.25rem">
      <Flex justify="space-between" align="center">
        <Flex align="center">
          <Text c="#64748B" fz="0.875rem" fw={400}>
            {name}
          </Text>
          <Divider orientation="vertical" mx="0.9rem" />
          <Text c="#64748B" fz="0.875rem" fw={400}>
            {level}
          </Text>
          <Divider orientation="vertical" mx="0.9rem" />
          <Text c="#64748B" fz="0.875rem" fw={400}>
            {model}
          </Text>
        </Flex>
        <Text c={textColor} fz="0.875rem" fw={400}>
          {time}
        </Text>
      </Flex>
      <Progress
        radius="0.25rem"
        size="xl"
        value={progressValue}
        style={{
          background:
            index === 0
              ? 'linear-gradient(to right, #3B82F6, #004BC6)'
              : '#E5E7EB',
        }}
        color={index === 0 ? 'transparent' : '#94A3B8'}
      />
    </Stack>
  );
};

export default LengthOfStay;
