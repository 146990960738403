import {
  DocumentStatus,
  DocumentStatusDescription,
  Gender,
  GenderDescription,
} from 'types/enums';

export const genderOptions = [
  {
    value: Gender.MALE,
    label: GenderDescription[Gender.MALE],
  },
  {
    value: Gender.FEMALE,
    label: GenderDescription[Gender.FEMALE],
  },
  {
    value: Gender.OTHER,
    label: GenderDescription[Gender.OTHER],
  },
];

export const statusOptions = [
  {
    value: DocumentStatus.ACTIVE,
    label: DocumentStatusDescription[DocumentStatus.ACTIVE],
  },
  {
    value: DocumentStatus.DISABLED,
    label: DocumentStatusDescription[DocumentStatus.DISABLED],
  },
  {
    value: DocumentStatus.PASSIVE,
    label: DocumentStatusDescription[DocumentStatus.PASSIVE],
  },
];
