import { useQuery } from '@tanstack/react-query';
import { client } from 'services/api';

import { OrganizationData } from './types';

const getOrganizations = async (): Promise<OrganizationData[]> => {
  const url = 'organisations/';

  const response = await client.get(url);

  return response.data.data;
};

export const useOrganizations = () => {
  return useQuery<OrganizationData[], Error>({
    queryKey: ['organisations'],
    queryFn: () => getOrganizations(),
  });
};
