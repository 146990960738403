import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import {
  DELETE_EVENT_PARTICIPANT,
  DELETE_EVENT_PARTICIPANTS,
} from 'utils/apiUrl';

interface DeleteParams {
  eventId: string;
  participantId?: string;
  ids?: string[];
}

export class EventParticipantDeleteService extends ResponseInterceptor {
  static async deleteEventParticipant({
    eventId,
    participantId,
  }: DeleteParams): Promise<APIAxiosResponse<string[]>> {
    const res = await client.delete(
      DELETE_EVENT_PARTICIPANT(eventId, participantId!),
    );
    return this.handleResponse(res);
  }

  static async deleteEventParticipants({
    eventId,
    ids,
  }: DeleteParams): Promise<APIAxiosResponse<string[]>> {
    const response = await client.delete(DELETE_EVENT_PARTICIPANTS(eventId), {
      data: { ids },
    });
    console.log(response);
    return this.handleResponse(response);
  }
}
