import { RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import {
  TLDangerButton,
  TLNeutralButton,
  TLPrimaryButton,
} from 'components/Button';
import ButtonGroupData from 'components/ButtonGroupData/ButtonGroupData';
import { ButtonGroupDataInterface } from 'components/ButtonGroupData/ButtonGroupData.interface';
import { TLModal } from 'components/Modal';
import { TableResponse } from 'components/Table';
import TanStackTable, {
  RowAction,
} from 'components/TanStackTable/TanStackTable';
import { useFetchEventTimingLocationGatings } from 'hooks/event/timing-location/useFetchEventTimingLocationGatings';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { formatDateTimeFull } from 'utils/dateFormatters';

import style from '../Events.module.css';

import AddDevicesTimingLocationForm from './AddDevicesTimingLocationForm';
import { DeleteTimingLocationDeviceModal } from './DeleteTimingLocationDeviceModal/DeleteTimingLocationDeviceModal';
import { DeleteTimingLocationDevicesModal } from './DeleteTimingLocationDevicesModal/DeleteTimingLocationDevicesModal';
import RegisterDeviceForm from './RegisterDeviceForm';
import { EventTimingLocationsAssignDevicesTableData } from './TimingLocations.interfaces';
import UnregisterDeviceForm from './UnregisterDeviceForm';
type Props = {
  eventId: string;
  layoutId: string;
  sectionId: string;
  timezone: string;
};
const columnHelper = createColumnHelper<EventTimingLocationsAssignDevicesTableData>();

const ROW_LIMIT = 10;
const DetailsDevices = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tableResponse, setTableResponse] = useState<TableResponse>();

  const [status] = useState('all');
  //const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const [gating, setGating] = useState<string>();
  const [registerDate, setRegisterDate] = useState<Date>();
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});

  const selectedIds = Object.entries(selectedRows).reduce<string[]>(
    (acc, [key, value]) => {
      if (value) {
        acc.push(key);
      }
      return acc;
    },
    [],
  );

  const { data, isFetching } = useFetchEventTimingLocationGatings({
    eventId: props.eventId,
    layoutId: props.layoutId,
    sectionId: props.sectionId,
    offset: (page - 1) * ROW_LIMIT,
    row_limit: ROW_LIMIT,
    status,
  });

  const { data: devices } = data || {
    _route: { returned: 0, total: 0 },
    data: [],
  };

  // const getHandleStatusFilterChangeCallback = (status: string) => () => {
  //   setPage(1);
  //   setStatus(status);
  // };

  const buttons: Array<ButtonGroupDataInterface> = [
    // {
    //   text: 'All',
    //   text1: '97',
    //   indicator: '',
    //   onClick: getHandleStatusFilterChangeCallback('all'),
    // },
    // {
    //   text: 'Online',
    //   text1: '3',
    //   indicator: online,
    //   onClick: getHandleStatusFilterChangeCallback('online'),
    // },
    // {
    //   text: 'Idle',
    //   text1: '2',
    //   indicator: idle,
    //   onClick: getHandleStatusFilterChangeCallback('idle'),
    // },
    // {
    //   text: 'Offline',
    //   text1: '90',
    //   indicator: offline,
    //   onClick: getHandleStatusFilterChangeCallback('offline'),
    // },
  ];

  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  const [
    selectedDevices,
    setSelectedDevices,
  ] = useState<EventTimingLocationsAssignDevicesTableData | null>(null);

  const columns = [
    columnHelper.accessor('device.deviceId.macName', {
      header: 'Devices',
    }),
    columnHelper.accessor('device.deviceId.macAddress', {
      header: 'Mac address',
    }),

    // columnHelper.accessor(
    //   (data) => formatDateTime(data.device.registerDateTime),
    //   {
    //     header: 'Register date',
    //   },
    // ),

    columnHelper.accessor(
      (data) => {
        const registerDateTime = data.device.registerDateTime;

        if (registerDateTime) {
          return formatDateTimeFull(registerDateTime, props.timezone);
        } else {
          // Handle the case when the date is not present
          return ''; // or any other default value or message
        }
      },
      {
        header: 'Register date',
      },
    ),
    // columnHelper.accessor(
    //   (data) => formatDateTime(data.device.unregisterDateTime),
    //   {
    //     header: 'Register date',
    //   },
    // ),

    columnHelper.accessor(
      (data) => {
        const unregisterDateTime = data.device.unregisterDateTime;

        if (unregisterDateTime) {
          return formatDateTimeFull(unregisterDateTime, props.timezone);
        } else {
          // Handle the case when the date is not present
          return ''; // or any other default value or message
        }
      },
      {
        header: 'Unregister date',
      },
    ),
    // columnHelper.accessor('device.deviceId.status', {
    //   header: 'Status',
    //   cell: (row) => <span className="capitalize">{row.getValue()}</span>,
    // }),

    // columnHelper.accessor('state', {
    //   header: 'State',
    //   cell: (row) => (
    //     <Badge
    //       className={clsx(
    //         'capitalize',
    //         deviceStatusToBadgeColorMap[row.getValue()!],
    //       )}
    //     >
    //       {row.getValue()}
    //     </Badge>
    //   ),
    // }),
  ];

  const [showInsertDeviceModal, hideInsertDeviceModal] = useModal(() => {
    const closeModal = () => {
      hideInsertDeviceModal();
    };

    return (
      <TLModal title={'Add new device'} onClose={closeModal} hideFooter>
        <AddDevicesTimingLocationForm
          eventId={props.eventId}
          layoutId={props.layoutId}
          sectionId={props.sectionId}
          closeModal={closeModal}
        />
      </TLModal>
    );
  }, []);

  const [showRegisterDeviceModal, hideRegisterDeviceModal] = useModal(() => {
    const closeModal = () => {
      hideRegisterDeviceModal();
    };

    return (
      <TLModal title={'Register device'} onClose={closeModal} hideFooter>
        <RegisterDeviceForm
          eventId={props.eventId}
          layoutId={props.layoutId}
          sectionId={props.sectionId}
          gatingId={gating as string}
          timezone={props.timezone}
          closeModal={closeModal}
        />
      </TLModal>
    );
  }, [gating]);

  const [
    showUnregisterDeviceModal,
    hideUnregisterDeviceModal,
  ] = useModal(() => {
    const closeModal = () => {
      hideUnregisterDeviceModal();
    };

    return (
      <TLModal title={'Unregister device'} onClose={closeModal} hideFooter>
        <UnregisterDeviceForm
          eventId={props.eventId}
          layoutId={props.layoutId}
          sectionId={props.sectionId}
          gatingId={gating as string}
          timezone={props.timezone}
          registerDateTime={registerDate! as Date}
          closeModal={closeModal}
        />
      </TLModal>
    );
  }, [gating, registerDate]);

  const [
    showDeleteTimingLocationDeviceModal,
    hideDeleteTimingLocationDeviceModal,
  ] = useModal(
    () => (
      <DeleteTimingLocationDeviceModal
        eventId={props.eventId}
        layoutId={props.layoutId}
        sectionId={props.sectionId}
        gatingId={selectedDevices?.id as string}
        closeModal={hideDeleteTimingLocationDeviceModal}
      />
    ),
    [selectedDevices?._id],
  );

  const [
    showDeleteTimingLocationDevicesModal,
    hideDeleteTimingLocationDevicesModal,
  ] = useModal(
    () => (
      <DeleteTimingLocationDevicesModal
        eventId={props.eventId}
        layoutId={props.layoutId}
        sectionId={props.sectionId}
        selectedIds={selectedIds}
        closeModal={hideDeleteTimingLocationDevicesModal}
        setSelectedRows={setSelectedRows}
      />
    ),
    [selectedIds],
  );

  const handleAddDeviceClick = () => {
    showInsertDeviceModal();
  };

  // const handleEditClick = (row: EventTimingLocationsAssignDevicesTableData) => {
  //   //showDeleteTimingLocationDeviceModal(row._id);
  // };

  const handleDeleteClick = (
    row: EventTimingLocationsAssignDevicesTableData,
  ) => {
    setSelectedDevices(row);
    showDeleteTimingLocationDeviceModal();
  };

  const clearSelectedRows = () => setSelectedRows({});

  const handleRegister = (row: EventTimingLocationsAssignDevicesTableData) => {
    setGating(row.id);

    showRegisterDeviceModal();
  };

  const handleUnregister = (
    row: EventTimingLocationsAssignDevicesTableData,
  ) => {
    setGating(row.id);
    setRegisterDate(row.device?.registerDateTime!);
    showUnregisterDeviceModal();
  };

  const deviceTableSelectedRowsActions = (
    <>
      <TLNeutralButton leadingIcon="times" onClick={clearSelectedRows}>
        Clear selected
      </TLNeutralButton>
      <TLDangerButton
        leadingIcon="trash"
        onClick={showDeleteTimingLocationDevicesModal}
        className="text-white"
      >
        Delete
      </TLDangerButton>
    </>
  );

  const deviceTableActions = (
    <>
      <TLPrimaryButton leadingIcon="plus" onClick={handleAddDeviceClick}>
        New device
      </TLPrimaryButton>
    </>
  );
  const deviceTableRowActions = (
    row: EventTimingLocationsAssignDevicesTableData,
  ): RowAction[] => [
    {
      icon: 'plus',
      key: 'register',
      label: 'Register device',
      className: row.device.registerDateTime == null ? 'show' : 'hide',
      onClick: () => handleRegister(row),
    },
    {
      icon: 'minus',
      key: 'unregister',
      label: 'Unregister device',
      className:
        row.device.registerDateTime != null &&
        row.device.unregisterDateTime == null
          ? 'show'
          : 'hide',
      onClick: () => handleUnregister(row),
    },
    {
      icon: 'trash',
      key: 'delete',
      label: 'Delete',
      color: 'danger',
      onClick: () => handleDeleteClick(row),
    },
  ];

  return (
    <div className={style.timing_location_details_devices}>
      <TanStackTable
        getRowId={(row) => row.id}
        selectedRows={selectedRows}
        onRowSelectionChange={setSelectedRows}
        isLoading={isFetching}
        columns={columns}
        pageIndex={page}
        pageSize={ROW_LIMIT}
        totalRows={devices?.length}
        pageCount={Math.ceil(devices?.length / ROW_LIMIT)}
        data={devices}
        rowActions={deviceTableRowActions}
        onPageChange={handlePageChange}
        headerTitle={<ButtonGroupData buttons={buttons} />}
        tableActions={
          selectedIds.length > 0
            ? deviceTableSelectedRowsActions
            : deviceTableActions
        }
        enableRowSelection
        showHeader
      />
    </div>
  );
};

export default DetailsDevices;
