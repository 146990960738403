import { ReactElement, Children, ReactNode } from 'react';

import { TLCaseProps } from './TLCase/TLCase';
import { TLDefault } from './TLDefault';

export interface TLSwitchProps {
  condition: unknown | ((value: unknown) => boolean);
  children: ReactNode;
}

export const TLSwitch = ({ condition, children }: TLSwitchProps) => {
  const reactElements = Children.toArray(children);
  const cases = reactElements as ReactElement<TLCaseProps>[];

  const casesToRender: ReactElement[] = cases.filter((a) => {
    if (typeof condition === 'function') {
      return condition(a.props.value);
    }

    if (Array.isArray(a.props.value)) {
      const found = a.props.value.find((b) => b === condition);
      return !!found;
    }

    return condition === a.props.value;
  });

  if (casesToRender.length === 0) {
    const defaultCase = (reactElements as ReactElement[]).find(
      (a) => a.type === TLDefault,
    );

    if (defaultCase) {
      casesToRender.push(defaultCase);
    }
  }

  return <>{casesToRender}</>;
};
