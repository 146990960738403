import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { EventTagTableData } from 'pages/Events/Tags/EventTag.interface';
import { client } from 'services/api';
import { Response } from 'types/response';
import { GET_EVENT_TAGS } from 'utils/apiUrl';

import { eventTagBaseKey } from './baseKeys';

interface TagProps
  extends UseQueryOptions<Response<EventTagTableData[]>, AxiosError> {
  eventId: string;
  row_limit?: number;
  offset?: number;
  filter?: string;
  participantId?: string;
}

export const useFetchEventTags = ({
  eventId,
  row_limit = 10,
  offset = 0,
  filter,
  participantId,
  ...rest
}: TagProps) => {
  const queryClient = useQueryClient();
  const queryResult = useQuery<Response<EventTagTableData[]>, AxiosError>({
    queryKey: [
      eventTagBaseKey,
      eventId,
      row_limit,
      offset,
      filter,
      participantId,
    ],
    queryFn: async () => {
      const retVal = await client.get<Response<EventTagTableData[]>>(
        GET_EVENT_TAGS(eventId, row_limit, offset, filter, participantId),
      );

      retVal.data.data.forEach(async (eventTag) =>
        queryClient.setQueryData<EventTagTableData>(
          [eventTagBaseKey, eventTag._id],
          eventTag,
        ),
      );

      return retVal.data;
    },
    ...rest,
  });

  return queryResult;
};
