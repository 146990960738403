import { Flex, Text, Tooltip } from '@mantine/core';

import { CardColumnContentProps } from '../../types';

const CardColumnContent = ({
  Icon,
  name,
  lastColumn = false,
  tooltipTitle,
}: CardColumnContentProps) => {
  if (!name || name.length === 0) return null;
  return (
    <>
      <Icon
        width={20}
        height={20}
        color="var(--gray-400)"
        style={{ minWidth: 20, minHeight: 20 }}
      />
      <Text
        ml="0.5rem"
        mr={
          lastColumn && (name?.length === 1 || !Array.isArray(name))
            ? '1.25rem'
            : '0'
        }
        c="var(--gray-500)"
        fz={14}
        truncate="end"
        title={Array.isArray(name) ? name[0] : name}
      >
        {Array.isArray(name) ? name[0] : name}
      </Text>
      {Array.isArray(name) && name?.length > 1 && (
        <Tooltip
          label={
            <Flex direction="column">
              <span>{tooltipTitle}</span>
              <br />
              {name.map((item, index) => (
                <span key={index} style={{ marginBottom: '4px' }}>
                  {item}
                </span>
              ))}
            </Flex>
          }
          position="top"
          withArrow
        >
          <Text
            ml={3}
            mr={lastColumn ? '1.25rem' : '0rem'}
            c="var(--gray-500)"
            fz={14}
          >
            (+{name?.length - 1})
          </Text>
        </Tooltip>
      )}
    </>
  );
};

export default CardColumnContent;
