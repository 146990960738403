import {
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/24/solid';
import { ActionIcon, Menu } from '@mantine/core';
import TagMinusIcon from 'components/SvgIcons/TagMinusIcon';
import TagPlusIcon from 'components/SvgIcons/TagPlusIcon';
import { useEntitiesContext } from 'context/EntityProvider';
import {
  openModal,
  openRemoveModal,
} from 'pages/Entities/ModalManager/ModalsManager';
import { useState } from 'react';
import { useParams } from 'react-router';
import { useDeleteTagsByEvent } from 'services/entity/tag';
import { useDeleteTrackingObjectByEvent } from 'services/entity/trackingObject';
import { ModalWidth } from 'utils/consts';

import { setHubModalContent } from '../../Modals/hubModalsConfig';
import TagAssingToTrackingObjectModal from '../../Modals/TagModals/TagAssingToTrackingObjectModal';
import TrackingObjectAssignModal from '../../Modals/TrackingObjectModals/TrackingObjectAssignModal';
import { CardSettingsDropdownProps } from '../../types';

const CardSettingsDropdown = ({
  backgorundColor = '#ffffff',
  canUnassignTag,
  canUnassignTrackingObject,
  trackingObjects,
  cardType,
  dataId,
  tagName,
  trackingObjectTags,
  trackingObjectName,
}: CardSettingsDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>();
  const { mutate: deleteTrackingObject } = useDeleteTrackingObjectByEvent();
  const { mutate: deleteTags } = useDeleteTagsByEvent();
  const { id } = useParams<{ id?: string }>();
  const { selectedSection } = useEntitiesContext();

  const handleDeleteItem = (e) => {
    e.stopPropagation();
    if (cardType === 'trackingObject' && dataId && id) {
      openRemoveModal({
        variant: 'warning',
        title: 'Remove tracking object?',
        body: <>Are you sure you want to delete tracking object?</>,
        actionButtonFn: () =>
          deleteTrackingObject({ eventId: id, trackingObjectId: dataId }),
      });
    } else if (cardType === 'tags' && dataId && id) {
      deleteTags({ eventId: id, tagIds: [dataId] });
    }
  };

  const handleEditItem = (e) => {
    e.stopPropagation();
    if (cardType === 'trackingObject' && dataId && id) {
      openModal({
        type: selectedSection.type,
        size: selectedSection.modalSize,
        title: selectedSection.modalTitle,
        dataId: dataId,
        subtitles: selectedSection.modalSubTitles,
        isMultistep: selectedSection.type === 'trackingObjects',
        hasFooter: selectedSection.hasFooter,
        actionButtonFn: () => console.log('Update'),
        setModalContent: setHubModalContent,
      });
    }
  };

  const handleAssignTagToTrackingObject = (e, isAssignModal) => {
    e.stopPropagation();
    if (
      (cardType === 'trackingObject' || cardType === 'admission') &&
      dataId &&
      id
    ) {
      openModal({
        size: ModalWidth.ExtraLarge,
        title: isAssignModal
          ? 'Assign tag to tracking object'
          : 'Unassign tag from tracking object',
        dataId: dataId,
        titleDescription: isAssignModal
          ? `Select a tag you want to assign to ${trackingObjectName}`
          : `Select a tag you want to unassign from ${trackingObjectName}`,
        hasFooter: false,
        content: (
          <TagAssingToTrackingObjectModal
            dataId={dataId}
            eventId={id}
            isAssignModal={isAssignModal}
            trackingObjectTags={trackingObjectTags}
          />
        ),
      });
    }
  };

  const handleAssignTrackingObjectToTag = (e, isAssignModal) => {
    e.stopPropagation();
    if (cardType === 'tags' && dataId && id) {
      openModal({
        size: ModalWidth.ExtraLarge,
        title: isAssignModal
          ? 'Assign tracking object to tag'
          : 'Unassign tracking object from tag',
        dataId: dataId,
        titleDescription: isAssignModal
          ? `Select a tracking object you want to assign to ${tagName}`
          : `Select a tracking object you want to unassign from ${tagName}`,
        hasFooter: false,
        content: (
          <TrackingObjectAssignModal
            dataId={dataId}
            eventId={id}
            isAssignModal={isAssignModal}
            trackingObjects={trackingObjects}
          />
        ),
      });
    }
  };

  return (
    <Menu
      width={250}
      position="bottom-end"
      offset={0}
      onOpen={() => setIsDropdownOpen(true)}
      onClose={() => setIsDropdownOpen(false)}
    >
      <Menu.Target>
        <ActionIcon
          bg={isDropdownOpen ? '#e2e8f0' : backgorundColor}
          c="#475569"
          onClick={(e) => e.stopPropagation()}
        >
          <EllipsisVerticalIcon height="1.5rem" width="1.5rem" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        {(cardType === 'trackingObject' || cardType === 'admission') && (
          <>
            <Menu.Item
              leftSection={
                <TagPlusIcon height="1.25rem" width="1.25rem" fill="#94A3B8" />
              }
              onClick={(e) => handleAssignTagToTrackingObject(e, true)}
            >
              Assign another tag
            </Menu.Item>
            <Menu.Divider m={0} />
          </>
        )}

        {cardType === 'tags' && canUnassignTrackingObject && (
          <>
            <Menu.Item
              leftSection={
                <UserPlusIcon height="1.25rem" width="1.25rem" fill="#94A3B8" />
              }
              onClick={(e) => handleAssignTrackingObjectToTag(e, false)}
            >
              Unassign to tracking object
            </Menu.Item>
            <Menu.Divider m={0} />
          </>
        )}

        {cardType === 'tags' && !canUnassignTrackingObject && (
          <>
            <Menu.Item
              leftSection={
                <UserPlusIcon height="1.25rem" width="1.25rem" fill="#94A3B8" />
              }
              onClick={(e) => handleAssignTrackingObjectToTag(e, true)}
            >
              Assign to tracking object
            </Menu.Item>
            <Menu.Divider m={0} />
          </>
        )}

        {canUnassignTag &&
          (cardType === 'trackingObject' || cardType === 'admission') && (
            <Menu.Item
              leftSection={
                <TagMinusIcon height="1.25rem" width="1.25rem" fill="#94A3B8" />
              }
              onClick={(e) => handleAssignTagToTrackingObject(e, false)}
            >
              Unassign tag
            </Menu.Item>
          )}
        <Menu.Divider m={0} />
        {cardType === 'trackingObject' && (
          <Menu.Item
            c="#0F172A"
            leftSection={
              <PencilIcon height="1.25rem" width="1.25rem" color="#94A3B8" />
            }
            onClick={(e) => handleEditItem(e)}
          >
            {cardType === 'trackingObject' ? 'Edit tracking object' : 'Edit'}
          </Menu.Item>
        )}
        {(cardType === 'trackingObject' || cardType === 'tags') && (
          <>
            <Menu.Divider m={0} />
            <Menu.Item
              c="#F43F5E"
              leftSection={<TrashIcon height="1.25rem" width="1.25rem" />}
              onClick={(e) => handleDeleteItem(e)}
            >
              {cardType === 'trackingObject'
                ? 'Remove tracking object'
                : cardType === 'tags'
                ? 'Remove tag'
                : 'Remove'}
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default CardSettingsDropdown;
