import {
  FieldValues,
  Path,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

export const useDropdownField = <T extends FieldValues, K extends Path<T>>({
  names,
  setValue,
  watch
}: {
  names: K[];
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
}) => {
  const values = watch(names).reduce((acc, curr, index) => {
    acc[names[index]] = curr;
    return acc;
  }, {} as Record<K, T[K]>);

  const onDropdownValueChange = (option, props) => {
    if (!props?.name || !option?.value) {
      return;
    }

    setValue(props.name as Path<T>, option.value, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  return [values, onDropdownValueChange] as const;
};
