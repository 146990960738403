import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TimingLocationPayload } from 'pages/Events/MultiStepForm/timingLocation-schema';
import { TimingLocationService } from 'services/event/timingLocation/TimingLocationService';

import { eventTimingLocationBaseKey } from './eventTimingLocationBaseKey';

export const useMutateTimingLocation = (
  eventId: string,
  sectionId?: string,
  oldLayoutId?: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: TimingLocationPayload) => {
      const res =
        sectionId !== undefined
          ? await TimingLocationService.updateTimingLocation(
              eventId,
              oldLayoutId !== undefined ? oldLayoutId : payload.layout,
              sectionId,
              payload,
            )
          : await TimingLocationService.createTimingLocation(
              eventId,
              payload.layout,
              payload,
            );

      return res?.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([eventTimingLocationBaseKey]);
    },
  });
};
