import { notification } from '@lanaco/lnc-react-ui';
import { RowSelectionState } from '@tanstack/react-table';
import PromptModal from 'components/PromptModal/PromptModal';
import { useDeleteTags } from 'hooks/tag';
import { getErrorMsg } from 'utils/getErrorMsg';

import { TagTableData } from '../interfaces';

interface DeleteTagsModalProps {
  selectedIds: string[];
  closeModal: () => void;
  onTagEdit: (device: TagTableData | null) => void;
  setSelectedRows: (selectedRows: RowSelectionState) => void;
}

export const DeleteTagsModal = ({
  selectedIds,
  closeModal,
  onTagEdit,
  setSelectedRows,
}: DeleteTagsModalProps) => {
  const { isLoading, mutate } = useDeleteTags();

  const hideDeleteTagsModal = () => {
    closeModal();
    onTagEdit(null);
  };

  const confirmDeleteTags = async () => {
    mutate(
      {
        ids: selectedIds,
      },
      {
        onSuccess: (data) => {
          if (data.status === 201) {
            notification.success('Tags successfully deleted.');
          } else {
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          hideDeleteTagsModal();
          setSelectedRows({});
        },
      },
    );
  };

  return (
    <PromptModal
      title="Delete tags?"
      isLoading={isLoading}
      warningMessage={
        <>
          Are you sure you want to delete {selectedIds.length}
          <br />
          tags from the database?
        </>
      }
      confirmButtonName="Delete"
      icon="exclamation-triangle"
      iconSize="24"
      iconColor="danger"
      isDeleteButton
      onConfirm={confirmDeleteTags}
      onCancel={hideDeleteTagsModal}
    />
  );
};
