import { notification } from '@lanaco/lnc-react-ui';
import { RowSelectionState } from '@tanstack/react-table';
import PromptModal from 'components/PromptModal/PromptModal';
import { useMutateDeleteEventParticipant } from 'hooks/event/participants/useMutateDeleteEventParticipant';
import { getErrorMsg } from 'utils/getErrorMsg';

interface Props {
  participantId: string;
  participantName?: string;
  eventName?: string;
  eventId: string;
  participantIds?: string[];
  closeModal: () => void;
  setSelectedRows: (selectedRows: RowSelectionState) => void;
}

export const RemoveEventParticipantModal = ({
  participantId,
  eventId,
  participantName,
  eventName,
  participantIds,
  closeModal,
  setSelectedRows,
}: Props) => {
  const { mutate, isLoading } = useMutateDeleteEventParticipant();

  const hideDeleteModal = () => {
    closeModal();
  };

  const confirmRemoveParticipant = async () => {
    console.log(eventId, participantId, participantIds);
    mutate(
      {
        eventId,
        participantId: participantId,
        ids: participantIds,
      },
      {
        onSuccess: (response) => {
          if (response?.code === 201) {
            if (participantId === undefined) {
              notification.success('Participants successfully removed.');
            } else {
              notification.success('Participant successfully removed.');
            }
          } else {
            console.log(response);
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          hideDeleteModal();
          setSelectedRows({});
        },
      },
    );
  };

  return (
    <PromptModal
      title={
        participantId === undefined
          ? 'Remove participants?'
          : 'Remove participant?'
      }
      isLoading={isLoading}
      warningMessage={
        participantId === undefined ? (
          <>
            Are you sure you want to delete {participantIds?.length}
            <br />
            participants from the database?
          </>
        ) : (
          <>
            You will remove {participantName} from {eventName}.
          </>
        )
      }
      confirmButtonName="Remove"
      icon="trash"
      iconSize="24"
      isDeleteButton
      onConfirm={confirmRemoveParticipant}
      onCancel={hideDeleteModal}
    />
  );
};
