import { notification } from '@lanaco/lnc-react-ui';
import PromptModal from 'components/PromptModal/PromptModal';
import { useDeleteDevices } from 'hooks/device';
import { getErrorMsg } from 'utils/getErrorMsg';

import { DeviceTableData } from '../interfaces';

interface DeleteDevicesModalProps {
  selectedIds: string[];
  closeModal: () => void;
  onDeviceEdit: (device: DeviceTableData | null) => void;
  clearSelectedRows: () => void;
}

export const DeleteDevicesModal = ({
  selectedIds,
  closeModal,
  onDeviceEdit,
  clearSelectedRows,
}: DeleteDevicesModalProps) => {
  const { mutate, isLoading } = useDeleteDevices();

  const closeDeleteDevicesModal = () => {
    onDeviceEdit(null);
    closeModal();
  };

  const confirmDeleteDevices = async () => {
    mutate(
      {
        ids: selectedIds,
      },
      {
        onSuccess: (data) => {
          if (data?.status === 201) {
            notification.success('Device successfully deleted.');
            selectedIds.length = 0;
            clearSelectedRows();
          } else {
            notification.error('Something went wrong');
          }
        },
        onError: (error) => {
          notification.error(getErrorMsg(error));
        },
        onSettled: () => {
          closeDeleteDevicesModal();
        },
      },
    );
  };

  return (
    <PromptModal
      title="Delete devices?"
      isLoading={isLoading}
      warningMessage={
        <>
          Are you sure you want to delete {selectedIds.length}
          <br />
          {selectedIds.length === 1 ? 'device' : 'devices'} from the list?
        </>
      }
      confirmButtonName="Delete"
      icon="exclamation-triangle"
      iconSize="24"
      iconColor="danger"
      isDeleteButton
      onConfirm={confirmDeleteDevices}
      onCancel={closeDeleteDevicesModal}
    />
  );
};
