import { Redirect } from 'components/Redirect';
import Clients from 'pages/Clients/Clients';
import Dashboard from 'pages/Dashboard/Dashboard';
import Device from 'pages/Devices/Device';
import EntityControlAccess from 'pages/Entities/ControlAccess/EntityControlAccess';
import ControlAccessDetails from 'pages/Entities/Details/ControlAccessDetails';
import EntityDetails from 'pages/Entities/Details/EntityDetails';
import Entities from 'pages/Entities/Home/Entities';
import EventDetails from 'pages/Events/EventDetails';
import EventDetailsMobile from 'pages/Events/EventDetailsMobile';
import Events from 'pages/Events/Home/Events';
import TimingLocationDetails from 'pages/Events/TimingLocations/TimingLocationDetails';
import Login from 'pages/Login/Login';
import PageNotFound from 'pages/PageNotFound';
import Participants from 'pages/Participants/Participants';
import Registration from 'pages/Registration/Registration';
import RegistrationConfirm from 'pages/RegistrationConfirm/RegistrationConfirm';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import Settings from 'pages/Settings/Settings';
import Tags from 'pages/Tags/Tags';
import { Route, Routes, useParams } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import PrivateRoutes from './PrivateRoutes';

type Props = {};

const ScannedTagRedirect = () => {
  const { tagMAC } = useParams();

  return (
    <Redirect
      to={`https://chti.fanfareco.io/register/${tagMAC}`}
      mode="browser"
    />
  );
};

const MainRouter = (props: Props) => {
  const { width } = useWindowSize();

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/verify-email" element={<RegistrationConfirm />} />
      <Route path="/scanned/tag/:tagMAC" element={<ScannedTagRedirect />} />

      {/* Private routes */}
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/entities" element={<Entities />} />
        <Route path="/events" element={<Events />} />
        {/* TODO: Ubaciti Entity komponentu u element */}
        <Route path="/entities" element={<EntityDetails />} />

        <Route path="/devices" element={<Device />} />

        <Route path="/participants" element={<Participants />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/tags" element={<Tags />} />
        <Route
          path="/event/:id"
          element={width < 1024 ? <EventDetailsMobile /> : <EventDetails />}
        />
        <Route
          path="/event/:id/:tab"
          element={width < 1024 ? <EventDetailsMobile /> : <EventDetails />}
        />
        <Route path="/entities/:id/:tab" element={<EntityDetails />} />
        <Route
          path="/entities/:id/control-access"
          element={<EntityControlAccess />}
        />
        <Route path="/control-access" element={<ControlAccessDetails />} />
        <Route
          path="/entities/:id/control-access/:controlAccessId/:tab"
          element={<ControlAccessDetails />}
        />

        <Route path="/settings" element={<Settings />} />
        <Route
          path="/event-timing-location-details/:id"
          element={<TimingLocationDetails />}
        />

        <Route
          path="/event/:id/layout/:layoutId/section/:id2"
          element={<TimingLocationDetails />}
        />
      </Route>
      {/* Page not found */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default MainRouter;
