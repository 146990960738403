import clsx from 'clsx';
import { ReactNode } from 'react';
import Modal from 'react-modal';

import styles from './TLModal.module.scss';
import {
  TLModalFooter,
  TLModalFooterProps,
} from './TLModalFooter/TLModalFooter';
import { TLModalHeader } from './TLModalHeader/TLModalHeader';

export enum ModalSize {
  'FLUID' = 'fluid',
  'XS' = 'xs',
  'S' = 's',
  'M' = 'm',
  'L' = 'l',
  'XL' = 'xl',
  'CUSTOM80' = '80',
  'FULL' = 'full',
}

const modalSizes = {
  xs: styles.extraSmall,
  s: styles.small,
  m: styles.medium,
  l: styles.large,
  xl: styles.extraLarge,
  full: styles.full,
  '80': styles.custom80,
};

export type TLModalProps = Omit<ReactModal.Props, 'isOpen'> & {
  /**
   * The name of the icon to display.
   * @link https://fontawesome.com/v5/search?m=free
   */
  titleIcon?: string;
  isOpen?: boolean;
  title?: string;
  subtitle?: string;
  footer?: ReactNode;
  hideFooter?: boolean;
  hideHeader?: boolean;
  size?: ModalSize;
  onClose?: () => void;
  onConfirm?: TLModalFooterProps['onConfirm'];
  moreInfo?: TLModalFooterProps['moreInfo'];
};

export const TLModal = ({
  isOpen = true,
  size,
  className,
  title,
  children,
  subtitle,
  titleIcon,
  footer,
  hideFooter = true,
  hideHeader = false,
  onClose,
  onConfirm,
  moreInfo,
  ...props
}: TLModalProps) => (
  <Modal
    isOpen={isOpen}
    shouldCloseOnOverlayClick={true}
    className={clsx(styles.modal, className, size && modalSizes[size])}
    onRequestClose={onClose}
    {...props}
  >
    {!hideHeader && (
      <TLModalHeader
        icon={titleIcon}
        title={title}
        subtitle={subtitle}
        onClose={onClose}
      />
    )}

    {children}

    {!hideFooter &&
      (footer ?? (
        <TLModalFooter
          onClose={onClose}
          onConfirm={onConfirm}
          moreInfo={moreInfo}
        />
      ))}
  </Modal>
);
