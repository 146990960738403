import CustomMarker from 'components/CustomMarkerPin/CustomMarker';
import { Icon } from 'components/Icon/Icon';
import { DropDownInputGroup } from 'components/Input/DropDownInputGroup';
import { useDropdownField } from 'components/Input/hooks/useDropdownField';
import { useFetchEventLayouts } from 'hooks/event/layout';
import { useZodForm } from 'hooks/useZodForm';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ImageMarker, { Marker } from 'react-image-marker';

import style from '../Events.module.css';
import {
  EventLayoutPresentationImage,
  EventLayoutPresentationType,
} from '../Layout/interfaces';
import { LayoutLocationPicker } from '../Layout/LayoutScehemForm/LayoutLocationPicker';
import { useMultiStep } from '../MultiStepForm/context/multi-step-context';
import { MultiStepFormContent } from '../MultiStepForm/MultiStepFormContent/MultiStepFormContent';
import { MultiStepFormContentFooter } from '../MultiStepForm/MultiStepFormContent/MultiStepFormContentFooter/MultiStepFormContentFooter';
import {
  CreateTimingLocationSchema,
  PositionSchema,
  positionSchema,
} from '../MultiStepForm/timingLocation-schema';

type PositionFormProps = {
  defaultValues: PositionSchema;
  step: number;
  onCancel?: () => void;
  eventId: any;
};

export const PositionForm = ({
  defaultValues,
  step,
  eventId,
}: PositionFormProps) => {
  const { next } = useMultiStep();

  const { data } = useFetchEventLayouts({
    eventId: eventId,
    offset: 0,
    rowLimit: 100,
  });

  const {
    setValue: setContextValue,
  } = useFormContext<CreateTimingLocationSchema>();

  const {
    watch,
    //control,
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useZodForm<PositionSchema>(
    {
      defaultValues,
      mode: 'onBlur',
    },
    positionSchema,
  );

  const [marker, setMarker] = useState<Array<Marker>>(
    defaultValues.coordinates?.lat !== undefined &&
      defaultValues.coordinates.lng !== undefined
      ? [
          {
            top: defaultValues.coordinates?.lat as number,
            left: defaultValues.coordinates?.lng as number,
          },
        ]
      : [],
  );

  const [cor, setCor] = useState<object>(
    defaultValues.coordinates?.lat !== undefined &&
      defaultValues.coordinates.lng !== undefined
      ? [
          {
            lat: defaultValues.coordinates?.lat as number,
            lng: defaultValues.coordinates?.lng as number,
          },
        ]
      : [],
  );

  let coordinates;

  const onLayoutSchemeChange = (
    type: EventLayoutPresentationType,
    locationOrImage:
      | File
      | (google.maps.LatLngLiteral & { zoom: number | undefined }),
  ) => {
    const { lat, lng, zoom } = locationOrImage as google.maps.LatLngLiteral & {
      zoom: number | undefined;
    };

    const image = locationOrImage as File;

    var pin =
      type === EventLayoutPresentationType.GoogleMap
        ? {
            type,
            geoRange: {
              center: { lat, lng },
              zoom: zoom || 13,
            },
          }
        : {
            type,
            image,
          };

    coordinates = pin.geoRange?.center;
  };

  const layouts = (data?.data || []).map((c) => ({
    value: c._id,
    label: c.name,
  }));

  const [{ layout }, onDropdownChange] = useDropdownField({
    names: ['layout'],
    setValue,
    watch,
  });

  useEffect(() => {
    if (defaultValues.layout !== layout) {
      setMarker([]);
      setCor({});
    } else {
      setMarker([
        {
          top: defaultValues.coordinates?.lat as number,
          left: defaultValues.coordinates?.lng as number,
        },
      ]);
      setCor({
        lat: defaultValues.coordinates?.lat as number,
        lng: defaultValues.coordinates?.lng as number,
      });
    }
  }, [layout, defaultValues]);

  const onSubmit = (data: PositionSchema) => {
    if (tab === 'image') {
      coordinates = {
        lat: marker[0].top,
        lng: marker[0].left,
      };
    }
    data.coordinates = coordinates;
    reset(data);
    setContextValue('position', data);
    next();
  };

  const selectedLayout = data?.data.find((lout) => lout._id === layout);
  const tab =
    selectedLayout?.presentation?.type === 'googlemap' ? 'map' : 'image';

  return (
    <MultiStepFormContent step={step}>
      <DropDownInputGroup
        register={register}
        label="Layout"
        placeholder="Select layout"
        name="layout"
        value={layout}
        errors={errors}
        options={layouts}
        onChange={onDropdownChange}
      />

      {!selectedLayout && (
        <>
          <Icon
            icon="camera"
            className={style.layout_details_image_map_no_image}
          />
        </>
      )}
      {selectedLayout && (
        <>
          {tab === 'image' && (
            <ImageMarker
              src={
                (selectedLayout?.presentation as EventLayoutPresentationImage)
                  ?.image?.largeSource
              }
              markers={marker}
              onAddMarker={(marker: Marker) => {
                const newMarker: Marker = {
                  top: marker.top,
                  left: marker.left,
                };
                setMarker([newMarker]);
              }}
              markerComponent={CustomMarker}
            />
          )}

          <LayoutLocationPicker
            eventLayout={selectedLayout}
            visible={tab === 'map'}
            onChange={onLayoutSchemeChange}
            timingLocationCoordinates={cor}
          />
        </>
      )}
      <MultiStepFormContentFooter
        isValid={isValid}
        onConfirm={handleSubmit(onSubmit)}
      />
    </MultiStepFormContent>
  );
};

export default PositionForm;
