import { DropdownMenu, Separator, DropdownItem } from '@lanaco/lnc-react-ui';
import { colorPalette } from 'assets/theme/timelineTheme';
import { ModalSize, TLModalProps } from 'components/Modal';
import { TLCase, TLSwitch } from 'components/utils';
import { EventTag } from 'pages/Events/interfaces';

import UserReplace from '../../../assets/icons/solid/arrow-path.svg';
import { ReactComponent as ManageDevices } from '../../../assets/icons/solid/device.svg';
import { ReactComponent as Icon } from '../../../assets/icons/solid/ellipsis-horizontal.svg';
import pencil from '../../../assets/icons/solid/pencil.svg';
import { ReactComponent as Power } from '../../../assets/icons/solid/power.svg';
import { ReactComponent as ViewQR } from '../../../assets/icons/solid/qr-code.svg';
import trash from '../../../assets/icons/solid/trash.svg';
import UserMinusIcon from '../../../assets/icons/solid/user-minus.svg';
import UserPlusIcon from '../../../assets/icons/solid/user-plus.svg';
import SubtractIcon from '../../../assets/icons/subtract.svg';
import { CodebookAction } from '../interfaces';

import style from './TableOptionsButton.module.css';

type Props = {
  actions: Array<CodebookAction>;
  status: string;
  editLabel?: string;
  disableLabel?: string;
  deleteLabel?: string;
  removeLabel?: string;
  exportLabel?: string;
  renderEditComponent?: any;
  disableButtonName?: string;
  deleteButtonName?: string;
  removeButtonName?: string;
  exportButtonName?: string;
  onConfirmClick?: any;
  onExportClick?: any;
  warningMessageDelete?: string;
  warningMessageRemove?: string;
  warningMessageDisable?: string;
  warningMessageExport?: string;
  onDisableClick?: any;
  onDeleteClick?: any;
  onRemoveClick?: any;
  onEditClick?: any;
  onCancelClick?: any;
  confirmButtonName?: string;
  id?: string;
  data?: any;
  removeIcon?: any;
  deleteIcon?: any;
  exportIcon?: any;
  modalSize?: TLModalProps['size'];
  isExport?: boolean;
  viewQRLabel?: string;
  viewManageDevicesLabel?: string;
  renderQRComponent?: any;
  renderManageDevicesComponent?: any;
  exportTitle?: string;
  deleteTitle?: string;
  onUnassignClick?: (id: string, modalRef: { close: () => void }) => void;
};

export const TableOptionsButton = ({
  actions,
  status,
  data,
  deleteButtonName,
  deleteLabel,
  disableButtonName,
  disableLabel,
  editLabel,
  exportButtonName,
  exportIcon,
  exportLabel,
  exportTitle,
  id,
  isExport,
  modalSize,
  onDeleteClick,
  onDisableClick,
  onExportClick,
  onRemoveClick,
  onUnassignClick,
  removeButtonName,
  removeIcon,
  removeLabel,
  renderEditComponent,
  renderManageDevicesComponent,
  renderQRComponent,
  viewManageDevicesLabel,
  viewQRLabel,
  warningMessageDelete,
  warningMessageDisable,
  warningMessageExport,
  warningMessageRemove,
}: Props) => (
  <DropdownMenu
    control={
      <button className={style.button}>
        <Icon fill="#475569" width={'32'} height={'32'} />
      </button>
    }
    placement="left"
    className={style.dropdownMenu}
  >
    {actions.map((action) => (
      <div key={action}>
        <TLSwitch condition={action}>
          <TLCase value={CodebookAction.edit}>
            <DropdownItem
              name="edit"
              data={data}
              renderComponent={renderEditComponent}
            >
              <img
                src={pencil}
                alt="pencil"
                width={'17px'}
                height={'17px'}
              ></img>
              <span>{editLabel}</span>
            </DropdownItem>
          </TLCase>
          <TLCase value={CodebookAction.disable}>
            <DropdownItem
              id={id}
              name="disable"
              confirmButtonName={disableButtonName}
              title={disableLabel}
              onConfirmClick={onDisableClick}
              warningMessage={warningMessageDisable}
              icon="power"
            >
              <Power fill="#94A3B8" width={'17px'} height={'17px'} />
              <span>{status === 'active' ? 'Disable' : 'Enable'}</span>
            </DropdownItem>
          </TLCase>
          <TLCase value={CodebookAction.delete}>
            <DropdownItem
              id={id}
              name="delete"
              className={style.dropdownItemDelete}
              confirmButtonName={deleteButtonName}
              onConfirmClick={onDeleteClick}
              warningMessage={warningMessageDelete}
              size={modalSize || ModalSize.S}
              title={deleteLabel}
              isDeleteButton={true}
              icon="exclamation-triangle"
              iconColor={colorPalette.red[600]}
            >
              <img
                className={style.delete}
                src={trash}
                alt="Trash"
                width={'17px'}
                height={'17px'}
              ></img>
              <span className={style.delete}>{deleteLabel}</span>
            </DropdownItem>
          </TLCase>
          <TLCase value={CodebookAction.assignToParticipant}>
            <DropdownItem
              name="assignToParticipant"
              data={data}
              renderComponent={renderEditComponent}
              title="Assign to  participant"
            >
              <img
                src={UserPlusIcon}
                alt="plus-icon"
                width={'17px'}
                height={'17px'}
              ></img>
              <span>Assign to participant</span>
            </DropdownItem>
          </TLCase>
          <TLCase value={CodebookAction.unassignFromParticipant}>
            <DropdownItem
              id={id}
              name="edit"
              onConfirmClick={onUnassignClick}
              confirmButtonName="Unassign"
              warningMessage={`${
                (data as EventTag).tag?.name || (data as EventTag).number
              } will be unassigned from the participant ${
                (data as EventTag).participant?.firstLastName
              }.`}
              title="Unassign tag?"
              icon="tag-minus"
            >
              <img
                src={UserMinusIcon}
                alt="Minus"
                width={'17px'}
                height={'17px'}
              ></img>
              <span>Unassign from participant</span>
            </DropdownItem>
          </TLCase>
          <TLCase value={CodebookAction.assignToAnotherParticipant}>
            <DropdownItem
              name="assignToAnotherParticipant"
              data={data}
              renderComponent={renderEditComponent}
              title="Assign to another participant"
            >
              <img
                src={UserReplace}
                alt="plus-icon"
                width={'17px'}
                height={'17px'}
              ></img>
              <span>Assign to another participant</span>
            </DropdownItem>
          </TLCase>
          <TLCase value={CodebookAction.exportLog}>
            <>
              <Separator className={style.separator} />
              <DropdownItem
                id={id}
                name="export"
                confirmButtonName={exportButtonName}
                onConfirmClick={onExportClick}
                warningMessage={warningMessageExport}
                size={modalSize || ModalSize.S}
                title={exportTitle}
                isExport={isExport}
                icon={exportIcon}
              >
                <img
                  src={SubtractIcon}
                  alt="Export"
                  width={'17px'}
                  height={'17px'}
                ></img>
                <span>{exportLabel}</span>
              </DropdownItem>
            </>
          </TLCase>

          <TLCase value={CodebookAction.removeTag}>
            <>
              <Separator className={style.separator} />
              <DropdownItem
                id={id}
                name="remove"
                className={style.dropdownItemDelete}
                confirmButtonName={removeButtonName}
                onConfirmClick={onRemoveClick}
                warningMessage={warningMessageRemove}
                size={modalSize || ModalSize.S}
                title={removeLabel}
                isRemoveButton={true}
                icon={removeIcon}
              >
                <img
                  className={style.delete}
                  src={trash}
                  alt="Trash"
                  width={'17px'}
                  height={'17px'}
                ></img>
                <span className={style.delete}>{removeLabel}</span>
              </DropdownItem>
            </>
          </TLCase>
          <TLCase value={CodebookAction.viewQR}>
            <DropdownItem
              name="viewQR"
              data={data}
              renderComponent={renderQRComponent}
              title={viewQRLabel}
              size={ModalSize.XS}
            >
              <ViewQR fill="#94A3B8" />
              <span>{viewQRLabel}</span>
            </DropdownItem>
          </TLCase>
          <TLCase value={CodebookAction.manageDevices}>
            <DropdownItem
              name="manageDevices"
              data={data}
              renderComponent={renderManageDevicesComponent}
              title={viewManageDevicesLabel}
              size={ModalSize.L}
            >
              <ManageDevices fill="#94A3B8" />
              <span>{viewManageDevicesLabel}</span>
            </DropdownItem>
          </TLCase>
        </TLSwitch>
      </div>
    ))}
  </DropdownMenu>
);
