import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { ClockIcon } from '@heroicons/react/24/outline';
import {
  Button,
  Divider,
  Flex,
  Grid,
  Stack,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useDidUpdate, useViewportSize } from '@mantine/hooks';
import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import Dropzone from 'components/Dropzone/Dropzone';
import InteractiveMap from 'components/Map/InteractiveMap';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import Select from 'components/Select/Select';
import { layoutTypes } from 'pages/Entities/consts';
import { ModalFormProps } from 'pages/Entities/ModalManager/types';
import { useEffect, useRef, useState } from 'react';
import { useTimezoneByEvent } from 'services/entity/home';
import {
  useAddEntityLayout,
  useEntityLayout,
  useEntityLayouts,
  useLayoutLibrary,
  useUpdateEntityLayout,
} from 'services/entity/layout';
import { EntityLayoutSubmitData } from 'services/entity/types';
import { statuses } from 'utils/consts';
import { convertDate, convertTime } from 'utils/createDateAndTime';

import { AddLayoutModalForm, LayoutType } from '../../types';
import LayoutLibrary from '../Components/LayoutLibrary/LayoutLibrary';

const AddLayoutModal = ({
  dataId = '',
  eventId = '',
  setFormState,
  setIsFormValid,
  setOnFormSubmit,
}: ModalFormProps & { eventId: string }) => {
  const [layoutType, setLayoutType] = useState<LayoutType>(layoutTypes[0]);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [initialImage, setInitialImage] = useState<string>();
  const [zoom, setZoom] = useState<number | null>(15);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: -33.860664,
    lng: 151.208138,
  });
  const [marker, setMarker] = useState<google.maps.LatLngLiteral | null>(null);
  const [markers, setMarkers] = useState<Array<google.maps.LatLngLiteral>>();
  const [selectedLayout, setSelectedLayout] = useState<string | null>(null);
  const startTimeRef = useRef<HTMLInputElement>(null);
  const endTimeRef = useRef<HTMLInputElement>(null);
  const openRef = useRef<() => void>(null);

  const { height } = useViewportSize();

  const { data: entityLayoutsData } = useEntityLayouts(eventId);
  const { data: tz } = useTimezoneByEvent(eventId);
  const { data: entityLayoutData } = useEntityLayout(eventId, dataId);
  const { data: layoutLibraryData } = useLayoutLibrary();
  const { mutate: mutateAdd } = useAddEntityLayout();
  const { mutate: mutateUpdate } = useUpdateEntityLayout();

  const form = useForm<AddLayoutModalForm>({
    mode: 'controlled',
    validateInputOnChange: true,
    initialValues: {
      name: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      minAge: undefined,
      maxAge: undefined,
      status: statuses[0].value,
      capacity: 0,
      description: '',
      picture: null,
      latitude: null,
      longitude: null,
      zoom: null,
      libraryLayoutId: null,
      // Dodat properti zato sto se stanje layoutType stejta izgubi kad se onSubmit funkcija
      // okine u SingleStepModal komponenti
      layoutType: 'image',
    },
    validate: {
      name: (value) => (!value ? true : null),
      picture: (value) =>
        layoutType.id === 'image' && !value ? 'Image is required' : null,
      longitude: (value) =>
        layoutType.id.includes('Map') && !value ? 'Location is required' : null,
      latitude: (value) =>
        layoutType.id.includes('Map') && !value ? 'Location is required' : null,
      libraryLayoutId: (value) =>
        layoutType.id === 'library' && !value
          ? 'Layout picking is required'
          : null,
      minAge: (value, values) => {
        if (value === undefined || values.maxAge === undefined) return null;
        if (Number(value) >= Number(values.maxAge)) {
          return true;
        }
        return null;
      },
      maxAge: (value, values) => {
        if (value === undefined || values.minAge === undefined) return null;
        if (Number(value) <= Number(values.minAge)) {
          return true;
        }
        return null;
      },
    },
  });

  const getInfoText = () => {
    if (layoutType.id === 'image')
      return imageFile || initialImage
        ? 'Layout image uploaded'
        : layoutType.infoText;
    if (layoutType.id.includes('Map'))
      return marker
        ? 'Pin has been placed. You can replace point if needed.'
        : layoutType.infoText;
    if (layoutType.id === 'library')
      return selectedLayout
        ? `"${
            layoutLibraryData.find((layout) => layout.id === selectedLayout)
              ?.title
          }" has been selected`
        : layoutType.infoText;
  };

  useDidUpdate(() => {
    form.setFieldValue('picture', imageFile ?? null);
  }, [imageFile]);

  useDidUpdate(() => {
    form.setFieldValue('zoom', zoom);
    form.setFieldValue('latitude', marker?.lat);
    form.setFieldValue('longitude', marker?.lng);
  }, [zoom, marker]);

  useDidUpdate(() => {
    form.setFieldValue('libraryLayoutId', selectedLayout);
  }, [selectedLayout]);

  useDidUpdate(() => {
    form.setFieldValue('layoutType', layoutType.id);
  }, [layoutType]);

  useEffect(() => {
    if (!entityLayoutData) return;

    form.initialize({
      name: entityLayoutData?.name ?? '',
      startDate: entityLayoutData?.startDateTime
        ? convertDate(entityLayoutData.startDateTime, tz)
        : '',
      startTime: entityLayoutData?.startDateTime
        ? convertTime(entityLayoutData.startDateTime, tz)
        : '',
      endDate: entityLayoutData?.endDateTime
        ? convertDate(entityLayoutData.endDateTime)
        : '',
      endTime: entityLayoutData?.endDateTime
        ? convertTime(entityLayoutData.endDateTime)
        : '',
      minAge: entityLayoutData?.participantMinAge,
      maxAge: entityLayoutData?.participantMaxAge,
      status:
        statuses.find((status) => status.value === entityLayoutData?.status)
          ?.value ?? statuses[0].value,
      capacity: entityLayoutData?.maxParticipants ?? 0,
      description: entityLayoutData?.description ?? '',
      picture: entityLayoutData?.presentation?.image?.largeSource ?? null,
      latitude: entityLayoutData?.presentation?.geoRange?.center?.lat ?? null,
      longitude: entityLayoutData?.presentation?.geoRange?.center?.lng ?? null,
      zoom: entityLayoutData?.presentation?.geoRange?.zoom ?? null,
      libraryLayoutId: null,
      layoutType: 'image',
    });

    const pictureValue = entityLayoutData?.presentation?.image?.largeSource;
    if (pictureValue && !form.initialized) {
      form.setFieldValue('picture', pictureValue);
      setInitialImage(pictureValue);
      form.setFieldValue('layoutType', 'image');
      setLayoutType(layoutTypes[0]);
    }

    const locationValue = entityLayoutData?.presentation?.geoRange;
    if (locationValue && !form.initialized) {
      setMarker({
        lat: locationValue?.center?.lat,
        lng: locationValue?.center?.lng,
      });
      setCenter({
        lat: locationValue?.center?.lat,
        lng: locationValue?.center?.lng,
      });
      setZoom(locationValue?.zoom);
      form.setFieldValue(
        'layoutType',
        locationValue?.autozoom ? 'fixedMap' : 'dynamicMap',
      );
      setLayoutType(
        // Ako postoji autozoom properti onda se ucitava fiksna mapa, u suprotnom dinamicna
        locationValue?.autozoom ? layoutTypes[2] : layoutTypes[1],
      );
    }
  }, [form, entityLayoutData]);

  useEffect(() => {
    const filteredLayouts = entityLayoutsData?.eventLayouts
      ?.filter(
        (layout) =>
          layout._id !== entityLayoutData?._id &&
          layout?.presentation?.type !== 'image',
      )
      ?.map((layout) => layout?.presentation?.geoRange?.center)
      ?.filter(
        (center): center is google.maps.LatLngLiteral => center !== undefined,
      );

    setMarkers(filteredLayouts);
  }, [entityLayoutsData]);

  useEffect(() => {
    if (form.isValid()) {
      setIsFormValid(true);
      const newFormState = form.values;

      if (setFormState) {
        setFormState((prevState) => ({
          ...prevState,
          ...newFormState,
        }));
      }
    } else {
      setIsFormValid(false);
    }
  }, [form, setFormState, setIsFormValid]);

  const onSubmit = (data: AddLayoutModalForm) => {
    const submitData: EntityLayoutSubmitData = {
      id: dataId,
      eventId: eventId,
      name: data.name,
      startDateTime: data.startDate
        ? `${data.startDate}T${data.startTime ? data.startTime : '00:00:00'}Z`
        : undefined,
      endDateTime: data.endDate
        ? `${data.endDate}T${data.endTime ? data.endTime : '00:00:00'}Z`
        : undefined,
      maxParticipants: data.capacity,
      participantMinAge: data.minAge,
      participantMaxAge: data.maxAge,
      description: data.description,
      status: data.status,
      presentation: ['dynamicMap', 'fixedMap'].includes(data.layoutType)
        ? {
            type: 'googlemap',
            geoRange: {
              autozoom: data.layoutType === 'fixedMap',
              zoom: data.zoom!,
              center: {
                lat: data.latitude!,
                lng: data.longitude!,
              },
            },
          }
        : {
            type: data.layoutType === 'image' ? 'image' : 'template',
            image:
              data.layoutType === 'image'
                ? data.picture!
                : data.libraryLayoutId!,
          },
    };

    if (!eventId) return;

    if (dataId)
      mutateUpdate({
        eventId,
        layoutId: dataId,
        data: submitData,
      });
    else mutateAdd({ eventId, data: submitData });
  };

  useEffect(() => {
    setOnFormSubmit?.(() => onSubmit);
  }, [setOnFormSubmit]);

  return (
    <Grid>
      <Grid.Col span={5}>
        <ScrollArea.Autosize
          mah={height - 10.75 * 24}
          mr="-0.5rem"
          type="auto"
          styles={{ root: { paddingRight: '0.5rem' } }}
        >
          <TextInput
            mb="1.5rem"
            label="Name"
            placeholder="Layout name"
            withAsterisk
            key={form?.key('name')}
            {...form?.getInputProps('name')}
          />
          <Grid mb="1.5rem" gutter="1.5rem">
            <Grid.Col span={6}>
              <DatePickerInput
                label="Start date"
                value={form.values.startDate}
                onChange={(date) => form.setFieldValue('startDate', date)}
              />
            </Grid.Col>

            <Grid.Col span={6}>
              <TimeInput
                label="Start time"
                ref={startTimeRef}
                rightSection={
                  <ClockIcon
                    width={20}
                    height={20}
                    color="#0F172A"
                    onClick={() => startTimeRef.current?.showPicker()}
                  />
                }
                key={form.key('startTime')}
                {...form.getInputProps('startTime')}
              />
            </Grid.Col>
          </Grid>
          <Grid mb="1.5rem" gutter="1.5rem">
            <Grid.Col pt={0} span={6}>
              <DatePickerInput
                label="End date"
                value={form.values.endDate}
                onChange={(date) => form.setFieldValue('endDate', date)}
              />
            </Grid.Col>

            <Grid.Col pt={0} span={6}>
              <TimeInput
                label="End time"
                ref={endTimeRef}
                rightSection={
                  <ClockIcon
                    width={20}
                    height={20}
                    color="#0F172A"
                    onClick={() => endTimeRef.current?.showPicker()}
                  />
                }
                key={form.key('endTime')}
                {...form.getInputProps('endTime')}
              />
            </Grid.Col>
          </Grid>
          <Flex mb="1.5rem" align="flex-end">
            <Select
              flex={1}
              label="Age limit of participants"
              placeholder="0"
              data={Array.from({ length: 99 }, (_, i) => ({
                value: `${i + 1}`,
                label: `${i + 1}`,
              }))}
              {...form.getInputProps('minAge')}
            />

            <Text
              span
              mx="0.75rem"
              mb="0.25rem"
              style={{ alignSelf: 'flex-end' }}
            >
              to
            </Text>

            <Select
              flex={1}
              label=" "
              placeholder="None"
              data={Array.from({ length: 99 }, (_, i) => ({
                value: `${i + 1}`,
                label: `${i + 1}`,
              }))}
              {...form.getInputProps('maxAge')}
            />
          </Flex>
          <Flex mb="1.5rem" gap="1.5rem">
            <Select
              flex={1}
              label="Status"
              data={statuses.map((status) => ({
                value: status.value,
                label: status.label,
              }))}
              key={form.key('status')}
              {...form.getInputProps('status')}
            />

            <TextInput
              flex={1}
              label="Capacity"
              type="number"
              placeholder="0"
              key={form?.key('capacity')}
              {...form?.getInputProps('capacity')}
            />
          </Flex>
          <Textarea
            label="Description"
            placeholder="Short description about the layout"
            key={form?.key('description')}
            {...form?.getInputProps('description')}
            styles={{ input: { height: '6.25rem' } }}
          />
        </ScrollArea.Autosize>
      </Grid.Col>
      <Grid.Col span={7}>
        <Stack
          h={'100%'}
          p="1rem"
          bd="1px solid #e2e8f0"
          gap={0}
          style={{ borderRadius: '0.75rem' }}
        >
          <Flex mb="1rem" gap="0.5rem">
            <InformationCircleIcon
              height="1.25rem"
              width="1.25rem"
              color="#60A5FA"
            />
            <Text c="#3b82f6" fz="0.875rem" fw={400}>
              {getInfoText()}
            </Text>
          </Flex>

          <Divider mb="1rem" c="#e2e8f0" />

          <Button.Group mb="1rem">
            {layoutTypes.map((item) => (
              <Button
                key={item.id}
                flex={1}
                bg={item.id === layoutType.id ? '#f1f5f9' : '#ffffff'}
                c={item.id === layoutType.id ? '#334155' : '#64748b'}
                bd="1px solid #e2e8f0"
                onClick={() => setLayoutType(item)}
              >
                {item.value}
              </Button>
            ))}
          </Button.Group>

          {layoutType.id === 'image' && (
            <Dropzone
              height="100%"
              initialImage={initialImage}
              setImageFile={setImageFile}
              openRef={openRef}
            />
          )}

          {['dynamicMap', 'fixedMap'].includes(layoutType.id) && (
            <InteractiveMap
              height="100%"
              borderRadius="0.5rem"
              markersData={markers}
              autozoom={layoutType.id === 'dynamicMap'}
              zoom={zoom}
              setZoom={setZoom}
              center={center}
              setCenter={setCenter}
              marker={marker}
              setMarker={setMarker}
              readLocation={
                entityLayoutData?.presentation?.geoRange ? false : true
              }
            />
          )}

          {layoutType.id === 'library' && (
            <LayoutLibrary
              height="100%"
              layoutsData={layoutLibraryData}
              selectedLayout={selectedLayout}
              setSelectedLayout={setSelectedLayout}
            />
          )}
        </Stack>
      </Grid.Col>
    </Grid>
  );
};

export default AddLayoutModal;
