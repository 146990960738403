import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import {
  ASSIGN_ONE_OR_MORE_PARTICIPANT_ON_TAG,
  UNASSIGN_ONE_OR_MORE_PARTICIPANT_FROM_TAG,
} from 'utils/apiUrl';

interface Props {
  eventId: string;
  tagIds: string[];
  participantId: string;
}

export class EventTagAssignParticipantService extends ResponseInterceptor {
  static async assignParticipantOnEventTag({
    eventId,
    tagIds,
    participantId,
  }: Props): Promise<APIAxiosResponse<string[]>> {
    const res = await client.post(
      ASSIGN_ONE_OR_MORE_PARTICIPANT_ON_TAG(eventId, participantId),
      { tagIds },
    );
    return this.handleResponse(res);
  }

  static async unassignParticipantFromEventTag({
    eventId,
    tagIds,
    participantId,
  }: Props): Promise<APIAxiosResponse<string[]>> {
    const res = await client.post(
      UNASSIGN_ONE_OR_MORE_PARTICIPANT_FROM_TAG(eventId, participantId),
      { tagIds },
    );
    return this.handleResponse(res);
  }
}
