import { InputGroup } from 'components/Input';
import { ResponsiveRow } from 'components/utils/ResponsiveRow/ResponsiveRow';
import { useZodForm } from 'hooks/useZodForm';
import { useFormContext } from 'react-hook-form';

import {
  ContactInfoSchema,
  contactInfoSchema,
  EventFormSchema,
} from '../MultiStepForm/event-schema';
import { MultiStepFormContent } from '../MultiStepForm/MultiStepFormContent/MultiStepFormContent';
import { MultiStepFormContentFooter } from '../MultiStepForm/MultiStepFormContent/MultiStepFormContentFooter/MultiStepFormContentFooter';

type Props = {
  isSubmitting?: boolean;
  defaultValues: ContactInfoSchema;
  step: number;
};

const EventContactInfoForm = ({ step, isSubmitting, defaultValues }: Props) => {
  const { setValue: setContextValue } = useFormContext<EventFormSchema>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useZodForm<ContactInfoSchema>(
    {
      defaultValues,
    },
    contactInfoSchema,
  );

  const onSubmit = (data: ContactInfoSchema) => {
    reset(data);
    setContextValue('contactPerson', data);
  };

  return (
    <MultiStepFormContent step={step}>
      <InputGroup
        register={register}
        label="Contact person"
        placeholder="e.g. John Doe"
        name="name"
        errors={errors}
      />

      <ResponsiveRow>
        <InputGroup
          register={register}
          label="Email"
          placeholder="e.g. john@company.com"
          name="email"
          errors={errors}
        />

        <InputGroup
          register={register}
          label="Phone number"
          placeholder="e.g. 0491 570 006"
          name="phoneNumber"
          errors={errors}
        />
      </ResponsiveRow>

      <MultiStepFormContentFooter
        isSubmitting={isSubmitting}
        isValid={isValid}
        onConfirm={handleSubmit(onSubmit)}
      />
    </MultiStepFormContent>
  );
};

export default EventContactInfoForm;
