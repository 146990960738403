import { client } from 'services/api';
import { ResponseInterceptor } from 'services/response-interceptor';
import { APIAxiosResponse } from 'services/types';
import { DELETE_EVENT_DEVICE, DELETE_EVENT_DEVICES } from 'utils/apiUrl';

export class EventDeviceService extends ResponseInterceptor {
  static async deleteEventDevice(
    eventId: string,
    deviceId: string,
  ): Promise<APIAxiosResponse<string>> {
    const response = await client.delete(
      DELETE_EVENT_DEVICE(eventId, deviceId),
    );
    return this.handleResponse(response);
  }

  static async deleteEventDevices(
    eventId: string,
    deviceIds: string[],
  ): Promise<APIAxiosResponse<string[]>> {
    const response = await client.delete(DELETE_EVENT_DEVICES(eventId), {
      data: { deviceIds },
    });
    return this.handleResponse(response);
  }
}
