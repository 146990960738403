import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deviceStatisticsBaseKey } from 'hooks/event/statistics';
import { DeviceService } from 'services/device';

import { deviceBaseKey } from './baseKeys';

interface DeleteDevicesProps {
  ids: string[];
}

export const useDeleteDevices = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ ids }: DeleteDevicesProps) =>
      await DeviceService.deleteDevices(ids),
    onSuccess: () => {
      queryClient.invalidateQueries([deviceBaseKey]);
      queryClient.invalidateQueries([deviceStatisticsBaseKey]);
    },
  });
};
