import {
  Button,
  FlexGrid,
  FlexGridItem,
  FormField,
  PasswordInput,
  Spinner,
  TextInput,
} from '@lanaco/lnc-react-ui';
import { useAppDispatch, useAppSelector } from 'hooks/useAppDispatch';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Navigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { loginUser } from 'store/slices/userSlice/userActions';
import { getUser } from 'store/slices/userSlice/userSlice';

import icon from '../../assets/images/logo.svg';

import { LoginData } from './interfaces';
import style from './Login.module.css';

type Props = {};

const Login = (props: Props) => {
  let dispatch = useAppDispatch();
  let user = useAppSelector(getUser).user;

  const [loading, setLoading] = useState(false);
  const [badRequest, setBadRequest] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>();

  // const onClickHandler = async () => {
  //   let data: LoginData = {
  //     email: 'test_user_for_company@gmail.com',
  //     password: 'password123$',
  //   };
  //   dispatch(loginUser(data));
  // };

  if (user.loggedIn) {
    return <Navigate to="/" />;
  }

  const onSubmit: SubmitHandler<LoginData> = async (data) => {
    setLoading(true);
    setBadRequest(false);
    dispatch(loginUser(data))
      .then((resp) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setBadRequest(true);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FlexGrid className={style.container}>
        <FlexGridItem XS={0} L={7} M={6} XL={8}>
          <div className={style.backgroundImage}></div>
        </FlexGridItem>
        <FlexGridItem XS={12} L={5} M={6} XL={4}>
          <div className={style.sidebar}>
            <img src={icon} className={style.logo} alt="Logo"></img>
            <span className={style.naslov1}>Track. Analyze. Optimize. </span>
            <span className={style.naslov2}>
              Make complex analytics straightforward and intelligent.{' '}
            </span>
            <input
              hidden
              type="password"
              {...register('password')}
              autoComplete="new-password"
            />
            <FormField
              text={errors.email && <span>{errors.email.message}</span>}
              color={errors.email && 'danger'}
            >
              <TextInput
                placeholder={'Email'}
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Email is required.',
                  },
                })}
                color={errors.email && 'danger'}
              />
            </FormField>
            <FormField
              text={errors.password && <span>{errors.password.message}</span>}
              color={errors.password && 'danger'}
            >
              <PasswordInput
                placeholder={'Password'}
                {...register('password', {
                  required: {
                    value: true,
                    message: 'Password is required.',
                  },
                })}
                color={errors.password && 'danger'}
              />
            </FormField>
            {badRequest ? (
              <label className={style.errorLabel}>
                Incorrect email or password. Try again.
              </label>
            ) : null}
            <Button className={style.loginButton} type="submit">
              {' '}
              {loading ? <Spinner size={'small'} /> : null} Login{' '}
            </Button>
            <div className={style.forgottenPass}>
              <NavLink to={'/reset-password'}>Forgotten password?</NavLink>
            </div>
            <div className={style.dontAccount}>
              <p>
                Don't have TimeLine account?&nbsp;
                <NavLink to={'/registration'}>Register</NavLink>
              </p>
            </div>
          </div>
        </FlexGridItem>
      </FlexGrid>
    </form>
  );
};

export default Login;
