import { Button, TabItem, Tabs } from '@lanaco/lnc-react-ui';
import type { RowSelectionState } from '@tanstack/react-table';
import { Icon } from 'components/Icon/Icon';
import { ModalSize, TLModal } from 'components/Modal';
import QR from 'components/QR/QR';
import { useFetchEventDetails } from 'hooks/event/details';
import { useFetchEventTimingLocationDetails } from 'hooks/event/timing-location/useFetchEventTimingLocationDetails';
import { useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { ReactComponent as Organisation } from '../../../assets/icons/solid/building-office-2.svg';
import chevronRight from '../../../assets/icons/solid/chevron-right.svg';
import { ReactComponent as Devices } from '../../../assets/icons/solid/device.svg';
import { ReactComponent as Pencil } from '../../../assets/icons/solid/pencil.svg';
import { ReactComponent as ViewQR } from '../../../assets/icons/solid/qr-code.svg';
import { ReactComponent as Trash } from '../../../assets/icons/solid/trash.svg';
import type { TabItemsInterface } from '../Event.interface';
import style from '../Events.module.css';

import { DeleteEventTimingLocationModal } from './DeleteTimingLocationModal';
import DetailsAbout from './DetailsAbout';
import DetailsDevices from './DetailsDevices';
import DetailsOrganisationInfo from './DetailsOrganisationInfo';
//import TimingLocationForm from './TimingLocationForm';
import TimingLocationForm from './TimingLocationForm';
import type { EventTimingLocations } from './TimingLocations.interfaces';

type Props = {};

const TimingLocationDetails = (props: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const {
    id: eventId,
    id2: sectionId,
    layoutId,
  } = useParams<{
    id: string;
    id2: string;
    layoutId: string;
  }>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [timingLocationToEdit, setTimingLocationToEdit] =
    useState<EventTimingLocations | null>(null);

  const details = useFetchEventTimingLocationDetails(
    eventId as string,
    layoutId as string,
    sectionId as string,
  );

  const { data: event } = useFetchEventDetails(eventId);

  const [showQrModal, hideQrModal] = useModal(
    () => (
      <TLModal
        //size={ModalSize.XS}
        title="View QR"
        onClose={hideQrModal}
        className={style.qrModal}
      >
        <QR value={sectionId as string} qrId={sectionId as string} />
      </TLModal>
    ),
    [],
  );

  const [showDeleteModal, hideDeleteModal] = useModal(() => {
    const hideDeleteTagModal = () => {
      hideDeleteModal();
    };
    return (
      <DeleteEventTimingLocationModal
        id={sectionId as string}
        name={details.data?.name}
        layoutId={layoutId as string}
        eventName={event?.name}
        eventId={eventId as string}
        closeModal={hideDeleteTagModal}
        setSelectedRows={setSelectedRows}
        redirectToTimingLocationTable={true}
      />
    );
  }, [details, event]);

  const [showEditTimingLocationsModal, _hideEditTimingLocationsModal] =
    useModal(() => {
      const hideModal = () => {
        _hideEditTimingLocationsModal();
        setTimingLocationToEdit(null);
      };

      return (
        <TLModal size={ModalSize.CUSTOM80} onClose={hideModal} hideHeader>
          <TimingLocationForm
            onClose={hideModal}
            data={timingLocationToEdit}
            isEdit={!!timingLocationToEdit}
            eventId={eventId}
            timezone={event?.timezone!}
            fromDetails={true}
          />
        </TLModal>
      );
    }, [timingLocationToEdit]);

  const handleEditEventTimingLocationsClick = (
    event: EventTimingLocations | null,
  ) => {
    setTimingLocationToEdit(event);

    showEditTimingLocationsModal();
  };

  // const [showEditModal, hideEditModal] = useModal(() => {
  //   const hideDeleteTagModal = () => {
  //     hideDeleteModal();
  //   };
  //   return (
  //     <TLModal
  //       //size={ModalSize.XS}
  //       title="View QR"
  //       onClose={hideEditModal}
  //       className={style.qrModal}
  //     ></TLModal>
  //   );
  // }, []);

  const tabItems: Array<TabItemsInterface> = [
    {
      name: 'about',
      icon: 'information-circle',
      label: 'About',
    },
    {
      name: 'layout',
      icon:
        activeIndex === 1 ? (
          <Organisation className={style.iconActive} />
        ) : (
          <Organisation />
        ),
      label: 'Organisation info',
    },

    {
      name: 'devices',
      icon:
        activeIndex === 3 ? (
          <Devices className={style.iconActive} />
        ) : (
          <Devices />
        ),
      label: 'Devices',
    },
  ];

  const renderTabContent = () => {
    switch (activeIndex) {
      case 0:
        return (
          <DetailsAbout
            data={details?.data}
            eventId={eventId as string}
            layoutId={layoutId as string}
            sectionId={sectionId as string}
            timezone={event?.timezone!}
          />
        );
      case 1:
        return <DetailsOrganisationInfo data={details.data?.information} />;
      case 2:
        return (
          <DetailsDevices
            eventId={eventId as string}
            layoutId={layoutId as string}
            sectionId={sectionId as string}
            timezone={event?.timezone as string}
          />
        );
    }
  };

  return (
    <div className={style.page}>
      <div className={style.header}>
        <div className={style.breadcrumb}>
          <div className={style.breadcrumb_item1}>
            <Link to="/events">Events</Link>
          </div>
          <div className={style.breadcrumb_item2}>
            <img src={chevronRight} alt=""></img>
          </div>
          <div className={style.breadcrumb_item1}>
            <div></div>
            <Link to={`/event/${eventId}`}>{event?.name}</Link>
          </div>
          <div className={style.breadcrumb_item2}>
            <img src={chevronRight} alt=""></img>
          </div>
          <div className={style.breadcrumb_item3}>
            <div>{details.data?.name}</div>
          </div>
        </div>
        <div className={style.event_timing_location_details}>
          <div>{details.data?.name}</div>
          <div className={style.event_timing_location_details_buttons}>
            <Button
              className={style.actionButton}
              btnType={'outline'}
              iconStyle={'regular'}
              color={'neutral'}
              onClick={showQrModal}
            >
              <ViewQR fill="#94A3B8" />
              View QR
            </Button>
            <Button
              className={style.actionButton}
              btnType={'outline'}
              iconStyle={'regular'}
              color={'neutral'}
              onClick={() => handleEditEventTimingLocationsClick(details.data)}
            >
              <Pencil fill="#94A3B8" />
              Edit info
            </Button>
            <Button
              className={style.actionButton}
              btnType={'outline'}
              iconStyle={'regular'}
              color={'danger'}
              onClick={showDeleteModal}
            >
              <Trash fill="#F43F5E" />
              Delete
            </Button>
          </div>
        </div>
        <Tabs className={style.tabs} color="secondary">
          {tabItems.map((item: TabItemsInterface, index: number) => {
            return (
              <TabItem
                active={index === activeIndex}
                onClick={() => setActiveIndex(index)}
                className={style.tab}
                color="secondary"
                key={index}
              >
                {typeof item.icon === 'string' ? (
                  <Icon icon={item.icon} />
                ) : (
                  item.icon
                )}
                {item.label}
              </TabItem>
            );
          })}
        </Tabs>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default TimingLocationDetails;
